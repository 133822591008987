export const SELECT_ORDER = "[order_payment] Select";
export const FETCH_SINGLE_ORDER_SUCCESS = "[order_payment] Fetch single success";
export const FETCH_SINGLE_ORDER_ERROR = "[order_payment] Fetch single Error";
export const UPDATE_ORDER = "[order_payment] Update single";

export const APPROVE_PAYMENT_ORDER = "[order_payment] approve payment";
export const APPROVE_PAYMENT_ORDER_SUCCESS = "[order_payment] approve payment success";
export const APPROVE_PAYMENT_ORDER_ERROR = "[order_payment] approve payment Error";
export const APPROVE_PAYMENT_ORDER_SET_STATUS = "[order_payment] approve payment set status";

export const CANCEL_ORDER = "[order_payment] cancel order";
export const CANCEL_ORDER_SUCCESS = "[order_payment] cancel order success";
export const CANCEL_ORDER_ERROR = "[order_payment] cancel order Error";
export const CANCEL_ORDER_SET_STATUS = "[order_payment] cancel order set status";

export const EXPIRED_ORDER = "[order_payment] check order expiry date";
export const EXPIRED_ORDER_SUCCESS = "[order_payment] order expiry date success";
export const EXPIRED_ORDER_ERROR = "[order_payment] order expiry date Error";
export const EXPIRED_ORDER_SET_STATUS = "[order_payment] order expiry date status ";
export const EXPIRED_ORDER_SET_EXPIRY_STATUS = "[order_payment] order expiry date set response";

// For fetching a single order
export const selectOrder = (order_no) => ({
  type: SELECT_ORDER,
  payload: { order_no: order_no },
});

export const fetchSingleOrderSuccess = (data) => ({
  type: FETCH_SINGLE_ORDER_SUCCESS,
  payload: data,
});

export const fetchSingleOrderError = (data) => ({
  type: FETCH_SINGLE_ORDER_ERROR,
  payload: data,
});

export const setSingleOrder = (data) => ({
  type: UPDATE_ORDER,
  payload: data,
});



// For fetching a single order
export const approveOrderPaymentStatus = (data) => ({
  type: APPROVE_PAYMENT_ORDER,
  payload: data,
});

export const approveOrderPaymentStatusSuccess = (data) => ({
  type: APPROVE_PAYMENT_ORDER_SUCCESS,
  payload: data,
});

export const approveOrderPaymentStatusError = (data) => ({
  type: APPROVE_PAYMENT_ORDER_ERROR,
  payload: data,
});

export const setApproveOrderPaymentStatus = (data) => ({
  type: APPROVE_PAYMENT_ORDER_SET_STATUS,
  payload: data,
});

// For cancel order fetch
export const orderCancelStatus = (data) => ({
  type: CANCEL_ORDER,
  payload: data,
});

export const orderCancelStatusSuccess = (data) => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: data,
});

export const orderCancelStatusError = (data) => ({
  type: CANCEL_ORDER_ERROR,
  payload: data,
});

export const orderCancelledStatus = (data) => ({
  type: CANCEL_ORDER_SET_STATUS,
  payload: data,
});

export const orderExpiryDate = (data) => ({
  type: EXPIRED_ORDER,
  payload: data,
});
export const orderExpiryDateStatusSuccess = (data) => ({
  type: EXPIRED_ORDER_SUCCESS,
  payload: data,
});
export const orderExpiryDateStatusError = (data) => ({
  type: EXPIRED_ORDER_ERROR,
  payload: data,
});
export const setorderExpiryDateStatus = (data) => ({
  type: EXPIRED_ORDER_SET_STATUS,
  payload: data,
});
export const setExpiryStatus = (data) => ({
  type: EXPIRED_ORDER_SET_EXPIRY_STATUS,
  payload: data,
});