import React from "react";
import { NavLink } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";
import "../SidebarComponent/SidebarComponent.css";
import { connect } from "react-redux";
import { USER_PERMISSIONS } from "../../js/constants";

const SidebarComponent = ({ isSellerRegistered }) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Nav className="sidebar">
        {!isSellerRegistered && (
          <ReactBootstrap.Nav.Item>
            <ReactBootstrap.Nav.Link
              as={NavLink}
              exact
              to="/account/seller"
              activeClassName="myProfileActive"
            >
              Become a seller
            </ReactBootstrap.Nav.Link>
          </ReactBootstrap.Nav.Item>
        )}
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            exact
            to="/account"
            activeClassName="myProfileActive"
          >
            My Account
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            to="/account/mytickets"
            activeClassName="myProfileActive"
          >
            My Tickets
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            to="/account/mycollection"
            activeClassName="myProfileActive"
          >
            My Collections
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            exact
            to="/account/myorders"
            activeClassName="myProfileActive"
          >
            My Orders
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            to="/account/myoffers"
            activeClassName="myProfileActive"
          >
            My Offers
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            to="/account/mymessages"
            activeClassName="myProfileActive"
          >
            My Messages
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            exact
            to="/account/myreviews"
            activeClassName="myProfileActive"
          >
            My Reviews
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            exact
            to="/account/report"
            activeClassName="myProfileActive"
          >
            My Report
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
        <ReactBootstrap.Nav.Item>
          <ReactBootstrap.Nav.Link
            as={NavLink}
            exact
            to="/account/delete"
            activeClassName="myProfileActive"
          >
            Delete Account
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Nav.Item>
      </ReactBootstrap.Nav>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isSellerRegistered:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) => permission.permission === USER_PERMISSIONS.SELL
          )
        : false,
  };
};

export default connect(mapStateToProps, null)(SidebarComponent);
