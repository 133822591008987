import React from "react";
import PropTypes from "prop-types";
import { getMyOfferRenderStatus } from "../../../js/utils";
import { Badge } from "react-bootstrap";
import { OFFER_STATES } from "../../../js/constants";
import "./OfferDetailsHeader.css"

const OfferDetailsHeader = ({ offer_no, status, price }) => {
  const getBadgeClassName = (status) => {
    let className = "";
    switch (status) {
      case OFFER_STATES.ACCEPTED_BUYER:
      case OFFER_STATES.ACCEPTED_SELLER:
      case OFFER_STATES.CART:
      case OFFER_STATES.IN_CHECOUT:
      case OFFER_STATES.IN_ORDER:
        className = "badge-success";
        break;
      case OFFER_STATES.CANCELLED:
      case OFFER_STATES.DROPPED:
      case OFFER_STATES.REJECTED:
        className = "badge-danger";
        break;
      case OFFER_STATES.OPEN:
        className = "badge-warning";
        break;
      default:
        break;
    }
    return className
  };
  return (
    <React.Fragment>
      <div className="d-flex flex-wrap align-items-center">
        <div className="flex-grow-1 font-weight-bold offer_no">{offer_no}</div>
        <div className="ml-auto">
          <Badge className={getBadgeClassName(status)}>
            {getMyOfferRenderStatus(status)}
          </Badge>
          <div>
            <i className="fa fa-usd" aria-hidden="true"></i>
            {price}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

OfferDetailsHeader.propTypes = {
  offer_no: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default OfferDetailsHeader;
