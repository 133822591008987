import React from "react";
import { Spinner } from "react-bootstrap";
import Rating from "./Rating";
import { Link } from "react-router-dom";

const SellerRatings = ({ ratings, hasmore, nextPage, isLoading }) => {
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          {ratings.length === 0 ? (
            <div className="text-center">No reviews found</div>
          ) : (
            <div
              style={{
                height: 220,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div>
                {ratings.map((rating, idx) => {
                  return (
                    <Rating
                      key={idx}
                      communication_rating={rating.ratings.communication_rating}
                      description_rating={rating.ratings.description_rating}
                      shipping_rating={rating.ratings.shipping_rating}
                      rating_on={rating.rated_at}
                      review={rating.content}
                      user_name={rating.user_details.name}
                      user_image={rating.user_details.avatar}
                    />
                  );
                })}

                <p style={{ textAlign: "center" }}>
                  {hasmore === true && (
                    <Link
                      to="/nextReviews"
                      onClick={(e) => {
                        e.preventDefault();
                        nextPage();
                      }}
                    >
                      <b> Load more </b>
                    </Link>
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default SellerRatings;
