import React from "react";
import MyReviewCardComponent from "./MyReviewCardComponent/MyReviewCardComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Spinner } from "react-bootstrap";

const MyReviewsView = ({ items, hasmore, nextPage }) => {
  return (
    <React.Fragment>
      <Container className="my-4">
        <InfiniteScroll
          dataLength={items.length}
          next={nextPage}
          hasMore={hasmore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <Spinner as="span" animation="border" role="status" />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {items.length !== 0 ? (
                <b>No more reviews found</b>
              ) : (
                <b>No reviews Found</b>
              )}
            </p>
          }
        >
          {items.map((item, idx) => {
            return (
              <MyReviewCardComponent
                key={idx}
                type={item.type}
                communication_rating={item.ratings.communication_rating}
                description_rating={item.ratings.description_rating}
                shipping_rating={item.ratings.shipping_rating}
                rating_on={item.rated_at}
                review={item.content}
                user_name={item.user_details.name}
                user_image={item.user_details.avatar}
              />
            );
          })}
        </InfiniteScroll>
      </Container>
    </React.Fragment>
  );
};
export default MyReviewsView;
