import React, { useEffect } from "react";
import { Row, Col, Dropdown, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
// import { initiateCheckout } from "../../store/actions/checkout";
import CheckoutView from "./CheckoutView";
import {
  initiateOrder,
  setOrderStatus,
  setinitiateOrder,
} from "../../store/actions/myOrder";
import { OPERATION_STATUS } from "../../js/constants";

const CheckoutPage = ({
  is_checked_out,
  line_items,
  accepted_payments,
  pricing_summary,
  history,
  checkout_id,
  initiateOrder,
  Order_status,
  setOrderStatus,
  order_no,
  isInitiateOrderPending,
  setinitiateOrder,
}) => {
  useEffect(() => {
    if (!is_checked_out) {
      history.push({
        pathname: "/mycart",
      });
    }
  }, [is_checked_out]);

  const placeorder = () => {
    var data = {
      checkout_id: checkout_id,
    };
    initiateOrder(data);
  };
  useEffect(() => {
    if (Order_status === OPERATION_STATUS.SUCCESS) {
      let initiate_order_no = order_no;
      setOrderStatus(OPERATION_STATUS.YET_TO_START);
      setinitiateOrder(null);
      let path = "/previeworder/" + initiate_order_no;
      history.push({
        pathname: path,
      });
    } else if (Order_status === OPERATION_STATUS.FAILURE) {
      setOrderStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [Order_status]);
  return (
    <React.Fragment>
      <div className="m-0 my-4 mr-3 ml-3">
        <Row>
          <Col>
            <h5>Checkout details</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
        {isInitiateOrderPending ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status" />
            <p>Creating order. Please wait ...</p>
          </div>
        ) : (
          <CheckoutView
            placeorder={placeorder}
            line_items={line_items}
            accepted_payments={accepted_payments}
            pricing_summary={pricing_summary}
          />
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    is_checked_out:
      state.checkout.checkout_details.checkout_id &&
      state.checkout.checkout_details.checkout_id.toString().length > 0,
    checkout_id: state.checkout.checkout_details.checkout_id,
    line_items: state.checkout.checkout_details.line_items,
    accepted_payments: state.checkout.checkout_details.accepted_payment_methods,
    pricing_summary: state.checkout.checkout_details.pricing_summary,
    Order_status: state.myOrder.initiate_order.status,
    isInitiateOrderPending:
      state.myOrder.initiate_order.status === OPERATION_STATUS.PENDING,
    order_no: state.myOrder.initiate_order.data
      ? state.myOrder.initiate_order.data.order_no
      : null,
  };
};

export default connect(mapStateToProps, {
  initiateOrder,
  setOrderStatus,
  setinitiateOrder,
})(CheckoutPage);
