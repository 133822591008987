import React from "react";
import "./TextMessage.css";

const TextMessage = ({ content, time, sender_name, sender_image }) => {
  return (
    <React.Fragment>
      <div className="m-2 d-flex flex-nowrap">
        <div className="mr-2">
          <img
            src={sender_image}
            className="rounded-circle"
            alt={sender_name}
            width="40"
            height="40"
            style={{objectFit:"contain", backgroundColor:"#222"}}
          />
        </div>
        <div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="font-weight-bold mr-1">{sender_name}</div>
            <div className="msgTime">{time}</div>
          </div>
          <div
            className="mr-1 pr-2"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextMessage;
