import React from "react";
import PropTypes from "prop-types";
import UserDetails from "../UserDetails/UserDetails";
import { getRenderType } from "../../../../js/utils";
import { Badge } from "react-bootstrap";
import { OFFER_TYPES } from "../../../../js/constants";

const OfferCardHeader = ({ created_by, created_at, type, price, message }) => {
  const getBadgeClassName = (type) => {
    return type === OFFER_TYPES.OFFER ? "badge-primary" : "badge-secondary";
  };
  return (
    <React.Fragment>
      <div className="d-flex flex-wrap align-items-center">
        <div className="flex-grow-1">
          <UserDetails
            sender_name={created_by.name}
            sender_image={created_by.avatar.image_50}
            time={created_at}
            message={message}
          />
        </div>
        <div className="ml-auto">
          <Badge className={getBadgeClassName(type)}>{getRenderType(type)}</Badge>
          <div>
            <i className="fa fa-usd" aria-hidden="true"></i>
            {price}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

OfferCardHeader.propTypes = {
  created_by: PropTypes.object.isRequired,
  created_at: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default OfferCardHeader;
