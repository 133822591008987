import React from "react";
import PropTypes from "prop-types";
import * as ReactBootstrap from "react-bootstrap";
import Collapsible from "react-collapsible";

import "./TicketsFilterComponent.css";
import SelectFormControl from "../../../common/SelectFormControlComponent/SelectFormControlComponent";
import InputFormControl from "../../../common/InputFormControlComponent/InputFormControlComponent";
import DateFormControl from "../../../common/DateFormControlComponent/DateFormControlComponent";
import FilterButtonComponent from "../../../common/FilterButtonComponent/FilterButtonComponent";
import FilterMenuComponent from "../../../common/FilterMenuComponent/FilterMenuComponent";

const TicketsFilterComponent = ({ filters, onApply, onClear }) => {

  const renderSwitchFormControl = (filter,index,maxhight) => {
    switch(filter.type) {
      case 'select':
        return <SelectFormControl 
        maxhight={maxhight}
        key={index}
        placeholder={filter.placeholder}
        value={filter.value}
        name={filter.name}
        options={filter.options}
        onChange={filter.onChange}
      />;
      case 'text':
        return <InputFormControl 
        key={index}
        placeholder={filter.placeholder}
        value={filter.value}
        name={filter.name}
        onChange={filter.onChange}
      />
      case 'date':
        return <DateFormControl 
        key={index}
        placeholder={filter.placeholder}
        value={filter.value}
        name={filter.name}
        onChange={filter.onChange}
      />
      default:
        return null;
    }
  }
  const maxhight=120;
  return (
    <React.Fragment>
      <div className="collapsibleMobileModeTeam">
        <Collapsible trigger="Filter">
          <nav className="nav d-block justify-content-between">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12}>
                {filters.map((filter,index) => {
                  return renderSwitchFormControl(filter,index,maxhight)
                })}
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} align="center">
                <FilterButtonComponent onApply={onApply} onClear={onClear} />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </nav>
        </Collapsible>
      </div>
      <ReactBootstrap.Container fluid className="navTeamArtistBgColor">
        <ReactBootstrap.Container className="">
          <div className="navFilterSection">
            <ReactBootstrap.Row>
              <nav className="pt-2 nav d-flex justify-content-between navMenuFullWidth">
                <ReactBootstrap.Col xs={12} md={1}>
                  <FilterMenuComponent />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={8}>
                  <ReactBootstrap.Row className="ml-1">
                    {filters.map((filter,index) => {
                      return <ReactBootstrap.Col key={index} xs={12} md={4} className="mb-3">
                         {renderSwitchFormControl(filter,index)}
                      </ReactBootstrap.Col>
                    })}
                  </ReactBootstrap.Row>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={3} align="right">
                  <FilterButtonComponent onApply={onApply} onClear={onClear} />
                </ReactBootstrap.Col>
              </nav>
            </ReactBootstrap.Row>
          </div>
        </ReactBootstrap.Container>
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

TicketsFilterComponent.propTypes = {
  filters: PropTypes.array.isRequired,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default TicketsFilterComponent;
