import {
    INITIATE_CHECKOUT,
    INITIATE_CHECKOUT_SUCCESS,
    INITIATE_CHECKOUT_ERROR,
    initiateCheckoutSuccess,
    initiateCheckoutError,
    setCheckoutDetails,
    setCheckoutStatus,

    UPDATE_CHECKOUT_SHIPPING_ADDRESS,
    UPDATE_CHECKOUT_SHIPPING_ADDRESS_SUCCESS,
    UPDATE_CHECKOUT_SHIPPING_ADDRESS_ERROR,
    updateCheckoutShippingAddressSuccess ,
    updateCheckoutShippingAddressError,
    updateCheckoutShippingAddressSetStatus,
    setCheckoutShippingAddress,


    UPDATE_CHECKOUT_BILLING_ADDRESS,
    UPDATE_CHECKOUT_BILLING_ADDRESS_SUCCESS,
    UPDATE_CHECKOUT_BILLING_ADDRESS_ERROR,
    updateCheckoutBillingAddressSuccess ,
    updateCheckoutBillingAddressError,
    updateCheckoutBillingAddressSetStatus,
    setCheckoutBillingAddress,

    GET_CHECKOUT,
    GET_CHECKOUT_SUCCESS ,
    GET_CHECKOUT_ERROR  ,
    getCheckoutSuccess ,
    getCheckoutError,

    ADD_USER_SHIPPING_ADDRESS,
    ADD_USER_SHIPPING_ADDRESS_SUCCESS,
    ADD_USER_SHIPPING_ADDRESS_ERROR,
    addUserShippingAddressSuccess,
    addUserShippingAddressError,
    addUserShippingAddressSetStatus,

    EDIT_USER_SHIPPING_ADDRESS,
    EDIT_USER_SHIPPING_ADDRESS_SUCCESS,
    EDIT_USER_SHIPPING_ADDRESS_ERROR,
    editUserShippingAddressSuccess,
    editUserShippingAddressError,
    editUserShippingAddressSetStatus,

    ADD_USER_BILLING_ADDRESS,
    ADD_USER_BILLING_ADDRESS_SUCCESS,
    ADD_USER_BILLING_ADDRESS_ERROR,
    addUserBillingAddressSuccess,
    addUserBillingAddressError,
    addUserBillingAddressSetStatus,

    EDIT_USER_BILLING_ADDRESS,
    EDIT_USER_BILLING_ADDRESS_SUCCESS,
    EDIT_USER_BILLING_ADDRESS_ERROR,
    editUserBillingAddressSuccess,
    editUserBillingAddressError,
    editUserBillingAddressSetStatus



  } from "../../actions/checkout";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { setBillingAddress, setFullShippingAddress } from "../../actions/addressAction";
import { apiRequest } from "../../actions/api";
import { OPERATION_STATUS,notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
import Avatar from "../../../images/avatar.png";
  
// this middleware only care about the getBooks action
export const initiateCheckoutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === INITIATE_CHECKOUT) {
    dispatch(setCheckoutStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/orders/checkout.php",
        null,
        action.payload,
        initiateCheckoutSuccess,
        initiateCheckoutError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-initiate"));
  }

};

// on successful fetch, process the books data
export const processInitiateCheckout = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === INITIATE_CHECKOUT_SUCCESS) {
    let data = action.payload.data
    let payment_methods = data.accepted_payment_methods.map(item => {
      return {
        id: item.payment_method_id,
        type: item.payment_method_type,
        selected: item.selected
      }
    })
    let line_items =  data.line_items.map(item => {
      return {
        id: item.id,
        type: item.type,
        offer_id: item.offer_id,
        offer_no: item.offer_no,
        seller: {
          id: item.seller.id,
          name:  item.seller.name,
          avatar: {
            image_50: item.seller.avatar ?  item.seller.avatar : Avatar,
            image_200:  item.seller.avatar ?  item.seller.avatar  : Avatar,
            image_400:  item.seller.avatar  ?  item.seller.avatar : Avatar,
          },
          is_admin:  item.seller.is_admin,
        },
        base_price: item.base_price.value,
        tax_price: item.tax_price.value,
        details: item.details.map((ticket) => {
          return {
            product_id: ticket.product_id,
            listing_name: ticket.listing_name,
            cost: ticket.cost.value,
            image: ticket.images[0],
          };
        })
      }
    })
    let buyer = {
      id: data.buyer.id,
      name:  data.buyer.name,
      avatar: {
        image_50: data.buyer.avatar ?  data.buyer.avatar : Avatar,
        image_200:  data.buyer.avatar ?  data.buyer.avatar  : Avatar,
        image_400:  data.buyer.avatar  ?  data.buyer.avatar : Avatar,
      },
      is_admin:  data.buyer.is_admin,
    }
    let pricing_summary = {
      price_sub_total: data.pricing_summary.price_subtotal.value,
      tax: data.pricing_summary.tax.value,
      total: data.pricing_summary.total.value,
    }
    let checkout = {
      checkout_id: data.checkout_id,
      status: data.status,
      payment_methods: payment_methods,
      buyer: buyer,
      line_items: line_items,
      pricing_summary: pricing_summary,
      shipping_address: data.shipping_address,
      billing_address: data.billing_address,

    }
    dispatch(setCheckoutDetails(checkout));
    dispatch(setCheckoutStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-initiate"));
  }

  if (action.type === INITIATE_CHECKOUT_ERROR) {
    dispatch(setCheckoutStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-initiate",
        notification_types.error
      )
    );     
    dispatch(hideSpinner("checkout-initiate"));
  }
};

// this middleware only care about the  action
export const updateShippingAddressFlow = ({ dispatch}) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_CHECKOUT_SHIPPING_ADDRESS) {
    dispatch(updateCheckoutShippingAddressSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "/user/orders/checkout.php?action=updateShippingAddress",
        null,
        action.payload,
        updateCheckoutShippingAddressSuccess,
        updateCheckoutShippingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-update_shipping"));
  }

};
    
// on successful fetch, process the books data
export const processUpdateCheckoutShippingAddress = ({ dispatch }) => (next) => (action) => {
  next(action);
  
  if (action.type === UPDATE_CHECKOUT_SHIPPING_ADDRESS_SUCCESS) {
    dispatch(setCheckoutShippingAddress(action.payload.data));
    dispatch(
      setNotification(
        "Shipping Address Changed",
        "Address_Changed",
        notification_types.success
      )
    );
    dispatch(updateCheckoutShippingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-update_shipping"));
    // get Checkout calling  pending
  }

  if (action.type === UPDATE_CHECKOUT_SHIPPING_ADDRESS_ERROR) {
    dispatch(updateCheckoutShippingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-update_shipping",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-update_shipping"));
  }
};

export const updateBillingAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_CHECKOUT_BILLING_ADDRESS) {
    dispatch(
      apiRequest(
        "PUT",
        "/user/orders/checkout.php?action=updateBillingAddress",
        null,
        action.payload,
        updateCheckoutBillingAddressSuccess,
        updateCheckoutBillingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-update_billing"));
  }

};
  
// on successful fetch, process the books data
export const processUpdateCheckoutBillingAddress = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_CHECKOUT_BILLING_ADDRESS_SUCCESS) {
    dispatch(setCheckoutBillingAddress(action.payload.data));
    dispatch(
      setNotification(
        "Billing Address Changed",
        "Address_Changed",
        notification_types.success
      )
    );
    dispatch(updateCheckoutBillingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-update_billing"));
  }

  if (action.type === UPDATE_CHECKOUT_BILLING_ADDRESS_ERROR) {
    dispatch(updateCheckoutBillingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-update_billing",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-update_billing"));
  }
};

export const GetCheckoutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_CHECKOUT) {
    dispatch(setCheckoutStatus(false));
    dispatch(
      apiRequest(
        "GET",
        "/user/orders/checkout.php",
        action.payload,
        null,
        getCheckoutSuccess,
        getCheckoutError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout"));
  }

};
// on successful fetch, process the books data
export const processGetCheckoutCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === GET_CHECKOUT_SUCCESS) {
    let data = action.payload.data
    dispatch(setCheckoutDetails(data));
  }

  if (action.type === GET_CHECKOUT_ERROR) {
    dispatch(hideSpinner("checkout"));
  }
};

// this middleware only care about the  action
export const addShippingAddressFlow = ({ dispatch}) => (next) => (action) => {
  next(action);
  if (action.type === ADD_USER_SHIPPING_ADDRESS) {
    dispatch(addUserShippingAddressSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/account/address.php",
        null,
        action.payload,
        addUserShippingAddressSuccess,
        addUserShippingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-add_shipping"));
  }

};
    
// on successful fetch, process the books data
export const processAddShippingAddress = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  
  if (action.type === ADD_USER_SHIPPING_ADDRESS_SUCCESS) {
    let ship_address = getState().Address.ShippingAddressList;
    let add_address = action.payload.data
    let address = {
      id: add_address.id,
      first_name: add_address.first_name,
      last_name: add_address.last_name,
      mobile_no: add_address.mobile_no,
      address_line_1: add_address.address_line_1,
      address_line_2: add_address.address_line_2,
      administrative_area: add_address.administrative_area,
      city: add_address.city,
      postal_code: add_address.postal_code,
      country: add_address.country,
      is_primary: add_address.is_primary,
      type: 'DELIVERY'
    }

    let new_ship_addresses =  [...ship_address,address];

    dispatch(setFullShippingAddress(new_ship_addresses));
    dispatch(
      setNotification(
        "Shipping Address added",
        "Address_added",
        notification_types.success
      )
    );
    dispatch(addUserShippingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-add_shipping"));
    // get Checkout calling  pending
  }

  if (action.type === ADD_USER_SHIPPING_ADDRESS_ERROR) {
    dispatch(addUserShippingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-add_shipping",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-add_shipping"));
  }
};

// this middleware only care about the  action
export const editShippingAddressFlow = ({ dispatch}) => (next) => (action) => {
  next(action);
  if (action.type === EDIT_USER_SHIPPING_ADDRESS) {
    dispatch(editUserShippingAddressSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "/user/account/address.php",
        null,
        action.payload,
        editUserShippingAddressSuccess,
        editUserShippingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-edit_shipping"));
  }

};
    
// on successful fetch, process the books data
export const processEditShippingAddress = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  
  if (action.type === EDIT_USER_SHIPPING_ADDRESS_SUCCESS) {
    let edit_address = action.payload.data
    let ship_address = getState().Address.ShippingAddressList.filter(s=> parseInt(s.id) !== parseInt(edit_address.id) );
    let address = {
      id: edit_address.id,
      first_name: edit_address.first_name,
      last_name: edit_address.last_name,
      mobile_no: edit_address.mobile_no,
      address_line_1: edit_address.address_line_1,
      address_line_2: edit_address.address_line_2,
      administrative_area: edit_address.administrative_area,
      city: edit_address.city,
      postal_code: edit_address.postal_code,
      country: edit_address.country,
      is_primary: edit_address.is_primary,
      type: 'DELIVERY'
    }
    let new_ship_addresses =  [...ship_address,address];

    dispatch(setFullShippingAddress(new_ship_addresses));
    dispatch(
      setNotification(
        "Shipping Address updated",
        "Address_edited",
        notification_types.success
      )
    );
    dispatch(editUserShippingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-edit_shipping"));
    // get Checkout calling  pending
  }

  if (action.type === EDIT_USER_SHIPPING_ADDRESS_ERROR) {
    dispatch(editUserShippingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-edit_shipping",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-edit_shipping"));
  }
};

// Billing
// this middleware only care about the  action
export const addBillingAddressFlow = ({ dispatch}) => (next) => (action) => {
  next(action);
  if (action.type === ADD_USER_BILLING_ADDRESS) {
    dispatch(addUserBillingAddressSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/account/address.php",
        null,
        action.payload,
        addUserBillingAddressSuccess,
        addUserShippingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-add_billing"));
  }

};
    
// on successful fetch, process the books data
export const processAddBillingAddress = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  
  if (action.type === ADD_USER_BILLING_ADDRESS_SUCCESS) {
    let added_address = action.payload.data
    let address = [{
      id: added_address.id,
      first_name: added_address.first_name,
      last_name: added_address.last_name,
      mobile_no: added_address.mobile_no,
      address_line_1: added_address.address_line_1,
      address_line_2: added_address.address_line_2,
      administrative_area: added_address.administrative_area,
      city: added_address.city,
      postal_code: added_address.postal_code,
      country: added_address.country,
      is_primary: added_address.is_primary,
      type: 'BILL'
    }]

    dispatch(setBillingAddress(address));
    dispatch(
      setNotification(
        "Billing Address added",
        "Address_added",
        notification_types.success
      )
    );
    dispatch(addUserBillingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-add_billing"));
    // get Checkout calling  pending
  }

  if (action.type === ADD_USER_BILLING_ADDRESS_ERROR) {
    dispatch(addUserBillingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-add_billing",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-add_billing"));
  }
};

// this middleware only care about the  action
export const editBillingAddressFlow = ({ dispatch}) => (next) => (action) => {
  next(action);
  if (action.type === EDIT_USER_BILLING_ADDRESS) {
    dispatch(editUserBillingAddressSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "/user/account/address.php",
        null,
        action.payload,
        editUserBillingAddressSuccess,
        editUserBillingAddressError,
        true,
        "[checkout]"
      )
    );
    dispatch(showSpinner("checkout-edit_billing"));
  }

};
    
// on successful fetch, process the books data
export const processEditBillingAddress = ({ dispatch }) => (next) => (action) => {
  next(action);
  
  if (action.type === EDIT_USER_BILLING_ADDRESS_SUCCESS) {
    let edit_address = action.payload.data
    let address = {
      id: edit_address.id,
      first_name: edit_address.first_name,
      last_name: edit_address.last_name,
      mobile_no: edit_address.mobile_no,
      address_line_1: edit_address.address_line_1,
      address_line_2: edit_address.address_line_2,
      administrative_area: edit_address.administrative_area,
      city: edit_address.city,
      postal_code: edit_address.postal_code,
      country: edit_address.country,
      is_primary: edit_address.is_primary,
      type: 'BILL'
    }
    dispatch(setBillingAddress(address));
    dispatch(
      setNotification(
        "Billing Address updated",
        "Address_edited",
        notification_types.success
      )
    );
    dispatch(editUserBillingAddressSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("checkout-edit_billing"));
    // get Checkout calling  pending
  }

  if (action.type === EDIT_USER_BILLING_ADDRESS_ERROR) {
    dispatch(editUserBillingAddressSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "checkout-edit_billing",
        notification_types.error
      )
    ); 
    dispatch(hideSpinner("checkout-edit_billing"));
  }
};
  
  


export const checkoutMdl = [
  initiateCheckoutFlow,
  processInitiateCheckout, 
  updateShippingAddressFlow, 
  processUpdateCheckoutShippingAddress,
  updateBillingAddressFlow,
  processUpdateCheckoutBillingAddress,
  GetCheckoutFlow,
  processGetCheckoutCollection,
  addShippingAddressFlow,
  processAddShippingAddress,
  editShippingAddressFlow,
  processEditShippingAddress,
  addBillingAddressFlow,
  processAddBillingAddress,
  editBillingAddressFlow,
  processEditBillingAddress
  ];