import React from "react";
import { Row, Col ,Container} from "react-bootstrap";
import "./BannerComponent.css";
import BannerImage from "../../../images/banner-image-one.png";

const BannerComponent = () => {
  return (
    <React.Fragment>
    <section className="banner-section">
        <Container fluid className="m-0 p-0 ">
            <div className="banner-image" style={{height: '400px'}}>
                {/* <img src={BannerImage} alt="banner" /> */}
            </div>
            <div className="banner-overlay"></div>
            <Container className="banner-content">
                <div className="banner-container">
                    <Row>
                        <Col xs={12} sm={7} md={6} className="banner-left-content">
                            <h1>A Marketplace For <br /> Tickets And Memories<br /> From Moments In History</h1>
                        </Col>
                        <Col xs={12} sm={5}  className="banner-right-content">
                            <h4>If A Picture Is Worth A Thousand Words, Then A Ticket Is Worth A Million Memories</h4>
                            <p>Game 7, a knockout, a historical performance - few things drive the emotion of an event and what happend is a key moment in history</p>
                            <h4>The Ticket Tells The Story</h4>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Container>
    </section>
</React.Fragment>
  );
};

export default BannerComponent;
