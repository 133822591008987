import React from "react";
import { Route, Switch } from "react-router-dom";
import SiteLayout from "./SiteLayout"
import DashboardLayout from "./DashboardLayout"
import { PAGE_URLS } from "../../js/constants"
import HeaderComponent from "../../common/HeaderComponent/HeaderComponentNew";
import FooterComponent from "../../common/FooterComponent/FooterComponentNew";
import ProtectedRoute from "../RouteGuard/ProtectedRoute"

const MainLayout = (props) => {
  return (
    <React.Fragment>
      <header className="container-fluid p-0 m-0">
        <HeaderComponent />
      </header>
      <main className="container-fluid p-0 m-0">
        <Switch>
          <Route
            path={[
              PAGE_URLS.HOME,
              "/genre",
              "/venue",
              "/teamArtist",
              "/momentHistory",
              "/collections",
              "/collections/:id",
              "/tickets",
              "/tickets/:id",
              "/mytickets/:id",
              "/mycart",
              "/checkout",
              "/previeworder/:id",
              "/termscondition",
              "/policy",
              "/about"
            ]}
            exact
            component={SiteLayout}
          />
          <ProtectedRoute
            path={[
              "/account",
              "/account/seller",
              "/account/mytickets",
              "/account/mycollection",
              "/account/mycollection/:id",
              "/account/myoffers",
              "/account/myoffers/:id",
              "/account/mymessages",
              "/account/mymessages/:id",
              "/account/myoffers",
              "/account/myoffers/:id",
              "/account/myorders",
              "/account/myreviews",
              "/account/delete",
              "/account/report",
              "/account/report/:id"
            ]}
            component={DashboardLayout}
          />
        </Switch>
      </main>
      <footer className="container-fluid p-0 m-0">
        <FooterComponent />
      </footer>
    </React.Fragment>
  );
};


export default MainLayout;
