import React from "react";
import { Row, Col } from "react-bootstrap";
import OrderDetails from "./OrderDetails/OrderDetails";
import PriceDetails from "./PricingInfoComponent/PriceDetails";
import Alertmodel from "../../common/Alertmodel/Alertmodel";

const PreviewOrderDetailsView = ({
  order_no,
  order_date,
  expiry_date,
  expiry_status,
  payment_status,
  sub_orders,
  pricing_summary,
  shipping_address,
  billing_address,
  payment_summary,
  onApprovePayment,
  onCancelPayment,
  onErrorPayment,
  paypal_client_id,
  status,
  cancelOrder,
  cancelorderStatus,
  onCancelSubmit,
  orderExpiryDate,
  expiry_check
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={8}>
          <OrderDetails
            order_no={order_no}
            order_date={order_date}
            expiry_date={expiry_date}
            payment_status={payment_status}
            sub_orders={sub_orders}
            shipping_address={shipping_address}
            billing_address={billing_address}
          />
        </Col>
        <Col>
          <PriceDetails
          paypal_client_id={paypal_client_id}
            price_sub_total={pricing_summary.price_sub_total}
            tax={pricing_summary.delivery}
            delivery={pricing_summary.tax}
            grand_total={pricing_summary.total}
            order_no={order_no}
            payment_summary={payment_summary}
            onApprovePayment={onApprovePayment}
            onCancelPayment={onCancelPayment}
            onErrorPayment={onErrorPayment}
            orderStatus = {status}
            cancelOrder = {cancelOrder}
            orderExpiryDate={orderExpiryDate}
            expiry_status={expiry_status}
            onExpiryCheck ={expiry_check}
          />
        </Col>
      </Row>
      <Alertmodel 
      submit={onCancelSubmit}
      type={""}
      message={" Are you sure want cancel your order ?"}
      />
    </React.Fragment>
  );
};

export default PreviewOrderDetailsView;
