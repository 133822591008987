import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";

const InputFormControl = ({ onChange, value, placeholder, name }) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Form>
        <ReactBootstrap.Form.Group className="mb-3">
          <ReactBootstrap.Form.Control
            type="text"
						placeholder={placeholder}
						name={name}
						onChange = {(e) => {onChange(name,e)}}
						value = {value}
            autoComplete="off"
          />
        </ReactBootstrap.Form.Group>
      </ReactBootstrap.Form>
    </React.Fragment>
  );
}

InputFormControl.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

// Specifies the default values for props:
InputFormControl.defaultProps = {
  value: null
};

export default InputFormControl;
