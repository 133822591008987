export const SHOW_SPINNER = "[ui] show spinner";
export const HIDE_SPINNER = "[ui] hide spinner";

export const SHOW_MODAL = "[ui] show modal";
export const HIDE_MODAL = "[ui] hide modal";

export const showSpinner = (label = "global") => ({
  type: SHOW_SPINNER,
  payload: label,
});

export const hideSpinner = (label = "global") => ({
  type: HIDE_SPINNER,
  payload: label,
});

export const showModal = (label = "global") => ({
  type: SHOW_MODAL,
  payload: label,
});

export const hideModal = (label = "global") => ({
  type: HIDE_MODAL,
  payload: label,
});
