import React from "react";
import { Container, Col, Spinner, Button, Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import * as Icon from "react-bootstrap-icons";
import OwlCarousel from "react-owl-carousel";
import { useImmer } from "use-immer";
import "./CarouselComponent.css";
import { getTickets } from "../../../store/actions/tickets";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addItemToCart,
  setAddCartItemStatus,
} from "../../../store/actions/myCart";
import { showModal } from "../../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../../js/constants";

const CarouselComponent = ({
  tickets,
  ticketLoading,
  addToCart,
  isItemAddToCartLoading,
  user_id,
}) => {
  const initial_state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const [state, setState] = useImmer(initial_state);

  return (
    <section className="carousel-section py-4">
      <>
        <Container>
          <div>
            <Col>
              <div className="text-center py-4">
                <h2>Featured Tickets</h2>
                {/* <p className="content-carousel-section">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit
              </p> */}
              </div>
              {ticketLoading || isItemAddToCartLoading ? (
                <div className="d-flex justify-content-center align-items-center my-5">
                  <Spinner animation="border" role="status" />
                </div>
              ) : tickets.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center my-5">
                  <p>No Tickets found</p>
                </div>
              ) : (
                <OwlCarousel
                  items={3}
                  className="owl-theme owlCarouselImages carouselCard-section"
                  loop={false}
                  autoPlay={false}
                  nav={false}
                  dots={true}
                  margin={42}
                  responsive={state.responsive}
                >
                  {tickets.map((data) => (
                    <Card key={data.product_id}>
                      <div className="featuredImageContainer">
                        <Link to={"/tickets/" + data.product_id} target="_blank">
                          <div className="featuredImageDiv">
                            <img src={data.image.image_600} alt="imageOne" />
                          </div>
                        </Link>
                      </div>
                      <div className="card-ticket-title pt-3">
                        <Link to={"/tickets/" + data.product_id} target="_blank" className="textWrap">
                          <h6 className="mb-0">{data.listing_name}</h6>
                        </Link>
                        {data.isSale !== "0" && (
                          <Link as={Link} to={"/mycart"}>
                            <Badge className="badge-color">
                              <Icon.Cart2
                                color="#ffffff"
                                size={18}
                                onClick={() =>
                                  addToCart(data.product_id, data.listing_name)
                                }
                                disabled={!(user_id !== data.seller.id)}
                              />
                            </Badge>
                          </Link>
                        )

                        }
                      </div>
                      <hr />
                      <div className="ticket-title mb-3">
                        <span className="textWrap">{data.event_name}</span>
                        <span>
                          {data.isSale !== "0" ? (
                            <>
                              <i className="fa fa-usd" aria-hidden="true"></i> {data.cost}
                            </>
                          ) : (
                            <p>Not for sale</p>
                          )
                          }
                        </span>
                      </div>
                      <div className="ticket-date">
                        <span>{data.ticket_type}</span>
                        <span>{data.date}</span>
                      </div>
                    </Card>
                  ))}
                </OwlCarousel>
              )}
            </Col>
          </div>
        </Container>
      </>
    </section>
  );
};

export default CarouselComponent;
