import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import PlusIcon from "../../images/plus-icon.svg";
import MyCollectionView from "./MyCollection/MyCollectionView";
import MyCollectionAdd from "./CollectionAdd/CollectionAdd";
import {
  getCollections,
  getNextMyCollection,
  addCollection,
  setaddCollectionstatus,
  editCollection,
  deleteCollection
} from "../../store/actions/myCollection"
import {
  OPERATION_STATUS,
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../js/constants";
import { connect } from "react-redux";
const MyTicketPage = ({
  hasMore,
  page,
  getCollections,
  getNextMyCollection,
  collections,
  addCollection,
  editCollection,
  setaddCollectionstatus,
  addstatus,
  deleteCollection,
  collectionLoading,
  isActiveSeller
}) => {
  const [showMyTickets, setShowMyTickets] = useImmer(true);
  const [showAddForm, setShowAddForm] = useImmer(false);
  const [CollectionOperation, setCollectionOperation] = useImmer("Add");

  const init_ticket_data = {
    collection_id: "",
    collection_name: "",
    cost: "",
    isSale: "",
    option: {
      isSale: [{
        value: 1,
        label: "Sale",
      },
      {
        value: 0,
        label: "Show only",
      }]
    },
    error: {
      collection_name: "",
      cost: "",
      isSale: ""
    }
  };

  const [collectionFormData, setcollectionFormData] = useImmer(init_ticket_data);
  // Collection page view 
  const handleShowAddForm = () => {
    setShowMyTickets(false);
    setcollectionFormData(init_ticket_data);
    setCollectionOperation("Add");
    setShowAddForm(true);
  };

  const handleShowMyTickets = () => {
    setcollectionFormData(init_ticket_data);
    setShowAddForm(false);
    setShowMyTickets(true);
  };

  const handleAddTicketSuccess = () => {
    setShowAddForm(false);
    setShowMyTickets(true);
  };

  const handleTicketEdit = (collection) => {
    console.log(collection)
    setCollectionOperation("Edit");
    setcollectionFormData((draft) => {
      draft.collection_id = collection.id;
      draft.collection_name = collection.collection_name;
      draft.cost = collection.cost;
      draft.isSale = {
          value:collection.isSale,
          label:collection.isSale == "1" ? "Sale":"Show only"
      }
    });
    setShowMyTickets(false);
    setShowAddForm(true);
  };

  const nextPage = () => {
    let data = {
      page: page
    };
    getNextMyCollection(data);
  };

  useEffect(() => {
    getCollections()
  }, [])

  const onChangeValue = (name, e) => {
   
    switch (name) {
      case "collection_name":
      case "cost":
        setcollectionFormData((draft) => {
          draft[name] = e.target.value;
        });
        break;
      case "isSale":
        setcollectionFormData((draft) => {
          draft[name] = e;
        });
        break;
    }

  }
  const handleSubmit = () => {
    if (collectionFormData.collection_name.length === 0) {
      setcollectionFormData((draft) => {
        draft.error.collection_name = "Please enter the collection name.";
      });
      return false;
    } else {
      setcollectionFormData((draft) => {
        draft.error.collection_name = "";
      });
    }
    if (collectionFormData.collection_name.length > 255) {
      setcollectionFormData((draft) => {
        draft.error.collection_name = `Collection name length should not exceed 255 characters.`;
      });
      return false;
    } else {
      setcollectionFormData((draft) => {
        draft.error.collection_name = "";
      });
    }
    let isSale = collectionFormData.isSale
    ? collectionFormData.isSale.value
    : "";

    if (isSale.length === 0) {
      setcollectionFormData((draft) => {
        draft.error.isSale = "Please select the view type.";
      });
      return false;
    } else {
      setcollectionFormData((draft) => {
        draft.error.isSale = "";
      });
    }
 // Ticket cost validation
 let cost = collectionFormData.cost ? collectionFormData.cost :"";
 if (collectionFormData.isSale.value === 1 && cost.length === 0) {
  setcollectionFormData((draft) => {
     draft.error.cost = "Please enter the ticket price.";
   });
   return false;
 } else {
  setcollectionFormData((draft) => {
     draft.error.cost = "";
   });
 }
    let operation = CollectionOperation;
    if (operation === "Add") {
      let data = {
        collection_name: collectionFormData.collection_name,
        isSale:collectionFormData.isSale.value,
        cost: collectionFormData.cost
      }
    addCollection(data)
    } else if (operation === "Edit") {
      let data = {
        collection_id: collectionFormData.collection_id,
        collection_name: collectionFormData.collection_name,
        isSale:collectionFormData.isSale.value,
        cost: collectionFormData.cost
      }
      editCollection(data)
    }
  }
  useEffect(() => {
    if (addstatus) {
      handleAddTicketSuccess();
      setaddCollectionstatus(false)
    }
  }, [addstatus])
  const onDelete = (id) => {
    let data = {
      collection_id: id
    }
    deleteCollection(data)
  }
  return (
    <React.Fragment>
      <div className="my-4">
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} md={6}>
            <h5>My Collections</h5>
          </ReactBootstrap.Col>
          {showMyTickets && isActiveSeller &&(
            <ReactBootstrap.Col xs={12} md={6} className="text-right">
              <ReactBootstrap.Button
                className="btnAddTicket"
                onClick={handleShowAddForm}
              >
                <span>
                  <img src={PlusIcon} alt="PlusIcon" />
                </span>
                Add Collection
              </ReactBootstrap.Button>
            </ReactBootstrap.Col>
          )}
        </ReactBootstrap.Row>
        <ReactBootstrap.Dropdown.Divider />
      </div>
      {showMyTickets &&
        <>
          {collectionLoading ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <ReactBootstrap.Spinner animation="border" role="status" />
            </div>
          ) : (
            <MyCollectionView
              collections={collections}
              hasMore={hasMore}
              nextPage={nextPage}
              onEdit={handleTicketEdit}
              onDelete={onDelete}
            />
          )}


        </>

      }
      {showAddForm && (
        <MyCollectionAdd
          onCancel={handleShowMyTickets}
          onSuccess={handleAddTicketSuccess}
          handleSubmit={handleSubmit}
          formData={collectionFormData}
          operation={CollectionOperation}
          onChangeValue={onChangeValue}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.user ? state.user.id : null,
    collections: state.collection.collections,
    hasMore: state.collection.hasMore,
    page: state.collection.nextPage,
    addstatus: state.collection.addstatus,
    collectionLoading:
      "mycollection_init" in state.ui.loaders
        ? state.ui.loaders["mycollection_init"]
        : true,
    isActiveSeller:
    state.permissions.length > 0
      ? state.permissions.some(
          (permission) =>
            permission.permission === USER_PERMISSIONS.SELL &&
            permission.status === USER_PERMISSIONS_STATES.ACTIVE
        )
      : false
  };
};

export default connect(mapStateToProps, {
  getCollections,
  getNextMyCollection,
  addCollection,
  setaddCollectionstatus,
  editCollection,
  deleteCollection
})(MyTicketPage);
