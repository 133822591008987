import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";
import "./MomentsHistoryCardComponent.css";

const MomentsHistoryCardComponent = ({
  name,
  significance,
  genre,
  sub_genre,
  venue,
  date,
  teams,
  artists,
}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Card className="my-4 cardMomentHistory">
        <ReactBootstrap.Card.Body>
          <div className="mommentHistoryTitle">
            <h5 className="font-weight-bold">{name}</h5>
            {significance && <span>{significance}</span>}
          </div>
          <ReactBootstrap.Dropdown.Divider />
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={12} md={8}>
              {teams.length > 0 && (
                <div className="mommentHistoryContent">
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <span>{teams.join(", ")}</span>
                </div>
              )}
              {artists.length > 0 && (
                <div className="mommentHistoryContent">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>{artists.join(", ")}</span>
                </div>
              )}
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} md={4}>
              <div className="mommentHistoryContent">
                {genre && (
                  <>
                    <i className="fa fa-tag" aria-hidden="true"></i>
                      <span>{genre}</span>
                      <span>|</span>
                      <span>{sub_genre ? sub_genre : ""}</span>
                  </>
                )}
              </div>
              <div className="mommentHistoryContent">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span className="eventLocationSpan">{venue}</span>
              </div>
              <div className="mommentHistoryContent">
                <i className="fa fa-calendar" aria-hidden="true"></i>
                <span className="eventDateSpan">{date}</span>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Card.Body>
      </ReactBootstrap.Card>
    </React.Fragment>
  );
};

MomentsHistoryCardComponent.propTypes = {
  name: PropTypes.string.isRequired,
  significance: PropTypes.string,
  genre: PropTypes.string,
  sub_genre: PropTypes.string,
  venue: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  teams: PropTypes.array,
  artists: PropTypes.array,
};

export default MomentsHistoryCardComponent;
