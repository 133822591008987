import axios from "axios";
import * as configs from "../../../js/constants";
import qs from "qs";
import { API_REQUEST, apiStart, apiEnd } from "../../actions/api";
import { userProtectAPI } from "../../../apis/userProtectedApis.instance"

const userAPI = axios.create({
  baseURL: configs.API_BASE_URL,
  withCredentials: true,
});

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type.includes(API_REQUEST)) {
    const { feature, isProtected } = action.meta;
    const { method, url, params, body, onSuccess, onError } = action.payload;
    var config = {
      method: method,
      url: url,
    };
    if (params) {
      config.params = params;
      config.paramsSerializer = (params) => {
        return qs.stringify(params);
      };
    }
    if (body) {
      config.data = body;
    }
    if (feature) {
      dispatch(apiStart(feature));
    }
    var api = isProtected ? userProtectAPI :  userAPI
    // Axios request
    api
      .request(config)
      .then(({ data }) => {
        dispatch(onSuccess(data));
      })
      .catch((error) => {
        // dispatch(apiError(error));
        dispatch(onError(error));

        // if (error.response && error.response.status === 403) {
        //   dispatch(accessDenied(window.location.pathname));
        // }
      })
      .finally(() => {
        if (feature) {
          dispatch(apiEnd(feature));
        }
      });
  }
};

export default apiMiddleware;
