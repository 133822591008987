import {
 GET_CONTACT_ADDRESS ,
 GET_CONTACT_ADDRESS_SUCCESS, 
 GET_CONTACT_ADDRESS_ERROR,
 getContactAddress,
 getContactAddressSuccess,
 getContactAddressError,
 setContactAddress,

GET_BILLING_ADDRESS, 
GET_BILLING_ADDRESS_SUCCESS,
GET_BILLING_ADDRESS_ERROR,
getBillingAddress,
getBillingAddressSuccess,
getBillingAddressError,
setBillingAddress,
setFullBillngAddress,

GET_SHIPPING_ADDRESS,
GET_SHIPPING_ADDRESS_SUCCESS,
GET_SHIPPING_ADDRESS_ERROR,
getShippingAddress,
getShippingAddressSuccess,
getShippingAddressError,
setShippingAddress,
setFullShippingAddress,

ADD_ADDRESS,
ADD_ADDRESS_SUCCESS,
ADD_ADDRESS_ERROR,
addAddressSuccess,
addAddressError,
AddressStatus,

EDIT_ADDRESS,
EDIT_ADDRESS_SUCCESS,
EDIT_ADDRESS_ERROR,
editAddressSuccess,
editAddressError,

DELETE_ADDRESS,
DELETE_ADDRESS_SUCCESS,
DELETE_ADDRESS_ERROR ,
deleteAddressSuccess,
deleteAddressError,
AddressDeleteStatus,

SET_DEFAULT_ADDRESS,
SET_DEFAULT_ADDRESS_SUCCESS ,
SET_DEFAULT_ADDRESS_ERROR,
setDefaultKeySuccess,
setDefaultKeyError


  } from "../../actions/addressAction";
  // import {addCheckoutBillingAddress} from "../../actions/checkout"
  import { showSpinner, hideSpinner } from "../../actions/ui";
  import { apiRequest } from "../../actions/api";
  import {
    notification_types,
    OPERATION_STATUS,
    USER_PERMISSIONS,
    USER_STATES,
    USER_PERMISSIONS_STATES
  } from "../../../js/constants";
  import { setNotification } from "../../actions/notification";
  
  // FOR CONTACT
  export const getContactAddressFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_CONTACT_ADDRESS) {
      dispatch(
        apiRequest(
          "GET",
          "/user/account/address.php",
          action.payload,
          null,
          getContactAddressSuccess,
          getContactAddressError,
          true,
          "[address]"
        )
      );
      dispatch(showSpinner("address"));
    }
  
  };
  
  // on successful fetch, process the books data
  export const processgetContactAddressFlowCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === GET_CONTACT_ADDRESS_SUCCESS) {
      let Address = action.payload.data.items
      dispatch(setContactAddress(Address));
      dispatch(hideSpinner("address"));
    }
  
    if (action.type === GET_CONTACT_ADDRESS_ERROR) {
      dispatch(hideSpinner("address"));
    }
  };
  

    // FOR BILLING
    export const getBillingAddressFlow = ({ dispatch }) => (next) => (action) => {
      next(action);
    
      if (action.type === GET_BILLING_ADDRESS) {
        dispatch(
          apiRequest(
            "GET",
            "/user/account/address.php",
            action.payload,
            null,
            getBillingAddressSuccess,
            getBillingAddressError,
            true,
            "[address]"
          )
        );
        dispatch(showSpinner("address"));
      }
    
    };
    
    // on successful fetch, process the books data
    export const processgetBillingAddressFlowCollection = ({ dispatch }) => (next) => (
      action
    ) => {
      next(action);
    
      if (action.type === GET_BILLING_ADDRESS_SUCCESS) {
        let Address = action.payload.data.items
        dispatch(setBillingAddress(Address));
        dispatch(hideSpinner("address"));
      }
    
      if (action.type === GET_BILLING_ADDRESS_ERROR) {
        dispatch(hideSpinner("address"));
      }
    };
 // FOR SHIPPING
 export const getShippingAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SHIPPING_ADDRESS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/account/address.php",
        action.payload,
        null,
        getShippingAddressSuccess,
        getShippingAddressError,
        true,
        "[address]"
      )
    );
    dispatch(showSpinner("address"));
  }

};

// on successful fetch, process the books data
export const processgetShippingAddressFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === GET_SHIPPING_ADDRESS_SUCCESS) {
    let Address = action.payload.data.items
    var newbillingAddress = Address.filter(function (el)
            {
            return el.is_primary == 1
            })
    dispatch(setShippingAddress(newbillingAddress));
    dispatch(setFullShippingAddress(Address));
    dispatch(hideSpinner("address"));
  }

  if (action.type === GET_SHIPPING_ADDRESS_ERROR) {
    dispatch(hideSpinner("address"));
  }
};

// ADD ADDRESS
 export const AddAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_ADDRESS) {
    dispatch(AddressStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/account/address.php",
        null,
        action.payload,
        addAddressSuccess,
        addAddressError,
        true,
        "[address]"
      )
    );
    dispatch(showSpinner("address"));
  }

};

// on successful fetch, process the books data
export const processAddAddressFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === ADD_ADDRESS_SUCCESS) {
    dispatch(AddressStatus(OPERATION_STATUS.SUCCESS));
    dispatch(
      setNotification(
        "Address Added",
        "Address_added",
        notification_types.success
      )
    );const user = {
      type: "CONTACT",
    };
    dispatch(getContactAddress(user));
    const data = {
      type: "BILL",
    };
    dispatch(getBillingAddress(data));
    const userData = {
      type: "DELIVERY",
    };
    dispatch(getShippingAddress(userData));
    
    dispatch(hideSpinner("address"));
  }

  if (action.type === ADD_ADDRESS_ERROR) {
    dispatch(AddressStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("address"));
  }
};


// EDIT ADDRESS
export const EditAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === EDIT_ADDRESS) {
    dispatch(AddressStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "/user/account/address.php",
        null,
        action.payload,
        editAddressSuccess,
        editAddressError,
        true,
        "[address]"
      )
    );
    dispatch(showSpinner("address"));
  }

};

// on successful fetch, process the books data
export const processEditAddressFlowCollection = ({ dispatch, getState}) => (next) => (
  action
) => {
  next(action);

  if (action.type === EDIT_ADDRESS_SUCCESS) {
    dispatch(AddressStatus(OPERATION_STATUS.SUCCESS));
    const addAddress = {
      checkout_id: getState().checkout.checkout_id,
      address_id: action.payload.data.address_id,
    };
    // dispatch(addCheckoutBillingAddress(addAddress))
    dispatch(
      setNotification(
        "Address Changed",
        "Address_update",
        notification_types.success
      )
    );const user = {
      type: "CONTACT",
    };
    dispatch(getContactAddress(user));
    const data = {
      type: "BILL",
    };
    dispatch(getBillingAddress(data));
    const userData = {
      type: "DELIVERY",
    };
    dispatch(getShippingAddress(userData));
    
    dispatch(hideSpinner("address"));
  }

  if (action.type === EDIT_ADDRESS_ERROR) {
    dispatch(AddressStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("address"));
  }
};

// DELETE ADDRESS
export const DeleteAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === DELETE_ADDRESS) {
    dispatch(AddressDeleteStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "DELETE",
        "/user/account/address.php",
        null,
        action.payload,
        deleteAddressSuccess,
        deleteAddressError,
        true,
        "[address]"
      )
    );
    dispatch(showSpinner("address"));
  }

};

// on successful fetch, process the books data
export const processDeleteAddressFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === DELETE_ADDRESS_SUCCESS) {
    dispatch(AddressDeleteStatus(OPERATION_STATUS.SUCCESS));
    dispatch(
      setNotification(
        "Address Deleted",
        "Address_Delete",
        notification_types.success
      )
    );
    const userData = {
      type: "DELIVERY",
    };
    dispatch(getShippingAddress(userData));
    
    dispatch(hideSpinner("address"));
  }

  if (action.type === DELETE_ADDRESS_ERROR) {
    dispatch(AddressDeleteStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("address"));
  }
};

// SET DEFAULT ADDRESS
export const setDefaultAddressFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SET_DEFAULT_ADDRESS) {
    dispatch(
      apiRequest(
        "PUT",
        "/user/account/address.php?action=set_default_shipping",
        null,
        action.payload,
        setDefaultKeySuccess,
        setDefaultKeyError,
        true,
        "[address]"
      )
    );
    dispatch(showSpinner("address"));
  }
}
// on successful fetch, process the books data
export const processsetDefaultAddressFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === SET_DEFAULT_ADDRESS_SUCCESS) {
    dispatch(
      setNotification(
        "Default Address Changed",
        "Address_Changed",
        notification_types.success
      )
    );
    const userData = {
      type: "DELIVERY",
    };
    dispatch(getShippingAddress(userData));
    
    dispatch(hideSpinner("address"));
  }

  if (action.type === SET_DEFAULT_ADDRESS_ERROR) {
    dispatch(hideSpinner("address"));
  }
};

  export const addressMdl = [
      getContactAddressFlow,
      processgetContactAddressFlowCollection,
      getBillingAddressFlow,
      processgetBillingAddressFlowCollection,
      getShippingAddressFlow,
      processgetShippingAddressFlowCollection,
      AddAddressFlow,
      processAddAddressFlowCollection,
      EditAddressFlow,
      processEditAddressFlowCollection,
      DeleteAddressFlow,
      processDeleteAddressFlowCollection,
      setDefaultAddressFlow,
      processsetDefaultAddressFlowCollection

    ];