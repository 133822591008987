import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import Avatar from "../../images/avatar.png";
import { connect } from "react-redux";
import OfferDetailsView from "./OfferDetailsView";
import OfferForm from "./OfferForm/OfferForm";
import {
  OFFER_STATES,
  OFFER_ACTIONS,
  notification_types,
  PRICE_REGEX,
  OFFER_TYPES,
  FLAGGED_TERM,
} from "../../js/constants";
import { formattedMessageDisplayDateTime } from "../../js/utils";
import { OPERATION_STATUS } from "../../js/constants";
import { Card, Collapse } from "react-bootstrap";
import { api as UserAPI } from "../../apis/userProtectedApis.instance";
import { setNotification } from "../../store/actions/notification";
import { addItemToCart, setAddCartItemStatus } from "../../store/actions/myCart";

const OfferDetailsPage = ({
  seller_items,
  offer_detail,
  loggedUserId,
  setNotification,
  showOfferDetailBody,
  addItemToCart,
  addItemToCartStatus,
  setAddCartItemStatus
}) => {
  const [offer, setOffer] = useImmer({
    data: null,
    form: {
      id: "",
      offer_no: "",
      message: "",
      price: "",
      seller_id: "",
      items: [],
      availableOfferItems: [],
      currentOfferItem: null,
      operation: "Update",
      loading: false,
      error: {
        message: "",
        currentOfferItem: "",
        price: "",
      },
    },
  });
  const [showBody, setShowBody] = useImmer(false);
  const [showOfferForm, setShowOfferForm] = useImmer(false);
  const [statusLoader, setStatusLoader] = useImmer(false);
  const [loader, setLoader] = useImmer(false);

  const handleOfferInputChange = (name, e) => {
    switch (name) {
      case "price":
        setOffer((draft) => {
          draft.form.price = e.target.value;
          if (e.target.value.length === 0) {
            draft.form.error.price = "Please enter the price.";
          } else {
            draft.form.error.price = "";
          }

          if (e.target.value.length > 20) {
            draft.form.error.price =
              "Please enter less than 20 characters for price.";
          } else {
            draft.form.error.price = "";
          }
          if (!PRICE_REGEX.test(e.target.value)) {
            draft.form.error.price = "Please enter valid price.";
          } else {
            draft.form.error.price = "";
          }
        });
        break;
      case "message":
        // html input handle
        setOffer((draft) => {
          if (e.target.value.length > 500) {
            draft.form.error.message =
              "Please enter less than 500 characters for message.";
          } else {
            draft.form.error.message = "";
          }
          draft.form.message = e.target.value;
        });
        break;
      case "currentOfferItem":
        setOffer((draft) => {
          draft.form[name] = e;
        });
        break;
      default:
        break;
    }
  };

  const handleOfferCancel = () => {
    if (offer.form.operation === "Update") {
      handleEdit(offer.form.offer_no, offer.form.id, false);
    }
    setOffer((draft) => {
      if (draft.form.operation === "Counter") {
        setShowOfferForm(false);
      }
      draft.form.id = "";
      draft.form.offer_no = "";
      draft.form.message = "";
      draft.form.price = "";
      draft.form.seller_id = "";
      draft.form.items = [];
      draft.form.currentOfferItem = null;
      draft.form.operation = "Update";
      draft.form.loading = false;
    });
  };

  const handleSelectItem = () => {
    setOffer((draft) => {
      if (
        draft.form.currentOfferItem &&
        !draft.form.items.some(
          (item) => item.product_id === draft.form.currentOfferItem.product_id
        )
      ) {
        draft.form.items.push({
          product_id: draft.form.currentOfferItem.product_id,
          name: draft.form.currentOfferItem.name,
          cost: draft.form.currentOfferItem.cost,
          image: draft.form.currentOfferItem.image,
        });
        let index = draft.form.availableOfferItems.findIndex(
          (item) => item.product_id === draft.form.currentOfferItem.product_id
        );
        if (index > -1) {
          draft.form.availableOfferItems[index].isDisabled = true;
        }
        draft.form.currentOfferItem = null;
        draft.form.error.currentOfferItem = "";
      }
    });
  };

  const handleRemoveItem = (id) => {
    setOffer((draft) => {
      let index = draft.form.items.findIndex((item) => item.product_id === id);
      if (index > -1) {
        draft.form.items.splice(index, 1);
        let option_index = draft.form.availableOfferItems.findIndex(
          (item) => item.product_id === id
        );
        if (option_index > -1) {
          draft.form.availableOfferItems[option_index].isDisabled = false;
        }
      }
    });
  };

  const getOfferActions = (is_user_created, is_seller, status) => {
    let actions = {
      isAccept: false,
      isReject: false,
      isCancel: false,
      isEdit: false,
      isCounter: false,
      isCart: false,
    };

    if (is_seller) {
      // Current user of the offer is seller
      // Latest offer status is open
      if (status === OFFER_STATES.OPEN) {
        if (is_user_created) {
          // created by user so can able to edit
          actions.isEdit = true;
        } else {
          // Not created by user so seller can accept, reject or counter offer
          actions.isAccept = true;
          actions.isReject = true;
          actions.isCounter = true;
        }
      } else if (status === OFFER_STATES.ACCEPTED_BUYER) {
        // Buyer accepted the offer so seller should be able to accept
        actions.isAccept = true;
      } else if (status === OFFER_STATES.ACCEPTED_SELLER) {
        // Seller accepted the offer so seller can able to cancel the offer
        actions.isCancel = true;
      }
    } else {
      // Current user of the offer is buyer
      // Latest offer status is open
      if (status === OFFER_STATES.OPEN) {
        if (is_user_created) {
          // created by user so can able to edit
          actions.isEdit = true;
        } else {
          // Not created by user so buyer can accept or counter offer
          actions.isAccept = true;
          actions.isCounter = true;
        }
      } else if (status === OFFER_STATES.ACCEPTED_SELLER) {
        // Seller accepted the offer so buyer can able to add the offer to cart
        actions.isCart = true;
      }
    }

    return actions;
  };

  const processOfferDetails = (data) => {
    let result = {};
    let latest_offer = null;
    if (data.counter_offers.length > 0) {
      // Counter offers exist
      let latest_counter_offer =
        data.counter_offers[data.counter_offers.length - 1];

      latest_offer = {
        id: latest_counter_offer.offer_id,
        type: latest_counter_offer.offer_type,
        status: latest_counter_offer.offer_status,
        price: latest_counter_offer.offer_price,
        message: latest_counter_offer.offer_message,
        is_logged_user_created:
          latest_counter_offer.created_by.id === loggedUserId,
        created_by: {
          id: latest_counter_offer.created_by.id,
          name: latest_counter_offer.created_by.name,
          avatar: {
            image_50: latest_counter_offer.created_by.avatar
              ? latest_counter_offer.created_by.avatar
              : Avatar,
            image_200: latest_counter_offer.created_by.avatar
              ? latest_counter_offer.created_by.avatar
              : Avatar,
            image_400: latest_counter_offer.created_by.avatar
              ? latest_counter_offer.created_by.avatar
              : Avatar,
          },
          is_admin: latest_counter_offer.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(
          latest_counter_offer.created_at
        ),
        items: latest_counter_offer.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
      };
    } else {
      latest_offer = {
        id: data.offer_id,
        type: data.offer_type,
        status: data.offer_status,
        price: data.offer_price,
        message: data.offer_message,
        is_logged_user_created: data.created_by.id === loggedUserId,
        created_by: {
          id: data.created_by.id,
          name: data.created_by.name,
          avatar: {
            image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
          },
          is_admin: data.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(data.created_at),
        items: data.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
      };
    }

    let actions = {
      isAccept: false,
      isReject: false,
      isCancel: false,
      isEdit: false,
      isCounter: false,
      isCart: false,
    };

    // if (data.seller_details.is_admin) {
    let is_seller = data.seller_details.seller_id === loggedUserId;
    let processed_Actions = getOfferActions(
      latest_offer.is_logged_user_created,
      is_seller,
      latest_offer.status
    );

    actions.isEdit = processed_Actions.isEdit;
    actions.isAccept = processed_Actions.isAccept;
    actions.isReject = processed_Actions.isReject;
    actions.isCounter = processed_Actions.isCounter;
    actions.isCancel = processed_Actions.isCancel;
    actions.isCart = processed_Actions.isCart;
    // }

    let offer_details = [
      {
        id: data.offer_id,
        type: data.offer_type,
        status: data.offer_status,
        price: data.offer_price,
        message: data.offer_message,
        is_logged_user_created: data.created_by.id === loggedUserId,
        created_by: {
          id: data.created_by.id,
          name: data.created_by.name,
          avatar: {
            image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
          },
          is_admin: data.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(data.created_at),
        items: data.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
        isEdit: actions.isEdit && data.offer_id === latest_offer.id,
        showEdit: false,
      },
    ];

    data.counter_offers.forEach((offer) => {
      offer_details.push({
        id: offer.offer_id,
        type: offer.offer_type,
        status: offer.offer_status,
        price: offer.offer_price,
        message: offer.offer_message,
        is_logged_user_created: offer.created_by.id === loggedUserId,
        created_by: {
          id: offer.created_by.id,
          name: offer.created_by.name,
          avatar: {
            image_50: offer.created_by.avatar
              ? offer.created_by.avatar
              : Avatar,
            image_200: offer.created_by.avatar
              ? offer.created_by.avatar
              : Avatar,
            image_400: offer.created_by.avatar
              ? offer.created_by.avatar
              : Avatar,
          },
          is_admin: offer.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(offer.created_at),
        items: offer.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
        isEdit: actions.isEdit && offer.offer_id === latest_offer.id,
        showEdit: false,
      });
    });

    result.offer_no = data.offer_no;
    result.status = latest_offer.status;
    result.price = latest_offer.price;
    result.offer_details = offer_details;
    result.latest_offer = latest_offer;
    result.actions = actions;
    result.seller = data.seller_details;
    result.buyer = data.buyer_details;
    result.is_seller_offer = data.seller_details.seller_id === loggedUserId;
    return result;
  };

  const handleEdit = (offer_no, offer_id, flag) => {
    setOffer((draft) => {
      let offer_index = draft.data.offer_details.findIndex(
        (offer) => offer.id === offer_id
      );
      if (offer_index > -1) {
        draft.data.offer_details[offer_index].showEdit = flag;
        let editOfferDetails = draft.data.offer_details[offer_index];
        draft.form.id = editOfferDetails.id;
        draft.form.offer_no = draft.data.offer_no;
        draft.form.message = editOfferDetails.message
          ? editOfferDetails.message
          : "";
        draft.form.price = editOfferDetails.price;
        draft.form.seller_id = draft.data.seller.seller_id;
        draft.form.items = editOfferDetails.items.map((item) => {
          return {
            product_id: item.product_id,
            name: item.name,
            cost: item.price,
            image: item.image,
          };
        });
        editOfferDetails.items.forEach((o_item) => {
          let index = draft.form.availableOfferItems.findIndex(
            (item) => item.product_id === o_item.product_id
          );
          if (index > -1) {
            draft.form.availableOfferItems[index].isDisabled = true;
          }
        });
        draft.form.currentOfferItem = null;
        draft.form.operation = "Update";
      }
      setShowOfferForm(flag);
    });
  };

  const handleOfferSubmit = async (e) => {
    e.preventDefault();

    let offer_id = offer.form.id;
    let offer_no = offer.form.offer_no;
    let message = offer.form.message;
    let price = offer.form.price;
    // var seller_id = this.state.offer.seller_id;
    let items = offer.form.items;

    if (price.length === 0) {
      setOffer((draft) => {
        draft.form.error.price = "Please enter the price.";
      });
      return false;
    } else {
      setOffer((draft) => {
        draft.form.error.price = "";
      });
    }

    if (price.length > 20) {
      setOffer((draft) => {
        draft.form.error.price =
          "Please enter less than 20 characters for price.";
      });
      return false;
    } else {
      setOffer((draft) => {
        draft.form.error.price = "";
      });
    }

    if (!PRICE_REGEX.test(price)) {
      setOffer((draft) => {
        draft.form.error.price = "Please enter valid price.";
      });
      return false;
    } else {
      setOffer((draft) => {
        draft.form.error.price = "";
      });
    }

    if (message.length > 500) {
      setOffer((draft) => {
        draft.form.error.message =
          "Please enter less than 500 characters for message.";
      });
      return false;
    } else {
      setOffer((draft) => {
        draft.form.error.message = "";
      });
    }

    if (items.length === 0) {
      setOffer((draft) => {
        draft.form.error.currentOfferItem = "Please select atleast one ticket.";
      });
      return false;
    } else {
      setOffer((draft) => {
        draft.form.error.currentOfferItem = "";
      });
    }

    let editOffer = {
      offer_no: offer_no,
      offer_price: parseFloat(price),
      offer_items: items.map((item) => {
        return {
          product_id: item.product_id,
          product_listing_name: item.name,
        };
      }),
    };
    if (message.length > 0) {
      editOffer.message = message;
    }
    setOffer((draft) => {
      draft.form.loading = true;
    });
    if (offer.form.operation === "Update") {
      editOffer.offer_id = offer_id;
      try {
        let add_response = await UserAPI.Offers.edit(editOffer);
        if (add_response.status === 200) {
          setNotification(
            "Offer updated successfully",
            "myoffers-create_offer",
            notification_types.success
          );
          // this.handleGenreCancel();
          handleOfferEditSuccessResponse(add_response.data.data);
          handleEdit(offer_no, offer_id, false);
          setOffer((draft) => {
            draft.form.loading = false;
          });
          setShowOfferForm(false);
        }
      } catch (error) {
        let error_code = error.response.data.error.Code;
        let error_message = error.response.data.error.message;
        setNotification(
          error_message,
          "myoffers-create_offer",
          notification_types.error
        );
        if (error_code === FLAGGED_TERM.ERROR_CODE) {
          setNotification(
            FLAGGED_TERM.ERROR_MSG,
            "myoffers-create_offer",
            notification_types.error
          );
        }
        setOffer((draft) => {
          draft.form.loading = false;
        });
        // setNotification(
        //   add_error_msg,
        //   "myoffers-create_offer",
        //   notification_types.error
        // );
        return false;
      }
    } else if (offer.form.operation === "Counter") {
      editOffer.offer_type = OFFER_TYPES.COUNTER;
      try {
        let add_response = await UserAPI.Offers.counter(editOffer);
        if (add_response.status === 200) {
          setNotification(
            "Counter offer created successfully",
            "myoffers-create_offer",
            notification_types.success
          );
          handleOfferCounterSuccessResponse(add_response.data.data);
          handleEdit(offer_no, offer_id, false);
          setOffer((draft) => {
            draft.form.loading = false;
          });
          setShowOfferForm(false);
        }
      } catch (error) {
        let error_code = error.response.data.error.Code;
        let error_message = error.response.data.error.message;
        setNotification(
          error_message,
          "myoffers-create_offer",
          notification_types.error
        );
        if (error_code === FLAGGED_TERM.ERROR_CODE) {
          setNotification(
            FLAGGED_TERM.ERROR_MSG,
            "myoffers-create_offer",
            notification_types.error
          );
        }
        setOffer((draft) => {
          draft.form.loading = false;
        });
        // setNotification(
        //   add_error_msg,
        //   "myoffers-create_offer",
        //   notification_types.error
        // );
        return false;
      }
    }
  };

  const handleCounter = () => {
    setOffer((draft) => {
      draft.form.id = draft.data.latest_offer.id;
      draft.form.offer_no = draft.data.offer_no;
      draft.form.message = draft.data.latest_offer.message
        ? draft.data.latest_offer.message
        : "";
      draft.form.price = draft.data.latest_offer.price;
      draft.form.seller_id = draft.data.seller.seller_id;
      draft.form.items = draft.data.latest_offer.items.map((item) => {
        return {
          product_id: item.product_id,
          name: item.name,
          cost: item.price,
          image: item.image,
        };
      });
      draft.form.currentOfferItem = null;
      draft.form.operation = "Counter";
      draft.form.loading = false;
    });
    setShowOfferForm(true);
  };

  const handleStatus = async (action) => {
    let offer_body = {
      offer_no: offer.data.offer_no,
      offer_id: offer.data.latest_offer.id,
      status: action,
    };
    try {
      setStatusLoader(true);
      let response = await UserAPI.Offers.setStatus(offer_body);
      if (response.status === 204) {
        let updated_status = null;
        if (offer.data.is_seller_offer) {
          switch (action) {
            case OFFER_ACTIONS.ACCEPT:
              setNotification(
                "Offer accepted successfully",
                "myoffers-create_offer",
                notification_types.success
              );
              updated_status = OFFER_STATES.ACCEPTED_SELLER;
              break;
            case OFFER_ACTIONS.REJECT:
              setNotification(
                "Offer rejected successfully",
                "myoffers-create_offer",
                notification_types.success
              );
              updated_status = OFFER_STATES.REJECTED;
              break;
            case OFFER_ACTIONS.CANCEL:
              setNotification(
                "Offer cancelled successfully",
                "myoffers-create_offer",
                notification_types.success
              );
              updated_status = OFFER_STATES.CANCELLED;
              break;

            default:
              break;
          }
        } else {
          if (action === OFFER_ACTIONS.ACCEPT) {
            setNotification(
              "Offer accepted successfully",
              "myoffers-create_offer",
              notification_types.success
            );
            updated_status = OFFER_STATES.ACCEPTED_BUYER;
          }
        }

        handleOfferSetStatusSuccessResponse(
          offer_body.offer_id,
          updated_status
        );
        setStatusLoader(false);
      }
    } catch (error) {
      let add_error_msg = error.response.data.error.message;
      setStatusLoader(false);
      this.props.setNotify(add_error_msg);
      return false;
    }
  };

  const handleOfferSetStatusSuccessResponse = (offer_id, status) => {
    setOffer((draft) => {
      // Set latest status
      draft.data.status = status;
      // Set latest offer status
      draft.data.latest_offer.status = status;
      // Set latest counter offer status
      let offer_index = draft.data.offer_details.findIndex(
        (offer) => offer.id === offer_id
      );
      if (offer_index > -1) {
        draft.data.offer_details[offer_index].status = status;
      }

      let actions = {
        isAccept: false,
        isReject: false,
        isCancel: false,
        isEdit: false,
        isCounter: false,
        isCart: false,
      };

      let processed_Actions = getOfferActions(
        draft.data.latest_offer.is_logged_user_created,
        draft.data.is_seller_offer,
        status
      );

      actions.isEdit = processed_Actions.isEdit;
      actions.isAccept = processed_Actions.isAccept;
      actions.isReject = processed_Actions.isReject;
      actions.isCounter = processed_Actions.isCounter;
      actions.isCancel = processed_Actions.isCancel;
      actions.isCart = processed_Actions.isCart;

      // Set new actions
      draft.data.actions = actions;
    });
  };

  const handleOfferEditSuccessResponse = (data) => {
    setOffer((draft) => {
      draft.data.latest_offer.price = data.offer_price;
      draft.data.latest_offer.message = data.offer_message;
      draft.data.latest_offer.items = data.offer_items.map((offer_item) => {
        return {
          id: offer_item.id,
          product_id: offer_item.product_id,
          name: offer_item.product_listing_name,
          price: offer_item.price,
          image: offer_item.images[0],
        };
      });

      let offer_index = draft.data.offer_details.findIndex(
        (offer) => offer.id === data.offer_id
      );
      if (offer_index > -1) {
        draft.data.offer_details[offer_index].price = data.offer_price;
        draft.data.offer_details[offer_index].message = data.offer_message;
        draft.data.offer_details[offer_index].items = data.offer_items.map(
          (offer_item) => {
            return {
              id: offer_item.id,
              product_id: offer_item.product_id,
              name: offer_item.product_listing_name,
              price: offer_item.price,
              image: offer_item.images[0],
            };
          }
        );
      }
    });
  };

  const handleOfferCounterSuccessResponse = (data) => {
    setOffer((draft) => {
      let latest_offer = {
        id: data.offer_id,
        type: data.offer_type,
        status: data.offer_status,
        price: data.offer_price,
        message: data.offer_message,
        is_logged_user_created: data.created_by.id === loggedUserId,
        created_by: {
          id: data.created_by.id,
          name: data.created_by.name,
          avatar: {
            image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
          },
          is_admin: data.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(data.created_at),
        items: data.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
      };

      let actions = {
        isAccept: false,
        isReject: false,
        isCancel: false,
        isEdit: false,
        isCounter: false,
        isCart: false,
      };

      // if (data.seller_details.is_admin) {
      let processed_Actions = getOfferActions(
        latest_offer.is_logged_user_created,
        offer.data.is_seller_offer,
        latest_offer.status
      );

      actions.isEdit = processed_Actions.isEdit;
      actions.isAccept = processed_Actions.isAccept;
      actions.isReject = processed_Actions.isReject;
      actions.isCounter = processed_Actions.isCounter;
      actions.isCancel = processed_Actions.isCancel;
      actions.isCart = processed_Actions.isCart;
      // }

      let offer_detail = {
        id: data.offer_id,
        type: data.offer_type,
        status: data.offer_status,
        price: data.offer_price,
        message: data.offer_message,
        is_logged_user_created: data.created_by.id === loggedUserId,
        created_by: {
          id: data.created_by.id,
          name: data.created_by.name,
          avatar: {
            image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
            image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
          },
          is_admin: data.created_by.is_admin,
        },
        created_at: formattedMessageDisplayDateTime(data.created_at),
        items: data.offer_items.map((offer_item) => {
          return {
            id: offer_item.id,
            product_id: offer_item.product_id,
            name: offer_item.product_listing_name,
            price: offer_item.price,
            image: offer_item.images[0],
          };
        }),
        isEdit: actions.isEdit && data.offer_id === latest_offer.id,
        showEdit: false,
      };

      // Set new actions
      draft.data.actions = actions;
      // Set latest offer price
      draft.data.price = latest_offer.price;
      // Set latest status
      draft.data.status = latest_offer.status;
      // Set latest offer to current offer
      draft.data.latest_offer = latest_offer;
      // Add the counter offer to the existing offer details
      draft.data.offer_details.push(offer_detail);
    });
  };

  useEffect(() => {
    setShowBody(showOfferDetailBody);
  }, [showOfferDetailBody]);

  useEffect(() => {
    setLoader(true);
    setOffer((draft) => {
      draft.form.availableOfferItems = seller_items.map((item) => {
        return {
          value: item.product_id,
          label: item.name + ", $" + item.cost,
          product_id: item.product_id,
          name: item.name,
          cost: item.cost,
          image: item.image,
          isDisabled: false,
        };
      });
      draft.data = processOfferDetails(offer_detail);
    });
    setLoader(false);
  }, [seller_items, offer_detail]);

  const addCart = () => {
    setStatusLoader(true);
    var data = {
      type: "OFFER",
      offer: {
        offer_no: offer_detail.offer_no,
        offer_id: offer.data.latest_offer.id,
      },
    };
    addItemToCart(data);
  };

  useEffect(() => {
    if (addItemToCartStatus === OPERATION_STATUS.SUCCESS) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
      handleOfferSetStatusSuccessResponse(offer.data.latest_offer.id, OFFER_STATES.CART)
      setStatusLoader(false);
    } else if (addItemToCartStatus === OPERATION_STATUS.FAILURE) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
      setStatusLoader(false);
    }
  }, [addItemToCartStatus]);

  return (
    <React.Fragment>
      {loader ? (
        <p>Loading ...</p>
      ) : (
        <>
          {offer.data && (
            <OfferDetailsView
              offer_no={offer.data.offer_no}
              offer_details={offer.data.offer_details}
              status={offer.data.status}
              price={offer.data.price}
              actions={offer.data.actions}
              handleCounter={handleCounter}
              handleStatus={(action) => handleStatus(action)}
              handleEdit={handleEdit}
              isFormShowing={showOfferForm}
              isLoadingStatus={statusLoader}
              handleCart={addCart}
              showBody={showBody}
              handleShowBody={setShowBody}
            />
          )}
          {/* {showOfferForm && ( */}
          <Collapse in={showOfferForm}>
            <Card>
              <Card.Header>
                <h6 className="font-weight-bold">
                  {offer.form.operation} Offer
                </h6>
              </Card.Header>
              <Card.Body>
                <OfferForm
                  value={offer.form}
                  onChangeValue={handleOfferInputChange}
                  onCancel={handleOfferCancel}
                  onSubmit={handleOfferSubmit}
                  selectItem={handleSelectItem}
                  removeItem={handleRemoveItem}
                  error={offer.form.error}
                />
              </Card.Body>
            </Card>
          </Collapse>
          {/* )} */}
        </>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    loggedUserId: state.user ? state.user.id : null,
    addItemToCartStatus: state.cart.add_status

  };
};

export default connect(mapStateToProps, { setNotification, addItemToCart, setAddCartItemStatus })(
  OfferDetailsPage
);
