// FOR LOGIN
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
// FOR REGISTER
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const USER_REFRESH_REQUEST = 'USER_REFRESH_REQUEST';
export const USER_REFRESH_SUCCESS = 'USER_REFRESH_SUCCESS';
export const USER_REFRESH_ERROR = 'USER_REFRESH_ERROR';

export const USER_CHECKAUTH_REQUEST = 'USER_CHECKAUTH_REQUEST';
export const USER_CHECKAUTH_SUCCESS = 'USER_CHECKAUTH_SUCCESS';
export const USER_CHECKAUTH_ERROR = 'USER_CHECKAUTH_ERROR';

export const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';
// THIS FOR MAIL VALIDATION
export const USER_MAILVALIDATE_REQUEST = 'USER_MAILVALIDATE_REQUEST';
export const USER_MAILVALIDATE_SUCCESS = 'USER_MAILVALIDATE_SUCCESS';
export const USER_MAILVALIDATE_ERROR = 'USER_MAILVALIDATE_ERROR';
//THIS FOR FORGOT PASSWORD
export const USER_FORGOTPASS_REQUEST = 'USER_FORGOTPASS_REQUEST';
export const USER_FORGOTPASS_SUCCESS = 'USER_FORGOTPASS_SUCCESS';
export const USER_FORGOTPASS_ERROR = 'USER_FORGOTPASS_ERROR';
//THIS FOR RESET PASSWORD
export const USER_RESETPASS_REQUEST = 'USER_RESETPASS_REQUEST';
export const USER_RESETPASS_SUCCESS = 'USER_RESETPASS_SUCCESS';
export const USER_RESETPASS_ERROR = 'USER_RESETPASS_ERROR';
// FOR LOGOUT
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const USER_REGISTER_RESET_STATUS = 'USER_REGISTER_RESET_STATUS';
