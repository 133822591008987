import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import "./Room.css";

const Room = ({
  name,
  avatar,
  message,
  hasUnreadMessage,
  lastMessageSentByYou,
}) => {

  return (
    <React.Fragment>
      <ReactBootstrap.Card className="my-4 textBlack">
        <ReactBootstrap.Card.Body>
          <div className="d-flex flex-row flex-nowrap mx-0">
            <div className="d-flex flex-row flex-grow-1">
              <div className="mr-2">
                <img
                  src={avatar}
                  className="rounded-circle"
                  alt={name}
                  width="40"
                  height="40"
                />
              </div>
              <div>
                <div className="font-weight-bold text-ellipsis-wrap">
                  {name}
                </div>

                {message && (
                  <div className="text-ellipsis-wrap">
                    {lastMessageSentByYou
                      ? "You: " + message.message
                      : message.message}
                  </div>
                )}
              </div>
            </div>
            {hasUnreadMessage && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="hasUnreadMessagesActive"></div>
              </div>
            )}
          </div>
        </ReactBootstrap.Card.Body>
      </ReactBootstrap.Card>
    </React.Fragment>
  );
};

export default Room;
