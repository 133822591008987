import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useImmer } from "use-immer";
import OfferForm from "./OfferForm/OfferForm";
import { hideModal } from "../../../../store/actions/ui";
import {
  sendOffer,
  setSendOfferStatus,
} from "../../../../store/actions/myOffer";
import { connect } from "react-redux";
import {
  PRICE_REGEX,
  OPERATION_STATUS,
  OFFER_TYPES,
} from "../../../../js/constants";

import { useHistory } from "react-router-dom";

const CreateOffer = ({
  hideModal,
  show,
  seller_items,
  seller_id,
  selectedItems,
  sendOffer,
  setSendOfferStatus,
  sendOfferData,
  sendOfferStatus,
  isOfferSending,
}) => {
  const history = useHistory();
  let init_offer = {
    operation: "Create",
    message: "",
    price: "",
    seller_id: "",
    items: [],
    availableOfferItems: [],
    currentOfferItem: null,
    loading: false,
    error: {
      message: "",
      currentOfferItem: "",
      price: "",
    },
  };
  const [offerForm, setOfferForm] = useImmer(init_offer);

  useEffect(() => {
    setOfferForm((draft) => {
      draft.availableOfferItems = seller_items.map((item) => {
        return {
          value: item.product_id,
          label: item.name + ", $" + item.cost,
          product_id: item.product_id,
          name: item.name,
          cost: item.cost,
          image: item.image,
          isDisabled: selectedItems.some(
            (selected_item) => selected_item.product_id === item.product_id
          ),
        };
      });
      draft.seller_id = seller_id;
      draft.loading = isOfferSending;
      draft.items = selectedItems;
    });
  }, [seller_items, seller_id, selectedItems]);

  useEffect(() => {
    setOfferForm((draft) => {
      draft.loading = isOfferSending;
    });
  }, [isOfferSending]);

  const handleOfferInputChange = (name, e) => {
    switch (name) {
      case "price":
        setOfferForm((draft) => {
          draft.price = e.target.value;
          if (e.target.value.length === 0) {
            draft.error.price = "Please enter the price.";
          } else {
            draft.error.price = "";
          }

          if (e.target.value.length > 20) {
            draft.error.price =
              "Please enter less than 20 characters for price.";
          } else {
            draft.error.price = "";
          }
          if (!PRICE_REGEX.test(e.target.value)) {
            draft.error.price = "Please enter valid price.";
          } else {
            draft.error.price = "";
          }
        });
        break;
      case "message":
        // html input handle
        setOfferForm((draft) => {
          if (e.target.value.length > 500) {
            draft.error.message =
              "Please enter less than 500 characters for message.";
          } else {
            draft.error.message = "";
          }
          draft.message = e.target.value;
        });
        break;
      case "currentOfferItem":
        setOfferForm((draft) => {
          draft[name] = e;
        });
        break;
      default:
        break;
    }
  };

  const handleOfferCancel = () => {
    setOfferForm((draft) => {
      draft.message = "";
      draft.price = "";
      draft.seller_id = "";
      draft.items = [];
      draft.currentOfferItem = null;
      draft.loading = false;
    });
    hideModal("OfferView");
  };

  const handleSelectItem = () => {
    setOfferForm((draft) => {
      if (
        draft.currentOfferItem &&
        !draft.items.some(
          (item) => item.product_id === draft.currentOfferItem.product_id
        )
      ) {
        draft.items.push({
          product_id: draft.currentOfferItem.product_id,
          name: draft.currentOfferItem.name,
          cost: draft.currentOfferItem.cost,
          image: draft.currentOfferItem.image,
        });

        let index = draft.availableOfferItems.findIndex(
          (item) => item.product_id === draft.currentOfferItem.product_id
        );
        if (index > -1) {
          draft.availableOfferItems[index].isDisabled = true;
        }
        draft.currentOfferItem = null;
        draft.error.currentOfferItem = "";
      }
    });
  };

  const handleRemoveItem = (id) => {
    setOfferForm((draft) => {
      let index = draft.items.findIndex((item) => item.product_id === id);
      if (index > -1) {
        draft.items.splice(index, 1);
        let option_index = draft.availableOfferItems.findIndex(
          (item) => item.product_id === id
        );
        if (option_index > -1) {
          draft.availableOfferItems[option_index].isDisabled = false;
        }
      }
    });
  };

  const handleOfferSubmit = (e) => {
    e.preventDefault();
    if (offerForm.price.length === 0) {
      setOfferForm((draft) => {
        draft.error.price = "Please enter the price.";
      });
      return false;
    } else {
      setOfferForm((draft) => {
        draft.error.price = "";
      });
    }

    if (offerForm.price.length > 20) {
      setOfferForm((draft) => {
        draft.error.price = "Please enter less than 20 characters for price.";
      });
      return false;
    } else {
      setOfferForm((draft) => {
        draft.error.price = "";
      });
    }

    if (!PRICE_REGEX.test(offerForm.price)) {
      setOfferForm((draft) => {
        draft.error.price = "Please enter valid price.";
      });
      return false;
    } else {
      setOfferForm((draft) => {
        draft.error.price = "";
      });
    }

    if (offerForm.message.length > 500) {
      setOfferForm((draft) => {
        draft.error.message =
          "Please enter less than 500 characters for message.";
      });
      return false;
    } else {
      setOfferForm((draft) => {
        draft.error.message = "";
      });
    }

    if (offerForm.items.length === 0) {
      setOfferForm((draft) => {
        draft.error.currentOfferItem = "Please select atleast one ticket.";
      });
      return false;
    } else {
      setOfferForm((draft) => {
        draft.error.currentOfferItem = "";
      });
    }

    let data = {
      offer_type: OFFER_TYPES.OFFER,
      offer_price: parseFloat(offerForm.price),
      seller_id: offerForm.seller_id,
      offer_items: offerForm.items.map((item) => {
        return {
          product_id: item.product_id,
          product_listing_name: item.name,
        };
      }),
    };

    if (offerForm.message.length > 0) {
      data.message = offerForm.message;
    }

    sendOffer(data);
  };

  useEffect(() => {
    if (sendOfferStatus === OPERATION_STATUS.SUCCESS) {
      setOfferForm(init_offer);
      setSendOfferStatus(OPERATION_STATUS.YET_TO_START);
      hideModal("OfferView");
      history.push({
        pathname: "/account/myoffers/" + sendOfferData.offer_no,
      });
    } else if (sendOfferStatus === OPERATION_STATUS.FAILURE) {
      setSendOfferStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [sendOfferStatus]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={() => hideModal("OfferView")}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="font-weight-bold">Create Offer</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OfferForm
            value={offerForm}
            onChangeValue={handleOfferInputChange}
            onCancel={handleOfferCancel}
            onSubmit={handleOfferSubmit}
            selectItem={handleSelectItem}
            removeItem={handleRemoveItem}
            error={offerForm.error}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.ui.activeModal === "OfferView",
    selectedItems: state.myOffer.currentOfferItems,
    sendOfferStatus: state.myOffer.sendOffer.status,
    sendOfferData: state.myOffer.sendOffer.data,
    isOfferSending: state.myOffer.sendOffer.status === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, {
  hideModal,
  sendOffer,
  setSendOfferStatus,
})(CreateOffer);
