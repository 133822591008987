import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import "./CollectionAdd.css";
import { connect } from "react-redux";

import CollectionForm from "./CollectionForm/CollectionForm";

const TicketAdd = ({
  onCancel,
  onSuccess,
  operation,
  handleSubmit,
  formData,
  onChangeValue
}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Container className="my-4">
          <CollectionForm
            value={formData}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            operation={operation}
            onChangeValue={onChangeValue}
          />
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps,null)(TicketAdd);
