export const GET_COLLECTIONS                      = '[collections] GET Collections';
export const GET_COLLECTIONS_SUCCESS              = '[collections] GET Collections success';
export const GET_COLLECTIONS_ERROR                = '[collections] GET Collections error';
export const SET_COLLECTIONS                      = '[collections] SET Collections ';

export const GET_NEXT_COLLECTIONS                      = '[collections] GET nextCollections';
export const GET_NEXT_COLLECTIONS_SUCCESS              = '[collections] GET nextCollections success';
export const GET_NEXT_COLLECTIONS_ERROR                = '[collections] GET nextCollections error';
export const SET_NEXT_COLLECTIONS                      = '[collections] SET nextCollections ';

export const ADD_COLLECTION                           = '[collections] Add Collections';
export const ADD_COLLECTION_SUCCESS                   = '[collections] Add Collections success';
export const ADD_COLLECTION_ERROR                     = '[collections] Add Collections error';
export const SET_ADD_COLLECTION                       = '[collections] SET AddCollections ';

export const EDIT_COLLECTION                           = '[collections] Edit Collections';
export const EDIT_COLLECTION_SUCCESS                   = '[collections] Edit Collections success';
export const EDIT_COLLECTION_ERROR                     = '[collections] Edit Collections error';

export const DELETE_COLLECTION                           = '[collections] delete Collections';
export const DELETE_COLLECTION_SUCCESS                   = '[collections] delete Collections success';
export const DELETE_COLLECTION_ERROR                     = '[collections] delete Collections error';

export const REMOVE_COLLECTION                           = '[collections] remove Collections';
export const REMOVE_COLLECTION_SUCCESS                   = '[collections] remove Collections success';
export const REMOVE_COLLECTION_ERROR                     = '[collections] remove Collections error';
export const SET_REMOVE_COLLECTION                       = '[collections] set remove Collections status'

// For fetching collections
export const getCollections = (data) => ({
    type: GET_COLLECTIONS,
    payload: data
  });
export const getCollectionsSuccess = (data) => ({
    type: GET_COLLECTIONS_SUCCESS,
    payload: data
})
export const getCollectionsError = (data) => ({
    type: GET_COLLECTIONS_ERROR,
    payload: data
})
export const setCollections = (data) => ({
    type: SET_COLLECTIONS,
    payload: data
})



// For fetching next collections
export const getNextMyCollection = (data) => ({
    type: GET_NEXT_COLLECTIONS,
    payload: data
  });
export const getNextCollectionsSuccess = (data) => ({
    type: GET_NEXT_COLLECTIONS_SUCCESS,
    payload: data
})
export const getNextCollectionsError = (data) => ({
    type: GET_NEXT_COLLECTIONS_ERROR,
    payload: data
})
export const setNextCollections = (data) => ({
    type: SET_NEXT_COLLECTIONS,
    payload: data
})

// For add collections
export const addCollection = (data) => ({
    type: ADD_COLLECTION,
    payload: data
  });
export const addCollectionSuccess = (data) => ({
    type: ADD_COLLECTION_SUCCESS,
    payload: data
})
export const addCollectionError = (data) => ({
    type: ADD_COLLECTION_ERROR,
    payload: data
})
export const setaddCollectionstatus = (data) => ({
    type: SET_ADD_COLLECTION,
    payload: data
})

// For edit collections
export const editCollection = (data) => ({
    type: EDIT_COLLECTION,
    payload: data
  });
export const editCollectionSuccess = (data) => ({
    type: EDIT_COLLECTION_SUCCESS,
    payload: data
})
export const editCollectionError = (data) => ({
    type: EDIT_COLLECTION_ERROR,
    payload: data
})

// For delete collections
export const deleteCollection = (data) => ({
    type: DELETE_COLLECTION,
    payload: data
  });
export const deleteCollectionSuccess = (data) => ({
    type: DELETE_COLLECTION_SUCCESS,
    payload: data
})
export const deleteCollectionError = (data) => ({
    type: DELETE_COLLECTION_ERROR,
    payload: data
})

// For remove collections
export const removeCollection = (data) => ({
    type: REMOVE_COLLECTION,
    payload: data
  });
export const removeCollectionSuccess = (data) => ({
    type: REMOVE_COLLECTION_SUCCESS,
    payload: data
})
export const removeCollectionError = (data) => ({
    type: REMOVE_COLLECTION_ERROR,
    payload: data
})
export const removeCollectionStatus = (data) => ({
    type: SET_REMOVE_COLLECTION,
    payload: data
})