import React from "react";
import * as ReactBootstrap from "react-bootstrap";

function DeleteDialog({show, hideModal, onDelete, id, name}) {
  return (
    <React.Fragment>
      <ReactBootstrap.Modal
        show={show}
				onHide={hideModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        top
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title
            id="contained-modal-title-vcenter"
            className="font-weight-bold"
          >
            Delete Ticket
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className="text-center">
          <h6>Are you sure you want to delete this ticket - {name}?</h6>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button onClick={hideModal}>
            Close
          </ReactBootstrap.Button>
          <ReactBootstrap.Button className="btnDelete" onClick={() => onDelete(id)}>
            Delete
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </React.Fragment>
  );
}

export default DeleteDialog;
