import React from "react";
import { Route, Switch } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";
import SidebarComponent from "../../common/SidebarComponent/SidebarComponent";
import MyAccountPage from "../MyAccountScreen/MyAccountPage";
import BecomeASellerComponent from "../../components/BecomeASellerComponent/BecomeASellerComponent";
import MyTicketPage from "../../screens/MyTicketsPage/MyTicketPage";
import MyCollectionPage from "../MyCollectionPage/MyCollectionPage";
import MyOrderPage from "../../screens/MyOrdersPage/MyOrderPage";
import MyOffersPage from "../../screens/MyOffersPage/MyOffersPage";
import MyOfferDetailsPage from "../../screens/MyOffersPage/MyOfferDetailsPage";
import MyMessagePage from "../../screens/MyMessagesPage/MyMessagePage";
import MyChatPage from "../MyMessagesPage/ChatViewComponent/ChatPage";
import MyReviewsPage from "../MyReviews/MyReviewsPage";
import AccountDeletePage from "../DeleteAccount/AccountDeletePage";
import ReportingPage from "../Reporting/ReportingPage";
import MyReplayPage from "../Reporting/ChatViewComponent/ReplyPage";
import ProductList from "../MyCollectionPage/ProductList/ProductList";

const DashboardLayout = () => {
  return (
    <React.Fragment>
      <section>
        <ReactBootstrap.Container fluid>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col
              md={3}
              lg={2}
              className="d-none d-md-block my-4 pl-0"
            >
              <SidebarComponent />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={9} lg={10}>
              <Switch>
                <Route exact path="/account" component={MyAccountPage} />
                <Route
                  exact
                  path="/account/seller"
                  component={BecomeASellerComponent}
                />
                <Route
                  exact
                  path="/account/mytickets"
                  component={MyTicketPage}
                />
                <Route
                  exact
                  path="/account/mycollection"
                  component={MyCollectionPage}
                />
                <Route
                  exact
                  path="/account/mycollection/:id"
                  component={ProductList}
                />
                <Route
                  exact
                  path="/account/mymessages/:id"
                  component={MyChatPage}
                />
                <Route exact path="/account/myorders" component={MyOrderPage} />
                <Route
                  exact
                  path="/account/myoffers"
                  component={MyOffersPage}
                />
                <Route
                  exact
                  path="/account/myoffers/:id"
                  component={MyOfferDetailsPage}
                />
                <Route
                  exact
                  path="/account/mymessages"
                  component={MyMessagePage}
                />
                <Route
                  exact
                  path="/account/myreviews"
                  component={MyReviewsPage}
                />
                 <Route
                  exact
                  path="/account/delete"
                  component={AccountDeletePage}
                />
                <Route
                  exact
                  path="/account/report"
                  component={ReportingPage}
                />
                <Route
                  exact
                  path="/account/report/:id"
                  component={MyReplayPage}
                />
              </Switch>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </section>
    </React.Fragment>
  );
};

export default DashboardLayout;
