import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import "./TicketsPage.css";
import TicketsView from "./TicketsView";
import TicketsFilterComponent from "./TicketsFilterComponent/TicketsFilterComponent";
import {
  getTickets,
  getNextTickets,
  searchTickets,
  searchNextTickets,
} from "../../store/actions/tickets";
import {
  addItemToCart,
  setAddCartItemStatus,
} from "../../store/actions/myCart";
import { showModal } from "../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../js/constants";
import { connect } from "react-redux";
import { api as userapi } from "../../apis/userApi.instance";
import { Spinner } from "react-bootstrap";

const TicketsPage = ({
  tickets,
  hasMore,
  page,
  getTickets,
  getNextTickets,
  searchTickets,
  searchNextTickets,
  searchvalue,
  ticketLoading,
  addItemToCart,
  itemAddStatus,
  setAddCartItemStatus,
  isItemAddToCartLoading,
  user_id,
  isLogged,
  showModal,
}) => {
  const history = useHistory();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const SEARCH_QUERY = "q";
  const filternames = {
    DATE: "date",
    SUBGENRE: "sub_genre",
    EVENT: "event",
    VENUE: "venue",
    TEAM: "team",
    ARTIST: "artist",
    SECTION: "section",
    ROW: "row",
    FACEPRICE: "face_price",
    SEAT: "seat",
    COST: "price",
    SELLER: "seller",
  };

  const handleFilterChange = (name, e) => {
    setFilter((draft) => {
      const index = draft.findIndex((d) => d.name === name);
      if (index !== -1) {
        draft[index].value =
          draft[index].type === "select" ? e : e.target.value;
      }
    });
  };

  const initial_filters = [
    {
      placeholder: "Date",
      type: "date",
      value: "",
      name: filternames.DATE,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Sub Genre",
      type: "select",
      value: null,
      name: filternames.SUBGENRE,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Event",
      type: "select",
      value: null,
      name: filternames.EVENT,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Venue",
      type: "select",
      value: null,
      name: filternames.VENUE,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Team",
      type: "select",
      value: null,
      name: filternames.TEAM,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Artist",
      type: "select",
      value: null,
      name: filternames.ARTIST,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Section",
      type: "text",
      value: "",
      name: filternames.SECTION,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Row",
      type: "text",
      value: "",
      name: filternames.ROW,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Face price",
      type: "text",
      value: "",
      name: filternames.FACEPRICE,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Seat",
      type: "text",
      value: "",
      name: filternames.SEAT,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Cost",
      type: "text",
      value: "",
      name: filternames.COST,
      onChange: handleFilterChange,
    },
  ];

  const [filter, setFilter] = useImmer(initial_filters);
  const [isSeller, setSeller] = useImmer(false);
  const [isEvent, setEvent] = useImmer(false);

  const getSubGenreOptions = async () => {
    var subgenreOptions = {
      name: filternames.SUBGENRE,
      options: [],
    };
    try {
      var genres = await userapi.Filters.getGenre({has_tickets: true});
      genres.data.data.items.forEach((genre) => {
        var sub_genre_options = genre.sub_genre.map((sg) => ({
          id: sg.sub_genre_id,
          value: sg.name,
          label: sg.display_name,
        }));
        subgenreOptions.options = [
          ...subgenreOptions.options,
          ...sub_genre_options,
        ];
      });
    } catch (error) {
      console.log(error);
    }
    return subgenreOptions;
  };

  const getVenueOptions = async () => {
    var venueOptions = {
      name: filternames.VENUE,
      options: [],
    };
    try {
      var venues = await userapi.Filters.getVenue({has_tickets: true});
      venueOptions.options = venues.data.data.items.map((venue) => ({
        id: venue.venue_id,
        value: venue.venue_name,
        label: venue.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return venueOptions;
  };

  const getTeamOptions = async () => {
    var teamOptions = {
      name: filternames.TEAM,
      options: [],
    };
    try {
      var teams = await userapi.Filters.getTeam({has_tickets: true});
      teamOptions.options = teams.data.data.items.map((team) => ({
        id: team.team_id,
        value: team.team_name,
        label: team.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return teamOptions;
  };

  const getArtistOptions = async () => {
    var artistOptions = {
      name: filternames.ARTIST,
      options: [],
    };
    try {
      var artists = await userapi.Filters.getArtist({has_tickets: true});
      artistOptions.options = artists.data.data.items.map((artist) => ({
        id: artist.artist_id,
        value: artist.artist_name,
        label: artist.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return artistOptions;
  };

  const getEventOptions = async () => {
    var eventOptions = {
      name: filternames.EVENT,
      options: [],
    };
    try {
      var events = await userapi.Filters.getEvent({has_tickets: true});
      eventOptions.options = events.data.data.items.map((event) => ({
        id: event.event_id,
        value: event.name,
        label: event.name,
      }));
    } catch (error) {
      console.log(error);
    }
    return eventOptions;
  };

  // Load filter values
  const getFilterOptions = async () => {
    return Promise.all([
      getSubGenreOptions(),
      getVenueOptions(),
      getTeamOptions(),
      getArtistOptions(),
      getEventOptions(),
    ]).then((results) => {
      results.forEach((result) => {
        setFilter((draft) => {
          const index = draft.findIndex((d) => d.name === result.name);
          if (index !== -1) {
            // Set filter options
            draft[index].options = result.options;
          }
        });
      });
    });
  };

  const getFilterValues = () => {
    var filter_values = {};
    filter.forEach((f) => {
      if (f.value) {
        filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
      }
    });
    return filter_values;
  };

  // Get the query params and set it to the filters
  const setFilterValues = () => {
    let filter_values = getFilterValuesFromQueryParams(getQueryParams());
    setFilter((draft) => {
      draft.forEach((d) => {
        if (d.name in filter_values) {
          if (d.type === "select") {
            d.value = d.options.find(
              (o) => parseInt(o.id) === parseInt(filter_values[d.name])
            );
          } else {
            d.value = filter_values[d.name];
          }
        }
      });
    });
    // Object.keys(filter_values).forEach((name) => {
    //   setFilter((draft) => {
    // 		const index = draft.findIndex((d) => d.name === name);
    // 		if (index !== -1) {
    // 			if(draft[index].type === "select"){
    // 				draft[index].value = draft[index].options.find((o) => o.id === filter_values[name])
    // 			}else{
    // 				draft[index].value = filter_values[name];
    // 			}
    // 		}
    // 	});
    // });
  };

  const acceptedParams = [
    SEARCH_QUERY,
    filternames.DATE,
    filternames.SUBGENRE,
    filternames.EVENT,
    filternames.VENUE,
    filternames.TEAM,
    filternames.ARTIST,
    filternames.SECTION,
    filternames.ROW,
    filternames.FACEPRICE,
    filternames.COST,
    filternames.SEAT,
    filternames.SELLER,
  ];

  // Returns only params which are allowed
  const getQueryParams = () => {
    let params = {};
    acceptedParams.forEach((p) => {
      if (urlparams.get(p)) {
        params[p] = urlparams.get(p);
      }
    });
    return params;
  };

  const setQueryParams = (keyvaluepairs) => {
    const params = new URLSearchParams();
    Object.keys(keyvaluepairs).forEach((name) => {
      params.append(name, keyvaluepairs[name]);
    });

    history.push({
      pathname: "/tickets",
      search: params.toString(),
    });
  };

  const getSearchQuery = (queryparams) => {
    return SEARCH_QUERY in queryparams ? queryparams[SEARCH_QUERY] : null;
  };

  const getFilterValuesFromQueryParams = (queryparams) => {
    return Object.fromEntries(
      Object.entries(queryparams).filter(
        ([name, value]) => name !== SEARCH_QUERY
      )
    );
  };

  const fetchTickets = () => {
    let queryparams = getQueryParams();
    let query = getSearchQuery(queryparams);
    let filters = getFilterValuesFromQueryParams(queryparams);
    if (filternames.SELLER in filters) {
      setSeller(true);
    } else {
      setSeller(false);
    }
    // Ticket page - Event Filter
    if (filternames.EVENT in filters) {
      setEvent(true);
    } else {
      setEvent(false);
    }
    ///////////////////////////////////

    if (query) {
      searchTickets(query, filters);
    } else {
      getTickets(filters);
    }
    window.scrollTo(0, 450);
  };

  // Infinite scroll callback to fetch next set of tickets
  const nextPage = () => {
    let queryparams = getQueryParams();
    let query = getSearchQuery(queryparams);
    let filters = {
      page: page,
      ...getFilterValuesFromQueryParams(queryparams),
    };
    if (query) {
      searchNextTickets(query, filters);
    } else {
      getNextTickets(filters);
    }
  };

  // When page load get the filter options and set the values
  useEffect(() => {
    getFilterOptions().then(() => setFilterValues());
  }, []);

  // Fetch tickets when
  //  1) page load
  //  2) search value change
  //  3) query params in url change
  useEffect(() => {
    fetchTickets();
  }, [searchvalue, location.search]);

  //Submit filter
  const filterSubmit = () => {
    let keyVal = {
      ...getFilterValues(),
    };
    if (searchvalue) {
      keyVal[SEARCH_QUERY] = searchvalue;
    }
    // Append seller if it is set
    let queryparams = getQueryParams();
    if (filternames.SELLER in queryparams) {
      keyVal[filternames.SELLER] = queryparams[filternames.SELLER];
    }
    // set the params with the filters applied
    setQueryParams(keyVal);
  };

  // Clear filter
  const reset = () => {
    let keyVal = {};
    if (searchvalue) {
      keyVal[SEARCH_QUERY] = searchvalue;
    }
    // Append seller if it is set
    let queryparams = getQueryParams();
    if (filternames.SELLER in queryparams) {
      keyVal[filternames.SELLER] = queryparams[filternames.SELLER];
    }
    setQueryParams(keyVal);
    // Set all filter values to null
    setFilter((draft) => {
      draft.forEach((f) =>
        f.type === "select" ? (f.value = null) : (f.value = "")
      );
    });
  };

  // Clear filter
  const backFromSeller = () => {
    let keyVal = {};
    if (searchvalue) {
      keyVal[SEARCH_QUERY] = searchvalue;
    }

    setQueryParams(keyVal);
    // Set all filter values to null
    setFilter((draft) => {
      draft.forEach((f) =>
        f.type === "select" ? (f.value = null) : (f.value = "")
      );
    });
    setOfferedTickets([]);
  };

  // This For seller Ticket search
  // var initial_state = {
  //   isSellerBySearch:false
  // };
  //   const locations = useLocation();
  //   const urlSellerparams = new URLSearchParams(locations.search);
  //   useEffect(() => {
  //     if(urlSellerparams.get("seller")){
  //       setState((draft) => {
  //         draft.isSellerBySearch = true;
  //     })
  //   }
  //   }, [location.search]);
  //   const [state, setState] = useImmer(initial_state);
  // +++++++++++++++++++++++++++++
  const [offeredTickets, setOfferedTickets] = useImmer([]);
  const handleAddToOffer = (product_id) => {
    let offeredTicket = tickets.find(
      (ticket) => ticket.product_id === product_id
    );
    setOfferedTickets([...offeredTickets, offeredTicket]);
  };

  const handleRemoveFromOffer = (product_id) => {
    let removed_offeredTickets = offeredTickets.filter(
      (ticket) => ticket.product_id !== product_id
    );
    setOfferedTickets(removed_offeredTickets);
  };

  useEffect(() => {
    if (itemAddStatus === OPERATION_STATUS.SUCCESS) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/mycart",
      });
    } else if (itemAddStatus === OPERATION_STATUS.FAILURE) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [itemAddStatus]);

  const addCart = (product_id, listing_name) => {
    if (isLogged) {
      var data = {
        type: "BUY",
        product: {
          id: product_id,
          name: listing_name,
        },
      };
      addItemToCart(data);
    } else {
      showModal("login");
    }
  };

  return (
    <>
      <div className="divSearchResult">
        <TicketsFilterComponent
          filters={filter}
          onApply={filterSubmit}
          onClear={reset}
        />
      </div>
      {ticketLoading || isItemAddToCartLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <TicketsView
          tickets={tickets}
          hasMore={hasMore}
          nextPage={nextPage}
          isEvent={isEvent}
          isSeller={isSeller}
          offeredTickets={offeredTickets}
          addToOffer={handleAddToOffer}
          removeFromOffer={handleRemoveFromOffer}
          backFromSeller={backFromSeller}
          addToCart={addCart}
          logged_user_id={user_id}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tickets: state.ticket.tickets,
    hasMore: state.ticket.hasMore,
    page: state.ticket.nextPage,
    searchvalue: state.searchBar.search,
    ticketLoading:
      "ticket_init" in state.ui.loaders
        ? state.ui.loaders["ticket_init"]
        : true,
    user_id: state.user ? state.user.id : null,
    itemAddStatus: state.cart.add_status,
    isItemAddToCartLoading: state.cart.add_status === OPERATION_STATUS.PENDING,
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
  };
};

export default connect(mapStateToProps, {
  getTickets,
  getNextTickets,
  searchTickets,
  searchNextTickets,
  showModal,
  addItemToCart,
  setAddCartItemStatus,
})(TicketsPage);
