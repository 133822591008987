import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import MomentsHistoryCardComponent from "./MomentsHistoryCardComponent/MomentsHistoryCardComponent";
import "./MomentsHistoryComponent.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const MommentsHistoryView = ({ moments, hasMore, nextPage }) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Container fluid>
        <ReactBootstrap.Container className="momentCardContainer">
          <InfiniteScroll
            dataLength={moments.length}
            next={nextPage}
            hasMore={hasMore}
            loader={
              <h4>
                <ReactBootstrap.Spinner
                  as="span"
                  animation="border"
                  role="status"
                />
                Loading...
              </h4>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                {moments.length !== 0 ? (
                  <b>No more events found</b>
                ) : (
                  <b>No Event Found</b>
                )}
              </p>
            }
          >
            {moments.map((moment) => {
              return (
                <Link key={moment.id} to={"/tickets?event=" + moment.id}>
                  <MomentsHistoryCardComponent
                    key={moment.id}
                    name={moment.name}
                    significance={moment.significance}
                    genre={moment.genre}
                    sub_genre={moment.sub_genre}
                    venue={moment.venue}
                    date={moment.date}
                    teams={moment.teams}
                    artists={moment.artists}
                  />
                </Link>
              );
            })}
          </InfiniteScroll>
        </ReactBootstrap.Container>
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

MommentsHistoryView.propTypes = {
  moments: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default MommentsHistoryView;
