export const GET_REPORT_ADMIN                = '[reportAdmin] GET report rooms';
export const FETCH_REPORT_ADMIN_SUCCESS      = '[reportAdmin] Fetch report rooms success';
export const FETCH_REPORT_ADMIN_ERROR        = '[reportAdmin] Fetch report rooms Error';
export const UPDATE_REPORT_ADMIN             = '[reportAdmin] Update report rooms';

export const GET_REPLIES_ADMIN                = '[reportAdmin] GET replies';
export const FETCH_REPLIES_ADMIN_SUCCESS      = '[reportAdmin] Fetch replies success';
export const FETCH_REPLIES_ADMIN_ERROR        = '[reportAdmin] Fetch replies Error';
export const UPDATE_REPLIES_ADMIN             = '[reportAdmin] Update replies';
export const UPDATE_NEXT_REPLIES_ADMIN         = '[reportAdmin] Update next replies';

export const ADD_REPORT_ADMIN                 = '[reportAdmin] Add Admin report';
export const ADD_REPORT_ADMIN_SUCCESS         = '[reportAdmin] Add Admin report success';
export const ADD_REPORT_ADMIN_ERROR           = '[reportAdmin] Add Admin report Error';
export const UPDATE_REPORT_ADMIN_STATUS       = '[reportAdmin] Update report';

export const SEND_MESSAGE_ADMIN                     = '[reportAdmin] Send Admin message';
export const SEND_MESSAGE_ADMIN_SUCCESS             = '[reportAdmin] Send Admin message success';
export const SEND_MESSAGE_ADMIN_ERROR               = '[reportAdmin] Send Admin message Error';
export const UPDATE_SEND_MESSAGE_ADMIN_STATUS       = '[reportAdmin] Send message';

export const GET_REPORT_TYPES                     = '[reportAdmin] Get Report Type';
export const GET_REPORT_TYPES_SUCCESS             = '[reportAdmin] Get Report Type success';
export const GET_REPORT_TYPES_ERROR               = '[reportAdmin] Get Report Type Error';
export const UPDATE_REPORT_TYPES                  = '[reportAdmin] Set Report Type';

// For fetching moments
export const getReportList = () => ({
    type: GET_REPORT_ADMIN
  });

// For fetching moments
export const getReportListSuccess = (data) => ({
  type: FETCH_REPORT_ADMIN_SUCCESS,
  payload: data,
});

export const getReportListError = (data) => ({
  type: FETCH_REPORT_ADMIN_ERROR,
  payload: data
});


export const setgetReportList = (data) => ({
    type   : UPDATE_REPORT_ADMIN,
    payload: data
  });

// For fetching moments
export const getReplies = (data) => ({
  type: GET_REPLIES_ADMIN,
  payload: data,
});

// For fetching moments
export const getRepliesListSuccess = (data) => ({
type: FETCH_REPLIES_ADMIN_SUCCESS,
payload: data,
});

export const getRepliesListError = (data) => ({
type: FETCH_REPLIES_ADMIN_ERROR,
payload: data
});


export const setRepliesList = (data) => ({
  type   : UPDATE_REPLIES_ADMIN,
  payload: data
});
export const setnextRepliesList = (data) => ({
  type   : UPDATE_NEXT_REPLIES_ADMIN,
  payload: data
});


// For fetching moments
export const addAdminReport = (data) => ({
  type: ADD_REPORT_ADMIN,
  payload: data,
});

// For fetching moments
export const addAdminReportSuccess = (data) => ({
type: ADD_REPORT_ADMIN_SUCCESS,
payload: data,
});

export const addAdminReportError = (data) => ({
type: ADD_REPORT_ADMIN_ERROR,
payload: data
});


export const setReportAdminStatus = (data) => ({
  type   : UPDATE_REPORT_ADMIN_STATUS,
  payload: data
});


export const sendMessageAdmin = (data) => ({
  type: SEND_MESSAGE_ADMIN,
  payload: data,
});

// For fetching moments
export const sendMessageAdminSuccess = (data) => ({
type: SEND_MESSAGE_ADMIN_SUCCESS,
payload: data,
});

export const sendMessageAdminError = (data) => ({
type: SEND_MESSAGE_ADMIN_ERROR,
payload: data
});


export const setMessageAdminStatus = (data) => ({
  type   : UPDATE_SEND_MESSAGE_ADMIN_STATUS,
  payload: data
});

export const getreportTypes = () => ({
  type: GET_REPORT_TYPES,
});

// For fetching moments
export const getreportTypesSuccess = (data) => ({
type: GET_REPORT_TYPES_SUCCESS,
payload: data,
});

export const getreportTypesError = (data) => ({
type: GET_REPORT_TYPES_ERROR,
payload: data
});


export const setreportType = (data) => ({
  type   : UPDATE_REPORT_TYPES,
  payload: data
})