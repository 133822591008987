import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import CheckoutBillingAddress from "./CheckoutBillingAddress";
import {
  updateCheckoutBillingAddress,
  updateCheckoutBillingAddressSetStatus,
  addUserBillingAddress,
  addUserShippingAddress,
  addUserBillingAddressSetStatus,
  editUserBillingAddress,
  editUserBillingAddressSetStatus,
} from "../../../store/actions/checkout";
import { OPERATION_STATUS } from "../../../js/constants";
import AddressAddEditModel from "../AdressAddEditModel/AddressAddEditModel";
import { showModal, hideModal } from "../../../store/actions/ui";

const BillingAddressPage = ({
  billing_address,
  updateCheckoutBillingAddress,
  updateCheckoutBillingAddressSetStatus,
  updateCheckoutBillingAddressStatus,
  addUserBillingAddress,
  addUserShippingAddress,
  addUserBillingAddressSetStatus,
  addBillingAddressStatus,
  editUserBillingAddress,
  editUserBillingAddressSetStatus,
  editBillingAddressStatus,
  checkout_id,
  isUpdateBillingLoading,
  addressModalShow,
  showModal,
  hideModal,
  user_billing_addresses
}) => {
  var initial_state = {
    address: {
      id: "",
      type: "BILL",
      first_name: "",
      last_name: "",
      mobile_no: "",
      address_line_1: "",
      address_line_2: "",
      administrative_area: "",
      city: "",
      postal_code: "",
      country: "",
    },
    operation: "Add",
  };
  // Set State value using immer
  const [address_form, setAddressForm] = useImmer(initial_state);

  const [isAddressModalLoading, setAddressModalLoading] = useImmer(false);

  const updateCheckoutAddress = (address_id) => {
    let data = {
      checkout_id: checkout_id,
      address_id: address_id,
    };
    updateCheckoutBillingAddress(data);
  };

  useEffect(() => {
    if (updateCheckoutBillingAddressStatus === OPERATION_STATUS.SUCCESS) {
      updateCheckoutBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
    } else if (
      updateCheckoutBillingAddressStatus === OPERATION_STATUS.FAILURE
    ) {
      updateCheckoutBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [updateCheckoutBillingAddressStatus]);

  // Edit shipping address
  useEffect(() => {
    if (editBillingAddressStatus === OPERATION_STATUS.SUCCESS) {
      updateCheckoutAddress(billing_address.id);
      editUserBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressForm(initial_state);
      hideModal("checkout-shipping-modal");
      setAddressModalLoading(false)
    } else if (editBillingAddressStatus === OPERATION_STATUS.FAILURE) {
      editUserBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressModalLoading(false)
    }
  }, [editBillingAddressStatus]);

  // Add shipping address
  useEffect(() => {
    if (addBillingAddressStatus === OPERATION_STATUS.SUCCESS) {
      if(user_billing_addresses.length > 0){
        updateCheckoutAddress(user_billing_addresses[0].id);
      }
      addUserBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressForm(initial_state);
      hideModal("checkout-billing-modal");
      setAddressModalLoading(false)
    } else if (addBillingAddressStatus === OPERATION_STATUS.FAILURE) {
      addUserBillingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressModalLoading(false)
    }
  }, [addBillingAddressStatus]);

  const addAddress = () => {
    setAddressForm(initial_state);
    showModal("checkout-billing-modal");
  };

  const editAddress = () => {
    let address = billing_address;
    setAddressForm((draft) => {
      draft.address.id = address.id;
      draft.address.type = address.type;
      draft.address.first_name = address.first_name;
      draft.address.last_name = address.last_name;
      draft.address.mobile_no = address.mobile_no;
      draft.address.address_line_1 = address.address_line_1;
      draft.address.address_line_2 = address.address_line_2;
      draft.address.administrative_area = address.administrative_area;
      draft.address.city = address.city;
      draft.address.postal_code = address.postal_code;
      draft.address.country = address.country;
      draft.operation = "update";
    });
    showModal("checkout-billing-modal");
  };

  const Submit = (address) => {
    let data = {
      type: "BILL",
      first_name: address.first_name,
      last_name: address.last_name,
      mobile_no: address.mobile_no,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      administrative_area: address.administrative_area,
      city: address.city,
      postal_code: address.postal_code,
      country: address.country,
    };
    setAddressModalLoading(true)
    if (address_form.operation === "Add") {
      // Add address
      addUserBillingAddress(data);
    } else if (address_form.operation === "update") {
      // Edit address
      data.id = address.id;
      editUserBillingAddress(data);
    }
    if(address.isSameAs==true){
      let data = {
        type: "DELIVERY",
        first_name: address.first_name,
        last_name: address.last_name,
        mobile_no: address.mobile_no,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        administrative_area: address.administrative_area,
        city: address.city,
        postal_code: address.postal_code,
        country: address.country,
      };
      addUserShippingAddress(data)
    }
  };

  const Cancel = () => {
    setAddressForm(initial_state);
    hideModal("checkout-billing-modal");
  };

  return (
    <>
      <CheckoutBillingAddress
        checkout_billing_address={billing_address}
        add_Address={addAddress}
        edit_Address={editAddress}
        loading={isUpdateBillingLoading}
      />
      <AddressAddEditModel
        loading={isAddressModalLoading}
        showAddressModal={addressModalShow}
        address={address_form.address}
        operation={address_form.operation}
        title="Billing"
        onSubmit={Submit}
        onCancel={Cancel}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    billing_address: state.checkout.checkout_details.billing_address,
    isUpdateBillingLoading:
      state.checkout.update_billing_address_status === OPERATION_STATUS.PENDING,
    updateCheckoutBillingAddressStatus:
      state.checkout.update_billing_address_status,
    editBillingAddressStatus: state.checkout.edit_billing_address_status,
    addBillingAddressStatus: state.checkout.add_billing_address_status,
    checkout_id: state.checkout.checkout_details.checkout_id,
    addressModalShow: state.ui.activeModal === "checkout-billing-modal",
    user_billing_addresses: state.Address.BillingAddress
  };
};

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  updateCheckoutBillingAddress,
  updateCheckoutBillingAddressSetStatus,
  addUserBillingAddress,
  addUserShippingAddress,
  addUserBillingAddressSetStatus,
  editUserBillingAddress,
  editUserBillingAddressSetStatus,
})(BillingAddressPage);
