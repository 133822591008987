import React from "react";
import { Card, Badge, Dropdown } from "react-bootstrap";
import TicketCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";

const OrderItemComponent = ({
  type,
  base_price,
  details,
  seller_id,
  seller_name,
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center py-1">
            <div className="flex-grow-1">
              <div>
                <Badge className="badge-info">{type}</Badge>
              </div>
              <div>
                <i className="fa fa-handshake-o" aria-hidden="true"></i>
                <Link key={seller_id} to={"/tickets?seller=" + seller_id}>
                  <span className="ticketType">{seller_name}</span>
                </Link>
              </div>
            </div>
            <div className="d-flex">
              <div className="font-weight-bold">Cost:</div>
              <div className="font-weight-bold ml-1">
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {base_price}
              </div>
            </div>
          </div>
          <Dropdown.Divider />
          <div className="d-flex">
            {details.map((item) => {
              return (
                <TicketCard
                  key={item.product_id}
                  listing_name={item.listing_name}
                  cost={item.cost}
                  image={item.image}
                />
              );
            })}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default OrderItemComponent;
