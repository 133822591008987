import React from "react";
import { Card, Col, Row, Badge, Button, Dropdown } from "react-bootstrap";
import Paypal from "../PaypalButton/PaypalButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { NavLink } from "react-router-dom";
import { ORDER_STATES } from "../../../js/constants";
import "./PriceDetails.css";

const PriceDetails = ({
  paypal_client_id,
  price_sub_total,
  tax,
  delivery,
  grand_total,
  order_no,
  payment_summary,
  onApprovePayment,
  onCancelPayment,
  onErrorPayment,
  orderStatus,
  cancelOrder,
  orderExpiryDate,
  expiry_status,
  onExpiryCheck
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Col xs={12} md={6}>
            <div className="searchResultEventName textBlackColor">
              <strong>Pricing details</strong>
            </div>
          </Col>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} sm={6} md={8}>
              <div>Price</div>
              <div>Shipping</div>
              <div>Tax</div>
              <strong>
                <div>Total Amount</div>
              </strong>
            </Col>
            <Col xs={6} sm={6} md={4} className=" text-center text-md-right">
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {price_sub_total}
              </div>
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {tax}
              </div>
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {delivery}
              </div>
              <strong>
                <div>
                  <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                  {grand_total}
                </div>
              </strong>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Col xs={12} sm={12} className="text-center">
          {(orderStatus === ORDER_STATES.OPEN || orderStatus === ORDER_STATES.VALIDATED) && (
            <>
              {payment_summary.is_paid ? (
                <>
                  <div className="mb-2 order-payment-success">
                    Your order has been paid successfully...!
                  </div>
                  <Button
                    className="btnClickSeller "
                    as={NavLink}
                    exact
                    to="/tickets"
                  >
                    <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                    Continue Shopping
                  </Button>
                </>
                ) : (
                <>
                  <PayPalScriptProvider
                    options={{
                      "client-id": paypal_client_id,
                      // "disable-funding": 'card'
                    }}
                  >
                    <Paypal
                      description={"Memory lane order -" + order_no}
                      amount={grand_total}
                      order_no={order_no}
                      onApprovePayment={onApprovePayment}
                      onCancelPayment={onCancelPayment}
                      onErrorPayment={onErrorPayment}
                      orderExpiryDate={orderExpiryDate}
                      onexpiryCheck={onExpiryCheck}
                      expiryStatus={expiry_status}
                    />
                  </PayPalScriptProvider>
                  <hr />
                  <Button className="btn btn-danger" disabled={false} 
                  onClick={(e) => {
                    e.preventDefault();
                    cancelOrder(order_no);
                  }}>
                    Cancel
                  </Button>
                </>
                )
              }
            </>
          )}
          
          {orderStatus === ORDER_STATES.CANCELLED && (
            <>
              <div className="mb-2 order-payment-cancelled">
                Your order has been cancelled...!
              </div>
              <Button
                className="btnClickSeller "
                as={NavLink}
                exact
                to="/tickets"
              >
                <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                Continue Shopping
              </Button>
            </>
          )}
          </Col>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

export default PriceDetails;
