import React from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import * as ReactBootstrap from "react-bootstrap";
// import { useImmer } from "use-immer";

const EditableSelect = ({
  value,
  error,
  options,
  onChangeValue,
  name,
  placeholder,
  onEditOption,
  onEditInputCancel,
  index
}) => {
  
  const Option = (props) => {
    return (
      <div className="d-flex">
        <components.Option {...props} />
        {props.data.canEdit && (
          <div
            className="m-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onEditOption(props.data,name,index);
            }}
          >
            <i className="fa fa-edit" aria-hidden="true"></i>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {value && value.showEdit ? (
        <ReactBootstrap.InputGroup>
          <ReactBootstrap.Form.Control
            className={error.length > 0 ? "p-1 is-invalid" : "p-1"}
            type="text"
            placeholder={placeholder}
            value={value.value}
            name={name}
            onChange={(e) => {
              onChangeValue((name+"_update"), index, e);
            }}
            autoComplete="off"
          />
          <ReactBootstrap.InputGroup.Text
            className="btn btn-danger"
            onClick={() => {
              onEditInputCancel(name,index);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </ReactBootstrap.InputGroup.Text>
        </ReactBootstrap.InputGroup>
      ) : (
        <CreatableSelect
          className={error.length > 0 ? "p-1 is-invalid" : "p-1"}
          isClearable={false}
          components={{ Option }}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={(e) => {
            onChangeValue(name, index, e);
          }}
          options={options}
        />
      )}
    </>
  );
};

export default EditableSelect;
