import {
  GET_ROOMS,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_ERROR,
  fetchRoomsSuccess,
  fetchRoomsError,
  setRooms,
  GET_NEXT_ROOMS,
  FETCH_NEXT_ROOMS_SUCCESS,
  FETCH_NEXT_ROOMS_ERROR,
  fetchNextRoomsSuccess,
  fetchNextRoomsError,
  CONTACT_SELLER,
  FETCH_ROOM_SUCCESS,
  FETCH_ROOM_ERROR,
  fetchRoomSuccess,
  fetchRoomError,
  setContactSellerStatus,
  setActiveRoomDetails,
  setReceiverId,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_ERROR,
  createRoomSuccess,
  createRoomError,
  GET_MESSAGES,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_ERROR,
  fetchMessagesSuccess,
  fetchMessagesError,
  setMessages,
  GET_NEXT_MESSAGES,
  FETCH_NEXT_MESSAGES_SUCCESS,
  FETCH_NEXT_MESSAGES_ERROR,
  fetchNextMessagesSuccess,
  fetchNextMessagesError,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  sendMessageSuccess,
  sendMessageError,
  setSendMessageStatus,
  setSendMessageData,
  createRoomSetStatus,
  SELECT_ROOM,
  FETCH_SINGLE_ROOM_SUCCESS,
  FETCH_SINGLE_ROOM_ERROR,
  fetchSingleRoomSuccess,
  fetchSingleRoomError,
  setSingleRoomStatus,
  UPDATE_UNREAD_MESSAGE,
  UPDATE_UNREAD_MESSAGE_SUCCESS,
  UPDATE_UNREAD_MESSAGE_ERROR,
  updateUnreadMessageSuccess,
  updateUnreadMessageError,
  updateUnreadMessageSetStatus,
  updateUnreadMessage,
  setUnreadMessageFlag
} from "../../actions/mymessages";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { setNotification } from "../../actions/notification";
import { notification_types, OPERATION_STATUS, FLAGGED_TERM } from "../../../js/constants"
import Avatar from "../../../images/avatar.png";
import { formattedMessageDisplayDateTime } from "../../../js/utils";

//************************GET ROOMS LIST******************** */
export const getRoomsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ROOMS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/message/rooms.php",
        action.payload,
        null,
        fetchRoomsSuccess,
        fetchRoomsError,
        true,
        "[mymessages]"
      )
    );
    dispatch(showSpinner("mymessages-rooms_init"));
  }
};

// on successful fetch, process the books data
export const processRoomsCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ROOMS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    let current_user_id = getState().user.id;
    let rooms = action.payload.data.items.map((room) => {
      let receiver = room.participants.find(participant => participant.user_id !== current_user_id)
      let has_unread_messages = room.participants.some(participant => participant.user_id === current_user_id && participant.has_unread_messages )
      return {
        id: room.room_id,
        message: room.messages,
        name: receiver.user_name,
        participants: room.participants,
        hasUnreadMessage: has_unread_messages,
        image: {
          image_50: receiver.avatar ? receiver.avatar : Avatar,
          image_200: receiver.avatar ? receiver.avatar : Avatar,
          image_400: receiver.avatar ? receiver.avatar : Avatar,
        },
        lastMsgSentByCurrentUser: room.messages ? room.messages.sender_id === current_user_id : false
      }
    })
    const newData = {
      roomslist: rooms,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setRooms(newData));
    dispatch(hideSpinner("mymessages-rooms_init"));
  }
  if (action.type === FETCH_ROOMS_ERROR) {
    dispatch(hideSpinner("mymessages-rooms_init"));
  }
};

//************************GET NEXT ROOMS LIST******************** */
export const getNextRoomsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_NEXT_ROOMS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/message/rooms.php",
        action.payload,
        null,
        fetchNextRoomsSuccess,
        fetchNextRoomsError,
        true,
        "[rooms]"
      )
    );
    dispatch(showSpinner("mymessages-rooms_next"));
  }
};

// on successful fetch, process the books data
export const processNextRoomsCollection = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === FETCH_NEXT_ROOMS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    

    let current_user_id = getState().user.id;
    let newrooms = action.payload.data.items.map((room) => {
      let receiver = room.participants.find(participant => participant.user_id !== current_user_id)
      let has_unread_messages = room.participants.some(participant => participant.user_id === current_user_id && participant.has_unread_messages )
      return {
        id: room.room_id,
        message: room.messages,
        name: receiver.user_name,
        participants: room.participants,
        hasUnreadMessage: has_unread_messages,
        image: {
          image_50: receiver.avatar ? receiver.avatar : Avatar,
          image_200: receiver.avatar ? receiver.avatar : Avatar,
          image_400: receiver.avatar ? receiver.avatar : Avatar,
        },
        lastMsgSentByCurrentUser: room.messages ? room.messages.sender_id === current_user_id : false
      }
    })

    const prevrooms = getState().room.rooms;
    const currtRooms = [...prevrooms, ...newrooms];
    const nextData = {
      roomslist: currtRooms,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setRooms(nextData));
    dispatch(hideSpinner("mymessages-rooms_next"));
  }
  if (action.type === FETCH_NEXT_ROOMS_ERROR) {
    dispatch(hideSpinner("mymessages-rooms_next"));
  }
};

//*************************** CONTACT SELLER ************************** */
export const contactSellerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CONTACT_SELLER) {
    dispatch(setContactSellerStatus(OPERATION_STATUS.PENDING));
    dispatch(setReceiverId(action.payload.receiver_id));
    dispatch(
      apiRequest(
        "GET",
        "/user/message/rooms.php",
        action.payload,
        null,
        fetchRoomSuccess,
        fetchRoomError,
        true,
        "[mymessages]"
      )
    );
    dispatch(showSpinner("mymessages-contactseller"));
  }
};

export const processContactSeller = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === FETCH_ROOM_SUCCESS) {
    let room_data = action.payload.data
    let current_user_id = getState().user.id;
    let receiver = room_data.participants.find(participant => participant.user_id !== current_user_id)
    let has_unread_messages = room_data.participants.some(participant => participant.user_id === current_user_id && participant.has_unread_messages )
    let room_details = {
      room_id: room_data.room_id,
      room_name: receiver.user_name,
      image: {
        image_50: receiver.avatar ? receiver.avatar : Avatar,
        image_200: receiver.avatar ? receiver.avatar : Avatar,
        image_400: receiver.avatar ? receiver.avatar : Avatar,
      },
      participants: room_data.participants.map(participants => {
        return {
          id: participants.user_id,
          name: participants.user_name,
          avatar: {
            image_50: participants.avatar ? participants.avatar : Avatar,
            image_200: participants.avatar ? participants.avatar : Avatar,
            image_400: participants.avatar ? participants.avatar : Avatar,
          },
          has_unread_messages: participants.has_unread_messages,
        }
      }),
      hasUnreadMessage: has_unread_messages
    }
    // dispatch(showModal("OfferView"))
    dispatch(setActiveRoomDetails(room_details));
    dispatch(setContactSellerStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("mymessages-contactseller"));
  }

  if (action.type === FETCH_ROOM_ERROR) {
    let data = {
      receiver_id: getState().room.contact_seller.receiver_id
    }
    dispatch(createRoomSetStatus(OPERATION_STATUS.PENDING))
    // Create new room
    dispatch(
      apiRequest(
        "POST",
        "/user/message/rooms.php",
        null,
        data,
        createRoomSuccess,
        createRoomError,
        true,
        "[mymessages]"
      )
    );
  }
};

export const processCreateRoom = ({ dispatch,getState }) => (next) => (
  action
) => {
  next(action);
  if (action.type === CREATE_ROOM_SUCCESS) {
    let room_data = action.payload.data
    let current_user_id = getState().user.id;
    let receiver = room_data.participants.find(participant => participant.user_id !== current_user_id)
    let has_unread_messages = room_data.participants.some(participant => participant.user_id === current_user_id && participant.has_unread_messages )
    let room_details = {
      room_id: room_data.room_id,
      room_name: receiver.user_name,
      image: {
        image_50: receiver.avatar ? receiver.avatar : Avatar,
        image_200: receiver.avatar ? receiver.avatar : Avatar,
        image_400: receiver.avatar ? receiver.avatar : Avatar,
      },
      participants: room_data.participants.map(participants => {
        return {
          id: participants.user_id,
          name: participants.user_name,
          avatar: {
            image_50: participants.avatar ? participants.avatar : Avatar,
            image_200: participants.avatar ? participants.avatar : Avatar,
            image_400: participants.avatar ? participants.avatar : Avatar,
          },
          has_unread_messages: participants.has_unread_messages,
        }
      }),
      hasUnreadMessage: has_unread_messages
    }
    // dispatch(showModal("OfferView"))
    dispatch(setActiveRoomDetails(room_details));
    dispatch(setContactSellerStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("mymessages-contactseller"));
  }

  if (action.type === CREATE_ROOM_ERROR) {
    // if(action.payload.response.status == 409){

    //   if (action.type === GET_SELLERROOM) {
    //     dispatch(setReceiverId(action.payload));
    //     dispatch(
    //       apiRequest(
    //         "GET",
    //         "/user/message/rooms.php",
    //         action.payload,
    //         null,
    //         fetchSellerRoomSuccess,
    //         fetchSellerRoomError,
    //         true,
    //         "[rooms]"
    //       )
    //     );
    //     dispatch(showSpinner("rooms"));
    //   }
    // }
    dispatch(setContactSellerStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("mymessages-contactseller"));
  }
};

//*************************** CONTACT SELLER ************************** */


//***************************GET MESSAGE LIST************************** */
export const getMessagesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_MESSAGES) {
    dispatch(showSpinner("mymessages-messages_init"));
    dispatch(
      apiRequest(
        "GET",
        "/user/message/messages.php",
        action.payload,
        null,
        fetchMessagesSuccess,
        fetchMessagesError,
        true,
        "[mymessages]"
      )
    );
    
  }
};

// on successful fetch, process the books data
export const processMessageCollection = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);
  if (action.type === FETCH_MESSAGE_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }

    let activeRoom = getState().room.activeRoom.info

    if(activeRoom && activeRoom.hasUnreadMessage){
      // Update unread
      dispatch(updateUnreadMessage({room_id: activeRoom.room_id}));
    }
    
    let messages = action.payload.data.items.map(message => {
      return {
        id: message.message_id,
        message: message.message,
        send_date: formattedMessageDisplayDateTime(message.sent_date),
        sender_details: {
          id: message.sender.user_id,
          name: message.sender.user_name,
          avatar: {
            image_50: message.sender.avatar ? message.sender.avatar : Avatar,
            image_200: message.sender.avatar ? message.sender.avatar : Avatar,
            image_400: message.sender.avatar ? message.sender.avatar : Avatar,
          },
        }
      }
    })
    const newData = {
      messages: messages,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMessages(newData));
    dispatch(hideSpinner("mymessages-messages_init"));
  }

  if (action.type === FETCH_MESSAGE_ERROR) {
    dispatch(hideSpinner("mymessages-messages_init"));
  }
};
//***************************GET NEXT MESSAGE LIST************************** */
export const getNextMessageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_NEXT_MESSAGES) {
    dispatch(showSpinner("mymessages-messages_next"));
    dispatch(
      apiRequest(
        "GET",
        "/user/message/messages.php",
        action.payload,
        null,
        fetchNextMessagesSuccess,
        fetchNextMessagesError,
        true,
        "[mymessages]"
      )
    );
    
  }
};

// on successful fetch, process the books data
export const processNextMessageCollection = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === FETCH_NEXT_MESSAGES_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }

    let messages = action.payload.data.items.map(message => {
      return {
        id: message.message_id,
        message: message.message,
        send_date: formattedMessageDisplayDateTime(message.sent_date),
        sender_details: {
          id: message.sender.user_id,
          name: message.sender.user_name,
          avatar: {
            image_50: message.sender.avatar ? message.sender.avatar : Avatar,
            image_200: message.sender.avatar ? message.sender.avatar : Avatar,
            image_400: message.sender.avatar ? message.sender.avatar : Avatar,
          },
        }
      }
    })
    const prevMessages = getState().message.messages;
    const currtMessages = [...prevMessages, ...messages];
    const newData = {
      messages: currtMessages,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMessages(newData));
    dispatch(hideSpinner("mymessages-messages_next"));
  }

  if (action.type === FETCH_NEXT_MESSAGES_ERROR) {
    dispatch(hideSpinner("mymessages-messages_next"));
  }
};

//***************************SEND MESSAGE LIST************************** */
export const sendMessageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SEND_MESSAGE) {
    dispatch(setSendMessageStatus(OPERATION_STATUS.PENDING));
    dispatch(showSpinner("mymessages-send"));
    dispatch(
      apiRequest(
        "POST",
        "/user/message/messages.php",
        null,
        action.payload,
        sendMessageSuccess,
        sendMessageError,
        true,
        "[mymessages]"
      )
    );
    
  }
};

// on successful fetch, process the books data
export const processSendMessage = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === SEND_MESSAGE_SUCCESS) {
    let message = action.payload.data
    let sent_message = {
      id: message.message_id,
      message: message.message,
      send_date: formattedMessageDisplayDateTime(message.sent_date),
      sender_details: {
        id: message.sender.user_id,
        name: message.sender.user_name,
        avatar: {
          image_50: message.sender.avatar ? message.sender.avatar : Avatar,
          image_200: message.sender.avatar ? message.sender.avatar : Avatar,
          image_400: message.sender.avatar ? message.sender.avatar : Avatar,
        },
      }
    }
    // Append the sent message to chat
    const prevMessages = getState().message.messages;
    const currtMessages = [sent_message,...prevMessages];
    dispatch(setSendMessageData(currtMessages));
    dispatch(setSendMessageStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("mymessages-send"));
  }
  if (action.type === SEND_MESSAGE_ERROR) {
    var error_code = action.payload.response.data.error.Code;
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "mymessages-send",
        notification_types.error
      )
    );
    if(error_code === FLAGGED_TERM.ERROR_CODE){
      dispatch(
        setNotification(
          FLAGGED_TERM.ERROR_MSG,
          "mymessages-send",
          notification_types.error
        )
      );
    }
    dispatch(setSendMessageStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("mymessages-send"));
  }
};


//*************************** GET Room details ************************** */
export const getSingleRoomFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SELECT_ROOM) {
    dispatch(setSingleRoomStatus(OPERATION_STATUS.PENDING))
    dispatch(
      apiRequest(
        "GET",
        "/user/message/rooms.php",
        action.payload,
        null,
        fetchSingleRoomSuccess,
        fetchSingleRoomError,
        true,
        "[mymessages]"
      )
    );
    dispatch(showSpinner("mymessages-getroom"));
  }
};

export const processSingleRoom = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === FETCH_SINGLE_ROOM_SUCCESS) {
    let room_data = action.payload.data
    let current_user_id = getState().user.id;
    let receiver = room_data.participants.find(participant => participant.user_id !== current_user_id)
    let has_unread_messages = room_data.participants.some(participant => participant.user_id === current_user_id && participant.has_unread_messages )
    let room_details = {
      room_id: room_data.room_id,
      room_name: receiver.user_name,
      image: {
        image_50: receiver.avatar ? receiver.avatar : Avatar,
        image_200: receiver.avatar ? receiver.avatar : Avatar,
        image_400: receiver.avatar ? receiver.avatar : Avatar,
      },
      participants: room_data.participants.map(participants => {
        return {
          id: participants.user_id,
          name: participants.user_name,
          avatar: {
            image_50: participants.avatar ? participants.avatar : Avatar,
            image_200: participants.avatar ? participants.avatar : Avatar,
            image_400: participants.avatar ? participants.avatar : Avatar,
          },
          has_unread_messages: participants.has_unread_messages,
        }
      }),
      hasUnreadMessage: has_unread_messages
    }
    
    dispatch(setActiveRoomDetails(room_details));
    dispatch(setSingleRoomStatus(OPERATION_STATUS.SUCCESS))
    dispatch(hideSpinner("mymessages-getroom"));
  }

  if (action.type === FETCH_SINGLE_ROOM_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "mymessages-getroom",
        notification_types.error
      )
    );
    dispatch(setSingleRoomStatus(OPERATION_STATUS.FAILURE))
    dispatch(hideSpinner("mymessages-getroom"));
  }
};


//***************************SEND MESSAGE LIST************************** */
export const updateUnreadMessageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_UNREAD_MESSAGE) {
    dispatch(updateUnreadMessageSetStatus(OPERATION_STATUS.PENDING));
    dispatch(showSpinner("mymessages-update-unread"));
    dispatch(
      apiRequest(
        "PUT",
        "/user/message/messages.php?action=updateUnreadMessageFlag ",
        null,
        action.payload,
        updateUnreadMessageSuccess,
        updateUnreadMessageError,
        true,
        "[mymessages]"
      )
    );
    
  }
};

// on successful fetch, process the books data
export const processUpdateUnreadMessage = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);
  if (action.type === UPDATE_UNREAD_MESSAGE_SUCCESS) {
    let activeRoom = getState().room.activeRoom.info
    if(activeRoom && activeRoom.hasUnreadMessage){
      // Update unread
      dispatch(setUnreadMessageFlag(activeRoom.room_id));
    }
    dispatch(updateUnreadMessageSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("mymessages-update-unread"));
  }
  if (action.type === UPDATE_UNREAD_MESSAGE_ERROR) {
    dispatch(updateUnreadMessageSetStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("mymessages-update-unread"));
  }
};

export const mymessagesMdl = [
  getRoomsFlow,
  processRoomsCollection,
  getNextRoomsFlow,
  processNextRoomsCollection,
  contactSellerFlow,
  processContactSeller,
  processCreateRoom,
  getMessagesFlow,
  processMessageCollection,
  getNextMessageFlow,
  processNextMessageCollection,
  sendMessageFlow,
  processSendMessage,
  getSingleRoomFlow,
  processSingleRoom,
  updateUnreadMessageFlow,
  processUpdateUnreadMessage
];
