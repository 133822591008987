export const GET_VENUE                = '[venue] GET';
export const FETCH_VENUE_SUCCESS      = '[venue] Fetch success';
export const FETCH_VENUE_ERROR        = '[venue] Fetch Error';
export const UPDATE_VENUE             = '[venue] Update';

// For fetching moments
export const getVenue = (data) => ({
    type: GET_VENUE,
    payload:data
  });

// For fetching moments
export const fetchVenueSuccess = (data) => ({
  type: FETCH_VENUE_SUCCESS,
  payload: data,
});

export const fetchVenueError = (data) => ({
  type: FETCH_VENUE_ERROR,
  payload: data
});


export const setVenue = (data) => ({
    type   : UPDATE_VENUE,
    payload: data
  });