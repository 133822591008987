import { combineReducers } from "redux";
// import adminUserReducer from './adminUserReducer'
import authReducer from "./authReducer";
import notificationReducer from "./notificationReducer";
import profileReducer from './profileReducer'
import { momentsReducer } from './momentshistoryReducer'
import { ticketsReducer } from './ticketsReducer'
import { myticketsReducer } from './myticketsReducer'
import { searchBarReducer } from './searchBarReducer'
import { ModalsReducer } from './uiReducer'
import { genreReducer } from './genreReducer'
import { landingPageReducer } from './landingPageReducer'
import { AppReducer } from "./appReducer";

import { 
  roomReducer,
  messageReducer
} from "./myMessagesReducer"
import {reportAdminReducer} from "./reportAdminReducer"
import {
  loginReducer,
  refreshReducer,
  userDetailsReducer,
  permissionReducer,
  tokenReducer,
  userStatusReducer
} from "./auth1Reducer";
import { sellerReducer } from "./sellerReducer"
import {myOfferReducer} from "./myOfferReducer"
import { AddressReducer } from "./addressReducer";
import {profileActiveReducer} from "./profileActiveReducer"
import {myCartReducer} from "./myCartReducer"
import {CheckoutReducer} from "./checkoutReducer"
import {myOrderReducer} from "./myOrderReducer"
import {orderPaymentReducer} from "./orderPaymentReducer"
import {ratingReducer} from "./ratingReducer"
import {accountReducer} from "./AccountReducer"
import {venueReducer} from "./venueReducer"
import {myCollectionReducer} from "./myCollectionReducer"
import { collectionReducer } from "./collectionsReducer";

export default combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  profile: profileReducer,
  moment: momentsReducer,
  ticket: ticketsReducer,
  searchBar: searchBarReducer,
  ui: ModalsReducer,
  genres: genreReducer,
  mytickets: myticketsReducer,
  landingpage: landingPageReducer,
  app: AppReducer,
  login: loginReducer,
  refresh: refreshReducer,
  user: userDetailsReducer,
  permissions: permissionReducer,
  token: tokenReducer,
  userState: userStatusReducer,
  message:  messageReducer,
  seller: sellerReducer,
  myOffer: myOfferReducer,
  room: roomReducer,
  Address: AddressReducer,
  profile: profileActiveReducer,
  cart: myCartReducer,
  checkout: CheckoutReducer,
  myOrder: myOrderReducer,
  orderpayment: orderPaymentReducer,
  rating:ratingReducer,
  reportAdmin:reportAdminReducer,
  Account: accountReducer,
  venue: venueReducer,
  collection: myCollectionReducer,
  allcollection:collectionReducer
});
