import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";
import "./TitleComponent.css";
import { Link } from "react-router-dom";

const TitleComponent = ({ type, seller, seller_id }) => {
  return (
    <React.Fragment>
      <div className="my-3 titleTicketDetails">
        <h6>Ticket details</h6>
      </div>
      <div className="ticketTypeClass">
        {type && (
          <>
            <span>
              <i className="fa fa-ticket mr-2" aria-hidden="true"></i>
              {type}
            </span>
          </>
        )}
      </div>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={6}>
          {/* <span className="spanCost">
            <i className="fa fa-usd" aria-hidden="true"></i>
            {cost}
          </span> */}
          <span className="spanSeller">
            <i className="fa fa-handshake-o" aria-hidden="true"></i>
            <Link key={seller_id} to={"/tickets?seller=" + seller_id}>
              {seller}
            </Link>
          </span>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Dropdown.Divider />
    </React.Fragment>
  );
};

TitleComponent.propTypes = {
  type: PropTypes.string.isRequired,
  seller: PropTypes.string.isRequired,
  seller_id: PropTypes.string.isRequired,
};

export default TitleComponent;
