import {
  LOAD_APP,
  setAppState,
  GET_PAYMENT_MASTER_DATA,
  FETCH_PAYMENT_MASTER_DATA_SUCCESS,
  FETCH_PAYMENT_MASTERR_DATA_ERROR,
  getPaymentMasterData,
  fetchPaymentMasterDataSuccess,
  fetchPaymentMasterDataError,
  setPaymentMasterData,
  getContents,
  GET_CONSTANTS,
  FETCH_CONSTANTS_SUCCESS,
  FETCH_CONSTANTS_ERROR,
  getContentsSuccess,
  getContentsError,
  setContents
} from "../../actions/app";
import {refresh} from "../../actions/auth"
import { showSpinner, hideSpinner } from "../../actions/ui";
import { siteRefresh } from "../../actions/landingpage";
import  {APP_STATES,OPERATION_STATUS} from '../../../js/constants'
import {getCartDetails} from "../../actions/myCart"
import {getContactAddress, getBillingAddress, getShippingAddress} from "../../actions/addressAction"
import { apiRequest } from "../../actions/api";

// this middleware only care about the getBooks action
export const loadAppFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOAD_APP) {
    dispatch(showSpinner());
    dispatch(refresh())
    dispatch(siteRefresh())
    dispatch(getPaymentMasterData())
    dispatch(getContents())
    // dispatch(
    //   apiRequest(
    //     "GET",
    //     "/genre.php",
    //     action.payload,
    //     null,
    //     fetchGenresSuccess,
    //     fetchGenresError,
    //     false,
    //     "[genre]"
    //   )
    // );
    
  }

};

// // this middleware only care about the getBooks action
// export const getUserDataFlow = ({ dispatch }) => (next) => (action) => {
//   next(action);

//   if (action.type === GET_USER_DATA) {
//     dispatch(
//       apiRequest(
//         "GET",
//         "/user/user_settings.php?action=refresh_token",
//         null,
//         null,
//         fetchUserDataSuccess,
//         fetchUserDataError,
//         false,
//         "[app]"
//       )
//     );
//   }

// };
export const getContentsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CONSTANTS) {
    dispatch(
      apiRequest(
        "GET",
        "/metaContents.php",
        null,
        null,
        getContentsSuccess,
        getContentsError,
        false,
        "[app]"
      )
    );
  }
};

// on successful fetch, process the books data
export const processContentsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CONSTANTS_SUCCESS) {
    let static_pages = action.payload.data.items
    dispatch(setContents(static_pages));
  }
  if (action.type === FETCH_CONSTANTS_ERROR) {
    dispatch(setContents(""));
  }
};
// // on successful fetch, process the books data
export const processUserData = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (getState().app.state === APP_STATES.LOADING && getState().refresh.refresh_status === OPERATION_STATUS.SUCCESS) {
    // let data = action.payload.data
    // dispatch(setUserData(action.payload));
    dispatch(setAppState(APP_STATES.LOADED));
    dispatch(hideSpinner());
    dispatch(getCartDetails());
    const user = {
      type: "CONTACT",
    };
    getContactAddress(user);
    const data = {
      type: "BILL",
    };
     getBillingAddress(data);
     const user_data = {
      type: "DELIVERY",
    };
    getShippingAddress(user_data);
  }

  if (getState().app.state === APP_STATES.LOADING && getState().refresh.refresh_status === OPERATION_STATUS.FAILURE) {
    // let error = action.payload.response.data.error.message
    // Check site is logged in
    
    // dispatch(setAppState(APP_STATES.LOADED));
    // dispatch(hideSpinner());
  }

  if (getState().app.state === APP_STATES.LOADING && getState().landingpage.site_refresh_status === OPERATION_STATUS.SUCCESS) {
    // let error = action.payload.response.data.error.message
    dispatch(setAppState(APP_STATES.LOADED));
    dispatch(hideSpinner());
  }

  if (getState().app.state === APP_STATES.LOADING && getState().landingpage.site_refresh_status === OPERATION_STATUS.FAILURE) {
    // let error = action.payload.response.data.error.message
    // Check site is logged in
    dispatch(setAppState(APP_STATES.LOADED));
    dispatch(hideSpinner());
  }

  
};

export const getPaymentMasterDataFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_PAYMENT_MASTER_DATA) {
    dispatch(
      apiRequest(
        "GET",
        "/masterPaymentData.php?action=payment",
        null,
        null,
        fetchPaymentMasterDataSuccess,
        fetchPaymentMasterDataError,
        false,
        "[app]"
      )
    );
  }

};

export const processPaymentMasterData = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_PAYMENT_MASTER_DATA_SUCCESS) {
    let paypal_client_id = action.payload.data.paypal_client_id
    dispatch(setPaymentMasterData(paypal_client_id));
    
  }

  if (action.type === FETCH_PAYMENT_MASTERR_DATA_ERROR) {
    dispatch(setPaymentMasterData(""));
  }

  
};

export const appMdl = [loadAppFlow, processUserData, getPaymentMasterDataFlow,processPaymentMasterData,getContentsFlow,processContentsCollection];
