import { UPDATE_APP, UPDATE_PAYMENT_MASTER_DATA, SET_CONSTANTS } from "../actions/app";
// import produce from 'immer'
import {APP_STATES} from '../../js/constants'

const INITIAL_STATE = {
  state: APP_STATES.LOADING,
  paypal_client_id: "",
  staticContents: []
};


export const AppReducer = (appState = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_APP:
      return {...appState,state: action.payload};
    case UPDATE_PAYMENT_MASTER_DATA:
      return {...appState,paypal_client_id: action.payload};
    case SET_CONSTANTS:
      return {...appState,staticContents: action.payload};
    default:
      return appState;
  }
};


// export const AppReducer = produce((draft, action) => {
//   switch (action.type) {
//     case UPDATE_APP:
//       draft.state = action.payload;
//       break;
//     default:
//       break;
//   }
// },INITIAL_STATE);
