import React from "react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import Address from "./AddressViewComponent/AddressViewComponent";
import OrderSummary from "./OrderSummaryComponent/OrderSummaryComponent";
import { ORDER_DELEVERED_STATES, ORDER_STATES } from "../../../js/constants";

const OrderDetails = ({
  order_no,
  order_date,
  expiry_date,
  payment_status,
  sub_orders,
  shipping_address,
  billing_address,
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="d-flex">
            <div className="mr-2">
              <div>
                <i className="fa fa-info-circle mr-2" aria-hidden="true"></i>
                <strong> Order id</strong>
              </div>
              <div>
                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                <strong> Ordered on</strong>
              </div>
              {
                (payment_status===ORDER_STATES.OPEN) &&
                (
                  <div>
                    <i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                    <strong> Payment Expires</strong>
                  </div>
                )
              }
            </div>
            <div>
              <div>{order_no}</div>
              <div>{order_date}</div>
              {
                payment_status===ORDER_STATES.OPEN &&
                (
                  <div>{expiry_date}</div>
                )
              }
            </div>
          </div>
          <Dropdown.Divider />
          <Row className="m-0">
            <Col xs={12} md={6} className="my-2 p-1">
              <strong> Shipping Address</strong>
              <Address
                first_name={shipping_address.first_name}
                last_name={shipping_address.last_name}
                address_line_1={shipping_address.address_line_1}
                address_line_2={shipping_address.address_line_2}
                administrative_area={shipping_address.administrative_area}
                city={shipping_address.city}
                country={shipping_address.country}
                mobile_no={shipping_address.mobile_no}
                postal_code={shipping_address.postal_code}
              />
            </Col>
            <Col xs={12} md={6} className="my-2 p-1">
              <strong> Billing Address</strong>
              <Address
                first_name={billing_address.first_name}
                last_name={billing_address.last_name}
                address_line_1={billing_address.address_line_1}
                address_line_2={billing_address.address_line_2}
                administrative_area={billing_address.administrative_area}
                city={billing_address.city}
                country={billing_address.country}
                mobile_no={billing_address.mobile_no}
                postal_code={billing_address.postal_code}
              />
            </Col>
          </Row>
          <Dropdown.Divider />
          <OrderSummary sub_orders={sub_orders} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default OrderDetails;
