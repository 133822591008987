import React, { useEffect } from "react";
import { Button, Dropdown, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import "../TicketSeller/TicketSeller.css";
import { connect } from "react-redux";
import {
  contactSeller,
  // reportingSeller,
  setContactSellerStatus,
} from "../../../store/actions/mymessages";
import { setOfferItems } from "../../../store/actions/myOffer";
import { showModal } from "../../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../../js/constants";
import TicketsCardSelectedComponent from "../TicketsCardSelectedComponent/TicketsCardSelectedComponent";
import CreateOffer from "./CreateOffer/CreateOffer";
import { api as UserAPI } from "../../../apis/userProtectedApis.instance";
import ReportSellerModal from "./ReportSellerModal";
import {
  getSellerRatingsList,
  getNextSellerRatingsList
} from "../../../store/actions/rating";
import SellerRatings from "./SellerRatings/SellerRatings";

const TicketSeller = ({
  sellerName,
  sellerId,
  activeRoomId,
  isContactSellerLoading,
  contactSeller,
  reportingSeller,
  getSellerRatingsList,
  getNextSellerRatingsList,
  setContactSellerStatus,
  showModal,
  isLogged,
  loggedUserId,
  contact_seller_status,
  offeredTickets,
  remove,
  backFromSeller,
  setOfferItems,

  // for Ratings
  isLoading,
  ratings_list,
  report_types,
  hasmore,
  page,
}) => {
  const history = useHistory();

  const getSellerItems = async (seller_id) => {
    try {
      let queryparams = {
        full_result: 1,
        seller: seller_id,
      };
      let response = await UserAPI.Offers.getSellerTickets(queryparams);
      let ticketsRecords = response.data.data.items.map((ticket) => {
        return {
          product_id: ticket.product_id,
          name: ticket.listing_name,
          cost: ticket.sell_details.cost,
          image: ticket.images[0],
        };
      });
      return ticketsRecords;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const [isOfferDisabled, setOfferDisabled] = useImmer(false);
  const [isOfferLoading, setOfferLoading] = useImmer(false);
  const [seller_items, setSellerItems] = useImmer([]);

  // Disable offer button when current user is the seller
  useEffect(() => {
    if (isLogged && loggedUserId === sellerId) {
      setOfferDisabled(true);
    }
  }, [isLogged, loggedUserId, sellerId]);

  useEffect(() => {
    if (contact_seller_status === OPERATION_STATUS.SUCCESS && activeRoomId) {
      setContactSellerStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/account/mymessages/" + activeRoomId,
      });
    }
  }, [activeRoomId, contact_seller_status]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (isLogged) {
      contactSeller(sellerId);
    } else {
      showModal("login");
    }
  };
  
  const sellerComplaint = (e) => {
    e.preventDefault();
    if (isLogged) {
      showModal("ReportSellerId");
    } else {
      showModal("login");
    }
  };

  //get Ratings Details
  useEffect(() => {
    const data = {
      seller_id: sellerId,
    };
    getSellerRatingsList(data);
  }, []);

 

  const nextPage = () => {
    const data = {
      page: page,
      seller_id: sellerId,
    };
    getNextSellerRatingsList(data);
  };

  useEffect(() => {
    if (contact_seller_status === OPERATION_STATUS.SUCCESS && activeRoomId) {
      setContactSellerStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/account/mymessages/" + activeRoomId,
      });
    }
  }, [activeRoomId, contact_seller_status]);

  const makeOffer = async (e) => {
    e.preventDefault();
    if (isLogged) {
      setOfferLoading(true);
      // Get seller items
      setOfferItems(offeredTickets);
      let seller_Tickets = await getSellerItems(sellerId);
      setSellerItems(seller_Tickets);
      setOfferLoading(false);
      // Open offer modal
      showModal("OfferView");
    } else {
      showModal("login");
    }
  };
  return (
    <React.Fragment>
      <div className="d-flex flex-nowrap">
        <div className="flex-grow-1">
          <div className="">
            <span className="font-weight-bold mr-2">Seller:</span>
            <span>{sellerName}</span>
          </div>
          <div className="">
            <Link to="contact_seller" onClick={(e) => sendMessage(e)}>
              <i className="fa fa fa-envelope mr-2" aria-hidden="true"></i>
              Send Message
              {isContactSellerLoading && (
                <span
                  className="ml-2 spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </Link>
          </div>
          <div className="">
          <Link to="/reportSeller" onClick={(e) => {sellerComplaint(e)}}>
            <i className="fa fa-flag mr-2" aria-hidden="true"></i>
            Report Seller
          </Link>
          </div>
        </div>
        <div className="mr-2">
          <Button
            className="btnBuySearchResult "
            onClick={(e) => makeOffer(e)}
            disabled={
              isOfferDisabled || isOfferLoading || offeredTickets.length === 0
            }
          >
            {isOfferLoading && (
              <span
                className="mr-2 spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Make Offer
          </Button>
        </div>
        <div className="">
          <Button
            className="btnBuySearchResult alignstartbutton"
            onClick={backFromSeller}
          >
            Back
          </Button>
        </div>
      </div>
      <Dropdown.Divider />
      <div className="font-weight-bold">Rating and Reviews</div>
      <SellerRatings
        isLoading={isLoading}
        ratings={ratings_list}
        hasmore={hasmore}
        nextPage={nextPage}
      />
      <Dropdown.Divider />
      <div className="font-weight-bold">Offer tickets</div>
      <Row className="my-2">
        <Col>
          {offeredTickets.length === 0 && (
            <div className="text-center">No Tickets is selected</div>
          )}
          <div className="d-flex flex-wrap">
            {offeredTickets.map((ticket) => {
              return (
                <TicketsCardSelectedComponent
                  key={ticket.product_id}
                  product_id={ticket.product_id}
                  listing_name={ticket.listing_name}
                  image={ticket.image}
                  cost={ticket.cost}
                  remove={remove}
                />
              );
            })}
          </div>
        </Col>
      </Row>

      <Dropdown.Divider />
      <CreateOffer seller_items={seller_items} seller_id={sellerId} />
      {/* seller report modal */}
      <ReportSellerModal
        seller_id={sellerId}
        content = ""
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isContactSellerLoading:
      state.room.contact_seller.status === OPERATION_STATUS.PENDING,
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
    loggedUserId: state.user ? state.user.id : null,
    contact_seller_status: state.room.contact_seller.status,
    activeRoomId: state.room.activeRoom.info
      ? state.room.activeRoom.info.room_id
      : null,
    ratings_list: state.rating.seller_ratings.ratings,
    hasmore: state.rating.seller_ratings.hasMore,
    page: state.rating.seller_ratings.page,
    isLoading:
      "seller_ratings_init_list" in state.ui.loaders
        ? state.ui.loaders["seller_ratings_init_list"]
        : true,
  };
};

export default connect(mapStateToProps, {
  contactSeller,
  setContactSellerStatus,
  setOfferItems,
  getSellerRatingsList,
  getNextSellerRatingsList,
  showModal
})(TicketSeller);
