export const SET_SEARCH_BAR_VALUE = "[search] set value";
export const REMOVE_SEARCH_BAR_VALUE = "[search] remove value";

export const setSearchBar = (value) => ({
  type: SET_SEARCH_BAR_VALUE,
  payload: value,
});

export const removeSearchBar = () => ({
  type: REMOVE_SEARCH_BAR_VALUE
});
