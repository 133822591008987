import React from "react";
import "./ReplyView.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import TextMessage from "./TextMessage/TextMessage";

const ReplyView = ({ replies, hasMore, nextPage, isLoading }) => {
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div
          id="chatScrollableDiv"
          style={{
            height: 330,
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {replies.map((reply) => {
            return (
              <TextMessage
                key={reply.id}
                content={reply.message}
                time={reply.send_date}
                sender_name={reply.sender_details.name}
                sender_image={reply.sender_details.avatar.image_50}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default ReplyView;
