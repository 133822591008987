import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import CheckoutShippingAddress from "./CheckoutShippingAddress";
import ShippingAddressChangeForm from "./ShippingAddressChangeForm";
import { getShippingAddress } from "../../../store/actions/addressAction";
import {
  updateCheckoutShippingAddress,
  updateCheckoutShippingAddressSetStatus,
  addUserShippingAddressSetStatus,
  editUserShippingAddressSetStatus,
  addUserShippingAddress,
  editUserShippingAddress
} from "../../../store/actions/checkout";
import { OPERATION_STATUS } from "../../../js/constants";
import AddressAddEditModel from "../AdressAddEditModel/AddressAddEditModel";
import { showModal, hideModal } from "../../../store/actions/ui";

const ShippingAddressPage = ({
  shipping_address,
  user_shipping_addresses,
  getShippingAddress,
  checkout_id,
  updateCheckoutShippingAddress,
  updateCheckoutShippingAddressSetStatus,
  isUpdateShippingLoading,
  updateCheckoutShippingAddressStatus,
  addressModalShow,
  showModal,
  hideModal,
  editShippingAddressStatus,
  editUserShippingAddressSetStatus,
  addUserShippingAddress,
  editUserShippingAddress,
  addShippingAddressStatus,
  addUserShippingAddressSetStatus
}) => {
  useEffect(() => {
    const user_data = {
      type: "DELIVERY",
    };
    getShippingAddress(user_data);
  }, []);

  var initial_state = {
    address:{
      id: "",
      type: "DELIVERY",
      first_name: "",
      last_name: "",
      mobile_no: "",
      address_line_1: "",
      address_line_2: "",
      administrative_area: "",
      city: "",
      postal_code: "",
      country: ""
    },
    operation: "Add"
  };
  // Set State value using immer
  const [address_form, setAddressForm] = useImmer(initial_state);

  const [showCheckoutAddress, setShowCheckoutAddress] = useImmer(true);
  const [showShippingAddress, setShowShippingAddress] = useImmer(false);
  const [isAddressModalLoading, setAddressModalLoading] = useImmer(false);
  const [checkout_address_id, setCheckoutAddressId] = useImmer(0);

  const changeAddress = () => {
    setCheckoutAddressId(shipping_address.id);
    setShowCheckoutAddress(false);
    setShowShippingAddress(true);
  };

  const cancelChangeAddress = () => {
    setShowCheckoutAddress(true);
    setShowShippingAddress(false);
  };

  const updateCheckoutAddress = () => {
    let data = {
      checkout_id: checkout_id,
      address_id: checkout_address_id,
    };
    updateCheckoutShippingAddress(data);
  };

  useEffect(() => {
    if (updateCheckoutShippingAddressStatus === OPERATION_STATUS.SUCCESS) {
      updateCheckoutShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      cancelChangeAddress();
    } else if (
      updateCheckoutShippingAddressStatus === OPERATION_STATUS.FAILURE
    ) {
      updateCheckoutShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [updateCheckoutShippingAddressStatus]);


  // Edit shipping address
  useEffect(() => {
    if (editShippingAddressStatus === OPERATION_STATUS.SUCCESS) {
      editUserShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressForm(initial_state);
      setAddressModalLoading(false)
      hideModal("checkout-shipping-modal")
    } else if (
      editShippingAddressStatus === OPERATION_STATUS.FAILURE
    ) {
      editUserShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressModalLoading(false)
    }
  }, [editShippingAddressStatus]);

   // Add shipping address
   useEffect(() => {
    if (addShippingAddressStatus === OPERATION_STATUS.SUCCESS) {
      if(user_shipping_addresses.length === 1){
        // Only one address is there so set it to that address
        let data = {
          checkout_id: checkout_id,
          address_id: user_shipping_addresses[0].id,
        };
        updateCheckoutShippingAddress(data);
      }
      addUserShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressForm(initial_state);
      setAddressModalLoading(false)
      hideModal("checkout-shipping-modal")

    } else if (
      addShippingAddressStatus === OPERATION_STATUS.FAILURE
    ) {
      editUserShippingAddressSetStatus(OPERATION_STATUS.YET_TO_START);
      setAddressModalLoading(false)
    }
  }, [addShippingAddressStatus]);

  const addAddress = () => {
    setAddressForm(initial_state);
    showModal("checkout-shipping-modal")
  };

  const editAddress = (id) => {
    let address = user_shipping_addresses.find(s=>parseInt(s.id) === parseInt(id))
    setAddressForm((draft) => {
      draft.address.id = address.id;
      draft.address.type = address.type;
      draft.address.first_name = address.first_name;
      draft.address.last_name = address.last_name;
      draft.address.mobile_no = address.mobile_no;
      draft.address.address_line_1 = address.address_line_1;
      draft.address.address_line_2 = address.address_line_2;
      draft.address.administrative_area = address.administrative_area;
      draft.address.city = address.city;
      draft.address.postal_code = address.postal_code;
      draft.address.country = address.country;
      draft.operation = "update";
    });
    showModal("checkout-shipping-modal")
  };


  const Submit = (address) => {
    let data = {
      type : "DELIVERY",
      first_name : address.first_name,
      last_name : address.last_name,
      mobile_no : address.mobile_no,
      address_line_1 : address.address_line_1,
      address_line_2 : address.address_line_2,
      administrative_area : address.administrative_area,
      city : address.city,
      postal_code : address.postal_code,
      country : address.country
    }
    setAddressModalLoading(true)
    if(address_form.operation === "Add"){
      // Add address
      addUserShippingAddress(data)
      
    }else if(address_form.operation === "update"){
      // Edit address
      data.id = address.id;
      editUserShippingAddress(data)
    }
  }

  const Cancel = () => {
    setAddressForm(initial_state);
    hideModal("checkout-shipping-modal")
  }

  return (
    <>
      {showCheckoutAddress && (
        <CheckoutShippingAddress
          checkout_shipping_address={shipping_address}
          add_Address={addAddress}
          change_Address={changeAddress}
        />
      )}
      {showShippingAddress && shipping_address && (
        <ShippingAddressChangeForm
          shipping_addresses={user_shipping_addresses}
          checkout_address_id={checkout_address_id}
          editAddress={editAddress}
          updateCheckoutAddress={updateCheckoutAddress}
          onChangeAddress={(id) => setCheckoutAddressId(id)}
          cancelChangeAddress={cancelChangeAddress}
          isLoading={isUpdateShippingLoading}
          add_Address={addAddress}
        />
      )}
      <AddressAddEditModel 
        loading={isAddressModalLoading}
        showAddressModal={addressModalShow}
        address={address_form.address}
        operation={address_form.operation}
        title="Shipping"
        onSubmit={Submit}
        onCancel={Cancel}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    shipping_address: state.checkout.checkout_details.shipping_address,
    user_shipping_addresses: state.Address.ShippingAddressList,
    isUpdateShippingLoading:
      state.checkout.update_shipping_address_status ===
      OPERATION_STATUS.PENDING,
    updateCheckoutShippingAddressStatus:
      state.checkout.update_shipping_address_status,
    editShippingAddressStatus:
      state.checkout.edit_shipping_address_status,
    addShippingAddressStatus:
      state.checkout.add_shipping_address_status,
    checkout_id: state.checkout.checkout_details.checkout_id,
    addressModalShow: state.ui.activeModal === "checkout-shipping-modal",
  };
};

export default connect(mapStateToProps, {
  getShippingAddress,
  updateCheckoutShippingAddress,
  updateCheckoutShippingAddressSetStatus,
  showModal,
  hideModal,
  editUserShippingAddressSetStatus,
  addUserShippingAddress,
  editUserShippingAddress,
  addUserShippingAddressSetStatus
})(ShippingAddressPage);
