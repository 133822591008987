import React from "react";
import { Card, Button, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import OfferCardHeader from "./OfferCardHeader/OfferCardHeader";
import OfferItemCard from "./OfferItem/OfferItem";
import { useImmer } from "use-immer";

const OfferCardDetails = ({
  offer_no,
  offer_id,
  created_by,
  created_at,
  items,
  type,
  price,
  message,
  isEdit,
  handleEdit,
  showEdit,
  showOfferCardDetailsBody,
}) => {
  const [showOfferCardBody, setShowOfferCardBody] = useImmer(
    showOfferCardDetailsBody
  );
  return (
    <React.Fragment>
      {!showEdit && (
        <Card>
          <Card.Header
            className="py-1 px-2"
            style={{ cursor: "pointer" }}
            onClick={() => setShowOfferCardBody(!showOfferCardBody)}
          >
 
            <OfferCardHeader
              created_at={created_at}
              created_by={created_by}
              message={message}
              type={type}
              price={price}
            />
          </Card.Header>
          <Collapse in={showOfferCardBody}>
            <Card.Body>
              <div className="d-flex">
                {items.map((item) => {
                  return (
                    <OfferItemCard
                      key={item.id}
                      listing_name={item.name}
                      image={item.image}
                      cost={item.price}
                    />
                  );
                })}
              </div>
            </Card.Body>
          </Collapse>
          {isEdit && (
            <Card.Footer className="py-1 px-2">
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => handleEdit(offer_no, offer_id, true)}
                  className="text-center"
                >
                  Edit
                </Button>
              </div>
            </Card.Footer>
          )}
        </Card>
      )}
    </React.Fragment>
  );
};

OfferCardDetails.propTypes = {
  offer_no: PropTypes.string.isRequired,
  offer_id: PropTypes.number.isRequired,
  created_by: PropTypes.object.isRequired,
  created_at: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  message: PropTypes.string,
  isEdit: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  showEdit: PropTypes.bool.isRequired,
};

export default OfferCardDetails;
