import React from "react";
import * as ReactBootstrap from "react-bootstrap";

import TicketMandatoryItems from "./TicketMandatoryItems";
import TicketOptionalItems from "./TicketOptionalItems";
// import { useImmer } from "use-immer";
// import { useHistory } from "react-router-dom";

const TicketForm = ({
  value,
  options,
  onChangeValue,
  addTeamArtist,
  removeTeamArtist,
  onSubmit,
  handleImageChange,
  removeImage,
  handleDocsChange,
  onCancel,

  onEditOption,
  onEditInputCancel,
  onEventOptionEdit,
  operation

}) => {
  // const history = useHistory();

  return (
    <ReactBootstrap.Row className="parentAddTicket">
      <ReactBootstrap.Col>
        <ReactBootstrap.Card>
          <ReactBootstrap.Card.Header as="h5">
            {operation} Tickets
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            <ReactBootstrap.Form onSubmit={onSubmit}>
              <TicketMandatoryItems
                value={value}
                options={options}
                onChangeValue={onChangeValue}
                addTeamArtist={addTeamArtist}
                removeTeamArtist={removeTeamArtist}
                handleImageChange={handleImageChange}
                removeImage={removeImage}
                onEditOption={onEditOption}
                onEditInputCancel={onEditInputCancel}
                onEventOptionEdit={onEventOptionEdit}

              />

              {/* <span
                className="expandManageAddress"
                onClick={() =>
                  onChangeValue("ticket_additional_info","","")
                }
              >
                Additional information
              </span> */}
              <ReactBootstrap.Dropdown.Divider />
              <TicketOptionalItems
                value={value.additional_options}
                error={value.error.additional_options}
                options={options}
                onChangeValue={onChangeValue}
                handleDocsChange={handleDocsChange}
              />
              {/* <ReactBootstrap.Collapse in={value.additional_options.isopen}>
                <ReactBootstrap.Card>
                  <ReactBootstrap.Card.Body>
                    <TicketOptionalItems
                      value={value.additional_options}
                      error={value.error.additional_options}
                      options={options}
                      onChangeValue={onChangeValue}
                      handleDocsChange={handleDocsChange}
                    />
                  </ReactBootstrap.Card.Body>
                </ReactBootstrap.Card>
              </ReactBootstrap.Collapse> */}
              <ReactBootstrap.Row className="align_props my-2">
                <ReactBootstrap.Button
                  className="space_button"
                  variant="outline-primary"
                  onClick={onCancel}
                >
                  Cancel
                </ReactBootstrap.Button>
                <ReactBootstrap.Button as="input" type="submit" value="Save" />
              </ReactBootstrap.Row>
            </ReactBootstrap.Form>
          </ReactBootstrap.Card.Body>
        </ReactBootstrap.Card>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
};

export default TicketForm;
