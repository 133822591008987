import axios from "axios";
import * as configs from "../js/constants";
import qs from 'qs'



const userAPI = axios.create({
  baseURL: configs.API_BASE_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});


//functions to make api calls
const api = {
    User: {
      loginUser: (body) => {
        return userAPI.post(`${configs.API_BASE_URL}/user/user_settings.php?action=login`, body);
      },
      register: (body) => {
        return userAPI.post(`${configs.API_BASE_URL}/user/user_settings.php?action=register`, body);
      },
      mailValidate: (body) => {
        return userAPI.post(`${configs.API_BASE_URL}/user/user_settings.php?action=verify`, body);
      },
      forgetPassword: (body) => {
        return userAPI.post(`${configs.API_BASE_URL}/user/user_settings.php?action=resetmail`, body);
      },
      resetPassword: (body) => {
        return userAPI.post(`${configs.API_BASE_URL}/user/user_settings.php?action=resetpassword`, body);
      },
      refreshToken: () => {
        return userAPI.get("/user/user_settings.php?action=refresh_token");
      }
    },
    Filters: {
      getGenre: (content) => {
        const queryparams = {
          status: "APPROVED",...content
        }
        return userAPI.get('/genre.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      getSubGenre: (content) => {
        const queryparams = {
          status: "APPROVED",...content
        }
        return userAPI.get('/admin/sub_genre.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      getVenue: (content) => {
        const queryparams = {
          status: "APPROVED",...content
        }
        return userAPI.get('/venues.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      getTeam: (content) => {
        const queryparams = {
          status: "APPROVED",...content
        }
        return userAPI.get('/teams.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      getArtist: (content) => {
        const queryparams = {
          status: "APPROVED",...content
        }
        return userAPI.get('/artist.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      getEvent: (content) => {
        const queryparams = {
          status: "APPROVED",...content,
          full_result: 1
        }
        return userAPI.get('/events.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      

    }
};

export {
    api,
    userAPI
}
