import {
  SET_LOGIN_STATUS,
  SET_REFRESH_STATUS,
  UPDATE_USER_PERMISSIONS,
  SET_TOKENS,
  UPDATE_USER_DATA,
  SET_USER_STATUS,
  SET_LOGOUT_STATUS
} from "../actions/auth";
// import produce from 'immer'
import { OPERATION_STATUS, USER_STATES } from "../../js/constants";

const INITIAL_LOGIN_STATE = {
  login_status: OPERATION_STATUS.YET_TO_START,
};

export const loginReducer = (loginState = INITIAL_LOGIN_STATE, action) => {
  switch (action.type) {
    case SET_LOGIN_STATUS:
      return { ...loginState, login_status: action.payload };
    default:
      return loginState;
  }
};

const INITIAL_REFRESH_STATE = {
  refresh_status: OPERATION_STATUS.YET_TO_START,
};

export const refreshReducer = (
  refreshState = INITIAL_REFRESH_STATE,
  action
) => {
  switch (action.type) {
    case SET_REFRESH_STATUS:
      return { ...refreshState, refresh_status: action.payload };
    default:
      return refreshState;
  }
};

const INITIAL_TOKENS = null;

export const tokenReducer = (tokens = INITIAL_TOKENS, action) => {
  switch (action.type) {
    case SET_TOKENS:
      return action.payload;
    default:
      return tokens;
  }
};

const INITIAL_PERMISSIONS = [];

export const permissionReducer = (
  permissions = INITIAL_PERMISSIONS,
  action
) => {
  switch (action.type) {
    case UPDATE_USER_PERMISSIONS:
      return action.payload;
    default:
      return permissions;
  }
};

const INITIAL_USERDATA = null;

export const userDetailsReducer = (user = INITIAL_USERDATA, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return action.payload;
    default:
      return user;
  }
};

const INITIAL_USER_STATE = {
  user_status: USER_STATES.ANONYMOUS,
};

export const userStatusReducer = (userState = INITIAL_USER_STATE, action) => {
  switch (action.type) {
    case SET_USER_STATUS:
      return { ...userState, user_status: action.payload };
    default:
      return userState;
  }
};


const INITIAL_LOGOUT_STATE = {
  logout_status: OPERATION_STATUS.YET_TO_START,
};

export const logoutReducer = (logoutState = INITIAL_LOGOUT_STATE, action) => {
  switch (action.type) {
    case SET_LOGOUT_STATUS:
      return { ...logoutState, logout_status: action.payload };
    default:
      return logoutState;
  }
};