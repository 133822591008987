import React from "react";
import { connect } from "react-redux";
import ProductItem from "./ProductItem/ProductItem";

function ShippingDetails({ line_items, seller_id, seller_name }) {
  return (
    <React.Fragment>
      <strong>Shipment details</strong>

      {line_items.map((items,idx) => {
        return (
          <ProductItem
            key={idx}
            type={items.type}
            base_price={items.base_price.value}
            item_details={items.item_details}
            seller_id={seller_id}
            seller_name={seller_name}
          />
        );
      })}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(ShippingDetails);
