import React from "react";
import * as ReactBootstarp from "react-bootstrap";
import "./MyTicketCardComponent.css";
import { Link } from "react-router-dom";
import ArrowIconRight from "../../../images/arrow-right-one.svg";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import Badge from "react-bootstrap/Badge";
import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../../js/constants";
import { connect } from "react-redux";
import { getMyTicketRenderStatus } from "../../../js/utils";
import { TICKET_STATES } from "../../../js/constants";

const MyTicketCardComponent = ({
  product_id,
  listing_name,
  seller,
  event_name,
  significance,
  venue,
  date,
  teams,
  artists,
  cost,
  isSale,
  image,
  description,
  status,
  onEdit,
  onDelete,
  isActiveSeller,
}) => {
  const getBadgeClassName = (status) => {
    let className = "";
    switch (status) {
      case TICKET_STATES.OWNED:
      case TICKET_STATES.SOLD:
      case TICKET_STATES.SELL:
        className = "badge-success";
        break;
      case TICKET_STATES.INACTIVE:
        className = "badge-danger";
        break;
      case TICKET_STATES.IN_CHECK_OUT:
      case TICKET_STATES.IN_ORDER:
      case TICKET_STATES.CART:
      case TICKET_STATES.OFFER:
        className = "badge-info";
        break;
      default:
        break;
    }
    return className;
  };
  const [DeletemodalShow, setDeleteModalShow] = React.useState(false);
  return (
    <React.Fragment>
      <ReactBootstarp.Card className="searchResultCard my-4">
        <ReactBootstarp.Card.Body>
          <ReactBootstarp.Row>
            <ReactBootstarp.Col xs={12} md={2} className="text-center">
              <div>
                <Link to={"/mytickets/" + product_id} target="_blank">
                  <div className="mb-2 divTicketImage">
                    <img
                      src={image.image_400}
                      alt="ticket_image"
                      width={100}
                      height={100}
                    />
                  </div>
                </Link>
              </div>
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={8}>
              {/* <Link to={"/mytickets/" + ticket.product_id} target="_blank"> */}
              {/* <Link
                to={`/mytickets/${product_id}`}
                target="_blank"
              > */}
              <div className="searchResultEventName textBlackColor">
                <Link to={"/mytickets/" + product_id} target="_blank">
                  <h5>{listing_name}</h5>
                </Link>
                <div className="font-weight-bold my-2">
                { isSale !== 0 ?(
                  <>
                  <i className="fa fa-usd" aria-hidden="true"></i> {cost}
                  </>  
                ):(
                  <p>Not for sale</p>
                )
                  }
                </div>
                <span>{event_name}</span>
                {significance && (
                  <>
                    <span>|</span>
                    <span>{significance}</span>
                  </>
                )}
              </div>
              <ReactBootstarp.Dropdown.Divider />
              <ReactBootstarp.Row>
                <ReactBootstarp.Col xs={12} md={6}>
                  <div className="searchResultLeftColumn textBlackColor">
                    <div>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span className="searchResultLocation">{venue}</span>
                    </div>
                    <div>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <span className="searchResultDate">{date}</span>
                    </div>
                  </div>
                </ReactBootstarp.Col>
                <ReactBootstarp.Col
                  xs={12}
                  md={6}
                  className="searchResultCenterColumn textBlackColor"
                >
                  {teams.length > 0 && (
                    <div>
                      <i className="fa fa-users" aria-hidden="true"></i>
                      <span className="searchResultTeamArt">
                        {teams.join(", ")}
                      </span>
                    </div>
                  )}
                  {artists.length > 0 && (
                    <div>
                      <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                      <span className="searchResultTeamArt">
                        {artists.join(", ")}
                      </span>
                    </div>
                  )}
                </ReactBootstarp.Col>
              </ReactBootstarp.Row>
              {/* </Link> */}
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={2}>
              <div className="d-flex flex-wrap">
                <div className="mb-2">
                  <Badge className={getBadgeClassName(status)}>{getMyTicketRenderStatus(status)}</Badge>
                </div>
                {isActiveSeller && status === TICKET_STATES.OWNED && (
                  <div>
                    <ReactBootstarp.Button className="btnMoveSeller">
                      Move To Sell
                      <span>
                        <img src={ArrowIconRight} alt="ArrowIconRight" />
                      </span>
                    </ReactBootstarp.Button>
                  </div>
                )}
                {isActiveSeller &&
                  !(
                    status === TICKET_STATES.SOLD ||
                    status === TICKET_STATES.IN_CHECK_OUT ||
                    status === TICKET_STATES.CART ||
                    status === TICKET_STATES.OFFER ||
                    status === TICKET_STATES.IN_ORDER ||
                    status === TICKET_STATES.INACTIVE
                  ) && (
                    <>
                      <div>
                        <ReactBootstarp.Button
                          className="btnEdit"
                          onClick={() => onEdit(product_id)}
                        >
                          <span>
                            <img src={EditIcon} alt="EditIcon" />
                          </span>
                          Edit
                        </ReactBootstarp.Button>
                      </div>
                      <div>
                        <ReactBootstarp.Button
                          className="btnDelete"
                          onClick={() => setDeleteModalShow(true)}
                        >
                          <span>
                            <img src={DeleteIcon} alt="DeleteIcon" />
                          </span>
                          Delete
                        </ReactBootstarp.Button>
                      </div>
                    </>
                  )}
              </div>
            </ReactBootstarp.Col>
          </ReactBootstarp.Row>
          <DeleteDialog
            id={product_id}
            name={listing_name}
            show={DeletemodalShow}
            hideModal={() => setDeleteModalShow(false)}
            onDelete={(id) => {
              setDeleteModalShow(false);
              onDelete(id);
            }}
          />
        </ReactBootstarp.Card.Body>
      </ReactBootstarp.Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) =>
              permission.permission === USER_PERMISSIONS.SELL &&
              permission.status === USER_PERMISSIONS_STATES.ACTIVE
          )
        : false,
  };
};

export default connect(mapStateToProps, null)(MyTicketCardComponent);
