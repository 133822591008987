import React from "react";
import * as ReactBootstrap from "react-bootstrap";

const TicketEvent = ({
  event_name,
  significance,
  venue,
  date,
  teams,
  artists,
}) => {
  return (
    <>
      <React.Fragment>
        <ReactBootstrap.Container className="my-4">
          {/* <div className="my-3 ">
            <h6>Event details</h6>
          </div> */}
          {event_name && (
            <div className="mb-3">
              <strong> {event_name} </strong>
            </div>
          )}
          <div className="mb-3">
            <span className="mr-4" title="Event date">
              <i
                className="fa fa-calendar mr-2"
                aria-hidden="true"
                title="Date"
              ></i>{" "}
              {date}
            </span>
            <span title="Event venue"> 
              <i
                className="fa fa-map-marker mr-2"
                aria-hidden="true"
                title="Venue"
              ></i>{" "}
              {venue}
            </span>
          </div>
          {/* {venue && (
            <div className="mb-3">
              <i
                className="fa fa-map-marker"
                aria-hidden="true"
                title="Event Venue"
              ></i>
              <span className="searchResultTeamArt">{venue}</span>
            </div>
          )}
          {date && (
            <div className="mb-3">
              <i
                className="fa fa-calendar"
                aria-hidden="true"
                title="Event Date"
              ></i>
              <span className="searchResultTeamArt">{date}</span>
            </div>
          )} */}
          {teams.length > 0 && (
            <div className="mb-3" title="Teams">
              <i className="fa fa-users" aria-hidden="true" title="Teams"></i>
              <span className="searchResultTeamArt">{teams.join(", ")}</span>
            </div>
          )}
          {artists.length > 0 && (
            <div className="mb-3" title="Artists">
              <i
                className="fa fa-user-circle-o"
                aria-hidden="true"
                title="Artist"
              ></i>
              <span className="searchResultTeamArt">{artists.join(", ")}</span>
            </div>
          )}
          {significance && <div className="mb-3">{significance}</div>}
          <ReactBootstrap.Dropdown.Divider />
        </ReactBootstrap.Container>
      </React.Fragment>
    </>
  );
};

export default TicketEvent;
