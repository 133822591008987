import React from "react";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import "../TicketSeller/TicketSeller.css";
import { AddSellerReportInfo,setSellerReportStatus } from "../../../store/actions/seller"
import { hideModal } from "../../../store/actions/ui";
import Loader from "../../../common/LoaderComponent/LoaderComponent";
import { Spinner } from "react-bootstrap";
import { OPERATION_STATUS } from "../../../js/constants";
import {
    getReportTypes
  } from "../../../store/actions/rating";
function ReportSellerModal({
  loading,
  ReportSellerId, // to show and hide the modal popup
  seller_id, // Input params for API call
  hideModal, // for modal hide property
  AddSellerReportInfo, // to pass input params for API call
  isSellerReportStatus,
  getReportTypes,
  report_types,
  content
}) {
  // set the initial value for the modal form
  var initial_state = {
    content: "",
    report_type: null,
    error: {
        content: "",
        report_type: ""
    },
  };
  // Set State value using immer
  const [state, setSellerReport] = useImmer(initial_state);
  useEffect(() => {
    SetValue();
  }, []);
  // function at page load
  const SetValue = async () => {
    setSellerReport((draft) => {
      draft.content = "";
      draft.report_type = null;
      draft.seller_id = seller_id;
    });
  };
  useEffect(() => {
    if (isSellerReportStatus === OPERATION_STATUS.SUCCESS) {
        setSellerReportStatus(OPERATION_STATUS.YET_TO_START);
    } else if (isSellerReportStatus === OPERATION_STATUS.FAILURE) {
        setSellerReportStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [setSellerReportStatus]);
  useEffect(() => {
    getReportTypes();
  }, []);
  // on change event for form
  const handleInputChange = (name,e) => {
    switch(name) {
      case 'content':
        setSellerReport((draft) => {
          draft[name] = e.target.value;
        });
      break;
      case 'report_type':
        setSellerReport((draft) => {
          draft[name] = e;
          draft.error.report_type = "";
        });
      break;
      default:
      break;
    }
    
  };
  
  // API call for update seller payment details
  const Submit = () => {
    if (state.report_type === null) {
      setSellerReport((draft) => {
        draft.error.report_type = "Please choose an option.";
      });
      return false;
    } else {
      setSellerReport((draft) => {
        draft.error.report_type = "";
      });
    }
    // input params for the API call
    const seller_report = {
      content: state.content,
      report_type: state.report_type.value,
      report_on: state.seller_id,
    };
    
    AddSellerReportInfo(seller_report);
    
  };
  return (
    <>
    <React.Fragment>
      <ReactBootstrap.Modal
       show={ReportSellerId}
       onHide ={() => hideModal('ReportSellerId')}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
            <h6 className="font-weight-bold">Report Seller</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Form.Group>
              {report_types && (
                <div>
                  <ReactBootstrap.Form.Label className="requiredField">Types:</ReactBootstrap.Form.Label>
                  <Select
                  className="basic-single"
                  placeholder= "Select an option"
                  isClearable={false}
                  value={state.report_type}
                  name="report_type"
                  options={report_types}
                  onChange={(e) => {
                    handleInputChange("report_type", e);
                          }}
                  />
                  {state.error.report_type.length > 0 && (
                    <span className="invalid-feedback-display">{state.error.report_type}</span>
                  )}
                </div>
              )}
            </ReactBootstrap.Form.Group>
            <ReactBootstrap.Form.Group className="mb-3">
                <ReactBootstrap.Form.Label>Description</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                as="textarea"
                placeholder="Enter the reason"
                value={state.content}
                rows={3}
                name="content"
                onChange={(e) => {handleInputChange("content",e)}}
                />
            </ReactBootstrap.Form.Group> 
          </ReactBootstrap.Modal.Body>
          <ReactBootstrap.Modal.Footer>
            <ReactBootstrap.Button 
            className="btnModelSubmit" 
            onClick={Submit}
            disabled={isSellerReportStatus}
            >
           {isSellerReportStatus && (
            <Spinner
              as="span"
              animation="border"
              role="status"
              size="sm"
              className="mr-1"
            />
            )
            }
              Report
            </ReactBootstrap.Button>
            {!isSellerReportStatus &&
            (
              <ReactBootstrap.Button
              className="btnModelClose"
              onClick={() => hideModal('ReportSellerId')}
            >
              Close
            </ReactBootstrap.Button>
            )}
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading ? <Loader /> : ""}
      </ReactBootstrap.Modal>
    </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ReportSellerId: state.ui.activeModal === 'ReportSellerId',
    report_types: state.rating.seller_report_types,
    // for the spinner in button
    isSellerReportStatus:
      state.seller.seller_report_status === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, { getReportTypes,hideModal,AddSellerReportInfo })(
     ReportSellerModal
)
