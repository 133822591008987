import { UPDATE_VENUE } from "../actions/venue";

const INITIAL_STATE = []

export const venueReducer = (venue = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VENUE:
      return action.payload;

    default:
      return venue;
  }
};