import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {Modal,Form,Button,Spinner} from "react-bootstrap";
import { formattedInputDate } from "../../../../js/utils";

function TrackingInfoCreateForm({
  loading,
  order_no,
  operation,
  carrier,
  service_name,
  tracking_id,
  delivery_charge,
  estimated_delivery_at,
  OnClear,
  OnSubmit,
  show,

}) {
  // To get Redux value
  var initial_state = {
    carrier: "",
    service_name: "",
    tracking_id: "",
    delivery_charge: "",
    estimated_delivery_at: "",
    error: {
      carrier: "",
      service_name: "",
      tracking_id: "",
      delivery_charge: "",
      estimated_delivery_at: "",
    },
  };
  // Set State value using immer
  const [state, setUserDetails] = useImmer(initial_state);
  useEffect(() => {
    if (operation === "Update") {
      setUserDetails((draft) => {
        draft.carrier = carrier;
        draft.service_name = service_name;
        draft.tracking_id = tracking_id;
        draft.delivery_charge = delivery_charge;
        draft.estimated_delivery_at = formattedInputDate(estimated_delivery_at);
      });
    }
  }, [operation]);
  const handleInputChange = (e) => {
    setUserDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const Submit = () => {
    if (state.carrier.length === 0) {
      setUserDetails((draft) => {
        draft.error.carrier = "Please enter the Carrier Name.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.carrier = "";
      });
    }
    if (state.carrier.length > 256) {
      setUserDetails((draft) => {
        draft.error.carrier =
          "Please enter upto 256 characters for Carrier Name.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.carrier = "";
      });
    }
    if (state.service_name.length === 0) {
      setUserDetails((draft) => {
        draft.error.service_name = "Please enter the Shipping Service.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.service_name = "";
      });
    }
    if (state.service_name.length > 256) {
      setUserDetails((draft) => {
        draft.error.service_name =
          "Please enter upto 256 characters for Shipping Service.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.service_name = "";
      });
    }
    if (state.tracking_id.length === 0) {
      setUserDetails((draft) => {
        draft.error.tracking_id = "Please enter the Tracking ID.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.tracking_id = "";
      });
    }
    if (state.tracking_id.length > 256) {
      setUserDetails((draft) => {
        draft.error.tracking_id =
          "Please enter upto 256 characters for Tracking ID.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.tracking_id = "";
      });
    }

    if (state.delivery_charge.length === 0) {
      setUserDetails((draft) => {
        draft.error.delivery_charge = "Please enter the Shipping Cost.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.delivery_charge = "";
      });
    }
    if (state.delivery_charge.length > 20) {
      setUserDetails((draft) => {
        draft.error.delivery_charge =
          "Please enter upto 20 characters for Shipping Cost.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.delivery_charge = "";
      });
    }
    if (state.estimated_delivery_at.length === 0) {
      setUserDetails((draft) => {
        draft.error.estimated_delivery_at =
          "Please Select the Expected delivery date.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.estimated_delivery_at = "";
      });
    }
    const content = {
      order_no: order_no,
      carrier_name: state.carrier,
      service_name: state.service_name,
      tracking_no: state.tracking_id,
      shipping_cost: state.delivery_charge,
      expected_delivery: state.estimated_delivery_at,
    };
    OnSubmit(content);
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <>
      <React.Fragment>
        <Modal
          show={show}
          onHide={OnClear}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter text-weight-bold">
              <h6 className="font-weight-bold">Tracking Information</h6>
            </Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group controlId="formBasicOldPasswd">
                <Form.Label className="font-weight-bold requiredField">
                  Shipping Carrier
                </Form.Label>
                <Form.Control
                  type="text"
                  value={state.carrier}
                  placeholder="Enter Shipping Carrier Name"
                  className={state.error.carrier.length > 0 ? "is-invalid" : ""}
                  name="carrier"
                  onChange={handleInputChange}
                />
                {state.error.carrier.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.carrier}
                  </span>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold requiredField">
                  Shipping Service
                </Form.Label>
                <Form.Control
                  type="text"
                  value={state.service_name}
                  placeholder="Enter Service Name "
                  className={
                    state.error.service_name.length > 0 ? "is-invalid" : ""
                  }
                  name="service_name"
                  onChange={handleInputChange}
                />
                {state.error.service_name.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.service_name}
                  </span>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold requiredField">
                  Tracking ID
                </Form.Label>
                <Form.Control
                  type="text"
                  value={state.tracking_id}
                  placeholder="Enter Tracking ID"
                  className={
                    state.error.tracking_id.length > 0 ? "is-invalid" : ""
                  }
                  name="tracking_id"
                  onChange={handleInputChange}
                />
                {state.error.tracking_id.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.tracking_id}
                  </span>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold requiredField">
                  Shipping Cost
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Shipping Cost"
                  value={state.delivery_charge}
                  className={
                    state.error.delivery_charge.length > 0 ? "is-invalid" : ""
                  }
                  name="delivery_charge"
                  onChange={handleInputChange}
                />
                {state.error.delivery_charge.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.delivery_charge}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="font-weight-bold requiredField">
                  Expected delivery date
                </Form.Label>
                <Form.Control
                  min={disablePastDate()}
                  type="date"
                  value={state.estimated_delivery_at}
                  placeholder={"Expected delivery date"}
                  className={
                    state.error.estimated_delivery_at.length > 0
                      ? "is-invalid"
                      : ""
                  }
                  name="estimated_delivery_at"
                  onChange={handleInputChange}
                />
                {state.error.estimated_delivery_at.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.estimated_delivery_at}
                  </span>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button
                className="btnTicketAddSubmit"
                onClick={Submit}
                disabled={loading}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-1"
                  />
                )}
                {operation}
              </Button>
              <Button
                className="btnModelClose"
                onClick={OnClear}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
}


export default TrackingInfoCreateForm;
