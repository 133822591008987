export const GET_MOMENTS                = '[moments] GET';
export const FETCH_MOMENTS_SUCCESS      = '[moments] Fetch success';
export const FETCH_MOMENTS_ERROR        = '[moments] Fetch Error';
export const GET_NEXT_MOMENTS           = '[moments] GET Next';
export const FETCH_NEXT_MOMENTS_SUCCESS = '[moments] Fetch next success';
export const FETCH_NEXT_MOMENTS_ERROR   = '[moments] Fetch next Error';
export const UPDATE_MOMENTS             = '[moments] Update';

// For fetching moments
export const getMoments = (filter = {}) => ({
    type: GET_MOMENTS,
    payload: filter
  });

// For fetching moments
export const fetchMomentsSuccess = (data) => ({
  type: FETCH_MOMENTS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchMomentsError = (data) => ({
  type: FETCH_MOMENTS_ERROR,
  payload: data
});



// For fetching moments for next iteration
export const getNextMoments = (filter) => ({
    type: GET_NEXT_MOMENTS,
    payload: filter
  });

export const fetchNextMomentsSuccess = (data) => ({
    type: FETCH_NEXT_MOMENTS_SUCCESS,
    payload: data,
    meta: {
      to_append: true
    }
  });

export const fetchNextMomentsError = (data) => ({
    type: FETCH_NEXT_MOMENTS_ERROR,
    payload: data
  });
  

export const setMoments = (data) => ({
    type   : UPDATE_MOMENTS,
    payload: data
  });