import React from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePassword,setPasswordStatus } from "../../../../../store/actions/profile"
import { connect } from "react-redux";
import { hideModal } from "../../../../../store/actions/ui";
import Loader from "../../../../../common/LoaderComponent/LoaderComponent";
import { OPERATION_STATUS} from "../../../../../js/constants";
import { useEffect } from "react";

function UpdatePasswordDialog({
  loading,
  UpdatePassShow,
  updatePassword,
  hideModal,
  setPasswordStatus
}) {
  console.log("fail",loading)
  useEffect(()=>{
    if(loading===OPERATION_STATUS.SUCCESS ||loading===OPERATION_STATUS.FAILURE){
      hideModal("UpdatePassShow")
     setUserDetails({...initial_state})
     setPasswordStatus(OPERATION_STATUS.YET_TO_START)
    }
  },[loading])
  // To get Redux value
  var initial_state = {
    oldPass: "",
    newPass: "",
    conNewPass: "",
    error: {
      oldPass: "",
      newPass: "",
      conNewPass: "",
    },
  };
  // Set State value using immer
  const [state, setUserDetails] = useImmer(initial_state);
  const handleInputChange = (e) => {
    setUserDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const Submit = () => {
    if (state.oldPass.length === 0) {
      setUserDetails((draft) => {
        draft.error.oldPass = "Please enter the password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.oldPass = "";
      });
		}
    if (state.oldPass.length > 256) {
      setUserDetails((draft) => {
        draft.error.oldPass = "Please enter upto 256 characters for password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.oldPass = "";
      });
		}
    if (state.newPass.length === 0) {
      setUserDetails((draft) => {
        draft.error.newPass = "Please enter the New password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.newPass = "";
      });
		}
    if (state.newPass.length > 256) {
      setUserDetails((draft) => {
        draft.error.newPass =
          "Please enter upto 256 characters for New password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.newPass = "";
      });
		}
    if (state.newPass.length === 0) {
      setUserDetails((draft) => {
        draft.error.conNewPass = "Please enter the Conform password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.conNewPass = "";
      });
		}
    if (state.newPass.length > 256) {
      setUserDetails((draft) => {
        draft.error.conNewPass =
          "Please enter upto 256 characters for Conform password.";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.conNewPass = "";
      });
		}
    if (state.conNewPass !== state.newPass) {
      setUserDetails((draft) => {
        draft.error.conNewPass = "Please enter the same password as above";
      });
      return false;
    }else{
			setUserDetails((draft) => {
        draft.error.conNewPass = "";
      });
		}

    const content = {
      old_password: state.oldPass,
      new_password: state.newPass,
      confirm_password: state.conNewPass,
    };
    updatePassword(content)
  };
  const onClose=()=>{
    hideModal("UpdatePassShow")
    setUserDetails({...initial_state})
  }
  return (
    <>
    <React.Fragment>
      <ReactBootstrap.Modal
        show={UpdatePassShow}
        onHide={()=>onClose()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter text-weight-bold">
            <h6 className="font-weight-bold">Update Password</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Form.Group controlId="formBasicOldPasswd">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Old Password
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="password"
                placeholder="Enter old password"
                className={state.error.oldPass.length > 0 ? "is-invalid" : ""}
                name="oldPass"
                onChange={handleInputChange}
              />
              {state.error.oldPass.length > 0 && (
                <span className="invalid-feedback">{state.error.oldPass}</span>
              )}
            </ReactBootstrap.Form.Group>

            <ReactBootstrap.Form.Group controlId="formBasicNewPasswd">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                New Password
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="password"
                placeholder="Enter new password"
                className={state.error.newPass.length > 0 ? "is-invalid" : ""}
                name="newPass"
                onChange={handleInputChange}
              />
              {state.error.newPass.length > 0 && (
                <span className="invalid-feedback">{state.error.newPass}</span>
              )}
            </ReactBootstrap.Form.Group>

            <ReactBootstrap.Form.Group controlId="formBasicConfirmPasswd">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Confirm Password
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="password"
                placeholder="Enter confirm password"
                className={
                  state.error.conNewPass.length > 0 ? "is-invalid" : ""
                }
                name="conNewPass"
                onChange={handleInputChange}
              />
              {state.error.conNewPass.length > 0 && (
                <span className="invalid-feedback">
                  {state.error.conNewPass}
                </span>
              )}
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Modal.Body>
          <ReactBootstrap.Modal.Footer>
            {/* <ReactBootstrap.Button className="btnModelSubmit">Save</ReactBootstrap.Button> */}
            <ReactBootstrap.Button
              className="btnTicketAddSubmit"
              onClick={Submit}
            >
              Save
            </ReactBootstrap.Button>{" "}
            <ReactBootstrap.Button
              className="btnModelClose"
              onClick={()=>onClose()}
            >
              Close
            </ReactBootstrap.Button>
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading === OPERATION_STATUS.PENDING ? <Loader /> : ""}
      </ReactBootstrap.Modal>
    </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    UserInfo: state.user,
    UpdatePassShow: state.ui.activeModal === 'UpdatePassShow',
    loading: state.profile.set_password_status
  };
};

export default connect(mapStateToProps, { updatePassword, hideModal, setPasswordStatus })(
  UpdatePasswordDialog
)
