export const GET_CONTACT_ADDRESS                 = '[address] GETAddress';
export const GET_CONTACT_ADDRESS_SUCCESS         = '[address] Fetch ContactAddress success';
export const GET_CONTACT_ADDRESS_ERROR           = '[address] Fetch ContactAddress Error';
export const UPDATE_CONTACT_ADDRESS              = '[address] Update ContactAddress';

export const GET_BILLING_ADDRESS                 = '[address] GETBillingAddress';
export const GET_BILLING_ADDRESS_SUCCESS         = '[address] Fetch BillingAddress success';
export const GET_BILLING_ADDRESS_ERROR           = '[address] Fetch BillingAddress Error';
export const UPDATE_BILLING_ADDRESS              = '[address] Update BillingAddress';

export const GET_SHIPPING_ADDRESS                 = '[address] GETShippingAddress';
export const GET_SHIPPING_ADDRESS_SUCCESS         = '[address] Fetch ShippingAddress success';
export const GET_SHIPPING_ADDRESS_ERROR           = '[address] Fetch ShippingAddress Error';
export const UPDATE_SHIPPING_ADDRESS              = '[address] Update ShippingAddress';
export const UPDATE_SHIPPING_ADDRESS_LIST         = '[address] Update ShippingAddressList';

export const ADD_ADDRESS                 = '[address] AddAddress';
export const ADD_ADDRESS_SUCCESS         = '[address] AddAddress success';
export const ADD_ADDRESS_ERROR           = '[address] AddAddress Error';
export const ADD_ADDRESS_STATUS           = '[address] AddressStatus Error';


export const EDIT_ADDRESS                 = '[address] EditAddress';
export const EDIT_ADDRESS_SUCCESS         = '[address] EditAddress success';
export const EDIT_ADDRESS_ERROR           = '[address] EditAddress Error';

export const DELETE_ADDRESS                = '[address] DeleteAddress';
export const DELETE_ADDRESS_SUCCESS        = '[address] DeleteAddress success';
export const DELETE_ADDRESS_ERROR          = '[address] DeleteAddress Error';
export const DELETE_ADDRESS_STATUS          = '[address] DeleteAddress Status';


export const SET_DEFAULT_ADDRESS                 = '[address] setDefaultAddress';
export const SET_DEFAULT_ADDRESS_SUCCESS         = '[address] setDefaultAddress success';
export const SET_DEFAULT_ADDRESS_ERROR           = '[address] setDefaultAddress Error';
// Set Address Model 
export const SET_ADDRESS_MODEL                    = '[address] setAddressModel success';

//FOR CONTACT ADDRESS
export const getContactAddress = (data) => ({
    type: GET_CONTACT_ADDRESS,
    payload: data,
  });

export const getContactAddressSuccess = (data) => ({
  type: GET_CONTACT_ADDRESS_SUCCESS,
  payload: data,
});

export const getContactAddressError = (data) => ({
  type: GET_CONTACT_ADDRESS_ERROR,
  payload: data
});

export const setContactAddress = (data) => ({
    type   : UPDATE_CONTACT_ADDRESS,
    payload: data
  });
// FOR BILLING ADDRESS
  export const getBillingAddress = (data) => ({
    type: GET_BILLING_ADDRESS,
    payload: data,
  });

export const getBillingAddressSuccess = (data) => ({
  type: GET_BILLING_ADDRESS_SUCCESS,
  payload: data,
});

export const getBillingAddressError = (data) => ({
  type: GET_BILLING_ADDRESS_ERROR,
  payload: data
});

export const setBillingAddress = (data) => ({
    type   : UPDATE_BILLING_ADDRESS,
    payload: data
  });


//FOR SHIPPING ADDRESS
export const getShippingAddress = (data) => ({
  type: GET_SHIPPING_ADDRESS,
  payload: data,
});

export const getShippingAddressSuccess = (data) => ({
type: GET_SHIPPING_ADDRESS_SUCCESS,
payload: data,
});

export const getShippingAddressError = (data) => ({
type: GET_SHIPPING_ADDRESS_ERROR,
payload: data
});

export const setShippingAddress = (data) => ({
  type   : UPDATE_SHIPPING_ADDRESS,
  payload: data
});

export const setFullShippingAddress = (data) => ({
  type   : UPDATE_SHIPPING_ADDRESS_LIST,
  payload: data
});

// ADD ADDRESS
export const addAddress = (data) => ({
  type: ADD_ADDRESS,
  payload: data,
});

export const addAddressSuccess = (data) => ({
type: ADD_ADDRESS_SUCCESS,
payload: data,
});

export const addAddressError = (data) => ({
type: ADD_ADDRESS_ERROR,
payload: data
});
export const AddressStatus = (data) => ({
  type: ADD_ADDRESS_STATUS,
  payload: data
  });


// EDIT ADDRESS
export const editAddress = (data) => ({
  type: EDIT_ADDRESS,
  payload: data,
});

export const editAddressSuccess = (data) => ({
type: EDIT_ADDRESS_SUCCESS,
payload: data,
});

export const editAddressError = (data) => ({
type: EDIT_ADDRESS_ERROR,
payload: data
});

// DELETE ADDRESS
export const deleteShippingAddress = (data) => ({
  type: DELETE_ADDRESS,
  payload: data,
});

export const deleteAddressSuccess = (data) => ({
type: DELETE_ADDRESS_SUCCESS,
payload: data,
});

export const deleteAddressError = (data) => ({
type: DELETE_ADDRESS_ERROR,
payload: data
});
export const AddressDeleteStatus = (data) => ({
  type: DELETE_ADDRESS_STATUS,
  payload: data
  });


//SET DEFAULT KEY

export const setDefaultKey = (data) => ({
  type: SET_DEFAULT_ADDRESS,
  payload: data,
});

export const setDefaultKeySuccess = (data) => ({
type: SET_DEFAULT_ADDRESS_SUCCESS,
payload: data,
});

export const setDefaultKeyError = (data) => ({
type: SET_DEFAULT_ADDRESS_ERROR,
payload: data
});


export const setAddressModel = (data) => ({
  type: SET_ADDRESS_MODEL,
  payload: data
  });
