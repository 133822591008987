import React from "react";
import { Card, Badge, Dropdown } from "react-bootstrap";
import TicketItem from "./TicketItem/TicketItem";
import { Link } from "react-router-dom";

const ProductItem = ({ type, base_price, item_details }) => {
  return (
    <React.Fragment>
      <Card className="my-1 ">
        <Card.Body>
          <div className="d-flex align-items-center ">
            <div className="flex-grow-1">
              <Badge className="badge-info">{type}</Badge>
            </div>

            <div className="d-flex">
              <div className="font-weight-bold">Cost:</div>
              <div className="font-weight-bold ml-1">
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {base_price}
              </div>
            </div>
          </div>
          <Dropdown.Divider />
          <div className="d-flex">
            {item_details.map((item,idx) => {
              return (
                <TicketItem
                  key={idx}
                  listing_name={item.listing_name}
                  cost={item.cost.value}
                  image={item.images[0].image_url}
                />
              );
            })}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ProductItem;
