import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";

import "../PageNotFoundComponent/PageNotFoundComponent.css";
import {PAGE_URLS} from "../../js/constants"

function PageNotFoundComponent() {
  return (
    <React.Fragment>
      <section className="text-center errorPageSection">
        <div className="Error404">404</div>
        <div className="ErrorContent">Oops. Nothing here...</div>
        <div className="m-4">
          <ReactBootstrap.Button className="btnGoToHome" as={Link} to={PAGE_URLS.HOME}>
            Go Back Home
          </ReactBootstrap.Button>{" "}
        </div>
      </section>
    </React.Fragment>
  );
}

export default PageNotFoundComponent;
