import  * as authTypes  from '../constants/profileConstants'
import produce from 'immer'

const INITIAL_STATE = {
    loading:false,
    UserInfo:[],
    ContactAddress:[],
    BillingAddress:[],
    ShippingAddress:[],
    ContactAddressCheck:true,
    BillingAddressCheck:true,
    defaultShippingAddress:[],
    sellerPaymentDetails: [],
    paypalDetails:[],
    hideModel: false,
    userUpdateHide: false,
    userPassUpdateHide: false,
    onHideProfile:false,
    isUserDetailsUpdate:false,
    isProfilePicUpdate:false,
    isAddressModified:false,
    getEventOption:[]
}
const user_profile_reducer = produce((draft, action) => {
    // FOR Home Screen
    switch (action.type) {
        case authTypes.USER_INFO_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_INFO_SUCCESS:
            draft.loading = false
            draft.UserInfo = action.payload.data
            break
        case authTypes.USER_INFO_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    } 
    switch (action.type) {
        case authTypes.USER_INFO_UPDATE_REQUEST:
            draft.loading = true
            draft.userUpdateHide = false
            draft.isUserDetailsUpdate = false
            break
        case authTypes.USER_INFO_UPDATE_SUCCESS:
            draft.loading = false
            draft.userUpdateHide = true
            draft.isUserDetailsUpdate = true
            break
        case authTypes.USER_INFO_UPDATE_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_INFO_PASSUPDATE_REQUEST:
            draft.loading = true
            draft.userPassUpdateHide = false
            break
        case authTypes.USER_INFO_PASSUPDATE_SUCCESS:
            draft.loading = false
            draft.userPassUpdateHide = true
            break
        case authTypes.USER_INFO_PASSUPDATE_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_CONTACTADDRESS_REQUEST: 
            draft.loading = true
            break
        case authTypes.USER_CONTACTADDRESS_SUCCESS:
            if(action.payload.data.items.length !== 0){
                draft.ContactAddress = action.payload.data.items
                draft.ContactAddressCheck = false
            }
            draft.loading = false
            break
        case authTypes.USER_CONTACTADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_BILLINGADDRESS_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_BILLINGADDRESS_SUCCESS:
            if(action.payload.data.items.length !== 0){
                draft.BillingAddress = action.payload.data.items
                draft.BillingAddressCheck = false
            }
            draft.loading = false
            break
        case authTypes.USER_BILLINGADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_SHIPPINGADDRESS_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_SHIPPINGADDRESS_SUCCESS:
            var fullArray = action.payload.data.items
            var newArray = fullArray.filter(function (el)
            {
            return el.is_primary == 1
            }
            );
            draft.defaultShippingAddress = newArray
            draft.ShippingAddress = action.payload.data.items
            draft.loading = false
            break
        case authTypes.USER_SHIPPINGADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_ADDADDRESS_REQUEST:
            draft.hideModel = false
            draft.isAddressModified = false
            draft.loading = true
            break
        case authTypes.USER_ADDADDRESS_SUCCESS:
            draft.isAddressModified = true
            draft.hideModel = true
            draft.loading = false
            break
        case authTypes.USER_ADDADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_EDITADDRESS_REQUEST:
            draft.isAddressModified = false
            draft.hideModel = false
            draft.loading = true
            break
        case authTypes.USER_EDITADDRESS_SUCCESS:
            draft.isAddressModified = true
            draft.hideModel = true
            draft.loading = false
            break
        case authTypes.USER_EDITADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_DELETESHIPPINGADDRESS_REQUEST:
            draft.loading = true
            draft.isAddressModified = false
            break
        case authTypes.USER_DELETESHIPPINGADDRESS_SUCCESS:
            draft.loading = false
            draft.isAddressModified = true
            break
        case authTypes.USER_DELETESHIPPINGADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_SETDEFAULTADDRESS_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_SETDEFAULTADDRESS_SUCCESS:
            draft.loading = false
            break
        case authTypes.USER_SETDEFAULTADDRESS_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_GETSELLER_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_GETSELLER_SUCCESS:
            draft.sellerPaymentDetails = action.payload.data
            draft.paypalDetails = action.payload.data.paypal_details
            draft.loading = false
            break
        case authTypes.USER_GETSELLER_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_SETSELLER_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_SETSELLER_SUCCESS:
            draft.loading = false
            break
        case authTypes.USER_SETSELLER_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_PROFILEPICUPDATE_REQUEST:
            draft.loading = true
            draft.onHideProfile = false
            draft.isProfilePicUpdate = false
            break
        case authTypes.USER_PROFILEPICUPDATE_SUCCESS:
            draft.isProfilePicUpdate = true
            draft.onHideProfile = true
            draft.loading = false
            break
        case authTypes.USER_PROFILEPICUPDATE_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    switch (action.type) {
        case authTypes.USER_GETEVENT_REQUEST:
            draft.loading = true
            break
        case authTypes.USER_GETEVENT_SUCCESS:
            draft.getEventOption = action.payload.data.items
            draft.loading = false
            break
        case authTypes.USER_GETEVENT_ERROR:
            draft.loading = false
            draft.error = action.payload
            break
        default:
            break
    }
    
}, INITIAL_STATE)

export default user_profile_reducer;