import React from "react";
import { Button } from "react-bootstrap";
import Address from "../AddressViewComponent/AddressViewComponent";

const CheckoutShippingAddress = ({
  checkout_shipping_address,
  add_Address,
  change_Address,
}) => {
  return (
    <>
      {checkout_shipping_address && checkout_shipping_address.id !== null ? (
        <>
          <Address
            first_name={checkout_shipping_address.first_name}
            last_name={checkout_shipping_address.last_name}
            address_line_1={checkout_shipping_address.address_line_1}
            address_line_2={checkout_shipping_address.address_line_2}
            administrative_area={checkout_shipping_address.administrative_area}
            city={checkout_shipping_address.city}
            country={checkout_shipping_address.country}
            mobile_no={checkout_shipping_address.mobile_no}
            postal_code={checkout_shipping_address.postal_code}
          />
          <Button
            onClick={() => {
              change_Address();
            }}
          >
            Change
          </Button>
        </>
      ) : (
        <>
          <p>No Address found</p>
          <Button
            onClick={() => {
              add_Address();
            }}
          >
            Add
          </Button>
        </>
      )}
    </>
  );
};

export default CheckoutShippingAddress;
