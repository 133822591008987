import React from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressAddEditModel from "../../../AdressAddEditModel/AddressAddEditModel";
import "./ManageAddressComponent.css";
import { connect } from "react-redux";
import { OPERATION_STATUS } from "../../../../../js/constants";
import Loader from "../../../../../common/LoaderComponent/LoaderComponent";
import {
  deleteShippingAddress,
  setDefaultKey,
  setAddressModel,
} from "../../../../../store/actions/addressAction";
import { showModal, hideModal } from "../../../../../store/actions/ui";
function ManageAddressComponent({
  loading,
  ShippingAddress,
  deleteShippingAddress,
  setDefaultKey,
  setAddressModel,
  showModal,
}) {
  // Edit the Shipping Address Model
  const EditShippingButton = (e) => {
    var newArray = ShippingAddress.filter(function (el) {
      return el.id == e;
    });
    var Data = {
      AddressType: "",
      operations: "EDIT",
      AddressID: e,
      address: newArray[0],
      title: "Edit Shipping Information",
    };
    setAddressModel(Data);
    showModal("AddressAddEdit");
  };

  // Delete Shipping Address
  const DeleteShippingButton = (e) => {
    const value = {
      id: e,
    };
    deleteShippingAddress(value);
  };
  // Set Default Shipping Address
  const defaultAddressKey = (e, id) => {
    if (e.target.checked) {
      const value = {
        id: id,
      };
      setDefaultKey(value);
    }
  };
  return (
    <>
      <React.Fragment>
        <div>
          <ReactBootstrap.ListGroup defaultActiveKey="#link1">
            {ShippingAddress.map((data, index) => (
              <ReactBootstrap.ListGroup.Item key={index} action>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} md={6}>
                    <ReactBootstrap.Form>
                      <ReactBootstrap.Form.Check
                        type="radio"
                        onChange={(e) => defaultAddressKey(e, data.id)}
                        checked={data.is_primary}
                      />
                    </ReactBootstrap.Form>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col xs={12} md={6} className="text-right">
                    <ReactBootstrap.Button
                      className="btnEditManageAddress"
                      onClick={() => EditShippingButton(data.id)}
                    >
                      Edit
                    </ReactBootstrap.Button>{" "}
                    <ReactBootstrap.Button
                      className="btnDeleteManageAddress"
                      onClick={() => DeleteShippingButton(data.id)}
                    >
                      Delete
                    </ReactBootstrap.Button>{" "}
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <div key={index}>
                  <div>
                    {data.first_name && data.first_name + " "}
                    {data.last_name && data.last_name}
                  </div>

                  <div>{data.address_line_1 && data.address_line_1 + ","}</div>
                  {data.address_line_2 && data.address_line_2 + ","}
                  <div></div>

                  <div>
                    {data.administrative_area && data.administrative_area + ","}
                    {data.city && data.city + ","}
                    {data.country && data.country + ","}
                    {data.postal_code && data.postal_code}
                  </div>

                  <div>{data.mobile_no && data.mobile_no}</div>
                </div>
              </ReactBootstrap.ListGroup.Item>
            ))}
          </ReactBootstrap.ListGroup>
        </div>
        <div></div>
      </React.Fragment>
      {loading ? <Loader /> : ""}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    ShippingAddress: state.Address.ShippingAddressList,
    AddressDetailsUpdated: state.Address.AddressDetailsUpdated,
    isLoading:
      "address" in state.ui.loaders ? state.ui.loaders["address"] : true,
    loading: state.Address.deleteAddressData === OPERATION_STATUS.PENDING,
  };
};
export default connect(mapStateToProps, {
  deleteShippingAddress,
  setDefaultKey,
  setAddressModel,
  showModal,
})(ManageAddressComponent);
