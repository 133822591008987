import React from "react";

const Address = ({
  first_name,
  last_name,
  address_line_1,
  address_line_2,
  administrative_area,
  city,
  country,
  postal_code,
  mobile_no,
}) => {
  return (
    <div>
      <div>
        {first_name && <span className="mr-1 word-break">{first_name}</span>}
        {last_name && <span className="word-break">{last_name}</span>}
      </div>

      {address_line_1 && <div><span className="word-break">{address_line_1}</span></div>}
      {address_line_2 && <div><span className="word-break">{address_line_2}</span></div>}

      <div>
        {administrative_area && <span className="mr-1 word-break">{administrative_area},</span>}
        {city && <span className="mr-1 word-break">{city},</span>}
        {country && <span className="mr-1 word-break">{country},</span>}
        {postal_code && <span className="mr-1 word-break">{postal_code}</span>}
      </div>

      <div>{mobile_no && <span className="word-break">{mobile_no}</span>}</div>
    </div>
  );
};

export default Address;
