import {
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  deleteAccountSuccess,
  deleteAccountError,
  setdeleteAccount,
} from "../../actions/account";
import {
  setUserData,
  setTokens,
  setUserPermission,
  setUserStatus,
} from "../../actions/auth";
import { notification_types } from "../../../js/constants";
import { showSpinner, hideSpinner, hideModal } from "../../actions/ui";
import { clearCart } from "../../actions/myCart";
import { setNotification } from "../../actions/notification";
import { apiRequest } from "../../actions/api";
import { OPERATION_STATUS, USER_STATES } from "../../../js/constants";

// this middleware only care about the getBooks action
export const deleteAccountFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === DELETE_ACCOUNT) {
      dispatch(setdeleteAccount(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "DELETE",
          "/user/account/user.php",
          null,
          action.payload,
          deleteAccountSuccess,
          deleteAccountError,
          true,
          "[account]"
        )
      );
      dispatch(showSpinner("account"));
    }
  };

// on successful fetch, process the books data
export const processDeleteAccountCollection =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === DELETE_ACCOUNT_SUCCESS) { 
        dispatch(setUserData(null));
        dispatch(setUserPermission([]));
        dispatch(setTokens(null));
        dispatch(setUserStatus(USER_STATES.LOGGED_OUT));
        dispatch(setdeleteAccount(OPERATION_STATUS.SUCCESS));
        dispatch(clearCart());
        dispatch(
          setNotification(
            "Account deleted successfully",
            "account deleted successfully",
            notification_types.success
          ));
        dispatch(hideSpinner("account"));
        dispatch(hideModal("UserAccountDelete"));
    
    }

    if (action.type === DELETE_ACCOUNT_ERROR) {
      dispatch(setdeleteAccount(OPERATION_STATUS.FAILURE));
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "order exist",
          notification_types.error
        ));
        dispatch(hideModal("account"));
      dispatch(hideModal("UserAccountDelete"));
    }
  };

export const accountMdl = [deleteAccountFlow, processDeleteAccountCollection];
