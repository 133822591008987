import React, { useState } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import * as ReactBootstrap from "react-bootstrap";
import "../../MyAccountComponent/MyAccountComponent.css";
import UserInformationEditModel from "./UserInformationEditModel/UserInformationEditModel";
import UpdateUserPassModel from "../../Subcomponents/ProfileInformationSection/UpdateUserPassModel/UpdateUserPassModel";
import UpdateUserPicModel from "./UpdateUserPicModel/UpdateUserPicModel";
import { ProfilePicUpload } from "../../../../store/actions/profile";
import { showModal, hideModal } from "../../../../store/actions/ui";

function ProfileInformationSection({
  profile,
  ProfilePicUpload,
  name,
  mail,
  mobile_no,
  profileInfoUpdated,
  showModal,
  hideModal,
}) {
  const [UploadPicturemodalShow, setPictureModalShow] = React.useState(false);
  useEffect(() => {
    if (profileInfoUpdated === true) {
      hideModal("UserInfoEdit");
      hideModal("UpdatePic");
      hideModal("UpdatePassShow");
      removeImage();
    }
  }, [profileInfoUpdated]);

  const [State, Setstate] = useImmer({
    imagefiles: [],
    error: {
      imagefiles: "",
    },
  });

  const handleSubmit = () => {
    if (State.imagefiles.length > 0) {
      const content = {
        image_file: State.imagefiles[0].image_file,
        image_name: State.imagefiles[0].image_name,
      };
      ProfilePicUpload(content);
    }
  };

  const handleImageChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      Setstate((draft) => {
        draft.error.imagefiles = "File type must be image/jpeg, image/png";
      });
    } else {
      Setstate((draft) => {
        draft.error.imagefiles = "";
      });
    }

    let uploadedImages = acceptedFiles.map(async (file) => {
      let base64String = await getBase64(file);
      return {
        image_id: "",
        image_url: URL.createObjectURL(file),
        image_file: base64String
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", ""),
        image_name: file.name,
      };
    });
    Promise.all(uploadedImages).then((images) =>
      Setstate((draft) => {
        draft.imagefiles = images;
      })
    );
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
        // return baseURL;
      };
      // return baseURL;
    });
  };

  const removeImage = (image) => {
    Setstate((draft) => {
      draft.imagefiles = [];
    });
  };
  return (
    <React.Fragment>
      <div className="my-4">
        <ReactBootstrap.Card>
          <ReactBootstrap.Card.Header as="h5">
            Profile Information
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            <ReactBootstrap.Container fluid>
              <ReactBootstrap.Form>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} md={12} className="text-right">
                    <ReactBootstrap.Button
                      className="btnEditSection"
                      onClick={() => showModal("UserInfoEdit")}
                    >
                      Edit
                    </ReactBootstrap.Button>{" "}
                    <ReactBootstrap.Button
                      className="btnEditSection"
                      onClick={() => showModal("UpdatePassShow")}
                    >
                      Update Password
                    </ReactBootstrap.Button>{" "}
                    <UserInformationEditModel />
                    <UpdateUserPassModel />
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} md={4}>
                    <ReactBootstrap.Form.Group controlId="formBasicName">
                      <ReactBootstrap.Form.Label className="font-weight-bold">
                        Name
                      </ReactBootstrap.Form.Label>
                      <ReactBootstrap.Form.Text className="text-muted">
                        {name}
                      </ReactBootstrap.Form.Text>
                    </ReactBootstrap.Form.Group>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} md={4}>
                    <ReactBootstrap.Form.Group controlId="formBasicEmail">
                      <ReactBootstrap.Form.Label className="font-weight-bold">
                        Email
                      </ReactBootstrap.Form.Label>
                      <ReactBootstrap.Form.Text className="text-muted">
                        {mail}
                      </ReactBootstrap.Form.Text>
                    </ReactBootstrap.Form.Group>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} md={4}>
                    <ReactBootstrap.Form.Group controlId="formBasicNumber">
                      <ReactBootstrap.Form.Label className="font-weight-bold">
                        Phone
                      </ReactBootstrap.Form.Label>
                      <ReactBootstrap.Form.Text className="text-muted">
                        {mobile_no}
                      </ReactBootstrap.Form.Text>
                    </ReactBootstrap.Form.Group>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Dropdown.Divider />
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12}>
                    <ReactBootstrap.Form.Label className="font-weight-bold">
                      Profile Photo
                    </ReactBootstrap.Form.Label>
                    <UpdateUserPicModel
                      show={UploadPicturemodalShow}
                      onHide={() => setPictureModalShow(false)}
                      images={State.imagefiles}
                      handleImageChange={handleImageChange}
                      removeImage={removeImage}
                      error={State.error.imagefiles}
                      handleSubmit={handleSubmit}
                    />
                    <ReactBootstrap.Button
                      className="btnEditSection image-edit-btn"
                      onClick={() => showModal("UpdatePic")}
                    >
                      Edit
                    </ReactBootstrap.Button>{" "}
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                {profile && (
                  <div className=" userImage mb-2" style={{overflow: "hidden"}}>
                    <img className="img-fluid " src={profile.image_400} />
                  </div>
                )}
              </ReactBootstrap.Form>
            </ReactBootstrap.Container>
          </ReactBootstrap.Card.Body>
        </ReactBootstrap.Card>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    name: state.user.name ? state.user.name : null,
    mail: state.user.mail ? state.user.mail : null,
    mobile_no: state.user.mobile_no ? state.user.mobile_no : null,
    name: state.user.name ? state.user.name : null,
    profileInfoUpdated: state.profile.profileInfoUpdated,
    profile: state.user.profile_url,
  };
};

export default connect(mapStateToProps, {
  ProfilePicUpload,
  showModal,
  hideModal,
})(ProfileInformationSection);
