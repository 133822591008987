import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./GenreComponent.css";

const Genre = ({ genres, isLoading }) => {
  return (
    <ReactBootstrap.Container fluid className="my-4 m-0">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : (
        genres.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <p>No Genre found</p>
          </div>
        ) :(
          <ReactBootstrap.Row className="m-0">
          {genres.map((genre) => {
            return (
              <ReactBootstrap.Col key={genre.genre_id} xs={12} md={3} lg={3} xl={3}>
                <div className="genreListSection">
                  <h5>{genre.display_name}</h5>
                  <ul>
                    {genre.sub_genre.map((sub_genre) => (
                      <ReactBootstrap.Nav.Link
                        key={sub_genre.sub_genre_id}
                        as={Link}
                        to={"/tickets?sub_genre=" + sub_genre.sub_genre_id}
                      >
                        <li> {sub_genre.display_name}</li>
                      </ReactBootstrap.Nav.Link>
                    ))}
                  </ul>
                </div>
              </ReactBootstrap.Col>
            );
          })}
        </ReactBootstrap.Row>
        )
        
      )}
    </ReactBootstrap.Container>
  );
};

Genre.propTypes = {
  genres: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Genre;
