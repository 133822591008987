import React from "react";
import { useEffect } from "react";
import Venue from "./Venue";
import { connect } from "react-redux";
import { getVenue } from "../../store/actions/venue";

const VenuePage = ({venues, isLoading, getVenue}) => {
  useEffect(() => {
    const data={
      has_tickets: true
    }
    getVenue(data);
  }, []);
  return (
    <Venue venues={venues} isLoading={isLoading} />
  );
}

const mapStateToProps = (state) => {
  return {
    venues: state.venue,
    isLoading: 'venue' in state.ui.loaders ? state.ui.loaders['venue'] : true
  };
};

export default connect(mapStateToProps, { getVenue })(
  VenuePage
);