import React from "react";
function AddressViewComponent({
  first_name,
  last_name,
  address_line_1,
  address_line_2,
  administrative_area,
  city,
  country,
  postal_code,
  mobile_no,
}) {
  return (
    <React.Fragment>
      <div>
        <div>
          {first_name && first_name + " "}
          {last_name && last_name}
        </div>

        <div>{address_line_1 && address_line_1 + ","}</div>
        {address_line_2 && address_line_2 + ","}
        <div></div>

        <div>
          {administrative_area && administrative_area + ","}
          {city && city + ","}
          {country && country + ","}
          {postal_code && postal_code}
        </div>

        <div>{mobile_no && mobile_no}</div>
      </div>
    </React.Fragment>
  );
}

export default AddressViewComponent;
