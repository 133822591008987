import { UPDATE_REPORT_ADMIN,
  UPDATE_REPLIES_ADMIN,
  UPDATE_REPORT_ADMIN_STATUS,
  UPDATE_SEND_MESSAGE_ADMIN_STATUS,
  UPDATE_REPORT_TYPES,
  UPDATE_NEXT_REPLIES_ADMIN
           } from "../actions/reportAdmin";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
    report_rooms:[],
    replies:{
      reply_list:[],
      title:"",
      description:""
    },
    report_types:[],
    add_report_status: OPERATION_STATUS.YET_TO_START,
    message_sent_status: OPERATION_STATUS.YET_TO_START

};

export const reportAdminReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_REPORT_ADMIN:
      draft.report_rooms = action.payload;
      break;
    case UPDATE_REPLIES_ADMIN:
      draft.replies = action.payload;
      break;
    case UPDATE_REPORT_ADMIN_STATUS:
        draft.add_report_status = action.payload;
      break;
      case UPDATE_SEND_MESSAGE_ADMIN_STATUS:
        draft.message_sent_status = action.payload;
      break;
      case UPDATE_REPORT_TYPES:
        draft.report_types = action.payload;
      break;
      case UPDATE_NEXT_REPLIES_ADMIN:
        draft.replies.reply_list = action.payload.reply_list;
        draft.replies.title = action.payload.title;
        draft.replies.description = action.payload.description;
      break;
    default:  
  }
}, INITIAL_STATE);