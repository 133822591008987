import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Room from "./ReportComponent/Report";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";

const ReportingView = ({ reporting_rooms, isLoading }) => {
  const history = useHistory();
  const routeToMesagePage = (e, room) => {
    e.preventDefault();
    history.push({
      pathname: "/account/report/" + room.room_id,
    });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          {reporting_rooms.length === 0 ? (
            <p style={{ textAlign: "center" }}>
              <b>No reports found</b>
            </p>
          ) : (
            <>
              {reporting_rooms.map((room) => {
                return (
                  <Link
                    key={room.room_id}
                    to={"/account/report/" + room.room_id}
                    onClick={(e) => routeToMesagePage(e, room)}
                  >
                    <Room
                      key={room.room_id}
                      name={room.title}
                      type={room.type}
                      status={room.status}
                      message={room.content}
                      description={room.description}
                      reported_date ={room.reported_date}
                    />
                  </Link>
                );
              })}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ReportingView;
