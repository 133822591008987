// Import Section
import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import { Link } from "react-router-dom";
import "../RegisterComponent/RegisterComponent.css";
import * as authOperations from "../../store/actions/authAction";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from "../../images/memorylanelogo.png";
import { showModal, hideModal } from "../../store/actions/ui";
import { connect } from "react-redux";
import {MAIL_REGEX} from "../../js/constants"

function RegisterComponnet({ auth, showModal, onRegister, hideModal,isRegistredStatus }) {
  const { loading, isRegistred } = auth;

  // Set State value using immer
  const [registerForm, setRegisterForm] = useImmer({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    error: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  // To handle the Onchange Function
  const handleRegisterInputChange = (e) => {
    setRegisterForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };

  // Register validation
  const registerSubmit = async (e) => {
    e.preventDefault();
    if (registerForm.name.length === 0) {
      setRegisterForm((draft) => {
        draft.error.name = "Please enter the Name.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.name = "";
      });
    }

    if (registerForm.name.length > 256) {
      setRegisterForm((draft) => {
        draft.error.name = "Please enter upto 256 characters for Name.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.name = "";
      });
    }

    if (registerForm.email.length === 0) {
      setRegisterForm((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.email = "";
      });
    }

    if (registerForm.email.length > 256) {
      setRegisterForm((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!registerForm.email.match(MAIL_REGEX)) {
      setRegisterForm((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.email = "";
      });
    }

    if (registerForm.password.length === 0) {
      setRegisterForm((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.password = "";
      });
    }

    if (registerForm.password.length > 256) {
      setRegisterForm((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.password = "";
      });
    }

    if (registerForm.confirmPassword.length === 0) {
      setRegisterForm((draft) => {
        draft.error.confirmPassword = "Please enter the confirm password.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }

    if (registerForm.confirmPassword.length > 256) {
      setRegisterForm((draft) => {
        draft.error.confirmPassword =
          "Please enter upto 256 characters for confirm password.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }

    if (registerForm.confirmPassword !== registerForm.password) {
      setRegisterForm((draft) => {
        draft.error.confirmPassword = "Please make sure your passwords match.";
      });
      return false;
    } else {
      setRegisterForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }

    var user_name = registerForm.name;
    var email = registerForm.email;
    var password = registerForm.password;
    var confirm_password = registerForm.confirmPassword;
    const user = {
      user_name: user_name,
      email: email,
      password: password,
      confirm_password: confirm_password,
    };

    onRegister(user);
  };
const backtohome =()=>{
  isRegistredStatus()
  showModal("login")
}
  return (
    <>
      {isRegistred ? (
        <ReactBootstrap.Card className="passwdReset">
          <ReactBootstrap.Card.Header>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} md={4} className="pl-0">
                <div className="PasswdResetLogo">
                  <img src={Logo} alt="Logo" />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} md={8}>
                <div className="mt-4">
                  <h5>Email verification</h5>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            <p>Thanks for signing up!</p>
            <p>A verification link has been sent to your email account</p>
            <p>
              Please click on the link to verify your email account and complete
              the registration
            </p>
            <p>You should receive them shortly.</p>
            <p>
              If you don't receive an email, please make sure you have entered
              the address you registered with and check your spam folder
            </p>
          </ReactBootstrap.Card.Body>
          <ReactBootstrap.Card.Footer>
            <ReactBootstrap.Nav.Link onClick={backtohome}>
              Back to login
            </ReactBootstrap.Nav.Link>
          </ReactBootstrap.Card.Footer>
        </ReactBootstrap.Card>
      ) : (
        <ReactBootstrap.Form
          className="registerFormSection"
          onSubmit={registerSubmit}
        >
          <div align="center" className="loginLogo mb-1">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="registerTitleDiv text-center">
            <h5>Create an Account</h5>
          </div>
          <ReactBootstrap.Form.Group
            className="mb-4 mt-4"
            controlId="formBasicName"
          >
            <ReactBootstrap.Form.Label>Name:</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="text"
              placeholder="Enter your name..."
              name="name"
              value={registerForm.name}
              className={registerForm.error.name.length > 0 ? "is-invalid" : ""}
              onChange={handleRegisterInputChange}
              autoComplete="off"
            />
            {registerForm.error.name.length > 0 && (
              <span className="invalid-feedback">
                {registerForm.error.name}
              </span>
            )}
          </ReactBootstrap.Form.Group>
          <ReactBootstrap.Form.Group
            className="mb-4 mt-4"
            controlId="formBasicEmail"
          >
            <ReactBootstrap.Form.Label>
              Email Address:
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="email"
              placeholder="Enter your email address..."
              name="email"
              value={registerForm.email}
              className={
                registerForm.error.email.length > 0 ? "is-invalid" : ""
              }
              onChange={handleRegisterInputChange}
            />
            {registerForm.error.email.length > 0 && (
              <span className="invalid-feedback">
                {registerForm.error.email}
              </span>
            )}
          </ReactBootstrap.Form.Group>
          <ReactBootstrap.Form.Group
            className="mb-4 mt-4"
            controlId="formBasicPassword"
          >
            <ReactBootstrap.Form.Label>Password:</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="password"
              placeholder="Enter your password..."
              value={registerForm.password}
              name="password"
              className={
                registerForm.error.password.length > 0 ? "is-invalid" : ""
              }
              onChange={handleRegisterInputChange}
            />
            {registerForm.error.password.length > 0 && (
              <span className="invalid-feedback">
                {registerForm.error.password}
              </span>
            )}
          </ReactBootstrap.Form.Group>
          <ReactBootstrap.Form.Group
            className="mb-4 mt-4"
            controlId="formBasicConfirmPassword"
          >
            <ReactBootstrap.Form.Label>
              Confirm Password:
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="password"
              placeholder="Enter your Confirm password..."
              value={registerForm.confirmPassword}
              name="confirmPassword"
              className={
                registerForm.error.confirmPassword.length > 0
                  ? "is-invalid"
                  : ""
              }
              onChange={handleRegisterInputChange}
            />
            {registerForm.error.confirmPassword.length > 0 && (
              <span className="invalid-feedback">
                {registerForm.error.confirmPassword}
              </span>
            )}
          </ReactBootstrap.Form.Group>
          <div>By signing up you are accepting the <Link onClick={() => hideModal("login")} to="/termscondition">Terms & condition</Link> And <Link onClick={() => hideModal("login")} to="/policy">Privacy Policy</Link></div>
          <ReactBootstrap.Button type="submit" className="btnRegister mt-3">
            Sign Up
          </ReactBootstrap.Button>
          <ReactBootstrap.Form.Text className="loginAccount text-center">
            <span>
              Already a user?{" "}
              <Link onClick={() => showModal("login")}>Log In </Link>
            </span>
          </ReactBootstrap.Form.Text>
        </ReactBootstrap.Form>
      )}

      {/* *************   Loading  ********************/}
      {loading ? <Loader /> : ""}
      {/* **************************************/}
    </>
  );
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
const mapDispatchToProps = (dispatch) => ({
  onRegister: (credentials) => dispatch(authOperations.register(credentials)),
  isRegistredStatus:()=>dispatch(authOperations.isRegistred()),
  showModal: (label) => dispatch(showModal(label)),
  hideModal: (label) => dispatch(hideModal(label)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponnet);
