import React from "react";
import { Card, Badge } from "react-bootstrap";
import TicketCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";

const CheckoutItemComponent = ({
  type,
  offer_no,
  offer_id,
  base_price,
  tax_price,
  details,
  seller_id,
  seller_name,
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center py-1">
          <div>
            <div>
              <Badge className="badge-info">{type}</Badge>
            </div>
            <div>
              <i className="fa fa-handshake-o" aria-hidden="true"></i>
              <Link key={seller_id} to={"/tickets?seller=" + seller_id}>
                <span className="ticketType">{seller_name}</span>
              </Link>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {details.map((item) => {
            return (
              <TicketCard
                key={item.product_id}
                listing_name={item.listing_name}
                cost={item.cost}
                image={item.image}
              />
            );
          })}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <div className="font-weight-bold">Cost:</div>
          <div className="font-weight-bold ml-1">
            <i className="fa fa-usd mr-1" aria-hidden="true"></i>
            {base_price}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

export default CheckoutItemComponent;
