import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {
  Modal,
  Form,
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";

const AddressAddEditModel = ({
  loading,
  showAddressModal,
  address,
  operation,
  title,
  onSubmit,
  onCancel,
}) => {
  //Page Onload
  useEffect(() => {
    let el = address;
    setAddressForm((draft) => {
      draft.id = el.id;
      draft.type = el.type;
      draft.firstName = el.first_name;
      draft.lastName = el.last_name;
      draft.phoneNo = el.mobile_no;
      draft.addressOne = el.address_line_1;
      draft.addressTwo = el.address_line_2;
      draft.area = el.administrative_area;
      draft.city = el.city;
      draft.code = el.postal_code;
      draft.country = el.country;
      draft.isSameAs = false;
      draft.error.firstName = "";
      draft.error.lastName = "";
      draft.error.phoneNo = "";
      draft.error.addressOne = "";
      draft.error.addressTwo = "";
      draft.error.area = "";
      draft.error.city = "";
      draft.error.code = "";
      draft.error.country = "";
    });
  }, [address]);

  var initial_state = {
    id: "",
    type: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    addressOne: "",
    addressTwo: "",
    area: "",
    city: "",
    code: "",
    country: "",
    isSameAs: false,
    error: {
      firstName: "",
      lastName: "",
      phoneNo: "",
      addressOne: "",
      addressTwo: "",
      area: "",
      city: "",
      code: "",
      country: "",
    },
  };
  // Set State value using immer
  const [address_form, setAddressForm] = useImmer(initial_state);

  const onInputChange = (e) => {
    setAddressForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const handleCheck = (e) => {
    setAddressForm((draft) => {
      draft.isSameAs = e.target.checked;
    });
  };

  const Submit = () => {
    if (address_form.firstName.length === 0) {
      setAddressForm((draft) => {
        draft.error.firstName = "Please enter the First Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.firstName = "";
      });
    }
    if (address_form.firstName.length > 256) {
      setAddressForm((draft) => {
        draft.error.firstName =
          "Please enter upto 256 characters for First Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.firstName = "";
      });
    }
    if (address_form.lastName.length === 0) {
      setAddressForm((draft) => {
        draft.error.lastName = "Please enter the Last Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.lastName = "";
      });
    }
    if (address_form.lastName.length > 256) {
      setAddressForm((draft) => {
        draft.error.lastName =
          "Please enter upto 256 characters for Last Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.lastName = "";
      });
    }
    if (address_form.phoneNo.length === 0) {
      setAddressForm((draft) => {
        draft.error.phoneNo = "Please enter the Mobile number.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.phoneNo = "";
      });
    }
    if (address_form.phoneNo.length > 20) {
      setAddressForm((draft) => {
        draft.error.phoneNo =
          "Please enter upto 20 characters for Mobile number.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.phoneNo = "";
      });
    }
    if (address_form.addressOne.length === 0) {
      setAddressForm((draft) => {
        draft.error.addressOne = "Please enter the Address Line 1.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.addressOne = "";
      });
    }
    if (address_form.addressOne.length > 1000) {
      setAddressForm((draft) => {
        draft.error.addressOne =
          "Please enter upto 1000 characters for Address Line 1.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.addressOne = "";
      });
    }
    if (address_form.addressTwo.length > 1000) {
      setAddressForm((draft) => {
        draft.error.addressTwo =
          "Please enter upto 1000 characters for Address Line 2.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.addressTwo = "";
      });
    }
    if (address_form.city.length === 0) {
      setAddressForm((draft) => {
        draft.error.city = "Please enter the City Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.city = "";
      });
    }
    if (address_form.city.length > 300) {
      setAddressForm((draft) => {
        draft.error.city = "Please enter upto 300 characters for City Name.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.city = "";
      });
    }
    if (address_form.area.length === 0) {
      setAddressForm((draft) => {
        draft.error.area = "Please enter the address_form.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.area = "";
      });
    }
    if (address_form.area.length > 300) {
      setAddressForm((draft) => {
        draft.error.area = "Please enter upto 300 characters for address_form.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.area = "";
      });
    }

    if (address_form.code.length === 0) {
      setAddressForm((draft) => {
        draft.error.code = "Please enter the Postal Code.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.code = "";
      });
    }
    if (address_form.code.length > 50) {
      setAddressForm((draft) => {
        draft.error.code = "Please enter upto 50 characters for Postal Code.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.code = "";
      });
    }
    if (address_form.country.length === 0) {
      setAddressForm((draft) => {
        draft.error.country = "Please enter the Country.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.country = "";
      });
    }
    if (address_form.country.length > 100) {
      setAddressForm((draft) => {
        draft.error.country = "Please enter upto 100 characters for Country.";
      });
      return false;
    } else {
      setAddressForm((draft) => {
        draft.error.country = "";
      });
    }
    const ADDcontent = {
      id: address_form.id,
      first_name: address_form.firstName,
      last_name: address_form.lastName,
      mobile_no: address_form.phoneNo,
      address_line_1: address_form.addressOne,
      address_line_2: address_form.addressTwo,
      administrative_area: address_form.area,
      city: address_form.city,
      postal_code: address_form.code,
      country: address_form.country,
      type: address_form.type,
      isSameAs: address_form.isSameAs,
    };
    onSubmit(ADDcontent);
  };
  return (
    <>
      <React.Fragment>
        <Modal
          show={showAddressModal}
          onHide={onCancel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h6 className="font-weight-bold">{title}</h6>
            </Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicFirstName">
                      <Form.Label className="font-weight-bold requiredField">
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Frist name..."
                        value={address_form.firstName}
                        name="firstName"
                        className={
                          address_form.error.firstName.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.firstName.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.firstName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicLastName">
                      <Form.Label className="font-weight-bold requiredField">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name..."
                        value={address_form.lastName}
                        name="lastName"
                        className={
                          address_form.error.lastName.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.lastName.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.lastName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicNumber">
                      <Form.Label className="font-weight-bold requiredField">
                        Phone
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter your number..."
                        value={address_form.phoneNo}
                        name="phoneNo"
                        className={
                          address_form.error.phoneNo.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                      />
                      {address_form.error.phoneNo.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.phoneNo}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicAddress1">
                      <Form.Label className="font-weight-bold requiredField">
                        Address Line 1
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={address_form.addressOne}
                        placeholder="Enter address one..."
                        name="addressOne"
                        className={
                          address_form.error.addressOne.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.addressOne.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.addressOne}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-3" controlId="formBasicAddress2">
                      <Form.Label className="font-weight-bold">
                        Address Line 2
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter address two..."
                        value={address_form.addressTwo}
                        name="addressTwo"
                        className={
                          address_form.error.addressTwo.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.addressTwo.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.addressTwo}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicCity">
                      <Form.Label className="font-weight-bold requiredField">
                        City
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your city..."
                        value={address_form.city}
                        name="city"
                        className={
                          address_form.error.city.length > 0 ? "is-invalid" : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.city.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.city}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicState">
                      <Form.Label className="font-weight-bold requiredField">
                        State
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your address_form..."
                        value={address_form.area}
                        name="area"
                        className={
                          address_form.error.area.length > 0 ? "is-invalid" : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.area.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.area}
                        </span>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPostalCode"
                    >
                      <Form.Label className="font-weight-bold requiredField">
                        Postal Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your Postal Code..."
                        value={address_form.code}
                        name="code"
                        className={
                          address_form.error.code.length > 0 ? "is-invalid" : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.code.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.code}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="formBasicCountry">
                      <Form.Label className="font-weight-bold requiredField">
                        Country
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your country..."
                        value={address_form.country}
                        name="country"
                        className={
                          address_form.error.country.length > 0
                            ? "is-invalid"
                            : ""
                        }
                        onChange={onInputChange}
                        autoComplete="off"
                      />
                      {address_form.error.country.length > 0 && (
                        <span className="invalid-feedback">
                          {address_form.error.country}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {address_form.type === "BILL" && address_form.id ===""? (
                  <Form.Check
                    type="checkbox"
                    label="Same as shipping address"
                    onChange={(e) => handleCheck(e)}
                  />
                ) : (
                  ""
                )}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btnModelSubmit"
                onClick={Submit}
                disabled={loading}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-1"
                  />
                )}
                {operation}
              </Button>

              <Button className="btnModelClose" onClick={onCancel}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default AddressAddEditModel;
