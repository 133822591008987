import { SHOW_MODAL, HIDE_MODAL, SHOW_SPINNER, HIDE_SPINNER } from "../actions/ui";
import produce from 'immer'

const INITIAL_STATE = {
  activeModal: "",
  resetModelValue:false,
  loaders: {}
};

export const ModalsReducer = produce((draft, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      draft.activeModal = action.payload;
      draft.resetModelValue = false
      break;
    case HIDE_MODAL:
      draft.activeModal = "";
      draft.resetModelValue = true
      break;
    case SHOW_SPINNER:
      draft.loaders[action.payload] = true;
      break;
    case HIDE_SPINNER:
      draft.loaders[action.payload] = false;
      break;
    default:
      break;
  }
},INITIAL_STATE);
