import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import AddressAddEditModel from "../../AdressAddEditModel/AddressAddEditModel";
import {
  getContactAddress,
  getBillingAddress,
  setAddressModel,
} from "../../../../store/actions/addressAction";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../../../store/actions/ui";

function AddressInformationSection({
  ContactAddress,
  BillingAddress,
  getContactAddress,
  getBillingAddress,
  AddressDetailsUpdated,
  showModal,
  hideModal,
  setAddressModel,
}) {
  // Set State value using immer
  const [getState, setBillingModelShow] = useImmer({
    AddressType: "",
    AddressID: "",
    address: null,
    billingModelShow: false,
    operations: "",
    title: "",
  });
  //Page Onload
  useEffect(() => {
    const user = {
      type: "CONTACT",
    };
    getContactAddress(user);
    const data = {
      type: "BILL",
    };
    getBillingAddress(data);
  }, []);

  // Add Button Click For Address
  const AddContactButton = (e) => {
    var Data = {
      AddressType: "CONTACT",
      operations: "ADD",
      AddressID: "",
      address: null,
      title: "Add Contact Information",
    };
    setAddressModel(Data);
    showModal("AddressAddEdit");
  };

  const AddBillingButton = (e) => {
    var Data = {
      AddressType: "BILL",
      operations: "ADD",
      AddressID: "",
      address: null,
      title: "Add Billing Information",
    };
    setAddressModel(Data);
    showModal("AddressAddEdit");
  };

  // Edit Button Click For Address
  const EditContactButton = (e) => {
    var Data = {
      AddressType: "",
      operations: "EDIT",
      AddressID: ContactAddress[0].id,
      address: ContactAddress[0],
      title: "Edit Contact Information",
    };
    setAddressModel(Data);
    showModal("AddressAddEdit");
  };

  const EditBillingButton = (e) => {
    var Data = {
      AddressType: "",
      operations: "EDIT",
      AddressID: BillingAddress[0].id,
      address: BillingAddress[0],
      title: "Edit Billing Information",
    };
    setAddressModel(Data);
    showModal("AddressAddEdit");
  };
  //hideModel
  useEffect(() => {
    if (AddressDetailsUpdated === true) {
      hideModal("AddressAddEdit");
    }
  }, [AddressDetailsUpdated]);
  return (
    <>
      <React.Fragment>
        <div className="mb-4">
          <ReactBootstrap.Card>
            <ReactBootstrap.Card.Header as="h5">
              Address Information
            </ReactBootstrap.Card.Header>
            <ReactBootstrap.Card.Body>
              <ReactBootstrap.Container fluid>
                <ReactBootstrap.Form>
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col
                      xs={12}
                      md={6}
                      className="columnInfoSection"
                    >
                      <ReactBootstrap.Form.Group controlId="formBasicName">
                        <ReactBootstrap.Form.Label className="font-weight-bold">
                          Contact Information
                        </ReactBootstrap.Form.Label>
                        {ContactAddress.length > 0 && (
                          <ReactBootstrap.Form.Text className="text-muted">
                            <div>
                              <div>
                                {ContactAddress[0].first_name &&
                                  ContactAddress[0].first_name + " "}
                                {ContactAddress[0].last_name &&
                                  ContactAddress[0].last_name}
                              </div>

                              <div>
                                {ContactAddress[0].address_line_1 &&
                                  ContactAddress[0].address_line_1 + ","}
                              </div>
                              {ContactAddress[0].address_line_2 &&
                                ContactAddress[0].address_line_2 + ","}
                              <div></div>

                              <div>
                                {ContactAddress[0].administrative_area &&
                                  ContactAddress[0].administrative_area + ","}
                                {ContactAddress[0].city &&
                                  ContactAddress[0].city + ","}
                                {ContactAddress[0].country &&
                                  ContactAddress[0].country + ","}
                                {ContactAddress[0].postal_code &&
                                  ContactAddress[0].postal_code}
                              </div>

                              <div>
                                {ContactAddress[0].mobile_no &&
                                  ContactAddress[0].mobile_no}
                              </div>
                            </div>
                          </ReactBootstrap.Form.Text>
                        )}

                        <div className="mt-3">
                          {ContactAddress.length === 0 ? (
                            <ReactBootstrap.Button
                              className="btnAddContactInfo"
                              onClick={() => AddContactButton()}
                            >
                              Add
                            </ReactBootstrap.Button>
                          ) : (
                            <ReactBootstrap.Button
                              className="btnEditContactInfo"
                              onClick={() => EditContactButton()}
                            >
                              Edit
                            </ReactBootstrap.Button>
                          )}
                        </div>
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} md={6}>
                      <ReactBootstrap.Form.Group controlId="formBasicBillingAddress">
                        <ReactBootstrap.Form.Label className="font-weight-bold">
                          Billing Address
                        </ReactBootstrap.Form.Label>
                        {BillingAddress.length > 0 && (
                          <ReactBootstrap.Form.Text className="text-muted">
                            <div>
                              <div>
                                {BillingAddress[0].first_name &&
                                  BillingAddress[0].first_name + " "}
                                {BillingAddress[0].last_name &&
                                  BillingAddress[0].last_name}
                              </div>

                              <div>
                                {BillingAddress[0].address_line_1 &&
                                  BillingAddress[0].address_line_1 + ","}
                              </div>
                              {BillingAddress[0].address_line_2 &&
                                BillingAddress[0].address_line_2 + ","}
                              <div></div>

                              <div>
                                {BillingAddress[0].administrative_area &&
                                  BillingAddress[0].administrative_area + ","}
                                {BillingAddress[0].city &&
                                  BillingAddress[0].city + ","}
                                {BillingAddress[0].country &&
                                  BillingAddress[0].country + ","}
                                {BillingAddress[0].postal_code &&
                                  BillingAddress[0].postal_code}
                              </div>

                              <div>
                                {BillingAddress[0].mobile_no &&
                                  BillingAddress[0].mobile_no}
                              </div>
                            </div>
                          </ReactBootstrap.Form.Text>
                        )}
                        <div className="mt-3">
                          {BillingAddress.length === 0 ? (
                            <ReactBootstrap.Button
                              className="btnAddContactInfo"
                              onClick={() => AddBillingButton()}
                            >
                              Add
                            </ReactBootstrap.Button>
                          ) : (
                            <ReactBootstrap.Button
                              className="btnEditContactInfo"
                              onClick={EditBillingButton}
                            >
                              Edit
                            </ReactBootstrap.Button>
                          )}
                        </div>
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Form>
              </ReactBootstrap.Container>
            </ReactBootstrap.Card.Body>
          </ReactBootstrap.Card>
        </div>
      </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    ContactAddress: state.Address.ContactAddress,
    BillingAddress: state.Address.BillingAddress,
    AddressDetailsUpdated: state.Address.AddressDetailsUpdated,
    isLoading:
      "address" in state.ui.loaders ? state.ui.loaders["address"] : true,
  };
};

export default connect(mapStateToProps, {
  getContactAddress,
  getBillingAddress,
  showModal,
  hideModal,
  setAddressModel,
})(AddressInformationSection);
