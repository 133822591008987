import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

function PricingSummary({
  base_price,
  tax_price,
  processing_fee,
  total_price,
  due_amount,
}) {
  return (
    <React.Fragment>
      <strong>Pricing Summary</strong>
      <Row>
        <Col xs={6} sm={6} md={8}>
          <div> Base price</div>
          <div>Tax Price</div>
          <div>Processing Fee</div>
          <strong>
            <div>Total Amount</div>
          </strong>
          <strong>
            <div>Due Amount</div>
          </strong>
        </Col>
        <Col xs={6} sm={6} md={4} className=" text-center text-md-right">
          <div>
            <i className="fa fa-usd" aria-hidden="true"></i> {base_price}
          </div>
          <div>
            <i className="fa fa-usd" aria-hidden="true"></i> {tax_price}
          </div>
          <div>
            <i className="fa fa-usd" aria-hidden="true"></i> {processing_fee}
          </div>
          <strong>
            <div>
              <i className="fa fa-usd" aria-hidden="true"></i> {total_price}
            </div>
          </strong>
          <strong>
            <div>
              <i className="fa fa-usd" aria-hidden="true"></i> {due_amount}
            </div>
          </strong>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(PricingSummary);
