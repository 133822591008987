import {
  SELECT_ORDER,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_SINGLE_ORDER_ERROR,
  fetchSingleOrderSuccess,
  fetchSingleOrderError,
  setSingleOrder,

	APPROVE_PAYMENT_ORDER,
	APPROVE_PAYMENT_ORDER_SUCCESS,
	APPROVE_PAYMENT_ORDER_ERROR,
	approveOrderPaymentStatusSuccess,
	approveOrderPaymentStatusError,
	setApproveOrderPaymentStatus,

	CANCEL_ORDER,
	CANCEL_ORDER_SUCCESS,
	CANCEL_ORDER_ERROR,
	orderCancelStatusSuccess,
	orderCancelStatusError,
	orderCancelledStatus,
	EXPIRED_ORDER,
	EXPIRED_ORDER_SUCCESS,
	EXPIRED_ORDER_ERROR,
	orderExpiryDateStatusSuccess,
	orderExpiryDateStatusError,
	setorderExpiryDateStatus,
	setExpiryStatus

} from "../../actions/orderPayment";
import {alertModelStatus} from "../../actions/myOrder";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { hideModal } from "../../actions/ui";
import { formattedDate,formattedMessageDisplayDateTime } from "../../../js/utils";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
import Avatar from "../../../images/avatar.png";


export const getSingleOrderFlow = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === SELECT_ORDER) {
		dispatch(
			apiRequest(
				"GET",
				"/user/orders/order.php",
				action.payload,
				null,
				fetchSingleOrderSuccess,
				fetchSingleOrderError,
				true,
				"[order_payment]"
			)
		);
		dispatch(showSpinner("order_single"));
	}
	
};
  
// on successful fetch, process the order data
export const processOrderSingle = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === FETCH_SINGLE_ORDER_SUCCESS) {
		var data = action.payload.data
		let sub_orders = data.sub_orders.map(sub_order => {
			return {
				id: sub_order.id,
				seller: {
					id: sub_order.seller.id,
					name:  sub_order.seller.name,
					avatar: {
						image_50: sub_order.seller.avatar ?  sub_order.seller.avatar : Avatar,
						image_200:  sub_order.seller.avatar ?  sub_order.seller.avatar  : Avatar,
						image_400:  sub_order.seller.avatar  ?  sub_order.seller.avatar : Avatar,
					},
					is_admin:  sub_order.seller.is_admin,
				},
				line_items :  sub_order.lineitems.map(item => {
					return {
						id: item.id,
						type: item.type,        
						base_price: item.base_price.value,
						tax_price: item.tax_price.value,
						details: item.item_details.map((ticket) => {
							return {
								product_id: ticket.product_id,
								listing_name: ticket.listing_name,
								cost: ticket.cost.value,
								image: ticket.images[0],
							};
						})
					}
				})
				
			}
		})
		let buyer = {
			id: data.buyer.id,
			name:  data.buyer.name,
			avatar: {
				image_50: data.buyer.avatar ?  data.buyer.avatar : Avatar,
				image_200:  data.buyer.avatar ?  data.buyer.avatar  : Avatar,
				image_400:  data.buyer.avatar  ?  data.buyer.avatar : Avatar,
			},
			is_admin:  data.buyer.is_admin,
		}
		let pricing_summary = {
			price_sub_total: data.pricing_summary.price_subtotal.value,
			tax: data.pricing_summary.tax.value,
			delivery: data.pricing_summary.delivery.value,
			total: data.pricing_summary.total.value
		}
		let payment_summary = {
			payment_amount: data.payment_summary.payments[0].amount.value,
			is_paid: data.payment_summary.payments[0].is_paid,
			status: data.payment_summary.payments[0].status,
			method: data.payment_summary.payments[0].method
		}
		let order = {
			order_no : data.order_no,
			order_date : formattedDate(data.ordered_at),
			expiry_date : formattedMessageDisplayDateTime(data.expiry_date),
			status : data.order_status,
			buyer: buyer,
			sub_orders: sub_orders,
			pricing_summary: pricing_summary,
			payment_summary: payment_summary,
			shipping_address: data.shipping_address,
			billing_address: data.billing_address,

		}
		dispatch(setSingleOrder(order));
		dispatch(hideSpinner("order_single"));
	}

	if (action.type === FETCH_SINGLE_ORDER_ERROR) {
		dispatch(hideSpinner("order_single"));
	}

};

export const approveOrderPaymentFlow = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === APPROVE_PAYMENT_ORDER) {
		dispatch(
			apiRequest(
				"PUT",
				"/user/orders/order.php?action=paymentUpdate",
				null,
				action.payload,
				approveOrderPaymentStatusSuccess,
				approveOrderPaymentStatusError,
				true,
				"[order_payment]"
			)
		);
		dispatch(setApproveOrderPaymentStatus(OPERATION_STATUS.PENDING));
		dispatch(showSpinner("order_payment_status"));
	}
	
};

// on successful fetch, process the order data
export const processOrderPayment = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === APPROVE_PAYMENT_ORDER_SUCCESS) {
		dispatch(setApproveOrderPaymentStatus(OPERATION_STATUS.SUCCESS));
		dispatch(
			setNotification(
				"Payment completed successfully",
				"order_payment_status",
				notification_types.success
			)
		);
		dispatch(hideSpinner("order_payment_status"));
		dispatch(setExpiryStatus(true));
	}

	if (action.type === APPROVE_PAYMENT_ORDER_ERROR) {
		dispatch(setApproveOrderPaymentStatus(OPERATION_STATUS.FAILURE));
		dispatch(
			setNotification(
				"Payment update done failure. Please contact administrator",
				"order_payment_status",
				notification_types.error
			)
		);
		dispatch(hideSpinner("order_payment_status"));
		dispatch(setExpiryStatus(true));
	}

};

export const cancelOrderFlow = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === CANCEL_ORDER) {
		dispatch(alertModelStatus(OPERATION_STATUS.PENDING));
		dispatch(
			apiRequest(
				"DELETE",
				"/user/orders/order.php",
				null,
				action.payload,
				orderCancelStatusSuccess,
				orderCancelStatusError,
				true,
				"[order_payment]"
			)
		);
		dispatch(orderCancelledStatus(OPERATION_STATUS.PENDING));
		dispatch(showSpinner("order_payment_status"));
	}
	
};

// on successful fetch, process the order data
export const processCancelOrder = ({ dispatch }) => (next) => (action) => {
	next(action);

	if (action.type === CANCEL_ORDER_SUCCESS) {
		dispatch(alertModelStatus(OPERATION_STATUS.SUCCESS));
		dispatch(orderCancelledStatus(OPERATION_STATUS.SUCCESS));
		dispatch(
			setNotification(
				"Order Cancelled",
				"order_payment_status",
				notification_types.success
			)
		);
		dispatch(hideModal("alertbox"));
		dispatch(setExpiryStatus(true));
		dispatch(hideSpinner("order_payment_status"));
	}

	if (action.type === CANCEL_ORDER_ERROR) {
		dispatch(alertModelStatus(OPERATION_STATUS.FAILURE));
		dispatch(orderCancelledStatus(OPERATION_STATUS.FAILURE));
		dispatch(
			setNotification(
				"Cancel Order failure",
				"order_payment_status",
				notification_types.error
			)
		);
		
		dispatch(hideModal("alertbox"));
		dispatch(hideSpinner("order_payment_status"));
	}

};

export const OrderExpiryDateFlow = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === EXPIRED_ORDER) {
		dispatch(
			apiRequest(
				"GET",
				"/user/orders/order.php",
				action.payload,
				null,
				orderExpiryDateStatusSuccess,
				orderExpiryDateStatusError,
				true,
				"[order_payment]"
			)
		);
		dispatch(setorderExpiryDateStatus(OPERATION_STATUS.PENDING));
		dispatch(showSpinner("order_payment_status"));
	}
	
};
 
export const processOrderExpiryDate = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === EXPIRED_ORDER_SUCCESS) {
		var data = action.payload.data.status
		if (data) {
			dispatch(
				setNotification(
					"Order Expired",
					"order_payment_status",
					notification_types.error
				)
			);
		}
		dispatch(setExpiryStatus(false));
		dispatch(hideSpinner("order_payment_status"));
		dispatch(setorderExpiryDateStatus(OPERATION_STATUS.SUCCESS));
	}
 
	if (action.type === EXPIRED_ORDER_ERROR) {
		dispatch(setorderExpiryDateStatus(OPERATION_STATUS.FAILURE));
		dispatch(
			setNotification(
				"Order Expiry check failure",
				"order_payment_status",
				notification_types.error
			)
		);
		dispatch(hideSpinner("order_payment_status"));
	}
}

export const orderPaymentMdl = [
	getSingleOrderFlow,
	processOrderSingle,
	approveOrderPaymentFlow,
	processOrderPayment,
	cancelOrderFlow,
	processCancelOrder,
	OrderExpiryDateFlow,
	processOrderExpiryDate
];