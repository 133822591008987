import React from "react";
import CreatableSelect from "react-select/creatable";
import * as ReactBootstrap from "react-bootstrap";
import Select from "react-select";
import EditableSelect from "../EditableSelect/EditableSelect";

const EventOptionalItems = ({
  value,
  error,
  options,
  onChangeValue,
  addTeamArtist,
  removeTeamArtist,
  onEditOption,
  onEditInputCancel,
}) => {
  return (
    <>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={6}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Genre</ReactBootstrap.Form.Label>
            <Select
              className={error.currentGenre.length > 0 ? "is-invalid" : ""}
              isClearable={false}
              placeholder="Select or enter the event genre"
              value={value.currentGenre}
              name="currentGenre"
              onChange={(e) => {
                onChangeValue("currentGenre", "", e);
              }}
              options={options.genres}
            />
            {error.currentGenre.length > 0 && (
              <span className="invalid-feedback">{error.currentGenre}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={6}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Sub Genre</ReactBootstrap.Form.Label>
            <EditableSelect
              value={value.currentSubGenre}
              error={error.currentSubGenre}
              options={options.subGenres}
              onChangeValue={onChangeValue}
              placeholder="Select or enter the event sub genre"
              name="currentSubGenre"
              onEditOption={onEditOption}
              onEditInputCancel={onEditInputCancel}
              index=""
            />
            {error.currentSubGenre.length > 0 && (
              <span className="invalid-feedback">{error.currentSubGenre}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col>
          {value.currentTeamArtists.map((item, index) => {
            return (
              <ReactBootstrap.Form.Group className="mb-3" key={index}>
                <ReactBootstrap.Form.Label>
                  Team / Artist
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} md={9}>
                    <EditableSelect
                      value={item}
                      error=""
                      options={options.teams_artists}
                      onChangeValue={onChangeValue}
                      placeholder="Select a team / artist"
                      name="currentTeamArtists"
                      onEditOption={onEditOption}
                      onEditInputCancel={onEditInputCancel}
                      index={index}
                    />
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} md={1}>
                    <ReactBootstrap.Form.Check
                      className="py-2"
                      label="isTeam"
                      name="isTeam"
                      type="checkbox"
                      checked={item ? item.isTeam : false}
                      disabled={
                        item ? ("__isNew__" in item ? false : true) : true
                      }
                      onChange={(e) => onChangeValue("isTeam", index, e)}
                    />
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} md={2}>
                    {index === 0 ? (
                      <ReactBootstrap.Button
                        onClick={() => addTeamArtist()}
                        type="button"
                        className="btn btn-primary text-center"
                      >
                        <i className="fa fa-plus-circle" aria-hidden="true" />
                      </ReactBootstrap.Button>
                    ) : (
                      <ReactBootstrap.Button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => removeTeamArtist(index)}
                      >
                        <i className="fa fa-minus" aria-hidden="true" />
                      </ReactBootstrap.Button>
                    )}
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Form.Group>
            );
          })}
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <ReactBootstrap.Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <ReactBootstrap.Form.Label>
              Event Significance
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.significance.length > 0 ? "is-invalid" : ""}
              as="textarea"
              placeholder="Enter the event significance"
              value={value.significance}
              rows={3}
              name="significance"
              onChange={(e) => {
                onChangeValue("significance", "", e);
              }}
              autoComplete="off"
            />
            {error.significance.length > 0 && (
              <span className="invalid-feedback">{error.significance}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </>
  );
};

export default EventOptionalItems;
