import React from "react";
import { Container, Spinner, Row, Col, Dropdown } from "react-bootstrap";
import "./TicketDetailsPage.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import SliderComponent from "./SliderComponentV2/SliderComponent";
import TitleComponent from "./TitleComponent/TitleComponent";
import EventInfoComponent from "./EventInfoComponent/EventInfoComponent";
import TicketInfoComponent from "./TicketInfoComponent/TicketInfoComponent";
import { selectTicket } from "../../store/actions/tickets";
import {
  addItemToCart,
  setAddCartItemStatus,
} from "../../store/actions/myCart";
import { showModal } from "../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../js/constants";

const TicketDetailsPage = ({
  selectTicket,
  ticket,
  error,
  history,
  ticketLoading,
  user_id,
  addItemToCart,
  itemAddStatus,
  setAddCartItemStatus,
  isItemAddToCartLoading,
  isLogged,
  showModal,
}) => {
  let { id } = useParams();
  // DISPATCH THE API
  useEffect(() => {
    selectTicket(id);
  }, []);

  useEffect(() => {
    if (itemAddStatus === OPERATION_STATUS.SUCCESS) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/mycart",
      });
    } else if (itemAddStatus === OPERATION_STATUS.FAILURE) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [itemAddStatus]);

  const addCart = () => {
    if (isLogged) {
      var data = {
        type: "BUY",
        product: {
          id: ticket.product_id,
          name: ticket.listing_name,
        },
      };
      addItemToCart(data);
    } else {
      showModal("login");
    }
  };
  return (
    <React.Fragment>
      {ticketLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div>
          {ticket ? (
            <Container className="my-4">
              <Row>
                <Col md={8}>
                  <EventInfoComponent
                    listing_name={ticket.listing_name}
                    name={ticket.event_name}
                    significance={ticket.significance}
                    teams={ticket.teams}
                    artists={ticket.artists}
                    venue={ticket.venue}
                    date={ticket.date}
                  />
                </Col>
                <Col md={4} className="align-self-center">
                  {ticket.video_link && (
                    <iframe
                      width="300"
                      height="200"
                      title="Ticket Video"
                      src={ticket.video_link}
                    ></iframe>
                  )}
                  {/* <video width="320" height="240" controls>
              <source src="https://www.youtube.com/embed/5V2B28OqfqM" type="video/mp4"/>
                </video> */}
                </Col>
              </Row>
              <Dropdown.Divider />
              {error && <div>Oops.. Something went wrong</div>}
              {ticket && (
                <Row className="my-4">
                  <Col xs={12} md={6}>
                    <SliderComponent
                      images={ticket.images}
                      isLoading={isItemAddToCartLoading}
                      addCart={addCart}
                      canBuy={user_id !== ticket.seller.id && ticket.isSale !=="0" }
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <TitleComponent
                      type={ticket.type}
                      seller={ticket.seller.name}
                      seller_id={ticket.seller.id}
                    />
                    <TicketInfoComponent
                      cost={ticket.cost}
                      isSale={ticket.isSale}
                      face_price={ticket.face_price}
                      section={ticket.section}
                      row={ticket.row}
                      seat_no={ticket.seat_no}
                      seat_type={ticket.seat_type}
                      description={ticket.description}
                      quality={ticket.quality}
                      grade={ticket.grade}
                      type={ticket.type}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          ) : (
            <div className="d-flex justify-content-center align-items-center my-5">
              <p>No Tickets found</p>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ticket: state.ticket.ticket.info,
    error: state.ticket.ticket.error,
    ticketLoading:
      "ticket_single" in state.ui.loaders
        ? state.ui.loaders["ticket_single"]
        : true,
    user_id: state.user ? state.user.id : null,
    itemAddStatus: state.cart.add_status,
    isItemAddToCartLoading: state.cart.add_status === OPERATION_STATUS.PENDING,
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
  };
};

export default connect(mapStateToProps, {
  selectTicket,
  addItemToCart,
  setAddCartItemStatus,
  showModal,
})(TicketDetailsPage);
