import React from "react";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { connect } from "react-redux";
import { updateSellerPaymentInfo,setSellerPaymentStatus } from "../../../../../store/actions/seller"
import { hideModal } from "../../../../../store/actions/ui";
import Loader from "../../../../../common/LoaderComponent/LoaderComponent";
import { Spinner } from "react-bootstrap";
import { OPERATION_STATUS } from "../../../../../js/constants";
function SellerPaymentEditModal({
  loading,
  sellerPaymentDetails, // to show and hide the modal popup
  paypal_id, // Input params for API call
  paypal_email, // Input params for API call
  id, // Input params for API call
  hideModal, // for modal hide property
  updateSellerPaymentInfo, // to pass input params for API call
  isSellerPaymentStatus
}) {
  // set the initial value for the modal form
  var initial_state = {
    paypal_id: "",
    paypal_email: "",
    id: "",
    error: {
        paypal_id: "",
        paypal_email: "",
    },
  };
  // Set State value using immer
  const [state, setSellerDetails] = useImmer(initial_state);
  useEffect(() => {
    SetValue();
  }, []);
  useEffect(() => {
    if (isSellerPaymentStatus === OPERATION_STATUS.SUCCESS) {
      setSellerPaymentStatus(OPERATION_STATUS.YET_TO_START);
    } else if (isSellerPaymentStatus === OPERATION_STATUS.FAILURE) {
      setSellerPaymentStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [setSellerPaymentStatus]);
  // function at page load
  const SetValue = async () => {
    setSellerDetails((draft) => {
      draft.id = id;
      draft.paypal_id = paypal_id;
      draft.paypal_email = paypal_email;
    });
  };
  // on change event for form
  const handleInputChange = (e) => {
    setSellerDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  // API call for update seller payment details
  const Submit = () => {
    // For paypal id validation
    if (state.paypal_id.length === 0) {
      setSellerDetails((draft) => {
        draft.error.paypal_id = "Please enter the paypal_id.";
      });
      return false;
    }else{
      setSellerDetails((draft) => {
        draft.error.paypal_id = "";
      });
    }
    if (state.paypal_id.length > 256) {
      setSellerDetails((draft) => {
        draft.error.paypal_id = "Please enter upto 256 characters for paypal_id.";
      });
      return false;
    }else{
      setSellerDetails((draft) => {
        draft.error.paypal_id = "";
      });
    }
    // For paypal email validation
    if (state.paypal_email.length === 0) {
      setSellerDetails((draft) => {
        draft.error.paypal_email = "Please enter the paypal email.";
      });
      return false;
    }else{
      setSellerDetails((draft) => {
        draft.error.paypal_email = "";
      });
    }
    if (state.paypal_email.length > 256) {
      setSellerDetails((draft) => {
        draft.error.paypal_email = "Please enter upto 256 characters for paypal email.";
      });
      return false;
    }else{
      setSellerDetails((draft) => {
        draft.error.paypal_email = "";
      });
    }
    // input params for the API call
    const seller_payment = {
      paypal_details_id: state.id,
      paypal_id: state.paypal_id,
      paypal_email: state.paypal_email,
    };
   updateSellerPaymentInfo(seller_payment);
  };
  return (
    <>
    <React.Fragment>
      <ReactBootstrap.Modal
       show={sellerPaymentDetails}
       onHide ={() => hideModal('sellerPaymentDetails')}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
            <h6 className="font-weight-bold">Edit Seller Payment</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Form.Group controlId="formBasicName">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Paypal Id
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="text"
                name="paypal_id"
                placeholder="Enter Paypal Id..."
                value={state.paypal_id}
                onChange={handleInputChange}
                autoComplete="off"
              />
              {state.error.paypal_id.length > 0 && (
                <span className="invalid-feedback">{state.error.paypal_id}</span>
              )}
            </ReactBootstrap.Form.Group>

            <ReactBootstrap.Form.Group controlId="formBasicNumber">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Paypal Email
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="text"
                name="paypal_email"
                className={state.error.paypal_email.length > 0 ? "is-invalid" : ""}
                placeholder="Enter Paypal Email..."
                value={state.paypal_email}
                onChange={handleInputChange}
              />
              {state.error.paypal_email.length > 0 && (
                <span className="invalid-feedback">{state.error.paypal_email}</span>
              )}
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Modal.Body>
          <ReactBootstrap.Modal.Footer>
            <ReactBootstrap.Button 
            className="btnModelSubmit" 
            onClick={Submit}
            disabled={isSellerPaymentStatus}
            >
           {isSellerPaymentStatus && (
            <Spinner
              as="span"
              animation="border"
              role="status"
              size="sm"
              className="mr-1"
            />
            )
            }
              Update
            </ReactBootstrap.Button>
            {!isSellerPaymentStatus &&
            (
              <ReactBootstrap.Button
              className="btnModelClose"
              onClick={() => hideModal('UserInfoEdit')}
            >
              Close
            </ReactBootstrap.Button>
            )}
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading ? <Loader /> : ""}
      </ReactBootstrap.Modal>
    </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    sellerPaymentDetails: state.ui.activeModal === 'sellerPaymentDetails',
    // for the spinner in button
    isSellerPaymentStatus:
      state.seller.seller_payment_status === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, { hideModal, updateSellerPaymentInfo })(
    SellerPaymentEditModal
)
