import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import "./MyTicketsDetailsPage.css";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import SliderComponent from "./SliderComponent/SliderComponent";
import TitleComponent from "./TitleComponent/TitleComponent";
import EventInfoComponent from "./EventInfoComponent/EventInfoComponent";
import TicketInfoComponent from "./TicketInfoComponent/TicketInfoComponent";
import { selectMyTicket } from "../../store/actions/mytickets";

const MyTicketsDetailsPage = ({
  selectMyTicket,
  ticket,
  error,
  ticketLoading,
}) => {
  let { id } = useParams();
console.log(ticket)
  // DISPATCH THE API
  useEffect(() => {
    selectMyTicket(id);
  }, []);

  return (
    <React.Fragment>
      {ticketLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : (
        <div>
          <ReactBootstrap.Container className="my-4">
            {error && <div>Oops.. Something went wrong</div>}
            {ticket && (
              <ReactBootstrap.Row className="my-4">
                <ReactBootstrap.Col xs={12} md={6}>
                  <SliderComponent images={ticket.images} />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <TitleComponent
                    listing_name={ticket.listing_name}
                    type={ticket.type}
                    cost={ticket.cost}
                    isSale={ticket.isSale}
                    seller={ticket.seller.name}
                  />
                  <EventInfoComponent
                    name={ticket.event_name}
                    significance={ticket.significance}
                    teams={ticket.teams}
                    artists={ticket.artists}
                    venue={ticket.venue}
                    date={ticket.date}
                  />
                  <TicketInfoComponent
                    face_price={ticket.face_price}
                    section={ticket.section}
                    row={ticket.row}
                    seat_no={ticket.seat_no}
                    seat_type={ticket.seat_type}
                    description={ticket.description}
                    quality={ticket.quality}
                    grade={ticket.grade}
                    type={ticket.type}
                  />
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            )}
          </ReactBootstrap.Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ticket: state.mytickets.ticket.info,
    error: state.mytickets.ticket.error,
    ticketLoading:
      "myticket_single" in state.ui.loaders
        ? state.ui.loaders["myticket_single"]
        : true,
  };
};

export default connect(mapStateToProps, { selectMyTicket })(
  MyTicketsDetailsPage
);
