import React from "react";
import { Form, Row } from "react-bootstrap";

function PaymentMethod({ accepted_payments }) {
  return (
    <React.Fragment>
      <Form>
        {accepted_payments.map((payment) => {
          return (
            <div className="ml-4 mr-3" key={payment.type}>
              <Row className="justify-content-center ">
                <Form.Group>
                  <Form.Label>Payment Type:</Form.Label>
                  <Form.Check
                    type="radio"
                    label={payment.type}
                    disabled
                    checked={payment.selected}
                  />
                </Form.Group>
              </Row>
            </div>
          );
        })}
      </Form>
    </React.Fragment>
  );
}

export default PaymentMethod;
