import React from "react";
import * as ReactBootstarp from "react-bootstrap";
import "./ProductCard.css";
import DeleteIcon from "../../../images/delete.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const ProductCard = ({
  product_id,
  listing_name,
  seller,
  event_name,
  significance,
  venue,
  date,
  teams,
  artists,
  cost,
  image,
}) => {
  const [DeletemodalShow, setDeleteModalShow] = React.useState(false);
  return (
    <React.Fragment>
      <ReactBootstarp.Card className="searchResultCard my-4">
        <ReactBootstarp.Card.Body>
          <ReactBootstarp.Row>
            <ReactBootstarp.Col xs={12} md={2} className="text-center">
              <div>
                <Link to={"/mytickets/" + product_id} target="_blank">
                  <div className="mb-2 divTicketImage">
                    <img
                      src={image.image_400}
                      alt="ticket_image"
                      width={100}
                      height={100}
                    />
                  </div>
                </Link>
              </div>
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={8}>
              <div className="searchResultEventName textBlackColor">
                <Link to={"/mytickets/" + product_id} target="_blank">
                  <h5>{listing_name}</h5>
                </Link>
                <div className="font-weight-bold my-2">
                  <i className="fa fa-usd" aria-hidden="true"></i>
                  {cost}
                </div>
                <span>{event_name}</span>
                {significance && (
                  <>
                    <span>|</span>
                    <span>{significance}</span>
                  </>
                )}
              </div>
              <ReactBootstarp.Dropdown.Divider />
              <ReactBootstarp.Row>
                <ReactBootstarp.Col xs={12} md={6}>
                  <div className="searchResultLeftColumn textBlackColor">
                    <div>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span className="searchResultLocation">{venue}</span>
                    </div>
                    <div>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <span className="searchResultDate">{date}</span>
                    </div>
                  </div>
                </ReactBootstarp.Col>
                <ReactBootstarp.Col
                  xs={12}
                  md={6}
                  className="searchResultCenterColumn textBlackColor"
                >
                  {teams.length > 0 && (
                    <div>
                      <i className="fa fa-users" aria-hidden="true"></i>
                      <span className="searchResultTeamArt">
                        {teams.join(", ")}
                      </span>
                    </div>
                  )}
                  {artists.length > 0 && (
                    <div>
                      <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                      <span className="searchResultTeamArt">
                        {artists.join(", ")}
                      </span>
                    </div>
                  )}
                </ReactBootstarp.Col>
              </ReactBootstarp.Row>
              {/* </Link> */}
            </ReactBootstarp.Col>
          </ReactBootstarp.Row>
        </ReactBootstarp.Card.Body>
      </ReactBootstarp.Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
   
  };
};

export default connect(mapStateToProps, null)(ProductCard);
