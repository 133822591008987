import React from "react";
import { Card, Badge, Button } from "react-bootstrap";
import TicketCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";

const CartItem = ({
  type,
  offer_no,
  offer_id,
  collection_name,
  base_price,
  tax_price,
  details,
  seller_id,
  seller_name,
  removeItem,
  expiry_date,
  created_date
}) => {
  return (
    <React.Fragment>
      <Card className="my-2">
        <Card.Header className="d-flex align-items-center py-1">
          <div>
            <div>
              <Badge className="badge-info">{type}</Badge>
            </div>
            <div title="seller">
              <i className="fa fa-handshake-o" aria-hidden="true"></i>
              <Link key={seller_id} to={"/tickets?seller=" + seller_id}>
                <span className="ticketType">{seller_name}</span>
              </Link>
            </div>
            <div>Added On: {created_date}</div>
            <div>Expires On: {expiry_date}</div>
          </div>
          {type === "BUY" && (
            <div className="ml-auto">
              <Button
                className="btn btn-danger"
                title="Remove"
                onClick={() => {
                  removeItem(details[0].product_id);
                }}
              >
                <i title="Remove From Cart" className="fa fa-trash"></i>
              </Button>
            </div>
          )}
          {type === "COLLECTION" && (
            <div className="ml-auto">
              {collection_name}
            </div>
          )}
        </Card.Header>
        <Card.Body>
          {details.map((item) => {
            return (
              <TicketCard
                key={item.product_id}
                listing_name={item.listing_name}
                cost={item.cost}
                image={item.image}
                expiry_date={item.expiry_date}
              />
            );
          })}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <div>Cost:</div>
          <div className="ml-1">
            <i className="fa fa-usd mr-1" aria-hidden="true"></i>
            {base_price}
          </div>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

export default CartItem;
