import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./TeamArtistPage.css";

const Artists = ({ artists, isLoading }) => {
  return (
    <ReactBootstrap.Col xs={12} md={4}>
      <div className="teamArtistListSection">
        <h5>Artists</h5>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <ReactBootstrap.Spinner animation="border" role="status" />
          </div>
        ) : artists.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <p>No Artists found</p>
          </div>
        ) : (
          <ul>
            {artists.map((artist) => (
              <ReactBootstrap.Nav.Link
                key={artist.id}
                as={Link}
                to={"/tickets?artist=" + artist.id}
              >
                <li> {artist.name}</li>
              </ReactBootstrap.Nav.Link>
            ))}
          </ul>
        )}
      </div>
    </ReactBootstrap.Col>
  );
};

Artists.propTypes = {
  artists: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Artists;
