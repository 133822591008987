import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { NavLink } from "react-router-dom";

const MyChatPageHeader = ({ title, refereshMessages , msgLoading ,description }) => {
  return (
    <React.Fragment>
      {!msgLoading && (
        <>
        <div className="d-flex flex-nowrap my-4 mx-0">
          <div className="p-0">
            <ReactBootstrap.Button
              className="btn btn-primary"
              as={NavLink}
              exact
              to={"/account/report/"}
              title="Go back"
            >
              <i className="fa fa-chevron-left"></i>
            </ReactBootstrap.Button>
          </div>
          {title && (
            <div className="d-flex flex-grow-1 align-items-center px-2 py-0">
              <div className=" text-ellipsis-wrap font-weight-bold ml-2">
                {title}
              </div>
            </div>
          )}
          <div className="p-0">
            <ReactBootstrap.Button
              className="btn btn-primary"
              onClick={refereshMessages}
              title="Refresh messages"
            >
              <i className="fa fa-repeat"></i>
            </ReactBootstrap.Button>
          </div>
        </div>
         <ReactBootstrap.Dropdown.Divider />
         <div className="d-flex flex-grow-1 align-items-center px-2 py-0">
                <div className="ml-2">
               <span className=" text-ellipsis-wrap font-weight-bold ml-2"> Description : </span>{description}
                </div>
              </div>
              </>
      )}
      
       
    </React.Fragment>
  );
};

export default MyChatPageHeader;
