import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import { TICKET_CONSTANTS, EVENT_CONSTANTS } from "../../../js/constants";
import "./TicketAdd.css";
import { api as userProtectedApi } from "../../../apis/userProtectedApis.instance";
import { formattedDate } from "../../../js/utils";
import {
  createMyTicket,
  updateMyTicketSetFormData,
  updateMyTicketSubmit,
} from "../../../store/actions/mytickets";
import { connect } from "react-redux";
import { OPERATION_STATUS } from "../../../js/constants";
import { formattedInputDate } from "../../../js/utils";
import { showSpinner, hideSpinner } from "../../../store/actions/ui";

import TicketForm from "./TicketForm/TicketForm";

const TicketAdd = ({
  onCancel,
  onSuccess,
  formData,
  createMyTicket,
  create_ticket_loader,
  create_ticket_status,
  showSpinner,
  hideSpinner,
  logged_user_id,
  operation,
  updateMyTicketSetFormData,
  update_ticket_loader,
  updateMyTicketSubmit,
  update_ticket_status,
}) => {
  const getEventOptions = async () => {
    try {
      var events = await userProtectedApi.Mytickets.getEvent();
      var availableEventOptions = [];
      events.data.data.items.forEach((event) => {
        availableEventOptions.push({
          value:
            event.name +
            ", " +
            formattedDate(event.event_date) +
            ", " +
            event.venue.display_name,
          label:
            event.name +
            ", " +
            formattedDate(event.event_date) +
            ", " +
            event.venue.display_name,
          event: event,
          canEdit:
            event.is_admin_created === false &&
            logged_user_id === event.created_by,
          showEdit: false,
        });
      });

      setTicketForm((draft) => {
        draft.options.events = availableEventOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getGenreOptions = async () => {
    try {
      var genres = await userProtectedApi.Mytickets.getGenre();
      var availableGenresOptions = [];
      // var availableSubGenresOptions = [];
      genres.data.data.items.forEach((genre) => {
        availableGenresOptions.push({
          value: genre.genre_name,
          label: genre.display_name,
          genre: {
            id: genre.genre_id,
            name: genre.genre_name,
            display_name: genre.display_name,
          },
        });
      });

      setTicketForm((draft) => {
        draft.options.genres = availableGenresOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSubGenreOptions = async () => {
    try {
      var sub_genres = await userProtectedApi.Mytickets.getSubGenre();
      var availableSubGenresOptions = [];
      sub_genres.data.data.items.forEach((sub_genre) => {
        availableSubGenresOptions.push({
          value: sub_genre.name,
          label: sub_genre.display_name,
          id: sub_genre.sub_genre_id,
          genre_id: sub_genre.genre.id,
          display_name: sub_genre.display_name,
          name: sub_genre.name,
          canEdit:
            sub_genre.is_admin_created === false &&
            sub_genre.status === "INQUEUE" &&
            logged_user_id === sub_genre.created_by,
          showEdit: false,
        });
      });

      setTicketForm((draft) => {
        draft.options.availableSubGenreOptions = availableSubGenresOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getVenueOptions = async () => {
    try {
      var venues = await userProtectedApi.Mytickets.getVenue();
      var availableVenueOptions = [];
      venues.data.data.items.forEach((venue) => {
        availableVenueOptions.push({
          value: venue.venue_name,
          label: venue.display_name,
          canEdit:
            venue.is_admin_created === false &&
            venue.status === "INQUEUE" &&
            logged_user_id === venue.created_by,
          showEdit: false,
          venue: {
            id: venue.venue_id,
            name: venue.venue_name,
            display_name: venue.display_name,
          },
        });
      });

      setTicketForm((draft) => {
        draft.options.venues = availableVenueOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamOptions = async () => {
    try {
      var teams = await userProtectedApi.Mytickets.getTeam();
      var availableTeamOptions = [];
      teams.data.data.items.forEach((team) => {
        availableTeamOptions.push({
          value: team.team_name,
          label: team.display_name,
          id: team.team_id,
          name: team.team_name,
          display_name: team.display_name,
          sub_genre_id: team.sub_genre.id,
          isTeam: true,
          isDisabled: false,
          canEdit:
            team.is_admin_created === false &&
            team.status === "INQUEUE" &&
            logged_user_id === team.created_by,
          showEdit: false,
        });
      });

      setTicketForm((draft) => {
        draft.options.availableTeamsOptions = availableTeamOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getArtistOptions = async () => {
    try {
      var artists = await userProtectedApi.Mytickets.getArtist();
      var availableArtistsOptions = [];
      artists.data.data.items.forEach((artist) => {
        availableArtistsOptions.push({
          value: artist.artist_name,
          label: artist.display_name,
          id: artist.artist_id,
          name: artist.artist_name,
          display_name: artist.display_name,
          sub_genre_id: artist.sub_genre.id,
          isTeam: false,
          isDisabled: false,
          canEdit:
            artist.is_admin_created === false &&
            artist.status === "INQUEUE" &&
            logged_user_id === artist.created_by,
          showEdit: false,
        });
      });

      setTicketForm((draft) => {
        draft.options.availableArtistsOptions = availableArtistsOptions;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterData = async () => {
    try {
      var availableCollection = [];
      var availableTicketType = [];
      var availableSeatType = [];
      var response = await userProtectedApi.Mytickets.getMetaData();
      response.data.data.ticketType.forEach((ty) => {
        availableTicketType.push({
          value: ty.name,
          label: ty.name,
          id: ty.id,
        });
      });

      response.data.data.seatType.forEach((se) => {
        availableSeatType.push({
          value: se.name,
          label: se.name,
          id: se.id,
        });
      });
      var response_data = await userProtectedApi.Mytickets.getCollectionData();
      response_data.data.data.items.forEach((cl) => {
        availableCollection.push({
          value: cl.id,
          label: cl.collection_name,
          id: cl.id,
        });
      });
      setTicketForm((draft) => {
        draft.options.ticketTypes = availableTicketType;
        draft.options.seatTypes = availableSeatType;
        draft.options.collection =availableCollection
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Load dropdown values
  const getFormOptions = async () => {
    return Promise.all([
      getGenreOptions(),
      getSubGenreOptions(),
      getVenueOptions(),
      getTeamOptions(),
      getArtistOptions(),
      getEventOptions(),
      getMasterData(),
    ]);
  };

  useEffect(() => {
    if (operation === "Add") {
      showSpinner("myticket_create");
    } else {
      showSpinner("myticket_update");
    }
    getFormOptions().then(() => {
      if (operation === "Add") {
        hideSpinner("myticket_create");
      } else {
        hideSpinner("myticket_update");
      }
    });
  }, []);

  useEffect(() => {
    if (
      create_ticket_status === OPERATION_STATUS.SUCCESS ||
      update_ticket_status === OPERATION_STATUS.SUCCESS
    ) {
      onSuccess();
    }
  }, [create_ticket_status, update_ticket_status]);

  let ticketOptions = {
    collection:[],
    seatTypes: [],
    ticketTypes: [],
    isSale:[{
      value: 1,
      label: "Sale",
    },
    {
      value: 0,
      label: "Show only",
    }],
    events: [],
    teams_artists: [
      {
        label: "Teams",
        options: [],
      },
      {
        label: "Artists",
        options: [],
      },
    ],
    venues: [],
    genres: [],
    subGenres: [],
    quality: [
      {
        value: "EXCELLENT",
        label: "EXCELLENT",
      },
      {
        value: "GOOD",
        label: "GOOD",
      },
      {
        value: "AVERAGE",
        label: "AVERAGE",
      },
      {
        value: "POOR",
        label: "POOR",
      },
      {
        value: "VERY_POOR",
        label: "VERY_POOR",
      },
    ],
    availableTeamsOptions: [],
    availableArtistsOptions: [],
    availableSubGenreOptions: [],
  };

  let ticket = {
    product_id: formData.product_id,
    listing_name: formData.listing_name,
    event: {
      id: formData.event.id,
      name: formData.event.name,
      date: formData.event.date,
      currentEvent: formData.event.currentEvent,
      currentVenue: formData.event.currentVenue,
      additional_significance: {
        id: formData.event.additional_significance.id,
        significance: formData.event.additional_significance.significance
      },
      additional_options: {
        significance: formData.event.additional_options.significance,
        currentGenre: formData.event.additional_options.currentGenre,
        currentSubGenre: formData.event.additional_options.currentSubGenre,
        currentTeamArtists:
          formData.event.additional_options.currentTeamArtists,
      },
      isNew: false,
      operation: "Add",
    },
    currentTicketType: formData.currentTicketType,
    isSale:formData.isSale,
    currentQuality: formData.currentQuality,
    removedfiles: [],
    imagefiles: formData.imagefiles,
    cost: formData.cost,
    additional_options: {
      collection: formData.additional_options.collection,
      description: formData.additional_options.description,
      currentSeatType: formData.additional_options.currentSeatType,
      face_price: formData.additional_options.face_price,
      section: formData.additional_options.section,
      row: formData.additional_options.row,
      seat: formData.additional_options.seat,
      grade: formData.additional_options.grade,
      document: formData.additional_options.document,
    },
    error: {
      listing_name: "",
      event: {
        id: "",
        name: "",
        currentEvent: "",
        additional_significance: "",
        date: "",
        currentVenue: "",
        additional_options: {
          significance: "",
          currentGenre: "",
          currentSubGenre: "",
          currentTeamArtists: "",
        },
      },
      currentTicketType: "",
      isSale:"",
      currentQuality: "",
      imagefiles: "",
      cost: "",
      additional_options: {
        description: "",
        collection:"",
        currentSeatType: "",
        face_price: "",
        section: "",
        row: "",
        seat: "",
        grade: "",
        document: "",
      },
    },
  };
  const [ticketForm, setTicketForm] = useImmer({
    ticket: ticket,
    options: ticketOptions,
  });

  const onEventOptionEdit = (record) => {
    let event = record.event;

    var currentGenre = null;
    var currentSubGenre = null;
    var currentTeamArtists = [null];

    if (event.genre) {
      currentGenre = {
        value: event.genre.genre_name,
        label: event.genre.display_name,
        genre: {
          id: event.genre.genre_id,
          name: event.genre.genre_name,
          display_name: event.genre.display_name,
        },
      };
      if (event.genre.sub_genre) {
        currentSubGenre = {
          value: event.genre.sub_genre.name,
          label: event.genre.sub_genre.display_name,
          id: event.genre.sub_genre.id,
          genre_id: event.genre.genre_id,
          display_name: event.genre.sub_genre.display_name,
          name: event.genre.sub_genre.name,
          canEdit:
            event.genre.sub_genre.is_admin_created === false &&
            event.genre.sub_genre.status === "INQUEUE" &&
            logged_user_id === event.genre.sub_genre.created_by,
          showEdit: false,
        };
      }
      let teams = event.genre.teams.map((team) => {
        return {
          value: team.name,
          label: team.display_name,
          id: team.id,
          name: team.name,
          display_name: team.display_name,
          sub_genre_id: event.genre.sub_genre.id,
          isTeam: true,
          isDisabled: false,
          canEdit:
            team.is_admin_created === false &&
            team.status === "INQUEUE" &&
            logged_user_id === team.created_by,
          showEdit: false,
        };
      });
      let artists = event.genre.artists.map((artist) => {
        return {
          value: artist.name,
          label: artist.display_name,
          id: artist.id,
          name: artist.name,
          display_name: artist.display_name,
          sub_genre_id: event.genre.sub_genre.id,
          isTeam: false,
          isDisabled: false,
          canEdit:
            artist.is_admin_created === false &&
            artist.status === "INQUEUE" &&
            logged_user_id === artist.created_by,
          showEdit: false,
        };
      });
      if (teams.length > 0 && artists.length > 0) {
        // Both team and artist available
        currentTeamArtists = [...teams, ...artists];
      } else if (teams.length > 0) {
        // Only team is available
        currentTeamArtists = [...teams];
      } else if (artists.length > 0) {
        // Only artist is available
        currentTeamArtists = [...artists];
      } else {
        // Both team and artist empty
        currentTeamArtists = [null];
      }
    }

    setTicketForm((draft) => {
      draft.ticket.event.isNew = true;
      draft.ticket.event.operation = "Edit";
      draft.ticket.event.id = event.event_id;
      draft.ticket.event.name = event.name;
      draft.ticket.event.date = formattedInputDate(event.event_date);
      draft.ticket.event.currentVenue = {
        value: event.venue.name,
        label: event.venue.display_name,
        canEdit:
          event.venue.is_admin_created === false &&
          event.venue.status === "INQUEUE" &&
          logged_user_id === event.venue.created_by,
        showEdit: false,
        venue: {
          id: event.venue.id,
          name: event.venue.name,
          display_name: event.venue.display_name,
        },
      };
      draft.ticket.event.additional_options.significance = event.significance;
      draft.ticket.event.additional_options.currentGenre = currentGenre;
      draft.ticket.event.additional_options.currentSubGenre = currentSubGenre;
      draft.ticket.event.additional_options.currentTeamArtists = currentTeamArtists;

      if (currentGenre) {
        draft.options.subGenres = draft.options.availableSubGenreOptions.filter(
          (sg) => parseInt(sg.genre_id) === parseInt(currentGenre.genre.id)
        );
      }

      if (currentSubGenre) {
        draft.options.teams_artists = [
          {
            label: "Teams",
            options: draft.options.availableTeamsOptions.filter(
              (t) => parseInt(t.sub_genre_id) === parseInt(currentSubGenre.id)
            ),
          },
          {
            label: "Artists",
            options: draft.options.availableArtistsOptions.filter(
              (a) => parseInt(a.sub_genre_id) === parseInt(currentSubGenre.id)
            ),
          },
        ];
      }
    });
  };

  const onEditInputCancel = (name, index) => {
    switch (name) {
      // For Event new mandatory react select
      case "currentVenue":
        setTicketForm((draft) => {
          draft.ticket.event.currentVenue.showEdit = false;
          draft.ticket.event.currentVenue.value =
            draft.ticket.event.currentVenue.venue.name;
          draft.ticket.event.currentVenue.label =
            draft.ticket.event.currentVenue.venue.display_name;
        });
        break;
      case "currentSubGenre":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentSubGenre.showEdit = false;
          draft.ticket.event.additional_options.currentSubGenre.value =
            draft.ticket.event.additional_options.currentSubGenre.name;
          draft.ticket.event.additional_options.currentSubGenre.label =
            draft.ticket.event.additional_options.currentSubGenre.display_name;
        });
        break;
      case "currentTeamArtists":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].showEdit = false;
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].value =
            draft.ticket.event.additional_options.currentTeamArtists[
              index
            ].name;
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].label =
            draft.ticket.event.additional_options.currentTeamArtists[
              index
            ].display_name;
        });
        break;
      default:
    }
  };

  const onOptionEditHandle = (record, name, index) => {
    switch (name) {
      // For Event new mandatory react select
      case "currentVenue":
        setTicketForm((draft) => {
          draft.ticket.event[name] = { ...record, showEdit: true };
        });
        break;
      case "currentSubGenre":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options[name] = {
            ...record,
            showEdit: true,
          };
        });
        break;
      case "currentTeamArtists":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentTeamArtists[index] = {
            ...record,
            showEdit: true,
          };
        });
        break;
      default:
    }
  };

  const handleInputChange = (name, index, e) => {
    switch (name) {
      // Ticket mandatory options html input
      case "listing_name":
      case "cost":
        setTicketForm((draft) => {
          draft.ticket[name] = e.target.value;
        });
        break;
      // Ticket mandatory options react select dropdown
      case "currentTicketType":
      case "currentQuality":
      case "isSale":
        setTicketForm((draft) => {
          draft.ticket[name] = e;
        });
        break;
      case "additional_significance":
        setTicketForm((draft) => {
          draft.ticket.event.additional_significance.significance = e.target.value;
        });
        break;
      // Ticket additional options html input
      case "description":
      case "face_price":
      case "section":
      case "row":
      case "seat":
      case "grade":
        setTicketForm((draft) => {
          draft.ticket.additional_options[name] = e.target.value;
        });
        break;
      // Ticket additional options react select dropdown
      case "currentSeatType":
      case "collection":
        setTicketForm((draft) => {
          draft.ticket.additional_options[name] = e;
        });
        break;
      // For Event new mandatory html input
      case "name":
      case "date":
        setTicketForm((draft) => {
          draft.ticket.event[name] = e.target.value;
        });
        break;

      // For handling new event form
      case "event_new":
        setTicketForm((draft) => {
          draft.ticket.event.isNew = true;
          draft.ticket.event.operation = "Add";
          draft.ticket.event.id = "";
          draft.ticket.event.name = "";
          draft.ticket.event.date = "";
          draft.ticket.event.currentVenue = null;
          draft.ticket.event.additional_options.significance = "";
          draft.ticket.event.additional_options.currentGenre = null;
          draft.ticket.event.additional_options.currentSubGenre = null;
          draft.ticket.event.additional_options.currentTeamArtists = [null];
        });
        break;
      case "event_old":
        setTicketForm((draft) => {
          draft.ticket.event.isNew = false;
        });
        break;
      // For Event new mandatory react select
      case "currentVenue":
      // For existing event react select
      case "currentEvent":
        setTicketForm((draft) => {
          draft.ticket.event[name] = e;
        });
        break;

      // For editing current venue
      case "currentVenue_update":
        setTicketForm((draft) => {
          draft.ticket.event.currentVenue.value = e.target.value;
          draft.ticket.event.currentVenue.label = e.target.value;
        });
        break;
      // For Event new additional info
      case "currentGenre":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options[name] = e;
          draft.options.subGenres = draft.options.availableSubGenreOptions.filter(
            (sg) => parseInt(sg.genre_id) === parseInt(e.genre.id)
          );
          draft.ticket.event.additional_options.currentSubGenre = null;
          draft.ticket.event.additional_options.currentTeamArtists = [null];
          draft.options.teams_artists = [
            {
              label: "Teams",
              options: [],
            },
            {
              label: "Artists",
              options: [],
            },
          ];
        });
        break;
      case "currentSubGenre":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentSubGenre = e;
          draft.ticket.event.additional_options.currentTeamArtists = [null];
          draft.options.teams_artists = [
            {
              label: "Teams",
              options: draft.options.availableTeamsOptions.filter(
                (t) => parseInt(t.sub_genre_id) === parseInt(e.id)
              ),
            },
            {
              label: "Artists",
              options: draft.options.availableArtistsOptions.filter(
                (a) => parseInt(a.sub_genre_id) === parseInt(e.id)
              ),
            },
          ];
        });
        break;
      // For editing current venue
      case "currentSubGenre_update":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentSubGenre.value =
            e.target.value;
          draft.ticket.event.additional_options.currentSubGenre.label =
            e.target.value;
        });
        break;
      case "currentTeamArtists":
        setTicketForm((draft) => {
          if ("__isNew__" in e) {
            // New team artist
            draft.ticket.event.additional_options.currentTeamArtists[index] = {
              ...e,
              isTeam: false,
            };
          } else {
            draft.ticket.event.additional_options.currentTeamArtists[index] = e;
          }
        });
        break;
      case "currentTeamArtists_update":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].value = e.target.value;
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].label = e.target.value;
        });
        break;
      case "isTeam":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options.currentTeamArtists[
            index
          ].isTeam = e.target.checked;
        });
        break;
      case "significance":
        setTicketForm((draft) => {
          draft.ticket.event.additional_options[name] = e.target.value;
        });

        break;
      default:
    }
  };

  // Add an teamArtist field in form if the last one is filled
  const addTeamArtist = () => {
    setTicketForm((draft) => {
      var currentTeamArtists = [
        ...draft.ticket.event.additional_options.currentTeamArtists,
      ];
      var currentTeamArtistLength = currentTeamArtists.length;
      var lastteamartist = currentTeamArtists[currentTeamArtistLength - 1];
      if (lastteamartist) {
        draft.ticket.event.additional_options.currentTeamArtists.push(null);
      }
    });
  };

  const removeTeamArtist = (index) => {
    setTicketForm((draft) => {
      draft.ticket.event.additional_options.currentTeamArtists.splice(index, 1);
    });
  };

  // Performs validation
  // Set error
  // Return details on successful validation
  const ticket_mandatory_basic_validation = () => {
    let listing_name = ticketForm.ticket.listing_name;
    // Listing name validation
    if (listing_name.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.listing_name = "Please enter the listing name.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.listing_name = "";
      });
    }

    // Listing name length validation
    if (listing_name.length > TICKET_CONSTANTS.TICKET_LISTING_NAME_MAX_LENGTH) {
      setTicketForm((draft) => {
        draft.ticket.error.listing_name = `Listing name length should not exceed ${TICKET_CONSTANTS.TICKET_LISTING_NAME_MAX_LENGTH} characters.`;
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.listing_name = "";
      });
    }

    let ticket_type = ticketForm.ticket.currentTicketType
      ? ticketForm.ticket.currentTicketType.id
      : "";
    // Ticket type validation
    if (ticket_type.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.currentTicketType = "Please select the ticket type.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.currentTicketType = "";
      });
    }
// validation for is Sale or display
    let isSale = ticketForm.ticket.isSale
    ? ticketForm.ticket.isSale.value
    : "";

    if (isSale.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.isSale = "Please select the view type.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.isSale = "";
      });
    }
 // Ticket cost validation
 let cost = ticketForm.ticket.cost ? ticketForm.ticket.cost :"";
 if (ticketForm.ticket.isSale.value === 1 && cost.length === 0) {
   setTicketForm((draft) => {
     draft.ticket.error.cost = "Please enter the ticket price.";
   });
   return false;
 } else {
   setTicketForm((draft) => {
     draft.ticket.error.cost = "";
   });
 }

    // TODO need to check cost validation

    let quality = ticketForm.ticket.currentQuality
      ? ticketForm.ticket.currentQuality.value
      : "";
    // Ticket quality validation
    if (quality.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.currentQuality = "Please select the ticket quality.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.currentQuality = "";
      });
    }

    // Event Validation
    let event_details = event_validation();
    if (event_details === false) {
      return false;
    }

    // Image validation
    let ticket_id = ticketForm.ticket.product_id;
    let images = ticketForm.ticket.imagefiles;
    if (ticket_id.length === 0 && images.length === 0) {
      // New ticket , so aleast one image is required
      setTicketForm((draft) => {
        draft.ticket.error.imagefiles =
          "Please upload atleast one image for ticket.";
      });
      return false;
    }

    let ticket_optional = ticket_additional_basic_validation();
    if (ticket_optional.is_valid === false) {
      return false;
    }
    let new_images = images.filter((image) => image.isnew);
    let valid_ticket = {
      listing_name: listing_name,
      event: event_details,
      images: new_images.map((image) => {
        return {
          image_file: image.image_file,
          image_name: image.image_name,
        };
      }),
      ticket_type: ticket_type,
      isSale:isSale,
      cost: cost,
      quality: quality,
      support_document: ticket_optional.data.support_document,
    };
    if (Object.keys(ticket_optional.data.ticket_details).length > 0) {
      valid_ticket.ticket_details = ticket_optional.data.ticket_details;
    }

    return valid_ticket;
  };

  // Performs validation
  // Set error
  // Return details on successful validation
  const ticket_additional_basic_validation = () => {
    let result = {
      is_valid: false,
      data: null,
    };
    let ticket_details = {};
    let support_document = [];
    // Regex for cost/money
    const regex = /^[+]?\d*(?:[.]\d*)?$/;
    let face_price = ticketForm.ticket.additional_options.face_price;
    let description = ticketForm.ticket.additional_options.description;
    let seat_type = ticketForm.ticket.additional_options.currentSeatType
      ? ticketForm.ticket.additional_options.currentSeatType.id
      : "";
    let collection = ticketForm.ticket.additional_options.collection
    ? ticketForm.ticket.additional_options.collection.id
    : "";
    let section = ticketForm.ticket.additional_options.section;
    let row = ticketForm.ticket.additional_options.row;
    let seat = ticketForm.ticket.additional_options.seat;
    let grade = ticketForm.ticket.additional_options.grade;
    let document = ticketForm.ticket.additional_options.document;

    if (section.length > 0) {
      if (section.length > TICKET_CONSTANTS.TICKET_SECTION_MAX_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.section = `Section length should not exceed ${TICKET_CONSTANTS.TICKET_SECTION_MAX_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.section = "";
        });
        ticket_details.section = section;
      }
    }

    if (row.length > 0) {
      if (row.length > TICKET_CONSTANTS.TICKET_ROW_MAX_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.row = `Row length should not exceed ${TICKET_CONSTANTS.TICKET_ROW_MAX_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.row = "";
        });
        ticket_details.row = row;
      }
    }

    if (seat.length > 0) {
      if (seat.length > TICKET_CONSTANTS.TICKET_SEAT_NO_MAX_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.seat = `Seat length should not exceed ${TICKET_CONSTANTS.TICKET_SEAT_NO_MAX_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.seat = "";
        });
        ticket_details.seat = seat;
      }
    }

    if (face_price.length > 0) {
      if (!regex.test(face_price)) {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.face_price =
            "Please enter valid face price.";
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.face_price = "";
        });
        ticket_details.face_price = parseFloat(face_price);
      }
    }

    if (description.length > 0) {
      // if (description.length > TICKET_CONSTANTS.TICKET_DESCRIPTION_MAX_LENGTH) {
      //   setTicketForm((draft) => {
      //     draft.ticket.error.additional_options.description = `Description length should not exceed ${TICKET_CONSTANTS.TICKET_DESCRIPTION_MAX_LENGTH} characters.`;
      //   });
      //   return result;
      // } else {
      //   setTicketForm((draft) => {
      //     draft.ticket.error.additional_options.description = "";
      //   });
      // }
      ticket_details.description = description;
    }

    if (grade.length > 0) {
      if (grade.length > TICKET_CONSTANTS.TICKET_GRADE_MAX_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.grade = `Grade length should not exceed ${TICKET_CONSTANTS.TICKET_GRADE_MAX_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.additional_options.grade = "";
        });
        ticket_details.grade = grade;
      }
    }

    if (seat_type.length > 0) {
      ticket_details.seat_type = seat_type;
    }
    if (collection) {
      ticket_details.collection = collection;
    }
    

    if (document.length > 0) {
      let support_doc = {
        document_data: document[0].document_data,
        document_name: document[0].document_name,
      };
      if (document[0].doc_id.length > 0) {
        support_doc.doc_id = document[0].doc_id;
      }
      support_document.push(support_doc);
    }

    result.is_valid = true;
    result.data = {
      ticket_details: ticket_details,
      support_document: support_document,
    };
    return result;
  };

  // Performs validation
  // Set error
  // Return details on successful validation
  const event_validation = () => {
    let event = ticketForm.ticket.event;
    if (event.isNew) {
      // create new event or edit selected event
      let eventMandatoryDetails = new_event_mandatory_validation();
      if (eventMandatoryDetails === false) {
        // Event validation failed
        return false;
      } else {
        let eventOptionalDetails = new_event_additional_options_validation();
        if (eventOptionalDetails.is_valid === false) {
          // Entered additional info in event but having validation error
          return false;
        } else {
          // TODO genreate event optional details
          let valid_event = {
            event_name: eventMandatoryDetails.event_name,
            event_date: formattedInputDate(eventMandatoryDetails.event_date),
            venue: eventMandatoryDetails.venue,
          };
          if (eventMandatoryDetails.event_id) {
            // Edit Existing event
            valid_event.event_id = eventMandatoryDetails.event_id;
          }

          valid_event.significance = eventOptionalDetails.data.significance;
          valid_event.genre = eventOptionalDetails.data.genre;

          return valid_event;
        }
      }
    } else {
      // Select the existing event
      if (event.currentEvent) {
        setTicketForm((draft) => {
          draft.ticket.error.event.currentEvent = "";
        });

        let additional_significance = null;
        if(event.additional_significance.significance.length > 0){
          additional_significance = {
            id: event.additional_significance.id,
            significance: event.additional_significance.significance
          }
        }

        let genre = null;
        if(event.currentEvent.event.genre){
          let form_genre = event.currentEvent.event.genre
          genre = {
            genre_id: form_genre.genre_id
          }
          if(form_genre.sub_genre){
            let form_subgenre = form_genre.sub_genre
            genre.sub_genre = {
              id: form_subgenre.id,
              name: form_subgenre.name,
              display_name: form_subgenre.display_name,
            }
            let form_teams = form_genre.teams
            if(form_teams.length > 0){
              genre.teams = form_teams.map(team => {
                return {
                  id: team.id,
                  name: team.name,
                  display_name: team.display_name,
                }
              })
            }
            let form_artists = form_genre.artists
            if(form_artists.length > 0){
              genre.artists = form_artists.map(artist => {
                return {
                  id: artist.id,
                  name: artist.name,
                  display_name: artist.display_name,
                }
              })
            }
          }
        }
        // event selected
        return {
          event_id: event.currentEvent.event.event_id,
          event_name: event.currentEvent.event.name,
          event_date: formattedInputDate(event.currentEvent.event.event_date),
          significance: event.currentEvent.event.significance,
          additional_significance: additional_significance,
          venue: {
            id: event.currentEvent.event.venue.id,
            name: event.currentEvent.event.venue.name,
            display_name: event.currentEvent.event.venue.display_name,
          },
          genre: genre
        };
      } else {
        // No event is selected
        setTicketForm((draft) => {
          draft.ticket.error.event.currentEvent =
            "Please select the ticket event.";
        });
        return false;
      }
    }
  };

  // Performs validation
  // Set error
  // Return details on successful validation
  const new_event_mandatory_validation = () => {
    let event_name = ticketForm.ticket.event.name;
    // Event name validation
    if (event_name.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.event.name = "Please enter the event name.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.event.name = "";
      });
    }

    // Event name length validation
    if (event_name.length > EVENT_CONSTANTS.EVENT_NAME_MAX_LENGTH) {
      setTicketForm((draft) => {
        draft.ticket.error.event.name = `Event name length should not exceed ${EVENT_CONSTANTS.EVENT_NAME_MAX_LENGTH} characters.`;
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.event.name = "";
      });
    }

    let venue = {};
    if (ticketForm.ticket.event.currentVenue) {
      setTicketForm((draft) => {
        draft.ticket.error.event.currentVenue = "";
      });
      let valid_venue = venue_validation(ticketForm.ticket.event.currentVenue);
      if (valid_venue.is_valid) {
        venue = valid_venue.data;
      } else {
        return false;
      }
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.event.currentVenue =
          "Please select the event venue.";
      });
      return false;
    }

    let event_date = ticketForm.ticket.event.date;
    // Event date validation
    if (event_date.length === 0) {
      setTicketForm((draft) => {
        draft.ticket.error.event.date = "Please select the event date.";
      });
      return false;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.event.date = "";
      });
    }

    // All validation success return event
    return {
      event_id: ticketForm.ticket.event.id,
      event_name: event_name,
      event_date: event_date,
      venue: venue,
    };
  };

  const venue_validation = (venue) => {
    let result = {
      is_valid: false,
      data: null,
    };
    if ("__isNew__" in venue) {
      // New venue
      // Listing name length validation
      if (venue.value.length > EVENT_CONSTANTS.VENUE_NAME_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.event.currentVenue = `Venue name length should not exceed ${EVENT_CONSTANTS.VENUE_NAME_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.event.currentVenue = "";
        });
      }

      result.is_valid = true;
      result.data = {
        name: venue.value,
        display_name: venue.label,
      };
    } else {
      result.is_valid = true;
      result.data = {
        id: venue.venue.id,
        name: venue.value,
        display_name: venue.label,
      };
    }

    return result;
  };

  // Performs validation
  // Set error
  // Return details on successful validation
  const new_event_additional_options_validation = () => {
    let result = {
      is_valid: false,
      data: null,
    };
    let genre = ticketForm.ticket.event.additional_options.currentGenre;
    let significance = ticketForm.ticket.event.additional_options.significance;
    let subgenre = ticketForm.ticket.event.additional_options.currentSubGenre;
    let teams_artists =
      ticketForm.ticket.event.additional_options.currentTeamArtists;

    // if (significance.length > EVENT_CONSTANTS.EVENT_SIGNIFICANCE_MAX_LENGTH) {
    //   setTicketForm((draft) => {
    //     draft.ticket.error.additional_options.significance = `Significance length should not exceed ${EVENT_CONSTANTS.EVENT_SIGNIFICANCE_MAX_LENGTH} characters.`;
    //   });
    //   return result;
    // } else {
    //   setTicketForm((draft) => {
    //     draft.ticket.error.additional_options.significance = "";
    //   });
    // }

    // Genre validation
    if (genre === null && subgenre !== null) {
      setTicketForm((draft) => {
        draft.ticket.error.additional_options.currentGenre =
          "Please select a genre.";
      });
      return result;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.additional_options.currentGenre = "";
      });
    }

    // Check team artist is entered without selecting genre and sub genre
    if (
      genre === null &&
      subgenre === null &&
      teams_artists.length > 0 &&
      teams_artists[0] !== null
    ) {
      setTicketForm((draft) => {
        draft.ticket.error.additional_options.currentGenre =
          "Please select a genre.";
        draft.ticket.error.additional_options.currentSubGenre =
          "Please select a subgenre.";
      });
      return result;
    } else if (
      subgenre === null &&
      teams_artists.length > 0 &&
      teams_artists[0] !== null
    ) {
      // Check team artist is entered without selecting sub genre
      setTicketForm((draft) => {
        draft.ticket.error.additional_options.currentGenre = "";
        draft.ticket.error.additional_options.currentSubGenre =
          "Please select a subgenre.";
      });
      return result;
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.additional_options.currentGenre = "";
        draft.ticket.error.additional_options.currentSubGenre = "";
      });
    }

    // Validation flow
    if (genre) {
      // Genre is selected
      if (subgenre) {
        // Sub genre is given
        let valid_subgenre = subgenre_validation(subgenre);
        if (valid_subgenre.is_valid) {
          // Valid sub genre
          let valid_team_artist = team_artist_validation(teams_artists);
          if (valid_team_artist.is_valid) {
            // Valid team artist
            result.is_valid = true;
            result.data = {
              significance: significance,
              genre: {
                genre_id: genre.genre.id,
                sub_genre: valid_subgenre.data,
                teams: valid_team_artist.data.teams,
                artists: valid_team_artist.data.artists,
              },
            };
          } else {
            // Not valid team artist
            result.is_valid = false;
            result.data = null;
            return result;
          }
        } else {
          // Not valid sub genre
          result.is_valid = false;
          result.data = null;
          return result;
        }
      } else {
        // Sub genre is not given
        result.is_valid = true;
        result.data = {
          significance: significance,
          genre: {
            genre_id: genre.genre.id,
            sub_genre: null,
            teams: [],
            artists: [],
          },
        };
      }
    } else {
      // Genre not selected so not process
      result.is_valid = true;
      result.data = {
        significance: significance,
        genre: null,
      };
    }
    return result;
  };

  const subgenre_validation = (subgenre) => {
    let result = {
      is_valid: false,
      data: null,
    };
    if ("__isNew__" in subgenre) {
      // New sub genre
      // Listing name length validation
      if (subgenre.value.length > EVENT_CONSTANTS.SUB_GENRE_NAME_LENGTH) {
        setTicketForm((draft) => {
          draft.ticket.error.event.additional_options.currentSubGenre = `Sub genre name length should not exceed ${EVENT_CONSTANTS.SUB_GENRE_NAME_LENGTH} characters.`;
        });
        return result;
      } else {
        setTicketForm((draft) => {
          draft.ticket.error.event.additional_options.currentSubGenre = "";
        });
      }

      result.is_valid = true;
      result.data = {
        name: subgenre.value,
        display_name: subgenre.label,
      };
    } else {
      result.is_valid = true;
      result.data = {
        name: subgenre.value,
        display_name: subgenre.label,
        id: subgenre.id,
      };
    }

    return result;
  };

  const team_artist_validation = (team_artists) => {
    let result = {
      is_valid: false,
      data: null,
    };

    if (team_artists.length > 0 && team_artists[0] !== null) {
      let teams = [];
      let artists = [];
      let duplicateArtists = [];
      let duplicateTeams = [];

      // Check if some team / artist is not selected
      if (team_artists.some((artist) => artist === null)) {
        setTicketForm((draft) => {
          draft.ticket.error.event.additional_options.currentTeamArtists =
            "Please select a team/artist.";
        });
        return result;
      }

      // Generate team artist
      team_artists.forEach((ta) => {
        if (ta.isTeam) {
          if (teams.find((t) => t.name === ta.value)) {
            duplicateTeams.push(ta.value);
          } else {
            let team = {
              name: ta.value,
              display_name: ta.label,
            };
            if ("__isNew__" in ta) {
              // New team not push id
            } else {
              // Existing team push id
              team.id = ta.id;
            }
            teams.push(team);
          }
        } else {
          if (artists.find((a) => a.name === ta.value)) {
            duplicateArtists.push(ta.value);
          } else {
            let artist = {
              name: ta.value,
              display_name: ta.label,
            };
            if ("__isNew__" in ta) {
              // New team not push id
            } else {
              // Existing team push id
              artist.id = ta.id;
            }
            artists.push(artist);
          }
        }
      });

      // Check for duplication
      if (duplicateArtists.length > 0 || duplicateTeams.length > 0) {
        setTicketForm((draft) => {
          draft.ticket.error.event.additional_options.currentTeamArtists =
            "Duplicate team/artists value exists. Please select unique team/artists.";
        });
        return result;
      }

      // Valid team / artists
      result.is_valid = true;
      result.data = {
        teams: teams,
        artists: artists,
      };
    } else {
      // No team artist is selected
      result.is_valid = true;
      result.data = {
        teams: [],
        artists: [],
      };
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let ticket = ticket_mandatory_basic_validation();
    if (ticket === false) {
      return false;
    }
    // alert("Ticket created")
    if (operation === "Add") {
     createMyTicket(ticket);
    } else if (operation === "Edit") {
      ticket.product_id = ticketForm.ticket.product_id;
      let removedFiles = ticketForm.ticket.removedfiles;
      updateMyTicketSetFormData(ticket, removedFiles);
      updateMyTicketSubmit();
    }

    window.scrollTo(0, 0);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
        // return baseURL;
      };
      // return baseURL;
    });
  };

  const handleImageChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setTicketForm((draft) => {
        draft.ticket.error.imagefiles =
          "File type must be image/jpeg, image/png";
      });
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.imagefiles = "";
      });
    }

    let uploadedImages = acceptedFiles.map(async (file) => {
      let base64String = await getBase64(file);
      return {
        image_id: "",
        image_url: URL.createObjectURL(file),
        image_file: base64String
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", ""),
        image_name: file.name,
        isnew: true,
      };
    });
    Promise.all(uploadedImages).then((images) =>
      setTicketForm((draft) => {
        draft.ticket.imagefiles = [...draft.ticket.imagefiles, ...images];
      })
    );
  };

  const handleDocsChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setTicketForm((draft) => {
        draft.ticket.error.document =
          "File type must be *.pdf,*.png,text/plain,image/jpeg,image/png";
      });
    } else {
      setTicketForm((draft) => {
        draft.ticket.error.document = "";
      });
    }

    let uploadedDocs = acceptedFiles.map(async (file) => {
      let base64String = await getBase64(file);
      return {
        doc_id: "",
        document_data: base64String
          .replace("data:application/pdf;base64,", "")
          .replace("data:application/vnd.ms-excel;base64,", "")
          .replace(
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            ""
          )
          .replace("data:application/msword;base64,", "")
          .replace(
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
            ""
          )
          .replace("data:text/plain;base64,", "")
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpeg;base64,", ""),
        document_name: file.name,
      };
    });

    Promise.all(uploadedDocs).then((docs) => {
      setTicketForm((draft) => {
        if (draft.ticket.additional_options.document.length > 0) {
          // For updating already loaded document
          draft.ticket.additional_options.document[0].document_data =
            docs[0].document_data;
          draft.ticket.additional_options.document[0].document_name =
            docs[0].document_name;
        } else {
          // For new documet
          draft.ticket.additional_options.document = docs;
        }
      });
    });
  };

  const removeImage = (image) => {
    setTicketForm((draft) => {
      if (draft.ticket.imagefiles.length > 1) {
        const index = draft.ticket.imagefiles.findIndex(
          (e) => e.image_url === image.image_url
        );
        if (!image.isnew) {
          draft.ticket.removedfiles.push(image.image_id);
        }
        draft.ticket.imagefiles.splice(index, 1);
      } else {
        draft.ticket.error.imagefiles = "Ticket should have aleast one image";
      }
    });
  };
  return (
    <React.Fragment>
      <ReactBootstrap.Container className="my-4">
        {((operation === "Add" && create_ticket_loader) ||
        (operation === "Edit" && update_ticket_loader)) ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <ReactBootstrap.Spinner animation="border" role="status" />
          </div>
        ) : (
          <TicketForm
            value={ticketForm.ticket}
            options={ticketForm.options}
            onChangeValue={handleInputChange}
            addTeamArtist={addTeamArtist}
            removeTeamArtist={removeTeamArtist}
            onSubmit={handleSubmit}
            handleImageChange={handleImageChange}
            removeImage={removeImage}
            handleDocsChange={handleDocsChange}
            onCancel={onCancel}
            onEditOption={onOptionEditHandle}
            onEditInputCancel={onEditInputCancel}
            onEventOptionEdit={onEventOptionEdit}
            operation={operation}
          />
        )}
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    create_ticket_loader:
      "myticket_create" in state.ui.loaders
        ? state.ui.loaders["myticket_create"]
        : true,
    update_ticket_loader:
      "myticket_update" in state.ui.loaders
        ? state.ui.loaders["myticket_update"]
        : true,
    create_ticket_status: state.mytickets.createticket_status,
    update_ticket_status: state.mytickets.update_ticket.update_status,
    logged_user_id: state.user ? state.user.id : null,
  };
};

export default connect(mapStateToProps, {
  createMyTicket,
  showSpinner,
  hideSpinner,
  updateMyTicketSetFormData,
  updateMyTicketSubmit,
})(TicketAdd);
