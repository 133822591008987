import React from "react";
import { connect } from "react-redux";
import { Row, Col, Dropdown } from "react-bootstrap";
import { showModal } from "../../../../store/actions/ui";
import { Link } from "react-router-dom";
// import StarRatings from "../../../../common/StarRatings/StarRatings";
import ReactStars from "../../../../common/StarRatings/react-stars";
import { ORDER_DELEVERED_STATES } from "../../../../js/constants";

function ReviewDetails({
  showModal,
  buyer_name,
  seller_name,
  delivery_expected_date,
  seller_ratings,
  buyer_ratings,
  buyer_review,
  seller_review,
  is_admin,
  onEditRating,
  onAddRating,
  delivery_status,
}) {
  return (
    <React.Fragment>
      <Row className="d-flex flex-wrap">
        <Col xs={12} md={6}>
          <div>
            <strong>Buyer </strong>(You): {buyer_name}
          </div>
          {delivery_status === ORDER_DELEVERED_STATES.DELIVERED_ADMIN ||
            (delivery_status === ORDER_DELEVERED_STATES.DELIVERED_BUYER && (
              <>
                <Dropdown.Divider />
                {seller_ratings.communication_rating !== "" ? (
                  <div className=" p-2">
                    {seller_ratings.communication_rating && (
                      <div className=" d-flex ">
                        Communication rating :
                        <ReactStars
                          value={seller_ratings.communication_rating}
                        />
                      </div>
                    )}
                    {seller_ratings.description_rating && (
                      <div className=" d-flex ">
                        Description rating :
                        <ReactStars value={seller_ratings.description_rating} />
                      </div>
                    )}
                    {seller_ratings.shipping_rating && (
                      <div className=" d-flex ">
                        {" "}
                        Shipping rating :
                        <ReactStars value={seller_ratings.shipping_rating} />
                      </div>
                    )}
                    {seller_review && <>Review :{seller_review}</>}
                  </div>
                ) : (
                  <div className=" p-2">No ratings found</div>
                )}
              </>
            ))}
        </Col>
        <Col xs={12} md={6}>
          <div>
            <strong>Seller:</strong> {is_admin ? "Memory Lane" : seller_name}
          </div>
          {delivery_status === ORDER_DELEVERED_STATES.DELIVERED_ADMIN ||
            (delivery_status === ORDER_DELEVERED_STATES.DELIVERED_BUYER && (
              <>
                <Dropdown.Divider />
                {buyer_ratings.communication_rating !== "" ? (
                  <div className=" ">
                    {buyer_ratings.communication_rating && (
                      <div className=" d-flex ">
                        Communication rating :
                        <ReactStars
                          value={buyer_ratings.communication_rating}
                        />
                      </div>
                    )}
                    {buyer_ratings.description_rating && (
                      <div className=" d-flex ">
                        Description rating :
                        <ReactStars value={buyer_ratings.description_rating} />
                      </div>
                    )}
                    {buyer_ratings.shipping_rating && (
                      <div className=" d-flex ">
                        {" "}
                        Shipping rating :
                        <ReactStars value={buyer_ratings.shipping_rating} />
                      </div>
                    )}
                    {buyer_review && <>Review :{buyer_review}</>}
                    <Link className="p-2" onClick={onEditRating} to="/review">
                      Edit
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="">No ratings found</div>
                    <Link  onClick={onAddRating} to="/review">
                      Rate & Write a Review
                    </Link>
                  </>
                )}
              </>
            ))}
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { showModal })(ReviewDetails);
