import React, { useEffect } from "react";
import { Row, Col, Spinner, Dropdown, Button } from "react-bootstrap";
import ReportingView from "./ReportingView";
import ReportForm from "./ReportForm/ReportForm";
import { connect } from "react-redux";
import PlusIcon from "../../images/plus-icon.svg";
import { getReportList } from "../../store/actions/reportAdmin";
import { StaticRouter } from "react-router";
import { useImmer } from "use-immer";
import {
  addAdminReport,
  setReportAdminStatus,
  getreportTypes,
} from "../../store/actions/reportAdmin";
import {
  OPERATION_STATUS,
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../js/constants";

const ReportingPage = ({
  addAdminReport,
  getreportTypes,
  reportTypes_options,
  reporting_rooms,
  isCreateLoading,
  getReportList,
  add_report_status,
  setReportAdminStatus,
  ReportLoading,
}) => {
  const [showReport, setShowReport] = useImmer(true);
  const [showAddForm, setShowAddForm] = useImmer(false);
  useEffect(() => {
    getReportList();
    getreportTypes();
  }, []);
  useEffect(() => {
    if (add_report_status === OPERATION_STATUS.SUCCESS) {
      setShowReport(true);
      setShowAddForm(false);
      setReportAdminStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [add_report_status]);
  const handleShowAddForm = () => {
    setShowReport(false);
    setShowAddForm(true);
  };
  const onFormCancel = () => {
    setShowReport(true);
    setShowAddForm(false);
    setReportDetails(initial_state);
    getreportTypes();
  };
  var initial_state = {
    reportType: "",
    title: "",
    description: "",
    error: {
      reportType: "",
      title: "",
      description: "",
    },

    typeOptions: [],
  };
  // Set State value using immer
  const [reportState, setReportDetails] = useImmer(initial_state);
  useEffect(() => {
    setReportDetails((draft) => {
      draft.typeOptions = reportTypes_options;
    });
  }, [reportTypes_options]);
  const handleInputChange = (name, index, e) => {
    switch (name) {
      case "reportType":
        setReportDetails((draft) => {
          draft.reportType = e.id;
        });
        break;
      case "title":
        setReportDetails((draft) => {
          draft.title = e.target.value;
        });
        break;
      case "description":
        setReportDetails((draft) => {
          draft.description = e.target.value;
        });
        break;
      default:
    }
  };

  const reportSubmit = (e) => {
    e.preventDefault();
    if (reportState.title.length === 0) {
      setReportDetails((draft) => {
        draft.error.title = "Please enter the title.";
      });
      return false;
    } else {
      setReportDetails((draft) => {
        draft.error.title = "";
      });
    }
    if (reportState.reportType.length === 0) {
      setReportDetails((draft) => {
        draft.error.reportType = "Please select Type.";
      });
      return false;
    } else {
      setReportDetails((draft) => {
        draft.error.reportType = "";
      });
    }
    if (reportState.title.length > 256) {
      setReportDetails((draft) => {
        draft.error.title = "Please enter upto 256 characters for title.";
      });
      return false;
    } else {
      setReportDetails((draft) => {
        draft.error.title = "";
      });
    }
    if (reportState.description.length === 0) {
      setReportDetails((draft) => {
        draft.error.description = "Please enter the description.";
      });
      return false;
    } else {
      setReportDetails((draft) => {
        draft.error.description = "";
      });
    }
    if (reportState.description.length > 256) {
      setReportDetails((draft) => {
        draft.error.description = "Please enter upto 256 characters for description.";
      });
      return false;
    } else {
      setReportDetails((draft) => {
        draft.error.description = "";
      });
    }
    const Report = {
      reportType: reportState.reportType,
      title: reportState.title,
      description: reportState.description,
    };
    addAdminReport(Report);
  };

  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={6}>
            <h5>My Reports</h5>
          </Col>
          <Col xs={12} md={6} className="text-right">
            <Button className="btnAddTicket" onClick={handleShowAddForm}>
              <span>
                <img src={PlusIcon} alt="PlusIcon" />
              </span>
              New Report
            </Button>
          </Col>
        </Row>
        <Dropdown.Divider />
      </div>
      {showReport && (
        <ReportingView
          reporting_rooms={reporting_rooms}
          isLoading={ReportLoading}
        />
      )}

      {showAddForm && (
        <ReportForm
          onSubmit={reportSubmit}
          onChangeValue={handleInputChange}
          onFormCancel={onFormCancel}
          options={reportState.typeOptions}
          error={reportState.error}
          isLoading={isCreateLoading}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reporting_rooms: state.reportAdmin.report_rooms,
    add_report_status: state.reportAdmin.add_report_status,
    reportTypes_options: state.reportAdmin.report_types,
    ReportLoading:
      "report-rooms" in state.ui.loaders
        ? state.ui.loaders["report-rooms"]
        : true,
    isCreateLoading:
      state.reportAdmin.add_report_status === OPERATION_STATUS.PENDING
        ? true
        : false,
  };
};

export default connect(mapStateToProps, {
  getReportList,
  addAdminReport,
  setReportAdminStatus,
  getreportTypes,
})(ReportingPage);
