import {
    UPDATE_PROFILE_PIC,
    UPDATE_PROFILE_PIC_SUCCESS,
    UPDATE_PROFILE_PIC_ERROR,
    profilePicUploadSuccess,
    profilePicUploadError,

    UPDATE_PERSONAL_INFO ,
    UPDATE_PERSONAL_INFO_SUCCESS,
    UPDATE_PERSONAL_INFO_ERROR,
    updatePersonalInfoSuccess,
    updatePersonalInfoError,


    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR,
    updatePasswordSuccess,
    updatePasswordError,
    setPasswordStatus,

    UserDetailsModifyStatus

  } from "../../actions/profile";
  import {
    getUserData
   } from "../../actions/auth";
  import { showSpinner, hideSpinner } from "../../actions/ui";
  import { apiRequest } from "../../actions/api";
  import { notification_types, OPERATION_STATUS } from "../../../js/constants";
  import { setNotification } from "../../actions/notification";
  
  // this middleware only care about the getBooks action
  export const updatePicFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === UPDATE_PROFILE_PIC) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "POST",
          "/user/account/user.php?action=upload_profile",
          null,
          action.payload,
          profilePicUploadSuccess,
          profilePicUploadError,
          true,
          "[profile]"
        )
      );
      dispatch(showSpinner("profile"));
    }
  
  };
  
  // on successful fetch, process the books data
  export const updatePicFlowCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === UPDATE_PROFILE_PIC_SUCCESS) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.SUCCESS));
        dispatch(
            setNotification(
              "Profile Picture Changed",
              "Profile",
              notification_types.success
            ))
   dispatch(getUserData());
      dispatch(hideSpinner("profile"));
    }
  
    if (action.type === UPDATE_PROFILE_PIC_ERROR) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.FAILURE));
      dispatch(hideSpinner("profile"));
    }
  };

  // this middleware only care about the getBooks action
  export const updateUserInfoFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === UPDATE_PERSONAL_INFO) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "PUT",
          "/user/account/user.php",
          null,
          action.payload,
          updatePersonalInfoSuccess,
          updatePersonalInfoError,
          true,
          "[profile]"
        )
      );
      dispatch(showSpinner("profile"));
    }
  
  };

  // on successful fetch, process the books data
  export const updateUserInfoFlowCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === UPDATE_PERSONAL_INFO_SUCCESS) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.SUCCESS));
        dispatch(
            setNotification(
              "Profile Information Changed",
              "Profile",
              notification_types.success
            ))
   dispatch(getUserData());
      dispatch(hideSpinner("profile"));
    }
  
    if (action.type === UPDATE_PERSONAL_INFO_ERROR) {
      dispatch(UserDetailsModifyStatus(OPERATION_STATUS.FAILURE));
      dispatch(hideSpinner("profile"));
    }
  };


   // this middleware only care about the getBooks action
   export const updatePasswordFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === UPDATE_PASSWORD) {
      dispatch(setPasswordStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "PUT",
          "/user/account/user.php?action=change_password",
          null,
          action.payload,
          updatePasswordSuccess,
          updatePasswordError,
          true,
          "[profile]"
        )
      );
      dispatch(showSpinner("profile"));
    }
  
  };
  // on successful fetch, process the books data
  export const updatePasswordFlowCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === UPDATE_PASSWORD_SUCCESS) {
      dispatch(setPasswordStatus(OPERATION_STATUS.SUCCESS));
        dispatch(
            setNotification(
              "Password Changed Successfully",
              "Profile",
              notification_types.success
            ))
      dispatch(getUserData());
      dispatch(hideSpinner("profile"));
    }
  
    if (action.type === UPDATE_PASSWORD_ERROR) {
      dispatch(setPasswordStatus(OPERATION_STATUS.FAILURE));
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "order exist",
          notification_types.error
        ));
      dispatch(hideSpinner("profile"));
    }
  };
  export const profileMdl = [
      updatePicFlow,
      updatePicFlowCollection,
      updateUserInfoFlow,
      updateUserInfoFlowCollection,
      updatePasswordFlow,
      updatePasswordFlowCollection
    ];
  