import React from "react";
import PropTypes from "prop-types";
import { OFFER_ACTIONS } from "../../../js/constants";
import { Button, Spinner } from "react-bootstrap";

const OfferDetailsFooter = ({
  isAccept,
  isReject,
  isCancel,
  isCounter,
  isCart,
  handleStatus,
  handleCounter,
  handleCart,
  isLoadingStatus,
}) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-wrap justify-content-end">
        {isLoadingStatus ? (
          <div className="m-1">
            <Button disabled={true}>
              <div>
                <Spinner animation="border spinner-border-sm" role="status" />
                Loading ...
              </div>
            </Button>
          </div>
        ) : (
          <>
            {isAccept && (
              <div className="m-1">
                <Button
                  variant="success"
                  onClick={() => handleStatus(OFFER_ACTIONS.ACCEPT)}
                >
                  Accept
                </Button>
              </div>
            )}
            {isReject && (
              <div className="m-1">
                <Button
                  variant="danger"
                  onClick={() => handleStatus(OFFER_ACTIONS.REJECT)}
                >
                  Reject
                </Button>
              </div>
            )}
            {isCancel && (
              <div className="m-1">
                <Button
                  variant="danger"
                  onClick={() => handleStatus(OFFER_ACTIONS.CANCEL)}
                >
                  Cancel
                </Button>
              </div>
            )}
            {isCounter && (
              <div className="m-1">
                <Button variant="primary" onClick={() => handleCounter()}>
                  Counter
                </Button>
              </div>
            )}
            {isCart && (
              <div className="m-1">
                <Button variant="primary" onClick={() => handleCart()}>
                  Add to Cart
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

OfferDetailsFooter.propTypes = {
  isAccept: PropTypes.bool.isRequired,
  isReject: PropTypes.bool.isRequired,
  isCancel: PropTypes.bool.isRequired,
  isCounter: PropTypes.bool.isRequired,
  handleStatus: PropTypes.func.isRequired,
  handleCounter: PropTypes.func.isRequired,
  handleCart: PropTypes.func.isRequired,
  isLoadingStatus: PropTypes.bool.isRequired,
};

export default OfferDetailsFooter;
