import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { NavLink } from "react-router-dom";

const MyChatPageHeader = ({ name, refereshMessages, avatar, loading }) => {
  return (
    <React.Fragment>
      {!loading && (
        <div className="d-flex flex-nowrap my-4 mx-0">
          <div className="p-0">
            <ReactBootstrap.Button
              className="btn btn-primary"
              as={NavLink}
              exact
              to={"/account/mymessages/"}
              title="Go back"
            >
              <i className="fa fa-chevron-left"></i>
            </ReactBootstrap.Button>
          </div>
          {name && (
            <div className="d-flex flex-grow-1 align-items-center px-2 py-0">
              {avatar && (
                <div>
                  <img
                    src={avatar}
                    className="rounded-circle"
                    alt={name}
                    width={40}
                    height={40}
                  />
                </div>
              )}
              <div className=" text-ellipsis-wrap font-weight-bold ml-2">
                {name}
              </div>
            </div>
          )}
          <div className="p-0">
            <ReactBootstrap.Button
              className="btn btn-primary"
              onClick={refereshMessages}
              title="Refresh messages"
            >
              <i className="fa fa-repeat"></i>
            </ReactBootstrap.Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyChatPageHeader;
