import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { setSearchBar, removeSearchBar } from "../../store/actions/searchbar";
import { connect } from "react-redux";
import {Form,InputGroup, Nav} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useHistory, useLocation } from "react-router";
import './HeaderComponentNew.css'

const SearchBarNew = ({ searchvalue, setSearchBar, removeSearchBar }) => {
  //This section for searching
  const location = useLocation();
  const history = useHistory();
  let pathname = location.pathname;
  // Set State value using immer
  const [search, setSearch] = useImmer(searchvalue);

  useEffect(() => {
    setSearch(searchvalue);
  }, [searchvalue]);

  useEffect(() => {
    if (!pathname.includes("/tickets") && search) {
      removeSearchBar();
    }
  }, [pathname]);

  const SearchResult = async () => {
    setSearchBar(search);
    const params = new URLSearchParams(location.search);
    if (search) {
      if (params.get("q")) {
        params.delete("q");
        params.append("q", search);
      } else {
        params.append("q", search);
      }
    } else {
      if (params.get("q")) {
        params.delete("q");
      }
    }
    history.push({
      pathname: "/tickets",
      search: params.toString(),
    });
  };
  // const SearchResultEnter = (event) => {
  //   if (event.key === "Enter") {
  //     SearchResult();
  //   }
  // };
  return (
    <Form className="input-search my-3"  onSubmit={(e) => {
			e.preventDefault(); 
			SearchResult()}}>
          <Form.Control
            type="search"
            placeholder="Search tickets by artist, team, venue, event, moment"
            name="search"
            aria-label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
         <span className="search-icon">
        <Icon.Search color="#207490" size={18} onClick={SearchResult} />
      </span>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    searchvalue: state.searchBar.search,
  };
};

export default connect(mapStateToProps, { setSearchBar, removeSearchBar })(
  SearchBarNew
);
