export const GET_TICKETS                      = '[tickets] GET';
export const SEARCH_TICKETS                   = '[tickets] SEARCH';
export const FETCH_TICKETS_SUCCESS            = '[tickets] Fetch success';
export const FETCH_TICKETS_ERROR              = '[tickets] Fetch Error';
export const GET_NEXT_TICKETS                 = '[tickets] GET Next';
export const SEARCH_NEXT_TICKETS              = '[tickets] SEARCH Next';
export const FETCH_NEXT_TICKETS_SUCCESS       = '[tickets] Fetch next success';
export const FETCH_NEXT_TICKETS_ERROR         = '[tickets] Fetch next Error';
export const UPDATE_TICKETS                   = '[tickets] Update';
export const SELECT_TICKET                    = '[tickets] Select';
export const FETCH_SINGLE_TICKET_SUCCESS      = '[tickets] Fetch single success';
export const FETCH_SINGLE_TICKET_ERROR        = '[tickets] Fetch single Error';
export const UPDATE_TICKET                    = '[tickets] Update single';

// For fetching tickets
export const getTickets = (filter = {}) => ({
    type: GET_TICKETS,
    payload: filter
  });

// For searching tickets
export const searchTickets = (query, filter = {}) => ({
  type: SEARCH_TICKETS,
  payload: {q: query, ...filter}
});

// For fetching moments
export const fetchTicketsSuccess = (data) => ({
  type: FETCH_TICKETS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchTicketsError = (data) => ({
  type: FETCH_TICKETS_ERROR,
  payload: data
});



// For fetching tickets for next iteration
export const getNextTickets = (filter) => ({
    type: GET_NEXT_TICKETS,
    payload: filter
  });

// For fetching search tickets for next iteration
export const searchNextTickets = (query,filter) => ({
  type: SEARCH_NEXT_TICKETS,
  payload: {q: query, ...filter}
});

export const fetchNextTicketsSuccess = (data) => ({
    type: FETCH_NEXT_TICKETS_SUCCESS,
    payload: data,
    meta: {
      to_append: true
    }
  });

export const fetchNextTicketsError = (data) => ({
    type: FETCH_NEXT_TICKETS_ERROR,
    payload: data
  });
  

export const setTickets = (data) => ({
    type   : UPDATE_TICKETS,
    payload: data
  });


// For fetching a single ticket
export const selectTicket = (ticket_id) => ({
  type: SELECT_TICKET,
  payload: {product_id: ticket_id}
});


export const fetchSingleTicketSuccess = (data) => ({
  type: FETCH_SINGLE_TICKET_SUCCESS,
  payload: data
});

export const fetchSingleTicketError = (data) => ({
  type: FETCH_SINGLE_TICKET_ERROR,
  payload: data
});

export const setSingleTicket = (data) => ({
  type   : UPDATE_TICKET,
  payload: data
});