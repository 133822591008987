import {
  ADD_BUYER_RATING,
  ADD_BUYER_RATING_SUCCESS,
  ADD_BUYER_RATING_ERROR ,
  addBuyerRatingSuccess,
  addBuyerRatingError,
  setBuyerRating,
  setBuyerRatingStatus,

  ADD_SELLER_RATING,
  ADD_SELLER_RATING_SUCCESS,
  ADD_SELLER_RATING_ERROR ,
  addSellerRatingSuccess,
  addSellerRatingError,
  setSellerRating,
  setSellerRatingStatus,

  ADD_BUYER_REVIEW,
  ADD_BUYER_REVIEW_SUCCESS,
  ADD_BUYER_REVIEW_ERROR ,
  addBuyerReviewSuccess,
  addBuyerReviewError,
  setBuyerReview,
  setBuyerReviewStatus,

  ADD_SELLER_REVIEW,
  ADD_SELLER_REVIEW_SUCCESS,
  ADD_SELLER_REVIEW_ERROR ,
  addSellerReviewSuccess,
  addSellerReviewError,
  setSellerReview,
  setSellerReviewStatus,


  GET_REVIEW_LIST ,
  GET_REVIEW_LIST_SUCCESS,
  GET_REVIEW_LIST_ERROR,
  getReviewListSuccess,
  getReviewListError,
  setReviewList,

  GET_NEXT_REVIEW_LIST,
  GET_NEXT_REVIEW_LIST_SUCCESS,
  GET_NEXT_REVIEW_LIST_ERROR,
  getNextReviewListSuccess,
  getNextReviewListError,

  GET_SELLER_RATINGS_LIST,
  GET_SELLER_RATINGS_LIST_SUCCESS, 
  GET_SELLER_RATINGS_LIST_ERROR,
  getSellerRatingsListSuccess,
  getSellerRatingsListError,
  setSellerRatingsList,

  GET_SELLER_NEXT_RATINGS_LIST,
  GET_SELLER_NEXT_RATINGS_LIST_SUCCESS,
  GET_SELLER_NEXT_RATINGS_LIST_ERROR,
  getNextSellerRatingsListSuccess,
  getNextSellerRatingsListError,

  resetRating,
  resetReview,

  GET_SELLER_REPORT_TYPES,
  GET_SELLER_REPORT_TYPES_SUCCESS,
  GET_SELLER_REPORT_TYPES_ERROR,
  getReportTypesSuccess,
  getReportTypesListError,
  setReportTypesList
} from "../../actions/rating";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS } from "../../../js/constants"
import { setNotification } from "../../actions/notification";
import { hideModal } from "../../actions/ui";
import { formattedMessageDisplayDateTime } from "../../../js/utils";
import Avatar from "../../../images/avatar.png";

const processRating = (data) => {
  return data.map((item) => {
    return {
        type: item.type === "BUYER" ? "SELL" : "BUY",
        content: item.review.content,
        rated_at: formattedMessageDisplayDateTime(item.review.reviewed_at),
        user_details: {
          avatar: item.user_details.avatar ? item.user_details.avatar : Avatar,
          id: item.user_details.id,
          is_admin: item.user_details.is_admin,
          name: item.user_details.is_admin ? "Memory Lane" : item.user_details.name
        },
        ratings:
        {
          communication_rating: item.ratings.communication_rating,
          shipping_rating:  item.ratings.shipping_rating,
          description_rating: item.ratings.description_rating
        } 
    };
  });
}


export const addBuyerRatingsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === ADD_BUYER_RATING) {
    dispatch(setBuyerRatingStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "user/ratings.php",
        null,
        action.payload,
        addBuyerRatingSuccess,
        addBuyerRatingError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("rating"));
  }

};
  
export const processAddBuyerRatings = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_BUYER_RATING_SUCCESS) {
    const result=action.payload.data
    const data ={
      suborder_no:result.sub_order_id,
      order_no:result.order_no,
      buyer_ratings:{
        communication_rating:result.communication_rating,
        description_rating:result.description_rating,
        shipping_rating:result.shipping_rating
      }
    }
    dispatch(setBuyerRating(data));
    dispatch(resetRating(true));
    dispatch(setBuyerRatingStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("rating"));
    dispatch(
      setNotification(
        "Rating Added",
        "rating-add_item",
        notification_types.success
      )
    );
    dispatch(hideModal("BuyerRatings"));
  }

  if (action.type === ADD_BUYER_RATING_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "rating-add_item",
        notification_types.error
      )
    );
    dispatch(setBuyerRatingStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("rating"));
  }
};
   
export const addSellerRatingsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === ADD_SELLER_RATING) {
    dispatch(setSellerRatingStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "user/ratings.php",
        null,
        action.payload,
        addSellerRatingSuccess,
        addSellerRatingError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("rating"));
  }
};
    

export const processAddSellerRatings = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_SELLER_RATING_SUCCESS) {
    const result =action.payload.data
    const data ={
      suborder_no:result.sub_order_id,
      order_no:result.order_no,
      seller_ratings:{
        communication_rating:result.communication_rating,
        description_rating:result.description_rating,
        shipping_rating:result.shipping_rating
      }
    }
    dispatch(setSellerRating(data));
    dispatch(resetRating(true));
    dispatch(setSellerRatingStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("rating"));
    dispatch(
      setNotification(
        "Rating Added",
        "rating-add_item",
        notification_types.success
      )
    );
    dispatch(hideModal("SellerRatings"));
  }

  if (action.type === ADD_SELLER_RATING_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "rating-add_item",
        notification_types.error
      )
    );
    dispatch(setSellerRatingStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("rating"));
  }
};
// ********************************************************************************************************

export const addBuyerReviewFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === ADD_BUYER_REVIEW) {
    dispatch(setBuyerReviewStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "user/reviews.php",
        null,
        action.payload,
        addBuyerReviewSuccess,
        addBuyerReviewError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("rating"));
  }

};
export const processAddBuyerReview = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_BUYER_REVIEW_SUCCESS) {
    const result=action.payload.data
    const reviewdata ={
      suborder_no:result.sub_order_id,
      order_no:result.order_no,
      description: result.description,
    }
    dispatch(setBuyerReview(reviewdata));
    dispatch(resetReview(true));
    dispatch(setBuyerReviewStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("rating"));
    dispatch(
      setNotification(
        "Review Added",
        "rating-add_item",
        notification_types.success
      )
    );
    dispatch(hideModal("BuyerRatings"));
  }

  if (action.type === ADD_BUYER_REVIEW_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "rating-add_item",
        notification_types.error
      )
    );
    dispatch(setBuyerReviewStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("rating"));
  }
};


export const addSellerReviewFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === ADD_SELLER_REVIEW) {
    dispatch(setSellerReviewStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "user/reviews.php",
        null,
        action.payload,
        addSellerReviewSuccess,
        addSellerReviewError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("rating"));
  }
};
    
export const processAddSellerReviewCollection = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_SELLER_REVIEW_SUCCESS) {
    const result=action.payload.data
    const reviewdata ={
      suborder_no:result.sub_order_id,
      order_no:result.order_no,
      description: result.description,
    }
    dispatch(setSellerReview(reviewdata));
    dispatch(resetReview(true));
    dispatch(setSellerReviewStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("rating"));
    dispatch(
      setNotification(
        "Review Added",
        "rating-add_item",
        notification_types.success
      )
    );
    dispatch(hideModal("SellerRatings"));
  }

  if (action.type === ADD_SELLER_REVIEW_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "rating-add_item",
        notification_types.error
      )
    );
    dispatch(setSellerReviewStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("rating"));
  }
};

export const getReviewListFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_REVIEW_LIST) {
    dispatch(
      apiRequest(
        "GET",
        "user/reviews.php",
        action.payload,
        null,
        getReviewListSuccess,
        getReviewListError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("reviews_init_list"));
  }
  if (action.type === GET_NEXT_REVIEW_LIST) {
    dispatch(
      apiRequest(
        "GET",
        "user/reviews.php",
        action.payload,
        null,
        getNextReviewListSuccess,
        getNextReviewListError,
        true,
        "[rating]"
      )
    );
    dispatch(showSpinner("reviews_next_list"));
  }

};
   
export const processgetReviewCollection = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_REVIEW_LIST_SUCCESS) {
    const items = action.payload.data.items
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    let reviewsList = processRating(items);
    const review_details = {
      reviews: reviewsList,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setReviewList(review_details));
    dispatch(hideSpinner("reviews_init_list"));
  }

  if (action.type === GET_REVIEW_LIST_ERROR) {  
    dispatch(hideSpinner("reviews_init_list"));
  }
};
export const processgetReviewNextCollection = ({ dispatch,getState }) => (next) => (action) => {
  next(action);

  if (action.type === GET_NEXT_REVIEW_LIST_SUCCESS) {
    const items=action.payload.data.items
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    let next_reviews = processRating(items);
   

    const prevreviews = getState().rating.reviews_list.reviews;
    const new_reviews = [...prevreviews, ...next_reviews];
    const review_details = {
      reviews: new_reviews,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setReviewList(review_details));
    dispatch(hideSpinner("reviews_next_list"));
  }

  if (action.type === GET_NEXT_REVIEW_LIST_ERROR) {
    
    dispatch(hideSpinner("reviews_next_list"));
  } 
};    
export const getSellerRatingsListFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SELLER_RATINGS_LIST) {
    dispatch(showSpinner("seller_ratings_init_list"));
    dispatch(
      apiRequest(
        "GET",
        "user/ratings.php",
        action.payload,
        null,
        getSellerRatingsListSuccess,
        getSellerRatingsListError,
        true,
        "[rating]"
      )
    );
  }
  if (action.type === GET_SELLER_NEXT_RATINGS_LIST) {
    dispatch(showSpinner("seller_ratings_next_list"));
    dispatch(
      apiRequest(
        "GET",
        "user/ratings.php",
        action.payload,
        null, 
        getNextSellerRatingsListSuccess,
        getNextSellerRatingsListError,
        true,
        "[rating]"
      )
    );
  }
};
export const processSellerRatingsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SELLER_RATINGS_LIST_SUCCESS) {
    const items=action.payload.data.items
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    let sellerRatingsList = processRating(items);
   
    const ratings_details = {
      ratings: sellerRatingsList,
      hasMore: hasMore,
      page: page,
    };
      
    dispatch(setSellerRatingsList(ratings_details));
    dispatch(hideSpinner("seller_ratings_init_list"));
  }

  if (action.type === GET_SELLER_RATINGS_LIST_ERROR) {
    dispatch(setSellerReviewStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("seller_ratings_init_list"));
  } 
};

export const processSellerRatingsNextCollection = ({ dispatch,getState }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SELLER_NEXT_RATINGS_LIST_SUCCESS) {
    const items=action.payload.data.items
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    let next_ratings = processRating(items);
   
    const prev_ratings = getState().rating.seller_ratings.ratings;
    const new_reviews = [...prev_ratings, ...next_ratings];
    const ratings_details = {
      ratings: new_reviews,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setSellerRatingsList(ratings_details));
    dispatch(hideSpinner("seller_ratings_init_list"));
  }

  if (action.type === GET_SELLER_NEXT_RATINGS_LIST_ERROR) {
    dispatch(setSellerReviewStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("seller_ratings_init_list"));
  } 
};

export const getSellerReportsTypeFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SELLER_REPORT_TYPES) {
    dispatch(showSpinner("fetch_seller_report_types"));
    dispatch(
      apiRequest(
        "GET",
        "user/reports.php",
        null,
        null,
        getReportTypesSuccess,
        getReportTypesListError,
        true,
        "[rating]"
      )
    );
  }
};
// on successful fetch, process the report types data
export const processSellerReportTypesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SELLER_REPORT_TYPES_SUCCESS) {
    let data = action.payload.data.items;
    
    dispatch(hideSpinner("fetch_seller_report_types"));
    dispatch(setReportTypesList(data));
  }

  if (action.type === GET_SELLER_REPORT_TYPES_ERROR) {
    dispatch(hideSpinner("fetch_seller_report_types"));
  } 
};

export const ratingMdl = [
  addBuyerRatingsFlow,
  processAddBuyerRatings,
  addSellerRatingsFlow,
  processAddSellerRatings,
  addBuyerReviewFlow,
  processAddBuyerReview,
  addSellerReviewFlow,
  processAddSellerReviewCollection,
  getReviewListFlow,
  processgetReviewCollection,
  processgetReviewNextCollection,
  getSellerRatingsListFlow,
  processSellerRatingsCollection,
  processSellerRatingsNextCollection,
  processSellerReportTypesCollection,
  getSellerReportsTypeFlow
];
   