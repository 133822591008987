import {
  UPDATE_MY_BUY_ORDERS,
  UPDATE_MY_SELL_ORDERS,
  SET_ORDER_STATUS,
  SET_TRACKING_INFO_DETAILS,
  SET_INITIATE_ORDER_DATA,
  APPROVE_ORDER_RECEIVED_SET_STATUS,
  APPROVE_ORDER_DELIVERED_SET_STATUS,
  SET_TRACKING_INFO_STATUS,
  ALERTMODEL_STATUS
} from "../actions/myOrder";

import {
  SET_BUYER_RATING,
  SET_SELLER_RATING,
  SET_BUYER_REVIEW,
  SET_SELLER_REVIEW
} from "../actions/rating";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  initiate_order: {
    status: OPERATION_STATUS.YET_TO_START,
    data: null,
    alertboxstatus: OPERATION_STATUS.YET_TO_START
  },
  buy: {
    hasMore: false,
    nextPage: null,
    orders: [],
    approve_order_received: {
      status: OPERATION_STATUS.YET_TO_START
    }
  },
  sell: {
    hasMore: false,
    nextPage: null,
    orders: [],
    approve_order_delivered: {
      status: OPERATION_STATUS.YET_TO_START
    },
    tracking_info: {
      status: OPERATION_STATUS.YET_TO_START
    }
  },
};

export const myOrderReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_MY_BUY_ORDERS:
      draft.buy.hasMore = action.payload.hasMore;
      draft.buy.nextPage = action.payload.page;
      draft.buy.orders = action.payload.orders;
      break;
    case UPDATE_MY_SELL_ORDERS:
      draft.sell.hasMore = action.payload.hasMore;
      draft.sell.nextPage = action.payload.page;
      draft.sell.orders = action.payload.orders;
      break;
    case SET_TRACKING_INFO_DETAILS:
      let order_index = draft.sell.orders.findIndex(
        (order) => order.order_no === action.payload.order_no
      );
      if (order_index > -1) {
        draft.sell.orders[order_index].fulfillment_information =
          action.payload.fulfillment_information;
        draft.sell.orders[order_index].fulfillment_status =
          action.payload.fulfillment_information.delivery_status;
      }
      break;
    case SET_ORDER_STATUS:
      draft.initiate_order.status = action.payload;
      break;
    case SET_INITIATE_ORDER_DATA:
      draft.initiate_order.data = action.payload;
      break;
    case SET_BUYER_RATING:
      let buyrating_index = draft.buy.orders.findIndex(
        (order) => parseInt(order.suborder_no) === parseInt(action.payload.suborder_no)
      );
      if (buyrating_index > -1) {
        draft.buy.orders[buyrating_index].ratings.buyer_ratings =
          action.payload.buyer_ratings;
      }
    break;
    case SET_SELLER_RATING:
      let sellrating_index = draft.sell.orders.findIndex(
        (order) => order.order_no === action.payload.order_no
      );
      if (sellrating_index > -1) {
        draft.sell.orders[sellrating_index].ratings.seller_ratings =
          action.payload.seller_ratings;
      }
      break;


      case SET_BUYER_REVIEW:
        let buyreview_index = draft.buy.orders.findIndex(
          (order) => parseInt(order.suborder_no) === parseInt(action.payload.suborder_no)
        );
        if (buyreview_index > -1) {
          draft.buy.orders[buyreview_index].review.buyer_review =
            action.payload.description;
        }
      break;
      case SET_SELLER_REVIEW:
        let sellreview_index = draft.sell.orders.findIndex(
          (order) => order.order_no === action.payload.order_no
        );
        if (sellreview_index > -1) {
          draft.sell.orders[sellreview_index].review.seller_review =
            action.payload.description;
        }
        break;



    case APPROVE_ORDER_DELIVERED_SET_STATUS:
      draft.sell.approve_order_delivered.status = action.payload;
      break;
    case APPROVE_ORDER_RECEIVED_SET_STATUS:
      draft.buy.approve_order_received.status = action.payload;
      break;
    case SET_TRACKING_INFO_STATUS:
      draft.sell.tracking_info.status = action.payload;
      break;
    case ALERTMODEL_STATUS:
      draft.alertboxstatus = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);
