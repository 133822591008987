import React from 'react';
import Loader from "react-loader-spinner";

import '../LoaderComponent/LoaderComponent.css';

// import * as ReactBootstrap from "react-bootstrap"

function LoaderComponent () {
    return (
        <React.Fragment>
            {/*---------------------- React Custom Loader ----------------------*/}
            <section className="loaderSection">
                <Loader
                    type="Bars"
                    color="#003d79"
                    height={60}
                    width={60}
                    //timeout={8000} //8 secs
                />
            </section>
            {/*---------------------- React Bootstrap Spinner ----------------------*/}
            {/* <section className="spinnerSection">
                <div>
                    <ReactBootstrap.Spinner animation="border" />
                </div>
            </section> */}
        </React.Fragment>
    )
}

export default LoaderComponent;