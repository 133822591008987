import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getMyBuyOffers,
  getNextMyBuyOffers,
} from "../../../store/actions/myOffer";
import MyBuyOffersView from "./MyBuyOffersView";
import { useImmer } from "use-immer";
import OfferDetailsPage from "../../OfferDetailsPage/OfferDetailsPage";
import { Button, Spinner } from "react-bootstrap";
import { api as UserAPI } from "../../../apis/userProtectedApis.instance";

const MyBuyOffersPage = ({
  offers,
  hasMore,
  page,
  getMyBuyOffers,
  getNextMyBuyOffers,
  offerLoading,
}) => {
  const [detailOfferLoading, setDetailOfferLoading] = useImmer(false);
  const [showMyBuyOffers, setShowMyBuyOffers] = useImmer(true);
  const [showMyBuyOffersDetail, setShowMyBuyOffersDetail] = useImmer(false);
  const [offersDetail, setOffersDetail] = useImmer({
    offer_details: null,
    seller_items: [],
  });

  const nextPage = () => {
    let filters = {
      page: page,
    };
    getNextMyBuyOffers(filters);
  };

  const handleShowMyBuyOffers = () => {
    setOffersDetail((draft) => {
      draft.offer_details = null;
    });
    setShowMyBuyOffersDetail(false);
    setShowMyBuyOffers(true);
  };

  const getSellerItems = async (seller_id) => {
    try {
      let queryparams = {
        full_result: 1,
        seller: seller_id,
      };
      let response = await UserAPI.Offers.getSellerTickets(queryparams);
      let ticketsRecords = response.data.data.items.map((ticket) => {
        return {
          product_id: ticket.product_id,
          name: ticket.listing_name,
          cost: ticket.sell_details.cost,
          image: ticket.images[0],
        };
      });
      return ticketsRecords;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const viewOfferDetails = async (record) => {
    setShowMyBuyOffers(false);
    setDetailOfferLoading(true);
    let seller_items = await getSellerItems(
      record.offer_details.seller_details.seller_id
    );
    setOffersDetail((draft) => {
      draft.offer_details = record.offer_details;
      draft.seller_items = seller_items;
    });
    setDetailOfferLoading(false);
    setShowMyBuyOffersDetail(true);
  };

  useEffect(() => {
    getMyBuyOffers();
  }, []);
  return (
    <React.Fragment>
      {(detailOfferLoading || offerLoading) && (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      )}
      {showMyBuyOffers && !offerLoading && (
        <MyBuyOffersView
          offers={offers}
          nextPage={nextPage}
          hasMore={hasMore}
          viewOfferDetails={viewOfferDetails}
        />
      )}
      {showMyBuyOffersDetail && !detailOfferLoading && (
        <div className="d-flex flex-column my-3">
          <div className="ml-auto my-2">
            <Button title="goBack" onClick={() => handleShowMyBuyOffers()}>
              <i className="fa fa-chevron-left"></i>
            </Button>
          </div>
          <OfferDetailsPage
            offer_detail={offersDetail.offer_details}
            seller_items={offersDetail.seller_items}
            showOfferDetailBody={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    offers: state.myOffer.buy.offers,
    hasMore: state.myOffer.buy.hasMore,
    page: state.myOffer.buy.nextPage,
    offerLoading:
      "mybuyoffers_init" in state.ui.loaders
        ? state.ui.loaders["mybuyoffers_init"]
        : true,
  };
};

export default connect(mapStateToProps, { getMyBuyOffers, getNextMyBuyOffers })(
  MyBuyOffersPage
);
