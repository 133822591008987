import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";
import "./TitleComponent.css";

const TitleComponent = ({ listing_name, type, cost, seller,isSale }) => {
  return (
    <React.Fragment>
      <div className="searchResultTitleDiv">
        <span>{listing_name}</span>
        {type && (
          <>
            <span>|</span>
            <span>
              <i className="fa fa-ticket mr-2" aria-hidden="true"></i>
              {type}
            </span>
          </>
        )}
      </div>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={6}>
          <span className="spanCost">
          { isSale !== 0 ?(
                  <>
                  <i className="fa fa-usd" aria-hidden="true"></i> {cost}
                  </>  
                ):(
                  <p>Not for sale</p>
                )
                  }
          </span>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={6} className="text-right">
          <span className="spanSeller">
            <i className="fa fa-handshake-o" aria-hidden="true"></i>
            {seller}
          </span>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Dropdown.Divider />
    </React.Fragment>
  );
};

TitleComponent.propTypes = {
  listing_name: PropTypes.string.isRequired,
  type: PropTypes.string,
  cost: PropTypes.string.isRequired,
  seller: PropTypes.string.isRequired,
};

export default TitleComponent;
