import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Select, { components } from "react-select";
// import { useImmer } from "use-immer";
// import { useHistory } from "react-router-dom";

const TicketForm = ({
  value,
  onSubmit,
  onCancel,
  operation,
  onChangeValue
}) => {
  // const history = useHistory();
  return (
    <ReactBootstrap.Row className="parentAddTicket">
      <ReactBootstrap.Col>
        <ReactBootstrap.Card>
          <ReactBootstrap.Card.Header as="h5">
            {operation} Collection
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
              <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} md={12}>
              <ReactBootstrap.Form.Group className="mb-3">
                  <ReactBootstrap.Form.Label className="requiredField">
                    Collection name
                  </ReactBootstrap.Form.Label>
                  <ReactBootstrap.Form.Control
                    type="text"
                    placeholder="Enter the collection name."
                    name="collection_name"
                    value={value.collection_name}
                    onChange={(e) => {
                      onChangeValue("collection_name",e);
                    }}
                    className={
                      value.error.collection_name.length > 0 ? "is-invalid" : ""
                    }
                    autoComplete="off"
                  />
                  {value.error.collection_name.length > 0 && (
                    <span className="invalid-feedback">
                      {value.error.collection_name}
                    </span>
                  )}
                </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Row>
      <ReactBootstrap.Col xs={12} md={6}>
          <ReactBootstrap.Form.Label className="requiredField">
            Sale/Show
          </ReactBootstrap.Form.Label>
          <Select
            className={
              value.error.isSale.length > 0 ? "is-invalid" : ""
            }
            classNamePrefix="select"
            isClearable={false}
            value={value.isSale}
            placeholder="Select a ticket type"
            name="isSale"
            onChange={(e) => {
              onChangeValue("isSale",e);
            }}
           options={value.option.isSale}
          />
          {value.error.isSale.length > 0 && (
            <span className="invalid-feedback">
              {value.error.isSale}
            </span>
          )}
        </ReactBootstrap.Col>
         <ReactBootstrap.Col xs={12} md={6}>
         <ReactBootstrap.Form.Group className="mb-3">
           <ReactBootstrap.Form.Label>
             Price
           </ReactBootstrap.Form.Label>
           <ReactBootstrap.Form.Control
             type="tel" 
             pattern="[0.00-9.00]*"
             min={1}
             placeholder="Enter the price"
             name="cost"
             value={value.cost}
            className={value.error.cost.length > 0 ? "is-invalid" : ""}
            onChange={(e) => {
            onChangeValue("cost",e);
            }}
           />
           {value.error.cost.length > 0 && (
             <span className="invalid-feedback">{value.error.cost}</span>
           )}
         </ReactBootstrap.Form.Group>
       </ReactBootstrap.Col>
      </ReactBootstrap.Row>
              <ReactBootstrap.Row className="align_props my-2">
                <ReactBootstrap.Button
                  className="space_button"
                  variant="outline-primary"
                  onClick={onCancel}
                >
                  Cancel
                </ReactBootstrap.Button>
                <ReactBootstrap.Button
                  className="space_button"
                  variant="outline-primary"
                  onClick={onSubmit}
                >
                  Save
                </ReactBootstrap.Button>
              </ReactBootstrap.Row>
          </ReactBootstrap.Card.Body>
        </ReactBootstrap.Card>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
};

export default TicketForm;
