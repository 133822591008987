import React from "react";
import {
  Nav,
  Navbar,
  Col,
  Button,
  Badge,
  NavDropdown,
  Container,
  Row,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import SearchBarNew from "./SearchComponent2";
import Avatar from "../../images/avatar.png";
import { useHistory, useLocation } from "react-router-dom";
import "./HeaderComponentNew.css";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import AuthPageModal from "../../screens/AuthPage/AuthPage";
import { showModal } from "../../store/actions/ui";
import { logout } from "../../store/actions/auth";
import { PAGE_URLS, USER_STATES } from "../../js/constants";
import SiteLogo from "../../images/site-logo.png";
import OffCanvas from "./OffCanvas/OffCanvas";
function HeaderComponentNew({
  isLogged,
  showModal,
  logout,
  profile,
  isLoggedOut,
  name,
  cart_item_count,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    showModal("offCan");
  };
  const history = useHistory();

  const goToCart = (e) => {
    if (isLogged) {
      history.push({
        pathname: "/mycart",
      });
    } else {
      showModal("login");
    }
  };

  //  GET QUERY STRING ON PAGE LOAD
  useEffect(() => {
    if (isLoggedOut) {
      history.push({
        pathname: PAGE_URLS.HOME,
      });
    }
  }, [isLoggedOut]);

  const location = useLocation();
  let pathname = location.pathname;
  const [showMenu, setShowMenu] = useState(true);
  useEffect(() => {
    if (
      pathname.includes("/mycart") ||
      pathname.includes("/checkout") ||
      pathname.includes("/previeworder")
    ) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [pathname]);
  const btnStyles = { minWidth: 65,borderColor:"#269399" };
  return (
    <React.Fragment>
        <section>
          <Container fluid className="m-0 p-0">
            <Container>
              <Row>
                <Col xs={12} sm={3} lg={4} className="siteLogo">
                  <Navbar.Brand href="#">
                    <img src={SiteLogo} alt="site-logo" />
                  </Navbar.Brand>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={5}
                  className="text-center input-search-column"
                >
                  <SearchBarNew />
                </Col>
                <Col xs={12} sm={3} className="column-top-header">
                  {!isLogged && (
                        <Button
                          variant="outline-dark"
                          onClick={() => showModal("login")}
                          size={"sm"}
                          style={btnStyles}
                        >
                          Login
                        </Button>
                  )}
                  {isLogged && (
                    <>
                      <div className="d-md-none">
                        <Nav.Link
                          onClick={handleShow}
                          className="header-nav-name m-0"
                        >
                          {profile ? (
                            <img
                              className="avator-image mr-2"
                              src={profile.image_50}
                              alt="Avatar pic"
                            />
                          ) : (
                            <img
                              className="avator-image mr-2"
                              src={Avatar}
                              alt="Avatar pic"
                            />
                          )}
                        </Nav.Link>
                      </div>
                      <div className=" d-none d-md-block">
                        <section className="dropdownSection">
                          <Navbar>
                            <NavDropdown
                              alignRight
                              title={
                                <div className="pull-left">
                                  {profile ? (
                                    <img
                                      className="avator-image"
                                      src={profile.image_50}
                                      alt="Avatar pic"
                                    />
                                  ) : (
                                    <img
                                      className="avator-image"
                                      src={Avatar}
                                      alt="Avatar pic"
                                    />
                                  )}
                                </div>
                              }
                              id="basic-nav-dropdown"
                            >
                              <div className="dropdownMenuSection dropdownEditColour">
                                <NavDropdown.Item
                                  as={NavLink}
                                  to="/account"
                                  className="header-dropdown-menu-items"
                                >
                                  <i className="fa fa-user mr-1"></i> My Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                  to={PAGE_URLS.HOME}
                                  onClick={logout}
                                  className="header-dropdown-menu-items"
                                >
                                  <i className="fa fa-sign-out mr-1"></i>LogOut
                                </NavDropdown.Item>
                              </div>
                            </NavDropdown>
                          </Navbar>
                        </section>
                      </div>
                    </>
                  )}

                  <div className="cart-icon">
                  <a
                  onClick={() => goToCart()}
                  style={{ cursor: "pointer", color: "white" }}
                >
                    <Icon.Cart2 color="#207490" size={24} />
                    <span className="cart-count">
                      <Badge variant="light" className="item-count">
                      {cart_item_count > 10 ? "10+" : cart_item_count}
                      </Badge>
                    </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
            <hr className="m-0 header-divider" />
            <Container>
              <Navbar collapseOnSelect expand="lg" className="main-menu">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mx-auto nav-menu">
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.HOME}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.TEAM_ARTIST}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Team/Artist
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.GENRE}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Genre
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.VENUE}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Venue
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.MOMENTS_HISTORY}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Moments In History
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      exact
                      to={PAGE_URLS.COLLECTIONS}
                      className="px-4"
                      activeClassName="headerNavBarActive"
                    >
                      Collections
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
            <hr className="m-0 header-divider" />
          </Container>
          <AuthPageModal />
          <OffCanvas show={show} onHide={handleClose} name={name} />
        </section>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
    profile: state.user ? state.user.profile_url : null,
    name: state.user ? state.user.name : "",
    mail: state.user ? state.user.mail : "",
    isLoggedOut: state.userState.user_status === USER_STATES.LOGGED_OUT,
    cart_item_count: state.cart.items.length,
  };
};
export default connect(mapStateToProps, {
  showModal,
  logout,
})(HeaderComponentNew);
