import React from "react";
import { useImmer } from "use-immer";
import { api as userapi } from "../../apis/userProtectedApi.instance";
import * as ReactBootstrap from "react-bootstrap";
import { useEffect } from "react";
import "./TeamArtistPage.css";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Teams from "./Teams";
import Artists from "./Artists";
import TeamArtistFilterComponent from "./TeamArtistFilterComponent/TeamArtistFilterComponent";

function TeamArtistPage() {
  const [isLoading, setloader] = useImmer(true);
  // const [genres, setGenre] = useImmer([]);
  // for Table values
  const [teamArtist, setTeamArtist] = useImmer([]);
  const [viewTeamArtist, setViewTeamArtist] = useImmer([]);

  const handleFilterChange = (name, e) => {
    switch (name) {
      case "genre":
        setFilter((draft) => {
          const genre_index = draft.findIndex((d) => d.name === "genre");
          const sub_genre_index = draft.findIndex((d) => d.name === "subgenre");
          if (genre_index !== -1 && sub_genre_index !== -1) {
            // Generate sub genre options based on genre
            var sub_genre_options = e.sub_genre.map((sub_genre) => ({
              ...sub_genre,
              value: sub_genre.name,
              label: sub_genre.display_name,
            }));
            // Set subgenre options
            draft[sub_genre_index].options = sub_genre_options;
            draft[sub_genre_index].value = null;
            // Set Genre value as current value
            draft[genre_index].value = e;
          }
        });
        break;
      case "subgenre":
        setFilter((draft) => {
          const sub_genre_index = draft.findIndex((d) => d.name === "subgenre");
          if (sub_genre_index !== -1) {
            // Set subgenre options
            draft[sub_genre_index].value = e;
          }
        });
        break;
        case "name":
        setFilter((draft) => {
          const name_index = draft.findIndex((d) => d.name === "name");
          if (name_index !== -1) {
            // Set subgenre options
            draft[name_index].value = e.target.value;
          }
        });
        break;
      default:
        break;
    }
  };

  const initial_filters = [
    {
      placeholder: "Genre",
      type: "select",
      value: null,
      name: "genre",
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Sub Genre",
      type: "select",
      value: null,
      name: "subgenre",
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Name",
      type: "text",
      value: "",
      name: "name",
      onChange: handleFilterChange,
    },
  ];

  const [filter, setFilter] = useImmer(initial_filters);

  const getTeamArtist = async () => {
    try {
      var allGenre = await userapi.genre.genreList({has_tickets: true});
      var genreResult = allGenre.data.data.items;

      var allSubGenre = await userapi.genre.genreList({has_tickets: true});
      var subgenreResult = allGenre.data.data.items;

      var genreOptions = genreResult.map((genre) => ({
        ...genre,
        value: genre.genre_name,
        label: genre.display_name,
      }));
      const query = {has_tickets: true};
      var team = await userapi.team.TeamList({has_tickets: true});
      var teamResult = team.data.data.items;
      const data ={
        has_tickets : true
      }
      var artist = await userapi.artist.ArtistList(data);
      var artistResult = artist.data.data.items;

      var genre_subgenre = [];
      genreResult.forEach((genre) => {
        var genre_id = genre.genre_id;
        var genre_name = genre.genre_name;
        var merged_result = genre.sub_genre.map((sub_genre) => ({
          ...sub_genre,
          genre_id,
          genre_name,
        }));
        genre_subgenre = [...genre_subgenre, ...merged_result];
      });
      // Get team name ane subgenre name
      var temp_teamArtists = [];
      teamResult.forEach((team) => {
        var sub_genre = genre_subgenre.filter(
          (sg) => parseInt(sg.sub_genre_id) === parseInt(team.sub_genre.id)
        );
        var temp_teamArtist = {};
        temp_teamArtist.id = team.team_id;
        temp_teamArtist.name = team.display_name;
        temp_teamArtist.subgenre_name = sub_genre[0].name;
        temp_teamArtist.subgenre_id = sub_genre[0].sub_genre_id;
        temp_teamArtist.genre_name = sub_genre[0].genre_name;
        temp_teamArtist.genre_id = sub_genre[0].genre_id;
        temp_teamArtist.isteam = true;
        temp_teamArtists.push(temp_teamArtist);
      });
      // Get artist name ane subgenre name
      artistResult.forEach((artist) => {
        var sub_genre = genre_subgenre.filter(
          (sg) => parseInt(sg.sub_genre_id) === parseInt(artist.sub_genre.id)
        );
        var temp_teamArtist = {};
        temp_teamArtist.id = artist.artist_id;
        temp_teamArtist.name = artist.display_name;
        temp_teamArtist.subgenre_name = sub_genre[0].name;
        temp_teamArtist.subgenre_id = sub_genre[0].sub_genre_id;
        temp_teamArtist.genre_name = sub_genre[0].genre_name;
        temp_teamArtist.genre_id = sub_genre[0].genre_id;
        temp_teamArtist.isteam = false;
        temp_teamArtists.push(temp_teamArtist);
      });

      setFilter((draft) => {
        const index = draft.findIndex((d) => d.name === "genre");
        if (index !== -1) {
          draft[index].options = genreOptions;
        }
      });
      setTeamArtist(temp_teamArtists);
      setViewTeamArtist(temp_teamArtists);
    } catch (error) {
      console.log(error);
    }
  };

  // Page Onload
  useEffect(() => {
    getTeamArtist().then(() => {
      setloader(false);
    });
  }, []);

  //Submit filter
  const filterSubmit = () => {
    var filterTeamArtistArray = [];

    let genre = filter.find((o) => o.name === "genre");
    let sub_genre = filter.find((o) => o.name === "subgenre");
    let name = filter.find((o) => o.name === "name");
    // Both filter is set
    if (genre.value && sub_genre.value) {
      filterTeamArtistArray = teamArtist.filter(
        (ta) => parseInt(ta.subgenre_id) === parseInt(sub_genre.value.sub_genre_id)
      );
    } else if (genre.value) {
      filterTeamArtistArray = teamArtist.filter(
        (ta) => parseInt(ta.genre_id) === parseInt(genre.value.genre_id)
      );
    } else {
      filterTeamArtistArray = teamArtist;
    }
    if(name.value.length>0){
      filterTeamArtistArray = teamArtist.filter(
        (ta) => ta.name.toLowerCase().includes(name.value.toLowerCase())
      )
    }
    setViewTeamArtist(filterTeamArtistArray);
  };

  const reset = () => {
    setFilter(initial_filters);
    getTeamArtist()
    setViewTeamArtist(teamArtist);
  };

  return (
    <React.Fragment>
      <div className="divFilterTeamArtist">
        <TeamArtistFilterComponent
          filters={filter}
          onApply={filterSubmit}
          onClear={reset}
        />
      </div>
      <ReactBootstrap.Container fluid className="my-4 m-0">
        <ReactBootstrap.Row className="m-0">
          <Teams
            teams={viewTeamArtist.filter((ta) => ta.isteam === true)}
            isLoading={isLoading}
          />
          <Artists
            artists={viewTeamArtist.filter((ta) => ta.isteam === false)}
            isLoading={isLoading}
          />
        </ReactBootstrap.Row>
      </ReactBootstrap.Container>
      {/* {isLoading ? (
        <Loader />
      ) : (
        
      )} */}
    </React.Fragment>
  );
}

export default TeamArtistPage;
