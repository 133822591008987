import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { showModal } from "../../../../store/actions/ui";
import AddressViewComponent from "../../../../common/AddressViewComponent/AddressViewComponent";
import { formattedDate } from "../../../../js/utils";
import { ORDER_DELEVERED_STATES } from "../../../../js/constants";
function FulfillInformation({
  order_no,
  carrier,
  service_name,
  tracking_id,
  shipping_add,
  delivery_status,
  onAddfullfillmentModel,
  onEditfullfillmentModel,
  delivery_expected_date,
  items,
}) {
  return (
    <React.Fragment>
      <strong>Fulfill information</strong>
      <Row>
        <Col>
          <strong>Shipping Address</strong>
          <AddressViewComponent
            first_name={shipping_add.first_name}
            last_name={shipping_add.last_name}
            address_line_1={shipping_add.address_line_1}
            address_line_2={shipping_add.address_line_2}
            administrative_area={shipping_add.administrative_area}
            city={shipping_add.city}
            country={shipping_add.country}
            postal_code={shipping_add.postal_code}
            mobile_no={shipping_add.mobile_no}
          />
        </Col>
        <Col>
          <strong className="p-1"> Tracking information</strong>
          {delivery_status === ORDER_DELEVERED_STATES.SHIPPED && (
            <>
              {carrier ? (
                <Link
                 onClick={(e) =>{e.preventDefault(); onEditfullfillmentModel(items)} }
                 to="/fulfillment"
                 >Edit</Link>
              ) : (
                <Link onClick={(e) =>{e.preventDefault(); onAddfullfillmentModel(order_no)}}>
                  add
                </Link>
              )}
            </>
          )}

          <div>
            {carrier && <div>Carrier Name: {carrier}</div>}
            {service_name && <div>Service Name: {service_name}</div>}
            {tracking_id && <div>Tracking Id: {tracking_id}</div>}
            {delivery_expected_date && (
              <div>
                Delivery Expected Date: {formattedDate(delivery_expected_date)}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showModal })(FulfillInformation);
