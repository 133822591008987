export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';

export const USER_INFO_UPDATE_REQUEST = 'USER_INFO_UPDATE_REQUEST';
export const USER_INFO_UPDATE_SUCCESS = 'USER_INFO_UPDATE_SUCCESS';
export const USER_INFO_UPDATE_ERROR = 'USER_INFO_UPDATE_ERROR';

export const USER_INFO_PASSUPDATE_REQUEST = 'USER_INFO_PASSUPDATE_REQUEST';
export const USER_INFO_PASSUPDATE_SUCCESS = 'USER_INFO_PASSUPDATE_SUCCESS';
export const USER_INFO_PASSUPDATE_ERROR = 'USER_INFO_PASSUPDATE_ERROR';

export const USER_CONTACTADDRESS_REQUEST = 'USER_CONTACTADDRESS_REQUEST';
export const USER_CONTACTADDRESS_SUCCESS = 'USER_CONTACTADDRESS_SUCCESS';
export const USER_CONTACTADDRESS_ERROR = 'USER_CONTACTADDRESS_ERROR';

export const USER_BILLINGADDRESS_REQUEST = 'USER_BILLINGADDRESS_REQUEST';
export const USER_BILLINGADDRESS_SUCCESS = 'USER_BILLINGADDRESS_SUCCESS';
export const USER_BILLINGADDRESS_ERROR = 'USER_BILLINGADDRESS_ERROR';

export const USER_SHIPPINGADDRESS_REQUEST = 'USER_SHIPPINGADDRESS_REQUEST';
export const USER_SHIPPINGADDRESS_SUCCESS = 'USER_SHIPPINGADDRESS_SUCCESS';
export const USER_SHIPPINGADDRESS_ERROR = 'USER_SHIPPINGADDRESS_ERROR';

export const USER_ADDADDRESS_REQUEST = 'USER_ADDADDRESS_REQUEST';
export const USER_ADDADDRESS_SUCCESS = 'USER_ADDADDRESS_SUCCESS';
export const USER_ADDADDRESS_ERROR = 'USER_ADDADDRESS_ERROR';

export const USER_EDITADDRESS_REQUEST = 'USER_EDITADDRESS_REQUEST';
export const USER_EDITADDRESS_SUCCESS = 'USER_EDITADDRESS_SUCCESS';
export const USER_EDITADDRESS_ERROR = 'USER_EDITADDRESS_ERROR';

export const USER_DELETESHIPPINGADDRESS_REQUEST = 'USER_DELETESHIPPINGADDRESS_REQUEST';
export const USER_DELETESHIPPINGADDRESS_SUCCESS = 'USER_DELETESHIPPINGADDRESS_SUCCESS';
export const USER_DELETESHIPPINGADDRESS_ERROR = 'USER_DELETESHIPPINGADDRESS_ERROR';

export const USER_SETDEFAULTADDRESS_REQUEST = 'USER_SETDEFAULTADDRESS_REQUEST';
export const USER_SETDEFAULTADDRESS_SUCCESS = 'USER_SETDEFAULTADDRESS_SUCCESS';
export const USER_SETDEFAULTADDRESS_ERROR = 'USER_SETDEFAULTADDRESS_ERROR';

export const USER_GETSELLER_REQUEST = 'USER_GETSELLER_REQUEST';
export const USER_GETSELLER_SUCCESS = 'USER_GETSELLER_SUCCESS';
export const USER_GETSELLER_ERROR = 'USER_GETSELLER_ERROR';

export const USER_SETSELLER_REQUEST = 'USER_SETSELLER_REQUEST';
export const USER_SETSELLER_SUCCESS = 'USER_SETSELLER_SUCCESS';
export const USER_SETSELLER_ERROR = 'USER_SETSELLER_ERROR'; 

export const USER_PROFILEPICUPDATE_REQUEST = 'USER_PROFILEPICUPDATE_REQUEST';
export const USER_PROFILEPICUPDATE_SUCCESS = 'USER_PROFILEPICUPDATE_SUCCESS';
export const USER_PROFILEPICUPDATE_ERROR = 'USER_PROFILEPICUPDATE_ERROR';

export const USER_GETEVENT_REQUEST = 'USER_GETEVENT_REQUEST';
export const USER_GETEVENT_SUCCESS = 'USER_GETEVENT_SUCCESS';
export const USER_GETEVENT_ERROR = 'USER_GETEVENT_ERROR';

export const USER_ADDUSERTICKET_REQUEST = 'USER_ADDUSERTICKET_REQUEST';
export const USER_ADDUSERTICKET_SUCCESS = 'USER_ADDUSERTICKET_SUCCESS';
export const USER_ADDUSERTICKET_ERROR = 'USER_ADDUSERTICKET_ERROR';


export const USER_ROOMLIST_REQUEST = 'USER_ROOMLIST_REQUEST';
export const USER_ROOMLIST_SUCCESS = 'USER_ROOMLIST_SUCCESS';
export const USER_ROOMLIST_ERROR = 'USER_ROOMLIST_ERROR';

