import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import Select, { components } from "react-select";
import {
  OPERATION_STATUS,
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../../js/constants";
// import { useHistory } from "react-router-dom";

const ReportForm = ({
  options,
  error,
  onChangeValue,
  onSubmit,
  onFormCancel,
  isLoading
}) => {
  return (
    <ReactBootstrap.Row className="parentAddTicket">
      <ReactBootstrap.Col>
        <ReactBootstrap.Card>
          <ReactBootstrap.Card.Header as="h5">
            Add Report
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            <ReactBootstrap.Form onSubmit={(e)=>onSubmit(e)}>
              <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group className="mb-3">
                    <ReactBootstrap.Form.Label className="requiredField">
                      Title
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      min={1}
                      placeholder="Enter the title"
                      name="title"
                      onChange={(e) => {
                        onChangeValue("title", "", e);
                      }}
                      className={
                        error.title.length > 0 ? "is-invalid" : ""
                      }
                    />
                    {error.title.length > 0 && (
                      <span className="invalid-feedback">{error.title}</span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Label className="requiredField">
                    Report Type
                  </ReactBootstrap.Form.Label>
                  <Select
                    classNamePrefix="select"
                    isClearable={false}
                    placeholder="Select report type"
                    className={
                      error.reportType.length > 0 ? "is-invalid" : ""
                    }
                    name="reportType"
                    onChange={(e) => {
                      onChangeValue("reportType", "", e);
                    }}
                    options={options}
                  />
                   {error.reportType.length > 0 && (
                      <span className="invalid-feedback">{error.reportType}</span>
                    )}
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group className="mb-3">
                    <ReactBootstrap.Form.Label className="requiredField">
                      Description
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      as="textarea"
                      placeholder="Enter the description here"
                      name="description"
                      rows={3}
                      onChange={(e) => {
                        onChangeValue("description", "", e);
                      }}
                      className={
                        error.description.length > 0 ? "is-invalid" : ""
                      }
                      autoComplete="off"
                    />
                    {error.description.length > 0 && (
                      <span className="invalid-feedback">{error.description}</span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row className="align_props my-2">
                <ReactBootstrap.Button
                  className="space_button"
                  variant="outline-primary"
                  onClick={onFormCancel}
                >
                  Cancel
                </ReactBootstrap.Button>
                <ReactBootstrap.Button as="input" type="submit" value="Create" disabled={isLoading}/>
              </ReactBootstrap.Row>
            </ReactBootstrap.Form>
          </ReactBootstrap.Card.Body>
        </ReactBootstrap.Card>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
  );
};

export default ReportForm;
