import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import PriceDetails from "./PricingInfoComponent/PriceDetails";
import CartItem from "./CartItemComponent/CartItem";

const MyCartView = ({
  cart_id,
  cart_items,
  pricing_details,
  checkout,
  remove,
  isLoading,
}) => {
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <Row>
          <Col xs={12} md={8}>
            {cart_items.map((item) => {
              return (
                <CartItem
                  key={item.id}
                  type={item.type}
                  removeItem={remove}
                  offer_id={item.offer_id}
                  offer_no={item.offer_no}
                  collection_name={item.collection_name}
                  base_price={item.base_price}
                  tax_price={item.tax_price}
                  seller_id={item.seller.id}
                  seller_name={
                    item.seller.is_admin ? "Memory Lane" : item.seller.name
                  }
                  details={item.details}
                  expiry_date={item.expiry_date}
                  created_date = {item.created_date}
                />
              );
            })}
          </Col>
          <Col>
            <PriceDetails
              cart_id={cart_id}
              price_sub_total={pricing_details.sub_total}
              tax={pricing_details.total_tax}
              grand_total={pricing_details.grand_total}
              initiateCheckout={checkout}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default MyCartView;
