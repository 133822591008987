import React from "react";
import {Card,Row,Col,Container,Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PopularEvent.css";

function PopularEvent({ popularEvents }) {
  return (
    <React.Fragment>
      <section className="popular-event-section py-4">
        <Container>
          <Row className="py-5">
            <Col xs={12} md={8} className="mx-auto ">
              <div className="text-center">
                <h2>Popular Events</h2>
                {/* <p className="content-popular-event">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit
                </p> */}
              </div>
            </Col>
          </Row>

          {
          popularEvents.isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" />
            </div>
          ) :
          popularEvents.items.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <p>No Events found</p>
            </div>
          ) : (
            <Row className="px-2">
              {popularEvents.items.map((data,idx) => (
                <Col xs={12} sm={6} md={3} key={idx}>
                  <Link as={Link} to={"/tickets?event=" + data.event_id}>
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Title>
                          {data.name}
                        </Card.Title>
                        <Card.Text>
                          <span className="text-ellipsis-wrap">{data.event_date}{", "}{data.venue.display_name}</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PopularEvent;
