import {
  SET_CHECKOUT_DETAILS,
  SET_CHECKOUT_BILLING_ADDRESS,
  SET_CHECKOUT_SHIPPING_ADDRESS,
  SET_CHECKOUT_STATUS,
  UPDATE_CHECKOUT_BILLING_ADDRESS_SET_STATUS,
  UPDATE_CHECKOUT_SHIPPING_ADDRESS_SET_STATUS,
  IS_ADDRESS_MODEL_HIDE,
  ADD_USER_SHIPPING_ADDRESS_SET_STATUS,
  EDIT_USER_SHIPPING_ADDRESS_SET_STATUS,
  ADD_USER_BILLING_ADDRESS_SET_STATUS,
  EDIT_USER_BILLING_ADDRESS_SET_STATUS
} from "../actions/checkout";
import { EDIT_ADDRESS, ADD_ADDRESS } from "../actions/addressAction";
import { OPERATION_STATUS } from "../../js/constants";
import produce from "immer";


const INITIAL_STATE = {
  checkout_details: {
    accepted_payment_methods: [],
    checkout_id: "",
    status: "",
    line_items: [],
    buyer: null,
    pricing_summary:  {
      price_sub_total: 0,
      tax: 0,
      total: 0,
    },
    shipping_address: null,
    billing_address:null,
  },
  initiate_checkout_status: OPERATION_STATUS.YET_TO_START,
  update_shipping_address_status: OPERATION_STATUS.YET_TO_START,
  update_billing_address_status: OPERATION_STATUS.YET_TO_START,
  TempAddress: "",
  CheckoutStatus: false,
  isAddressModelHide: false,
  add_shipping_address_status: OPERATION_STATUS.YET_TO_START,
  edit_shipping_address_status: OPERATION_STATUS.YET_TO_START,
  add_billing_address_status: OPERATION_STATUS.YET_TO_START,
  edit_billing_address_status: OPERATION_STATUS.YET_TO_START,
};

export const CheckoutReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_CHECKOUT_DETAILS:
      draft.checkout_details.shipping_address = action.payload.shipping_address;
      draft.checkout_details.billing_address = action.payload.billing_address;
      draft.checkout_details.accepted_payment_methods = action.payload.payment_methods;
      draft.checkout_details.checkout_id = action.payload.checkout_id;
      draft.checkout_details.status = action.payload.status;
      draft.checkout_details.line_items = action.payload.line_items;
      draft.checkout_details.buyer = action.payload.buyer;
      draft.checkout_details.pricing_summary.price_sub_total = action.payload.pricing_summary.price_sub_total;
      draft.checkout_details.pricing_summary.tax = action.payload.pricing_summary.tax;
      draft.checkout_details.pricing_summary.total = action.payload.pricing_summary.total;
      break;
    case SET_CHECKOUT_STATUS:
      draft.initiate_checkout_status = action.payload;
      break;
    case UPDATE_CHECKOUT_BILLING_ADDRESS_SET_STATUS:
      draft.update_billing_address_status = action.payload;
      break;
    case UPDATE_CHECKOUT_SHIPPING_ADDRESS_SET_STATUS:
      draft.update_shipping_address_status = action.payload;
      break;
    case EDIT_USER_SHIPPING_ADDRESS_SET_STATUS:
      draft.edit_shipping_address_status = action.payload;
      break;
    case ADD_USER_SHIPPING_ADDRESS_SET_STATUS:
      draft.add_shipping_address_status = action.payload;
      break;
    case SET_CHECKOUT_SHIPPING_ADDRESS:
      draft.checkout_details.shipping_address = action.payload;
      break;
    case SET_CHECKOUT_BILLING_ADDRESS:
      draft.checkout_details.billing_address = action.payload;
      break;
    case ADD_USER_BILLING_ADDRESS_SET_STATUS:
      draft.add_billing_address_status = action.payload;
      break;
    case EDIT_USER_BILLING_ADDRESS_SET_STATUS:
      draft.edit_billing_address_status = action.payload;
      break;
    // case IS_ADDRESS_MODEL_HIDE:
    //   draft.isAddressModelHide = action.payload;
    //   break;
    // case EDIT_ADDRESS:
    //   draft.TempAddress = action.payload;
    //   break;
    // case ADD_ADDRESS:
    //   draft.TempAddress = action.payload;
    //   break;
    default:
      break;
  }
}, INITIAL_STATE);
