import {
  SET_OFFER_ITEMS,
  SEND_OFFER_STATUS,
  SEND_OFFER_DATA,
  UPDATE_MY_BUY_OFFERS,
  UPDATE_MY_SELL_OFFERS,
  UPDATE_MY_OFFER,
  FETCH_SINGLE_MY_OFFER_ERROR,
} from "../actions/myOffer";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  buy: {
    hasMore: false,
    nextPage: null,
    offers: [],
  },
  sell: {
    hasMore: false,
    nextPage: null,
    offers: [],
  },
  currentOfferItems: [],
  sendOffer: {
    status: OPERATION_STATUS.YET_TO_START,
    data: null,
  },
  offer: {
    info: null,
    error: null,
  },
};

export const myOfferReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_MY_BUY_OFFERS:
      draft.buy.hasMore = action.payload.hasMore;
      draft.buy.nextPage = action.payload.page;
      draft.buy.offers = action.payload.offers;
      break;
    case UPDATE_MY_SELL_OFFERS:
      draft.sell.hasMore = action.payload.hasMore;
      draft.sell.nextPage = action.payload.page;
      draft.sell.offers = action.payload.offers;
      break;
    case SET_OFFER_ITEMS:
      draft.currentOfferItems = action.payload.map((item) => {
        return {
          product_id: item.product_id,
          name: item.listing_name,
          cost: item.cost,
          image: item.image,
        };
      });
      break;
    case SEND_OFFER_STATUS:
      draft.sendOffer.status = action.payload;
      break;
    case SEND_OFFER_DATA:
      draft.sendOffer.data = action.payload;
      break;
    case UPDATE_MY_OFFER:
      draft.offer.info = action.payload;
      draft.offer.error = null;
      break;
    case FETCH_SINGLE_MY_OFFER_ERROR:
      draft.offer.info = null;
      draft.offer.error = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);
