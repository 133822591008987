export const GET_ROOMS = "[mymessages] GET rooms";
export const FETCH_ROOMS_SUCCESS = "[mymessages] Fetch rooms success";
export const FETCH_ROOMS_ERROR = "[mymessages] Fetch rooms Error";
export const UPDATE_ROOMS = "[mymessages] Update rooms";

export const GET_NEXT_ROOMS = "[mymessages] GET Next rooms";
export const FETCH_NEXT_ROOMS_SUCCESS = "[mymessages] Fetch Next rooms success";
export const FETCH_NEXT_ROOMS_ERROR = "[mymessages] Fetch Next rooms error";

export const CONTACT_SELLER = "[mymessages] Contact seller to send message";
export const GET_SELLER_ROOM = "[mymessages] GETRoomId";
export const FETCH_ROOM_SUCCESS = "[mymessages] Fetch Single Room Success (contact seller)";
export const FETCH_ROOM_ERROR = "[mymessages] Fetch Single Room Error (contact seller)";
export const SET_ACTIVE_ROOM = "[mymessages] Set active room details";
export const SET_SELLER_ID = "[mymessages]  Set receiver id ";
export const CONTACT_SELLER_STATUS = '[mymessages] Contact seller set status';

export const SELECT_ROOM                    = '[mymessages] Select Room';
export const FETCH_SINGLE_ROOM_SUCCESS      = '[mymessages] Fetch single room success';
export const FETCH_SINGLE_ROOM_ERROR        = '[mymessages] Fetch single room Error';
export const SET_SINGLE_ROOM_STATUS = '[mymessages] Select Room set status';

export const CREATE_ROOM_SUCCESS = "[mymessages] Create Room Success";
export const CREATE_ROOM_ERROR = "[mymessages] Create Room Error";
export const UPDATE_CREATEROOM = "[mymessages] UpdateCreatedRoomId";
export const CREATE_ROOM_STATUS = '[mymessages] Create Room set status';

export const GET_MESSAGES = "[mymessages] GET Messages";
export const FETCH_MESSAGE_SUCCESS = "[mymessages] Fetch messages success";
export const FETCH_MESSAGE_ERROR = "[mymessages] Fetch messages Error";
export const UPDATE_MESSAGES = "[mymessages] Update messages";

export const GET_NEXT_MESSAGES = "[mymessages] GET Next Messages";
export const FETCH_NEXT_MESSAGES_SUCCESS = "[mymessages] Fetch Next Messages success";
export const FETCH_NEXT_MESSAGES_ERROR = "[mymessages] Fetch Next Messages error";


export const SEND_MESSAGE = "[mymessages] Send message";
export const SEND_MESSAGE_SUCCESS = "[mymessages] Send message Success";
export const SEND_MESSAGE_ERROR = "[mymessages] Send message Error";
export const UPDATE_SEND_MESSAGE = "[mymessages] Send message set data";
export const SEND_MESSAGE_STATUS = '[mymessages] Send message set status';

export const UPDATE_UNREAD_MESSAGE = "[mymessages] Update unread message";
export const UPDATE_UNREAD_MESSAGE_SUCCESS = "[mymessages]  Update unread message Success";
export const UPDATE_UNREAD_MESSAGE_ERROR = "[mymessages]  Update unread message Error";
export const UPDATE_UNREAD_MESSAGE_STATUS = '[mymessages] Update unread message set status';
export const SET_UNREAD_MESSAGE = "[mymessages] Unread message set data";

// ************************* GET ROOM LIST ********************************
// For fetching moments
export const getRoomsList = () => ({
  type: GET_ROOMS,
});
// For fetching moments
export const fetchRoomsSuccess = (data) => ({
  type: FETCH_ROOMS_SUCCESS,
  payload: data,
});

export const fetchRoomsError = (data) => ({
  type: FETCH_ROOMS_ERROR,
  payload: data,
});

export const setRooms = (data) => ({
  type: UPDATE_ROOMS,
  payload: data,
});
// ************************* GET NEXT ROOM LIST ********************************
export const getNextRoomsList = (data) => ({
  type: GET_NEXT_ROOMS,
  payload: data,
});
// For fetching moments
export const fetchNextRoomsSuccess = (data) => ({
  type: FETCH_NEXT_ROOMS_SUCCESS,
  payload: data,
});

export const fetchNextRoomsError = (data) => ({
  type: FETCH_NEXT_ROOMS_ERROR,
  payload: data,
});

//************************** GET SELLER ROOM ***************************** */
export const contactSeller = (seller_id) => ({
  type: CONTACT_SELLER,
  payload: {
    receiver_id: seller_id
  },
});

// For fetching moments
export const fetchRoomSuccess = (data) => ({
  type: FETCH_ROOM_SUCCESS,
  payload: data,
});

export const fetchRoomError = (data) => ({
  type: FETCH_ROOM_ERROR,
  payload: data,
});

export const setActiveRoomDetails = (data) => ({
  type: SET_ACTIVE_ROOM,
  payload: data,
});

export const setContactSellerStatus = (data) => ({
  type: CONTACT_SELLER_STATUS,
  payload: data,
});
export const setReceiverId = (data) => ({
  type: SET_SELLER_ID,
  payload: data,
});

export const createRoomSuccess = (data) => ({
  type: CREATE_ROOM_SUCCESS,
  payload: data,
});

export const createRoomError = (data) => ({
  type: CREATE_ROOM_ERROR,
  payload: data,
});

export const createRoomSetStatus = (data) => ({
  type: CREATE_ROOM_STATUS,
  payload: data,
});

export const setCreateRoom = (data) => ({
  type: UPDATE_CREATEROOM,
  payload: data,
});

//********************************************************* */
export const getMessages = (data) => ({
  type: GET_MESSAGES,
  payload: data,
});

// For fetching moments
export const fetchMessagesSuccess = (data) => ({
  type: FETCH_MESSAGE_SUCCESS,
  payload: data,
});

export const fetchMessagesError = (data) => ({
  type: FETCH_MESSAGE_ERROR,
  payload: data,
});

export const setMessages = (data) => ({
  type: UPDATE_MESSAGES,
  payload: data,
});

//**********************GET NEXT MESSAGE LIST*********************************** */

export const getNextMessages = (data) => ({
  type: GET_NEXT_MESSAGES,
  payload: data,
});

// For fetching moments
export const fetchNextMessagesSuccess = (data) => ({
  type: FETCH_NEXT_MESSAGES_SUCCESS,
  payload: data,
});

export const fetchNextMessagesError = (data) => ({
  type: FETCH_NEXT_MESSAGES_ERROR,
  payload: data,
});
//***************************SEND MESSAGE****************************** */

export const sendMessage = (data) => ({
  type: SEND_MESSAGE,
  payload: data,
});


export const sendMessageSuccess = (data) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: data,
});

export const sendMessageError = (data) => ({
  type: SEND_MESSAGE_ERROR,
  payload: data,
});

export const setSendMessageStatus = (data) => ({
  type: SEND_MESSAGE_STATUS,
  payload: data,
});

export const setSendMessageData = (data) => ({
  type: UPDATE_SEND_MESSAGE,
  payload: data,
});


export const getRoom = (data) => ({
  type: SELECT_ROOM,
  payload: data
});
// For fetching moments
export const fetchSingleRoomSuccess = (data) => ({
  type: FETCH_SINGLE_ROOM_SUCCESS,
  payload: data,
});

export const fetchSingleRoomError = (data) => ({
  type: FETCH_SINGLE_ROOM_ERROR,
  payload: data,
});

export const setSingleRoomStatus = (data) => ({
  type: SET_SINGLE_ROOM_STATUS,
  payload: data,
});

export const updateUnreadMessage = (data) => ({
  type: UPDATE_UNREAD_MESSAGE,
  payload: data,
});


export const updateUnreadMessageSuccess = (data) => ({
  type: UPDATE_UNREAD_MESSAGE_SUCCESS,
  payload: data,
});

export const updateUnreadMessageError = (data) => ({
  type: UPDATE_UNREAD_MESSAGE_ERROR,
  payload: data,
});

export const updateUnreadMessageSetStatus = (data) => ({
  type: UPDATE_UNREAD_MESSAGE_STATUS,
  payload: data,
});

export const setUnreadMessageFlag = (data) => ({
  type: SET_UNREAD_MESSAGE,
  payload: data,
});