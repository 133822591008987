export const INITIATE_ORDER                   = '[order] initiate Order';
export const INITIATE_ORDER_SUCCESS           = '[order] initiate Order success';
export const INITIATE_ORDER_ERROR             = '[order] initiate Order Error';
export const SET_INITIATE_ORDER_DATA          = '[order] set initiate Order'
export const SET_ORDER_STATUS                 = '[order] set order status'

export const GET_MY_BUY_ORDERS               = '[myorders] GET buy orders';
export const FETCH_MY_BUY_ORDERS_SUCCESS     = '[myorders] Fetch buy order success';
export const FETCH_MY_BUY_ORDERS_ERROR       = '[myorders] Fetch  buyer order error';
export const GET_NEXT_MY_BUY_ORDERS                 = '[myorders] GET Next buy orders';
export const FETCH_NEXT_MY_BUY_ORDERS_SUCCESS       = '[myorders] Fetch next buy orders success';
export const FETCH_NEXT_MY_BUY_ORDERS_ERROR         = '[myorders] Fetch next buy orders Error';
export const UPDATE_MY_BUY_ORDERS                  = '[myorders] Update buy orders';


export const GET_MY_SELL_ORDERS                     = '[myorders] GET sell orders';
export const FETCH_MY_SELL_ORDERS_SUCCESS            = '[myorders] Fetch sell orders success';
export const FETCH_MY_SELL_ORDERS_ERROR              = '[myorders] Fetch sell orders Error';
export const GET_NEXT_MY_SELL_ORDERS                 = '[myorders] GET Next sell orders';
export const FETCH_NEXT_MY_SELL_ORDERS_SUCCESS       = '[myorders] Fetch next sell orders success';
export const FETCH_NEXT_MY_SELL_ORDERS_ERROR         = '[myorders] Fetch next sell orders Error';
export const UPDATE_MY_SELL_ORDERS                  = '[myorders] Update sell orders';


export const ADD_TRACKING_INFO_DETAILS         = '[myorders] Add Tracking information Details ';
export const ADD_TRACKING_INFO_DETAILS_SUCCESS = '[myorders] Add Tracking information Success';
export const ADD_TRACKING_INFO_DETAILS_ERROR   = '[myorders] Add Tracking information error';

export const EDIT_TRACKING_INFO_DETAILS         = '[myorders] Edit Tracking information Details ';
export const EDIT_TRACKING_INFO_DETAILS_SUCCESS = '[myorders] Edit Tracking information Success';
export const EDIT_TRACKING_INFO_DETAILS_ERROR   = '[myorders] Edit Tracking information error';
export const SET_TRACKING_INFO_DETAILS          = '[myorders] set Tracking information ';
export const SET_TRACKING_INFO_STATUS          = '[myorders] Add / Edit set status ';



export const APPROVE_ORDER_DELIVERED         = '[myorders] approve order delivered (seller)';
export const APPROVE_ORDER_DELIVERED_SUCCESS = '[myorders] approve order delivered (seller) Success';
export const APPROVE_ORDER_DELIVERED_ERROR   = '[myorders] approve order delivered (seller) error';
export const APPROVE_ORDER_DELIVERED_SET_STATUS   = '[myorders] approve order delivered (seller) set status';
export const APPROVE_ORDER_DELIVERED_SET_DATA   = '[myorders] approve order delivered (seller) set data';

export const APPROVE_ORDER_RECEIVED         = '[myorders] approve order received (seller)';
export const APPROVE_ORDER_RECEIVED_SUCCESS = '[myorders] approve order received (seller) Success';
export const APPROVE_ORDER_RECEIVED_ERROR   = '[myorders] approve order received (seller) error';
export const APPROVE_ORDER_RECEIVED_SET_STATUS   = '[myorders] approve order received (seller) set status';
export const APPROVE_ORDER_RECEIVED_SET_DATA   = '[myorders] approve order received (seller) set data';

export const ALERTMODEL_STATUS  ='[myorders] set alertmodel status'

//**************************************** Order Initiate *************************************************** */
export const initiateOrder = (data) => ({
  type: INITIATE_ORDER,
  payload: data,
});

export const initiateOrderSuccess = (data) => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: data,
});

export const initiateOrderError = (data) => ({
  type: INITIATE_ORDER_ERROR,
  payload: data
});
export const setinitiateOrder = (data) => ({
  type: SET_INITIATE_ORDER_DATA,
  payload: data
});

export const setOrderStatus = (data) => ({
  type: SET_ORDER_STATUS,
  payload: data,
});
//********************************************BUYER***************************************************** */
// For fetching Buyer list 
export const getMyBuyOrders = (filter = {}) => ({
  type: GET_MY_BUY_ORDERS,
  payload: filter,
});

export const fetchMyBuyOrdersSuccess = (data) => ({
  type: FETCH_MY_BUY_ORDERS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchMyBuyOrdersError = (data) => ({
  type: FETCH_MY_BUY_ORDERS_ERROR,
  payload: data
});


// For fetching tickets for next iteration
export const getNextMyBuyOrders = (filter) => ({
  type: GET_NEXT_MY_BUY_ORDERS,
  payload: {...filter, type: "BUYER"}
});


export const fetchNextMyBuyOrdersSuccess = (data) => ({
  type: FETCH_NEXT_MY_BUY_ORDERS_SUCCESS,
  payload: data,
  meta: {
    to_append: true
  }
});

export const fetchNextMyBuyOrdersError = (data) => ({
  type: FETCH_NEXT_MY_BUY_ORDERS_ERROR,
  payload: data
});


export const setMyBuyOrders = (data) => ({
  type   : UPDATE_MY_BUY_ORDERS,
  payload: data
});


//********************************************SELLER***************************************************** */

// For fetching seller Order list
export const getMySellOrders = (filter = {}) => ({
  type: GET_MY_SELL_ORDERS,
  payload: filter,
});

export const fetchMySellOrdersSuccess = (data) => ({
  type: FETCH_MY_SELL_ORDERS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchMySellOrdersError = (data) => ({
  type: FETCH_MY_SELL_ORDERS_ERROR,
  payload: data
});


// For fetching tickets for next iteration
export const getNextMySellOrders = (filter) => ({
  type: GET_NEXT_MY_SELL_ORDERS,
  payload: {...filter, type: "BUYER"}
});


export const fetchNextMySellOrdersSuccess = (data) => ({
  type: FETCH_NEXT_MY_SELL_ORDERS_SUCCESS,
  payload: data,
  meta: {
    to_append: true
  }
});

export const fetchNextMySellOrdersError = (data) => ({
  type: FETCH_NEXT_MY_SELL_ORDERS_ERROR,
  payload: data
});


export const setMySellOrders = (data) => ({
  type   : UPDATE_MY_SELL_ORDERS,
  payload: data
});

//*********************************SELLER ORDER TRACKING INFO******************************************************* */

export const addTrackingInformation = (data) => ({
  type: ADD_TRACKING_INFO_DETAILS,
  payload: data,
});
export const addTrackingInformationSuccess = (data) => ({
  type: ADD_TRACKING_INFO_DETAILS_SUCCESS,
  payload: data,
});
  
export const addTrackingInformationError = (data) => ({
  type: ADD_TRACKING_INFO_DETAILS_ERROR,
  payload: data
});


export const editTrackingInformation = (data) => ({
  type: EDIT_TRACKING_INFO_DETAILS,
  payload: data,
});
export const editTrackingInformationSuccess = (data) => ({
  type: EDIT_TRACKING_INFO_DETAILS_SUCCESS,
  payload: data,
});
  
export const editTrackingInformationError = (data) => ({
  type: EDIT_TRACKING_INFO_DETAILS_ERROR,
  payload: data
});
export const setTrackingDetails = (data) => ({
  type: SET_TRACKING_INFO_DETAILS,
  payload: data
});

export const setTrackingInfoStatus = (data) => ({
  type: SET_TRACKING_INFO_STATUS,
  payload: data
});
    

//*********************************DELIVERED BY SELLER******************************************************* */

export const approveOrderDelivered = (data) => ({
  type: APPROVE_ORDER_DELIVERED,
  payload: data,
});
export const approveOrderDeliveredSuccess = (data) => ({
  type: APPROVE_ORDER_DELIVERED_SUCCESS,
  payload: data,
});
  
export const approveOrderDeliveredError = (data) => ({
  type: APPROVE_ORDER_DELIVERED_ERROR,
  payload: data
}); 

export const approveOrderDeliveredSetStatus = (data) => ({
  type: APPROVE_ORDER_DELIVERED_SET_STATUS,
  payload: data
});

export const approveOrderDeliveredSetData = (data) => ({
  type: APPROVE_ORDER_DELIVERED_SET_DATA,
  payload: data
});

//*********************************DELIVERED BY SELLER******************************************************* */

export const approveOrderReceived = (data) => ({
  type: APPROVE_ORDER_RECEIVED,
  payload: data,
});
export const approveOrderReceivedSuccess = (data) => ({
  type: APPROVE_ORDER_RECEIVED_SUCCESS,
  payload: data,
});
  
export const approveOrderReceivedError = (data) => ({
  type: APPROVE_ORDER_RECEIVED_ERROR,
  payload: data
}); 

export const approveOrderReceivedSetStatus = (data) => ({
  type: APPROVE_ORDER_RECEIVED_SET_STATUS,
  payload: data
});

export const approveOrderReceivedSetData = (data) => ({
  type: APPROVE_ORDER_RECEIVED_SET_DATA,
  payload: data
});

export const alertModelStatus = (data) => ({
  type: ALERTMODEL_STATUS,
  payload: data
});