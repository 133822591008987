import React, { useState } from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import "../../../MyAccountScreen/MyAccountScreen.css";
import ManageDefaultAddress from "./ManageDefaultAddress/ManageDefaultAddress";
import DisplayDefaultAddress from "./DisplayDefaultAddress/DisplayDefaultAddress";
import { connect } from "react-redux";
import { showModal,hideModal } from "../../../../store/actions/ui";
import AddressAddEditModel from "../../AdressAddEditModel/AddressAddEditModel";
import {setAddressModel} from "../../../../store/actions/addressAction";

function MyAccountPage({
  setAddressModel,
  showModal
}) {
    const [open, setOpen] = useState(false);

    const [getState, setBillingModelShow] = useImmer({
      AddressType: "",
      operations: "",
      title: "",
      billingModelShow: false,
    });
    const AddButton = (e) => {
      var Data ={
        AddressType:"DELIVERY",
        operations:"ADD",
        AddressID: "",
        address:null,
        title:"Add Shipping Information"
      }
      setAddressModel(Data)
      showModal('AddressAddEdit')
    };
  return (
<React.Fragment>        
<div className="mb-4">
<ReactBootstrap.Card>
  <ReactBootstrap.Card.Header as="h5">
      <div className="d-flex">
      <div className="flex-grow-1">
      <div>Shipping Address</div>
          <div><span
              className="expandManageAddress"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
           Manage Address
           </span>
           </div>
      </div>
      {/* <ReactBootstrap.Col xs={12} md={6} className="d-flex justify-content-end align-items-end"> */}
      <div className="flex-nowrap align-content-end">
        <ReactBootstrap.Button
              className="btnEditSection"
              onClick={()=>AddButton()}
            >
              Add
         </ReactBootstrap.Button>
      </div>
      </div>
  </ReactBootstrap.Card.Header>
  <ReactBootstrap.Card.Body>
    <div>
      <DisplayDefaultAddress />
    </div>
    <ReactBootstrap.Collapse in={open}>
      <ReactBootstrap.Card>
         <ManageDefaultAddress />
      </ReactBootstrap.Card>
    </ReactBootstrap.Collapse>
  </ReactBootstrap.Card.Body>
</ReactBootstrap.Card>
</div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return { 
    isLoading: 'address' in state.ui.loaders ? state.ui.loaders['address'] : true
  };
};

export default connect(mapStateToProps, {showModal, setAddressModel})(
  MyAccountPage
);


