import {
  UPDATE_ROOMS,
  SET_SELLER_ID,
  UPDATE_MESSAGES,
  UPDATE_SEND_MESSAGE,
  SET_ACTIVE_ROOM,
  CONTACT_SELLER_STATUS,
  CREATE_ROOM_STATUS,
  SEND_MESSAGE_STATUS,
  SET_SINGLE_ROOM_STATUS,
  SET_UNREAD_MESSAGE,
  UPDATE_UNREAD_MESSAGE_STATUS
} from "../actions/mymessages";
import produce from "immer";
import {OPERATION_STATUS} from "../../js/constants"

const INITIAL_ROOM_STATE = {
  rooms: [],
  hasMore: false,
  nextPage: null,
  activeRoom: {
    info: null,
    error: null,
  },
  contact_seller: {
    status: OPERATION_STATUS.YET_TO_START,
    receiver_id: null,
  },
  create_room: {
    status: OPERATION_STATUS.YET_TO_START,
  },
  select_room: {
    status: OPERATION_STATUS.YET_TO_START,
  },
  update_unread_flag: {
    status: OPERATION_STATUS.YET_TO_START,
  }
};

export const roomReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_ROOMS:
      draft.rooms = action.payload.roomslist;
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      break;
    case SET_ACTIVE_ROOM:
      draft.activeRoom.info = action.payload;
      break;
    case SET_SELLER_ID:
      draft.contact_seller.receiver_id = action.payload;
      break;
    case CONTACT_SELLER_STATUS:
      draft.contact_seller.status = action.payload;
      break;
    case CREATE_ROOM_STATUS:
      draft.create_room.status = action.payload;
      break;
    case SET_SINGLE_ROOM_STATUS:
      draft.select_room.status = action.payload;
      break;
    case UPDATE_UNREAD_MESSAGE_STATUS:
      draft.update_unread_flag.status = action.payload;
      break;
    case SET_UNREAD_MESSAGE:
      draft.activeRoom.info.hasUnreadMessage = false;
      let room_index = draft.rooms.findIndex(room => room.id === action.payload)
      if(room_index > -1){
        draft.rooms[room_index].hasUnreadMessage = false;
      }
      break;
    default:
      break;
  }
}, INITIAL_ROOM_STATE);


const INITIAL_MESSAGE_STATE = {
  messages: [],
  hasMore: false,
  nextPage: null,
  send_message: {
    status: OPERATION_STATUS.YET_TO_START
  }
};

export const messageReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_MESSAGES:
      draft.messages = action.payload.messages;
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      break;
    case UPDATE_SEND_MESSAGE:
      draft.messages = action.payload;
      break;
    case SEND_MESSAGE_STATUS:
      draft.send_message.status = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_MESSAGE_STATE);
