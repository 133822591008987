import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, Card, Badge } from "react-bootstrap";
import ReactStars from "../../../common/StarRatings/react-stars";
import "./MyReviewCardComponent.css";
const MyReviewCardComponent = ({
  type,
  communication_rating,
  description_rating,
  shipping_rating,
  rating_on,
  review,
  user_name,
  user_image,
}) => {
  return (
    <React.Fragment>
      <Card className="searchResultCard my-2">
        <Card.Body>
          <Row>
            <Col
              xs={12}
              md={2}
              className=" text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <div>
                  <img src={user_image} width="80" height="80" />
                </div>
              </div>
            </Col>
            <Col xs={12} md={8} className=" d-flex align-items-center">
              <div>
                <div className="flex-grow-1 font-weight-bold">
                  <h5
                    title="Order Number "
                    className="font-weight-bold  "
                    style={{ color: "var(--secondary-color)" }}
                  >
                    {user_name}
                  </h5>
                </div>
                <Row>
                  <Col xs={12} md={12}>
                    <span className="font-weight-bold ">{rating_on}</span>
                    {communication_rating ? (
                      <>
                        <div className=" d-flex ">
                          Communication rating :
                          <ReactStars value={communication_rating} />
                        </div>
                        <div className=" d-flex ">
                          Description rating :
                          <ReactStars value={description_rating} />
                        </div>
                        <div className=" d-flex ">
                          Shipping rating :
                          <ReactStars value={shipping_rating} />
                        </div>
                      </>
                    ) : (
                      <p>No ratings found </p>
                    )}
                    {review && <>Review : {review}</>}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex justify-content-center">
              <div>
                <h5>
                  <Badge className="badge-info">{type} </Badge>
                </h5>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
export default MyReviewCardComponent;
