import React from "react";
import "./ChatView.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import TextMessage from "./TextMessage/TextMessage";

const ChatView = ({ messages, hasMore, nextPage, isLoading }) => {
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div
          id="chatScrollableDiv"
          style={{
            height: 330,
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <InfiniteScroll
            dataLength={messages.length}
            next={nextPage}
            hasMore={hasMore}
            scrollableTarget="chatScrollableDiv"
            style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
            inverse={true}
            loader={
              <h4>
                <Spinner as="span" animation="border" role="status" />
                Loading...
              </h4>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                {messages.length !== 0 ? (
                  <b>No more Messages found</b>
                ) : (
                  <b>No Message Found</b>
                )}
              </p>
            }
          >
            {messages.map((message) => {
              return (
                <TextMessage
                  key={message.id}
                  message={message.message}
                  time={message.send_date}
                  sender_name={message.sender_details.name}
                  sender_image={message.sender_details.avatar.image_50}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </React.Fragment>
  );
};

export default ChatView;
