import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import SelectedOfferItem from "./OfferItemCardSelectedComponent/OfferItemCardSelectedComponent";
import { Form, Button, Spinner } from "react-bootstrap";

const OfferForm = ({
  value,
  onChangeValue,
  onSubmit,
  onCancel,
  selectItem,
  removeItem,
  error,
}) => {
  return (
    <React.Fragment>
      <Form onSubmit={onSubmit}>
        <Form.Group className="">
          <Form.Label className="requiredField">Offer price</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your price..."
            name="price"
            value={value.price}
            className={error.price.length > 0 ? "is-invalid" : ""}
            onChange={(e) => onChangeValue("price", e)}
            autoComplete="off"
          />
          {error.price.length > 0 && (
            <span className="invalid-feedback">{error.price}</span>
          )}
        </Form.Group>
        <Form.Group className="">
          <Form.Label>Message</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your message..."
            name="message"
            value={value.message}
            className={error.message.length > 0 ? "is-invalid" : ""}
            onChange={(e) => onChangeValue("message", e)}
            autoComplete="off"
          />
          {error.message.length > 0 && (
            <span className="invalid-feedback">{error.message}</span>
          )}
        </Form.Group>
        <Form.Group className="">
          <Form.Label>Additional Items</Form.Label>
          <div className="d-flex flex-nowrap">
            <div className="flex-grow-1">
              <Select
                className={
                  error.currentOfferItem.length > 0 ? "is-invalid" : ""
                }
                value={value.currentOfferItem}
                isClearable={false}
                onChange={(e) => {
                  onChangeValue("currentOfferItem", e);
                }}
                isSearchable
                name="currentOfferItem"
                options={value.availableOfferItems}
                placeholder="Select a offer items"
              />
            </div>
            <div className="ml-1">
              <Button onClick={() => selectItem()}>
                <i className="fa fa-plus-circle" aria-hidden="true" />
              </Button>
            </div>
          </div>
          {error.currentOfferItem.length > 0 && (
            <span className="text-danger">{error.currentOfferItem}</span>
          )}
        </Form.Group>
        <div className="border-top border-bottom my-2">
          <p className="text-center font-weight-bold pt-1">Selected Items</p>
          {value.items.length === 0 ? (
            <p className="text-center">No items selected</p>
          ) : (
            <div className="d-flex flex-wrap">
              {value.items.map((item) => {
                return (
                  <SelectedOfferItem
                    key={item.product_id}
                    id={item.product_id}
                    listing_name={item.name}
                    cost={item.cost}
                    image={item.image}
                    removeItem={removeItem}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end">
          {value.loading ? (
            <div className="mx-1">
              <Button type="submit" disabled={value.loading}>
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-1"
                  />
                  Loading ...
                </div>
              </Button>
            </div>
          ) : (
            <>
              <div className="mx-1">
                <Button type="submit">{value.operation}</Button>
              </div>
              <div>
                <Button onClick={onCancel}>Cancel</Button>
              </div>
            </>
          )}
        </div>
      </Form>
    </React.Fragment>
  );
};

OfferForm.propTypes = {
  value: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default OfferForm;
