import React, {useEffect} from "react";
import * as ReactBootstrap from "react-bootstrap";
import MyMessageView from "./MyMessageView";
import { connect } from "react-redux";
import {
  getRoomsList,
  getNextRoomsList,
} from "../../store/actions/mymessages";

const MyMesagePage = ({
	rooms,
  isLoading,
  getRoomsList,
  getNextRoomsList,
  hasMore,
  page,
}) => {

	// Infinite scroll callback to fetch next set of tickets
  const nextPage = () => {
    let filters = {
      page: page
    };
    getNextRoomsList(filters)
	};

	useEffect(() => {
    getRoomsList();
  }, []);
	
  return (
    <React.Fragment>
      <ReactBootstrap.Row className="my-4">
        <ReactBootstrap.Col>
          <h5>My Messages</h5>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Dropdown.Divider />
			{isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : (
        <MyMessageView
					rooms={rooms}
          hasMore={hasMore}
          nextPage={nextPage}
        />
      )}
    </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    rooms: state.room.rooms,
    hasMore: state.room.hasMore,
    page: state.room.nextPage,
    isLoading: "mymessages-rooms_init" in state.ui.loaders ? state.ui.loaders["mymessages-rooms_init"] : true,
  };
};

export default connect(mapStateToProps, { getRoomsList, getNextRoomsList })(
  MyMesagePage
);