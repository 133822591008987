export const GET_MY_TICKETS                      = '[mytickets] GET';
export const FETCH_MY_TICKETS_SUCCESS            = '[mytickets] Fetch success';
export const FETCH_MY_TICKETS_ERROR              = '[mytickets] Fetch Error';
export const GET_NEXT_MY_TICKETS                 = '[mytickets] GET Next';
export const FETCH_NEXT_MY_TICKETS_SUCCESS       = '[mytickets] Fetch next success';
export const FETCH_NEXT_MY_TICKETS_ERROR         = '[mytickets] Fetch next Error';
export const UPDATE_MY_TICKETS                   = '[mytickets] Update';
export const SELECT_MY_TICKET                    = '[mytickets] Select';
export const FETCH_SINGLE_MY_TICKET_SUCCESS      = '[mytickets] Fetch single success';
export const FETCH_SINGLE_MY_TICKET_ERROR        = '[mytickets] Fetch single Error';
export const UPDATE_MY_TICKET                    = '[mytickets] Update single';
export const CREATE_MY_TICKET                    = '[mytickets] Create ticket';
export const CREATE_MY_TICKET_SUCCESS            = '[mytickets] Create ticket success';
export const CREATE_MY_TICKET_ERROR              = '[mytickets] Create ticket error';
export const CREATE_MY_TICKET_STATUS             = '[mytickets] Create ticket set status';
export const UPDATE_MY_TICKET_FETCH              = '[mytickets] Update ticket (fetch ticket)';
export const UPDATE_MY_TICKET_FETCH_SUCCESS      = '[mytickets] Update ticket (fetch ticket success)';
export const UPDATE_MY_TICKET_FETCH_ERROR        = '[mytickets] Update ticket (fetch ticket error)';
export const UPDATE_MY_TICKET_SET_DATA           = '[mytickets] Update ticket (set ticket data)';
export const UPDATE_MY_TICKET_SET_STATUS         = '[mytickets] Update ticket (set fetch status)';
export const UPDATE_MY_TICKET_SUBMIT             = '[mytickets] Update ticket (submit)';
export const UPDATE_MY_TICKET_SUCCESS            = '[mytickets] Update ticket success';
export const UPDATE_MY_TICKET_ERROR              = '[mytickets] Update ticket error';
export const UPDATE_MY_TICKET_STATUS             = '[mytickets] Update ticket set status';
export const UPDATE_MY_TICKET_SET_FORM_DATA      = '[mytickets] Update ticket set form data';
export const REMOVE_MY_TICKET_IMAGES             = '[mytickets] Update ticket - Remove images';
export const REMOVE_MY_TICKET_IMAGES_SUCCESS     = '[mytickets] Update ticket - Remove images success';
export const REMOVE_MY_TICKET_IMAGES_ERROR       = '[mytickets] Update ticket - Remove images error';
export const DELETE_MY_TICKET                    = '[mytickets] Delete ticket';
export const DELETE_MY_TICKET_SUCCESS            = '[mytickets] Delete ticket success';
export const DELETE_MY_TICKET_ERROR              = '[mytickets] Delete ticket error';
export const DELETE_MY_TICKET_STATUS             = '[mytickets] Delete ticket set status';


// For fetching tickets
export const getMyTickets = (filter = {}) => ({
    type: GET_MY_TICKETS,
    payload: filter
  });

// For fetching moments
export const fetchMyTicketsSuccess = (data) => ({
  type: FETCH_MY_TICKETS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchMyTicketsError = (data) => ({
  type: FETCH_MY_TICKETS_ERROR,
  payload: data
});



// For fetching tickets for next iteration
export const getNextMyTickets = (filter) => ({
    type: GET_NEXT_MY_TICKETS,
    payload: filter
  });


export const fetchNextMyTicketsSuccess = (data) => ({
    type: FETCH_NEXT_MY_TICKETS_SUCCESS,
    payload: data,
    meta: {
      to_append: true
    }
  });

export const fetchNextMyTicketsError = (data) => ({
    type: FETCH_NEXT_MY_TICKETS_ERROR,
    payload: data
  });
  

export const setMyTickets = (data) => ({
    type   : UPDATE_MY_TICKETS,
    payload: data
  });


// For fetching a single ticket
export const selectMyTicket = (ticket_id) => ({
  type: SELECT_MY_TICKET,
  payload: {product_id: ticket_id}
});


export const fetchSingleMyTicketSuccess = (data) => ({
  type: FETCH_SINGLE_MY_TICKET_SUCCESS,
  payload: data
});

export const fetchSingleMyTicketError = (data) => ({
  type: FETCH_SINGLE_MY_TICKET_ERROR,
  payload: data
});

export const setSingleMyTicket = (data) => ({
  type   : UPDATE_MY_TICKET,
  payload: data
});

export const createMyTicket = (data) => ({
  type   : CREATE_MY_TICKET,
  payload: data
})


export const createMyTicketSuccess = (data) => ({
  type: CREATE_MY_TICKET_SUCCESS,
  payload: data
});

export const createMyTicketError = (data) => ({
  type: CREATE_MY_TICKET_ERROR,
  payload: data
});

export const createMyTicketSetStatus = (data) => ({
  type: CREATE_MY_TICKET_STATUS,
  payload: data
});


export const fetchUpdateTicketData = (ticket_id) => ({
  type: UPDATE_MY_TICKET_FETCH,
  payload: {product_id: ticket_id}
});

export const fetchUpdateTicketDataSuccess = (data) => ({
  type: UPDATE_MY_TICKET_FETCH_SUCCESS,
  payload: data
});

export const fetchUpdateTicketDataError = (data) => ({
  type: UPDATE_MY_TICKET_FETCH_ERROR,
  payload: data
});

export const setUpdateTicketData = (data) => ({
  type: UPDATE_MY_TICKET_SET_DATA,
  payload: data
});

export const setUpdateTicketDataStatus = (data) => ({
  type: UPDATE_MY_TICKET_SET_STATUS,
  payload: data
});


export const updateMyTicketSubmit = () => ({
  type   : UPDATE_MY_TICKET_SUBMIT,  
})


export const updateMyTicketSuccess = (data) => ({
  type: UPDATE_MY_TICKET_SUCCESS,
  payload: data
});

export const updateMyTicketError = (data) => ({
  type: UPDATE_MY_TICKET_ERROR,
  payload: data
});

export const updateMyTicketSetStatus = (data) => ({
  type: UPDATE_MY_TICKET_STATUS,
  payload: data
});

export const updateMyTicketSetFormData = (ticket,removedFiles) => ({
  type: UPDATE_MY_TICKET_SET_FORM_DATA,
  payload: {
    ticket: ticket,
    removeImages: removedFiles
  }
});


export const removeMyTicketImages = (data) => ({
  type: REMOVE_MY_TICKET_IMAGES,
  payload: data
});

export const removeMyTicketSuccess = (data) => ({
  type: REMOVE_MY_TICKET_IMAGES_SUCCESS,
  payload: data
});

export const removeMyTicketError = (data) => ({
  type: REMOVE_MY_TICKET_IMAGES_ERROR,
  payload: data
});




export const deleteMyTicket = (data) => ({
  type   : DELETE_MY_TICKET,
  payload: data
})


export const deleteMyTicketSuccess = (data) => ({
  type: DELETE_MY_TICKET_SUCCESS,
  payload: data
});

export const deleteMyTicketError = (data) => ({
  type: DELETE_MY_TICKET_ERROR,
  payload: data
});

export const deleteMyTicketSetStatus = (data) => ({
  type: DELETE_MY_TICKET_STATUS,
  payload: data
});

