import React from "react";
import ReactStars from "../../../../common/StarRatings/react-stars";

const Rating = ({
  communication_rating,
  description_rating,
  shipping_rating,
  review,
  user_name,
  user_image,
  rating_on,
}) => {
  return (
    <React.Fragment>
      <div className="m-2 d-flex flex-nowrap">
        <div className="mr-2">
          <img
            src={user_image}
            className="rounded-circle"
            alt={user_name}
            width="40"
            height="40"
            style={{ objectFit: "contain", backgroundColor: "#222" }}
          />
        </div>
        <div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="font-weight-bold mr-1">{user_name}</div>
            <div className="msgTime">{rating_on}</div>
          </div>
          <div className="mr-1 d-flex  flex-wrap ">
            <div className=" d-flex mr-4">
              <span>Communication :</span>
              <ReactStars value={communication_rating} />
            </div>
            <div className="d-flex mr-4">
              <span>Description :</span>
              <ReactStars value={description_rating} />
            </div>
            <div className="d-flex">
              Shipping :
              <ReactStars value={shipping_rating} />
            </div>
          </div>
          <div className="d-flex word-break">{review}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Rating;
