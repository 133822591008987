export const INITIATE_CHECKOUT = "[checkout] initiate checkout";
export const INITIATE_CHECKOUT_SUCCESS = "[checkout] initiate checkout success";
export const INITIATE_CHECKOUT_ERROR = "[checkout] initiate checkout Error";
export const SET_CHECKOUT_DETAILS = "[checkout] set checkout details";
export const SET_CHECKOUT_STATUS = "[checkout] set checkout Status";

export const UPDATE_CHECKOUT_SHIPPING_ADDRESS = "[checkout] update checkout shipping address";
export const UPDATE_CHECKOUT_SHIPPING_ADDRESS_SUCCESS = "[checkout] update checkout shipping address success";
export const UPDATE_CHECKOUT_SHIPPING_ADDRESS_ERROR = "[checkout] update checkout shipping address Error";
export const UPDATE_CHECKOUT_SHIPPING_ADDRESS_SET_STATUS = "[checkout] update checkout shipping address set status";
export const SET_CHECKOUT_SHIPPING_ADDRESS = "[checkout] set checkout shipping address";

export const UPDATE_CHECKOUT_BILLING_ADDRESS = "[checkout] update checkout billing address";
export const UPDATE_CHECKOUT_BILLING_ADDRESS_SUCCESS = "[checkout] update checkout billing address success";
export const UPDATE_CHECKOUT_BILLING_ADDRESS_ERROR = "[checkout] update checkout billing address Error";
export const UPDATE_CHECKOUT_BILLING_ADDRESS_SET_STATUS = "[checkout] update checkout billing address set status";
export const SET_CHECKOUT_BILLING_ADDRESS = "[checkout] set checkout billing address";

export const GET_CHECKOUT = "[checkout] GET ";
export const GET_CHECKOUT_SUCCESS = "[checkout] GET_CHECKOUT success";
export const GET_CHECKOUT_ERROR = "[checkout] GET_CHECKOUT Error";

export const SET_NEW_SHIPPING_ADDRESS = "[checkout] SET_NEW_SHIPPING_ADDRESS";
export const SET_NEW_BILLING_ADDRESS = "[checkout] SET_NEW_BILLING_ADDRESS";
export const IS_ADDRESS_MODEL_HIDE = "[checkout] IS_ADDRESS_MODEL_HIDE";

export const ADD_USER_BILLING_ADDRESS = "[checkout] add user billing address";
export const ADD_USER_BILLING_ADDRESS_SUCCESS = "[checkout] add user billing address success";
export const ADD_USER_BILLING_ADDRESS_ERROR = "[checkout] add user billing address Error";
export const ADD_USER_BILLING_ADDRESS_SET_STATUS = "[checkout] add user billing address set status";

export const EDIT_USER_BILLING_ADDRESS = "[checkout] edit user billing address";
export const EDIT_USER_BILLING_ADDRESS_SUCCESS = "[checkout] edit user billing address success";
export const EDIT_USER_BILLING_ADDRESS_ERROR = "[checkout] edit user billing address Error";
export const EDIT_USER_BILLING_ADDRESS_SET_STATUS = "[checkout] edit user billing address set status";

export const ADD_USER_SHIPPING_ADDRESS = "[checkout] add user shipping address";
export const ADD_USER_SHIPPING_ADDRESS_SUCCESS = "[checkout] add user shipping address success";
export const ADD_USER_SHIPPING_ADDRESS_ERROR = "[checkout] add user shipping address Error";
export const ADD_USER_SHIPPING_ADDRESS_SET_STATUS = "[checkout] add user shipping address set status";

export const EDIT_USER_SHIPPING_ADDRESS = "[checkout] edit user shipping address";
export const EDIT_USER_SHIPPING_ADDRESS_SUCCESS = "[checkout] edit user shipping address success";
export const EDIT_USER_SHIPPING_ADDRESS_ERROR = "[checkout] edit user shipping address Error";
export const EDIT_USER_SHIPPING_ADDRESS_SET_STATUS = "[checkout] edit user shipping address set status";

// For fetching moments
export const initiateCheckout = (data) => ({
  type: INITIATE_CHECKOUT,
  payload: data,
});

// For fetching moments
export const initiateCheckoutSuccess = (data) => ({
  type: INITIATE_CHECKOUT_SUCCESS,
  payload: data,
});

export const initiateCheckoutError = (data) => ({
  type: INITIATE_CHECKOUT_ERROR,
  payload: data,
});
export const setCheckoutDetails = (data) => ({
  type: SET_CHECKOUT_DETAILS,
  payload: data,
});
export const setCheckoutStatus = (data) => ({
  type: SET_CHECKOUT_STATUS,
  payload: data,
});

// Address Add For checkout
export const updateCheckoutShippingAddress = (data) => ({
  type: UPDATE_CHECKOUT_SHIPPING_ADDRESS,
  payload: data,
});

// For fetching moments
export const updateCheckoutShippingAddressSuccess = (data) => ({
  type: UPDATE_CHECKOUT_SHIPPING_ADDRESS_SUCCESS,
  payload: data,
});

export const updateCheckoutShippingAddressError = (data) => ({
  type: UPDATE_CHECKOUT_SHIPPING_ADDRESS_ERROR,
  payload: data,
});

export const updateCheckoutShippingAddressSetStatus = (data) => ({
  type: UPDATE_CHECKOUT_SHIPPING_ADDRESS_SET_STATUS,
  payload: data,
});

export const setCheckoutShippingAddress = (data) => ({
  type: SET_CHECKOUT_SHIPPING_ADDRESS,
  payload: data,
});

// Address Add For checkout
export const updateCheckoutBillingAddress = (data) => ({
  type: UPDATE_CHECKOUT_BILLING_ADDRESS,
  payload: data,
});

// For fetching moments
export const updateCheckoutBillingAddressSuccess = (data) => ({
  type: UPDATE_CHECKOUT_BILLING_ADDRESS_SUCCESS,
  payload: data,
});

export const updateCheckoutBillingAddressError = (data) => ({
  type: UPDATE_CHECKOUT_BILLING_ADDRESS_ERROR,
  payload: data,
});

export const updateCheckoutBillingAddressSetStatus = (data) => ({
  type: UPDATE_CHECKOUT_BILLING_ADDRESS_SET_STATUS,
  payload: data,
});

export const setCheckoutBillingAddress = (data) => ({
  type: SET_CHECKOUT_BILLING_ADDRESS,
  payload: data,
});




// get cart Details
export const getCheckoutDetails = (data) => ({
  type: GET_CHECKOUT,
  payload: data,
});

// For fetching moments
export const getCheckoutSuccess = (data) => ({
  type: GET_CHECKOUT_SUCCESS,
  payload: data,
});

export const getCheckoutError = (data) => ({
  type: GET_CHECKOUT_ERROR,
  payload: data,
});

export const AddressModelHide = (data) => ({
  type: IS_ADDRESS_MODEL_HIDE,
  payload: data,
});


// Address Add For checkout
export const addUserBillingAddress = (data) => ({
  type: ADD_USER_BILLING_ADDRESS,
  payload: data,
});

// For fetching moments
export const addUserBillingAddressSuccess = (data) => ({
  type: ADD_USER_BILLING_ADDRESS_SUCCESS,
  payload: data,
});

export const addUserBillingAddressError = (data) => ({
  type: ADD_USER_BILLING_ADDRESS_ERROR,
  payload: data,
});

export const addUserBillingAddressSetStatus = (data) => ({
  type: ADD_USER_BILLING_ADDRESS_SET_STATUS,
  payload: data,
});

// Address Add For checkout
export const addUserShippingAddress = (data) => ({
  type: ADD_USER_SHIPPING_ADDRESS,
  payload: data,
});

// For fetching moments
export const addUserShippingAddressSuccess = (data) => ({
  type: ADD_USER_SHIPPING_ADDRESS_SUCCESS,
  payload: data,
});

export const addUserShippingAddressError = (data) => ({
  type: ADD_USER_SHIPPING_ADDRESS_ERROR,
  payload: data,
});

export const addUserShippingAddressSetStatus = (data) => ({
  type: ADD_USER_SHIPPING_ADDRESS_SET_STATUS,
  payload: data,
});

// Address Add For checkout
export const editUserShippingAddress = (data) => ({
  type: EDIT_USER_SHIPPING_ADDRESS,
  payload: data,
});

// For fetching moments
export const editUserShippingAddressSuccess = (data) => ({
  type: EDIT_USER_SHIPPING_ADDRESS_SUCCESS,
  payload: data,
});

export const editUserShippingAddressError = (data) => ({
  type: EDIT_USER_SHIPPING_ADDRESS_ERROR,
  payload: data,
});

export const editUserShippingAddressSetStatus = (data) => ({
  type: EDIT_USER_SHIPPING_ADDRESS_SET_STATUS,
  payload: data,
});

// Address Add For checkout
export const editUserBillingAddress = (data) => ({
  type: EDIT_USER_BILLING_ADDRESS,
  payload: data,
});

// For fetching moments
export const editUserBillingAddressSuccess = (data) => ({
  type: EDIT_USER_BILLING_ADDRESS_SUCCESS,
  payload: data,
});

export const editUserBillingAddressError = (data) => ({
  type: EDIT_USER_BILLING_ADDRESS_ERROR,
  payload: data,
});

export const editUserBillingAddressSetStatus = (data) => ({
  type: EDIT_USER_BILLING_ADDRESS_SET_STATUS,
  payload: data,
});