import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Spinner } from "react-bootstrap";
import MyOfferCard from "../MyOfferCardComponent/MyOfferCard";
import { Link } from "react-router-dom";

const MyBuyOfferView = ({ offers, hasMore, nextPage, viewOfferDetails }) => {
  return (
    <React.Fragment>
      <Container className="my-4">
        <InfiniteScroll
          dataLength={offers.length}
          next={nextPage}
          hasMore={hasMore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <Spinner as="span" animation="border" role="status" />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {offers.length !== 0 ? (
                <b>No more offers found</b>
              ) : (
                <b>No Offers Found</b>
              )}
            </p>
          }
        >
          {offers.map((offer) => {
            return (
              <Link
                key={offer.offer_no}
                to={"/account/myoffers/" + offer.offer_no}
              >
                <MyOfferCard
                  key={offer.offer_no}
                  offer_no={offer.offer_no}
                  status={offer.status}
                  price={offer.price}
                  viewOfferDetails={viewOfferDetails}
                  offer={offer}
                  name={
                    offer.created_by.is_admin
                      ? "Memory Lane"
                      : offer.created_by.name
                  }
                  avatar={offer.created_by.avatar.image_200}
                />
              </Link>
            );
          })}
        </InfiniteScroll>
      </Container>
    </React.Fragment>
  );
};

export default MyBuyOfferView;
