import React from "react";
import { Form, ListGroup, Button, Spinner } from "react-bootstrap";
import Address from "../AddressViewComponent/AddressViewComponent";

const ShippingAddressChangeForm = ({
  shipping_addresses,
  checkout_address_id,
  editAddress,
  updateCheckoutAddress,
  onChangeAddress,
  cancelChangeAddress,
  isLoading,
  add_Address,
}) => {
  return (
    <>
      <div>
        <div className="my-2">
          <div className="my-1">
            <Button
              onClick={() => {
                add_Address();
              }}
            >
              Add address
            </Button>
          </div>
          <ListGroup>
            {shipping_addresses.map((address) => {
              return (
                <ListGroup.Item key={address.id}>
                  <Form className="d-flex flex-wrap">
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        onChange={() => onChangeAddress(address.id)}
                        id={"address_" + address.id}
                        checked={
                          parseInt(checkout_address_id) === parseInt(address.id)
                        }
                      />
                      <Form.Check.Label htmlFor={"address_" + address.id}>
                        <Address
                          first_name={address.first_name}
                          last_name={address.last_name}
                          address_line_1={address.address_line_1}
                          address_line_2={address.address_line_2}
                          administrative_area={address.administrative_area}
                          city={address.city}
                          country={address.country}
                          mobile_no={address.mobile_no}
                          postal_code={address.postal_code}
                        />
                      </Form.Check.Label>
                    </Form.Check>
                    <div className="ml-auto">
                      <Button
                        onClick={() => {
                          editAddress(address.id);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Form>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
        <div className="d-flex justify-content-end">
          <div className="mr-1">
            <Button
              onClick={() => {
                updateCheckoutAddress();
              }}
              disabled={isLoading}
            >
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  size="sm"
                  className="mr-1"
                />
              )}
              Save
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                cancelChangeAddress();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingAddressChangeForm;
