import React from "react";
import * as ReactBootstarp from "react-bootstrap";
import "./MyCollectionCardComponent.css";
import { Link } from "react-router-dom";
import ArrowIconRight from "../../../images/arrow-right-one.svg";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/delete.svg";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import Badge from "react-bootstrap/Badge";
import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../../js/constants";
import { connect } from "react-redux";
import { getMyTicketRenderStatus } from "../../../js/utils";
import { TICKET_STATES } from "../../../js/constants";

const MyTicketCardComponent = ({
  collection_name,
  collection_id,
  collection,
  product_id,
  status,
  cost,
  onEdit,
  onDelete
}) => {
  const [DeletemodalShow, setDeleteModalShow] = React.useState(false);
  return (
    <React.Fragment>
      <ReactBootstarp.Card className="searchResultCard my-4">
        <ReactBootstarp.Card.Body>
          <ReactBootstarp.Row>
            <ReactBootstarp.Col xs={12} md={6}>
              <div className="searchResultEventName textBlackColor">
                <Link to={"/account/mycollection/" + collection_id} >
                <h5> <i class="fa fa-folder-o" aria-hidden="true"></i>{ " "+collection_name}</h5>
                </Link>
              </div>
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={3}>
              <div className="searchResultEventName textBlackColor">
                 {cost >0?
                 <><i className="fa fa-usd mr-1" aria-hidden="true"></i> {cost}</>
                 :""}
              </div>
            </ReactBootstarp.Col>
            {status !== "CART" ? (
              <ReactBootstarp.Col xs={12} md={3}>
              <div className="d-flex flex-wrap">
                <div>
                  <ReactBootstarp.Button
                    className="btnEdit"
                    onClick={() => onEdit(collection)}
                  >
                    <span>
                      <img src={EditIcon} alt="EditIcon" />
                    </span>
                    Edit
                  </ReactBootstarp.Button>
                </div>
                <div>
                  <ReactBootstarp.Button
                    className="btnDelete"
                    onClick={() => setDeleteModalShow(true)}
                  >
                    <span>
                      <img src={DeleteIcon} alt="DeleteIcon" />
                    </span>
                    Delete
                  </ReactBootstarp.Button>
                </div>
              </div>
            </ReactBootstarp.Col>
            ):(
              <ReactBootstarp.Col>
              <Badge className="badge-success">{status}</Badge>
            </ReactBootstarp.Col>
            )

            }
            
          </ReactBootstarp.Row>
          <DeleteDialog
            id={collection_id}
            name={collection_name}
            show={DeletemodalShow}
            hideModal={() => setDeleteModalShow(false)}
            onDelete={(collection_id) => {
              setDeleteModalShow(false);
              onDelete(collection_id);
            }}
          />
        </ReactBootstarp.Card.Body>
      </ReactBootstarp.Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
          (permission) =>
            permission.permission === USER_PERMISSIONS.SELL &&
            permission.status === USER_PERMISSIONS_STATES.ACTIVE
        )
        : false,
  };
};

export default connect(mapStateToProps, null)(MyTicketCardComponent);
