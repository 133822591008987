
export const API_BASE_URL = "https://www.ticketmemory.com/api/v1";

export const notification_types = {
    info : 'info',
    success : 'success',
    error : 'error',
    warning : 'warning'
}

// Event Form constants
export const EVENT_CONSTANTS = {
    EVENT_NAME_MAX_LENGTH : 500,
    EVENT_SIGNIFICANCE_MAX_LENGTH : 500,
    SUB_GENRE_NAME_LENGTH: 50,
    TEAM_NAME_LENGTH: 50,
    ARTIST_NAME_LENGTH: 50,
    VENUE_NAME_LENGTH: 50,

}

// Ticket Form constants
export const TICKET_CONSTANTS = {
    TICKET_LISTING_NAME_MAX_LENGTH : 1000,
    TICKET_DESCRIPTION_MAX_LENGTH : 5000,
    TICKET_COST_MAX_LENGTH : 15,
    TICKET_FACE_PRICE_MAX_LENGTH : 15,
    TICKET_GRADE_MAX_LENGTH : 50,
    TICKET_SECTION_MAX_LENGTH : 10,
    TICKET_ROW_MAX_LENGTH : 10,
    TICKET_SEAT_NO_MAX_LENGTH : 10,
}

// For all CUD operations
export const OPERATION_STATUS = {
    YET_TO_START: "yet to start",
    PENDING: "pending",
    SUCCESS: "success",
    FAILURE: "failure",
} 

export const APP_STATES = {
    LOADING: "Loading",
    LOADED: "Loaded"
} 

export const USER_STATES = {
    ANONYMOUS: "anonymous user",
    LOGGED_IN: "user logged in",
    LOGGED_OUT: "user logged out"
}

export const PAGE_URLS = {
    HOME: "/home",
    GENRE: "/genre",
    VENUE:"/venue",
    TEAM_ARTIST: "/teamArtist",
    MOMENTS_HISTORY: "/momentHistory",
    TICKETS: "/tickets",
    COLLECTIONS: "/collections"
}

export const USER_PERMISSIONS = {
    SELL: "sell",
    BUY: "buy"
}

export const USER_PERMISSIONS_STATES = {
    ACTIVE: "active",
    INACTIVE: "inactive"
}

export const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PRICE_REGEX = /^[+]?\d*(?:[.]\d*)?$/;

export const FLAGGED_TERM = {
    ERROR_CODE: "ERR0010",
    ERROR_MSG: "All communucation should remain within the platform and outside contact info should not be disclosed"
}

export const MESSAGE_TYPES = {
    OFFER: "OFFER",
    NORMAL: "NORMAL",
}


export const OFFER_TYPES = {
    OFFER: "OFFER",
    COUNTER: "COUNTER_OFFER",
}

export const OFFER_STATES = {
    OPEN: "OPEN",
    ACCEPTED_SELLER: "ACCEPTED (SELLER)",
    ACCEPTED_BUYER: "ACCEPTED (BUYER)",
    REJECTED: "REJECTED",
    CANCELLED: "CANCELLED",
    DROPPED: "DROPPED",
    CART: "CART",
    SOLD: "SOLD",
    IN_CHECK_OUT: "IN_CHECK_OUT",
    IN_ORDER: "IN_ORDER"
}

export const OFFER_ACTIONS = {
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
    CANCEL: "CANCEL"
}
export const ORDER_DELEVERED_STATES = {
    OPEN: "OPEN",
    SHIPPED: "SHIPPED",
    DELIVERED_BUYER: "DELIVERED ACK (BUYER)",
    DELIVERED_SELLER: "DELIVERED ACK (SELLER)",
    DELIVERED_ADMIN: "DELIVERED ACK (ADMIN)",
    RETURNED: "RETURNED",  
}

export const ORDER_STATES = {
    PENDING    : 'PENDING',
    OPEN       : 'OPEN',
    VALIDATED  : 'VALIDATED',
    CANCELLED  : 'CANCELLED',
    ERROR      : 'ERROR',
    STOPPED    : 'STOPPED'
}

export const TICKET_STATES = {
    SELL: "SELL",
    INACTIVE: "INACTIVE",
    SOLD: "SOLD",
    OWNED: "OWNED",
    OFFER: "OFFER",
    CART: "CART",
    IN_CHECK_OUT: "IN_CHECK_OUT",
    IN_ORDER: "IN_ORDER"
}

export const PAGES = {
    TERMSCONDITION: "terms&condition",
    PRIVACY: "privacy policy",
    ABOUT: "about",
    SELLERAGREEMENT: "seller agreement"
}
