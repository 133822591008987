import React from "react";
import BuyOrderCardView from "./BuyOrderListView/BuyOrderCardView";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import BuyFilter from "../BuyOrder/Filter/BuyFilter";

const BuyOrderView = ({
  // BUY ORDER
  hasMore,
  nextPage,
  getBuyerOrderDetailView,
  viewRateByList,
  buyOrderList,
  //filter
  buyerFilterState,
  onApply,
  onClear,
  approveOrderReceived
}) => {
  return (
    <React.Fragment>
      <div className="my-4">
        <BuyFilter
          filters={buyerFilterState}
          onApply={onApply}
          onClear={onClear}
        />

        <InfiniteScroll
          dataLength={buyOrderList.length}
          next={nextPage}
          hasMore={hasMore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <Spinner as="span" animation="border" role="status" />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {buyOrderList.length !== 0 ? (
                <b>No more Orders found</b>
              ) : (
                <b>No Order Found</b>
              )}
            </p>
          }
        >
          {buyOrderList.map((items, idx) => {
            return (
              <BuyOrderCardView
                key={idx}
                getBuyerOrderDetailView={getBuyerOrderDetailView}
                viewRateByList={viewRateByList}
                order_number={items.order_no}
                expiry_date = {items.expiry_date}
                order_status={items.order_status}
                suborder_no={items.suborder_no}
                tracking_number={items.tracking_number}
                image={items.image}
                first_ticket_name={items.first_ticket_name}
                delivery_date={items.delivery_date}
                delivery_status={items.delivery_status}
                seller_name={items.is_admin ? "Memory Lane" : items.seller_name}
                seller_id={items.seller_id}
                is_admin={items.is_admin}
                order_date={items.order_date}
                order_total={items.order_total}
                remaining_items_count={items.remaining_items_count}
                items={items}
                seller_ratings={items.ratings.seller_ratings}
                seller_review={items.review.seller_review}
                payment_paid_status={items.payment_summary.payment_paid_status}
                approveOrderReceived={approveOrderReceived}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};

BuyOrderView.propTypes = {
  buyOrderList: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
};


export default BuyOrderView;
