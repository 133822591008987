import { SET_COLLECTIONS,SET_ADD_COLLECTION,SET_REMOVE_COLLECTION } from "../actions/myCollection";
import produce from 'immer'

const INITIAL_STATE = {
  hasMore: false,
  nextPage: null,
  collections: [],
  addstatus: false,
  removestatus: false
};

export const myCollectionReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_COLLECTIONS:
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      draft.collections = action.payload.collections;
      break;
    case SET_ADD_COLLECTION:
      draft.addstatus = action.payload;
    case SET_REMOVE_COLLECTION:
      draft.removestatus = action.payload;
    default:
      break;
  }
},INITIAL_STATE);
