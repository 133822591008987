import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";

import "./EventInfoComponent.css";

const EventInfoComponent = ({
  listing_name,
  name,
  significance,
  venue,
  date,
  teams,
  artists,
}) => {
  return (
    <React.Fragment>
      <div className="my-3 searchResultTitleDiv ">
        <span>{listing_name}</span>
      </div>
      <h6 className="mb-3">{name}</h6>
      <div className="mb-3">
        <span className="mr-4" title="Event date">
          <i className="fa fa-calendar mr-2" aria-hidden="true"></i> {date}
        </span>
        <span title="Event venue">
          <i className="fa fa-map-marker mr-2" aria-hidden="true"></i> {venue}
        </span>
      </div>
      {teams.length > 0 && (
        <div className="mb-3">
          <span title="Teams">
            <i className="fa fa-users mr-2" aria-hidden="true"></i>
            <span>{teams.join(", ")}</span>
          </span>
        </div>
      )}
      {artists.length > 0 && (
        <div className="mb-3">
          <span title="Artists">
            <i className="fa fa-user  mr-2" aria-hidden="true"></i>
            <span>{artists.join(", ")}</span>
          </span>
        </div>
      )}
      {significance && <div className="mb-3">{significance}</div>}
    </React.Fragment>
  );
};

EventInfoComponent.propTypes = {
  name: PropTypes.string.isRequired,
  significance: PropTypes.string,
  venue: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  teams: PropTypes.array,
  artists: PropTypes.array,
};

export default EventInfoComponent;
