export const GET_GENRES                = '[genres] GET';
export const FETCH_GENRES_SUCCESS      = '[genres] Fetch success';
export const FETCH_GENRES_ERROR        = '[genres] Fetch Error';
export const UPDATE_GENRES             = '[genres] Update';

// For fetching moments
export const getGenres = (data) => ({
    type: GET_GENRES,
    payload: data,
  });

// For fetching moments
export const fetchGenresSuccess = (data) => ({
  type: FETCH_GENRES_SUCCESS,
  payload: data,
});

export const fetchGenresError = (data) => ({
  type: FETCH_GENRES_ERROR,
  payload: data
});


export const setGenres = (data) => ({
    type   : UPDATE_GENRES,
    payload: data
  });