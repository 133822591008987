import React from "react";
import * as ReactBootstarp from "react-bootstrap";
import "./CollectionCard.css";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../../js/constants";
import { connect } from "react-redux";

const CollectionCard = ({
  collection_name,
  collection_id,
  collection,
  canBuy,
  cost,
  addToCart
}) => {
  const [DeletemodalShow, setDeleteModalShow] = React.useState(false);
  return (
    <React.Fragment>
      <ReactBootstarp.Card className="searchResultCard my-4">
        <ReactBootstarp.Card.Body>
          <ReactBootstarp.Row>
            <ReactBootstarp.Col xs={12} md={7}>
              <div className="searchResultEventName textBlackColor">
                <Link to={"/collections/" + collection_id }>
                  <h5> <i class="fa fa-folder-o" aria-hidden="true"></i>{ " "+collection_name}</h5>
                </Link>
              </div>
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={3}>
              {cost > 0 ?
                <><i className="fa fa-usd mr-1" aria-hidden="true"></i> {cost}</>
                : ""}
            </ReactBootstarp.Col>
            <ReactBootstarp.Col xs={12} md={2}>
            {canBuy && (
                        <Link as={Link} to={"/mycart"}>
                        <ReactBootstarp.Badge className="badge-color">
                          <Icon.Cart2
                            color="#ffffff"
                            size={18}
                            onClick={() =>
                              addToCart(collection_id,collection_name)
                            }
                          />
                        </ReactBootstarp.Badge>
                      </Link>
                      )
                      }
            </ReactBootstarp.Col>
          </ReactBootstarp.Row>
        </ReactBootstarp.Card.Body>
      </ReactBootstarp.Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
          (permission) =>
            permission.permission === USER_PERMISSIONS.SELL &&
            permission.status === USER_PERMISSIONS_STATES.ACTIVE
        )
        : false,
  };
};

export default connect(mapStateToProps, null)(CollectionCard);
