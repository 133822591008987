import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { hideModal } from "../../store/actions/ui";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Spinner } from "react-bootstrap";
import { OPERATION_STATUS, USER_STATES, MAIL_REGEX } from "../../js/constants";

function Alertmodel({ showModal,submit,message,hideModal,type,orderLoading }) {
  var initial_rating = {
    type:""
  };
  const [State, setState] = useImmer(initial_rating);
  useEffect(()=>{
    setState((draft) => {
      draft.type= type;
    });
  },[type])
  return (
    <>
      <React.Fragment>
        <ReactBootstrap.Modal
          show={showModal}
          onHide={() => hideModal("alertbox")}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
              <h6 className="font-weight-bold">
               {message}
              </h6>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
            <ReactBootstrap.Modal.Body className="d-flex justify-content-center">
            <ReactBootstrap.Button
                className=" btn-info"
                onClick={()=>submit(State.type)}
              >
                {orderLoading&&(<Spinner
                      as="span"
                      animation="border"
                      role="status"
                      size="sm"
                      className="mr-1"
                    />)}
                 
                Yes
              </ReactBootstrap.Button>
              <ReactBootstrap.Button
                className="btnModelClose"
                onClick={() => hideModal("alertbox")}
              >
                No
              </ReactBootstrap.Button>
            </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    showModal: state.ui.activeModal === "alertbox",
    orderLoading:state.myOrder.alertboxstatus === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, { hideModal})(Alertmodel);
