import { useParams } from "react-router-dom";
import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import ProductCard from "./ProductCard";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getMyTickets } from "../../../store/actions/mytickets";
import {removeCollectionStatus } from "../../../store/actions/myCollection";
const ProductList = ({ tickets, getMyTickets, ticketLoading,removestatus,removeCollectionStatus }) => {
    let { id } = useParams();
    useEffect(() => {
        let data = {
            full_result: 1,
            collection_id: id
        }
        getMyTickets(data)
    }, []);

    useEffect(()=>{
        if(removestatus){
            let data = {
                full_result: 1,
                collection_id: id
            }
            getMyTickets(data)
            removeCollectionStatus(false)
        }
    },[removestatus])
    return (
        <React.Fragment>
            <ReactBootstrap.Container className="my-4">
                <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} md={6}>
                        <h5>Collections</h5>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} md={6} className="text-right">
                        <Link to={"/collections"}>
                            <ReactBootstrap.Button
                                className="btnAddTicket"
                            >
                                Back
                            </ReactBootstrap.Button>
                        </Link>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                <ReactBootstrap.Dropdown.Divider />

                {ticketLoading ? (
                    <div className="d-flex justify-content-center align-items-center my-5">
                        <ReactBootstrap.Spinner animation="border" role="status" />
                    </div>
                ) : (
                    <>
                    { tickets.length !== 0 ? (
                            <>
                                {tickets.map((ticket) => {
                                    return (
                                        <ProductCard
                                            key={ticket.product_id}
                                            product_id={ticket.product_id}
                                            listing_name={ticket.listing_name}
                                            event_name={ticket.event_name}
                                            significance={ticket.significance}
                                            seller={ticket.seller}
                                            image={ticket.image}
                                            venue={ticket.venue}
                                            date={ticket.date}
                                            teams={ticket.teams}
                                            artists={ticket.artists}
                                            cost={ticket.cost}
                                            description={ticket.description}
                                            status={ticket.status}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <p style={{ textAlign: "center" }}>
                                <b>No Tickets Found</b>
                            </p>
                        )
                    }
                    </>
                    
                )
                }


            </ReactBootstrap.Container>
        </React.Fragment>
    )

}
const mapStateToProps = (state) => {
    return {
        tickets: state.mytickets.tickets,
        removestatus:state.collection.removestatus,
        ticketLoading:
            "mytickets_init" in state.ui.loaders
                ? state.ui.loaders["mytickets_init"]
                : true,
    }

}
export default connect(mapStateToProps, { getMyTickets,removeCollectionStatus })(ProductList);