import {
  ADD_ITEM_TO_CART,
  ADD_ITEM_TO_CART_SUCCESS,
  ADD_ITEM_TO_CART_ERROR,
  addItemToCartSuccess,
  addItemToCartError,
  setAddCartItemStatus,
  GET_CART_DETAILS,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_ERROR,
  getCartDetailsSuccess,
  getCartDetailsError,
  setCartDetails,
  setCartDetailsFetchStatus,
  getCartDetails,
  REMOVE_ITEM_FROM_CART,
  REMOVE_ITEM_FROM_CART_SUCCESS,
  REMOVE_ITEM_FROM_CART_ERROR,
  removeItemFromCartSuccess,
  removeItemFromCartError,
  removeItemFromCartSetStatus
} from "../../actions/myCart";

// import { getMyBuyOffers } from "../../actions/myOffer";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
import { formattedMessageDisplayDateTime } from "../../../js/utils";

const processCart = (data) => {
  let cart_details = {
    cart_id: data.cart_id,
    items: data.items.map((item) => {
      return {
        id: item.id,
        type: item.type,
        offer_id: item.offer_id,
        collection_id: item.collection_id,
        expiry_date:formattedMessageDisplayDateTime(item.expiry_date),
        created_date: formattedMessageDisplayDateTime(item.created_date),
        offer_no: item.offer_no,
        collection_name: item.collection_name,
        seller: item.seller,
        base_price: item.base_price.value,
        tax_price: item.tax_price.value,
        details: item.details.map((ticket) => {
          return {
            product_id: ticket.product_id,
            listing_name: ticket.listing_name,
            cost: ticket.cost.value,
            image: ticket.images[0],
          };
        }),
      };
    }),
    price_sub_total: data.total_price.value,
    total_tax: data.total_tax.value,
    grand_total: data.grand_total.value,
    status: data.status,
  };

  return cart_details;
};

// this middleware only care about the get cart details
export const AddItemToCartFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ADD_ITEM_TO_CART) {
      dispatch(setAddCartItemStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "PUT",
          "/user/cart.php",
          null,
          action.payload,
          addItemToCartSuccess,
          addItemToCartError,
          true,
          "[mycart]"
        )
      );

      dispatch(showSpinner("mycart-Add_item"));
    }
  };
// on successful fetch, process the cart data
export const processAddItemToCart = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ADD_ITEM_TO_CART_SUCCESS) {
      dispatch(setAddCartItemStatus(OPERATION_STATUS.SUCCESS));
       console.log(action.payload.data)
      let cart = processCart(action.payload.data);

      dispatch(setCartDetails(cart));
      // dispatch(getMyBuyOffers());
      dispatch(
        setNotification(
          "Item Added to Cart",
          "mycart-add_item",
          notification_types.success
        )
      );
      dispatch(hideSpinner("mycart-Add_item"));
    }

    if (action.type === ADD_ITEM_TO_CART_ERROR) {
      dispatch(setAddCartItemStatus(OPERATION_STATUS.FAILURE));
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "mycart-add_item",
          notification_types.error
        )
      );
      dispatch(hideSpinner("mycart-Add_item"));
    }
  };

// this middleware only care about the get cart details
export const getCartDetailsFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GET_CART_DETAILS) {
      dispatch(setCartDetailsFetchStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "GET",
          "/user/cart.php",
          action.payload,
          null,
          getCartDetailsSuccess,
          getCartDetailsError,
          true,
          "[mycart]"
        )
      );
      dispatch(showSpinner("mycart-get_details"));
    }
  };
// on successful fetch, process the cart data
export const processCartDetails = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GET_CART_DETAILS_SUCCESS) {
      dispatch(setCartDetailsFetchStatus(OPERATION_STATUS.SUCCESS));
      let cart = processCart(action.payload.data);
      dispatch(setCartDetails(cart));
      dispatch(hideSpinner("mycart-get_details"));
    }

    if (action.type === GET_CART_DETAILS_ERROR) {
      dispatch(setCartDetailsFetchStatus(OPERATION_STATUS.FAILURE));
      let cart = {
        cart_id: null,
        items: [],
        price_sub_total: 0,
        total_tax: 0,
        grand_total: 0,
        status: null,
      }
      dispatch(setCartDetails(cart));
      dispatch(hideSpinner("mycart-get_details"));
    }
  };
// DELETE Cart value
export const removeItemFromCartFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === REMOVE_ITEM_FROM_CART) {
      dispatch(removeItemFromCartSetStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "DELETE",
          "/user/cart.php",
          null,
          action.payload,
          removeItemFromCartSuccess,
          removeItemFromCartError,
          true,
          "[mycart]"
        )
      );
      dispatch(showSpinner("mycart-Remove_item"));
    }
  };
// on successful fetch, process the cart data
export const processRemoveItemFromCart = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === REMOVE_ITEM_FROM_CART_SUCCESS) {
      dispatch(removeItemFromCartSetStatus(OPERATION_STATUS.SUCCESS));
      dispatch(
        setNotification(
          "Item Removed From Cart",
          "mycart-remove_item",
          notification_types.success
        )
      );
      dispatch(getCartDetails());
      dispatch(hideSpinner("mycart-Remove_item"));
    }

    if (action.type === REMOVE_ITEM_FROM_CART_ERROR) {
      dispatch(removeItemFromCartSetStatus(OPERATION_STATUS.FAILURE));
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "mycart-Remove_item",
          notification_types.error
        )
      );
      dispatch(hideSpinner("mycart-Remove_item"));
    }
  };
export const myCartMdl = [
  AddItemToCartFlow,
  processAddItemToCart,
  getCartDetailsFlow,
  processCartDetails,
  removeItemFromCartFlow,
  processRemoveItemFromCart,
];
