import {
  UPDATE_MY_TICKETS,
  UPDATE_MY_TICKET,
  FETCH_SINGLE_MY_TICKET_ERROR,
  CREATE_MY_TICKET_STATUS,
  UPDATE_MY_TICKET_SET_DATA,
  UPDATE_MY_TICKET_SET_STATUS,
  UPDATE_MY_TICKET_STATUS,
  UPDATE_MY_TICKET_SET_FORM_DATA,
  DELETE_MY_TICKET_STATUS
} from "../actions/mytickets";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  hasMore: false,
  nextPage: null,
  tickets: [],
  ticket: {
    info: null,
    error: null,
  },
  createticket_status: OPERATION_STATUS.YET_TO_START,
  deleteticket_status: OPERATION_STATUS.YET_TO_START,
  update_ticket: {
    data: null,
    fetch_status: OPERATION_STATUS.YET_TO_START,
    update_status: OPERATION_STATUS.YET_TO_START,
    form_data: null,
  },
};

export const myticketsReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_MY_TICKETS:
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      draft.tickets = action.payload.tickets;
      break;
    case UPDATE_MY_TICKET:
      draft.ticket.info = action.payload;
      draft.ticket.error = null;
      break;
    case FETCH_SINGLE_MY_TICKET_ERROR:
      draft.ticket.info = null;
      draft.ticket.error = action.payload;
      break;
    case CREATE_MY_TICKET_STATUS:
      draft.createticket_status = action.payload;
      break;
    case UPDATE_MY_TICKET_SET_DATA:
      draft.update_ticket.data = action.payload;
      break;
    case UPDATE_MY_TICKET_SET_STATUS:
      draft.update_ticket.fetch_status = action.payload;
      break;
    case UPDATE_MY_TICKET_STATUS:
      draft.update_ticket.update_status = action.payload;
      break;
    case UPDATE_MY_TICKET_SET_FORM_DATA:
      draft.update_ticket.form_data = action.payload;
      break;
    case DELETE_MY_TICKET_STATUS:
      draft.deleteticket_status = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);
