import React from "react";
import {Container,Row,Col,Spinner} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PopularTeamArtist.css";
import PopularCard from "../PopularCard/PopularCard"

function PopularTeamArtist({ popularTeamArtists }) {
  return (
    <React.Fragment>
      <section className="popular-team-art-section py-4">
        <Container>
          <Row className="py-5">
            <Col xs={12} md={8} className="mx-auto ">
              <div className="text-center">
                <h2>Popular Teams/Artists</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit
                </p> */}
              </div>
            </Col>
          </Row>
          {
            popularTeamArtists.isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
              </div>
            ) :
          popularTeamArtists.items.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center  my-5">
              <p>No Team Artists found</p>
            </div>
          ) : (
            <Row className="px-2">
              {popularTeamArtists.items.map((data,idx) => (
                <Col
                xs={12} sm={6} md={3}
                key={idx}
                >
                  <Link
                    as={Link}
                    to={
                      "/tickets?" +
                      (data.is_team ? "team=" : "artist=") +
                      data.id
                    }
                  >
                    <PopularCard 
                    display_name={ data.display_name}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PopularTeamArtist;
