import React, { useEffect, useState } from "react";
//  Main componennt
import CarouselComponent from "./CarouselComponent/CarouselComponent";
import ListComponent from "./ListComponent/ListComponent";
import SellWithUs from "./SellWithUs/SellWithUs"
import BannerComponent from "./BannerComponent/BannerComponent";
import { getTickets } from "../../store/actions/tickets";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
import {
  addItemToCart,
  setAddCartItemStatus,
} from "../../store/actions/myCart";
import { showModal } from "../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../js/constants";

const HomePage = ({
  tickets,
  getTickets,
  ticketLoading,
  addItemToCart,
  itemAddStatus,
  setAddCartItemStatus,
  isItemAddToCartLoading,
  user_id,
  isLogged,
  showModal,
  user_status,
  history
}) => {
  // const history = useHistory();
  const [isUserLogged,setUserLogged] = useState(false);
  useEffect(() => {
    getTickets({ is_featured: true });
  }, []);

  useEffect(() => {
    setUserLogged(isLogged);
  }, [isLogged]);

  useEffect(() => {
    if (itemAddStatus === OPERATION_STATUS.SUCCESS) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/mycart",
      });
    } else if (itemAddStatus === OPERATION_STATUS.FAILURE) {
      setAddCartItemStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [itemAddStatus]);

  const addCart = (product_id, listing_name) => {
    if (user_status === USER_STATES.LOGGED_IN) {
      var data = {
        type: "BUY",
        product: {
          id: product_id,
          name: listing_name,
        },
      };
      addItemToCart(data);
    } else {
      showModal("login");
    }
  };

  return (
    <React.Fragment>
      <BannerComponent />
      <CarouselComponent 
        tickets={tickets}
        ticketLoading={ticketLoading}
        addToCart={addCart}
        isItemAddToCartLoading={isItemAddToCartLoading}
        user_id={user_id}
      />
      <ListComponent />
      <SellWithUs />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    tickets: state.ticket.tickets,
    ticketLoading:
      "ticket_init" in state.ui.loaders
        ? state.ui.loaders["ticket_init"]
        : true,
    user_id: state.user ? state.user.id : null,
    itemAddStatus: state.cart.add_status,
    isItemAddToCartLoading: state.cart.add_status === OPERATION_STATUS.PENDING,
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
    user_status: state.userState.user_status
  };
};
export default connect(mapStateToProps, {
  getTickets,
  showModal,
  addItemToCart,
  setAddCartItemStatus,
})(HomePage);


// export default HomePage;
