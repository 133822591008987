import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';

import '../../common/FilterMenuComponent/FilterMenuComponent.css';

function FilterMenuComponent () {
    return (
        <React.Fragment>
            <nav className="nav d-flex justify-content-between">
                <ReactBootstrap.Navbar.Text className="filterNavTeam">
                    Filter
                </ReactBootstrap.Navbar.Text>
            </nav>
        </React.Fragment>
    )
}

export default FilterMenuComponent;