import { 
    GET_ALL_COLLECTIONS,
    GET_ALL_COLLECTIONS_SUCCESS,
    GET_ALL_COLLECTIONS_ERROR,
    getAllCollectionsSuccess,
    getAllCollectionsError,
    setAllCollections,

    GET_ALL_NEXT_COLLECTIONS,
    GET_ALL_NEXT_COLLECTIONS_SUCCESS,
    GET_ALL_NEXT_COLLECTIONS_ERROR,
    getAllNextCollectionsSuccess,
    getAllNextCollectionsError,

} from "../../actions/collections";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
export const getAllCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_ALL_COLLECTIONS) {
      dispatch(
        apiRequest(
          "GET",
          "/collections.php",
          action.payload,
          null,
          getAllCollectionsSuccess,
          getAllCollectionsError,
          true,
          "[mycollection]"
        )
      );
      dispatch(showSpinner("collection_init"));
    }
  };
  
  export const processGetAllCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_ALL_COLLECTIONS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
          if (page) {
             hasMore = true;
          }
      }
      const newData = {
        collections: action.payload.data.items,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setAllCollections(newData));
      dispatch(hideSpinner("collection_init"));
    }
    if (action.type === GET_ALL_COLLECTIONS_ERROR) {
      dispatch(hideSpinner("collection_init"));
    }
  };
  export const getNextAllCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_ALL_NEXT_COLLECTIONS) {
      dispatch(
        apiRequest(
          "GET",
          "/collections.php",
          action.payload,
          null,
          getAllNextCollectionsSuccess,
          getAllNextCollectionsError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processGetNextAllCollection = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_ALL_NEXT_COLLECTIONS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
          if (page) {
             hasMore = true;
          }
      }
      const prevtickets = getState().allcollection.collections;
    const currtickets = [...prevtickets, ...action.payload.data.items];
      const newData = {
        collections:currtickets,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setAllCollections(newData));
      
    }
    if (action.type === GET_ALL_NEXT_COLLECTIONS_ERROR) {
    }
  };

export const collectionMdl = [
    getAllCollectionFlow,
    processGetAllCollection,
    getNextAllCollectionFlow,
    processGetNextAllCollection
]