export const ADD_BUYER_RATING                = '[rating] Add BUYER';
export const ADD_BUYER_RATING_SUCCESS        = '[rating] AddRating BUYER success ';
export const ADD_BUYER_RATING_ERROR          = '[rating] AddRating BUYER Error';
export const SET_RATING                      = '[rating] SetRating';
export const SET_BUYER_RATING                = '[rating] SetRating BUYER ';
export const SET_BUYER_RATING_STATUS         = '[rating] SetRatingStatus BUYER ';


export const ADD_SELLER_RATING                = '[rating] Add SELLER';
export const ADD_SELLER_RATING_SUCCESS        = '[rating] AddRating SELLER success';
export const ADD_SELLER_RATING_ERROR          = '[rating] AddRating SELLER Error';
export const SET_SELLER_RATING                = '[rating] SetRating SELLER';
export const SET_SELLER_RATING_STATUS         = '[rating] SetRatingStatus SELLER ';

export const ADD_BUYER_REVIEW                = '[rating] Add Review BUYER';
export const ADD_BUYER_REVIEW_SUCCESS        = '[rating] AddReview BUYER success ';
export const ADD_BUYER_REVIEW_ERROR          = '[rating] AddReview BUYER Error';
export const SET_REVIEW                      = '[rating] SetReview';
export const SET_BUYER_REVIEW                = '[rating] SetReview BUYER ';
export const SET_BUYER_REVIEW_STATUS         = '[rating] SetReviewStatus BUYER ';


export const ADD_SELLER_REVIEW                = '[rating] Add Review SELLER';
export const ADD_SELLER_REVIEW_SUCCESS        = '[rating] AddReview SELLER success';
export const ADD_SELLER_REVIEW_ERROR          = '[rating] AddReview SELLER Error';
export const SET_SELLER_REVIEW                = '[rating] SetReview SELLER';
export const SET_SELLER_REVIEW_STATUS         = '[rating] SetReviewStatus SELLER ';

export const GET_REVIEW_LIST                  ='[rating] fetch review list';
export const GET_REVIEW_LIST_SUCCESS          ='[rating] fetch review list success';
export const GET_REVIEW_LIST_ERROR            ='[rating] fetch review list error';
export const SET_REVIEW_LIST                  ='[rating] set review list';

export const GET_NEXT_REVIEW_LIST                  ='[rating] fetch review next list';
export const GET_NEXT_REVIEW_LIST_SUCCESS          ='[rating] fetch review next list success';
export const GET_NEXT_REVIEW_LIST_ERROR            ='[rating] fetch review next list error';

export const GET_SELLER_RATINGS_LIST               ='[rating] fetch seller ratings list';
export const GET_SELLER_RATINGS_LIST_SUCCESS       ='[rating] fetch seller ratings success';
export const GET_SELLER_RATINGS_LIST_ERROR         ='[rating] fetch seller ratings error';
export const SET_SELLER_RATINGS_LIST               ='[rating] set seller ratings  ';


export const GET_SELLER_NEXT_RATINGS_LIST           ='[rating] fetch seller next ratings list';
export const GET_SELLER_NEXT_RATINGS_LIST_SUCCESS   ='[rating] fetch seller next ratings success';
export const GET_SELLER_NEXT_RATINGS_LIST_ERROR     ='[rating] fetch seller next ratings error';

export const GET_SELLER_REPORT_TYPES                ='[rating] fetch seller report types';
export const GET_SELLER_REPORT_TYPES_SUCCESS        ='[rating] fetch seller report types success';
export const GET_SELLER_REPORT_TYPES_ERROR          ='[rating] fetch seller report types error';
export const SET_SELLER_REPORT_TYPES_LIST           ='[rating] set seller report types';


// For fetching moments
export const addBuyerRating= (data) => ({
    type: ADD_BUYER_RATING,
    payload: data,
  });

// For fetching moments
export const addBuyerRatingSuccess = (data) => ({
  type: ADD_BUYER_RATING_SUCCESS,
  payload: data,
});

export const addBuyerRatingError = (data) => ({
  type: ADD_BUYER_RATING_ERROR,
  payload: data
});

export const setBuyerRating = (data) => ({
  type   : SET_BUYER_RATING,
  payload: data
});
export const setBuyerRatingStatus = (data) => ({
  type   : SET_BUYER_RATING_STATUS,
  payload: data
});


export const resetRating = (data) => ({
    type   : SET_RATING,
    payload: data
  });
  

  // For fetching moments
export const addSellerRating= (data) => ({
  type: ADD_SELLER_RATING,
  payload: data,
});

// For fetching moments
export const addSellerRatingSuccess = (data) => ({
type: ADD_SELLER_RATING_SUCCESS,
payload: data,
});

export const addSellerRatingError = (data) => ({
type: ADD_SELLER_RATING_ERROR,
payload: data
});
export const setSellerRating =(data) =>({
  type:SET_SELLER_RATING,
  payload: data
})
export const setSellerRatingStatus = (data) => ({
  type   : SET_SELLER_RATING_STATUS,
  payload: data
});

   // This section For Review
export const addBuyerReview= (data) => ({
  type: ADD_BUYER_REVIEW,
  payload: data,
});

export const addBuyerReviewSuccess = (data) => ({
type: ADD_BUYER_REVIEW_SUCCESS,
payload: data,
});

export const addBuyerReviewError = (data) => ({
type: ADD_BUYER_REVIEW_ERROR,
payload: data
});

export const setBuyerReview = (data) => ({
type   : SET_BUYER_REVIEW,
payload: data
});
export const setBuyerReviewStatus = (data) => ({
type   : SET_BUYER_REVIEW_STATUS,
payload: data
});


export const resetReview = (data) => ({
  type   : SET_REVIEW,
  payload: data
}); 

// review for seller
export const addSellerReview= (data) => ({
  type: ADD_SELLER_REVIEW,
  payload: data,
});

// For fetching moments
export const addSellerReviewSuccess = (data) => ({
type: ADD_SELLER_REVIEW_SUCCESS,
payload: data,
});

export const addSellerReviewError = (data) => ({
type: ADD_SELLER_REVIEW_ERROR,
payload: data
});
export const setSellerReview =(data) =>({
  type:SET_SELLER_REVIEW,
  payload: data
})
export const setSellerReviewStatus = (data) => ({
  type   : SET_SELLER_REVIEW_STATUS,
  payload: data
});


export const getReviewList = () => ({
  type   : GET_REVIEW_LIST,
});

export const getReviewListSuccess = (data) => ({
  type: GET_REVIEW_LIST_SUCCESS,
  payload: data,
  });
  
  export const getReviewListError = (data) => ({
  type: GET_REVIEW_LIST_ERROR,
  payload: data
  });
  export const setReviewList =(data) =>({
    type:SET_REVIEW_LIST,
    payload: data
  }) 

  //get next review list
  export const getNextReviewList = (data) => ({
    type   : GET_NEXT_REVIEW_LIST,
    payload: data,
  });
  
  export const getNextReviewListSuccess = (data) => ({
    type: GET_NEXT_REVIEW_LIST_SUCCESS,
    payload: data,
    });
    
    export const getNextReviewListError = (data) => ({
    type: GET_NEXT_REVIEW_LIST_ERROR,
    payload: data
    });


    //seller ratings
    export const getSellerRatingsList = (data) => ({
      type   : GET_SELLER_RATINGS_LIST,
      payload: data
    });
    
    export const getSellerRatingsListSuccess = (data) => ({
      type: GET_SELLER_RATINGS_LIST_SUCCESS,
      payload: data,
      });
      
      export const getSellerRatingsListError = (data) => ({
      type: GET_SELLER_RATINGS_LIST_ERROR,
      payload: data
      });
      export const setSellerRatingsList =(data) =>({
        type:SET_SELLER_RATINGS_LIST,
        payload: data
      })

      export const getNextSellerRatingsList = (data) => ({
        type   : GET_SELLER_NEXT_RATINGS_LIST,
        payload: data
      });
      
      export const getNextSellerRatingsListSuccess = (data) => ({
        type: GET_SELLER_NEXT_RATINGS_LIST_SUCCESS,
        payload: data,
        });
        
        export const getNextSellerRatingsListError = (data) => ({
        type: GET_SELLER_NEXT_RATINGS_LIST_ERROR,
        payload: data
        });

       export const getReportTypes = () => ({
        type: GET_SELLER_REPORT_TYPES
       });

       export const getReportTypesSuccess = (data) => ({
        type: GET_SELLER_REPORT_TYPES_SUCCESS,
        payload: data,
        });
        
        export const getReportTypesListError = (data) => ({
        type: GET_SELLER_REPORT_TYPES_ERROR,
        payload: data
        });

        export const setReportTypesList = (data) => ({
        type: SET_SELLER_REPORT_TYPES_LIST,
        payload: data
        });
