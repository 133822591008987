import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Badge,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../../../store/actions/ui";
import { getDeliveredStatus } from "../../../../js/utils";
import { ORDER_STATES, ORDER_DELEVERED_STATES } from "../../../../js/constants";
import AddressViewComponent from "../../AddressViewComponent/AddressViewComponent";
import PricingDetails from "./PricingDetails";
import PaymentDetails from "./PaymentDetails";
import ShippingDetails from "./ShippingDetails";
import TrackingDetails from "./TrackingDetails";
import ReviewDetails from "./ReviewDetails";


function BuyOrderDetailsView({
  
  backToMainFromDetail,
  // ******Display values*********
  order_no,
  order_date,
  order_status,
  buyer_name,
  seller_name,
  seller_id,
  delivery_expected_date,
  delivery_status,
  base_price,
  tax_price,
  delivery_charge,
  total_price,
  line_items,
  shipping_add,
  billing_add,
  seller_ratings,
  buyer_ratings,
  buyer_review,
  seller_review,
  is_admin,
  payment_paid_status,
  payment_type,
  payment_date,
  payment_status,
  carrier,
  service_name,
  tracking_id,
  onEditRating,
  onAddRating
  //*************************** */
}) {
  return (
    <React.Fragment>
      <Button
        className="btn btn-primary my-1"
        onClick={backToMainFromDetail}
        title="Go back"
      >
        <i className="fa fa-chevron-left"></i>
      </Button>

      <Card className="mb-4">
        <Card.Body>
          <div>
            <strong>Order No: </strong>
            {order_no}
          </div>

          {order_status === ORDER_STATES.CANCELLED && (
            <div>
              <strong>Order status: </strong>
              <Badge className="badge-danger">Order Cancelled </Badge>
            </div>
          )}
          <div>
            <strong>Order On: </strong>
            {order_date}
          </div>
          {order_status === ORDER_STATES.OPEN ||
            (order_status === ORDER_STATES.VALIDATED && (
              <div>
                <strong>Delivery Status: </strong>
                {getDeliveredStatus(delivery_status)}
              </div>
            ))}

          <Dropdown.Divider />
          <ReviewDetails
            buyer_name={buyer_name}
            seller_name={seller_name}
            seller_ratings={seller_ratings}
            buyer_ratings={buyer_ratings}
            buyer_review={buyer_review}
            seller_review={seller_review}
            is_admin={is_admin}
            onEditRating={onEditRating}
            onAddRating={onAddRating}
            delivery_status={delivery_status}
          />
          <TrackingDetails
            delivery_expected_date={delivery_expected_date}
            delivery_status={delivery_status}
            carrier={carrier}
            service_name={service_name}
            tracking_id={tracking_id}
          />
          <Dropdown.Divider />

          <ShippingDetails
            line_items={line_items}
            seller_name={is_admin ? "Memory Lane" : seller_name}
            seller_id={seller_id}
          />
          <Dropdown.Divider />
          <Row>
            <Col>
              <strong>Shipping Address</strong>
              {shipping_add !== null && (
                <AddressViewComponent
                  first_name={shipping_add.first_name}
                  last_name={shipping_add.last_name}
                  address_line_1={shipping_add.address_line_1}
                  address_line_2={shipping_add.address_line_2}
                  administrative_area={shipping_add.administrative_area}
                  city={shipping_add.city}
                  country={shipping_add.country}
                  postal_code={shipping_add.postal_code}
                  mobile_no={shipping_add.mobile_no}
                />
              )}
            </Col>

            <Col>
              <strong>Billing Address</strong>
              {billing_add !== null && (
                <AddressViewComponent
                  first_name={billing_add.first_name}
                  last_name={billing_add.last_name}
                  address_line_1={billing_add.address_line_1}
                  address_line_2={billing_add.address_line_2}
                  administrative_area={billing_add.administrative_area}
                  city={billing_add.city}
                  country={billing_add.country}
                  postal_code={billing_add.postal_code}
                  mobile_no={billing_add.mobile_no}
                />
              )}
            </Col>
          </Row>
          <Dropdown.Divider />
          <PricingDetails
            tax_price={tax_price}
            base_price={base_price}
            delivery_charge={delivery_charge}
            total_price={total_price}
          />
          <Dropdown.Divider />
          <PaymentDetails
            payment_type={payment_type}
            payment_date={payment_date}
            payment_status={payment_status}
            payment_paid_status={payment_paid_status}
          />
        </Card.Body>
        {/* <div className=" d-flex justify-content-center p-2">
          <button
            type="button"
            className="btn btn-outline-primary waves-effect"
          >
            <i className="fa fa-download mr-2"></i>Download Invoice
          </button>
        </div> */}
      </Card>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  showModal,
})(BuyOrderDetailsView);
