import {
  INITIATE_ORDER, 
  INITIATE_ORDER_SUCCESS,
  INITIATE_ORDER_ERROR, 
  initiateOrderSuccess,
  initiateOrderError,
  setinitiateOrder,
  setOrderStatus,

  GET_MY_BUY_ORDERS,
  FETCH_MY_BUY_ORDERS_SUCCESS,
  FETCH_MY_BUY_ORDERS_ERROR,
  fetchMyBuyOrdersSuccess,
  fetchMyBuyOrdersError,
  GET_NEXT_MY_BUY_ORDERS,
  FETCH_NEXT_MY_BUY_ORDERS_SUCCESS,
  FETCH_NEXT_MY_BUY_ORDERS_ERROR,
  fetchNextMyBuyOrdersSuccess,
  fetchNextMyBuyOrdersError,
  setMyBuyOrders,
  GET_MY_SELL_ORDERS,
  FETCH_MY_SELL_ORDERS_SUCCESS,
  FETCH_MY_SELL_ORDERS_ERROR,
  fetchMySellOrdersSuccess,
  fetchMySellOrdersError,
  GET_NEXT_MY_SELL_ORDERS,
  FETCH_NEXT_MY_SELL_ORDERS_SUCCESS,
  FETCH_NEXT_MY_SELL_ORDERS_ERROR,
  fetchNextMySellOrdersSuccess,
  fetchNextMySellOrdersError,
  setMySellOrders,
  getMyBuyOrders,
  getMySellOrders,

  ADD_TRACKING_INFO_DETAILS ,
  ADD_TRACKING_INFO_DETAILS_SUCCESS,
  ADD_TRACKING_INFO_DETAILS_ERROR, 
  addTrackingInformationSuccess,
  addTrackingInformationError,
  

  EDIT_TRACKING_INFO_DETAILS ,
  EDIT_TRACKING_INFO_DETAILS_SUCCESS,
  EDIT_TRACKING_INFO_DETAILS_ERROR, 
  editTrackingInformationSuccess,
  editTrackingInformationError,
  setTrackingDetails,
  setTrackingInfoStatus,


  APPROVE_ORDER_DELIVERED,
  APPROVE_ORDER_DELIVERED_SUCCESS,
  APPROVE_ORDER_DELIVERED_ERROR,
  approveOrderDeliveredSuccess,
  approveOrderDeliveredError,
  approveOrderDeliveredSetStatus,

  APPROVE_ORDER_RECEIVED,
  APPROVE_ORDER_RECEIVED_SUCCESS,
  APPROVE_ORDER_RECEIVED_ERROR,
  approveOrderReceivedSuccess,
  approveOrderReceivedError,
  approveOrderReceivedSetStatus,

  alertModelStatus
 
} from "../../actions/myOrder";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { OPERATION_STATUS,notification_types } from "../../../js/constants";
import { apiRequest } from "../../actions/api";
import { setNotification } from "../../actions/notification";
import { hideModal } from "../../actions/ui";
import { formattedDate,formattedInputDate,formattedMessageDisplayDateTime } from "../../../js/utils";
import {getCartDetails} from "../../actions/myCart"


//************************************INITIATE ORDER****************************************** */
export const initiateOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === INITIATE_ORDER) {
    dispatch(setOrderStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/orders/order.php",
        null,
        action.payload,
        initiateOrderSuccess,
        initiateOrderError,
        true,
        "[order]"
      )
    );
    dispatch(showSpinner("placeOrder"));
  }
};
export const processInitiateOrderCollection = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === INITIATE_ORDER_SUCCESS) {
    let buyOrderDetaildata = action.payload.data
    dispatch(getCartDetails())
    dispatch(setinitiateOrder(buyOrderDetaildata));
    dispatch(setOrderStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("placeOrder"));
  }

  if (action.type === INITIATE_ORDER_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "placeOrder",
        notification_types.error
      )
    );
    dispatch(setOrderStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("placeOrder"));
  }
};
//************************************BUYER************************************************** */
export const getMyBuyOrdersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_MY_BUY_ORDERS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/orders/order.php",
        action.payload,
        null,
        fetchMyBuyOrdersSuccess,
        fetchMyBuyOrdersError,
        true,
        "[myorders]"
      )
    );
    dispatch(showSpinner("mybuyorder_init"));
  }
  if (action.type === GET_NEXT_MY_BUY_ORDERS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/orders/order.php",
        action.payload,
        null,
        fetchNextMyBuyOrdersSuccess,
        fetchNextMyBuyOrdersError,
        true,
        "[myorders]"
      )
    );
    dispatch(showSpinner("mybuyorder_next"));
  }

};
// on successful fetch, process the books data
export const processMyBuyOrderCollection = ({ dispatch,getState }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_MY_BUY_ORDERS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }

    var FinalBuyerList = []
    let orders = action.payload.data.items;
    orders.forEach((buyorder) => { 
        var order_number = buyorder.order_no
        var order_status = buyorder.order_status
        var expiry_date = formattedMessageDisplayDateTime(buyorder.expiry_date)
        var order_date = formattedDate(buyorder.ordered_at)
        var order_total = buyorder.pricing_summary.total.value
        var buyer_name = buyorder.buyer.name
        var buyer_id = buyorder.buyer.id
        var payment_summary = {
          payment_method : buyorder.payment_summary.payments[0].method,
          payment_amount: buyorder.payment_summary.payments[0].amount.value,
          payment_date : buyorder.payment_summary.payments[0].date,
          payment_paid_status : buyorder.payment_summary.payments[0].is_paid,
          payment_status : buyorder.payment_summary.payments[0].status,
        }
        var pricing_summary = {
					base_price : buyorder.pricing_summary.price_subtotal.value,
					tax_price : buyorder.pricing_summary.tax.value,
					delivery_charge : buyorder.pricing_summary.delivery.value,
					total_price : buyorder.pricing_summary.total.value,
        }
        var shipping_add = {
					first_name : buyorder.shipping_address.first_name,
					last_name : buyorder.shipping_address.last_name,
					address_line_1 : buyorder.shipping_address.address_line_1,
					address_line_2 : buyorder.shipping_address.address_line_2,
					administrative_area : buyorder.shipping_address.administrative_area,
					city : buyorder.shipping_address.city,
					country : buyorder.shipping_address.country,
					postal_code : buyorder.shipping_address.postal_code,
					mobile_no : buyorder.shipping_address.mobile_no,
				}
      	var billing_add = {
					first_name : buyorder.billing_address.first_name,
					last_name : buyorder.billing_address.last_name,
					address_line_1 : buyorder.billing_address.address_line_1,
					address_line_2 : buyorder.billing_address.address_line_2,
					administrative_area : buyorder.billing_address.administrative_area,
					city : buyorder.billing_address.city,
					country : buyorder.billing_address.country,
					postal_code : buyorder.billing_address.postal_code,
					mobile_no : buyorder.billing_address.mobile_no,
				}
      	buyorder.sub_orders.forEach((subOrderItem) => {
					FinalBuyerList.push({
						order_no : order_number,
						order_status : order_status,
            expiry_date: expiry_date,
						order_date : order_date,
						payment_summary : payment_summary,
						pricing_summary : pricing_summary,
						suborder_no : subOrderItem.id,
						carrier : subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.shipping_carrier_code :"",
						service_name : subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.shipping_service_code :"",
						tracking_number:subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.tracking_number :"",
						first_ticket_name:subOrderItem.lineitems[0].item_details[0].listing_name,
						image:subOrderItem.lineitems[0].item_details[0].images[0].image_url,
						remaining_items_count:subOrderItem.lineitems.length-1,
						delivery_date:subOrderItem.fulfillment_information.shipment?formattedDate(subOrderItem.fulfillment_information.shipment.estimated_delivery_at)  :"", 
						line_items:subOrderItem.lineitems,
						delivery_status:subOrderItem.fulfillment_information.status,
						buyer_name:buyer_name,
						buyer_id:buyer_id,
						seller_name:subOrderItem.seller.name,
						is_admin:subOrderItem.seller.is_admin,
						seller_id:subOrderItem.seller.id,
						order_total: order_total,
						shipping_add: shipping_add,
						billing_add:billing_add,
						ratings:{
							buyer_ratings:
              {
                communication_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[0].value:"",
                description_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[1].value:"",
                shipping_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[2].value:""
               },
            
							seller_ratings:{
              communication_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[0].value:"",
              description_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[1].value:"",
              shipping_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[2].value:""
            }
					
						},
						review:{
							buyer_review:subOrderItem.review.buyer?subOrderItem.review.buyer.content:"",
							seller_review:subOrderItem.review.seller?subOrderItem.review.seller.content:"",
						},
					});
      });
    });
    const newData = {
      orders: FinalBuyerList,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMyBuyOrders(newData));
    dispatch(hideSpinner("mybuyorder_init"));
  }

  if (action.type === FETCH_MY_BUY_ORDERS_ERROR) {
    dispatch(hideSpinner("mybuyorder_init"));
  }
// next list for buyer order

	if (action.type === FETCH_NEXT_MY_BUY_ORDERS_SUCCESS) {
		let hasMore = false;
		let page = null;
		if (action.payload.data.next) {
			let next_url = new URL(action.payload.data.next);
			page = next_url.searchParams.get("page");
			if (page) {
				hasMore = true;
			}
		}
		var NextList =[]
		let orders = action.payload.data.items;
		orders.forEach((buyorder) => { 
			var  order_number =buyorder.order_no
			var  order_status =buyorder.order_status
			var  order_date =  formattedDate(buyorder.ordered_at)
			var order_total = buyorder.pricing_summary.total.value
			var buyer_name =buyorder.buyer.name
			var buyer_id = buyorder.buyer.id
			var payment_summary ={
				payment_method : buyorder.payment_summary.payments[0].method,
				payment_amount: buyorder.payment_summary.payments[0].amount.value,
				payment_date : buyorder.payment_summary.payments[0].date,
				payment_paid_status : buyorder.payment_summary.payments[0].is_paid,
				payment_status : buyorder.payment_summary.payments[0].status,
			}
			var pricing_summary=
			{
				base_price : buyorder.pricing_summary.price_subtotal.value,
				tax_price : buyorder.pricing_summary.tax.value,
				delivery_charge : buyorder.pricing_summary.delivery.value,
				total_price : buyorder.pricing_summary.total.value,
			}
			var shipping_add={
				first_name:buyorder.shipping_address.first_name,
				last_name:buyorder.shipping_address.last_name,
				address_line_1:buyorder.shipping_address.address_line_1,
				address_line_2:buyorder.shipping_address.address_line_2,
				administrative_area:buyorder.shipping_address.administrative_area,
				city:buyorder.shipping_address.city,
				country:buyorder.shipping_address.country,
				postal_code:buyorder.shipping_address.postal_code,
				mobile_no:buyorder.shipping_address.mobile_no,
			}
			var billing_add={
				first_name:buyorder.billing_address.first_name,
				last_name:buyorder.billing_address.last_name,
				address_line_1:buyorder.billing_address.address_line_1,
				address_line_2:buyorder.billing_address.address_line_2,
				administrative_area:buyorder.billing_address.administrative_area,
				city:buyorder.billing_address.city,
				country:buyorder.billing_address.country,
				postal_code:buyorder.billing_address.postal_code,
				mobile_no:buyorder.billing_address.mobile_no,
			}
			buyorder.sub_orders.forEach((subOrderItem) => {
			NextList.push({
				order_no:order_number,
					order_status:order_status,
					order_date: order_date,
					payment_summary:payment_summary,
					pricing_summary:pricing_summary,
					suborder_no:subOrderItem.id,
					carrier : subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.shipping_carrier_code :"",
					service_name : subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.shipping_service_code :"",
					tracking_number:subOrderItem.fulfillment_information.shipment?subOrderItem.fulfillment_information.shipment.tracking_number :"",
					first_ticket_name:subOrderItem.lineitems[0].item_details[0].listing_name,
					image:subOrderItem.lineitems[0].item_details[0].images[0].image_url,
					remaining_items_count:subOrderItem.lineitems.length-1,
					delivery_date:subOrderItem.fulfillment_information.shipment?formattedDate(subOrderItem.fulfillment_information.shipment.estimated_delivery_at)  :"", 
					line_items:subOrderItem.lineitems,
					delivery_status:subOrderItem.fulfillment_information.status,
					buyer_name:buyer_name,
					buyer_id:buyer_id,
					seller_name:subOrderItem.seller.name,
					is_admin:subOrderItem.seller.is_admin,
					seller_id:subOrderItem.seller.id,
					order_total: order_total,
					shipping_add: shipping_add,
					billing_add:billing_add,
					ratings:{
            buyer_ratings:
          {
            communication_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[0].value:"",
            description_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[1].value:"",
            shipping_rating:subOrderItem.ratings.buyer?subOrderItem.ratings.buyer.records[2].value:""
           },
          seller_ratings:{
            communication_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[0].value:"",
            description_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[1].value:"",
            shipping_rating:subOrderItem.ratings.seller?subOrderItem.ratings.seller.records[2].value:""
          }
          },
          review:{
            buyer_review:subOrderItem.review.buyer?subOrderItem.review.buyer.content:"",
            seller_review:subOrderItem.review.seller?subOrderItem.review.seller.content:"",
          },
				});
			});
		});
		const prevOrder = getState().myOrder.buyOrderList.order_list;
		const currtickets = [...prevOrder, ...NextList];
		const newData = {
			orders: currtickets,
			hasMore: hasMore,
			page: page,
		};
		dispatch(setMyBuyOrders(newData));
		dispatch(hideSpinner("mybuyorder_next"));
	}

	if (action.type === FETCH_NEXT_MY_BUY_ORDERS_ERROR) {
		dispatch(hideSpinner("mybuyorder_next"));
	}
  
};
export const getMySellOrdersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_MY_SELL_ORDERS) {
    dispatch(
      apiRequest(
        "GET",
        "/seller/orders/order.php",
        action.payload,
        null,
        fetchMySellOrdersSuccess,
        fetchMySellOrdersError,
        true,
        "[myorder]"
      )
    );
    dispatch(showSpinner("mysellorder_init"));
  }
  if (action.type === GET_NEXT_MY_SELL_ORDERS) {
    dispatch(
      apiRequest(
        "GET",
        "/seller/orders/order.php",
        action.payload,
        null,
        fetchNextMySellOrdersSuccess,
        fetchNextMySellOrdersError,
        true,
        "[myorder]"
      )
    );
    dispatch(showSpinner("mysellorder_next"));
  }

};           


// on successful fetch, process the books data
export const processMySellOrderCollection = ({ dispatch,getState }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_MY_SELL_ORDERS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var sellerOrder=action.payload.data.items
    let sellOrderList = sellerOrder.map((order) => {
      return { 
        order_no:order.order_no,
        fulfillment_order_id:order.fulfillment_order_id,
        buyer_image:order.buyer.info.avatar,
        buyer_name:order.buyer.info.name,
        seller_name:order.seller.info.name,
        is_admin:order.seller.info.is_admin,
        order_at:formattedDate(order.ordered_at),
        due_amount:order.due.amount.value,
        total_amount:order.pricing_summary.total.value,
        fulfillment_status:order.fulfillment_information.status,
        first_ticket_name:order.lineitems[0].item_details[0].listing_name,
        first_ticket_image:order.lineitems[0].item_details[0].images[0].image_url,
        remaining_items_count:order.lineitems.length-1,
        shipping_add:{
          first_name:order.shipping_address.first_name,
          last_name:order.shipping_address.last_name,
          address_line_1:order.shipping_address.address_line_1,
          address_line_2:order.shipping_address.address_line_2,
          administrative_area:order.shipping_address.administrative_area,
          city:order.shipping_address.city,
          country:order.shipping_address.country,
          postal_code:order.shipping_address.postal_code,
          mobile_no:order.shipping_address.mobile_no,
        },
        ratings:{
          buyer_ratings:{
            communication_rating:order.buyer.rating?order.buyer.rating.records[0].value:"",
            description_rating:order.buyer.rating?order.buyer.rating.records[1].value:"",
            shipping_rating:order.buyer.rating?order.buyer.rating.records[2].value:""
           },
          seller_ratings:{
            communication_rating:order.seller.rating?order.seller.rating.records[0].value:"",
            description_rating:order.seller.rating?order.seller.rating.records[1].value:"",
            shipping_rating:order.seller.rating?order.seller.rating.records[2].value:""
           }
        },
        review:{
          buyer_review:order.buyer.review.content,
          seller_review:order.seller.review.content
        },
        pricing_summary:{
          base_price:order.pricing_summary.sold_price.value,
					tax_price: order.pricing_summary.tax.value,
					processing_fee: order.pricing_summary.processing_fee.value,
					total_price: order.pricing_summary.total.value,
        },
        line_items: order.lineitems.map((itemDetails)=> {
          return {
            type:itemDetails.type,
            base_price:itemDetails.base_price.value,
            item_details: itemDetails.item_details.map((item)=> {
              return {
                listing_name:item.listing_name,
                image:item.images[0].image_url,
                cost:item.cost.value
                }
            }),
          }
        }),
        payment_summary:{
          type: order.payment_summary.payments[0]?order.payment_summary.payments[0].method:"",
        },
        fulfillment_information:{
          delivery_cost:order.fulfillment_information.delivery_cost.shipping_cost.value,
          delivery_expected_date: order.fulfillment_information.shipment ? formattedInputDate(order.fulfillment_information.shipment.estimated_delivery_at):"", 
          delivery_status: order.fulfillment_information.shipment ? order.fulfillment_information.status:"",
          carrier: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.shipping_carrier_code:"",
          service_name: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.shipping_service_code:"",
          tracking_id: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.tracking_number:"",
        },
      };
    });


    const newData = {
      orders: sellOrderList,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMySellOrders(newData));
    dispatch(hideSpinner("mysellorder_init"));
  }

  if (action.type === FETCH_MY_SELL_ORDERS_ERROR) {
    dispatch(hideSpinner("mysellorder_init"));
  }
  // next list for buyer order

  if (action.type === FETCH_NEXT_MY_SELL_ORDERS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var sellerOrder=action.payload.data.items
    let NextList = sellerOrder.map((order) => {
      return { 
        order_no:order.order_no,
        fulfillment_order_id:order.fulfillment_order_id,
        buyer_image:order.buyer.info.avatar,
        buyer_name:order.buyer.info.name,
        seller_name:order.seller.info.name,
        is_admin:order.seller.info.is_admin,
        order_at:formattedDate(order.ordered_at),
        due_amount:order.due.amount.value,
        total_amount:order.pricing_summary.total.value,
        fulfillment_status:order.fulfillment_information.status,
        first_ticket_name:order.lineitems[0].item_details[0].listing_name,
        first_ticket_image:order.lineitems[0].item_details[0].images[0].image_url,
        remaining_items_count:order.lineitems.length-1,
        shipping_add:{
          first_name:order.shipping_address.first_name,
          last_name:order.shipping_address.last_name,
          address_line_1:order.shipping_address.address_line_1,
          address_line_2:order.shipping_address.address_line_2,
          administrative_area:order.shipping_address.administrative_area,
          city:order.shipping_address.city,
          country:order.shipping_address.country,
          postal_code:order.shipping_address.postal_code,
          mobile_no:order.shipping_address.mobile_no,
        },
        ratings:{
          buyer_ratings:{
            communication_rating:order.buyer.rating?order.buyer.rating.records[0].value:"",
            description_rating:order.buyer.rating?order.buyer.rating.records[1].value:"",
            shipping_rating:order.buyer.rating?order.buyer.rating.records[2].value:""
           },
          seller_ratings:{
            communication_rating:order.seller.rating?order.seller.rating.records[0].value:"",
            description_rating:order.seller.rating?order.seller.rating.records[1].value:"",
            shipping_rating:order.seller.rating?order.seller.rating.records[2].value:""
           }
        },
        review:{
          buyer_review:order.buyer.review.content,
          seller_review:order.seller.review.content
        },
        pricing_summary:{
          base_price:order.pricing_summary.sold_price.value,
          tax_price: order.pricing_summary.tax.value,
          processing_fee: order.pricing_summary.processing_fee.value,
          total_price: order.pricing_summary.total.value,
        },
        line_items: order.lineitems.map((itemDetails)=> {
          return {
            type:itemDetails.type,
            base_price:itemDetails.base_price.value,
            item_details: itemDetails.item_details.map((item)=> {
              return {
                listing_name:item.listing_name,
                image:item.images[0].image_url,
                cost:item.cost.value
                }
            }),
          }
        }),
        payment_summary:{
          type: order.payment_summary.payments[0]?order.payment_summary.payments[0].method:"",
        },
        fulfillment_information:{
          delivery_cost:order.fulfillment_information.delivery_cost.shipping_cost.value,
          delivery_expected_date: order.fulfillment_information.shipment ? formattedInputDate(order.fulfillment_information.shipment.estimated_delivery_at):"", 
          delivery_status: order.fulfillment_information.shipment ? order.fulfillment_information.status:"",
          carrier: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.shipping_carrier_code:"",
          service_name: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.shipping_service_code:"",
          tracking_id: order.fulfillment_information.shipment ? order.fulfillment_information.shipment.tracking_number:"",
        },
      };
    });
    const prevOrder = getState().myOrder.sellOrderList.order_list;
    const currtickets = [...prevOrder, ...NextList];
    const newData = {
      orders: currtickets,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMySellOrders(newData));
    dispatch(hideSpinner("mysellorder_next"));
  }

  if (action.type === FETCH_NEXT_MY_SELL_ORDERS_ERROR) {
    dispatch(hideSpinner("mysellorder_next"));
  }

  
};
  // *************************** Add Tracking Information  *********************************************

export const AddTrackinginfoFlow = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === ADD_TRACKING_INFO_DETAILS) {
		dispatch(setTrackingInfoStatus(OPERATION_STATUS.PENDING));
    dispatch(
			apiRequest(
				"POST",
				"/seller/orders/fulfillment.php",
				null,
				action.payload,
				addTrackingInformationSuccess,
				addTrackingInformationError,
				true,
				"[order]"
			)
		);
		dispatch(showSpinner("addTrackingInfo"));
	}
};

export const processAddTrackinginfoCollection = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === ADD_TRACKING_INFO_DETAILS_SUCCESS) {
		var fulfill_details = action.payload.data
		var information={
			fulfillment_information:{
				delivery_expected_date:formattedInputDate(fulfill_details.estimated_delivery_at), 
				delivery_status: fulfill_details.delivery_status,
				carrier:fulfill_details.carrier_name,
				service_name: fulfill_details.service_name,
				tracking_id: fulfill_details.tracking_no,
				delivery_cost:fulfill_details.delivery_cost.shipping_cost.value
			},
			order_no:fulfill_details.order_no,  
		}
    dispatch(setTrackingInfoStatus(OPERATION_STATUS.SUCCESS));
		dispatch(setTrackingDetails(information));
		dispatch(hideModal("trackingInfoModel")); 
		dispatch(
			setNotification(
				"Tracking information Added successfully",
				"myorders-tracking-info-add",
				notification_types.success
			)
		);  
	}

	if (action.type === ADD_TRACKING_INFO_DETAILS_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myorders-tracking-info-add",
        notification_types.error
      )
    );
    dispatch(setTrackingInfoStatus(OPERATION_STATUS.FAILURE));
		dispatch(hideSpinner("addTrackingInfo"));
	}

	
};
// *************************** Edit Tracking Information  *********************************************
export const EditTrackinginfoFlow = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === EDIT_TRACKING_INFO_DETAILS) {
    dispatch(setTrackingInfoStatus(OPERATION_STATUS.PENDING));
		dispatch(
			apiRequest(
				"PUT",
				"/seller/orders/fulfillment.php",
				null,
				action.payload,
				editTrackingInformationSuccess,
				editTrackingInformationError,
				true,
				"[myorder]"
			)
		);
		dispatch(showSpinner("editTrackingInfo"));
	}
};
export const processEditTrackinginfoCollection = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === EDIT_TRACKING_INFO_DETAILS_SUCCESS) {
		var fulfill_details = action.payload.data
		var information={
			fulfillment_information:{
				delivery_expected_date:formattedInputDate(fulfill_details.estimated_delivery_at), 
				delivery_status: fulfill_details.delivery_status,
				carrier:fulfill_details.carrier_name,
				service_name: fulfill_details.service_name,
				tracking_id: fulfill_details.tracking_no,
				delivery_cost:fulfill_details.delivery_cost.shipping_cost.value
			},
			order_no:fulfill_details.order_no,  
		}
		dispatch(setTrackingDetails(information));
		dispatch(hideModal("trackingInfoModel"));
    dispatch(setTrackingInfoStatus(OPERATION_STATUS.SUCCESS));
		dispatch(
			setNotification(
				"Tracking information Updated successfully",
				"myorders-tracking-info-edit",
				notification_types.success
			)
		);
	
	}

	if (action.type === EDIT_TRACKING_INFO_DETAILS_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myorders-tracking-info-edit",
        notification_types.error
      )
    );
    dispatch(setTrackingInfoStatus(OPERATION_STATUS.FAILURE));
		dispatch(hideSpinner("editTrackingInfo"));
	}

	
};
// *************************** set delivered by seller Information  *********************************************
export const approveOrderDelivered = ({ dispatch }) => (next) => (action) => {
	next(action);
	if (action.type === APPROVE_ORDER_DELIVERED) {
    dispatch(alertModelStatus(OPERATION_STATUS.PENDING));
    dispatch(approveOrderDeliveredSetStatus(OPERATION_STATUS.PENDING));
		dispatch(
			apiRequest(
				"PUT",
				"/seller/orders/order.php?action=updateOrderDelivered",
				null,
				action.payload,
				approveOrderDeliveredSuccess,
				approveOrderDeliveredError,
				true,
				"[myorder]"
			)
		);
		dispatch(showSpinner("approve-order"));
	}
};
export const processOrderDelivered = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === APPROVE_ORDER_DELIVERED_SUCCESS) {
    dispatch(alertModelStatus(OPERATION_STATUS.SUCCESS));
    dispatch(approveOrderDeliveredSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(
      setNotification(
        "Order status set to delivered successfully",
        "delivery Status Changed",
        notification_types.success
      )
    );
    dispatch(hideModal("alertbox"));
    dispatch(getMySellOrders());
    dispatch(hideSpinner("approve-order-delivered"));
  }

  if (action.type === APPROVE_ORDER_DELIVERED_ERROR) {
    dispatch(alertModelStatus(OPERATION_STATUS.FAILURE));
    dispatch(approveOrderDeliveredSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "approve-order-delivered",
        notification_types.error
      )
    );
    dispatch(hideModal("alertbox"));
    dispatch(hideSpinner("approve-order-delivered"));
  }
};
// *************************** set delivered by buyer Information  *********************************************
export const approveOrderReceivedFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === APPROVE_ORDER_RECEIVED) {
    dispatch(alertModelStatus(OPERATION_STATUS.PENDING));
    dispatch(approveOrderReceivedSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "/user/orders/order.php?action=updateOrderReceived",
        null,
        action.payload,
        approveOrderReceivedSuccess,
        approveOrderReceivedError,
        true,
        "[myorder]"
      )
    );
    dispatch(showSpinner("approve-order-received"));
  }
};
export const processApproveOrderReceived = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === APPROVE_ORDER_RECEIVED_SUCCESS) {
    dispatch(alertModelStatus(OPERATION_STATUS.SUCCESS));
    dispatch(approveOrderReceivedSetStatus(OPERATION_STATUS.SUCCESS));
    dispatch(
      setNotification(
        "Order status set to received successfully",
        "delivery Status Changed",
        notification_types.success
      )
    );
    dispatch(getMyBuyOrders());
    dispatch(hideModal("alertbox"));
    dispatch(hideSpinner("approve-order-received"));
  }

  if (action.type === APPROVE_ORDER_RECEIVED_ERROR) {
    dispatch(alertModelStatus(OPERATION_STATUS.FAILURE));
    dispatch(approveOrderReceivedSetStatus(OPERATION_STATUS.FAILURE));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "approve-order-received",
        notification_types.error
      )
    );
    dispatch(hideModal("alertbox"));
    dispatch(hideSpinner("approve-order-received"));
  }

  
};

export const myOrderMdl = [
	initiateOrderFlow,
	processInitiateOrderCollection,
	getMyBuyOrdersFlow,
	processMyBuyOrderCollection,
	getMySellOrdersFlow,
	processMySellOrderCollection,
	AddTrackinginfoFlow,
	processAddTrackinginfoCollection,
	EditTrackinginfoFlow,
	processEditTrackinginfoCollection,
	approveOrderDelivered,
	processOrderDelivered,
	approveOrderReceivedFlow,
	processApproveOrderReceived
	];
