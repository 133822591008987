import React from "react";
import MyTicketCardComponent from "../MyTicketCardComponent/MyTicketCardComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import * as ReactBootstrap from "react-bootstrap";

const MyTicketView = ({tickets, hasMore, nextPage, onEdit, onDelete}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Container className="my-4">
        <InfiniteScroll
          dataLength={tickets.length}
          next={nextPage}
          hasMore={hasMore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <ReactBootstrap.Spinner
                as="span"
                animation="border"
                role="status"
              />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {tickets.length !== 0 ? (
                <b>No more tickets found</b>
              ) : (
                <b>No Tickets Found</b>
              )}
            </p>
          }
        >
          {tickets.map((ticket) => {
            return (
              <MyTicketCardComponent
                key={ticket.product_id}
                product_id={ticket.product_id}
                listing_name={ticket.listing_name}
                event_name={ticket.event_name}
                significance={ticket.significance}
                seller={ticket.seller}
                image={ticket.image}
                venue={ticket.venue}
                date={ticket.date}
                teams={ticket.teams}
                artists={ticket.artists}
                cost={ticket.cost}
                isSale={ticket.isSale}
                description={ticket.description}
                status={ticket.status}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          })}
        </InfiniteScroll>
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

export default MyTicketView;
