import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../HomePage2/HomePage";
import GenrePage from "../GenrePage/GenrePage";
import VenuePage from "../VenuePage/VenuePage";
import MomentsHistoryPage from "../MomentsHistoryPage/MomentsHistoryPage";
import TeamArtistPage from "../TeamArtistPage/TeamArtistPage";
import TicketsPage from "../TicketsPage/TicketsPage";
import TicketDetailsPage from "../TicketDetailsPage/TicketDetailsPage";
import MYTicketsDetailsPage from "../MyTicketsDetailsPage/MyTicketsDetailsPage";
import {PAGE_URLS} from "../../js/constants"

import MyCartPage from "../MyCart/MyCartPage";
import CheckOutPage from "../CheckOut/CheckOutPage";
import OrderPaymentPage from "../PreviewOrderDetailsPage/PreviewOrderDetailsPage";
import TermsAndCondition from "../TermsCondition/TermsAndConditionPage";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicyPage";
import About from "../AboutUs/AboutPage";
import Collections from "../Collections/CollectionsPage"
import ProductList from "../Collections/ProductList/ProductList";

const SiteLayout = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route path={PAGE_URLS.HOME} exact component={HomePage} />
        <Route path="/genre" component={GenrePage} />
        <Route path="/venue" component={VenuePage} />
        <Route path="/teamArtist" component={TeamArtistPage} />
        <Route path="/momentHistory" component={MomentsHistoryPage} />
        <Route path="/collections" exact component={Collections} />
        <Route path="/collections/:id" exact component={ProductList} />
        <Route path="/tickets" exact component={TicketsPage} />
        <Route path="/tickets/:id" exact component={TicketDetailsPage} />
        <Route path="/mytickets/:id" exact component={MYTicketsDetailsPage} />
        <Route path="/mycart" exact component={MyCartPage} />
        <Route path="/checkout" exact component={CheckOutPage} />
        <Route path="/previeworder/:id" exact component={OrderPaymentPage} />
        <Route path="/termscondition" exact component={TermsAndCondition} />
        <Route path="/policy" exact component={PrivacyPolicy} />
        <Route path="/about" exact component={About} />
      </Switch>
    </React.Fragment>
  );
};

export default SiteLayout;