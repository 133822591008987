import React from "react";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { hideModal } from "../../../store/actions/ui";
import { deleteAccount ,setdeleteAccount } from "../../../store/actions/account";
import { OPERATION_STATUS } from "../../../js/constants";

function DeleteAccountModel({
  deleteAccount,
  isLoading,
  DeleteAccountShow,
  hideModal,
  remove_pass,
  setdeleteAccount
}) {
  // const { UserInfo } = profile;
  var initial_state = {
    password: "",
    error: {
      password: "",
    },
  };
  // Set State value using immer
  const [state, setUserDetails] = useImmer(initial_state);
  const handleInputChange = (e) => {
    setUserDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  useEffect(()=>{
 if(remove_pass === OPERATION_STATUS.SUCCESS ||remove_pass === OPERATION_STATUS.FAILURE  ){
  setUserDetails({...initial_state})
  setdeleteAccount(OPERATION_STATUS.YET_TO_START)
 }
  },[remove_pass])
  const onClear = () => {
    hideModal("UserAccountDelete");
    setUserDetails((draft) => {
      draft.password = "";
    });
  };
  const Submit = () => {
    if (state.password.length === 0) {
      setUserDetails((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.password = "";
      });
    }
    if (state.password.length > 256) {
      setUserDetails((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    } else {
      setUserDetails((draft) => {
        draft.error.password = "";
      });
    }
    const content = {
      password: state.password,
    };
    deleteAccount(content);
  };
  return (
    <>
      <React.Fragment>
        <ReactBootstrap.Modal
          show={DeleteAccountShow}
          onHide={() => onClear()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
              <h6 className="font-weight-bold">
                Are you sure want delete your account?
              </h6>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Form>
            <ReactBootstrap.Modal.Body>
              <ReactBootstrap.Form.Group controlId="formBasicName">
                <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                  Password
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  type="password"
                  name="password"
                  className={
                    state.error.password.length > 0 ? "is-invalid" : ""
                  }
                  placeholder="Enter your password ..."
                  value={state.password}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                {state.error.password.length > 0 && (
                  <span className="invalid-feedback">
                    {state.error.password}
                  </span>
                )}
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Modal.Body>
            <ReactBootstrap.Modal.Footer>
              <ReactBootstrap.Button className=" btn-danger" disabled={isLoading} onClick={Submit}>
                {isLoading && (
                  <ReactBootstrap.Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-1"
                  />
                )}
                Delete
              </ReactBootstrap.Button>
              {!isLoading && (
                <ReactBootstrap.Button
                  className="btnModelClose"
                  onClick={() => onClear()}
                >
                  Close
                </ReactBootstrap.Button>
              )}
            </ReactBootstrap.Modal.Footer>
          </ReactBootstrap.Form>
        </ReactBootstrap.Modal>
      </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    DeleteAccountShow: state.ui.activeModal === "UserAccountDelete",
  };
};

export default connect(mapStateToProps, { hideModal, deleteAccount,setdeleteAccount })(
  DeleteAccountModel
);
