import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import PlusIcon from "../../images/plus-icon.svg";
import MyTicketView from "./MyTickets/MyTicketView";
import MyTicketForm from "./TicketAdd/TicketAdd";
import {
  getMyTickets,
  getNextMyTickets,
  createMyTicketSetStatus,
  selectMyTicket,
  fetchUpdateTicketData,
  setUpdateTicketDataStatus,
  updateMyTicketSetStatus,
  deleteMyTicket,
} from "../../store/actions/mytickets";
import { connect } from "react-redux";
import {
  OPERATION_STATUS,
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../js/constants";

const MyTicketPage = ({
  tickets,
  hasMore,
  page,
  getMyTickets,
  getNextMyTickets,
  ticketLoading,
  user_id,
  createMyTicketSetStatus,
  editTicketDetails,
  editTicketDetailsFetchStatus,
  editTicketLoading,
  fetchUpdateTicketData,
  setUpdateTicketDataStatus,
  updateMyTicketSetStatus,
  deleteMyTicket,
  delete_status,
  deleteTicketLoading,
  isActiveSeller,
}) => {
  const [showMyTickets, setShowMyTickets] = useImmer(true);
  const [showAddForm, setShowAddForm] = useImmer(false);
  const [refreshTickets, setRefreshTickets] = useImmer(false);
  const [ticketOperation, setTicketOperation] = useImmer("Add");

  const init_ticket_data = {
    product_id: "",
    listing_name: "",
    isSale:"",
    event: {
      id: "",
      name: "",
      date: "",
      currentEvent: null,
      currentVenue: null,
      additional_significance: {
        id: "",
        significance: "",
      },
      additional_options: {
        significance: "",
        currentGenre: null,
        currentSubGenre: null,
        currentTeamArtists: [null],
      },
      isNew: false,
    },
    currentTicketType: null,
    currentQuality: null,
    imagefiles: [],
    cost: "",
    additional_options: {
      description: "",
      currentSeatType: null,
      collection: null,
      face_price: "",
      section: "",
      row: "",
      seat: "",
      grade: "",
      document: [],
    },
  };

  const [ticketFormData, setTicketFormData] = useImmer(init_ticket_data);

  const handleShowAddForm = () => {
    setShowMyTickets(false);
    setTicketFormData(init_ticket_data);
    setTicketOperation("Add");
    createMyTicketSetStatus(OPERATION_STATUS.YET_TO_START);
    setShowAddForm(true);
  };

  const handleShowMyTickets = () => {
    setTicketFormData(init_ticket_data);
    setShowAddForm(false);
    setShowMyTickets(true);
  };

  const handleAddTicketSuccess = () => {
    setShowAddForm(false);
    setRefreshTickets(!refreshTickets);
    setShowMyTickets(true);
  };

  const handleTicketEdit = (ticket_id) => {
    setUpdateTicketDataStatus(OPERATION_STATUS.YET_TO_START);
    fetchUpdateTicketData(ticket_id);
    setTicketOperation("Edit");
  };

  // For handling ticket update
  useEffect(() => {
    if (
      editTicketDetails &&
      editTicketDetailsFetchStatus === OPERATION_STATUS.SUCCESS
    ) {
      var currentGenre = null;
      var currentSubGenre = null;
      var currentTeamArtists = [null];

      if (editTicketDetails.genre) {
        currentGenre = {
          value: editTicketDetails.genre.genre_name,
          label: editTicketDetails.genre.display_name,
          genre: {
            id: editTicketDetails.genre.genre_id,
            name: editTicketDetails.genre.genre_name,
            display_name: editTicketDetails.genre.display_name,
          },
        };
        if (editTicketDetails.subgenre) {
          currentSubGenre = {
            value: editTicketDetails.subgenre.name,
            label: editTicketDetails.subgenre.display_name,
            id: editTicketDetails.subgenre.id,
            genre_id: editTicketDetails.genre.genre_id,
            display_name: editTicketDetails.subgenre.display_name,
            name: editTicketDetails.subgenre.name,
            canEdit:
              editTicketDetails.subgenre.is_admin_created === false &&
              editTicketDetails.subgenre.status === "INQUEUE" &&
              user_id === editTicketDetails.subgenre.created_by,
            showEdit: false,
          };
        }
        let teams = editTicketDetails.teams.map((team) => {
          return {
            value: team.name,
            label: team.display_name,
            id: team.id,
            sub_genre_id: editTicketDetails.subgenre.id,
            isTeam: true,
            isDisabled: false,
            canEdit:
              team.is_admin_created === false &&
              team.status === "INQUEUE" &&
              user_id === team.created_by,
            showEdit: false,
          };
        });
        let artists = editTicketDetails.artists.map((artist) => {
          return {
            value: artist.name,
            label: artist.display_name,
            id: artist.id,
            sub_genre_id: editTicketDetails.subgenre.id,
            isTeam: false,
            isDisabled: false,
            canEdit:
              artist.is_admin_created === false &&
              artist.status === "INQUEUE" &&
              user_id === artist.created_by,
            showEdit: false,
          };
        });
        if (teams.length > 0 && artists.length > 0) {
          // Both team and artist available
          currentTeamArtists = [...teams, ...artists];
        } else if (teams.length > 0) {
          // Only team is available
          currentTeamArtists = [...teams];
        } else if (artists.length > 0) {
          // Only artist is available
          currentTeamArtists = [...artists];
        } else {
          // Both team and artist empty
          currentTeamArtists = [null];
        }
      }

      let additional_significance = {
        id: "",
        significance: "",
      };

      if (editTicketDetails.event.additional_significance.length > 0) {
        additional_significance.id =
          editTicketDetails.event.additional_significance[0].id;
        additional_significance.significance =
          editTicketDetails.event.additional_significance[0].significance;
      }
      let edit_ticket_data = {
        product_id: editTicketDetails.product_id,
        listing_name: editTicketDetails.listing_name,
        isSale: {
          value:editTicketDetails.isSale,
          label:editTicketDetails.isSale == "1" ? "Sale":"Show only"
        },
        event: {
          id: editTicketDetails.event.event_id,
          name: editTicketDetails.event_name,
          date: editTicketDetails.date,
          currentEvent: {
            value:
              editTicketDetails.event_name +
              ", " +
              editTicketDetails.date +
              ", " +
              editTicketDetails.venue_name,
            label:
              editTicketDetails.event_name +
              ", " +
              editTicketDetails.date +
              ", " +
              editTicketDetails.venue_name,
            event: editTicketDetails.event,
            canEdit:
              editTicketDetails.event.is_admin_created === 0 &&
              user_id === editTicketDetails.event.created_by,
            showEdit: false,
          },
          additional_significance: additional_significance,
          currentVenue: {
            value: editTicketDetails.venue.name,
            label: editTicketDetails.venue.display_name,
            canEdit:
              editTicketDetails.venue.is_admin_created === false &&
              editTicketDetails.venue.status === "INQUEUE" &&
              user_id === editTicketDetails.venue.created_by,
            showEdit: false,
            venue: {
              id: editTicketDetails.venue.id,
              name: editTicketDetails.venue.name,
              display_name: editTicketDetails.venue.display_name,
            },
          },
          additional_options: {
            significance: editTicketDetails.significance,
            currentGenre: currentGenre,
            currentSubGenre: currentSubGenre,
            currentTeamArtists: currentTeamArtists,
          },
          isNew: false,
        },
        currentTicketType: {
          value: editTicketDetails.type.name,
          label: editTicketDetails.type.name,
          id: editTicketDetails.type.id,
        },
        currentQuality: {
          value: editTicketDetails.quality,
          label: editTicketDetails.quality,
        },
        imagefiles: editTicketDetails.images.map((image) => {
          return {
            image_id: image.image_id,
            image_url: image.image_400,
            image_file: "",
            image_name: "",
            isnew: false,
          };
        }),
        cost: editTicketDetails.cost,
        additional_options: {
          description: editTicketDetails.description,
          collection: {
            value: editTicketDetails.collection.collection_id,
              label: editTicketDetails.collection.collection_name,
              id: editTicketDetails.collection.collection_id
          },
          currentSeatType: editTicketDetails.seat_type
            ? {
                value: editTicketDetails.seat_type.name,
                label: editTicketDetails.seat_type.name,
                id: editTicketDetails.seat_type.id,
              }
            : null,
          face_price: editTicketDetails.face_price,
          section: editTicketDetails.section,
          row: editTicketDetails.row,
          seat: editTicketDetails.seat_no,
          grade: editTicketDetails.grade,
          document: [],
        },
      };
      setShowMyTickets(false);
      setTicketFormData(edit_ticket_data);
      updateMyTicketSetStatus(OPERATION_STATUS.YET_TO_START);
      setShowAddForm(true);
    } else if (editTicketDetailsFetchStatus === OPERATION_STATUS.FAILURE) {
      console.log("Fetch failed");
    }
  }, [editTicketDetailsFetchStatus]);

  // For handling ticket delete
  useEffect(() => {
    if (delete_status === OPERATION_STATUS.SUCCESS) {
      setRefreshTickets(!refreshTickets);
    } else {
    }
  }, [delete_status]);

  const handleTicketDelete = (ticket) => {
    deleteMyTicket(ticket);
    window.scrollTo(0, 0);
  };

  const nextPage = () => {
    let filters = {
      page: page
    };
    getNextMyTickets(filters);
  };

  // When page load get the filter options and set the values
  useEffect(() => {
    if (user_id) {
      getMyTickets();
    }
    createMyTicketSetStatus(OPERATION_STATUS.YET_TO_START);
    setUpdateTicketDataStatus(OPERATION_STATUS.YET_TO_START);
    updateMyTicketSetStatus(OPERATION_STATUS.YET_TO_START);
  }, [user_id, refreshTickets]);

  return (
    <React.Fragment>
      <div className="my-4">
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} md={6}>
            <h5>My Tickets</h5>
          </ReactBootstrap.Col>
          {showMyTickets && isActiveSeller && (
            <ReactBootstrap.Col xs={12} md={6} className="text-right">
              <ReactBootstrap.Button
                className="btnAddTicket"
                onClick={handleShowAddForm}
              >
                <span>
                  <img src={PlusIcon} alt="PlusIcon" />
                </span>
                Add Ticket
              </ReactBootstrap.Button>
            </ReactBootstrap.Col>
          )}
        </ReactBootstrap.Row>
        <ReactBootstrap.Dropdown.Divider />
      </div>
      {showMyTickets &&
        (ticketLoading || editTicketLoading || deleteTicketLoading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <ReactBootstrap.Spinner animation="border" role="status" />
          </div>
        ) : (
          <MyTicketView
            tickets={tickets}
            hasMore={hasMore}
            nextPage={nextPage}
            onEdit={handleTicketEdit}
            onDelete={handleTicketDelete}
          />
        ))}
      {showAddForm && (
        <MyTicketForm
          onCancel={handleShowMyTickets}
          onSuccess={handleAddTicketSuccess}
          formData={ticketFormData}
          operation={ticketOperation}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.user ? state.user.id : null,
    tickets: state.mytickets.tickets,
    hasMore: state.mytickets.hasMore,
    page: state.mytickets.page,
    ticketLoading:
      "mytickets_init" in state.ui.loaders
        ? state.ui.loaders["mytickets_init"]
        : true,
    editTicketDetails: state.mytickets.update_ticket.data,
    editTicketDetailsFetchStatus: state.mytickets.update_ticket.fetch_status,
    editTicketLoading:
      state.mytickets.update_ticket.fetch_status === OPERATION_STATUS.PENDING,
    delete_status: state.mytickets.deleteticket_status,
    deleteTicketLoading:
      state.mytickets.deleteticket_status === OPERATION_STATUS.PENDING,
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) =>
              permission.permission === USER_PERMISSIONS.SELL &&
              permission.status === USER_PERMISSIONS_STATES.ACTIVE
          )
        : false
  };
};

export default connect(mapStateToProps, {
  getMyTickets,
  getNextMyTickets,
  selectMyTicket,
  createMyTicketSetStatus,
  fetchUpdateTicketData,
  setUpdateTicketDataStatus,
  updateMyTicketSetStatus,
  deleteMyTicket,
})(MyTicketPage);
