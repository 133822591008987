import React from "react";
import { Card, Button, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../../../store/actions/ui";
import PricingSummary from "./PricingSummary";
import FulfillInformation from "./FulfillInformation";
import PaymentDetails from "./PaymentDetails";
import ShipmentDetails from "./ShipmentDetails";
import ReviewDetails from "./ReviewDetails";
import { getDeliveredStatus } from "../../../../js/utils";

import { ORDER_DELEVERED_STATES } from "../../../../js/constants";

function SellOrderDetailsView({
  backToMainFromDetail,
  //Display Values
  order_no,
  order_date,
  buyer_name,
  seller_name,
  delivery_expected_date,
  delivery_status,
  carrier,
  service_name,
  tracking_id,
  base_price,
  tax_price,
  due_amount,
  processing_fee,
  total_price,
  type_of_payment,
  shipping_add,
  line_items,
  buyer_ratings,
  seller_ratings,
  buyer_review,
  seller_review,
  items,
  //for fulfillmentDetails
  onAddfullfillmentModel,
  onEditfullfillmentModel,
  onEditRating,
  onAddRating,
}) {
  return (
    <React.Fragment>
      <Button
        className="btn btn-primary my-1"
        onClick={backToMainFromDetail}
        title="Go back"
      >
        <i className="fa fa-chevron-left"></i>
      </Button>

      <Card className="mb-4">
        <Card.Body>
          <div>
            <strong>Order No: </strong>
            {order_no}
          </div>
          <div>
            <strong>Order On: </strong>
            {order_date}
          </div>
          <div>
            <strong>Delivery Status: </strong>
            {getDeliveredStatus(delivery_status)}
          </div>
          <Dropdown.Divider />
          <ReviewDetails
            buyer_name={buyer_name}
            seller_name={seller_name}
            buyer_ratings={buyer_ratings}
            seller_ratings={seller_ratings}
            buyer_review={buyer_review}
            seller_review={seller_review}
            onEditRating={onEditRating}
            onAddRating={onAddRating}
            delivery_status={delivery_status}
          />
          <Dropdown.Divider />
          <ShipmentDetails line_items={line_items} />
          <Dropdown.Divider />
          <FulfillInformation
            order_no={order_no}
            carrier={carrier}
            service_name={service_name}
            tracking_id={tracking_id}
            delivery_status={delivery_status}
            delivery_expected_date={delivery_expected_date}
            shipping_add={shipping_add}
            onAddfullfillmentModel={onAddfullfillmentModel}
            onEditfullfillmentModel={onEditfullfillmentModel}
            items={items}
          />
          <Dropdown.Divider />
          <PricingSummary
            base_price={base_price}
            tax_price={tax_price}
            processing_fee={processing_fee}
            total_price={total_price}
            due_amount={due_amount}
          />
          {type_of_payment && (
            <>
              {" "}
              <Dropdown.Divider />
              <PaymentDetails PaymentType={type_of_payment} />
            </>
          )}
        </Card.Body>
        {/* <div className=" d-flex justify-content-center p-2">
          <button
            type="button"
            className="btn btn-outline-primary waves-effect"
          >
            <i className="fa fa-download mr-2"></i>Download Invoice
          </button>
        </div> */}
      </Card>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  showModal,
})(SellOrderDetailsView);
