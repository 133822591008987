import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useEffect } from "react";
import { getShippingAddress } from "../../../../../store/actions/addressAction";
import { connect } from "react-redux";
function TabManageBuyerComponent({
  getShippingAddress,
  defaultShippingAddress,
}) {
  //Page Onload
  useEffect(() => {
    const user = {
      type: "DELIVERY",
    };
    getShippingAddress(user);
  }, []);
  return (
    <React.Fragment>
      <ReactBootstrap.Form>
        <ReactBootstrap.Form.Group controlId="formDefaultShippingAddress">
          <ReactBootstrap.Form.Label className="font-weight-bold">
            Default Shipping Address
          </ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Text className="text-muted">
            {defaultShippingAddress.map((data, index) => (
              <div key={index}>
                <div>
                  {data.first_name && data.first_name + " "}
                  {data.last_name && data.last_name}
                </div>

                <div>{data.address_line_1 && data.address_line_1 + ","}</div>
                {data.address_line_2 && data.address_line_2 + ","}
                <div></div>

                <div>
                  {data.administrative_area && data.administrative_area + ","}
                  {data.city && data.city + ","}
                  {data.country && data.country + ","}
                  {data.postal_code && data.postal_code}
                </div>

                <div>{data.mobile_no && data.mobile_no}</div>
              </div>
            ))}
          </ReactBootstrap.Form.Text>
        </ReactBootstrap.Form.Group>
      </ReactBootstrap.Form>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    defaultShippingAddress: state.Address.ShippingAddress,
    isLoading:
      "address" in state.ui.loaders ? state.ui.loaders["address"] : true,
  };
};
export default connect(mapStateToProps, { getShippingAddress })(
  TabManageBuyerComponent
);
