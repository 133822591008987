import { UPDATE_TICKETS, UPDATE_TICKET, FETCH_SINGLE_TICKET_ERROR } from "../actions/tickets";
import produce from 'immer'

const INITIAL_STATE = {
  hasMore: false,
  nextPage: null,
  tickets: [],
  ticket: {
    info: null,
    error: null
  }
};

export const ticketsReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_TICKETS:
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      draft.tickets = action.payload.tickets;
      break;
    case UPDATE_TICKET:
      draft.ticket.info = action.payload;
      draft.ticket.error = null;
      break;
    case FETCH_SINGLE_TICKET_ERROR:
      draft.ticket.info = null;
      draft.ticket.error = action.payload;
      break;
    default:
      break;
  }
},INITIAL_STATE);
