import React from "react";
import { ListGroup } from "react-bootstrap";
import CheckoutItemComponent from "./CheckoutItemComponent/CheckoutItemComponent";

const OrderSummaryComponent = ({ line_items }) => {
  return (
    <React.Fragment>
      <div className="">
        <div>
          <strong>Products details</strong>
        </div>
        <ListGroup>
          {line_items.map((item) => {
            return (
              <ListGroup.Item key={item.id}>
                <CheckoutItemComponent
                  type={item.type}
                  offer_id={item.offer_id}
                  offer_no={item.offer_no}
                  base_price={item.base_price}
                  tax_price={item.tax_price}
                  seller_id={item.seller.id}
                  seller_name={
                    item.seller.is_admin ? "Memory Lane" : item.seller.name
                  }
                  details={item.details}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

export default OrderSummaryComponent;
