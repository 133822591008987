import React from "react";
import { Form, InputGroup, Button, Spinner } from "react-bootstrap";
import { OPERATION_STATUS } from "../../../../js/constants";
import "./MessageInput.css";

const MessageInput = ({
  sendMessage,
  value,
  error,
  onInputChange,
  isMessageSending,
}) => {
  const formSubmit = (e) => {
    // Get the code of pressed key
    const keyCode = e.which || e.keyCode;

    // 13 represents the Enter key
    if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line
      e.preventDefault();

      // Do something else such as send the message to back-end
      // ...
      if (value.length > 0) {
        sendMessage(e);
      }
    }
  };
  return (
    <React.Fragment>
      <Form className="py-3 px-4 border-top" onSubmit={sendMessage}>
        <div className="d-flex flex-nowrap">
          <div className="mr-2 flex-grow-1">
            <InputGroup>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter your reply"
                className={error.message.length > 0 ? "is-invalid" : ""}
                name="message"
                value={value}
                aria-label="message"
                onChange={onInputChange}
                onKeyDown={formSubmit}
                autoComplete="off"
              />
              <InputGroup.Text title="Send" className="p-0">
                <Button
                  onClick={sendMessage}
                  className="sendMessageIcon"
                  disabled={value.length === 0 }
                >
                 
                  {isMessageSending === OPERATION_STATUS.PENDING && (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      size="sm"
                      className="mr-1"
                    />
                  )}
                  Send Reply
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default MessageInput;
