import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { useImmer } from "use-immer";
import { useHistory } from "react-router-dom";
import "./BecomeASellerComponent.css";
import { PAGES } from "../../js/constants"
import {
  registerAsSeller,
  registerAsSellerSetStatus,
} from "../../store/actions/seller";
import SellerAgreementModel from "../../Dialogs/SellerAgreementModel/SellerAgreementModel";
import { connect } from "react-redux";
import { OPERATION_STATUS,MAIL_REGEX } from "../../js/constants";

const BecomeASellerComponent = ({
  registerAsSeller,
  registerAsSellerSetStatus,
  seller_registration_loader,
  seller_registration_status,
  Contents
}) => {
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  var initial_state = {
    id: "",
    email: "",
    isAgree: false,
    error: {
      id: "",
      email: "",
      isAgree: "",
    },
  };
  
  // Set State value using immer
  const [state, setState] = useImmer(initial_state);
   
  // To handle the Onchange Function
  const handleInputChange = (e) => {
    setState((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const handleCheckedChange = (e) => {
    setState((draft) => {
      draft[e.target.name] = e.target.checked;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.id.length === 0) {
      setState((draft) => {
        draft.error.id = "Please enter the id.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.id = "";
      });
    }

    if (state.id.length > 256) {
      setState((draft) => {
        draft.error.id = "Please enter upto 256 characters for id.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.id = "";
      });
    }
    if (state.email.length === 0) {
      setState((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.email = "";
      });
    }

    if (!state.email.match(MAIL_REGEX)) {
      setState((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.email = "";
      });
    }

    if (state.email.length > 256) {
      setState((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.email = "";
      });
    }

    if (state.isAgree === false) {
      setState((draft) => {
        draft.error.isAgree = "Agree to submit.";
      });
      return false;
    } else {
      setState((draft) => {
        draft.error.isAgree = "";
      });
    }

    var id = state.id;
    var email = state.email;
    const data = {
      paypal_details: {
        id: id,
        email: email,
      },
    };
    registerAsSeller(data);
  };

  useEffect(() => {
    // history.replace({ pathname: "/account" });
    registerAsSellerSetStatus(OPERATION_STATUS.YET_TO_START);
  }, []);

  useEffect(() => {
    if (seller_registration_status === OPERATION_STATUS.SUCCESS) {
      history.replace({ pathname: "/account" });
    }else if (seller_registration_status === OPERATION_STATUS.FAILURE){
      history.replace({ pathname: "/account" });
    }
  }, [seller_registration_status]);
  return (
    <React.Fragment>
      <div className="my-4">
        <ReactBootstrap.Card>
          <ReactBootstrap.Card.Header className="sellerCardHeader" as="h5">
            Become a Seller
          </ReactBootstrap.Card.Header>
          {seller_registration_loader ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <ReactBootstrap.Spinner animation="border" role="status" />
            </div>
          ) : (
            <ReactBootstrap.Card.Body>
              <ReactBootstrap.Container fluid>
                <ReactBootstrap.Form onSubmit={handleSubmit}>
                  <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={6}>
                      <ReactBootstrap.Form.Group>
                        <ReactBootstrap.Form.Label>
                          Payment Type:
                        </ReactBootstrap.Form.Label>
                        <ReactBootstrap.Form.Check
                          type={"radio"}
                          label={`PayPal`}
                          disabled
                          checked={true}
                        />
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={6}>
                      <ReactBootstrap.Form.Group controlId="formBasicRadio">
                        <ReactBootstrap.Form.Label>
                          Paypal Id:
                        </ReactBootstrap.Form.Label>
                        <ReactBootstrap.Form.Control
                          type="text"
                          placeholder="Paypal id..."
                          name="id"
                          className={
                            state.error.id.length > 0 ? "is-invalid" : ""
                          }
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                        {state.error.id.length > 0 && (
                          <span className="invalid-feedback">
                            {state.error.id}
                          </span>
                        )}
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={6}>
                      <ReactBootstrap.Form.Group controlId="formGroupEmail">
                        <ReactBootstrap.Form.Label>
                          Paypal Email:
                        </ReactBootstrap.Form.Label>
                        <ReactBootstrap.Form.Control
                          type="text"
                          placeholder="Paypal email..."
                          name="email"
                          className={
                            state.error.email.length > 0 ? "is-invalid" : ""
                          }
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                        {state.error.email.length > 0 && (
                          <span className="invalid-feedback">
                            {state.error.email}
                          </span>
                        )}
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={12}>
                      <ReactBootstrap.Form.Group
                        className="d-flex"
                        controlId="formBasicCheckbox"
                      >
                        <ReactBootstrap.Form.Check
                          type="checkbox"
                          name="isAgree"
                          className={
                            state.error.isAgree.length > 0 ? "is-invalid" : ""
                          }
                          onChange={handleCheckedChange}
                        />
                        <span>
                          Agree to{" "}
                          <span
                            className="modelAgreement"
                            onClick={() => setModalShow(true)}
                          >
                            Seller Agreement.
                          </span>
                        </span>
                        <span className="invalid-feedback">
                          {state.error.isAgree}
                        </span>
                        <SellerAgreementModel
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          Contents = {Contents}
                        />
                      </ReactBootstrap.Form.Group>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col xs={12} sm={12} className="text-center">
                      <ReactBootstrap.Button
                        className="btnClickSeller"
                        type="submit"
                      >
                        Submit
                      </ReactBootstrap.Button>
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                </ReactBootstrap.Form>
              </ReactBootstrap.Container>
            </ReactBootstrap.Card.Body>
          )}
        </ReactBootstrap.Card>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    Contents: state.app.staticContents.find( ({ page_name }) => page_name === PAGES.SELLERAGREEMENT),
    seller_registration_loader:
      state.seller.register_seller_status === OPERATION_STATUS.PENDING,
    seller_registration_status: state.seller.register_seller_status
    
  };
};

export default connect(mapStateToProps, {
  registerAsSeller,
  registerAsSellerSetStatus
})(BecomeASellerComponent);
