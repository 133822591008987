import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Row, Col, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import BuyOrderPage from "./BuyOrder/BuyOrderPage";
import SellOrderPage from "./SellOrder/SellOrderPage";
import { useImmer } from "use-immer";
import { showModal,hideModal } from "../../store/actions/ui";
import Alertmodel from "../../common/Alertmodel/Alertmodel";
import { USER_PERMISSIONS, USER_PERMISSIONS_STATES } from "../../js/constants";
import { approveOrderDelivered,approveOrderReceived } from "../../store/actions/myOrder";
const MyOrderPage = ({ isActiveSeller,showModal,approveOrderReceived,approveOrderDelivered }) => {
  var initial_rating = {
    delivery_order_no: "",
    recieved_order_no: "",
    sub_order_id :"",
    type:"",
    message:""
  };
  const [State, setState] = useImmer(initial_rating);

  const approveOrderDeliveredSubmit = (order_no) => {
    setState((draft) => {
      draft.delivery_order_no= order_no;
      draft.type = "delivered"
      draft.message = "Order Delivered ?"
    });
    showModal("alertbox");
  };
  const approveOrderReceivedSubmit = (order_no, sub_order_id) => {
    setState((draft) => {
      draft.recieved_order_no= order_no;
      draft.sub_order_id =sub_order_id;
      draft.type = "recieved"
      draft.message = "Order Received ?"
    });
    showModal("alertbox");
  };



    const submit = (type) =>{
      if(type==="delivered"){
        var data = {
          order_no: State.delivery_order_no,
        };
        approveOrderDelivered(data);
      }else if(type==="recieved"){
        var data = {
          order_no: State.recieved_order_no,
          sub_order_id: State.sub_order_id,
        };
        approveOrderReceived(data);
      }
     
    }
     
  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={12}>
            <h5>My Orders</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
      </div>
      {isActiveSeller ? (
        <Tabs fill className="tabselect">
          <Tab
            eventKey="first"
            title={
              <div className="headerbar">
                <i className="fa fa-shopping-cart"></i> BUY{" "}
              </div>
            }
          >
            <BuyOrderPage 
            approveOrderReceived={approveOrderReceivedSubmit}
            />
          </Tab>
          <Tab
            eventKey="second"
            title={
              <div className="headerbar">
                <i className="fa fa-credit-card"> </i> SELL{" "}
              </div>
            }
          >
            <SellOrderPage 
            approveOrderDelivered={approveOrderDeliveredSubmit}
            />
          </Tab>
        </Tabs>
      ) : (
        <BuyOrderPage
        approveOrderReceived={approveOrderReceivedSubmit}
         />
      )}
       <Alertmodel 
      submit={submit}
      type={State.type}
      message={State.message}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) =>
              permission.permission === USER_PERMISSIONS.SELL &&
              permission.status === USER_PERMISSIONS_STATES.ACTIVE
          )
        : false,
  };
};
export default connect(mapStateToProps, {showModal,approveOrderReceived,approveOrderDelivered})(MyOrderPage);
