import React from "react";
import * as ReactBootstrap from "react-bootstrap";

import "../MyAccountScreen/MyAccountScreen.css";

// Common component
import MyAccountComponent from "./MyAccountComponent/MyAccountComponent";
import AddressAddEditModel from "../MyAccountScreen/AdressAddEditModel/AddressAddEditModel";

function MyAccountPage() {
  return (
    <React.Fragment>
      <ReactBootstrap.Container fluid className="pl-0">
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12}>
            <MyAccountComponent />
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <AddressAddEditModel />
      </ReactBootstrap.Container>
    </React.Fragment>
  );
}

export default MyAccountPage;
