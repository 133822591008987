import {
    GET_VENUE,
    FETCH_VENUE_SUCCESS,
    FETCH_VENUE_ERROR,
    fetchVenueSuccess,
    fetchVenueError,
    setVenue
  } from "../../actions/venue";
  import { showSpinner, hideSpinner } from "../../actions/ui";
  import { apiRequest } from "../../actions/api";
  
  // this middleware only care about the getBooks action
  export const getVenueFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_VENUE) {
      dispatch(
        apiRequest(
          "GET",
          "/venues.php",
          action.payload,
          null,
          fetchVenueSuccess,
          fetchVenueError,
          false,
          "[venue]"
        )
      );
      dispatch(showSpinner("venue"));
    }
  
  };
  
  // on successful fetch, process the books data
  export const processVenueCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === FETCH_VENUE_SUCCESS) {
      let venues = action.payload.data.items
      let sortByDisplayOrder = venues.sort((ga,gb) => ga.display_order - gb.display_order)
      dispatch(setVenue(sortByDisplayOrder));
      dispatch(hideSpinner("venue"));
    }
  
    if (action.type === FETCH_VENUE_ERROR) {
      dispatch(hideSpinner("venue"));
    }
  
    
  };
  
  export const venueMdl = [getVenueFlow, processVenueCollection];
  