import React from "react";
import PropTypes from "prop-types";
// import { Card } from "react-bootstrap";
import "./OfferItem.css";

const OfferItem = ({ listing_name, cost, image }) => {
  return (
    <React.Fragment>
      <div className="m-1 p-3 border rounded" >
        {/* <Card.Body> */}
          <div className="d-flex flex-nowrap align-items-center">
            <div className="mr-1 divTicketImage">
              <img
                src={image.image_200}
                alt="ticket_image"
                height={50}
                width={50}
              />
            </div>
            <div className="searchResultEventName textBlackColor flex-grow-1 m-0">
              <h6>{listing_name}</h6>
              <div className="font-weight-bold">
                <i className="fa fa-usd" aria-hidden="true"></i> {cost}
              </div>
            </div>
          </div>
        {/* </Card.Body> */}
      </div>
    </React.Fragment>
  );
};

OfferItem.propTypes = {
  listing_name: PropTypes.string.isRequired,
  cost: PropTypes.string,
  image: PropTypes.object,
};

export default OfferItem;
