import React from "react";
import {Container,Row,Col,Button} from "react-bootstrap";
import { showModal } from "../../../store/actions/ui";
import { PAGE_URLS, USER_STATES } from "../../../js/constants";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./SellWithUs.css";

import BannerSellWithUs from "../../../images/banner-sell-with.png";

function SellWithUs({ showModal,isLogged }) {
  return (
    <React.Fragment>
      <section className="sell-with-us mb-5">
        <div className="banner-image" style={{height: '300px'}}>
          {/* <img src={BannerSellWithUs} alt="banner" /> */}
        </div>
        <div className="overlay"></div>
        <Container className="content-sellWithUs">
          <div>
            <Container>
              <Row>
                <Col xs={12} sm={8}>
                  <h1>SELL WITH US</h1>
                  <p>Get top value for your collections</p>
                </Col>
                {!isLogged &&
                  <Col xs={12} sm={4} className="text-center">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      showModal("register");
                    }}
                    size="lg"
                    className="w-100 mt-5 btn-signUp"
                  >
                    Sign Up Now
                  </Button>
                </Col>}
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
  };
};
export default connect(mapStateToProps, { showModal })(SellWithUs);
