import { SITE_LOGIN_STATUS, SET_SITE_REFRESH_STATUS, SET_SITE_TOKENS, SET_SITE_STATUS } from "../actions/landingpage";
import produce from 'immer'
import {OPERATION_STATUS, USER_STATES} from "../../js/constants"

const INITIAL_STATE = {
  sitelogin_status: OPERATION_STATUS.YET_TO_START,
  site_refresh_status: OPERATION_STATUS.YET_TO_START,
  tokens: null,
  site_status: USER_STATES.ANONYMOUS
};

export const landingPageReducer = produce((draft, action) => {
  switch (action.type) {
    case SITE_LOGIN_STATUS:
      draft.sitelogin_status = action.payload;
      break;
    case SET_SITE_REFRESH_STATUS:
      draft.site_refresh_status = action.payload;
      break;
    case SET_SITE_TOKENS:
      draft.tokens = action.payload;
      break;
    case SET_SITE_STATUS:
      draft.site_status = action.payload;
      break;
    default:
      break;
  }
},INITIAL_STATE);
