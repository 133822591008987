import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import ScrollArea from "react-scrollbar";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";
import OwlCarousel from "react-owl-carousel";
import "./SliderComponent.css";
import ImageZoom from "../../../Dialogs/ZoomImage/ZoomImage";

const SliderComponent = ({ images }) => {
  const [img, setImage] = useImmer({
    image: images[0],
    ActiveId: images[0].image_id,
  });
  const setloader = () => {};
  const [ImagemodalShow, setImageModalShow] = React.useState(false);

  const setImageUrl = (img) => {
    setImage((draft) => {
      draft.ActiveId = img.image_id;
      draft.image = img;
    });
  };
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <ReactBootstrap.Container className="my-2">
        <ReactBootstrap.Row className="responsive_class">
          <ReactBootstrap.Col xs={12} md={3}>
            <div className="parent_verticalOwlTickets">
              <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="content"
                horizontal={false}
                minScrollSize={500}
              >
                {images.map((image) => {
                  return (
                    <img
                      height="80px"
                      className={
                        img.ActiveId === image.image_id
                          ? "selected objectfit img-fluid"
                          : "objectfit img-fluid"
                      }
                      width="80px"
                      id={image.image_id}
                      onClick={() => {
                        setImageUrl(image);
                        setloader();
                      }}
                      src={image.image_200}
                      alt="carouselImage"
                    />
                  );
                })}
              </ScrollArea>
            </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} md={8}>
            <div>
              {/*  For responsive, no need of vertical carousel */}
              <div className="responsiveView">
                <OwlCarousel
                  items={1}
                  className="owl-theme owlCarouselImages"
                  loop={false}
                  autoPlay={false}
                  nav={true}
                  dots={true}
                  margin={8}
                  responsive={responsive}
                >
                  {images.map((image) => {
                    return (
                      <div key={image.image_id}>
                        <img src={image.image_600} alt="carouselImage" className="img-fluid carouselTicketDetailsImage" />
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>

              {/*  For desktop, no need of carousel */}
              <div className="desktop">
                <div>
                  <img
                    className="objectfitDIV Zoom_img img-fluid"
                    onClick={() => {
                      setImageModalShow(true);
                    }}
                    src={img.image.image_600}
                    alt="carouselImage"
                  />
                  <ImageZoom
                    src={img.image.image_1000}
                    show={ImagemodalShow}
                    onHide={() => setImageModalShow(false)}
                  />
                </div>
              </div>
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
        <div className="text-center my-4 button_parent">
          <ReactBootstrap.Button className="btnSearchBuyNow">
            <i className="fa fa-ticket" aria-hidden="true"></i> BUY NOW
          </ReactBootstrap.Button>
        </div>
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};
SliderComponent.propTypes = {
  images: PropTypes.array.isRequired,
};

export default SliderComponent;
