import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import './ZoomImage.css'

function ZoomImage (props) {
    const [display, setImageProperty] = React.useState("none");
    const styles = {
        display: display
    }
    return (
        <React.Fragment>
            <ReactBootstrap.Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onEntering = {() => setImageProperty('block')}
                onEntered = {() => setImageProperty('none')}
            >
            <div className="justify-content-center align-items-center my-5 ml-67 zoomClass_bm" style={styles}>
            <ReactBootstrap.Spinner animation="border" role="status" aria-hidden="true"/>
            </div>
            <img className="objectfit"   src={props.src} alt="carouselImage" />
            </ReactBootstrap.Modal>
        </React.Fragment>
    )
}

export default ZoomImage;