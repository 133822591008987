import {
  UPDATE_CONTACT_ADDRESS,
  UPDATE_BILLING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS_LIST,
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  EDIT_ADDRESS,
  EDIT_ADDRESS_SUCCESS,
  SET_ADDRESS_MODEL,
  ADD_ADDRESS_STATUS,
  DELETE_ADDRESS_STATUS
  } from "../actions/addressAction";
import produce from "immer";
const INITIAL_STATE = {
  AddressDetailsUpdated:false,
  ContactAddress:[],
  BillingAddress:[],
  ShippingAddress:[],
  ShippingAddressList:[],
  Address_data:{
    address:null,
    AddressType:"",
    operations:"",
    AddressID: "",
    title:"",
  },
  AddressStatus:"",
  deleteAddressData:""
}

export const AddressReducer = produce((draft, action) => {
  switch (action.type) {
      case UPDATE_CONTACT_ADDRESS:
        if(action.payload.length !== 0){
          draft.ContactAddress = action.payload;
      }
        break;
        case UPDATE_BILLING_ADDRESS:
          if(action.payload.length !== 0){
            draft.BillingAddress = action.payload;
        }
        break;
        case UPDATE_SHIPPING_ADDRESS:
          if(action.payload.length !== 0){
            draft.ShippingAddress = action.payload;
        }
       break;
       case UPDATE_SHIPPING_ADDRESS_LIST:
            draft.ShippingAddressList = action.payload;
       break;

      case ADD_ADDRESS:
          draft.AddressDetailsUpdated = false;
      break;
      case ADD_ADDRESS_SUCCESS:
        draft.AddressDetailsUpdated = true;
      break;
      case ADD_ADDRESS_STATUS:
        draft.AddressStatus = action.payload;
      break;
      case EDIT_ADDRESS:
          draft.AddressDetailsUpdated = false;
      break;
      case DELETE_ADDRESS_STATUS:
          draft.deleteAddressData= action.payload;
      break;
      case EDIT_ADDRESS_SUCCESS:
        draft.AddressDetailsUpdated = true;
      break;
      case SET_ADDRESS_MODEL:
        draft.Address_data = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);