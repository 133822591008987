import jwt_decode from "jwt-decode";
import { OFFER_STATES, OFFER_TYPES, TICKET_STATES } from "./constants";
import { ORDER_DELEVERED_STATES } from "./constants";

export const isTokenExpired = (token) => {
  let decodedToken = jwt_decode(token);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
};

export const formattedDate = (datetime) => {
  var localTime = getLocalTime(datetime);
  var date =
    ("0" + localTime.getDate()).slice(-2) +
    "/" +
    ("0" + (localTime.getMonth() + 1)).slice(-2) +
    "/" +
    localTime.getFullYear();
  return date;
};
export const formattedInputDate = (datetime) => {
  var localTime = getLocalTime(datetime);
  var date =
    localTime.getFullYear() +
    "-" +
    ("0" + (localTime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + localTime.getDate()).slice(-2);
  return date;
};
export const formattedDateTime = (datetime) => {
  var localTime = getLocalTime(datetime);
  var date =
    localTime.getFullYear() +
    "/" +
    ("0" + (localTime.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + localTime.getDate()).slice(-2);
  var time =
    ("0" + localTime.getHours()).slice(-2) +
    ":" +
    ("0" + localTime.getMinutes()).slice(-2) +
    ":" +
    ("0" + localTime.getSeconds()).slice(-2);
  return date + " " + time;
};

export const formattedMessageDisplayDateTime = (datetime) => {
  var localTime = getLocalTime(datetime);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date =
    monthNames[localTime.getMonth()] +
    " " +
    ("0" + localTime.getDate()).slice(-2) +
    ", " +
    localTime.getFullYear();

  var hours = localTime.getHours();
  var minutes = localTime.getMinutes();
  // Check whether AM or PM
  var newformat = hours >= 12 ? "PM" : "AM";

  // Find current hour in AM-PM Format
  hours = hours % 12;

  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  var time = hours + ":" + minutes + " " + newformat;
  return date + " " + time;
};

const getUTCDatetime = (datetime) => {
  var currentTime = new Date(datetime);
  return new Date(
    Date.UTC(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    )
  );
};

const getLocalTime = (datetime) => {
  return new Date(getUTCDatetime(datetime).toString());
};

export const getMyOfferRenderStatus = (status) => {
  let status_string = "";
  switch (status) {
    case OFFER_STATES.ACCEPTED_SELLER:
      status_string = "SELLER ACCEPTED";
      break;
    case OFFER_STATES.ACCEPTED_BUYER:
      status_string = "BUYER ACCEPTED";
      break;
    case OFFER_STATES.OPEN:
      status_string = "OPEN";
      break;
    case OFFER_STATES.REJECTED:
      status_string = "SELLER REJECTED";
      break;
    case OFFER_STATES.CANCELLED:
      status_string = "SELLER CANCELLED";
      break;
    case OFFER_STATES.CART:
    case OFFER_STATES.IN_CHECK_OUT:
      status_string = "ADDED TO CART";
      break;
    case OFFER_STATES.IN_ORDER:
      status_string = "ADDED TO ORDER";
      break;
    case OFFER_STATES.DROPPED:
      status_string = "OFFER DROPPED";
      break;
    default:
      break;
  }
  return status_string;
};

export const getRenderType = (type) => {
  let type_string = "";
  switch (type) {
    case OFFER_TYPES.OFFER:
      type_string = "OFFER";
      break;
    case OFFER_TYPES.COUNTER:
      type_string = "COUNTER OFFER";
      break;
    default:
      break;
  }
  return type_string;
};
export const getDeliveredStatus = (DeliveryStatus) => {
  let status_string = "";
  switch (DeliveryStatus) {
    case ORDER_DELEVERED_STATES.OPEN:
      status_string = "Your order is Pending";
      break;
    case ORDER_DELEVERED_STATES.SHIPPED:
      status_string = "Your order has been Dispatched";
      break;
    case ORDER_DELEVERED_STATES.DELIVERED_ADMIN:
      status_string = "Your order has been Delivered, Approved by Memory Lane";
      break;
    case ORDER_DELEVERED_STATES.DELIVERED_SELLER:
      status_string = "Your order has been Delivered, Approved by Seller";
      break;
    case ORDER_DELEVERED_STATES.DELIVERED_BUYER:
      status_string = "Your order has been Delivered";
      break;
    case ORDER_DELEVERED_STATES.RETURNED:
      status_string = "Retured";
      break;
    default:
      break;
  }
  return status_string;
};

export const getMyTicketRenderStatus = (status) => {
  let status_string = "";
  switch (status) {
    case TICKET_STATES.SELL:
      status_string = "IN SELL LISTING";
      break;
    case TICKET_STATES.OWNED:
      status_string = "OWNED";
      break;
    case TICKET_STATES.OFFER:
      status_string = "ADDED TO ACCEPTED OFFER";
      break;
    case TICKET_STATES.CART:
    case TICKET_STATES.IN_CHECK_OUT:
      status_string = "ADDED TO CART";
      break;
    case TICKET_STATES.IN_ORDER:
      status_string = "ADDED TO ORDER";
      break;
    case TICKET_STATES.INACTIVE:
      status_string = "SELLER INACTIVE";
      break;
    default:
      break;
  }
  return status_string;
};