import {api as userAPI} from '../../apis/userApi.instance'
import {api as userProtectedAPI} from '../../apis/userProtectedApis.instance'
import  * as authTypes  from '../constants/authConstants'
import  { setNotification }  from './notification'
import { notification_types } from '../../js/constants'


// Login Component 
const loginUser = (credentials) => async (dispatch) => {
  dispatch({ type: authTypes.USER_LOGIN_REQUEST, payload: credentials });
  try {
    const { data } = await userAPI.User.loginUser(credentials);
    dispatch({ type: authTypes.USER_LOGIN_SUCCESS, payload: data });
    dispatch(setNotification("Login success", "Login" , notification_types.success))
  } catch (error) {
      dispatch({ type: authTypes.USER_LOGIN_ERROR, payload: error.message });
     dispatch(setNotification(error.response.data.error.message, "Login" , notification_types.error))
  }
}
// Register Component
const register = (credentials) => async (dispatch) => {
  dispatch({ type: authTypes.USER_REGISTER_REQUEST, payload: credentials });
  try {
    const { data } = await userAPI.User.register(credentials);
    dispatch({ type: authTypes.USER_REGISTER_SUCCESS, payload: data });
    dispatch(setNotification("Registration completed successfully", "UserRegister" , notification_types.success))
  } catch (error) {
    dispatch({ type: authTypes.USER_REGISTER_ERROR, payload: error.message });
    dispatch(setNotification(error.response.data.error.message, "UserRegister" , notification_types.error))
  }
}
// Mail Verify Component
const mailValidate = (credentials) => async (dispatch) => {
  dispatch({ type: authTypes.USER_MAILVALIDATE_REQUEST, payload: credentials });
  try {
    const { data } = await userAPI.User.mailValidate(credentials);
    dispatch({ type: authTypes.USER_MAILVALIDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: authTypes.USER_MAILVALIDATE_ERROR, payload: error.message });
    dispatch(setNotification(error.response.data.error.message, "Mail verify" , notification_types.error))
    
  }
}
// Forgot Password
const forgetPassword = (credentials) => async (dispatch) => {
  dispatch({ type: authTypes.USER_FORGOTPASS_REQUEST, payload: credentials });
  try {
    const { data } = await userAPI.User.forgetPassword(credentials);
    dispatch({ type: authTypes.USER_FORGOTPASS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: authTypes.USER_FORGOTPASS_ERROR, payload: error.message });
    dispatch(setNotification(error.response.data.error.message, "Forgot password" , notification_types.error))
  }
}
// Reset Password
const resetPassword = (credentials) => async (dispatch) => {
  dispatch({ type: authTypes.USER_RESETPASS_REQUEST, payload: credentials });
  try {
    const { data } = await userAPI.User.resetPassword(credentials);
    dispatch({ type: authTypes.USER_RESETPASS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: authTypes.USER_RESETPASS_ERROR, payload: error.message });
    dispatch(setNotification(error.response.data.error.message, "Reset password" , notification_types.error))
  }
}
const logOutUser = () => async (dispatch) => {
  dispatch({ type: authTypes.USER_LOGOUT_REQUEST});
  try {
    const { data } = await userProtectedAPI.User.logOutUser();
    dispatch({ type: authTypes.USER_LOGOUT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: authTypes.USER_LOGOUT_ERROR, payload: error.message });
  }
}
const isRegistred = () => async (dispatch) => {
  dispatch({ type: authTypes.USER_REGISTER_RESET_STATUS});
}
export {
  loginUser,
  register,
  mailValidate,
  forgetPassword,
  resetPassword,
  logOutUser,
  isRegistred
}