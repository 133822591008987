import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { USER_STATES, PAGE_URLS } from "../../js/constants";
import { showModal } from "../../store/actions/ui";
 
const PrivateRoute = ({ component: Component, isUserLogged,showModal, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isUserLogged) {
          console.log("User logged out");
          // not logged in so redirect to login page with the return url
          showModal("login")
          return (
            <Redirect to={{ pathname: PAGE_URLS.HOME, state: { from: props.location } }} />
          );
        }
 
        // check if route is restricted by role
        // if (roles && roles.indexOf(currentUser.role) === -1) {
        //     // role not authorised so redirect to home page
        //     return <Redirect to={{ pathname: '/'}} />
        // }
 
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
 
const mapStateToProps = (state) => {
  return {
    isUserLogged: state.userState.user_status === USER_STATES.LOGGED_IN
  };
};
 
export default connect(mapStateToProps,{showModal})(PrivateRoute);