import {
    SET_RATING,
    SET_BUYER_RATING_STATUS,
    SET_SELLER_RATING_STATUS,
    SET_REVIEW_LIST,
    SET_SELLER_RATINGS_LIST,
    SET_SELLER_REPORT_TYPES_LIST

  } from "../actions/rating.js";
  import produce from "immer";
  import {OPERATION_STATUS} from "../../js/constants"
  
  const INITIAL_STATE = {
      reset_status: false,
      isbuyerRatingSendingStatus:OPERATION_STATUS.YET_TO_START,
      issellerRatingSendingStatus:OPERATION_STATUS.YET_TO_START,
      reviews_list:{
        reviews: [],
        hasMore: false,
        page: null
      },
      seller_ratings:{
        ratings: [],
        hasMore: false,
        page: null
      }
}
    
  
  export const ratingReducer = produce((draft, action) => {
    switch (action.type) {
      case SET_RATING:
        draft.reset_status = action.payload;
        break;
        case SET_BUYER_RATING_STATUS:
        draft.isbuyerRatingSendingStatus = action.payload;
        break;
        case SET_SELLER_RATING_STATUS:
        draft.issellerRatingSendingStatus = action.payload;
        break;
        case SET_REVIEW_LIST:
        draft.reviews_list = action.payload;
        break;
        case SET_SELLER_RATINGS_LIST:
          draft.seller_ratings = action.payload;
        break;
        case SET_SELLER_REPORT_TYPES_LIST:
          draft.seller_report_types = action.payload;
        break;
      default:
        break;
    }
  }, INITIAL_STATE);
  