import { 
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_ERROR,
    getCollectionsSuccess,
    getCollectionsError,
    setCollections,

    GET_NEXT_COLLECTIONS,
    GET_NEXT_COLLECTIONS_SUCCESS,
    GET_NEXT_COLLECTIONS_ERROR,
    getNextCollectionsSuccess,
    getNextCollectionsError,

    ADD_COLLECTION,
    ADD_COLLECTION_SUCCESS,
    ADD_COLLECTION_ERROR,
    addCollectionSuccess,
    addCollectionError,
    setaddCollectionstatus,
    getCollections,

    EDIT_COLLECTION,
    EDIT_COLLECTION_SUCCESS,
    EDIT_COLLECTION_ERROR,
    editCollectionSuccess,
    editCollectionError,

    DELETE_COLLECTION,
    DELETE_COLLECTION_SUCCESS,
    DELETE_COLLECTION_ERROR,
    deleteCollectionSuccess,
    deleteCollectionError,

    REMOVE_COLLECTION,
    REMOVE_COLLECTION_SUCCESS,
    REMOVE_COLLECTION_ERROR,
    removeCollectionSuccess,
    removeCollectionError,
    removeCollectionStatus
} from "../../actions/myCollection";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
export const getCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_COLLECTIONS) {
      dispatch(
        apiRequest(
          "GET",
          "/seller/collection.php",
          action.payload,
          null,
          getCollectionsSuccess,
          getCollectionsError,
          true,
          "[mycollection]"
        )
      );
      dispatch(showSpinner("mycollection_init"));
    }
  };
  
  export const processGetCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_COLLECTIONS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
          if (page) {
             hasMore = true;
          }
      }
      const newData = {
        collections: action.payload.data.items,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setCollections(newData));
      dispatch(hideSpinner("mycollection_init"));
    }
    if (action.type === GET_COLLECTIONS_ERROR) {
      dispatch(hideSpinner("mycollection_init"));
    }
  };
  export const getNextCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_NEXT_COLLECTIONS) {
      dispatch(
        apiRequest(
          "GET",
          "/seller/collection.php",
          action.payload,
          null,
          getNextCollectionsSuccess,
          getNextCollectionsError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processGetNextCollection = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_COLLECTIONS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
          if (page) {
             hasMore = true;
          }
      }
      const prevtickets = getState().collection.collections;
    const currtickets = [...prevtickets, ...action.payload.data.items];
      const newData = {
        collections:currtickets,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setCollections(newData));
      
    }
    if (action.type === GET_NEXT_COLLECTIONS_ERROR) {
    }
  };


  export const addCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === ADD_COLLECTION) {
      dispatch(
        apiRequest(
          "POST",
          "/seller/collection.php",
          null,
          action.payload,
          addCollectionSuccess,
          addCollectionError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processaddCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === ADD_COLLECTION_SUCCESS) {
      if(action.payload.data){
        dispatch( setaddCollectionstatus(true));
        dispatch( getCollections());
        dispatch(
          setNotification(
            "Collection created",
            "mycollection_create",
            notification_types.success
          )
        );
      }
     
    }
    if (action.type === ADD_COLLECTION_ERROR) {
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "mycollection_create",
          notification_types.error
        )
      );
    }
  };

  export const editCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === EDIT_COLLECTION) {
      dispatch(
        apiRequest(
          "PUT",
          "/seller/collection.php",
          null,
          action.payload,
          editCollectionSuccess,
          editCollectionError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processeditCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === EDIT_COLLECTION_SUCCESS) {
      if(action.payload.data){
        dispatch( setaddCollectionstatus(true));
        dispatch( getCollections());
        dispatch(
          setNotification(
            "Collection updated",
            "mycollection_update",
            notification_types.success
          )
        );
      }
     
    }
    if (action.type === EDIT_COLLECTION_ERROR) {
    }
  };

  export const deleteCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === DELETE_COLLECTION) {
      dispatch(
        apiRequest(
          "DELETE",
          "/seller/collection.php",
          null,
          action.payload,
          deleteCollectionSuccess,
          deleteCollectionError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processdeleteCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === DELETE_COLLECTION_SUCCESS) {
      if(action.payload.data){
        dispatch( getCollections());
        dispatch(
          setNotification(
            "Collection deleted",
            "mycollection_delete",
            notification_types.success
          )
        );
      }
     
    }
    if (action.type === DELETE_COLLECTION_ERROR) {
    }
  };

  export const removeCollectionFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === REMOVE_COLLECTION) {
      dispatch(
        apiRequest(
          "DELETE",
          "/seller/collection.php",
          null,
          action.payload,
          removeCollectionSuccess,
          removeCollectionError,
          true,
          "[mycollection]"
        )
      );
    }
  };
  
  export const processremoveCollection = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === REMOVE_COLLECTION_SUCCESS) {
     dispatch(removeCollectionStatus(true))
     dispatch(
      setNotification(
        "Ticket removed",
        "mycollection_remove",
        notification_types.success
      )
    );
    }
    if (action.type === REMOVE_COLLECTION_ERROR) {
    }
  };
export const myCollectionMdl = [
    getCollectionFlow,
    processGetCollection,
    getNextCollectionFlow,
    processGetNextCollection,
    addCollectionFlow,
    processaddCollection,
    editCollectionFlow,
    processeditCollection,
    deleteCollectionFlow,
    processdeleteCollection,
    removeCollectionFlow,
    processremoveCollection
]