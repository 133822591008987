export const GET_MY_BUY_OFFERS                      = '[myoffers] GET buy offers';
export const FETCH_MY_BUY_OFFERS_SUCCESS            = '[myoffers] Fetch buy offers success';
export const FETCH_MY_BUY_OFFERS_ERROR              = '[myoffers] Fetch buy offers Error';
export const GET_NEXT_MY_BUY_OFFERS                 = '[myoffers] GET Next buy offers';
export const FETCH_NEXT_MY_BUY_OFFERS_SUCCESS       = '[myoffers] Fetch next buy offers success';
export const FETCH_NEXT_MY_BUY_OFFERS_ERROR         = '[myoffers] Fetch next buy offers Error';
export const UPDATE_MY_BUY_OFFERS                   = '[myoffers] Update buy offers';

export const GET_MY_SELL_OFFERS                      = '[myoffers] GET sell offers';
export const FETCH_MY_SELL_OFFERS_SUCCESS            = '[myoffers] Fetch sell offers success';
export const FETCH_MY_SELL_OFFERS_ERROR              = '[myoffers] Fetch sell offers Error';
export const GET_NEXT_MY_SELL_OFFERS                 = '[myoffers] GET Next sell offers';
export const FETCH_NEXT_MY_SELL_OFFERS_SUCCESS       = '[myoffers] Fetch next sell offers success';
export const FETCH_NEXT_MY_SELL_OFFERS_ERROR         = '[myoffers] Fetch next sell offers Error';
export const UPDATE_MY_SELL_OFFERS                   = '[myoffers] Update sell offers';

export const SET_OFFER_ITEMS                 = '[myoffers] set offer items'

export const SEND_OFFER = "[myoffers] Send offer";
export const SEND_OFFER_SUCCESS = "[myoffers] Send offer Success";
export const SEND_OFFER_ERROR = "[myoffers] Send offer Error";
export const SEND_OFFER_DATA = '[myoffers] Send offer set data';
export const SEND_OFFER_STATUS = '[myoffers] Send offer set status';

export const SELECT_MY_OFFER                    = '[myoffers] Select';
export const FETCH_SINGLE_MY_OFFER_SUCCESS      = '[myoffers] Fetch single success';
export const FETCH_SINGLE_MY_OFFER_ERROR        = '[myoffers] Fetch single Error';
export const UPDATE_MY_OFFER                   = '[myoffers] Update single';



// For fetching tickets
export const getMyBuyOffers = (filter = {}) => ({
  type: GET_MY_BUY_OFFERS,
  payload: {...filter, type: "BUYER"}
});

// For fetching moments
export const fetchMyBuyOffersSuccess = (data) => ({
  type: FETCH_MY_BUY_OFFERS_SUCCESS,
  payload: data,
  meta: {
    to_append: false
  }
});

export const fetchMyBuyOffersError = (data) => ({
  type: FETCH_MY_BUY_OFFERS_ERROR,
  payload: data
});



// For fetching tickets for next iteration
export const getNextMyBuyOffers = (filter) => ({
  type: GET_NEXT_MY_BUY_OFFERS,
  payload: {...filter, type: "BUYER"}
});


export const fetchNextMyBuyOffersSuccess = (data) => ({
  type: FETCH_NEXT_MY_BUY_OFFERS_SUCCESS,
  payload: data,
  meta: {
    to_append: true
  }
});

export const fetchNextMyBuyOffersError = (data) => ({
  type: FETCH_NEXT_MY_BUY_OFFERS_ERROR,
  payload: data
});


export const setMyBuyOffers = (data) => ({
  type   : UPDATE_MY_BUY_OFFERS,
  payload: data
});

// Sell offers
// For fetching tickets
export const getMySellOffers = (filter = {}) => ({
  type: GET_MY_SELL_OFFERS,
  payload: {...filter, type: "SELLER"}
});

// For fetching moments
export const fetchMySellOffersSuccess = (data) => ({
type: FETCH_MY_SELL_OFFERS_SUCCESS,
payload: data,
meta: {
  to_append: false
}
});

export const fetchMySellOffersError = (data) => ({
type: FETCH_MY_SELL_OFFERS_ERROR,
payload: data
});



// For fetching tickets for next iteration
export const getNextMySellOffers = (filter) => ({
  type: GET_NEXT_MY_SELL_OFFERS,
  payload: {...filter, type: "SELLER"}
});


export const fetchNextMySellOffersSuccess = (data) => ({
  type: FETCH_NEXT_MY_SELL_OFFERS_SUCCESS,
  payload: data,
  meta: {
    to_append: true
  }
});

export const fetchNextMySellOffersError = (data) => ({
  type: FETCH_NEXT_MY_SELL_OFFERS_ERROR,
  payload: data
});


export const setMySellOffers = (data) => ({
  type   : UPDATE_MY_SELL_OFFERS,
  payload: data
});



//***************************SEND OFFER****************************** */
export const setOfferItems = (data) => ({
  type: SET_OFFER_ITEMS,
  payload: data
});

export const sendOffer = (data) => ({
  type: SEND_OFFER,
  payload: data,
});


export const sendOfferSuccess = (data) => ({
  type: SEND_OFFER_SUCCESS,
  payload: data,
});

export const sendOfferError = (data) => ({
  type: SEND_OFFER_ERROR,
  payload: data,
});

export const setSendOfferData = (data) => ({
  type: SEND_OFFER_DATA,
  payload: data,
});

export const setSendOfferStatus = (data) => ({
  type: SEND_OFFER_STATUS,
  payload: data,
});


export const selectMyOffer = (offer_no) => ({
  type: SELECT_MY_OFFER,
  payload: {offer_no: offer_no}
});


export const fetchSingleMyOfferSuccess = (data) => ({
  type: FETCH_SINGLE_MY_OFFER_SUCCESS,
  payload: data
});

export const fetchSingleMyOfferError = (data) => ({
  type: FETCH_SINGLE_MY_OFFER_ERROR,
  payload: data
});

export const setSingleMyOffer = (data) => ({
  type   : UPDATE_MY_OFFER,
  payload: data
});