import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { Container, Spinner } from "react-bootstrap";
import SellOrdersView from "./SellOrderView";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../../store/actions/ui";
import { resetRating} from "../../../store/actions/rating";
import SellOrderDetailsView from "../SellOrder/SellOrderDetailsView/SellOrderDetailsView";
import RateAndReviews from "./RateAndReview/SellerRateAndReview";
import TrackingInfoCreateForm from "../SellOrder/TrackingInfoCreateForm/TrackingInfoCreateForm";
import {addSellerRating,addSellerReview,setSellerRatingStatus} from "../../../store/actions/rating"
import BuyerAcknowledgementDialog from "../../../Dialogs/BuyerAcknowledgementDialog/BuyerAcknowledgementDialog";
import { OPERATION_STATUS } from "../../../js/constants";
import {
  getMySellOrders,
  getNextMySellOrders,
  addTrackingInformation,
  editTrackingInformation,
  approveOrderDeliveredSetStatus,
  setTrackingInfoStatus,
} from "../../../store/actions/myOrder";

const SellOrderPage = ({
  showModal,
  hideModal,
  next_page,
  has_more,
  getMySellOrders,
  getNextMySellOrders,
  sell_order_details_loading,
  sell_orders,
  addTrackingInformation,
  editTrackingInformation,
  isLoading,
  addSellerRating,
  issellerRatingSendingStatus,
  approveOrderDeliveredSubmit,
  setSellerRatingStatus,
  approveOrderDelivered,
  approveOrderDeliveredStatus,
  approveOrderDeliveredPending,
  addTrackingInfoStatus,
  addTrackingInfoPending,
  approveOrderDeliveredSetStatus,
  trackingInfoShowModal,
  reset_rating,
  resetRating,
  // review
  addSellerReview
}) => {
  useEffect(() => {
    getMySellOrders();
  }, []);

  const [showMyOrderList, setShowOrderList] = useImmer(true);
  const [showOrderDetail, setShowOrderDetail] = useImmer(false);
  // THIS FUNCTION FOR GET BUYER ORDER DETAILS
  var initial_FulfillOrder = {
    items: "",
  };
  const [itemsState, setItems] = useImmer(initial_FulfillOrder);

  useEffect(() => {
    if (itemsState.items) {
      let filtered_item = sell_orders.filter(
        (s) => s.order_no === itemsState.items.order_no
      );
      setItems((draft) => {
        draft.items = filtered_item[0];
      });
    }
  }, [sell_orders]);

  const sellOrderDetailsView = (items) => {
    setItems((draft) => {
      draft.items = items;
    });
    setShowOrderList(false);
    setShowOrderDetail(true);
  };
  const viewRateByList = (items) => {
    setItems((draft) => {
      draft.items = items;
    });
    setShowOrderList(false);
    setShowOrderDetail(true);
    show(items);
  };
  const show =(items)=>{
    if(items.ratings.seller_ratings.communication_rating !==""){
      setratingState((draft) => {
        draft.operation= "Update";
        draft.communication_rating =items.ratings.seller_ratings.communication_rating;
        draft.description_rating =items.ratings.seller_ratings.description_rating;
        draft.shipping_rating =items.ratings.seller_ratings.shipping_rating;
        draft.review =items.review.seller_review;
      });
      showModal("SellerRatings");
    }else{
      setratingState((draft) => {
        draft.operation= "Add";
        draft.communication_rating ="";
        draft.description_rating ="";
        draft.shipping_rating ="";
        draft.review ="";
      });
      showModal("SellerRatings");
    }
  }
  
  const backToMainFromDetail = () => {
    setShowOrderList(true);
    setShowOrderDetail(false);
  };
  //******************************THIS SECTION FOR BUYER FILTER COMPONENT************************************* */
  const filternames = {
    DATEFROM: "date_from",
    DATETO: "date_to"
  };
  //GET THE FILTER VALUE
  const handleFilterChange = (name, e) => {
    setFilter((draft) => {
      const index = draft.findIndex((d) => d.name === name);
      if (index !== -1) {
        draft[index].value =
          draft[index].type === "select" ? e : e.target.value;
      }
    });
  };
  //FILTER OPTIONS
  const statusOptions = [
    { id: 1, value: "OPEN", label: "OPEN" },
    { id: 2, value: "SHIPPED", label: "SHIPPED" },
    { id: 3, value: "DELIVERED", label: "DELIVERED" },
  ];
  const initial_filters = [
    {
      placeholder: "Date from",
      type: "date",
      value: "",
      name: filternames.DATEFROM,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Date to",
      type: "date",
      value: "",
      name: filternames.DATETO,
      onChange: handleFilterChange,
    }
  ];

  const [FilterState, setFilter] = useImmer(initial_filters);

  //GET ALL FILTER VALUE FOR EACH FILTERS
  const getFilterValues = () => {
    var filter_values = {};
    FilterState.forEach((f) => {
      if (f.value) {
        filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
      }
    });
    return filter_values;
  };
  //Filter Submit Process
  const filterSubmit = () => {
    let filters = {
      ...getFilterValues(),
    };
    getMySellOrders(filters);
  };
  // CLEAR FILTER VALUE
  const reset = () => {
    setFilter((draft) => {
      draft.forEach((f) =>
        f.type === "select" ? (f.value = null) : (f.value = "")
      );
    });
    getMySellOrders();
  };
  // /////////////////////////////SELLER NEXT PAGE ////////////////////////////
  const SellernextPage = () => {
    let filters = {
      page: next_page,
      ...getFilterValues(),
    };
    getNextMySellOrders(filters);
  };
  //////////////////////////////////Add Fullfilment details /////////////////////
  var initial_FulfillOrder = {
    operation: "Add",
    order_no: "",
    carrier: "",
    service_name: "",
    tracking_id: "",
    delivery_charge: "",
    estimated_delivery_at: "",
  };
  const [ful_fill_state, setfulfillDetails] = useImmer(initial_FulfillOrder);
  const onAddfullfillmentModel = (order_no) => {
    setfulfillDetails((draft) => {
      draft.order_no = order_no;
      draft.operation = "Add";
      draft.carrier = "";
      draft.service_name = "";
      draft.tracking_id = "";
      draft.delivery_charge = "";
      draft.estimated_delivery_at = "";
    });
    showModal("trackingInfoModel");
  };

  const onEditfullfillmentModel = (items) => {
    setfulfillDetails((draft) => {
      draft.order_no = items.order_no;
      draft.operation = "Update";
      draft.carrier = items.fulfillment_information.carrier;
      draft.service_name = items.fulfillment_information.service_name;
      draft.tracking_id = items.fulfillment_information.tracking_id;
      draft.delivery_charge = items.fulfillment_information.delivery_cost;
      draft.estimated_delivery_at =
        items.fulfillment_information.delivery_expected_date;
    });
    showModal("trackingInfoModel");
  };
  const OnClear = () => {
    // setfulfillDetails({ ...initial_FulfillOrder });
    setfulfillDetails((draft) => {
      draft.order_no = "";
      draft.operation = "Add";
      draft.carrier = "";
      draft.service_name = "";
      draft.tracking_id = "";
      draft.delivery_charge = "";
      draft.estimated_delivery_at = "";
    });
    hideModal("trackingInfoModel");
  };
  const OnSubmit = (content) => {
    if (ful_fill_state.operation === "Add") {
      addTrackingInformation(content);
    } else if (ful_fill_state.operation === "Update") {
      editTrackingInformation(content);
    }
  };
    //******************************THIS SECTION FOR RATING************************************* */
    const onRatingSubmit =(content)=>{
      if(content.review.length>0){
    const reviewData={
            order_no:itemsState.items.order_no,
            sub_order_id:itemsState.items.fulfillment_order_id,
            description:content.review
          }
        addSellerReview(reviewData)
      }
      const data ={
        communication_rating : content.communication_rating,
        description_rating :content.description_rating,
        shipping_rating :content.shipping_rating,
        order_no:itemsState.items.order_no,
        suborder_no:itemsState.items.fulfillment_order_id
      }
      addSellerRating(data)
    }
    useEffect(()=>{
      if(issellerRatingSendingStatus===false){
        setSellerRatingStatus(OPERATION_STATUS.YET_TO_START)
  
      }
    },[issellerRatingSendingStatus])

    // Add And Edit Modal
var initial_rating = {
  operation: "Add",
  communication_rating :"",
  description_rating :"",
  shipping_rating :"",
  review:""
};
const [ratingState, setratingState] = useImmer(initial_rating);

const onAddRating=(e)=>{
  e.preventDefault();
  setratingState((draft) => {
    draft.operation= "Add";
    draft.communication_rating ="";
    draft.description_rating ="";
    draft.shipping_rating ="";
    draft.review ="";
  });
  showModal("SellerRatings");
}

  const onEditRating=(e)=>{
    e.preventDefault();
    setratingState((draft) => {
      draft.operation= "Update";
      draft.communication_rating =itemsState.items.ratings.seller_ratings.communication_rating;
      draft.description_rating =itemsState.items.ratings.seller_ratings.description_rating;
      draft.shipping_rating =itemsState.items.ratings.seller_ratings.shipping_rating;
      draft.review =itemsState.items.review.seller_review;
    });
    showModal("SellerRatings");
  }
  const onRatingClear = (e) => {
    setratingState((draft) => {
      draft.operation= "Add";
      draft.communication_rating ="";
      draft.description_rating ="";
      draft.shipping_rating ="";
      draft.review ="";
    });
    hideModal("SellerRatings");
  };
  useEffect(()=>{
    if(reset_rating ===true){
      onRatingClear()
   resetRating(false)
    }
    
  },[reset_rating])

  useEffect(() => {
    if (approveOrderDeliveredStatus === OPERATION_STATUS.SUCCESS) {
      approveOrderDeliveredSetStatus(OPERATION_STATUS.YET_TO_START);
    } else if (approveOrderDeliveredStatus === OPERATION_STATUS.FAILURE) {
      approveOrderDeliveredSetStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [approveOrderDeliveredStatus]);

  useEffect(() => {
    if (addTrackingInfoStatus === OPERATION_STATUS.SUCCESS) {
      setTrackingInfoStatus(OPERATION_STATUS.YET_TO_START);
    } else if (addTrackingInfoStatus === OPERATION_STATUS.FAILURE) {
      setTrackingInfoStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [addTrackingInfoStatus]);

  return (
    <React.Fragment>
      <Container>
        {isLoading || approveOrderDeliveredPending ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            {showMyOrderList && (
              <div className="my-4">
                <SellOrdersView
                  SellernextPage={SellernextPage}
                  has_more={has_more}
                  sell_order_list={sell_orders}
                  sellOrderDetailsView={sellOrderDetailsView}
                  viewRateByList={viewRateByList}
                  sellerFilterState={FilterState}
                  onApply={filterSubmit}
                  onClear={reset}
                  onAddfullfillmentModel={onAddfullfillmentModel}
                  onEditfullfillmentModel={onEditfullfillmentModel}
                  approveOrderDelivered={approveOrderDelivered}
                />
              </div>
            )}
            {showOrderDetail && (
        <SellOrderDetailsView
          sell_order_details_loading={sell_order_details_loading}
          backToMainFromDetail={backToMainFromDetail}
          onAddfullfillmentModel={onAddfullfillmentModel}
          onEditfullfillmentModel={onEditfullfillmentModel}
          items={itemsState.items}
          // display Data
          order_no={itemsState.items.order_no}
          order_date={itemsState.items.order_at}
          buyer_name={itemsState.items.buyer_name}
          seller_name={itemsState.items.seller_name}
          delivery_expected_date={
            itemsState.items.fulfillment_information.delivery_expected_date
          }
          delivery_status={itemsState.items.fulfillment_status}
          carrier={itemsState.items.fulfillment_information.carrier}
          service_name={itemsState.items.fulfillment_information.service_name}
          tracking_id={itemsState.items.fulfillment_information.tracking_id}
          base_price={itemsState.items.pricing_summary.base_price}
          tax_price={itemsState.items.pricing_summary.tax_price}
          processing_fee={itemsState.items.pricing_summary.processing_fee}
          total_price={itemsState.items.pricing_summary.total_price}
          due_amount={itemsState.items.due_amount}
          type_of_payment={itemsState.items.payment_summary.type}
          shipping_add={itemsState.items.shipping_add}
          line_items={itemsState.items.line_items}
          buyer_ratings={itemsState.items.ratings.buyer_ratings}
          seller_ratings={itemsState.items.ratings.seller_ratings}
          buyer_review={itemsState.items.review.buyer_review}
          seller_review={itemsState.items.review.seller_review}
          onEditRating={onEditRating}
          onAddRating={onAddRating}
        />
      )}
          </>
        )}
      </Container>
     

      <TrackingInfoCreateForm
        order_no={ful_fill_state.order_no}
        operation={ful_fill_state.operation}
        carrier={ful_fill_state.carrier}
        service_name={ful_fill_state.service_name}
        tracking_id={ful_fill_state.tracking_id}
        delivery_charge={ful_fill_state.delivery_charge}
        estimated_delivery_at={ful_fill_state.estimated_delivery_at}
        OnClear={OnClear}
        OnSubmit={OnSubmit}
        loading={addTrackingInfoPending}
        show={trackingInfoShowModal}
      />
      <BuyerAcknowledgementDialog />
      <RateAndReviews
      onRatingSubmit={onRatingSubmit}
      onClear={onRatingClear}
      issellerRatingSendingStatus={issellerRatingSendingStatus}
      operation= {ratingState.operation}
      communicationRating ={ratingState.communication_rating}
      descriptionRating ={ratingState.description_rating}
      shippingRating ={ratingState.shipping_rating}
      review={ratingState.review}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    issellerRatingSendingStatus:
    state.rating.issellerRatingSendingStatus === OPERATION_STATUS.PENDING,
   // sell_order_list: state.myOrder.sell.order ? state.myOrder.sell.order : [],
    sell_orders: state.myOrder.sell.orders,
    has_more: state.myOrder.sell.hasMore,
    next_page: state.myOrder.sell.nextPage,
    isLoading:
      "mysellorder_init" in state.ui.loaders
        ? state.ui.loaders["mysellorder_init"]
        : true,
    sell_order_details_loading:
      "sell_order_detail" in state.ui.loaders
        ? state.ui.loaders["sell_order_detail"]
        : true,
    approveOrderDeliveredStatus:
      state.myOrder.sell.approve_order_delivered.status,
    approveOrderDeliveredPending:
      state.myOrder.sell.approve_order_delivered.status ===
      OPERATION_STATUS.PENDING,
    addTrackingInfoStatus: state.myOrder.sell.tracking_info.status,
    addTrackingInfoPending:
      state.myOrder.sell.tracking_info.status === OPERATION_STATUS.PENDING,
    trackingInfoShowModal: state.ui.activeModal === "trackingInfoModel",
    reset_rating:state.rating.reset_status
  };
};

export default connect(mapStateToProps, {
  getMySellOrders,
  getNextMySellOrders,
  addTrackingInformation,
  editTrackingInformation,
  showModal,
  hideModal,
  addSellerRating,
  setSellerRatingStatus,
  approveOrderDeliveredSetStatus,
  setTrackingInfoStatus,
  addSellerReview,
  resetRating
})(SellOrderPage);
