import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../store/actions/ui";
import PreviewOrderDetails from "./PreviewOrderDetailsView";
import {
  selectOrder,
  approveOrderPaymentStatus,
  setApproveOrderPaymentStatus,
  orderCancelStatus,
  orderExpiryDate,
  orderCancelledStatus
} from "../../store/actions/orderPayment";
import { setNotification } from "../../store/actions/notification";
import { OPERATION_STATUS, notification_types } from "../../js/constants";

const PreviewOrderDetailsPage = ({
  order,
  error,
  orderLoading,
  selectOrder,
  approveOrderPaymentStatus,
  setApproveOrderPaymentStatus,
  order_payment_status,
  setNotification,
  paypal_client_id,
  expiry_status,
  orderCancelStatus,
  orderExpiryDate,
  order_cancelled_status,
  orderCancelledStatus,
  orderStatus,
  showModal
  
}) => {
  let { id } = useParams();
  
  const [refreshOrder, setRefreshOrder] = useImmer(false);
  const [approvePaymentLoading, setApprovePaymentLoading] = useImmer(false);
  const [cancelOrderLoading, setCancelOrderLoading] = useImmer(false);

  // DISPATCH THE API
  useEffect(() => {
    selectOrder(id);
  }, [refreshOrder]);

  const approveOrderPayment = (details, order_no) => {
    setApprovePaymentLoading(true)
    let approve_data = {
      order_no: order_no,
      payment_result: {
        is_paid: true,
        token: details.id,
      },
    };
    approveOrderPaymentStatus(approve_data);
  };

  
  /*
  Author - BM
  Date - 2022-01-06
  comment - for the cancel order -BM
  params - order_no
   */

  const cancelledOrder = (order_no) => {
    showModal("alertbox");
  };
  const onCancelSubmit = () => {
    setCancelOrderLoading(true)
    let orderCancel_data = {
      order_no: order.order_no
    };
    orderCancelStatus(orderCancel_data);
  };
  

  useEffect(() => {
    if (order_payment_status === OPERATION_STATUS.SUCCESS) {
      setApproveOrderPaymentStatus(OPERATION_STATUS.YET_TO_START);
      setApprovePaymentLoading(false)
      setRefreshOrder(!refreshOrder);
    } else if (order_payment_status === OPERATION_STATUS.FAILURE) {
      setApproveOrderPaymentStatus(OPERATION_STATUS.YET_TO_START);
      setApprovePaymentLoading(false)
    }
  }, [order_payment_status]);

  // for the cancel order -BM
  useEffect(() => {
    if (order_cancelled_status === OPERATION_STATUS.SUCCESS) {
      orderCancelledStatus(OPERATION_STATUS.YET_TO_START);
      setCancelOrderLoading(false)
      setRefreshOrder(!refreshOrder);
    } else if (order_cancelled_status === OPERATION_STATUS.FAILURE) {
      orderCancelledStatus(OPERATION_STATUS.YET_TO_START);
      setCancelOrderLoading(false)
    }
  }, [order_cancelled_status]);

  const cancelOrderPayment = (order_no) => {
    // console.log("Payment cancelled", order_no);
    // setNotification(
    //   "Payment cancelled",
    //   "order_payment_status",
    //   notification_types.error
    // )
  };
  const errorOrderPayment = (order_no, err) => {
    setNotification(
      "Payment failed. Please contact administrator or try again later",
      "order_payment_status",
      notification_types.error
    )
  };
  const handleOrderExpiryDate = (order_no) => {
    let expiry_params = {
      order_no: order_no,
      action: 'Isexpired'
    };
    orderExpiryDate(expiry_params);
  };
  const cancelOrder = (order_no) => {
    setNotification(
      "Order cancelled",
      "order_payment_status",
      notification_types.error
    )
  };

  return (
    <React.Fragment>
      {(orderLoading || approvePaymentLoading || cancelOrderLoading) ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div>
          <Container className="my-4">
            <h5 className="font-weight-bold">Order details</h5>
            {order && (
              <PreviewOrderDetails
                order_no={order.order_no}
                order_date={order.order_date}
                expiry_date={order.expiry_date}
                payment_status={order.payment_summary.status}
                billing_address={order.billing_address}
                shipping_address={order.shipping_address}
                sub_orders={order.sub_orders}
                pricing_summary={order.pricing_summary}
                payment_summary={order.payment_summary}
                onApprovePayment={approveOrderPayment}
                onCancelPayment={cancelOrderPayment}
                onErrorPayment={errorOrderPayment}
                paypal_client_id={paypal_client_id}
                cancelOrder={cancelledOrder}
                expiry_check={handleOrderExpiryDate}
                expiry_status={expiry_status}
                status = {order.status}
                onCancelSubmit = {onCancelSubmit}
                orderExpiryDate= {handleOrderExpiryDate}
              />
            )}
          </Container>
        </div>
      )}
      
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    order: state.orderpayment.order.info,
    error: state.orderpayment.order.error,
    orderLoading:
      "order_single" in state.ui.loaders
        ? state.ui.loaders["order_single"]
        : true,
    order_payment_status: state.orderpayment.approve_payment_status,
    order_cancelled_status: state.orderpayment.order_cancelled_status,
    paypal_client_id: state.app.paypal_client_id,
    expiry_status: state.orderpayment.ExpiryStatus,
    expiry_status_process: state.orderpayment.order_Expiry_status
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectOrder,
  approveOrderPaymentStatus,
  setApproveOrderPaymentStatus,
  setNotification,
  orderCancelStatus,
  orderExpiryDate,
  orderCancelledStatus
})(PreviewOrderDetailsPage);
