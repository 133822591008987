import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import EditableSelect from "../EditableSelect/EditableSelect";

const EventMandatoryItems = ({
  value,
  error,
  options,
  onChangeValue,
  onEditOption,
  onEditInputCancel,
}) => {
  return (
    <>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label className="requiredField">
              Event Name
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.name.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the event name"
              value={value.name}
              name="name"
              onChange={(e) => {
                onChangeValue("name", "", e);
              }}
              autoComplete="off"
            />
            {error.name.length > 0 && (
              <span className="invalid-feedback">{error.name}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label className="requiredField">
              Venue
            </ReactBootstrap.Form.Label>
            <EditableSelect
              value={value.currentVenue}
              error={error.currentVenue}
              options={options.venues}
              onChangeValue={onChangeValue}
              placeholder="Select or enter the event venue"
              name="currentVenue"
              onEditOption={onEditOption}
              onEditInputCancel={onEditInputCancel}
              index=""
            />
            {error.currentVenue.length > 0 && (
              <span className="invalid-feedback">{error.currentVenue}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Label className="requiredField">
            Event Date
          </ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Control
            className={error.date.length > 0 ? "is-invalid" : ""}
            type="date"
            placeholder="Enter the event date"
            value={value.date}
            name="date"
            onChange={(e) => {
              onChangeValue("date", "", e);
            }}
          />
          {error.date.length > 0 && (
            <span className="invalid-feedback">{error.date}</span>
          )}
        </ReactBootstrap.Col>

      
      </ReactBootstrap.Row>
    </>
  );
};

export default EventMandatoryItems;
