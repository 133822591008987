import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  GET_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  loginError,
  loginSuccess,
  logoutSuccess,
  logoutError,
  fetchUserDataSuccess,
  fetchUserDataError,
  refreshSuccess,
  refreshError,
  setRefreshStatus,
  setUserData,
  setTokens,
  setUserPermission,
  setLoginStatus,
  setUserStatus,
  setLogoutStatus,
  refresh
} from "../../actions/auth";
import { setNotification } from "../../actions/notification";
import { getCartDetails, clearCart } from "../../actions/myCart";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import {
  notification_types,
  OPERATION_STATUS,
  USER_PERMISSIONS,
  USER_STATES,
  USER_PERMISSIONS_STATES
} from "../../../js/constants";

const processUserDetails = (data) => {
  let tokens = {
    access: {
      token: data.token,
      expiry: data.expiry,
    },
    refresh: {
      token: data.refresh_token,
    },
  };

  let permissions = data.user_data.permissions.map((permission) => {
    if (permission.status.toLowerCase() === USER_PERMISSIONS_STATES.ACTIVE) {
      if (USER_PERMISSIONS.BUY === permission.permission.toLowerCase()) {
        // return USER_PERMISSIONS.BUY;
        return {
          permission: USER_PERMISSIONS.BUY,
          status: USER_PERMISSIONS_STATES.ACTIVE
        }
      } else if (
        USER_PERMISSIONS.SELL === permission.permission.toLowerCase()
      ) {
        // return USER_PERMISSIONS.SELL;
        return {
          permission: USER_PERMISSIONS.SELL,
          status: USER_PERMISSIONS_STATES.ACTIVE
        }
      }
    }else if(permission.status.toLowerCase() === USER_PERMISSIONS_STATES.INACTIVE){
      if (USER_PERMISSIONS.BUY === permission.permission.toLowerCase()) {
        // return USER_PERMISSIONS.BUY;
        return {
          permission: USER_PERMISSIONS.BUY,
          status: USER_PERMISSIONS_STATES.INACTIVE
        }
      } else if (
        USER_PERMISSIONS.SELL === permission.permission.toLowerCase()
      ) {
        // return USER_PERMISSIONS.SELL;
        return {
          permission: USER_PERMISSIONS.SELL,
          status: USER_PERMISSIONS_STATES.INACTIVE
        }
      }
    }
  });

  let user_data = {
    id: data.user_data.id,
    name: data.user_data.name,
    mail: data.user_data.mail,
    mobile_no: data.user_data.mobile_no ? data.user_data.mobile_no : "",
    is_email_verified: data.user_data.is_email_verified === 1 ? true : false,
    is_admin: data.user_data.is_admin === 1 ? true : false,
    profile_url: null,
  };

  if (data.user_data.profile_url) {
    user_data.profile_url = {
      image_50: data.user_data.profile_url,
      image_200: data.user_data.profile_url,
      image_400: data.user_data.profile_url,
    };
  }

  return {
    tokens: tokens,
    permissions: permissions,
    user_data: user_data,
  };
};

// this middleware only care about the getBooks action
export const loginFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOGIN) {
    dispatch(showSpinner("login"));
    dispatch(setLoginStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/user/user_settings.php?action=login",
        null,
        action.payload,
        loginSuccess,
        loginError,
        false,
        "[auth] login"
      )
    );
  }
};

// this middleware only care about the getBooks action
export const processLogin = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOGIN_SUCCESS) {
    dispatch(
      setNotification("Login success", "Login", notification_types.success)
    );
    dispatch(setLoginStatus(OPERATION_STATUS.SUCCESS));
    // dispatch(setUserData(action.payload));
    // dispatch(processLoginRefreshResponse(action.payload));
    let data = processUserDetails(action.payload.data);
    dispatch(setUserData(data.user_data));
    dispatch(setUserPermission(data.permissions));
    dispatch(setTokens(data.tokens));
    dispatch(setUserStatus(USER_STATES.LOGGED_IN));
    dispatch(hideSpinner("login"));
    dispatch(getCartDetails());
    // Call refresh before token expiry
    let timer_id = setTimeout(() => {
      dispatch(refresh())
    }, new Date((data.tokens.access.expiry*1000) - 5000) - Date.now())
  }

  if (action.type === LOGIN_ERROR) {
    let error = action.payload.response
    let error_msg = "Login failed"
    if("error" in error.data){
      error_msg = error.data.error.message
    }
    dispatch(setNotification(error_msg, "Login" , notification_types.error))
    dispatch(setLoginStatus(OPERATION_STATUS.FAILURE));
    dispatch(setUserData(null));
    dispatch(setUserPermission([]));
    dispatch(setTokens(null));
    dispatch(setUserStatus(USER_STATES.ANONYMOUS));
    dispatch(hideSpinner("login"));
  }
};

export const refreshFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === REFRESH_TOKEN) {
    // dispatch(showSpinner("refresh"));
    dispatch(setRefreshStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "GET",
        "/user/user_settings.php?action=refresh_token",
        null,
        null,
        refreshSuccess,
        refreshError,
        false,
        "[auth] refresh"
      )
    );
  }
};

// this middleware only care about the getBooks action
export const processRefresh = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === REFRESH_TOKEN_SUCCESS) {
    // dispatch(
    //   setNotification("Login success", "Login", notification_types.success)
    // );
    let data = processUserDetails(action.payload.data);
    dispatch(setUserData(data.user_data));
    dispatch(setUserPermission(data.permissions));
    dispatch(setTokens(data.tokens));
    dispatch(setUserStatus(USER_STATES.LOGGED_IN));
    dispatch(setRefreshStatus(OPERATION_STATUS.SUCCESS));
    // dispatch(setUserData(action.payload));
    // dispatch(processLoginRefreshResponse(action.payload));
    // if(getState().app.state === APP_STATES.LOADING){
    //   dispatch(hideSpinner());
    // }

    // Call refresh before token expiry
    let timer_id = setTimeout(() => {
      dispatch(refresh())
    }, new Date((data.tokens.access.expiry*1000) - 5000) - Date.now())
  }

  if (action.type === REFRESH_TOKEN_ERROR) {
    // dispatch(
    //   setNotification("Login success", "Login", notification_types.success)
    // );
    // dispatch(setUserData(null));
    dispatch(setUserData(null));
    dispatch(setUserPermission([]));
    dispatch(setTokens(null));
    dispatch(setUserStatus(USER_STATES.ANONYMOUS));
    dispatch(clearCart());
    dispatch(setRefreshStatus(OPERATION_STATUS.FAILURE));
    // if(getState().app.state === APP_STATES.LOADING){
    //   dispatch(hideSpinner());
    // }
  }
};

// export const processLogin_RefreshResponse = ({ dispatch }) => (next) => (
//   action
// ) => {
//   next(action);

//   if (action.type === PROCESS_LOGIN_REFRESH_RESPONSE) {
//     let data = action.payload.data;

//     dispatch(setUserData(user_data));
//     dispatch(setUserPermission(permissions));
//     dispatch(setTokens(tokens));
//   }

//   if (action.type === LOGIN_ERROR) {

//     dispatch(setUserData(null));
//     dispatch(setUserPermission([]));
//     dispatch(setTokens(null));
//   }
// };

// Process login response

export const getUserDataFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_USER_DATA) {
    dispatch(
      apiRequest(
        "GET",
        "/user/account/user.php",
        null,
        null,
        fetchUserDataSuccess,
        fetchUserDataError,
        true,
        "[auth] Get user data"
      )
    );
  }
};

// on successful fetch, process the books data
export const processUserData = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_USER_DATA_SUCCESS) {
    let data = action.payload.data;
    let user_data = {
      id: data.id,
      name: data.name,
      mail: data.mail,
      mobile_no: data.mobile_no ? data.mobile_no : "",
      is_email_verified: data.is_email_verified === 1 ? true : false,
      is_admin: data.is_admin === 1 ? true : false,
      profile_url: null,
    };

    if (data.avatar_url) {
      user_data.profile_url = {
        image_50: data.avatar_url,
        image_200: data.avatar_url,
        image_400: data.avatar_url,
      };
    }

    dispatch(setUserData(user_data));
  }

  if (action.type === FETCH_USER_DATA_ERROR) {
    dispatch(setUserData(null));
  }
};

export const logoutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOGOUT) {
    dispatch(setLogoutStatus(OPERATION_STATUS.PENDING));
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        "POST",
        "/user/user_settings.php?action=logout",
        null,
        null,
        logoutSuccess,
        logoutError,
        true,
        "[auth] Logout"
      )
    );
  }
};

// on successful fetch, process the books data
export const processLogout = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOGOUT_SUCCESS) {
    dispatch(setUserData(null));
    dispatch(setUserPermission([]));
    dispatch(setTokens(null));
    dispatch(setUserStatus(USER_STATES.LOGGED_OUT));
    dispatch(setLogoutStatus(OPERATION_STATUS.SUCCESS));
    dispatch(clearCart());
    dispatch(hideSpinner());
  }

  if (action.type === LOGOUT_ERROR) {
    dispatch(setUserData(null));
    dispatch(setUserPermission([]));
    dispatch(setTokens(null));
    dispatch(setUserStatus(USER_STATES.LOGGED_OUT));
    dispatch(setLogoutStatus(OPERATION_STATUS.FAILURE));
    dispatch(clearCart());
    dispatch(hideSpinner());
  }
};

export const authMdl = [
  loginFlow,
  processLogin,
  refreshFlow,
  processRefresh,
  getUserDataFlow,
  processUserData,
  logoutFlow,
  processLogout,
];
