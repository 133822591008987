import React from "react";
import {Container,Row,Col,Nav,Spinner} from "react-bootstrap";
import "./PopularSearch.css";
import { Link } from "react-router-dom";
import PopularCard from "../PopularCard/PopularCard"

function PopularSearch({ popularSearch, onSearchSelect }) {
  return (
    <React.Fragment>
      <section className="popular-search-section py-4">
        <Container>
          <Row className="py-5">
            <Col xs={12} md={8} className="mx-auto ">
              <div className="text-center">
                <h2>Popular Searches</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit
                </p> */}
              </div>
            </Col>
          </Row>
          {
          popularSearch.isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" />
            </div>
          ) :popularSearch.items.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center my-5">
              <p>No Searches found</p>
            </div>
          ) : (
            <Row className="px-2">
              {popularSearch.items.map((data,idx) => (
                <Col xs={12} sm={6} md={3} key={idx}>
                  <Nav.Link
                  className="p-0"
                    as={Link}
                    to={"/tickets?q=" + data.keyword}
                    onClick={() => {
                      onSearchSelect(data.keyword);
                    }}
                  
                  >
                    <PopularCard 
                    display_name={data.keyword}
                    />
                  </Nav.Link>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PopularSearch;
