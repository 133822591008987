import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import LoginComponent from "../LoginComponent/LoginComponent"
import RegisterComponent from "../RegisterComponent/RegisterComponent"
import ForgetPasswordComponent from "../ForgetPassword/ForgetPasswordComponent"
import { connect } from "react-redux";
import { hideModal } from "../../store/actions/ui";

const AuthPageModal = ({loginShow,registerShow,forgotShow,hideModal}) => {
  return (
		<>
		<ReactBootstrap.Modal 
			show={loginShow}
			onHide={() => hideModal('login')}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
			<LoginComponent/>
		</ReactBootstrap.Modal>
		<ReactBootstrap.Modal 
			show={registerShow}
			onHide={() => hideModal('register')}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
			<RegisterComponent/>
		</ReactBootstrap.Modal>
		<ReactBootstrap.Modal 
			show={forgotShow}
			onHide={() => hideModal('forgot')}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
			<ForgetPasswordComponent/>
		</ReactBootstrap.Modal>
		</>
    
  );
};

const mapStateToProps = (state) => {
  return {
    loginShow:  state.ui.activeModal === 'login',
    registerShow: state.ui.activeModal === 'register',
    forgotShow: state.ui.activeModal === 'forgot'
  };
};

export default connect(mapStateToProps, { hideModal })(
  AuthPageModal
);
