import React, { useEffect } from "react";
import { Button, Row, Col, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { showModal } from "../../store/actions/ui";
import DeleteAccountModel from "./DeleteModel/DeleteModel";
import { OPERATION_STATUS } from "../../js/constants";
const AccountDeletePage = ({ showModal,isLoading,remove_pass }) => {
  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={6}>
            <h5>Delete Account</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
        <div>
          <p>
            <p>Once you delete your account, All of your information will be lost forever.</p>
           <p>We will not be able to restore your account.</p> 
            <p>You can't delete the account, If you have any
            active orders or approved offers.</p>
            <p>Are you sure you want to proceed?</p>
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <Button onClick={() => showModal("UserAccountDelete")}>Proceed</Button>
        </div>
      </div>
      <DeleteAccountModel
      isLoading={isLoading}
      remove_pass={remove_pass} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading : state.Account.delete_status === OPERATION_STATUS.PENDING,
    remove_pass : state.Account.delete_status
  };
};

export default connect(mapStateToProps, { showModal })(AccountDeletePage);
