import React from "react";
import SellOrderCardView from "./SellOrderListView/SellOrderCardView";
import InfiniteScroll from "react-infinite-scroll-component";
import * as ReactBootstrap from "react-bootstrap";
import { connect } from "react-redux";
import SellFilter from "../SellOrder/Filter/SellFilter";

const SellOrderView = ({
  sellOrderDetailsView,
  viewRateByList,
  sell_order_list,
  SellernextPage,
  has_more,
  sellerFilterState,
  onApply,
  onClear,
  onAddfullfillmentModel,
  onEditfullfillmentModel,
  approveOrderDelivered
}) => {
  return (
    <React.Fragment>
      <div className="my-4">
        <SellFilter
          filters={sellerFilterState}
          onApply={onApply}
          onClear={onClear}
        />
        <InfiniteScroll
          dataLength={sell_order_list.length}
          next={SellernextPage}
          hasMore={has_more}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <ReactBootstrap.Spinner
                as="span"
                animation="border"
                role="status"
              />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {sell_order_list.length !== 0 ? (
                <b>No more Orders found</b>
              ) : (
                <b>No Order Found</b>
              )}
            </p>
          }
        >
          {sell_order_list.map((items, idx) => {
            return (
              <SellOrderCardView
                key={idx}
                sellOrderDetailsView={sellOrderDetailsView}
                viewRateByList={viewRateByList}
                // showing  Data
                order_no={items.order_no}
                tracking_id={items.fulfillment_information.tracking_id}
                carrier={items.fulfillment_information.carrier}
                service_name={items.fulfillment_information.service_name}
                image={items.buyer_image}
                buyer_name={items.buyer_name}
                order_at={items.order_at}
                due_amount={items.due_amount}
                total_amount={items.total_amount}
                shipping_add={items.shipping_add}
                fulfillment_status={items.fulfillment_status}
                buyer_ratings={items.ratings.buyer_ratings}
                buyer_review={items.review.buyer_review}
                items={items}
                first_ticket_name={items.first_ticket_name}
                first_ticket_image={items.first_ticket_image}
                remaining_items_count={items.remaining_items_count}
                onAddfullfillmentModel={onAddfullfillmentModel}
                onEditfullfillmentModel={onEditfullfillmentModel}
                approveOrderDelivered={approveOrderDelivered}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, null)(SellOrderView);
