import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Button from "react-bootstrap/Button";
import EventMandatoryItems from "./EventMandatoryItems";
import EventOptionalItems from "./EventOptionalItems";

const EventForm = ({
  value,
  error,
  options,
  onChangeValue,
  addTeamArtist,
  removeTeamArtist,
  onEditOption,
  onEditInputCancel,
}) => {
  return (
    <ReactBootstrap.Card>
      <ReactBootstrap.Card.Header as="h5">{value.operation} Event</ReactBootstrap.Card.Header>
      <ReactBootstrap.Card.Body>
        <EventMandatoryItems
          value={value}
          error={error}
          options={options}
          onChangeValue={onChangeValue}
          onEditOption={onEditOption}
          onEditInputCancel={onEditInputCancel}
        />
        {/* <span
          className="expandManageAddress"
          onClick={() =>
            onChangeValue("event_additional_options","","")
          }
        >
          Additional information
        </span> */}
        <ReactBootstrap.Dropdown.Divider />
        <EventOptionalItems
          value={value.additional_options}
          error={error.additional_options}
          options={options}
          onChangeValue={onChangeValue}
          addTeamArtist={addTeamArtist}
          removeTeamArtist={removeTeamArtist}
          onEditOption={onEditOption}
          onEditInputCancel={onEditInputCancel}
        />
        {/* <ReactBootstrap.Collapse in={value.additional_options.isopen}>
          <ReactBootstrap.Card>
            <ReactBootstrap.Card.Body>
              <EventOptionalItems
                value={value.additional_options}
                error = {error.additional_options}
                options={options}
                onChangeValue={onChangeValue}
                addTeamArtist={addTeamArtist}
                removeTeamArtist={removeTeamArtist}
              />
            </ReactBootstrap.Card.Body>
          </ReactBootstrap.Card>
        </ReactBootstrap.Collapse> */}
        <ReactBootstrap.Row className="align_props my-2">
          <Button
            onClick={() => onChangeValue("event_old", "", "")}
            className="space_button"
            variant="outline-primary"
          >
            Cancel
          </Button>
        </ReactBootstrap.Row>
      </ReactBootstrap.Card.Body>
    </ReactBootstrap.Card>
  );
};

export default EventForm;
