import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";
import "./TicketInfoComponent.css";

const TicketInfoComponent = ({
  cost,
  face_price,
  section,
  row,
  seat_no,
  seat_type,
  quality,
  grade,
  type,
  description,
  isSale
}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={5} sm={5} md={4} className="divTicketDetails">
          {cost && <div>Price</div>}
          {quality && <div>Quality</div>}
          {grade && <div>Grade</div>}
          {section && <div>Section</div>}
          {row && <div>Row</div>}
          {seat_no && <div>Seat Number</div>}
          {seat_type && <div>Seat Type</div>}
          {face_price && <div>Face Price</div>}
          {type && <div>Type</div>}
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={7} sm={7} md={8} className="divTicketDetails">
            <div>
             { isSale !== "0" ?(
                  <>
                  <i className="fa fa-usd" aria-hidden="true"></i> {cost}
                  </>  
                ):(
                  <p>Not for sale</p>
                )
                  }
            </div>
          {quality && <div>{quality}</div>}
          {grade && <div>{grade}</div>}
          {section && <div>{section}</div>}
          {row && <div>{row}</div>}
          {seat_no && <div>{seat_no}</div>}
          {seat_type && <div>{seat_type}</div>}
          {face_price && <div>{face_price}</div>}
          {type && <div>{type}</div>}
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      {description && (
        <div className="titleDescription">
          <h6 className="mb-3">Description</h6>
          <span>{description}</span>
        </div>
      )}
    </React.Fragment>
  );
};

TicketInfoComponent.propTypes = {
  face_price: PropTypes.string,
  section: PropTypes.string,
  row: PropTypes.string,
  seat_no: PropTypes.string,
  seat_type: PropTypes.string,
  quality: PropTypes.string,
  grade: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default TicketInfoComponent;
