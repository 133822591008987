import {
  SITE_LOGIN,
  SITE_LOGIN_SUCCESS,
  SITE_LOGIN_ERROR,
  siteLoginSetStatus,
  siteLoginSuccess,
  siteLoginError,
  SITE_REFRESH_TOKEN,
  SITE_REFRESH_TOKEN_SUCCESS,
  SITE_REFRESH_TOKEN_ERROR,
  siteRefresh,
  setSiteRefreshStatus,
  siteRefreshSuccess,
  siteRefreshError,
  setSiteTokens,
  setSiteStatus
  
} from "../../actions/landingpage";
import { showSpinner, hideSpinner } from "../../actions/ui";
import  { setNotification }  from '../../actions/notification'
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS, USER_STATES } from '../../../js/constants'

// this middleware only care about the getBooks action
export const siteLoginFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SITE_LOGIN) {
    dispatch(siteLoginSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/sitelogin.php",
        null,
        action.payload,
        siteLoginSuccess,
        siteLoginError,
        false,
        "[landingpage]"
      )
    );
    dispatch(showSpinner("landingpage"));
  }

};

// on successful fetch, process the books data
export const processSiteLogin = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === SITE_LOGIN_SUCCESS) {
    dispatch(setNotification("Login success", "landingpage" , notification_types.success))
    dispatch(siteLoginSetStatus(OPERATION_STATUS.SUCCESS));
    let data = action.payload.data
    let tokens = {
      access: {
        token: data.token,
        expiry: data.expiry,
      },
      refresh: {
        token: data.refresh_token,
      },
    };
    dispatch(setSiteStatus(USER_STATES.LOGGED_IN));
    dispatch(setSiteTokens(tokens));
    // Call refresh before token expiry
    let timer_id = setTimeout(() => {
      dispatch(siteRefresh())
    }, new Date((tokens.access.expiry*1000) - 5000) - Date.now())
    dispatch(hideSpinner("landingpage"));
    
  }

  if (action.type === SITE_LOGIN_ERROR) {
    var error_message = action.payload.response.data.error.message
    dispatch(setNotification(error_message, "landingpage" , notification_types.error))
    dispatch(siteLoginSetStatus(OPERATION_STATUS.FAILURE));
    dispatch(setSiteStatus(USER_STATES.ANONYMOUS));
    dispatch(hideSpinner("landingpage"));
  } 
};

export const siteRefreshFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SITE_REFRESH_TOKEN) {
    // dispatch(showSpinner("refresh"));
    dispatch(setSiteRefreshStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "GET",
        "/sitelogin.php?action=refresh_token",
        null,
        null,
        siteRefreshSuccess,
        siteRefreshError,
        false,
        "[landingpage] refresh"
      )
    );
  }
};

// this middleware only care about the getBooks action
export const processSiteRefresh = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SITE_REFRESH_TOKEN_SUCCESS) {
    let data = action.payload.data
    let tokens = {
      access: {
        token: data.token,
        expiry: data.expiry,
      },
      refresh: {
        token: data.refresh_token,
      },
    };
    dispatch(setSiteTokens(tokens));
    // dispatch(setSiteTokens(data.tokens));
    // dispatch(setUserStatus(USER_STATES.LOGGED_IN));
    dispatch(setSiteStatus(USER_STATES.LOGGED_IN));
    dispatch(setSiteRefreshStatus(OPERATION_STATUS.SUCCESS));

    let timer_id = setTimeout(() => {
      dispatch(siteRefresh())
    }, new Date((tokens.access.expiry*1000) - 5000) - Date.now())
  }

  if (action.type === SITE_REFRESH_TOKEN_ERROR) {
    dispatch(setSiteTokens(null));
    // dispatch(setUserStatus(USER_STATES.ANONYMOUS));
    dispatch(setSiteStatus(USER_STATES.ANONYMOUS));
    dispatch(setSiteRefreshStatus(OPERATION_STATUS.FAILURE));

  }
};

export const landingpageMdl = [siteLoginFlow, processSiteLogin,siteRefreshFlow,processSiteRefresh];
