import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import ReplyView from "./ReplyView";
import MyChatPageHeader from "./ReplyPageHeader";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  getReplies,
  sendMessageAdmin,
  setMessageAdminStatus
} from "../../../store/actions/reportAdmin";
import { OPERATION_STATUS } from "../../../js/constants";
import MessageInput from "./MessageInput/MessageInput";

const ReplyPage = ({
  getReplies,
  sendMessageAdmin,
  error,
  roomLoading,
  replies,
  isMessageSending,
  setMessageAdminStatus,
  msgLoading,
  title,
  description
}) => {
  let { id } = useParams();
  useEffect(() => {
    if (id) {
      let data = {
        room_id: id,
      };
      getReplies(data);
    }
  }, [id]);


  useEffect(() => {
    if (isMessageSending === OPERATION_STATUS.SUCCESS) {
      setMessageForm(initial_state);
      setMessageAdminStatus(OPERATION_STATUS.YET_TO_START);
    } else if (isMessageSending === OPERATION_STATUS.FAILURE) {
      setMessageAdminStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [isMessageSending]);


  var initial_state = {
    message:"",
    error:{
      message:""
    }
  };
  // Set State value using immer
  const [messageState, setMessageForm] = useImmer(initial_state);
  const handleMessageInput = (e) => {
    setMessageForm((draft) => {
      draft.message = e.target.value;
      if (e.target.value.length === 0) {
        draft.error.message = "Please enter the message.";
      } else {
        draft.error.message = "";
      }
  
      if (e.target.value.length > 500) {
        draft.error.message =
          "Please enter less than 500 characters for message.";
      } else {
        draft.error.message = "";
      }
    });
  };
  const sendMessageSubmit = (e) => {
    e.preventDefault();
    if (messageState.message.length === 0) {
      setMessageForm((draft) => {
        draft.error.message = "Please enter the message.";
      });
      return false;
    } else {
      setMessageForm((draft) => {
        draft.error.message = "";
      });
    }

    if (messageState.message.length > 500) {
      setMessageForm((draft) => {
        draft.error.message =
          "Please enter less than 500 characters for message.";
      });
      return false;
    } else {
      setMessageForm((draft) => {
        draft.error.message = "";
      });
    }
    let data = {
      room_id: id,
      message: messageState.message,
    };
    sendMessageAdmin(data);
  };
  const refreshRooomMessages = () => {
    let data = {
      room_id: id,
    };
    getReplies(data);
  };
  return (
    <React.Fragment>
      <MyChatPageHeader
        msgLoading={msgLoading}
        title={title}
        description={description}
        refereshMessages={refreshRooomMessages}
      />
      <ReactBootstrap.Dropdown.Divider />
      {roomLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : error ? (
        <h5>Oops something went wrong</h5>
      ) : (
        <>
          <ReplyView
            isLoading={msgLoading}
            replies={replies}
          />
          <MessageInput
            sendMessage={sendMessageSubmit}
            error={messageState.error}
            value={messageState.message}
            onInputChange={handleMessageInput}
            isMessageSending={isMessageSending}
          />
          
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    msgLoading:
      "report-messages_init" in state.ui.loaders
        ? state.ui.loaders["report-messages_init"]
        : true,
    replies : state.reportAdmin.replies.reply_list,
    title : state.reportAdmin.replies.title,
    description: state.reportAdmin.replies.description,
    isMessageSending:state.reportAdmin.message_sent_status
  };
};

export default connect(mapStateToProps, {getReplies,sendMessageAdmin,setMessageAdminStatus})(ReplyPage);
