import { UPDATE_MOMENTS } from "../actions/momentshistory";

const INITIAL_STATE = {
  hasMore: false,
  nextPage: null,
  moments: [],
};

export const momentsReducer = (moment = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_MOMENTS:
      return action.payload;

    default:
      return moment;
  }
};
