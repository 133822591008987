import {
  GET_TICKETS,
  GET_NEXT_TICKETS,
  SEARCH_TICKETS,
  SEARCH_NEXT_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_ERROR,
  FETCH_NEXT_TICKETS_SUCCESS,
  FETCH_NEXT_TICKETS_ERROR,
  SELECT_TICKET,
  FETCH_SINGLE_TICKET_SUCCESS,
  FETCH_SINGLE_TICKET_ERROR,
  fetchTicketsSuccess,
  fetchTicketsError,
  fetchNextTicketsSuccess,
  fetchNextTicketsError,
  fetchSingleTicketSuccess,
  fetchSingleTicketError,
  setTickets,
  setSingleTicket
} from "../../actions/tickets";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { formattedDate } from "../../../js/utils";

// this middleware only care about the getBooks action
export const getTicketsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/products.php",
        action.payload,
        null,
        fetchTicketsSuccess,
        fetchTicketsError,
        false,
        "[ticket]"
      )
    );
    dispatch(showSpinner("ticket_init"));
  }

  if (action.type === GET_NEXT_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/products.php",
        action.payload,
        null,
        fetchNextTicketsSuccess,
        fetchNextTicketsError,
        false,
        "[ticket]"
      )
    );
    dispatch(showSpinner("ticket_next"));
  }

  if (action.type === SEARCH_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/search/products.php",
        action.payload,
        null,
        fetchTicketsSuccess,
        fetchTicketsError,
        false,
        "[ticket]"
      )
    );
    dispatch(showSpinner("ticket_init"));
  }

  if (action.type === SEARCH_NEXT_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/search/products.php",
        action.payload,
        null,
        fetchNextTicketsSuccess,
        fetchNextTicketsError,
        false,
        "[ticket]"
      )
    );
    dispatch(showSpinner("ticket_next"));
  }
};

// on successful fetch, process the tickets data
export const processTicketsCollection = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_TICKETS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var tickets = action.payload.data.items.map((ticket) => {
      let teams = [];
      let artists = [];
      if(ticket.event.genre){
        teams = ticket.event.genre.teams
        artists = ticket.event.genre.artists
      }
      return {
        product_id: ticket.product_id,
        listing_name: ticket.listing_name,
        isSale:ticket.isSale,
        seller: {
          id: ticket.seller.seller_id,
          name: ticket.seller.seller_name,
        },
        significance: ticket.event.significance,
        event_name: ticket.event.event_name,
        venue: ticket.event.venue.display_name,
        date: formattedDate(ticket.event.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
        image: ticket.images[0],
        cost: ticket.sell_details.cost,
        description: ticket.ticket_details.description,
        ticket_type: ticket.ticket_details.type.name
      }
      
    });
    const newData = {
      tickets: tickets,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setTickets(newData));
    dispatch(hideSpinner("ticket_init"));
  }

  if (action.type === FETCH_TICKETS_ERROR) {
    dispatch(hideSpinner("ticket_init"));
  }

  if (action.type === FETCH_NEXT_TICKETS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var newtickets = action.payload.data.items.map((ticket) => {

      let teams = [];
      let artists = [];
      if(ticket.event.genre){
        teams = ticket.event.genre.teams
        artists = ticket.event.genre.artists
      }
      return {
        product_id: ticket.product_id,
        listing_name: ticket.listing_name,
        isSale:ticket.isSale,
        seller: {
          id: ticket.seller.seller_id,
          name: ticket.seller.seller_name,
        },
        significance: ticket.event.significance,
        event_name: ticket.event.event_name,
        venue: ticket.event.venue.display_name,
        date: formattedDate(ticket.event.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
        image: ticket.images[0],
        cost: ticket.sell_details.cost,
        description: ticket.ticket_details.description,
        ticket_type: ticket.ticket_details.type.name
      };
    });

    const prevtickets = getState().ticket.tickets;
    const currtickets = [...prevtickets, ...newtickets];
    const newData = {
      tickets: currtickets,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setTickets(newData));
    dispatch(hideSpinner("ticket_next"));
  }

  if (action.type === FETCH_NEXT_TICKETS_ERROR) {
    dispatch(hideSpinner("ticket_next"));
  }
};

export const getSingleTicketFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SELECT_TICKET) {
    dispatch(
      apiRequest(
        "GET",
        "/products.php",
        action.payload,
        null,
        fetchSingleTicketSuccess,
        fetchSingleTicketError,
        false,
        "[ticket]"
      )
    );
    dispatch(showSpinner("ticket_single"));
  }
 
};

// on successful fetch, process the ticket data
export const processTicketSingle = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_SINGLE_TICKET_SUCCESS) {
    var ticket = action.payload.data
    let teams = [];
    let artists = [];
    if(ticket.event.genre){
      teams = ticket.event.genre.teams
      artists = ticket.event.genre.artists
    }
    var newData = {
        product_id: ticket.product_id,
        listing_name: ticket.listing_name,
        isSale:ticket.isSale,
        seller: {
          id: ticket.seller.seller_id,
          name: ticket.seller.seller_name,
        },
        video_link: ticket.event.video_link,
        significance: ticket.event.significance,
        event_name: ticket.event.name,
        venue: ticket.event.venue.display_name,
        date: formattedDate(ticket.event.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
        images: ticket.images,
        cost: ticket.sell_details.cost,
        face_price: ticket.ticket_details.face_price,
        section: ticket.ticket_details.section,
        row: ticket.ticket_details.row,
        seat_no: ticket.ticket_details.seat.number,
        seat_type: ticket.ticket_details.seat.type ? ticket.ticket_details.seat.type.name : null,
        quality: ticket.ticket_details.quality,
        grade: ticket.ticket_details.grade,
        type: ticket.ticket_details.type.name,
        description: ticket.ticket_details.description
      };

    dispatch(setSingleTicket(newData));
    dispatch(hideSpinner("ticket_single"));
  }

  if (action.type === FETCH_SINGLE_TICKET_ERROR) {
    dispatch(hideSpinner("ticket_single"));
  }

};

export const ticketsMdl = [
  getTicketsFlow, 
  processTicketsCollection, 
  getSingleTicketFlow, 
  processTicketSingle
];
