import React from "react";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import * as Operations from "../../../../../store/actions/profileAction";
import { useDispatch, useSelector , connect } from "react-redux";
import { updatePersonalInfo } from "../../../../../store/actions/profile"
import { hideModal } from "../../../../../store/actions/ui";
import Loader from "../../../../../common/LoaderComponent/LoaderComponent";
import { OPERATION_STATUS} from "../../../../../js/constants";

function UserInformationEditModel({
  loading,
  UserInfo,
  updatePersonalInfo,
  UserInfoEditShow,
  hideModal
}) {
  // To get Redux value
  const dispatch = useDispatch();
  // const profile = useSelector((state) => state.profile);
  // const { UserInfo } = profile;
  var initial_state = {
    name: "",
    phone_no: "",
    error: {
      name: "",
      phone_no: "",
    },
  };
  useEffect(() => {
    SetValue();
  }, [UserInfoEditShow]);

  const SetValue = async () => {
    setUserDetails((draft) => {
      draft.name = UserInfo.name;
      draft.phone_no = UserInfo.mobile_no;
    });
  };
  // Set State value using immer
  const [state, setUserDetails] = useImmer(initial_state);
  const handleInputChange = (e) => {
    setUserDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const Submit = () => {
    if (state.name.length === 0) {
      setUserDetails((draft) => {
        draft.error.name = "Please enter the Name.";
      });
      return false;
    }else{
      setUserDetails((draft) => {
        draft.error.name = "";
      });
    }
    if (state.name.length > 256) {
      setUserDetails((draft) => {
        draft.error.name = "Please enter upto 256 characters for Name.";
      });
      return false;
    }else{
      setUserDetails((draft) => {
        draft.error.name = "";
      });
    }

    if (state.phone_no.length === 0) {
      setUserDetails((draft) => {
        draft.error.phone_no = "Please enter the Phone Number.";
      });
      return false;
    }else{
      setUserDetails((draft) => {
        draft.error.phone_no = "";
      });
    }
    if (state.phone_no.length > 20) {
      setUserDetails((draft) => {
        draft.error.phone_no = "Please enter upto 20 characters for Phone Number.";
      });
      return false;
    }else{
      setUserDetails((draft) => {
        draft.error.phone_no = "";
      });
    }
    const content = {
      user_name: state.name,
      mobile_no: state.phone_no,
    };
   updatePersonalInfo(content)
  };
  return (
    <>
    <React.Fragment>
      <ReactBootstrap.Modal
       show={UserInfoEditShow}
       onHide ={() => hideModal('UserInfoEdit')}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
            <h6 className="font-weight-bold">Edit My Account</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Form.Group controlId="formBasicName">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Name
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="text"
                name="name"
                className={state.error.name.length > 0 ? "is-invalid" : ""}
                placeholder="Enter your name..."
                value={state.name}
                onChange={handleInputChange}
                autoComplete="off"
              />
              {state.error.name.length > 0 && (
                <span className="invalid-feedback">{state.error.name}</span>
              )}
            </ReactBootstrap.Form.Group>

            <ReactBootstrap.Form.Group controlId="formBasicNumber">
              <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                Phone
              </ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type="number"
                name="phone_no"
                className={state.error.phone_no.length > 0 ? "is-invalid" : ""}
                placeholder="Enter your number..."
                value={state.phone_no}
                onChange={handleInputChange}
              />
              {state.error.phone_no.length > 0 && (
                <span className="invalid-feedback">{state.error.phone_no}</span>
              )}
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Modal.Body>
          <ReactBootstrap.Modal.Footer>
            <ReactBootstrap.Button className="btnModelSubmit" onClick={Submit}>
              Save
            </ReactBootstrap.Button>
            <ReactBootstrap.Button
              className="btnModelClose"
              onClick={() => hideModal('UserInfoEdit')}
            >
              Close
            </ReactBootstrap.Button>
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading ? <Loader /> : ""}
      </ReactBootstrap.Modal>
    </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    UserInfo: state.user,
    UserInfoEditShow: state.ui.activeModal === 'UserInfoEdit',
    loading: state.profile.UserDetailsModifyStatus === OPERATION_STATUS.PENDING
  };
};

export default connect(mapStateToProps, { updatePersonalInfo ,hideModal })(
  UserInformationEditModel
)
