import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { USER_STATES } from "../../js/constants";
import { siteLoginSetStatus } from "../../store/actions/landingpage"

const PrivateRoute = ({
  component: Component,
  isLogged,
  isUserLogged,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
         // If user is not logged in the site
         if (!isLogged) {
          console.log("User logged out");
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        // if (roles && roles.indexOf(currentUser.role) === -1) {
        //     // role not authorised so redirect to home page
        //     return <Redirect to={{ pathname: '/'}} />
        // }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    // isLogged: (state.landingpage.sitelogin_status === OPERATION_STATUS.SUCCESS ||
    //   state.landingpage.site_refresh_status === OPERATION_STATUS.SUCCESS),
    isLogged: state.landingpage.site_status === USER_STATES.LOGGED_IN 
  };
};

export default connect(mapStateToProps,{siteLoginSetStatus})(PrivateRoute);
