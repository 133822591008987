import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useConfig from './hooks/useConfig';
import Star from './star';

const parentStyles = {
    overflow: "hidden",
    position: "relative",
};

function ReactStars(props) {
    const [uniqueness, setUniqueness] = useState('');
    const [currentValue, setCurrentValue] = useState(0);
    const [stars, setStars] = useState([]);
    const [isUsingIcons, setIsUsingIcons] = useState(false);
    const [config, setConfig] = useConfig(props);
    const [classNames, setClassNames] = useState('');

    function iconsUsed(config) {
        return (
            (!config.isHalf && config.emptyIcon && config.filledIcon)
            || (config.isHalf && config.emptyIcon && config.halfIcon && config.filledIcon)
        )
    }

    function createUniqueness() {
        setUniqueness((Math.random() + "").replace(".", ""));
    }

    useEffect(() => {
        addClassNames();
        validateInitialValue(props.value, props.count);
        setStars(getStars(props.value));
        setConfig(props);
        createUniqueness();
        setIsUsingIcons(iconsUsed(props));
    }, [props]);

    function validateInitialValue(value, count) {
        if (value < 0 || value > count) {
            setCurrentValue(0);
        }
        else {
            setCurrentValue(value);
        }
    }

    function addClassNames() {
        const reactStarsClass = 'react-stars';
        setClassNames(props.classNames + ` ${reactStarsClass}`);
    }


    function getRate() {
        return config.isHalf ?
            Math.floor(currentValue) :
            Math.round(currentValue);
    }

    function getStars(activeCount) {
        if (typeof activeCount === 'undefined') {
            activeCount = getRate();
        }

        let stars = [];
        for (let i = 0; i < config.count; i++) {
            stars.push({
                active: i <= activeCount - 1
            });
        }
        return stars;
    }
    function currentValueUpdated(value) {
        if (value !== currentValue) {
            setStars(getStars(value));
            setCurrentValue(value);
            props.onChange(value);
        }
    }

    function handleKeyDown(event) {
        if (!config.a11y && !config.edit) return;

        const { key } = event;
        let value = currentValue;

        const keyNumber = Number(key); // e.g. "1" => 1, "ArrowUp" => NaN
        if (keyNumber) {
            if (
                Number.isInteger(keyNumber) &&
                keyNumber > 0 &&
                keyNumber <= config.count
            ) {
                value = keyNumber;
            }
        } else {
            if ((key === "ArrowUp" || key === "ArrowRight") && value < config.count) {
                event.preventDefault();

                value += (config.isHalf ? 0.5 : 1);
            } else if ((key === "ArrowDown" || key === "ArrowLeft") && value > 0.5) {
                event.preventDefault();
                value -= (config.isHalf ? 0.5 : 1);
            }
        }

        currentValueUpdated(value);
    }

    function renderStars() {
        return stars.map((star, i) =>
            <Star
                key={i}
                index={i}
                active={star.active}
                config={config}
                isUsingIcons={isUsingIcons}
                uniqueness={uniqueness}
            />
        );
    }

    return <div className={`react-stars-wrapper-${uniqueness}`}
        style={{ display: 'flex' }}>
        <div tabIndex={config.a11y && config.edit ? 0 : null}
            aria-label='add rating by typing an integer from 0 to 5 or pressing arrow keys'
            onKeyDown={handleKeyDown}
            className={classNames}
            style={parentStyles} >
            {renderStars()}
            <p style={{ position: 'absolute', left: '-200rem' }} role='status'>
                {currentValue}
            </p>
        </div>
    </div>
}

ReactStars.propTypes = {
    classNames: PropTypes.string,
    edit: PropTypes.bool,
    half: PropTypes.bool,
    value: PropTypes.string,
    count: PropTypes.number,
    char: PropTypes.string,
    size: PropTypes.number,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    emptyIcon: PropTypes.element,
    halfIcon: PropTypes.element,
    filledIcon: PropTypes.element,
    a11y: PropTypes.bool
}

ReactStars.defaultProps = {
    edit: false,
    half: false,
    value: 0,
    count: 5,
    char: '★',
    size: 18,
    color: '#cccccc',
    activeColor: '#269399',
    a11y: false,

    onChange: () => { }
};

export default ReactStars;