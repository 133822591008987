import React from "react";
import { useEffect } from "react";
import CollectionCard from "./CollectionCard/CollectionCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Spinner, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getAllCollections,getAllNextCollection } from "../../store/actions/collections";
import { showModal } from "../../store/actions/ui";
import { OPERATION_STATUS, USER_STATES } from "../../js/constants";
import {
    addItemToCart,
    setAddCartItemStatus,
} from "../../store/actions/myCart";

const CollectionsPage = ({
    getAllCollections,
    collections,
    user_status,
    showModal,
    addItemToCart,
    currentUser_id,
    collectionLoading,
    getAllNextCollection,
    hasMore,
    page
}) => {
    useEffect(() => {
        let data = {
            full_collection: 1
        }
        getAllCollections(data)
    }, []);
    const addToCart = (collection_id, collection_name) => {
        if (user_status === USER_STATES.LOGGED_IN) {
            var data = {
                type: "COLLECTION",
                collection: {
                    id: collection_id,
                    name: collection_name,
                },
            };
            addItemToCart(data);
        } else {
            showModal("login");
        }
    }
    const nextPage = () => {
        let data = {
          full_collection: 1,
          page: page
        };
        getAllNextCollection(data);
      };
    return (
        <Container fluid className="my-4 m-0">
            {collectionLoading ? (
                <div className="d-flex justify-content-center align-items-center my-5">
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <InfiniteScroll
                    dataLength={collections.length}
                    next={nextPage}
                    hasMore={hasMore}
                    style={{ overflowX: "hidden" }}
                    loader={
                        <h4>
                            <Spinner
                                as="span"
                                animation="border"
                                role="status"
                            />
                            Loading...
                        </h4>
                    }
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {collections.length !== 0 ? (
                                <b>No more collections found</b>
                            ) : (
                                <b>No collections Found</b>
                            )}
                        </p>
                    }
                >
                    <Row>
                        {collections.map((collection) => {
                            return (
                                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <CollectionCard
                                        key={collection.id}
                                        collection={collection}
                                        collection_name={collection.collection_name}
                                        cost={collection.cost}
                                        canBuy={collection.isSale === "1" && currentUser_id !== collection.user_id ? true : false}
                                        collection_id={collection.id}
                                        user_id={collection.user_id}
                                        addToCart={addToCart}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </InfiniteScroll>
            )
            }
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        collections: state.allcollection.collections,
        user_status: state.userState.user_status,
        hasMore: state.allcollection.hasMore,
        page: state.allcollection.nextPage,
        currentUser_id: state.user ? state.user.id : null,
        collectionLoading:
            "collection_init" in state.ui.loaders
                ? state.ui.loaders["collection_init"]
                : true,
    };
};

export default connect(mapStateToProps, {
    getAllCollections,
    getAllNextCollection,
    showModal,
    addItemToCart
})(
    CollectionsPage
);