import { 
  UPDATE_PERSONAL_INFO,
  UPDATE_PERSONAL_INFO_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_PIC,
  UPDATE_PROFILE_PIC_SUCCESS,
  USER_DETAILS_MODIFY_STATUS,
  SET_PASSWORD_STATUS
} from "../actions/profile";
import { OPERATION_STATUS } from '../../js/constants'
import produce from "immer";
const INITIAL_STATE = {
  profileInfoUpdated:false,
  UserDetailsModifyStatus:"",
  set_password_status:OPERATION_STATUS.YET_TO_START
};

export const profileActiveReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_PERSONAL_INFO:
      draft.profileInfoUpdated = false;
      break;
      case UPDATE_PERSONAL_INFO_SUCCESS:
        draft.profileInfoUpdated= true;
        break;
        case UPDATE_PASSWORD:
      draft.profileInfoUpdated = false;
      break;
      case UPDATE_PASSWORD_SUCCESS:
        draft.profileInfoUpdated= true;
        break;
        case UPDATE_PROFILE_PIC:
      draft.profileInfoUpdated = false;
      break;
      case UPDATE_PROFILE_PIC_SUCCESS:
        draft.profileInfoUpdated= true;
        break;
      case SET_PASSWORD_STATUS:
        draft.set_password_status = action.payload
        break
        case USER_DETAILS_MODIFY_STATUS:
        draft.UserDetailsModifyStatus= action.payload;
        break;
  default:
    break;
}
}, INITIAL_STATE);