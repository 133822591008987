import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const PriceDetails = ({
  price_sub_total,
  tax,
  grand_total,
  cart_id,
  initiateCheckout,
}) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Header>
          <Col xs={12} md={6}>
            <div className="searchResultEventName textBlackColor">
              <strong>Pricing details</strong>
            </div>
          </Col>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6} sm={6} md={8}>
              <div>Price</div>
              <div>Tax</div>
              <strong>
                <div>Total Amount</div>
              </strong>
            </Col>
            <Col xs={6} sm={6} md={4} className=" text-center text-md-right">
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {price_sub_total}
              </div>
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {tax}
              </div>
              <strong>
                <div>
                  <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                  {grand_total}
                </div>
              </strong>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Col xs={12} sm={12} className="text-center">
            {/* <Button
              className="btnBuySearchResult"
              as={NavLink}
              exact
              to="/tickets"
            >
              <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
              Continue Shopping
            </Button> */}
            <Button
              className="btnBuySearchResult ml-2 my-1"
              onClick={() => initiateCheckout(cart_id)}
            >
              <i className="fa fa-shopping-cart mr-2 "></i>
              Checkout
            </Button>
          </Col>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

export default PriceDetails;
