import axios from "axios";
import * as configs from "../js/constants";
import qs from "qs";
import { api as userUnprotectedAPI } from "./userApi.instance";
import * as utils from "../js/utils";
import * as authTypes from "../store/constants/authConstants";

const userProtectAPI = axios.create({
  baseURL: configs.API_BASE_URL,
  withCredentials: true,
});

const userAPIInterceptors = (store) => {
  //request interceptor to add the auth token header to requests
  userProtectAPI.interceptors.request.use(
    async (config) => {
      //Get access token from store
      var accessToken = null;
      if (store.getState().token && "access" in store.getState().token) {
        accessToken = store.getState().token.access.token;
      }
      if (accessToken && !utils.isTokenExpired(accessToken)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        var refresh_token = window.localStorage.getItem("refresh_token");
        if (refresh_token) {
          var params = {
            action: "refresh_token",
            type: "request",
          };
          var body = {
            refresh_token: refresh_token,
          };
          // call refresh token and set access token in header
          store.dispatch({ type: authTypes.USER_REFRESH_REQUEST });
          try {
            var { data } = await userUnprotectedAPI.User.refreshToken();
            var token = data.data.token;
            //Set access token to store
            store.dispatch({
              type: authTypes.USER_REFRESH_SUCCESS,
              payload: data,
            });
            // console.log("After refresh", store.getState().auth.userInfo.token);
            config.headers.Authorization = `Bearer ${token}`;
          } catch (error) {
            store.dispatch({
              type: authTypes.USER_REFRESH_ERROR,
              payload: error.message,
            });
            console.log(error);
          }
        } else {
          store.dispatch({ type: authTypes.USER_UNAUTHORIZED });
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

const api = {
  User: {
    userInfo: () => {
      return userProtectAPI.get(
        `${configs.API_BASE_URL}/user/account/user.php`
      );
    },
    logOutUser: () => {
      return userProtectAPI.post(
        `${configs.API_BASE_URL}/user/user_settings.php?action=logout`
      );
    },
    userInfoUpdate: (credentials) => {
      return userProtectAPI.put(
        `${configs.API_BASE_URL}/user/account/user.php`,
        credentials
      );
    },
    userPasswordUpdate: (credentials) => {
      return userProtectAPI.put(
        `${configs.API_BASE_URL}/user/account/user.php?action=change_password`,
        credentials
      );
    },
  },
  Address: {
    getAddress: (content) => {
      return userProtectAPI.get(
        `${configs.API_BASE_URL}/user/account/address.php`,
        {
          params: content,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        }
      );
    },
    addAddress: (content) => {
      return userProtectAPI.post(
        `${configs.API_BASE_URL}/user/account/address.php`,
        content
      );
    },
    editAddress: (content) => {
      return userProtectAPI.put(
        `${configs.API_BASE_URL}/user/account/address.php`,
        content
      );
    },
    deleteShipAddress: (content) => {
      return userProtectAPI.delete(
        `${configs.API_BASE_URL}/user/account/address.php`,
        {
          data: content,
        }
      );
    },
    setDefault: (content) => {
      return userProtectAPI.put(
        `${configs.API_BASE_URL}/user/account/address.php?action=set_default_shipping`,
        content
      );
    },
    getPayment: () => {
      return userProtectAPI.get(
        `${configs.API_BASE_URL}/seller/account/payment_policy.php`
      );
    },
  },
  UpdatePic: {
    profilePicUpdate: (content) => {
      return userProtectAPI.post(
        `${configs.API_BASE_URL}/user/account/user.php?action=upload_profile`,
        content
      );
    },
  },
  BecomeSeller: {
    setSeller: (content) => {
      return userProtectAPI.post(
        `${configs.API_BASE_URL}/seller/account/payment_policy.php`,
        content
      );
    },
  },
  Mytickets: {
    getGenre: () => {
      const queryparams = {}
      return userProtectAPI.get('/genre.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getSubGenre: () => {
      const queryparams = {
        status: "APPROVED,INQUEUE"
      }
      return userProtectAPI.get('/sub_genre.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getVenue: () => {
      const queryparams = {
        status: "APPROVED,INQUEUE"
      }
      return userProtectAPI.get('/venues.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getTeam: () => {
      const queryparams = {
        status: "APPROVED,INQUEUE"
      }
      return userProtectAPI.get('/teams.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getArtist: () => {
      const queryparams = {
        status: "APPROVED,INQUEUE"
      }
      return userProtectAPI.get('/artist.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getEvent: () => {
      const queryparams = {
        full_result: 1
      }
      return userProtectAPI.get('/events.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    getMetaData: () => {
      return userProtectAPI.get('/masterData.php');
    },
    getCollectionData: () => {
      const queryparams = {
        full_result: 1
      }
      return userProtectAPI.get('/seller/collection.php',{
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
  },
  Offers: {
    getSellerTickets: (queryparams) => {
      return userProtectAPI.get('/products.php', { 
        params: queryparams,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
    },
    counter: (body) => {
      return userProtectAPI.post('/user/offers/offers.php',body);
    },
    edit: (body) => {
      return userProtectAPI.put('/user/offers/offers.php', body);
    },
    setStatus: (body) => {
      return userProtectAPI.put('/user/offers/offers.php?action=set_status',body);
    }
  }
  // Messages: {
  //   getRooms: () => {
  //     return userProtectAPI.get(
  //       `${configs.API_BASE_URL}/user/message/rooms.php`
  //     );
  //   },
  // },
};
export { api, userAPIInterceptors, userProtectAPI };
