import React from "react";
import { Button, Spinner } from "react-bootstrap";
import Address from "../AddressViewComponent/AddressViewComponent";

const CheckoutBillingAddress = ({
  checkout_billing_address,
  add_Address,
  edit_Address,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          {checkout_billing_address && checkout_billing_address.id !== null ? (
            <>
              <Address
                first_name={checkout_billing_address.first_name}
                last_name={checkout_billing_address.last_name}
                address_line_1={checkout_billing_address.address_line_1}
                address_line_2={checkout_billing_address.address_line_2}
                administrative_area={
                  checkout_billing_address.administrative_area
                }
                city={checkout_billing_address.city}
                country={checkout_billing_address.country}
                mobile_no={checkout_billing_address.mobile_no}
                postal_code={checkout_billing_address.postal_code}
              />
              <Button
                onClick={() => {
                  edit_Address();
                }}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              <p>No Address found</p>
              <Button
                onClick={() => {
                  add_Address();
                }}
              >
                Add
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutBillingAddress;
