import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { formattedDate } from "../../../../js/utils";
function paymentDetails({
  payment_type,
  payment_date,
  payment_status,
  payment_paid_status,
}) {
  return (
    <React.Fragment>
      <strong>Payment information</strong>
      <Row>
        <Col xs={6} sm={6} md={8}>
          {payment_type && <div>Payment Method</div>}
          {payment_date && <div>Payment Date</div>}
          <div>Payment Status</div>
        </Col>
        <Col xs={6} sm={6} md={4}className=" text-center text-md-right">
          {payment_type && <div>{payment_type}</div>}

          {payment_date && <div>{formattedDate(payment_date)}</div>}
          <div>
            {payment_paid_status === true ? (
              <Badge className="badge-info">Paid </Badge>
            ) : (
              <Badge className="badge-danger">Not Paid </Badge>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(paymentDetails);
