import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Select from "react-select";
import TicketSupportDocumentUpload from "./TicketSupportDocumentUpload";

const TicketOptionalItems = ({
  value,
  error,
  options,
  onChangeValue,
  handleDocsChange,
}) => {
  return (
    <>
    <ReactBootstrap.Row>
      <ReactBootstrap.Col xs={12} md={12}>
      <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Collection</ReactBootstrap.Form.Label>
            <Select
              className={error.collection.length > 0 ? "is-invalid" : ""}
              classNamePrefix="select"
              isClearable={false}
              value={value.collection}
              placeholder="Select a collection"
              name="collection"
              onChange={(e) => {
                onChangeValue("collection", "", e);
              }}
             options={options.collection}
            />
            {/* {error.collection.length > 0 && (
              <span className="invalid-feedback">{error.collection}</span>
            )} */}
          </ReactBootstrap.Form.Group>
      </ReactBootstrap.Col>
    </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Section</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.section.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the ticket section"
              value={value.section}
              name="section"
              onChange={(e) => {
                onChangeValue("section", "", e);
              }}
              autoComplete="off"
            />
            {error.section.length > 0 && (
              <span className="invalid-feedback">{error.section}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Row</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.row.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the ticket row"
              value={value.row}
              name="row"
              onChange={(e) => {
                onChangeValue("row", "", e);
              }}
              autoComplete="off"
            />
            {error.row.length > 0 && (
              <span className="invalid-feedback">{error.row}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Seat</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.seat.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the ticket seat"
              value={value.seat}
              name="seat"
              onChange={(e) => {
                onChangeValue("seat", "", e);
              }}
              autoComplete="off"
            />
            {error.seat.length > 0 && (
              <span className="invalid-feedback">{error.seat}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Face Price</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.face_price.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the ticket face price"
              value={value.face_price}
              name="face_price"
              onChange={(e) => {
                onChangeValue("face_price", "", e);
              }}
              autoComplete="off"
            />
            {error.face_price.length > 0 && (
              <span className="invalid-feedback">{error.face_price}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Seat Type</ReactBootstrap.Form.Label>
            <Select
              className={error.currentSeatType.length > 0 ? "is-invalid" : ""}
              classNamePrefix="select"
              isClearable={false}
              value={value.currentSeatType}
              placeholder="Select a ticket Seat type"
              name="currentSeatType"
              onChange={(e) => {
                onChangeValue("currentSeatType", "", e);
              }}
              options={options.seatTypes}
            />
            {error.currentSeatType.length > 0 && (
              <span className="invalid-feedback">{error.currentSeatType}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} md={4}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label>Grade</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.grade.length > 0 ? "is-invalid" : ""}
              type="text"
              placeholder="Enter the ticket grade"
              value={value.grade}
              name="grade"
              onChange={(e) => {
                onChangeValue("grade", "", e);
              }}
              autoComplete="off"
            />
            {error.grade.length > 0 && (
              <span className="invalid-feedback">{error.grade}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <ReactBootstrap.Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <ReactBootstrap.Form.Label>
              Ticket Description
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              className={error.description.length > 0 ? "is-invalid" : ""}
              as="textarea"
              placeholder="Enter the ticket description"
              value={value.description}
              rows={3}
              name="description"
              onChange={(e) => {
                onChangeValue("description", "", e);
              }}
              autoComplete="off"
            />
            {error.description.length > 0 && (
              <span className="invalid-feedback">{error.description}</span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <TicketSupportDocumentUpload
            supportdocs={value.document}
            handleDocsChange={handleDocsChange}
            error={error.document}
          />
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </>
  );
};

export default TicketOptionalItems;
