import React from "react";
import "./UserDetails.css";

const UserDetails = ({ message, time, sender_name, sender_image }) => {
  return (
    <React.Fragment>
      <div className="d-flex align-items-start">
        <div className="mr-1">
          <img
            src={sender_image}
            className="rounded-circle"
            alt={sender_name}
            width="40"
            height="40"
          />
        </div>
        <div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="font-weight-bold mr-1">{sender_name}</div>
            <div className="msgTime">{time}</div>
          </div>
          {message && (
            <div
              className="mr-1 pr-2"
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserDetails;
