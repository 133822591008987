import React from "react";
import { useEffect } from "react";
import Genre from "./Genre";
import { connect } from "react-redux";
import { getGenres } from "../../store/actions/genre";

const GenrePage = ({genres, isLoading, getGenres}) => {
  // To get Redux value
  // const dispatch = useDispatch();
  // let home = useSelector((state) => state.home);
  // let { genreloader, genreList } = home;
  useEffect(() => {
    getGenres({has_tickets: true});
  }, []);
  return (
    <Genre genres={genres} isLoading={isLoading} />
  );
}

const mapStateToProps = (state) => {
  return {
    genres: state.genres,
    isLoading: 'genre' in state.ui.loaders ? state.ui.loaders['genre'] : true
  };
};

export default connect(mapStateToProps, { getGenres })(
  GenrePage
);

