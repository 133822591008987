import React from "react";
import { useImmer } from "use-immer";
import { api as userapi } from "../../apis/userApi.instance";
import * as ReactBootstrap from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
// import "./MomentsHistoryComponent.css";
import { useEffect } from "react";
import MomentsHistoryFilterComponent from "./MomentsHistoryFilterComponent/MomentsHistoryFilterComponent";
import MomentsHistoryView from "./MomentsHistoryView";
import { connect } from "react-redux";
import { getMoments, getNextMoments } from "../../store/actions/momentshistory";

const MommentsHistoryPage = ({
  moments,
  hasMore,
  page,
  getMoments,
  getNextMoments,
  momentsLoading
}) => {
  const history = useHistory();
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const filternames = {
    VENUE: "venue",
    TEAM: "team",
    ARTIST: "artist",
    GENRE: "genre"
  };
  const acceptedParams = [
    filternames.VENUE,
    filternames.TEAM,
    filternames.ARTIST,
    filternames.GENRE,

  ];
  const handleFilterChange = (name, e) => {
    switch (name) {
      case "genre":
        setFilter((draft) => {
          const genre_index = draft.findIndex((d) => d.name === "genre");
          if (genre_index !== -1) {
            // Set Genre value as current value
            draft[genre_index].value = e;
          }
        });
        break;
      case "venue":
        setFilter((draft) => {
          const venue_index = draft.findIndex((d) => d.name === "venue");
          if (venue_index !== -1) {
            // Set venue value as current value
            draft[venue_index].value = e;
          }
        });
        break;
      case "team":
        setFilter((draft) => {
          const team_index = draft.findIndex((d) => d.name === "team");
          if (team_index !== -1) {
            // Set team value as current value
            draft[team_index].value = e;
          }
        });
        break;
      case "artist":
        setFilter((draft) => {
          const artist_index = draft.findIndex((d) => d.name === "artist");
          if (artist_index !== -1) {
            // Set artist value as current value
            draft[artist_index].value = e;
          }
        });
        break;
        case "name":
        setFilter((draft) => {
          const name_index = draft.findIndex((d) => d.name === "name");
          if (name_index !== -1) {
            // Set artist value as current value
            draft[name_index].value = e.target.value;
          }
        });
        break;
      default:
        break;
    }
  };

  const initial_filters = [
    {
      placeholder: "Genre",
      type: "select",
      value: null,
      name: filternames.GENRE,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Venue",
      type: "select",
      value: null,
      name: filternames.VENUE,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Team",
      type: "select",
      value: null,
      name: filternames.TEAM,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Artist",
      type: "select",
      value: null,
      name: filternames.ARTIST,
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "Search event by name, genre ... ",
      type: "text",
      value: "",
      name: "name",
      onChange: handleFilterChange,
    }
  ];

  const [filter, setFilter] = useImmer(initial_filters);

  const getFilterValues = () => {
    var filter_values = {};
    filter.forEach((f) => {
      if (f.value) {
        filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
      }
    });
    return filter_values;
  };

  // Infinite scroll callback to fetch next set of moments
  const nextPage = () => {
    getNextMoments({
      page: page,
      ...getFilterValues(),
    });
  };
  // Clear filter
  const reset = () => {
    // Set all filter values to null
    setFilter((draft) => {
      draft.forEach((f) => (f.value = null));
    });
    // Call the api with out any filters
    getMoments({has_tickets:true});
  };

  const getGenreOptions = async () => {
    var genreOptions = {
      name: "genre",
      options: [],
    };
    try {
      var genres = await userapi.Filters.getGenre({has_tickets:true});
      genreOptions.options = genres.data.data.items.map((genre) => ({
        id: genre.genre_id,
        value: genre.genre_name,
        label: genre.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return genreOptions;
  };

  const getVenueOptions = async () => {
    var venueOptions = {
      name: "venue",
      options: [],
    };
    try {
      var venues = await userapi.Filters.getVenue({has_tickets:true});
      venueOptions.options = venues.data.data.items.map((venue) => ({
        id: venue.venue_id,
        value: venue.venue_name,
        label: venue.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return venueOptions;
  };

  const getTeamOptions = async () => {
    var teamOptions = {
      name: "team",
      options: [],
    };
    try {
      var teams = await userapi.Filters.getTeam({has_tickets:true});
      teamOptions.options = teams.data.data.items.map((team) => ({
        id: team.team_id,
        value: team.team_name,
        label: team.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return teamOptions;
  };

  const getArtistOptions = async () => {
    var artistOptions = {
      name: "artist",
      options: [],
    };
    try {
      var artists = await userapi.Filters.getArtist({has_tickets:true});
      artistOptions.options = artists.data.data.items.map((artist) => ({
        id: artist.artist_id,
        value: artist.artist_name,
        label: artist.display_name,
      }));
    } catch (error) {
      console.log(error);
    }
    return artistOptions;
  };

  // Load filter values
  const getFilterOptions = async () => {
    return Promise.all([
      getGenreOptions(),
      getVenueOptions(),
      getTeamOptions(),
      getArtistOptions(),
    ]).then((results) => {
      results.forEach((result) => {
        setFilter((draft) => {
          const index = draft.findIndex((d) => d.name === result.name);
          if (index !== -1) {
            // Set filter options
            draft[index].options = result.options;
          }
        });
      });
    });
  };

  // Load moments on page load
  useEffect(() => {
    getFilterOptions().then(() => setFilterValues());
  }, [location.search]);
  const setFilterValues = () => {
    let filter_values = getFilterValuesFromQueryParams(getQueryParams());
    
    setFilter((draft) => {
      draft.forEach((d) => {
        if (d.name in filter_values) {
          if (d.type === "select") {
            d.value = d.options.find(
              (o) => parseInt(o.id) === parseInt(filter_values[d.name])
            );
          } else {
            d.value = filter_values[d.name];
          }
        }
      });
    });
  };
  const getFilterValuesFromQueryParams = (queryparams) => {
    return Object.fromEntries(
      Object.entries(queryparams).filter(
        ([name, value]) => name 
      )
    );
  };
  const getQueryParams = () => {
    let params = {};
    acceptedParams.forEach((p) => {
      if (urlparams.get(p)) {
        params[p] = urlparams.get(p);
      }
    });
    return params;
  };
  const fetchTickets = () => {
    let queryparams = getQueryParams();
    let filters = getFilterValuesFromQueryParams(queryparams);
    let data = {
      has_tickets :true,...filters
    }
      getMoments(data);
    window.scrollTo(0, 450);
  };
  useEffect(() => {
    fetchTickets();
  }, [location.search]);

  // *****************************************************************
  //Submit filter
  const filterSubmit = () => {
    let keyVal = {
      ...getFilterValues(),
    };
    // set the params with the filters applied
    setQueryParams(keyVal);
  };
  const setQueryParams = (keyvaluepairs) => {
    const params = new URLSearchParams();
    Object.keys(keyvaluepairs).forEach((name) => {
      params.append(name, keyvaluepairs[name]);
    });

    history.push({
      pathname: "/momentHistory",
      search: params.toString(),
    });
  };
  return (
    <>
      <React.Fragment>
        <div className="divFilterTeamArtist">
          <MomentsHistoryFilterComponent
            filters={filter}
            onApply={filterSubmit}
            onClear={reset}
          />
        </div>
        {
          momentsLoading ? (
            <div className="d-flex justify-content-center align-items-center my-5">
            <ReactBootstrap.Spinner animation="border" role="status" />
          </div>
          ):(
            <MomentsHistoryView
          moments={moments}
          hasMore={hasMore}
          nextPage={nextPage}
        />
          )
        }
        
      </React.Fragment>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    moments: state.moment.moments,
    hasMore: state.moment.hasMore,
    page: state.moment.page,
    momentsLoading: 'moment_init' in state.ui.loaders ? state.ui.loaders['moment_init'] : true
  };
};

export default connect(mapStateToProps, { getMoments, getNextMoments })(
  MommentsHistoryPage
);
