export const ADD_ITEM_TO_CART = "[cart] add item to cart";
export const ADD_ITEM_TO_CART_SUCCESS = "[cart] add item to cart success";
export const ADD_ITEM_TO_CART_ERROR = "[cart] add item to cart Error";
export const SET_ADD_ITEM_TO_CART_STATUS = '[cart] add item to cart set status';

export const GET_CART_DETAILS = "[cart] get cart details";
export const GET_CART_DETAILS_SUCCESS = "[cart] get cart details success";
export const GET_CART_DETAILS_ERROR = "[cart] get cart details Error";
export const SET_CART_DETAILS = "[cart] set cart details";
export const SET_CART_DETAILS_FETCH_STATUS = "[cart] set cart details fetch status";

export const REMOVE_ITEM_FROM_CART = "[cart] remove item from cart";
export const REMOVE_ITEM_FROM_CART_SUCCESS = "[cart] remove item from cart success";
export const REMOVE_ITEM_FROM_CART_ERROR = "[cart] remove item from cart Error";
export const SET_REMOVE_ITEM_FROM_CART_STATUS = '[cart] remove item from cart set status';

export const CLEAR_CART = '[cart] reset cart data';

// THIS FOR ADD CART VALUES
export const addItemToCart = (data) => ({
  type: ADD_ITEM_TO_CART,
  payload: data,
});

export const addItemToCartSuccess = (data) => ({
  type: ADD_ITEM_TO_CART_SUCCESS,
  payload: data,
});

export const addItemToCartError = (data) => ({
  type: ADD_ITEM_TO_CART_ERROR,
  payload: data,
});
export const setAddCartItemStatus = (data) => ({
  type: SET_ADD_ITEM_TO_CART_STATUS,
  payload: data,
});

// THIS FOR GET CART VALUES
export const getCartDetails = (data) => ({
  type: GET_CART_DETAILS,
  payload: data,
});

export const getCartDetailsSuccess = (data) => ({
  type: GET_CART_DETAILS_SUCCESS,
  payload: data,
});

export const getCartDetailsError = (data) => ({
  type: GET_CART_DETAILS_ERROR,
  payload: data,
});
export const setCartDetails = (data) => ({
  type: SET_CART_DETAILS,
  payload: data,
});

export const setCartDetailsFetchStatus = (data) => ({
  type: SET_CART_DETAILS_FETCH_STATUS,
  payload: data,
});


// THIS FOR REMOVE CART VALUE
export const removeItemFromCart = (data) => ({
  type: REMOVE_ITEM_FROM_CART,
  payload: data,
});

export const removeItemFromCartSuccess = (data) => ({
  type: REMOVE_ITEM_FROM_CART_SUCCESS,
  payload: data,
});

export const removeItemFromCartError = (data) => ({
  type: REMOVE_ITEM_FROM_CART_ERROR,
  payload: data,
});

export const removeItemFromCartSetStatus = (data) => ({
  type: SET_REMOVE_ITEM_FROM_CART_STATUS,
  payload: data,
});

export const clearCart = () => ({
  type: CLEAR_CART
});
