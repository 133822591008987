
import {
    SET_DELETE_ACCOUNT
  } from "../actions/account.js";
  import produce from "immer";
  import { OPERATION_STATUS } from "../../js/constants";
  
  const INITIAL_STATE = {
    delete_status: OPERATION_STATUS.YET_TO_START
  };
  
  export const accountReducer = produce((draft, action) => {
    switch (action.type) {
      case SET_DELETE_ACCOUNT:
        draft.delete_status = action.payload;
        break;
      default:
        break;
    }
  }, INITIAL_STATE);