import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { OPERATION_STATUS, USER_STATES } from "../../../js/constants";
const PaypalCheckout = ({
  amount,
  description,
  order_no,
  onApprovePayment,
  onCancelPayment,
  onErrorPayment,
  isOrder_expired,
  orderExpiryDate,
  isLoading,
  onexpiryCheck,
  expiryStatus,
}) => {
  const [{ isPending }] = usePayPalScriptReducer();
  // const onHandleExpiry =(order_no) => {
  //   onexpiryCheck(order_no);
  // }
  return (
    <>
      {isPending && (
        <Spinner animation="border spinner-border-sm" role="status" />
      )}
      {isOrder_expired ? (
        <Button
          className="mb-2 w-100"
          onClick={() => orderExpiryDate(order_no)}
        >
          {isLoading && (
            <Spinner animation="border spinner-border-sm" role="status" />
          )}
          Complete order
        </Button>
      ) : (
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
              purchase_units: [
                {
                  reference_id: order_no,
                  description: description,
                  amount: {
                    value: amount,
                    currency_code: "USD",
                  },
                  invoice_id: order_no,
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            // console.log("Approved", data, actions);
            const details = await actions.order.capture();
            // This function shows a transaction success message to your buyer.
            // console.log("Approved details", details);
            onApprovePayment(details, order_no);
          }}
          onError={(data, err) => {
            onErrorPayment(order_no, err);
          }}
          onCancel={() => onCancelPayment(order_no)}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isOrder_expired: state.orderpayment.ExpiryStatus,
    isLoading:
      state.orderpayment.order_Expiry_status === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, null)(PaypalCheckout);
