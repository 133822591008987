import React from "react";
import {Container,Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../PopularEvents/PopularEvent.css";

function popularCard({ display_name }) {
  return (
    <React.Fragment>
      <Card className="text-center">
        <Card.Body>
          <span className="text-ellipsis-wrap">{display_name}</span>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default popularCard;
