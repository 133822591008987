import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import * as ReactBootstrap from "react-bootstrap";
import { connect} from "react-redux";
import { hideModal } from "../../../../../store/actions/ui";
import Loader from "../../../../../common/LoaderComponent/LoaderComponent";
import { OPERATION_STATUS} from "../../../../../js/constants";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  position: "relative"
};

const fileRemove = {
  fontSize: "16px",
  cursor: "pointer",
  color: "#000",
  background: "#ccc",
  right: "0px",
  top: "0px",
  width: "20px",
  textAlign: "center",
  position: "absolute",
}

const img = {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "contain"
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const UpdateUserPicModel = ({
  loading,
  images,
  handleImageChange,
  removeImage,
  error,
  handleSubmit,
  UpdatePicShow,
  hideModal
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles, fileRejections) => {
      handleImageChange(acceptedFiles, fileRejections);
    },
    maxFiles: 1,
  });

  // Styling the file rejection and accept
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = images.map((image, index) => (
    <div style={thumb} key={image.image_name + "_" + (index + 1)}>
      <div style={thumbInner}>
        <img src={image.image_url} style={img} alt="no img" />
        <span
          style={fileRemove}
          onClick={() => removeImage(image)}
        >
          X
        </span>
      </div>
    </div>
  ));

  return (
    <>
    <ReactBootstrap.Modal
      show={UpdatePicShow}
      onHide={() => hideModal('UpdatePic')}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter text-weight-bold">
            <h6 className="font-weight-bold">Update Profile Photo</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Form.Group controlId="formBasicOldPasswd">

      <ReactBootstrap.Form.Group className="mb-3">
        <div
          {...getRootProps({ style })}
          className={error.length > 0 ? "is-invalid" : ""}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only *.jpg, *.jpeg and *.png images will be accepted)</em>
        </div>
        {error.length > 0 && <span className="invalid-feedback">{error}</span>}
      </ReactBootstrap.Form.Group>
      <aside style={thumbsContainer}>{thumbs}</aside>
      </ReactBootstrap.Form.Group>
    </ReactBootstrap.Modal.Body>
    <ReactBootstrap.Modal.Footer>
            <ReactBootstrap.Button
              className="btnTicketAddSubmit"
              onClick={handleSubmit}
            >
              Save
            </ReactBootstrap.Button>{" "}
            <ReactBootstrap.Button
              className="btnModelClose"
              onClick={() => hideModal('UpdatePic')}
            >
              Close
            </ReactBootstrap.Button>
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading ? <Loader /> : ""}
      </ReactBootstrap.Modal>
      </>
  );
};
const mapStateToProps = (state) => {
    return {
      UserInfo: state.user,
      UpdatePicShow: state.ui.activeModal === 'UpdatePic',
      loading: state.profile.UserDetailsModifyStatus === OPERATION_STATUS.PENDING
    };
  };
  
  export default connect(mapStateToProps, { hideModal })(
    UpdateUserPicModel
  )