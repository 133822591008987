import React from "react";
import { Container, Button, Spinner, Row, Col } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useImmer } from "use-immer";
import "./TicketsPage.css";
import TicketsCardComponent from "./TicketsCardComponent/TicketsCardComponent";
import TicketsCardComponentV2 from "./TicketCardComponent_v2/TicketsCardComponentV2";
import PropTypes from "prop-types";
import TicketSeller from "../TicketsPage/TicketSeller/TicketSeller";
import TicketEvent from "./TicketEvent/TicketEvent";

const TicketsView = ({
  tickets,
  hasMore,
  nextPage,
  isEvent,
  isSeller,
  offeredTickets,
  addToOffer,
  removeFromOffer,
  backFromSeller,
  addToCart,
  logged_user_id,
}) => {
  var initial_state = {
    isList: true,
  };
  // Set State value using immer
  const [state, setState] = useImmer(initial_state);
  const isList = () => {
    if (state.isList === true) {
      setState((draft) => {
        draft.isList = false;
      });
    } else {
      setState((draft) => {
        draft.isList = true;
      });
    }
  };
  // const _createItemList = (data,no_of_cols) => {
  //   let rows = {};
  //   let counter = 1;
  //   data.forEach((item, idx) => {
  //     rows[counter] = rows[counter] ? [...rows[counter]] : [];
  //     if (idx % no_of_cols === 0 && idx !== 0) {
  //       counter++;
  //       rows[counter] = rows[counter] ? [...rows[counter]] : [];
  //       rows[counter].push(item);
  //     } else {
  //       rows[counter].push(item);
  //     }
  //   });
  //   return rows;
  // }
  // const newData=_createItemList(tickets,3);
  return (
    <>
      <Container className="my-4">
        {isSeller === true && tickets.length > 0 && (
          <TicketSeller
            sellerName={tickets[0].seller.name}
            sellerId={tickets[0].seller.id}
            offeredTickets={offeredTickets}
            remove={removeFromOffer}
            backFromSeller={backFromSeller}
          />
        )}
        {isEvent === true && tickets.length > 0 && (
          <TicketEvent
            event_name={tickets[0].event_name}
            significance={tickets[0].significance}
            venue={tickets[0].venue}
            date={tickets[0].date}
            teams={tickets[0].teams}
            artists={tickets[0].artists}
          />
        )}
        {tickets.length > 0 && (
          <div align="right" className="mr-5">
            {state.isList ? (
              <Button title="Grid View" className="isList_btn" onClick={isList}>
                <i className="fa fa-th"></i>
              </Button>
            ) : (
              <Button title="List View" className="isList_btn" onClick={isList}>
                <i className="fa fa-th-list"></i>
              </Button>
            )}
          </div>
        )}

        <InfiniteScroll
          dataLength={tickets.length}
          next={nextPage}
          hasMore={hasMore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <Spinner as="span" animation="border" role="status" />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {tickets.length !== 0 ? (
                <b>No more tickets found</b>
              ) : (
                <b>No Tickets Found</b>
              )}
            </p>
          }
        >
          {state.isList ? (
            <Col>
              {tickets.map((ticket) => {
                return (
                  <TicketsCardComponent
                    key={ticket.product_id}
                    product_id={ticket.product_id}
                    listing_name={ticket.listing_name}
                    event_name={ticket.event_name}
                    significance={ticket.significance}
                    venue={ticket.venue}
                    date={ticket.date}
                    description={ticket.description}
                    seller={ticket.seller}
                    image={ticket.image}
                    teams={ticket.teams}
                    artists={ticket.artists}
                    cost={ticket.cost}
                    addToOffer={addToOffer}
                    inOffer={offeredTickets.some(
                      (tkt) => tkt.product_id === ticket.product_id
                    )}
                    isSeller={isSeller}
                    isEvent={isEvent}
                    addToCart={addToCart}
                    canBuy={logged_user_id !== ticket.seller.id && ticket.isSale !=="0"}
                    isSale={ticket.isSale}
                  />
                );
              })}
            </Col>
          ) : (
            <Row>
              {tickets.map((ticket) => {
                return (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <TicketsCardComponentV2
                      key={ticket.product_id}
                      product_id={ticket.product_id}
                      listing_name={ticket.listing_name}
                      event_name={ticket.event_name}
                      significance={ticket.significance}
                      venue={ticket.venue}
                      date={ticket.date}
                      teams={ticket.teams}
                      artists={ticket.artists}
                      seller={ticket.seller}
                      image={ticket.image}
                      cost={ticket.cost}
                      addToOffer={addToOffer}
                      inOffer={offeredTickets.some(
                        (tkt) => tkt.product_id === ticket.product_id
                      )}
                      isSeller={isSeller}
                      isEvent={isEvent}
                      addToCart={addToCart}
                      canBuy={logged_user_id !== ticket.seller.id  && ticket.isSale !=="0"}
                      isSale={ticket.isSale}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </InfiniteScroll>
      </Container>
    </>
  );
};

TicketsView.propTypes = {
  tickets: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default TicketsView;
