import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./TeamArtistPage.css";

const Teams = ({ teams, isLoading }) => {
  return (
    <ReactBootstrap.Col xs={12} md={4}>
      <div className="teamArtistListSection">
        <h5>Teams</h5>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <ReactBootstrap.Spinner animation="border" role="status" />
          </div>
        ) : teams.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <p>No Teams found</p>
          </div>
        ) : (
          <ul>
            {teams.map((team) => (
              <ReactBootstrap.Nav.Link
                key={team.id}
                as={Link}
                to={"/tickets?team=" + team.id}
              >
                <li> {team.name}</li>
              </ReactBootstrap.Nav.Link>
            ))}
          </ul>
        )} 
      </div>
    </ReactBootstrap.Col>
  );
};

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default Teams;
