import {
  REGISTER_SELLER,
  REGISTER_SELLER_SUCCESS,
  REGISTER_SELLER_ERROR,
  registerAsSellerSuccess,
  registerAsSellerError,
  registerAsSellerSetStatus,
  GET_SELLER_INFO,
  FETCH_SELLER_INFO_SUCCESS,
  FETCH_SELLER_INFO_ERROR,
  fetchSellerDetailsSuccess,
  fetchSellerDetailsError,
  setSellerDetails,

  UPDATE_SELLERPAYMENT_INFO,
  UPDATE_SELLERPAYMENT_INFO_SUCCESS,
  UPDATE_SELLERPAYMENT_INFO_ERROR,
  updateSellerPaymentInfoSuccess,
  updateSellerPaymentInfoError,
  setSellerPayment,
  setSellerPaymentStatus,
  
  REPORT_SELLER_INFO,
  REPORT_SELLER_INFO_SUCCESS,
  REPORT_SELLER_INFO_ERROR,
  AddSellerReportInfoSuccess,
  AddSellerReportInfoError,
  setSellerReportStatus
  
  
} from "../../actions/seller";
import { setNotification } from "../../actions/notification";
import { showSpinner, hideSpinner, hideModal } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";

// this middleware only care about the seller registration action
export const sellerRegistrationFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === REGISTER_SELLER) {
    dispatch(registerAsSellerSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/seller/account/payment_policy.php",
        null,
        action.payload,
        registerAsSellerSuccess,
        registerAsSellerError,
        true,
        "[seller]"
      )
    );
    dispatch(showSpinner("seller_register"));
  }
};

// on successful fetch, process the tickets data
export const processSellerRegistration = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === REGISTER_SELLER_SUCCESS) {
    dispatch(
      setNotification(
        "Registered as seller",
        "seller_register",
        notification_types.success
      )
    );
    dispatch(hideSpinner("seller_register"));
    dispatch(registerAsSellerSetStatus(OPERATION_STATUS.SUCCESS));
  }

  if (action.type === REGISTER_SELLER_ERROR) {
    dispatch(hideSpinner("seller_register"));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "seller_register",
        notification_types.error
      )
    );
    dispatch(registerAsSellerSetStatus(OPERATION_STATUS.FAILURE));
  }
};

// this middleware only care about the seller registration action
export const sellerDetailsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SELLER_INFO) {
    dispatch(
      apiRequest(
        "GET",
        "/seller/account/payment_policy.php",
        null,
        null,
        fetchSellerDetailsSuccess,
        fetchSellerDetailsError,
        true,
        "[seller]"
      )
    );
    dispatch(showSpinner("fetch_seller_details"));
  }
};

// on successful fetch, process the seller data
export const processSellerDetails = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_SELLER_INFO_SUCCESS) {
    let data = action.payload.data;
    let seller = {
      id: getState().user.id,
      payment_details: {
        id: data.id,
        is_verified: data.is_verified,
        type: data.type,
      },
      paypal_details: {
        id: data.paypal_details.id,
        paypal_id: data.paypal_details.paypal_id,
        paypal_mail: data.paypal_details.paypal_mail,
      },
    };

    dispatch(hideSpinner("fetch_seller_details"));
    dispatch(setSellerDetails(seller));
  }

  if (action.type === FETCH_SELLER_INFO_ERROR) {
    dispatch(hideSpinner("fetch_seller_details"));
  }
};

export const updatePaymentInfoFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_SELLERPAYMENT_INFO) {
    dispatch(setSellerPaymentStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "PUT",
        "seller/account/payment_policy.php",
        null,
        action.payload,
        updateSellerPaymentInfoSuccess,
        updateSellerPaymentInfoError,
        true,
        "[seller]"
      )
    );
    dispatch(showSpinner("seller"));
  }

};

// on successful fetch, process the payment details data
export const updatePaymentFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === UPDATE_SELLERPAYMENT_INFO_SUCCESS) {
    const response_Seller_payment = action.payload.data
    dispatch(hideModal("sellerPaymentDetails"));
      dispatch(
          setNotification(
            "Payment details Changed Successfully",
            "seller",
            notification_types.success
          ))
    dispatch(setSellerPayment(response_Seller_payment));
    dispatch(setSellerPaymentStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("seller"));
  }

  if (action.type === UPDATE_SELLERPAYMENT_INFO_ERROR) {
    dispatch(setSellerPaymentStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("seller"));
  }
};

export const AddReportSellerInfoFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === REPORT_SELLER_INFO) {
    dispatch(setSellerReportStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "user/reports.php",
        null,
        action.payload,
        AddSellerReportInfoSuccess,
        AddSellerReportInfoError,
        true,
        "[seller]"
      )
    );
    dispatch(showSpinner("seller"));
  }

};

// on successful fetch, process the report seller 
export const ReportSellerFlowCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === REPORT_SELLER_INFO_SUCCESS) {
    dispatch(hideModal("seller"));
      dispatch(
          setNotification(
            "Reported Successfully",
            "seller",
            notification_types.success
          ))
    dispatch(setSellerReportStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("seller"));
  }

  if (action.type === REPORT_SELLER_INFO_ERROR) {
    dispatch(setSellerReportStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("seller"));
  }
};

export const sellerMdl = [
  sellerRegistrationFlow,
  processSellerRegistration,
  sellerDetailsFlow,
  processSellerDetails,
  updatePaymentInfoFlow,
  updatePaymentFlowCollection,
  AddReportSellerInfoFlow,
  ReportSellerFlowCollection
];
