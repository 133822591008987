import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import "./Report.css";

const Room = ({
  name,
  reported_date,
  type,
  status,
  description
}) => {

  return (
    <React.Fragment>
      <ReactBootstrap.Card className="my-4 textBlack">
        <ReactBootstrap.Card.Body title="click to chat">
          <ReactBootstrap.Row>
          <ReactBootstrap.Col> <div className="d-flex flex-row flex-nowrap mx-0">
            <div className="d-flex flex-row flex-grow-1">
              <div>
                <div className="font-weight-bold text-ellipsis-wrap">
                  Issue: {name}
                </div>
                <div className="text-ellipsis-wrap">
                  Type: <span>{type}</span>
                </div>
                <div className="text-ellipsis-wrap">
                  Description: <span>{description}</span>
                </div>
                <div className="">
                  <i className="fa fa-calendar" aria-hidden="true"></i> Reported on: <span>{reported_date}</span>
                </div>
              </div>
            </div>
          </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col className="d-flex justify-content-end align-items-center">
            <div >
            <h5><ReactBootstrap.Badge className="badge-info">{status}</ReactBootstrap.Badge></h5>
            
          </div>
          </ReactBootstrap.Col>
          
          
          </ReactBootstrap.Row>
          
        </ReactBootstrap.Card.Body>
      </ReactBootstrap.Card>
    </React.Fragment>
  );
};

export default Room;
