import React, { useEffect } from "react";
import { Container, Spinner, Row, Col, Dropdown } from "react-bootstrap";
import MyReviewsView from "./MyReviewsView";
import { connect } from "react-redux";
import { getReviewList, getNextReviewList } from "../../store/actions/rating";

const MyReviewsPage = ({
  getReviewList,
  getNextReviewList,
  review_list,
  hasmore,
  page,
  isLoading,
}) => {
  useEffect(() => {
    getReviewList();
  }, []);

  const nextPage = () => {
    let pagedetails = {
      page: page,
    };
    getNextReviewList(pagedetails);
  };
  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={6}>
            <h5>My Reviews</h5>
          </Col>
        </Row>
      </div>
      <Dropdown.Divider />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <Container>
          <MyReviewsView
            items={review_list}
            hasmore={hasmore}
            page={page}
            nextPage={nextPage}
          />
        </Container>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    review_list: state.rating.reviews_list.reviews,
    hasmore: state.rating.reviews_list.hasMore,
    page: state.rating.reviews_list.page,
    isLoading:
      "reviews_init_list" in state.ui.loaders
        ? state.ui.loaders["reviews_init_list"]
        : true,
  };
};

export default connect(mapStateToProps, { getReviewList, getNextReviewList })(
  MyReviewsPage
);
