import {
  GET_MY_TICKETS,
  GET_NEXT_MY_TICKETS,
  FETCH_MY_TICKETS_SUCCESS,
  FETCH_MY_TICKETS_ERROR,
  FETCH_NEXT_MY_TICKETS_SUCCESS,
  FETCH_NEXT_MY_TICKETS_ERROR,
  SELECT_MY_TICKET,
  FETCH_SINGLE_MY_TICKET_SUCCESS,
  FETCH_SINGLE_MY_TICKET_ERROR,
  CREATE_MY_TICKET,
  CREATE_MY_TICKET_SUCCESS,
  CREATE_MY_TICKET_ERROR,
  fetchMyTicketsSuccess,
  fetchMyTicketsError,
  fetchNextMyTicketsSuccess,
  fetchNextMyTicketsError,
  fetchSingleMyTicketSuccess,
  fetchSingleMyTicketError,
  setMyTickets,
  setSingleMyTicket,
  createMyTicketSuccess,
  createMyTicketError,
  createMyTicketSetStatus,
  UPDATE_MY_TICKET_FETCH,
  UPDATE_MY_TICKET_FETCH_SUCCESS,
  UPDATE_MY_TICKET_FETCH_ERROR,
  UPDATE_MY_TICKET_SUBMIT,
  UPDATE_MY_TICKET_SUCCESS,
  UPDATE_MY_TICKET_ERROR,
  fetchUpdateTicketDataSuccess,
  fetchUpdateTicketDataError,
  setUpdateTicketData,
  setUpdateTicketDataStatus,
  updateMyTicketSuccess,
  updateMyTicketError,
  updateMyTicketSetStatus,
  REMOVE_MY_TICKET_IMAGES_SUCCESS,
  REMOVE_MY_TICKET_IMAGES_ERROR,
  removeMyTicketSuccess,
  removeMyTicketError,
  DELETE_MY_TICKET,
  DELETE_MY_TICKET_SUCCESS,
  DELETE_MY_TICKET_ERROR,
  deleteMyTicketSuccess,
  deleteMyTicketError,
  deleteMyTicketSetStatus,
} from "../../actions/mytickets";
import { setNotification } from "../../actions/notification";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { formattedDate } from "../../../js/utils";
import { notification_types, OPERATION_STATUS } from "../../../js/constants";

// this middleware only care about the getBooks action
export const getMyTicketsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_MY_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/products.php",
        action.payload,
        null,
        fetchMyTicketsSuccess,
        fetchMyTicketsError,
        true,
        "[mytickets]"
      )
    );
    dispatch(showSpinner("mytickets_init"));
  }

  if (action.type === GET_NEXT_MY_TICKETS) {
    dispatch(
      apiRequest(
        "GET",
        "/user/products.php",
        action.payload,
        null,
        fetchNextMyTicketsSuccess,
        fetchNextMyTicketsError,
        true,
        "[mytickets]"
      )
    );
    dispatch(showSpinner("mytickets_next"));
  }
};

// on successful fetch, process the tickets data
export const processMyTicketsCollection = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);

  if (action.type === FETCH_MY_TICKETS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var tickets = action.payload.data.items.map((ticket) => {
      let teams = [];
      let artists = [];
      if (ticket.event.genre) {
        teams = ticket.event.genre.teams;
        artists = ticket.event.genre.artists;
      }
      return {
        product_id: ticket.product_id,
        listing_name: ticket.listing_name,
        isSale: ticket.isSale,
        collection_id: ticket.collection_id ? ticket.collection_id:"",
        seller: {
          id: ticket.seller.seller_id,
          name: ticket.seller.seller_name,
        },
        significance: ticket.event.significance,
        event_name: ticket.event.event_name,
        venue: ticket.event.venue.display_name,
        date: formattedDate(ticket.event.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
        image: ticket.images[0],
        cost: ticket.sell_details.cost,
        description: ticket.ticket_details.description,
        ticket_type: ticket.ticket_details.type.name,
        status: ticket.status,
      };
    });
    const newData = {
      tickets: tickets,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMyTickets(newData));
    dispatch(hideSpinner("mytickets_init"));
  }

  if (action.type === FETCH_MY_TICKETS_ERROR) {
    dispatch(hideSpinner("mytickets_init"));
  }

  if (action.type === FETCH_NEXT_MY_TICKETS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var newtickets = action.payload.data.items.map((ticket) => {
      let teams = [];
      let artists = [];
      if (ticket.event.genre) {
        teams = ticket.event.genre.teams;
        artists = ticket.event.genre.artists;
      }
      return {
        product_id: ticket.product_id,
        listing_name: ticket.listing_name,
        isSale: ticket.isSale,
        collection_id: ticket.collection_id ? ticket.collection_id:"",
        seller: {
          id: ticket.seller.seller_id,
          name: ticket.seller.seller_name,
        },
        significance: ticket.event.significance,
        event_name: ticket.event.event_name,
        venue: ticket.event.venue.display_name,
        date: formattedDate(ticket.event.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
        image: ticket.images[0],
        cost: ticket.sell_details.cost,
        description: ticket.ticket_details.description,
        ticket_type: ticket.ticket_details.type.name,
        status: ticket.status,
      };
    });

    const prevtickets = getState().ticket.tickets;
    const currtickets = [...prevtickets, ...newtickets];
    const newData = {
      tickets: currtickets,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMyTickets(newData));
    dispatch(hideSpinner("mytickets_next"));
  }

  if (action.type === FETCH_NEXT_MY_TICKETS_ERROR) {
    dispatch(hideSpinner("mytickets_next"));
  }
};

export const getSingleMyTicketFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === SELECT_MY_TICKET) {
    dispatch(
      apiRequest(
        "GET",
        "/user/products.php",
        action.payload,
        null,
        fetchSingleMyTicketSuccess,
        fetchSingleMyTicketError,
        true,
        "[mytickets]"
      )
    );
    dispatch(showSpinner("myticket_single"));
  }
};

// on successful fetch, process the ticket data
export const processMyTicketSingle = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_SINGLE_MY_TICKET_SUCCESS) {
    var ticket = action.payload.data;
    let teams = [];
    let artists = [];
    if (ticket.event.genre) {
      teams = ticket.event.genre.teams;
      artists = ticket.event.genre.artists;
    }
    var newData = {
      product_id: ticket.product_id,
      listing_name: ticket.listing_name,
      isSale: ticket.isSale,
      seller: {
        id: ticket.seller.seller_id,
        name: ticket.seller.seller_name,
      },
      significance: ticket.event.significance,
      event_name: ticket.event.name,
      venue: ticket.event.venue.display_name,
      date: formattedDate(ticket.event.event_date),
      teams: teams.map((team) => team.display_name),
      artists: artists.map((artist) => artist.display_name),
      images: ticket.images,
      cost: ticket.sell_details.cost,
      face_price: ticket.ticket_details.face_price,
      section: ticket.ticket_details.section,
      row: ticket.ticket_details.row,
      seat_no: ticket.ticket_details.seat.number,
      seat_type: ticket.ticket_details.seat.type ? ticket.ticket_details.seat.type.name : null,
      quality: ticket.ticket_details.quality,
      grade: ticket.ticket_details.grade,
      type: ticket.ticket_details.type.name,
      description: ticket.ticket_details.description,
    };
    dispatch(setSingleMyTicket(newData));
    dispatch(hideSpinner("myticket_single"));
  }

  if (action.type === FETCH_SINGLE_MY_TICKET_ERROR) {
    dispatch(hideSpinner("myticket_single"));
  }
};

export const createMyTicketFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === CREATE_MY_TICKET) {
    dispatch(createMyTicketSetStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "POST",
        "/seller/products.php",
        null,
        action.payload,
        createMyTicketSuccess,
        createMyTicketError,
        true,
        "[mytickets]"
      )
    );

    dispatch(showSpinner("myticket_create"));
  }
};

export const processCreateMyTicket = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === CREATE_MY_TICKET_SUCCESS) {
    dispatch(
      setNotification(
        "Ticket created",
        "myticket_create",
        notification_types.success
      )
    );
    dispatch(hideSpinner("myticket_create"));
    dispatch(createMyTicketSetStatus(OPERATION_STATUS.SUCCESS));
  }

  if (action.type === CREATE_MY_TICKET_ERROR) {
    dispatch(hideSpinner("myticket_create"));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myticket_create",
        notification_types.error
      )
    );
    dispatch(createMyTicketSetStatus(OPERATION_STATUS.FAILURE));
  }
};

export const updateMyTicketFlow = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === UPDATE_MY_TICKET_FETCH) {
    dispatch(setUpdateTicketDataStatus(OPERATION_STATUS.PENDING));
    dispatch(
      apiRequest(
        "GET",
        "/products.php",
        action.payload,
        null,
        fetchUpdateTicketDataSuccess,
        fetchUpdateTicketDataError,
        true,
        "[mytickets]"
      )
    );

    dispatch(showSpinner("myticket_update_fetch"));
  }

  if (action.type === UPDATE_MY_TICKET_SUBMIT) {
    dispatch(updateMyTicketSetStatus(OPERATION_STATUS.PENDING));
    dispatch(showSpinner("myticket_update"));
    let ticket_data = getState().mytickets.update_ticket.form_data.ticket;
    let remove_images = getState().mytickets.update_ticket.form_data
      .removeImages;
    if (ticket_data) {
      if (remove_images.length > 0) {
        // Image need to be removed
        let params = {
          action: "delete_image",
        };
        let body = {
          product_id: ticket_data.product_id,
          images: remove_images,
        };
        dispatch(
          apiRequest(
            "DELETE",
            "/seller/products.php",
            params,
            body,
            removeMyTicketSuccess,
            removeMyTicketError,
            true,
            "[mytickets]"
          )
        );
      } else {
        // No images to be removed, call only update API
        dispatch(
          apiRequest(
            "PUT",
            "/seller/products.php",
            null,
            ticket_data,
            updateMyTicketSuccess,
            updateMyTicketError,
            true,
            "[mytickets]"
          )
        );
      }
    }
  }
};

export const processRemoveMyTicketImages = ({ dispatch, getState }) => (
  next
) => (action) => {
  next(action);

  if (action.type === REMOVE_MY_TICKET_IMAGES_SUCCESS) {
    let ticket_data = getState().mytickets.update_ticket.form_data.ticket;
    dispatch(
      apiRequest(
        "PUT",
        "/seller/products.php",
        null,
        ticket_data,
        updateMyTicketSuccess,
        updateMyTicketError,
        true,
        "[mytickets]"
      )
    );
  }

  if (action.type === REMOVE_MY_TICKET_IMAGES_ERROR) {
    dispatch(hideSpinner("myticket_update"));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myticket_update",
        notification_types.error
      )
    );
    dispatch(updateMyTicketSetStatus(OPERATION_STATUS.FAILURE));
  }
};

export const processUpdateMyTicket = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_MY_TICKET_FETCH_SUCCESS) {
    var ticket = action.payload.data;
    let teams = [];
    let artists = [];
    if (ticket.event.genre) {
      teams = ticket.event.genre.teams;
      artists = ticket.event.genre.artists;
    }
    var newData = {
      product_id: ticket.product_id,
      listing_name: ticket.listing_name,
      isSale: ticket.isSale,
      collection: ticket.collection?ticket.collection : "",
      seller: {
        id: ticket.seller.seller_id,
        name: ticket.seller.seller_name,
      },
      significance: ticket.event.significance ? ticket.event.significance : "",
      event_name: ticket.event.name,
      venue_name: ticket.event.venue.display_name,
      date: formattedDate(ticket.event.event_date),
      teams: teams,
      artists: artists,
      images: ticket.images,
      cost: ticket.sell_details.cost,
      face_price: ticket.ticket_details.face_price
        ? ticket.ticket_details.face_price
        : "",
      section: ticket.ticket_details.section
        ? ticket.ticket_details.section
        : "",
      row: ticket.ticket_details.row ? ticket.ticket_details.section : "",
      seat_no: ticket.ticket_details.seat.number
        ? ticket.ticket_details.seat.number
        : "",
      seat_type: ticket.ticket_details.seat.type,
      quality: ticket.ticket_details.quality,
      grade: ticket.ticket_details.grade ? ticket.ticket_details.grade : "",
      type: ticket.ticket_details.type,
      description: ticket.ticket_details.description
        ? ticket.ticket_details.description
        : "",
      event: ticket.event,
      genre: ticket.event.genre,
      subgenre: ticket.event.genre ? ticket.event.genre.sub_genre : null,
      venue: ticket.event.venue,
    };

    dispatch(setUpdateTicketData(newData));
    dispatch(setUpdateTicketDataStatus(OPERATION_STATUS.SUCCESS));
    dispatch(hideSpinner("myticket_update_fetch"));
  }

  if (action.type === UPDATE_MY_TICKET_FETCH_ERROR) {
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myticket_update_fetch",
        notification_types.error
      )
    );
    dispatch(setUpdateTicketDataStatus(OPERATION_STATUS.FAILURE));
    dispatch(hideSpinner("myticket_update_fetch"));
  }

  if (action.type === UPDATE_MY_TICKET_SUCCESS) {
    dispatch(
      setNotification(
        "Ticket updated",
        "myticket_update",
        notification_types.success
      )
    );
    dispatch(hideSpinner("myticket_update"));
    dispatch(updateMyTicketSetStatus(OPERATION_STATUS.SUCCESS));
  }

  if (action.type === UPDATE_MY_TICKET_ERROR) {
    dispatch(hideSpinner("myticket_update"));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myticket_update",
        notification_types.error
      )
    );
    dispatch(updateMyTicketSetStatus(OPERATION_STATUS.FAILURE));
  }
};

export const deleteMyTicketFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === DELETE_MY_TICKET) {
    dispatch(deleteMyTicketSetStatus(OPERATION_STATUS.PENDING));
    let params = {
      action: "delete_product",
    };
    let body = {
      product_id: action.payload,
    };
    dispatch(
      apiRequest(
        "DELETE",
        "/seller/products.php",
        params,
        body,
        deleteMyTicketSuccess,
        deleteMyTicketError,
        true,
        "[mytickets]"
      )
    );

    dispatch(showSpinner("myticket_delete"));
  }
};

export const processDeleteMyTicket = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === DELETE_MY_TICKET_SUCCESS) {
    dispatch(
      setNotification(
        "Ticket deleted",
        "myticket_delete",
        notification_types.success
      )
    );
    dispatch(hideSpinner("myticket_delete"));
    dispatch(deleteMyTicketSetStatus(OPERATION_STATUS.SUCCESS));
  }

  if (action.type === CREATE_MY_TICKET_ERROR) {
    dispatch(hideSpinner("myticket_delete"));
    var error_message = action.payload.response.data.error.message;
    dispatch(
      setNotification(
        error_message,
        "myticket_delete",
        notification_types.error
      )
    );
    dispatch(deleteMyTicketSetStatus(OPERATION_STATUS.FAILURE));
  }
};

export const myticketsMdl = [
  getMyTicketsFlow,
  processMyTicketsCollection,
  getSingleMyTicketFlow,
  processMyTicketSingle,
  createMyTicketFlow,
  processCreateMyTicket,
  updateMyTicketFlow,
  processRemoveMyTicketImages,
  processUpdateMyTicket,
  deleteMyTicketFlow,
  processDeleteMyTicket,
];
