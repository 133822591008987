import React from "react";
import OrderFilter from "./OrderFilter.css";
import { Row, Col, Container } from "react-bootstrap";
import Collapsible from "react-collapsible";
import InputFormControl from "../../../../common/InputFormControlComponent/InputFormControlComponent";
import DateFormControl from "../../../../common/DateFormControlComponent/DateFormControlComponent";
import SelectFormControl from "../../../../common/SelectFormControlComponent/SelectFormControlComponent";
import FilterButtonComponent from "../../../../common/FilterButtonComponent/FilterButtonComponent";
import FilterMenuComponent from "../../../../common/FilterMenuComponent/FilterMenuComponent";

const SellFilter = ({ filters, onApply, onClear }) => {
  const renderSwitchFormControl = (filter, index) => {
    switch (filter.type) {
      case "select":
        return (
          <SelectFormControl
            key={index}
            placeholder={filter.placeholder}
            value={filter.value}
            name={filter.name}
            options={filter.options}
            onChange={filter.onChange}
          />
        );
      case "text":
        return (
          <InputFormControl
            key={index}
            placeholder={filter.placeholder}
            value={filter.value}
            name={filter.name}
            onChange={filter.onChange}
          />
        );
      case "date":
        return (
          <DateFormControl
            key={index}
            placeholder={filter.placeholder}
            value={filter.value}
            name={filter.name}
            onChange={filter.onChange}
          />
        );
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <div className="collapsibleMobileModeTeam">
        <Collapsible trigger="Filter">
          <nav className="nav d-block justify-content-between">
            <Row>
              <Col xs={12}>
                {filters.map((filter, index) => {
                  return (
                    <Col key={index} xs={12} md={6} className="mb-2 p-0">
                      <label className="mr-2 font-weight-bold">
                        {filter.placeholder}
                      </label>
                      {renderSwitchFormControl(filter, index)}
                    </Col>
                  );
                })}
              </Col>
              <Col xs={12} align="center">
                <FilterButtonComponent onApply={onApply} onClear={onClear} />
              </Col>
            </Row>
          </nav>
        </Collapsible>
      </div>
      <Container fluid className="navTeamArtistBgColor">
        <Container className="">
          <div className="navFilterSection">
            <Row>
              <nav className="pt-2 nav d-flex justify-content-between navMenuFullWidth">
                <Col xs={12} md={1}>
                  <FilterMenuComponent />
                </Col>
                <Col xs={12} md={8}>
                  <Row className="ml-4">
                    {filters.map((filter, index) => {
                      return (
                        <Col key={index} xs={12} md={6} className="mb-2">
                          <div className="d-flex flex-nowrap align-items-center ">
                            <label
                              className="mr-2 font-weight-bold"
                              style={{ color: "white" }}
                            >
                              {filter.placeholder + ":"}
                            </label>
                            <div>{renderSwitchFormControl(filter, index)}</div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col xs={12} md={3} align="right">
                  <FilterButtonComponent onApply={onApply} onClear={onClear} />
                </Col>
              </nav>
            </Row>
          </div>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default SellFilter;
