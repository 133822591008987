import React from "react";
import { ListGroup } from "react-bootstrap";
import OrderItemComponent from "./OrderItemComponent/OrderItemComponent";

const OrderSummaryComponent = ({ sub_orders }) => {
  return (
    <React.Fragment>
      <div>
        <div className="my-1">
          <strong>Products details</strong>
        </div>
        <ListGroup>
          {sub_orders.map((sub_order) => {
            {
              return sub_order.line_items.map((item) => {
                return (
                  <ListGroup.Item key={item.id} className="p-0 border-0 mb-2">
                    <OrderItemComponent
                      type={item.type}
                      base_price={item.base_price}
                      seller_id={sub_order.seller.id}
                      seller_name={
                        sub_order.seller.is_admin
                          ? "Memory Lane"
                          : sub_order.seller.name
                      }
                      details={item.details}
                    />
                  </ListGroup.Item>
                );
              });
            }
          })}
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

export default OrderSummaryComponent;
