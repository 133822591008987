import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Room from "./RoomComponent/Room";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";

const MyMesageView = ({ rooms, hasMore, nextPage }) => {

  const history = useHistory();
  const routeToMesagePage = (e,room) => {
    e.preventDefault();
    history.push({
      pathname: "/account/mymessages/" + room.id
    });
  };

  return (
    <React.Fragment>
      <InfiniteScroll
        dataLength={rooms.length}
        next={nextPage}
        height={400}
        hasMore={hasMore}
        loader={
          <h4>
            <ReactBootstrap.Spinner
              as="span"
              animation="border"
              role="status"
            />
            Loading...
          </h4>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            {rooms.length !== 0 ? (
              <b>No more Rooms found</b>
            ) : (
              <b>No Rooms Found</b>
            )}
          </p>
        }
      >
        {rooms.map((room) => {
          return (
            <Link key={room.id} to={"/account/mymessages/" + room.id} onClick={(e) => (routeToMesagePage(e,room))}>
              <Room
                key={room.id}
                name={room.name}
                avatar={room.image.image_50}
                message={room.message}
                hasUnreadMessage={room.hasUnreadMessage}
                lastMessageSentByYou={room.lastMsgSentByCurrentUser}
              />
            </Link>
          );
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default MyMesageView
