import { SET_ALL_COLLECTIONS} from "../actions/collections";
import produce from 'immer'

const INITIAL_STATE = {
  hasMore: false,
  nextPage: null,
  collections: []
};

export const collectionReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_ALL_COLLECTIONS:
      draft.hasMore = action.payload.hasMore;
      draft.nextPage = action.payload.page;
      draft.collections = action.payload.collections;
      break;
  }
},INITIAL_STATE);
