import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Spinner, Row, Col, Dropdown } from "react-bootstrap";
import "./MyOffersPage.css";
import OfferDetailsPage from "../OfferDetailsPage/OfferDetailsPage";
import { api as UserAPI } from "../../apis/userProtectedApis.instance";
import { selectMyOffer } from "../../store/actions/myOffer";
import { useImmer } from "use-immer";
import { Link } from "react-router-dom";

const MyOfferDetailsPage = ({
  selectMyOffer,
  offer,
  error,
  match
}) => {
  const [detailOfferLoading, setDetailOfferLoading] = useImmer(true);
  const [offersDetail, setOffersDetail] = useImmer({
    offer_details: null,
    seller_items: [],
  });


  let { id } = match.params;

  // DISPATCH THE API
  useEffect(() => {
    selectMyOffer(id);
  }, []);

  // Set seller items and seller id
  useEffect(() => {
    if (offer) {
      let seller = offer.seller_details.seller_id;
      let queryparams = {
        full_result: 1,
        seller: seller,
      };
      UserAPI.Offers.getSellerTickets(queryparams).then(({ data }) => {
        let ticketsRecords = data.data.items.map((ticket) => {
          return {
            product_id: ticket.product_id,
            name: ticket.listing_name,
            cost: ticket.sell_details.cost,
            image: ticket.images[0],
          };
        });
        // setSellerItems(ticketsRecords);
        setOffersDetail((draft) => {
          draft.offer_details = offer;
          draft.seller_items = ticketsRecords;
        });
        setDetailOfferLoading(false);
      });
    }
  }, [offer]);

  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={12}>
            <h5>My Offers</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
      </div>
      {detailOfferLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          {error && <div>Oops.. Something went wrong</div>}
          {offersDetail.offer_details && (
            <div className="d-flex flex-column my-3">
              <div className="ml-auto my-2">
                <Button as={Link} title="goBack" to="/account/myoffers">
                  <i className="fa fa-chevron-left"></i>
                </Button>
              </div>
              <OfferDetailsPage
                offer_detail={offersDetail.offer_details}
                seller_items={offersDetail.seller_items}
                showOfferDetailBody={true}
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    offer: state.myOffer.offer.info,
    error: state.myOffer.offer.error,
    offerLoading:
      "myoffers-single_offer" in state.ui.loaders
        ? state.ui.loaders["myoffers-single_offer"]
        : true,
  };
};

export default connect(mapStateToProps, { selectMyOffer })(MyOfferDetailsPage);
