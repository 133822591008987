import React from "react";
import MycollectionCardComponent from "../MyCollectionCardComponent/MyCollectionCardComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import * as ReactBootstrap from "react-bootstrap";

const MycollectionView = ({collections, hasMore, nextPage, onEdit, onDelete}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Container className="my-4">
        <InfiniteScroll
          dataLength={collections.length}
          next={nextPage}
          hasMore={hasMore}
          style={{ overflowX: "hidden" }}
          loader={
            <h4>
              <ReactBootstrap.Spinner
                as="span"
                animation="border"
                role="status"
              />
              Loading...
            </h4>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {collections.length !== 0 ? (
                <b>No more collections found</b>
              ) : (
                <b>No collections Found</b>
              )}
            </p>
          }
        >
          {collections.map((collection) => {
            return (
              <MycollectionCardComponent
                key={collection.id}
                collection={collection}
                collection_name={collection.collection_name}
                cost={collection.cost}
                collection_id={collection.id}
                status={collection.status}
                user_id={collection.user_id}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
          })}
        </InfiniteScroll>
      </ReactBootstrap.Container>
    </React.Fragment>
  );
};

export default MycollectionView;
