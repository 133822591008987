import React, { useState } from "react";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { connect, useDispatch, useSelector } from "react-redux";
import * as ReactBootstrap from "react-bootstrap";
import "../MyAccountComponent/MyAccountComponent.css";
import { USER_PERMISSIONS } from "../../../js/constants";
import { ProfilePicUpload } from "../../../store/actions/profile"
import ProfileInformationSection from "../Subcomponents/ProfileInformationSection/ProfileInformationSection";
import AddressInformationSection from "../Subcomponents/AddressInformationSection/AddressInformationSection";
import DefaultShippingSection from "../Subcomponents/DefaultShippingSection/DefaultShippingSection"
import SellerInformationSection from "../Subcomponents/SellerInformationSection/SellerInformationSection"
const MyAccountComponent = ({ 
    profile,
    isSellerRegistered,
    ProfilePicUpload ,
    name,
    mail,
    mobile_no,
    profileInfoUpdated
  }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [UploadPicturemodalShow, setPictureModalShow] = React.useState(false);
  const [UpdatePassmodalShow, setUpdatePassModalShow] = React.useState(false);
  const [AddressModalShow, setAddressModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);
  
  // To get Redux value
  const dispatch = useDispatch();

  //hideModel user edit
  useEffect(() => {
    if (profileInfoUpdated === true) {
      setModalShow(false);
      setUpdatePassModalShow(false);
      setPictureModalShow(false);
    }
  }, [
    profileInfoUpdated
    ]);
  return (
    <React.Fragment>
      <ProfileInformationSection />
      <AddressInformationSection />
      <DefaultShippingSection/>
      <SellerInformationSection/>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.user.name ? state.user.name: null,
    mail: state.user.mail ? state.user.mail: null,
    mobile_no: state.user.mobile_no ? state.user.mobile_no: null,
    name: state.user.name ? state.user.name: null,
    profileInfoUpdated:state.profile.profileInfoUpdated,
    profile: state.user.profile_url,
    isSellerRegistered:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) => permission.permission === USER_PERMISSIONS.SELL
          )
        : false,
  };
};

export default connect(mapStateToProps, { ProfilePicUpload })(
  MyAccountComponent
);