import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import MyChatView from "./ChatView";
import MyChatPageHeader from "./ChatPageHeader";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import {
  getMessages,
  sendMessage,
  setSendMessageStatus,
  getNextMessages,
  getRoom,
  setSingleRoomStatus,
} from "../../../store/actions/mymessages";

import MessageInput from "./MessageInput/MessageInput";
import { OPERATION_STATUS } from "../../../js/constants";
import { api as UserAPI } from "../../../apis/userProtectedApis.instance";

const MyChatPage = ({
  messages,
  msgLoading,
  getMessages,
  getNextMessages,
  hasMore,
  page,
  activeRoom,
  sendMessage,
  setSendMessageStatus,
  isMessageSending,
  sendMessageStatus,
  getRoom,
  singleRoomStatus,
  setSingleRoomStatus,
  roomLoading,
  loggedUserId,
}) => {
  let { id } = useParams();
  const [error, setError] = useImmer(false);

  const init_message = {
    message: "",
    error: {
      message: "",
    },
  };
  const [messageForm, setMessageForm] = useImmer(init_message);

  const handleInputChange = (e) => {
    setMessageForm((draft) => {
      draft.message = e.target.value;
      if (e.target.value.length === 0) {
        draft.error.message = "Please enter the message.";
      } else {
        draft.error.message = "";
      }

      if (e.target.value.length > 500) {
        draft.error.message =
          "Please enter less than 500 characters for message.";
      } else {
        draft.error.message = "";
      }
    });
  };

  const sendMessageSubmit = (e) => {
    e.preventDefault();
    if (messageForm.message.length === 0) {
      setMessageForm((draft) => {
        draft.error.message = "Please enter the message.";
      });
      return false;
    } else {
      setMessageForm((draft) => {
        draft.error.message = "";
      });
    }

    if (messageForm.message.length > 500) {
      setMessageForm((draft) => {
        draft.error.message =
          "Please enter less than 500 characters for message.";
      });
      return false;
    } else {
      setMessageForm((draft) => {
        draft.error.message = "";
      });
    }
    let data = {
      room_id: id,
      message: messageForm.message,
    };
    sendMessage(data);
  };

  // Infinite scroll callback to fetch next set of tickets
  const nextPage = () => {
    let filters = {
      room_id: id,
      page: page,
    };
    getNextMessages(filters);
  };

  // On page load get room detail
  useEffect(() => {
    if (id) {
      let data = {
        room_id: id,
      };
      getRoom(data);
      setSendMessageStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [id]);

  // After room detail, fetch messages
  useEffect(() => {
    if (singleRoomStatus === OPERATION_STATUS.SUCCESS) {
      setSingleRoomStatus(OPERATION_STATUS.YET_TO_START);
      let data = {
        room_id: id,
      };
      setError(false);
      getMessages(data);
    } else if (singleRoomStatus === OPERATION_STATUS.FAILURE) {
      setError(true);
      setSingleRoomStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [singleRoomStatus]);

  useEffect(() => {
    if (sendMessageStatus === OPERATION_STATUS.SUCCESS) {
      setMessageForm(init_message);
      setSendMessageStatus(OPERATION_STATUS.YET_TO_START);
    } else if (sendMessageStatus === OPERATION_STATUS.FAILURE) {
      setSendMessageStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [sendMessageStatus]);

  

  const refreshRooomMessages = () => {
    let data = {
      room_id: id,
    };
    getMessages(data);
  };

  return (
    <React.Fragment>
      <MyChatPageHeader
        name={activeRoom ? activeRoom.room_name : null}
        refereshMessages={refreshRooomMessages}
        avatar={activeRoom ? activeRoom.image.image_50 : null}
        loading={roomLoading}
      />
      <ReactBootstrap.Dropdown.Divider />
      {roomLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : error ? (
        <h5>Oops something went wrong</h5>
      ) : (
        <>
          <MyChatView
            isLoading={msgLoading}
            messages={messages}
            hasMore={hasMore}
            nextPage={nextPage}
          />
          <MessageInput
            sendMessage={sendMessageSubmit}
            value={messageForm.message}
            error={messageForm.error.message}
            onInputChange={handleInputChange}
            isMessageSending={isMessageSending}
          />
          
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.message.messages,
    hasMore: state.message.hasMore,
    page: state.message.nextPage,
    roomLoading: state.room.select_room.status === OPERATION_STATUS.PENDING,
    msgLoading:
      "mymessages-messages_init" in state.ui.loaders
        ? state.ui.loaders["mymessages-messages_init"]
        : true,
    activeRoom: state.room.activeRoom.info,
    isMessageSending:
      state.message.send_message.status === OPERATION_STATUS.PENDING,
    sendMessageStatus: state.message.send_message.status,
    singleRoomStatus: state.room.select_room.status,
    loggedUserId: state.user ? state.user.id : null,
  };
};

export default connect(mapStateToProps, {
  getMessages,
  getNextMessages,
  sendMessage,
  setSendMessageStatus,
  getRoom,
  setSingleRoomStatus,
})(MyChatPage);
