import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import OfferDetailsHeader from "./OfferDetailsHeader/OfferDetailsHeader";
import OfferDetailsFooter from "./OfferDetailsFooter/OfferDetailsFooter";
import OfferCardDetails from "./OfferCardComponent/OfferCardDetails";

const OfferDetailsView = ({
  offer_no,
  status,
  price,
  offer_details,
  actions,
  handleStatus,
  handleEdit,
  handleCounter,
  isFormShowing,
  isLoadingStatus,
  handleCart,
  showBody,
  handleShowBody,
}) => {
  return (
    <React.Fragment>
      <Card className="mx-1">
        <Card.Header
          className="py-1 px-2"
          onClick={() => handleShowBody(!showBody)}
          style={{ cursor: "pointer" }}
        >
          <OfferDetailsHeader
            offer_no={offer_no}
            status={status}
            price={price}
          />
        </Card.Header>
        <Collapse in={showBody}>
          <Card.Body className="m-0 p-0">
            {offer_details.map((offer, index) => {
              return (
                <div className="m-2" key={offer.id}>
                  <OfferCardDetails
                    key={offer.id}
                    offer_no={offer_no}
                    offer_id={offer.id}
                    created_by={offer.created_by}
                    created_at={offer.created_at}
                    items={offer.items}
                    price={offer.price}
                    type={offer.type}
                    message={offer.message}
                    isEdit={offer.isEdit}
                    handleEdit={handleEdit}
                    showEdit={offer.showEdit}
                    showOfferCardDetailsBody={true}
                  />
                </div>
              );
            })}
          </Card.Body>
        </Collapse>
        {!isFormShowing &&
          (actions.isAccept ||
            actions.isReject ||
            actions.isCancel ||
            actions.isCounter ||
            actions.isCart) && (
            <Card.Footer className="py-1 px-2">
              <OfferDetailsFooter
                isAccept={actions.isAccept}
                isReject={actions.isReject}
                isCancel={actions.isCancel}
                isCounter={actions.isCounter}
                isCart={actions.isCart}
                handleCounter={handleCounter}
                handleStatus={handleStatus}
                isLoadingStatus={isLoadingStatus}
                handleCart={handleCart}
              />
            </Card.Footer>
          )}
      </Card>
    </React.Fragment>
  );
};

OfferDetailsView.propTypes = {
  offer_no: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  offer_details: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  handleStatus: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCounter: PropTypes.func.isRequired,
  isFormShowing: PropTypes.bool.isRequired,
  isLoadingStatus: PropTypes.bool.isRequired,
  handleCart: PropTypes.func.isRequired,
};

export default OfferDetailsView;
