export const REGISTER_SELLER                        = '[seller] Register seller';
export const REGISTER_SELLER_SUCCESS            = '[seller] Register seller success';
export const REGISTER_SELLER_ERROR              = '[seller] Register seller error';
export const REGISTER_SELLER_STATUS             = '[seller] Register seller set status';

export const UPDATE_SELLERPAYMENT_INFO          = '[seller] updateSellerPaymentInfo';
export const UPDATE_SELLERPAYMENT_INFO_SUCCESS  = '[seller] updateSellerPaymentInfo success';
export const UPDATE_SELLERPAYMENT_INFO_ERROR    = '[seller] updateSellerPaymentInfo Error';
export const SET_SELLER_PAYMENT_DETAILS         = '[seller] updateSellerPaymentInfo setData';
export const SET_SELLER_PAYMENT_STATUS          = '[seller] updateSellerPaymentInfo status';

export const REPORT_SELLER_INFO                 = '[seller] reportSellerInfo';
export const REPORT_SELLER_INFO_SUCCESS              = '[seller] reportSellerInfo success';
export const REPORT_SELLER_INFO_ERROR                = '[seller] reportSellerInfo Error';
export const SET_REPORT_SELLER_INFO_STATUS           = '[seller] reportSellerInfo status';

export const registerAsSeller = (data) => ({
    type   : REGISTER_SELLER,
    payload: data
  })
  
  
  export const registerAsSellerSuccess = (data) => ({
    type: REGISTER_SELLER_SUCCESS,
    payload: data
  });
  
  export const registerAsSellerError = (data) => ({
    type: REGISTER_SELLER_ERROR,
    payload: data
  });
  
  export const registerAsSellerSetStatus = (data) => ({
    type: REGISTER_SELLER_STATUS,
    payload: data
  });
  

export const GET_SELLER_INFO           = '[seller] GET';
export const FETCH_SELLER_INFO_SUCCESS      = '[seller] Fetch success';
export const FETCH_SELLER_INFO_ERROR        = '[seller] Fetch Error';
export const SET_SELLER_INFO             = '[seller] Set data';

// For fetching moments
export const getSellerDetails = () => ({
    type: GET_SELLER_INFO
  });

// For fetching moments
export const fetchSellerDetailsSuccess = (data) => ({
  type: FETCH_SELLER_INFO_SUCCESS,
  payload: data,
});

export const fetchSellerDetailsError = (data) => ({
  type: FETCH_SELLER_INFO_ERROR,
  payload: data
});


export const setSellerDetails = (data) => ({
    type   : SET_SELLER_INFO,
    payload: data
  });

// THIS IS TO UPDATE SELLER PAYPAL ID AND PAYPAL EMAIL
export const updateSellerPaymentInfo = (data) => ({
  type: UPDATE_SELLERPAYMENT_INFO,
  payload: data,
});
export const updateSellerPaymentInfoSuccess = (data) => ({
  type: UPDATE_SELLERPAYMENT_INFO_SUCCESS,
  payload: data,
});

export const updateSellerPaymentInfoError = (data) => ({
  type: UPDATE_SELLERPAYMENT_INFO_ERROR,
  payload: data
});

export const setSellerPayment = (data) => ({
  type: SET_SELLER_PAYMENT_DETAILS,
  payload: data
});
export const setSellerPaymentStatus = (data) => ({
  type: SET_SELLER_PAYMENT_STATUS,
  payload: data
});

export const AddSellerReportInfo = (data) => ({
  type: REPORT_SELLER_INFO,
  payload: data
});

export const AddSellerReportInfoSuccess = (data) => ({
  type: REPORT_SELLER_INFO_SUCCESS,
  payload: data
});

export const AddSellerReportInfoError = (data) => ({
  type: REPORT_SELLER_INFO_ERROR,
  payload: data
});

export const setSellerReportStatus = (data) => ({
  type: SET_REPORT_SELLER_INFO_STATUS,
  payload: data
});