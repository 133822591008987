// Import Section
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import { connect } from "react-redux";
import "../LoginComponent/LoginComponent.css";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from "../../images/memorylanelogo.png";
import { siteLogin } from "../../store/actions/landingpage";
import { OPERATION_STATUS,PAGE_URLS } from "../../js/constants";

const LandingPage = ({ isLogged, siteLogin, history, loading }) => {
  var initial_state = {
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  useEffect(() => {
    if (isLogged) {
      if(history.location.state){
        history.replace(history.location.state.from);
      }else{
        history.replace(PAGE_URLS.HOME);
      }
    }
  }, [isLogged]);

  // To get Redux value

  // Set State value using immer
  const [loginForm, setLoginForm] = useImmer(initial_state);
  // To handle the Onchange Function
  const handleLoginInputChange = (e) => {
    setLoginForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };

  // Login validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (loginForm.email.length === 0) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!loginForm.email.match(mailformat)) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.email.length > 256) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.password.length === 0) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    if (loginForm.password.length > 256) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    var useremail = loginForm.email;
    var password = loginForm.password;
    const user = {
      email: useremail,
      password: password,
    };
    siteLogin(user);
  };
  return (
    <>
      <ReactBootstrap.Form className="loginFormSection" onSubmit={handleSubmit}>
        <div align="center" className="loginLogo mb-1">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="loginTitleDiv text-center">
          <h5>Login</h5>
        </div>
        <ReactBootstrap.Form.Group
          className="mb-4 mt-4"
          controlId="formBasicName"
        >
          <ReactBootstrap.Form.Label>Email:</ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Control
            type="text"
            name="email"
            value={loginForm.email}
            className={loginForm.error.email.length > 0 ? "is-invalid" : ""}
            placeholder="Enter your mail..."
            onChange={handleLoginInputChange}
          />
          {loginForm.error.email.length > 0 && (
            <span className="invalid-feedback">{loginForm.error.email}</span>
          )}
        </ReactBootstrap.Form.Group>

        <ReactBootstrap.Form.Group
          className="mb-4 mt-4"
          controlId="formBasicPassword"
        >
          <ReactBootstrap.Form.Label>Password:</ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Control
            type="password"
            name="password"
            value={loginForm.password}
            className={loginForm.error.password.length > 0 ? "is-invalid" : ""}
            placeholder="Enter your password..."
            onChange={handleLoginInputChange}
          />
          {loginForm.error.password.length > 0 && (
            <span className="invalid-feedback">{loginForm.error.password}</span>
          )}
        </ReactBootstrap.Form.Group>

        <ReactBootstrap.Button type="submit" className="btnSignIn mt-3">
          Login
        </ReactBootstrap.Button>
      </ReactBootstrap.Form>
      {/* *************   Loading  ********************/}
      {loading && <Loader />}
      {/* **************************************/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: (state.landingpage.sitelogin_status === OPERATION_STATUS.SUCCESS ||
      state.landingpage.site_refresh_status === OPERATION_STATUS.SUCCESS),
    loading: state.landingpage.sitelogin_status === OPERATION_STATUS.PENDING,
  };
};

export default connect(mapStateToProps, { siteLogin })(LandingPage);
