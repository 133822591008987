// Import Section
import React from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../ResetPassword/ResetPasswordComponent.css";
import { Redirect } from "react-router";
import * as authOperations from "../../store/actions/authAction";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from '../../images/memorylanelogo.png';
import {PAGE_URLS} from "../../js/constants"

function ResetPasswordComponent() {
  // Redux Dispatcher
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { loading, isResetpass } = auth;

  // Set State value using immer
  const [resetForm, setResetForm] = useImmer({
    password: "",
    confirmPassword: "",
    error: {
      password: "",
      confirmPassword: "",
    },
  });
  // To handle the Onchange Function
  const handleLoginInputChange = (e) => {
    setResetForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  // ResetPassword validation
  const params = new URLSearchParams(useLocation().search);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (resetForm.password.length === 0) {
      setResetForm((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    }else{
      setResetForm((draft) => {
        draft.error.password = "";
      });
    }

    if (resetForm.password.length > 256) {
      setResetForm((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    }else{
      setResetForm((draft) => {
        draft.error.password = "";
      });
    }

    if (resetForm.confirmPassword.length === 0) {
      setResetForm((draft) => {
        draft.error.confirmPassword = "Please enter the confirm password.";
      });
      return false;
    }else{
      setResetForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }

    if (resetForm.confirmPassword.length > 256) {
      setResetForm((draft) => {
        draft.error.confirmPassword =
          "Please enter upto 256 characters for confirm password.";
      });
      return false;
    }else{
      setResetForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }

    if (resetForm.confirmPassword !== resetForm.password) {
      setResetForm((draft) => {
        draft.error.confirmPassword = "Please make sure your passwords match.";
      });
      return false;
    }else{
      setResetForm((draft) => {
        draft.error.confirmPassword = "";
      });
    }
    
    var password = resetForm.password;
    var confirmPassword = resetForm.confirmPassword;
    var key = params.get("reset_key");
    const user = {
      reset_key: key,
      password: password,
      confirm_password: confirmPassword,
    };
    dispatch(authOperations.resetPassword(user));
  };
  return (
    <>
      {isResetpass ? (
        <Redirect to="/" />
      ) : (
        <React.Fragment>
          <section className="resetPageSection">
            <ReactBootstrap.Form
              className="resetFormSection"
              onSubmit={handleSubmit}
            >
              <div align="center" className="loginLogo mb-1">
                <img src={Logo} alt="Logo"/>
              </div>
              <div className="resetTitleDiv text-center">
                <h5>Change Password</h5>
              </div>
              <ReactBootstrap.Form.Group
                className="mb-4 mt-4"
                controlId="formBasicName"
              >
                <ReactBootstrap.Form.Label>
                  New Password:
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  type="password"
                  name="password"
                  value={resetForm.password}
                  className={
                    resetForm.error.password.length > 0
                      ? "is-invalid"
                      : ""
                  }
                  placeholder="Enter your new password..."
                  onChange={handleLoginInputChange}
                />
                {resetForm.error.password.length > 0 && (
                  <span className="invalid-feedback">
                    {resetForm.error.password}
                  </span>
                )}
              </ReactBootstrap.Form.Group>

              <ReactBootstrap.Form.Group
                className="mb-4 mt-4"
                controlId="formBasicPassword"
              >
                <ReactBootstrap.Form.Label>
                  Confirm Password:
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  type="password"
                  name="confirmPassword"
                  value={resetForm.confirmPassword}
                  className={
                    resetForm.error.confirmPassword.length > 0
                      ? "is-invalid"
                      : ""
                  }
                  placeholder="Enter your conform password..."
                  onChange={handleLoginInputChange}
                />
                {resetForm.error.confirmPassword.length > 0 && (
                  <span className="invalid-feedback">
                    {resetForm.error.confirmPassword}
                  </span>
                )}
              </ReactBootstrap.Form.Group>

              <ReactBootstrap.Button type="submit" className="btnChangePassd mt-3">
                Change Password
              </ReactBootstrap.Button>
              <ReactBootstrap.Form.Text className="loginAccount text-center">
                <span>
                  <Link to={PAGE_URLS.HOME}>
                    Back to home{" "}
                  </Link>
                </span>
              </ReactBootstrap.Form.Text>
            </ReactBootstrap.Form>
          </section>
        </React.Fragment>
      )}
      {/* *************   Loading  ********************/}
      {loading ? <Loader /> : ""}
      {/* **************************************/}
    </>
  );
}

export default ResetPasswordComponent;
