import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./OfferItemCardSelectedComponent.css";

const OfferItemCardSelectedComponent = ({
  id,
  listing_name,
  cost,
  image,
  removeItem,
}) => {
  return (
    <React.Fragment>
      <div className="m-1 p-3 border rounded">
        {/* <Card.Body> */}
          <div className="d-flex flex-nowrap align-items-center">
            <div className="mx-1 divTicketImage">
              <img
                src={image.image_200}
                alt="ticket_image"
                height={50}
                width={50}
              />
            </div>
            <div className="searchResultEventName textBlackColor flex-grow-1 mx-1">
              <h6>{listing_name}</h6>
              <div className="font-weight-bold my-2">
                <i className="fa fa-usd" aria-hidden="true"></i> {cost}
              </div>
            </div>
            <div className="mx-1">
              <Button
                variant="danger"
                onClick={() => {
                  removeItem(id);
                }}
                title="Remove from offer"
              >
                <i className="fa fa-minus" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        {/* </Card.Body> */}
      </div>
    </React.Fragment>
  );
};

OfferItemCardSelectedComponent.propTypes = {
  id: PropTypes.string.isRequired,
  removeItem: PropTypes.func.isRequired,
  listing_name: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default OfferItemCardSelectedComponent;
