import {
  GET_MOMENTS,
  GET_NEXT_MOMENTS,
  FETCH_MOMENTS_SUCCESS,
  FETCH_MOMENTS_ERROR,
  FETCH_NEXT_MOMENTS_SUCCESS,
  FETCH_NEXT_MOMENTS_ERROR,
  fetchMomentsSuccess,
  fetchMomentsError,
  fetchNextMomentsSuccess,
  fetchNextMomentsError,
  setMoments,
} from "../../actions/momentshistory";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";
import { formattedDate } from "../../../js/utils";

// this middleware only care about the getBooks action
export const getMomentsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_MOMENTS) {
    dispatch(
      apiRequest(
        "GET",
        "/events.php",
        action.payload,
        null,
        fetchMomentsSuccess,
        fetchMomentsError,
        false,
        "[moment]"
      )
    );
    dispatch(showSpinner("moment_init"));
  }

  if (action.type === GET_NEXT_MOMENTS) {
    dispatch(
      apiRequest(
        "GET",
        "/events.php",
        action.payload,
        null,
        fetchNextMomentsSuccess,
        fetchNextMomentsError,
        false,
        "[moment]"
      )
    );
    dispatch(showSpinner("moment_next"));
  }
};

// on successful fetch, process the books data
export const processMomentsCollection = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_MOMENTS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    var moments = action.payload.data.items.map((moment) => {
      let genre = null;
      let subgenre = null;
      let teams = [];
      let artists = [];
      if(moment.genre){
        genre = {
          id: moment.genre.genre_id,
          name: moment.genre.genre_name,
          display_name: moment.genre.display_name,
        }
        if(moment.genre.sub_genre){
          subgenre = {
            id: moment.genre.sub_genre.id,
            name: moment.genre.sub_genre.name,
            display_name: moment.genre.sub_genre.display_name,
          }
        }
        teams = moment.genre.teams
        artists = moment.genre.artists
      }
      return {
        id: moment.event_id,
        name: moment.name,
        significance: moment.significance,
        genre: genre ? genre.display_name : null,
        sub_genre: subgenre ? subgenre.display_name : null,
        venue: moment.venue.display_name,
        date: formattedDate(moment.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
      };
    });
    const newData = {
      moments: moments,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMoments(newData));
    dispatch(hideSpinner("moment_init"));
  }

  if (action.type === FETCH_MOMENTS_ERROR) {
    dispatch(hideSpinner("moment_init"));
  }

  if (action.type === FETCH_NEXT_MOMENTS_SUCCESS) {
    let hasMore = false;
    let page = null;
    if (action.payload.data.next) {
      let next_url = new URL(action.payload.data.next);
      page = next_url.searchParams.get("page");
      if (page) {
        hasMore = true;
      }
    }
    const newmoments = action.payload.data.items.map((moment) => {
      let genre = null;
      let subgenre = null;
      let teams = [];
      let artists = [];
      if(moment.genre){
        genre = {
          id: moment.genre.genre_id,
          name: moment.genre.genre_name,
          display_name: moment.genre.display_name,
        }
        if(moment.genre.sub_genre){
          subgenre = {
            id: moment.genre.sub_genre.id,
            name: moment.genre.sub_genre.name,
            display_name: moment.genre.sub_genre.display_name,
          }
        }
        teams = moment.genre.teams
        artists = moment.genre.artists
      }
      return {
        id: moment.event_id,
        name: moment.name,
        significance: moment.significance,
        genre: genre ? genre.display_name : null,
        sub_genre: subgenre ? subgenre.display_name : null,
        venue: moment.venue.display_name,
        date: formattedDate(moment.event_date),
        teams: teams.map((team) => team.display_name),
        artists: artists.map((artist) => artist.display_name),
      };
    });
    const prevmoments = getState().moment.moments;
    const currmoments = [...prevmoments, ...newmoments];
    const newData = {
      moments: currmoments,
      hasMore: hasMore,
      page: page,
    };
    dispatch(setMoments(newData));
    dispatch(hideSpinner("moment_next"));
  }

  if (action.type === FETCH_NEXT_MOMENTS_ERROR) {
    dispatch(hideSpinner("moment_next"));
  }
};

export const momentsHistoryMdl = [getMomentsFlow, processMomentsCollection];
