import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import * as ReactBootstrap from "react-bootstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const TicketSupportDocumentUpload = ({
  supportdocs,
  handleDocsChange,
  error,
}) => {
  const acceptedDocumenttypes = [
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "image/jpeg",
    "image/png",
  ];
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptedDocumenttypes.join(","),
    onDrop: (acceptedFiles, fileRejections) => {
      handleDocsChange(acceptedFiles, fileRejections);
    },
    maxFiles: 1,
  });

  // Styling the file rejection and accept
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const supportDocs = supportdocs.map((doc, index) => (
    <li key={doc.document_name + "_" + (index + 1)}>{doc.document_name}</li>
  ));

  return (
    <section>
      <ReactBootstrap.Form.Group className="mb-3">
        <ReactBootstrap.Form.Label>Product Document</ReactBootstrap.Form.Label>
        <div
          {...getRootProps({ style })}
          className={error.length > 0 ? "is-invalid" : ""}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          {/* <em>(Only *.jpg, *.jpeg and *.png images will be accepted)</em> */}
        </div>
        {error.length > 0 && <span className="invalid-feedback">{error}</span>}
      </ReactBootstrap.Form.Group>
      {supportdocs.length > 0 && (
        <aside>
          <p>Document file name</p>
          <ul>{supportDocs}</ul>
        </aside>
      )}
    </section>
  );
};

export default TicketSupportDocumentUpload;
