import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../MyAccountComponent/MyAccountComponent.css";
import SellerInformationComponent from "./SellerInformationComponent/SellerInformationComponent";
import { USER_PERMISSIONS } from "../../../../js/constants";
import "../../../MyAccountScreen/MyAccountScreen.css";
import { ProfilePicUpload } from "../../../../store/actions/profile"


function SellerInformationSection({
    isSellerRegistered
}) {
  return (
    <React.Fragment>
          {isSellerRegistered &&
        <div>
          <SellerInformationComponent />
        </div>
      }
     
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
    return {
      isSellerRegistered:
        state.permissions.length > 0
          ? state.permissions.some(
              (permission) => permission.permission === USER_PERMISSIONS.SELL
            )
          : false,
    };
  };
  
  export default connect(mapStateToProps, { ProfilePicUpload })(
    SellerInformationSection
  );