import React from "react";
import Select, { components } from "react-select";
import * as ReactBootstrap from "react-bootstrap";
import TicketImageUpload from "./TicketImageUpload";
import PlusIcon from "../../../../images/plus-icon.svg";

import EventForm from "../EventForm/EventForm";

const TicketMandatoryItems = ({
  value,
  options,
  onChangeValue,
  addTeamArtist,
  removeTeamArtist,
  handleImageChange,
  removeImage,
  onEditOption,
  onEditInputCancel,
  onEventOptionEdit,
}) => {
  const Option = (props) => {
    return (
      <div className="d-flex">
        <components.Option {...props} />
        {props.data.canEdit && (
          <div
            className="m-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onEventOptionEdit(props.data);
            }}
          >
            <i className="fa fa-edit" aria-hidden="true"></i>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <ReactBootstrap.Form.Group className="mb-3">
            <ReactBootstrap.Form.Label className="requiredField">
              Listing Name
            </ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="text"
              placeholder="Enter the listing name"
              name="listing_name"
              value={value.listing_name}
              onChange={(e) => {
                onChangeValue("listing_name", "", e);
              }}
              className={
                value.error.listing_name.length > 0 ? "is-invalid" : ""
              }
              autoComplete="off"
            />
            {value.error.listing_name.length > 0 && (
              <span className="invalid-feedback">
                {value.error.listing_name}
              </span>
            )}
          </ReactBootstrap.Form.Group>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12} className="mb-3">
          <ReactBootstrap.Form.Label className="requiredField">
            Ticket Type
          </ReactBootstrap.Form.Label>
          <Select
            className={
              value.error.currentTicketType.length > 0 ? "is-invalid" : ""
            }
            classNamePrefix="select"
            isClearable={false}
            value={value.currentTicketType}
            placeholder="Select a ticket type"
            name="currentTicketType"
            onChange={(e) => {
              onChangeValue("currentTicketType", "", e);
            }}
            options={options.ticketTypes}
          />
          {value.error.currentTicketType.length > 0 && (
            <span className="invalid-feedback">
              {value.error.currentTicketType}
            </span>
          )}
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
      <ReactBootstrap.Col xs={12} md={6}>
          <ReactBootstrap.Form.Label className="requiredField">
            Sale/Show
          </ReactBootstrap.Form.Label>
          <Select
            className={
              value.error.isSale.length > 0 ? "is-invalid" : ""
            }
            classNamePrefix="select"
            isClearable={false}
            value={value.isSale}
            placeholder="Select a ticket type"
            name="isSale"
            onChange={(e) => {
              onChangeValue("isSale", "", e);
            }}
            options={options.isSale}
          />
          {value.error.isSale.length > 0 && (
            <span className="invalid-feedback">
              {value.error.isSale}
            </span>
          )}
        </ReactBootstrap.Col>
         <ReactBootstrap.Col xs={12} md={6}>
         <ReactBootstrap.Form.Group className="mb-3">
           <ReactBootstrap.Form.Label>
             Price
           </ReactBootstrap.Form.Label>
           <ReactBootstrap.Form.Control
             type="tel" 
             pattern="[0.00-9.00]*"
             min={1}
             placeholder="Enter the price"
             name="cost"
             value={value.cost}
             className={value.error.cost.length > 0 ? "is-invalid" : ""}
             onChange={(e) => {
               onChangeValue("cost", "", e);
             }}
           />
           {value.error.cost.length > 0 && (
             <span className="invalid-feedback">{value.error.cost}</span>
           )}
         </ReactBootstrap.Form.Group>
       </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <ReactBootstrap.Form.Label className="requiredField">
            Quality
          </ReactBootstrap.Form.Label>
          <Select
            className={
              value.error.currentQuality.length > 0 ? "is-invalid mb-3" : "mb-3"
            }
            classNamePrefix="select"
            isClearable={false}
            value={value.currentQuality}
            placeholder="Select a ticket type"
            name="currentQuality"
            onChange={(e) => {
              onChangeValue("currentQuality", "", e);
            }}
            options={options.quality}
          />
          {value.error.currentQuality.length > 0 && (
            <span className="invalid-feedback">
              {value.error.currentQuality}
            </span>
          )}
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row>
        {value.event.isNew ? (
          <ReactBootstrap.Col className="my-2">
            <EventForm
              value={value.event}
              error={value.error.event}
              options={options}
              onChangeValue={onChangeValue}
              addTeamArtist={addTeamArtist}
              removeTeamArtist={removeTeamArtist}
              onEditOption={onEditOption}
              onEditInputCancel={onEditInputCancel}
            />
          </ReactBootstrap.Col>
        ) : (
          <>
            <ReactBootstrap.Col>
              <ReactBootstrap.Form.Group className="mb-3">
                <ReactBootstrap.Form.Label className="requiredField">
                  Event
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col className="p-1">
                    <Select
                      components={{ Option }}
                      className={
                        value.error.event.currentEvent.length > 0
                          ? "is-invalid"
                          : ""
                      }
                      classNamePrefix="select"
                      isClearable={false}
                      name="currentEvent"
                      placeholder="Select an event"
                      value={value.event.currentEvent}
                      onChange={(e) => {
                        onChangeValue("currentEvent", "", e);
                      }}
                      options={options.events}
                    />
                    {value.error.event.currentEvent.length > 0 && (
                      <span className="invalid-feedback">
                        {value.error.event.currentEvent}
                      </span>
                    )}
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md={3}>
                    <span className="mx-3">Or</span>
                    <ReactBootstrap.Button
                      onClick={() => onChangeValue("event_new", "", "")}
                    >
                       <span>
                        <img src={PlusIcon} alt="PlusIcon" />
                      </span>
                    </ReactBootstrap.Button>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Col>
            {value.event.currentEvent && (
              <>
                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group className="mb-3">
                    <ReactBootstrap.Form.Label className="">
                      Current Event Significance
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      disabled={true}
                      as="textarea"
                      rows={3}
                      name="currentEventSignificance"
                      value={
                        value.event.currentEvent.event.significance
                          ? value.event.currentEvent.event.significance
                          : ""
                      }
                      onChange={(e) => {
                        console.log("current event significance");
                      }}
                      autoComplete="off"
                    />
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>

                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group className="mb-3">
                    <ReactBootstrap.Form.Label className="">
                      Additional Event Significance
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter the additional significance"
                      name="additional_significance"
                      value={value.event.additional_significance.significance}
                      onChange={(e) => {
                        onChangeValue("additional_significance", "", e);
                      }}
                      className={
                        value.error.event.additional_significance.length > 0
                          ? "is-invalid"
                          : ""
                      }
                      autoComplete="off"
                    />
                    {value.error.event.additional_significance.length > 0 && (
                      <span className="invalid-feedback">
                        {value.error.event.additional_significance}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
              </>
            )}
          </>
        )}
      </ReactBootstrap.Row>

      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} md={12}>
          <TicketImageUpload
            images={value.imagefiles}
            handleImageChange={handleImageChange}
            removeImage={removeImage}
            error={value.error.imagefiles}
          />
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </>
  );
};

export default TicketMandatoryItems;
