import React from "react";
import { connect } from "react-redux";
import { Row, Col, Dropdown } from "react-bootstrap";
import { showModal } from "../../../../store/actions/ui";
function TrackingDetails({
  delivery_expected_date,
  delivery_status,
  carrier,
  service_name,
  tracking_id,
}) {
  return (
    <React.Fragment>
      {carrier && <Dropdown.Divider />}

      <Row className="d-flex flex-wrap">
        <Col>
          {carrier && <strong>Tracking information</strong>}
          {carrier && <div>Carrier Name: {carrier}</div>}
          {service_name && <div>Service Type: {service_name}</div>}
          {tracking_id && <div>Tracking Id: {tracking_id}</div>}
          {delivery_expected_date && (
            <div>Delivery Expected Date: {delivery_expected_date}</div>
          )}
        </Col>
        {/* <Col>{getDeliveredStatus(delivery_status)}</Col> */}
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showModal })(TrackingDetails);
