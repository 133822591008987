import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const PriceSummary = ({
  price_sub_total,
  tax,
  total
}) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Col xs={12} md={6}>
            <div className="searchResultEventName textBlackColor">
              <strong>Pricing details</strong>
            </div>
          </Col>
        </Card.Header>
        <Card.Body>
          <Row className="d-flex">
            <Col xs={6} sm={6} md={10}>
              <div>Price</div>
              <div>Tax</div>
              <strong>
                <div>Total Amount</div>
              </strong>
            </Col>
            <Col xs={6} sm={6} md={2} className=" text-center text-md-right">
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {price_sub_total}
              </div>
              <div>
                <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                {tax}
              </div>
              <strong>
                <div>
                  <i className="fa fa-usd mr-1" aria-hidden="true"></i>
                  {total}
                </div>
              </strong>
            </Col>
          </Row>
        </Card.Body>
       
      </Card>
    </>
  );
};

export default PriceSummary;
