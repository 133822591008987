import {
  SET_CART_DETAILS,
  SET_ADD_ITEM_TO_CART_STATUS,
  SET_REMOVE_ITEM_FROM_CART_STATUS,
  SET_CART_DETAILS_FETCH_STATUS,
  CLEAR_CART,
} from "../actions/myCart";
import { LOGOUT } from "../actions/auth";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  cart_id: null,
  items: [],
  price_sub_total: 0,
  total_tax: 0,
  grand_total: 0,
  status: null,
  add_status: OPERATION_STATUS.YET_TO_START,
  remove_status: OPERATION_STATUS.YET_TO_START,
  fetch_status: OPERATION_STATUS.YET_TO_START,
};

export const myCartReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_CART_DETAILS:
      draft.cart_id = action.payload.cart_id;
      draft.items = action.payload.items;
      draft.price_sub_total = action.payload.price_sub_total;
      draft.total_tax = action.payload.total_tax;
      draft.grand_total = action.payload.grand_total;
      draft.status = action.payload.status;
      break;
    case LOGOUT:
      draft.cart_id = null;
      draft.items = [];
      draft.price_sub_total = 0;
      draft.total_tax = 0;
      draft.grand_total = 0;
      draft.status = null;
      break;
    case SET_ADD_ITEM_TO_CART_STATUS:
      draft.add_status = action.payload;
      break;
    case SET_REMOVE_ITEM_FROM_CART_STATUS:
      draft.remove_status = action.payload;
      break;
    case SET_CART_DETAILS_FETCH_STATUS:
      draft.fetch_status = action.payload;
      break;
    case CLEAR_CART:
      draft.cart_id = null;
      draft.items = [];
      draft.price_sub_total = 0;
      draft.total_tax = 0;
      draft.grand_total = 0;
      draft.status = null;
      draft.add_status = OPERATION_STATUS.YET_TO_START;
      draft.remove_status = OPERATION_STATUS.YET_TO_START;
      draft.fetch_status = OPERATION_STATUS.YET_TO_START;
      break;
    default:
      break;
  }
}, INITIAL_STATE);
