import React from 'react';
import * as ReactBootstrap from 'react-bootstrap'
import { hideModal } from "../../store/actions/ui";
import { connect } from "react-redux";

function BuyerAcknowledgementDialog ({BuyerAck ,hideModal}) {
    return (
        <React.Fragment>
            <ReactBootstrap.Modal
                show={BuyerAck}
                onHide={()=>hideModal("BuyerAck")}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                top
                >
                <ReactBootstrap.Modal.Header closeButton>
                    <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter" className="">
                    Acknowledgement
                    </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body className="text-center">
                    <h6>Your Order Received?</h6>
                    <ReactBootstrap.Button className="btnSubmit">Yes</ReactBootstrap.Button>
                </ReactBootstrap.Modal.Body>
                <ReactBootstrap.Modal.Footer>
                    <ReactBootstrap.Button onClick={()=>hideModal("BuyerAck")}>Close</ReactBootstrap.Button>
                    
                </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        BuyerAck: state.ui.activeModal === 'BuyerAck'
    };
  };
  
  export default connect(mapStateToProps,{hideModal})(
    BuyerAcknowledgementDialog
  )