import React, { useEffect } from "react";
import { getSellerDetails } from "../../../../../store/actions/seller";
import { connect } from "react-redux";
import {Card,Spinner,Form,Row,Col,Dropdown} from "react-bootstrap";
import SellerPayment from "./SellerPaymentEditModal";
import "./SellerInformationComponent.css";
import { Link } from "react-router-dom";
import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_STATES,
} from "../../../../../js/constants";
import { showModal } from "../../../../../store/actions/ui";

const SellerInformationComponent = ({
  seller_id,
  sellerPaymentDetails,
  paypalDetails,
  getSellerDetails,
  sellerLoading,
  isActiveSeller,
  showModal,
  EditPaymentDetails,
}) => {
  //Page Onload
  useEffect(() => {
    getSellerDetails();
  }, []);
  return (
    <React.Fragment>
      <Card className="mb-4">
        <Card.Header as="h5">
          Seller Information
        </Card.Header>
        {sellerLoading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <Card.Body>
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="formBasicSellerId">
                    <Form.Label className="font-weight-bold">
                      Seller Id
                    </Form.Label>
                    <Form.Text className="text-muted">
                      {seller_id}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="formBasicSellerStatus">
                    <Form.Label className="font-weight-bold">
                      Status
                    </Form.Label>
                    <Form.Text
                      className={
                        isActiveSeller ? "statusActive" : "statusInactive"
                      }
                    >
                      {isActiveSeller ? "Active" : "In Active"}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Dropdown.Divider />

              {sellerPaymentDetails && (
                <div>
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label className="font-weight-bold">
                          Payment Type
                        </Form.Label>
                        <Form.Text className="text-muted">
                          {sellerPaymentDetails.type}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label className="font-weight-bold">
                          Paypal Id
                        </Form.Label>
                        <Form.Text className="text-muted">
                          {paypalDetails.paypal_id}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label className="font-weight-bold">
                          Paypal Email
                        </Form.Label>
                        <Form.Text className="text-muted">
                          {paypalDetails.paypal_mail}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link
                        to="/editSellerPaypal"
                        onClick={(e) => {
                          e.preventDefault();
                          showModal("sellerPaymentDetails");
                        }}
                      >
                        Edit <i className="fa fa-edit mr-2"></i>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label className="font-weight-bold">
                          Payment Verified
                        </Form.Label>
                        <Form.Text className="text-muted">
                          {sellerPaymentDetails.is_verified === 0
                            ? "No"
                            : "Yes"}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* seller edit payment modal */}
                  <SellerPayment
                    id={paypalDetails.id}
                    paypal_id={paypalDetails.paypal_id}
                    paypal_email={paypalDetails.paypal_mail}
                  />
                </div>
              )}
            </Form>
          </Card.Body>
        )}
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    seller_id: state.seller.info ? state.seller.info.id : null,
    sellerPaymentDetails: state.seller.info
      ? state.seller.info.payment_details
      : null,
    paypalDetails: state.seller.info ? state.seller.info.paypal_details : null,
    sellerLoading:
      "fetch_seller_details" in state.ui.loaders
        ? state.ui.loaders["fetch_seller_details"]
        : true,
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) =>
              permission.permission === USER_PERMISSIONS.SELL &&
              permission.status === USER_PERMISSIONS_STATES.ACTIVE
          )
        : false,
  };
};

export default connect(mapStateToProps, {
  getSellerDetails,
  showModal,
})(SellerInformationComponent);
