import React from "react";
import {Container,Row,Col,Spinner} from "react-bootstrap";
import { useImmer } from "use-immer";
import "../ListComponent/ListComponent.css";
import { useEffect } from "react";
import { api as userapi } from "../../../apis/userProtectedApi.instance";
import { Link } from "react-router-dom";
import { setSearchBar } from "../../../store/actions/searchbar";
import { connect } from "react-redux";
import PopularSearch from "./PopularSearch/PopularSearch"
import PopularEvent from "./PopularEvents/PopularEvent"
import PopularTeamArtist from "./PopularTeamArtist/PopularTeamArtist"

function ListComponent({ setSearchBar }) {
  // Set State value using immer
  const [popularSearch, setPopularSearch] = useImmer({
    isLoading: false,
    items: [],
  });
  const [popularEvents, setPopularEvents] = useImmer({
    isLoading: false,
    items: [],
  });
  const [popularTeamArtists, setPopularTeamArtists] = useImmer({
    isLoading: false,
    items: [],
  });
  
  const getPopularSearches = async () => {
    setPopularSearch((draft) => {
      draft.isLoading = true;
    });
    try {
      let searchesresponse = await userapi.Home.popularSearches();
      let searches = searchesresponse.data.data.items;
      setPopularSearch((draft) => {
        draft.isLoading = false;
        draft.items = searches;
      });
    } catch (error) {
      setPopularSearch((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const getPopularEvents = async () => {
    setPopularEvents((draft) => {
      draft.isLoading = true;
    });
    try {
      let eventresponse = await userapi.Home.popularEvents();
      let events = eventresponse.data.data.items;
      setPopularEvents((draft) => {
        draft.isLoading = false;
        draft.items = events;
      });
    } catch (error) {
      setPopularEvents((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const getPopularTeamArtists = async () => {
    setPopularTeamArtists((draft) => {
      draft.isLoading = true;
    });
    try {
      let teamsresponse = await userapi.Home.popularTeams();
      let teams_artists = teamsresponse.data.data.items;
      setPopularTeamArtists((draft) => {
        draft.isLoading = false;
        draft.items = teams_artists;
      });
    } catch (error) {
      setPopularTeamArtists((draft) => {
        draft.isLoading = false;
      });
    }
  };

  //  GET QUERY STRING ON PAGE LOAD
  useEffect(() => {
    getPopularSearches();
    getPopularEvents();
    getPopularTeamArtists();
  }, []);
  return (
    <React.Fragment>
              
                <PopularSearch
                popularSearch ={popularSearch}
                onSearchSelect = {setSearchBar}
                />
                <PopularEvent
                popularEvents ={popularEvents}
                />
                <PopularTeamArtist
                popularTeamArtists ={popularTeamArtists}
                />
    </React.Fragment>
  );
}

export default connect(null, { setSearchBar })(ListComponent);
