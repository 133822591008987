import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getMySellOffers,
  getNextMySellOffers,
} from "../../../store/actions/myOffer";
import MySellOffersView from "./MySellOffersView";
import { useImmer } from "use-immer";
import OfferDetailsPage from "../../OfferDetailsPage/OfferDetailsPage";
import { Button, Spinner } from "react-bootstrap";
import { api as UserAPI } from "../../../apis/userProtectedApis.instance";

const MySellOffersPage = ({
  offers,
  hasMore,
  page,
  getMySellOffers,
  getNextMySellOffers,
  offerLoading,
}) => {
  const [detailOfferLoading, setDetailOfferLoading] = useImmer(false);
  const [showMySellOffers, setShowMySellOffers] = useImmer(true);
  const [showMySellOffersDetail, setShowMySellOffersDetail] = useImmer(false);
  const [offersDetail, setOffersDetail] = useImmer({
    offer_details: null,
    seller_items: [],
  });

  const nextPage = () => {
    let filters = {
      page: page,
    };
    getNextMySellOffers(filters);
  };

  const handleShowMySellOffers = () => {
    setOffersDetail((draft) => {
      draft.offer_details = null;
    });
    setShowMySellOffersDetail(false);
    setShowMySellOffers(true);
  };

  const getSellerItems = async (seller_id) => {
    try {
      let queryparams = {
        full_result: 1,
        seller: seller_id,
      };
      let response = await UserAPI.Offers.getSellerTickets(queryparams);
      let ticketsRecords = response.data.data.items.map((ticket) => {
        return {
          product_id: ticket.product_id,
          name: ticket.listing_name,
          cost: ticket.sell_details.cost,
          image: ticket.images[0],
        };
      });
      return ticketsRecords;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const viewOfferDetails = async (record) => {
    setShowMySellOffers(false);
    setDetailOfferLoading(true);
    let seller_items = await getSellerItems(
      record.offer_details.seller_details.seller_id
    );
    setOffersDetail((draft) => {
      draft.offer_details = record.offer_details;
      draft.seller_items = seller_items;
    });
    setDetailOfferLoading(false);
    setShowMySellOffersDetail(true);
  };

  useEffect(() => {
    getMySellOffers();
  }, []);

  return (
    <React.Fragment>
      {(detailOfferLoading || offerLoading) && (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      )}
      {showMySellOffers && !offerLoading && (
        <MySellOffersView
          offers={offers}
          nextPage={nextPage}
          hasMore={hasMore}
          viewOfferDetails={viewOfferDetails}
        />
      )}
      {showMySellOffersDetail && !detailOfferLoading && (
        <div className="d-flex flex-column my-3">
          <div className="ml-auto my-2">
            <Button title="goBack" onClick={() => handleShowMySellOffers()}>
              <i className="fa fa-chevron-left"></i>
            </Button>
          </div>
          <OfferDetailsPage
            offer_detail={offersDetail.offer_details}
            seller_items={offersDetail.seller_items}
            showOfferDetailBody={true}
          />
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    offers: state.myOffer.sell.offers,
    hasMore: state.myOffer.sell.hasMore,
    page: state.myOffer.sell.nextPage,
    offerLoading:
      "myselloffers_init" in state.ui.loaders
        ? state.ui.loaders["myselloffers_init"]
        : true,
  };
};

export default connect(mapStateToProps, {
  getMySellOffers,
  getNextMySellOffers,
})(MySellOffersPage);
