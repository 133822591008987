export const GET_ALL_COLLECTIONS                      = '[collections] GET all Collections';
export const GET_ALL_COLLECTIONS_SUCCESS              = '[collections] GET all Collections success';
export const GET_ALL_COLLECTIONS_ERROR                = '[collections] GET all Collections error';
export const SET_ALL_COLLECTIONS                      = '[collections] SET all Collections ';

export const GET_ALL_NEXT_COLLECTIONS                      = '[collections] GET all nextCollections';
export const GET_ALL_NEXT_COLLECTIONS_SUCCESS              = '[collections] GET all nextCollections success';
export const GET_ALL_NEXT_COLLECTIONS_ERROR                = '[collections] GET all nextCollections error';
export const SET_ALL_NEXT_COLLECTIONS                      = '[collections] SET all nextCollections ';

// For fetching collections
export const getAllCollections = (data) => ({
    type: GET_ALL_COLLECTIONS,
    payload: data
  });
export const getAllCollectionsSuccess = (data) => ({
    type: GET_ALL_COLLECTIONS_SUCCESS,
    payload: data
})
export const getAllCollectionsError = (data) => ({
    type: GET_ALL_COLLECTIONS_ERROR,
    payload: data
})
export const setAllCollections = (data) => ({
    type: SET_ALL_COLLECTIONS,
    payload: data
})


// For fetching next collections
export const getAllNextCollection = (data) => ({
    type: GET_ALL_NEXT_COLLECTIONS,
    payload: data
  });
export const getAllNextCollectionsSuccess = (data) => ({
    type: GET_ALL_NEXT_COLLECTIONS_SUCCESS,
    payload: data
})
export const getAllNextCollectionsError = (data) => ({
    type: GET_ALL_NEXT_COLLECTIONS_ERROR,
    payload: data
})
export const setAllNextCollections = (data) => ({
    type: SET_ALL_NEXT_COLLECTIONS,
    payload: data
})