import {
  GET_GENRES,
  FETCH_GENRES_SUCCESS,
  FETCH_GENRES_ERROR,
  fetchGenresSuccess,
  fetchGenresError,
  setGenres
} from "../../actions/genre";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { apiRequest } from "../../actions/api";

// this middleware only care about the getBooks action
export const getGenresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_GENRES) {
    dispatch(
      apiRequest(
        "GET",
        "/genre.php",
        action.payload,
        null,
        fetchGenresSuccess,
        fetchGenresError,
        false,
        "[genre]"
      )
    );
    dispatch(showSpinner("genre"));
  }

};

// on successful fetch, process the books data
export const processGenresCollection = ({ dispatch }) => (next) => (
  action
) => {
  next(action);

  if (action.type === FETCH_GENRES_SUCCESS) {
    let genres = action.payload.data.items
    let sortByDisplayOrder = genres.sort((ga,gb) => ga.display_order - gb.display_order)
    dispatch(setGenres(sortByDisplayOrder));
    dispatch(hideSpinner("genre"));
  }

  if (action.type === FETCH_GENRES_ERROR) {
    dispatch(hideSpinner("genre"));
  }

  
};

export const genreMdl = [getGenresFlow, processGenresCollection];
