// Import Section
import React from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import "./ForgetPasswordComponent.css";
import * as authOperations from "../../store/actions/authAction";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from "../../images/memorylanelogo.png";
import { showModal } from "../../store/actions/ui";
import { connect } from "react-redux";
import {MAIL_REGEX} from "../../js/constants"

function ForgetPasswordComponent({ auth, showModal, forgetPassword }) {
  const { loading, isForgetpass } = auth;

  // Set State value using immer
  const [form, setForm] = useImmer({
    email: "",
    error: {
      email: "",
    },
  });
  // To handle the Onchange Function
  const handleLoginInputChange = (e) => {
    setForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  // Login validation
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.email.length === 0) {
      setForm((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!form.email.match(MAIL_REGEX)) {
      setForm((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setForm((draft) => {
        draft.error.email = "";
      });
    }

    if (form.email.length > 256) {
      setForm((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setForm((draft) => {
        draft.error.email = "";
      });
    }

    var useremail = form.email;
    const user = {
      email: useremail,
    };
    forgetPassword(user);
  };
  return (
    <>
      {isForgetpass ? (
        <ReactBootstrap.Card className="passwdReset">
          <ReactBootstrap.Card.Header>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} md={4} className="pl-0">
                <div className="PasswdResetLogo">
                  <img src={Logo} alt="Logo" />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} md={8}>
                <div className="mt-4">
                  <h5>Password reset sent</h5>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            <p>
              We've emailed you the instructions for setting your password, if
              an account exists with the email you entered
            </p>
            <p>You should receive them shortly.</p>
            <p>
              If you don't receive an email, please make sure you have entered
              the address you registered with and check your spam folder
            </p>
          </ReactBootstrap.Card.Body>
          <ReactBootstrap.Card.Footer>
            <ReactBootstrap.Nav.Link onClick={() => showModal("login")}>
              Back to login
            </ReactBootstrap.Nav.Link>
          </ReactBootstrap.Card.Footer>
        </ReactBootstrap.Card>
      ) : (
        <ReactBootstrap.Form
          className="forgotFormSection"
          onSubmit={handleSubmit}
        >
          <div align="center" className="loginLogo mb-1">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="forgotTitleDiv text-center">
            <h5>Forgot Password</h5>
          </div>
          <ReactBootstrap.Form.Group
            className="mb-4 mt-4"
            controlId="formBasicName"
          >
            <ReactBootstrap.Form.Label>Email:</ReactBootstrap.Form.Label>
            <ReactBootstrap.Form.Control
              type="text"
              name="email"
              value={form.email}
              className={
                form.error.email.length > 0
                  ? "is-invalid form-control"
                  : "form-control"
              }
              placeholder="Enter your email..."
              onChange={handleLoginInputChange}
              autoComplete="off"
            />
            {form.error.email.length > 0 && (
              <span className="invalid-feedback">{form.error.email}</span>
            )}
          </ReactBootstrap.Form.Group>
          <ReactBootstrap.Button type="submit" className="btnReset mt-3">
            Request Password Reset
          </ReactBootstrap.Button>
          <ReactBootstrap.Form.Text className="text-center forgotPassd p-2">
            <p>Remember your password ? </p>
            <ReactBootstrap.Nav.Link onClick={() => showModal("login")}>
              Back to login
            </ReactBootstrap.Nav.Link>
          </ReactBootstrap.Form.Text>
        </ReactBootstrap.Form>
      )}
      {/* *************   Loading  ********************/}
      {loading ? <Loader /> : ""}
      {/* **************************************/}
    </>
  );
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
const mapDispatchToProps = (dispatch) => ({
  forgetPassword: (credentials) =>
    dispatch(authOperations.forgetPassword(credentials)),
  showModal: (label) => dispatch(showModal(label)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPasswordComponent);
