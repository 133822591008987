import React from "react";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import BuyOrdersView from "./BuyOrderView";
import BuyOrderDetailsView from "../BuyOrder/BuyOrderDetailsView/BuyOrderDetailsView";
import {
  getMyBuyOrders,
  getNextMyBuyOrders,
  approveOrderReceivedSetStatus
} from "../../../store/actions/myOrder";
import RateAndReviews from "./RateAndReview/BuyerRateAndReview";
import { OPERATION_STATUS } from "../../../js/constants";
import { ORDER_DELEVERED_STATES } from "../../../js/constants";
import { resetRating} from "../../../store/actions/rating";
import { showModal,hideModal } from "../../../store/actions/ui";
import {addBuyerRating,addBuyerReview,setBuyerRatingStatus} from "../../../store/actions/rating"
const BuyOrderPage = ({
  showModal,
  hideModal,
  //buyer
  page,
  hasMore,
  getMyBuyOrders,
  getNextMyBuyOrders,
  approveOrderReceived,
  approveOrderReceivedSetStatus,
  approveOrderReceivedSubmit,
  buy_orders,
  isLoading,
  // rating
  addBuyerRating,
  isbuyerRatingSendingStatus,
  setBuyerRatingStatus,
  approveOrderReceivedPending,
  approveOrderReceivedStatus,
  reset_rating,
  resetRating,
  // review
  addBuyerReview
}) => {
  

  useEffect(() => {
    getMyBuyOrders();
  }, []);

  /////////////// THIS SECTION FOR HANDLE SHOW AND HIDE ////////////////////
  const [showMyOrderList, setShowOrderList] = useImmer(true);
  const [showOrderDetail, setShowOrderDetail] = useImmer(false);
  // THIS FUNCTION FOR GET BUYER ORDER DETAILS
  var initial_State = {
    items: "",
  };
  const [OrderDetailstate, setOrderDetailstate] = useImmer(initial_State);

  useEffect(() => {
    if (OrderDetailstate.items) {
      
      let filtered_item = buy_orders.filter(
        (s) => s.suborder_no === OrderDetailstate.items.suborder_no
      );
      setOrderDetailstate((draft) => {
        draft.items = filtered_item[0];
      });
    }
  }, [buy_orders]);
  const getBuyerOrderDetailView = (items) => {
    setOrderDetailstate((draft) => {
      draft.items = items;
    });
    setShowOrderList(false);
    setShowOrderDetail(true);
  };
  const viewRateByList = (items,e)=>{
    console.log(items)
    setOrderDetailstate((draft) => {
      draft.items = items;
    });
    setShowOrderList(false);
    setShowOrderDetail(true);
    if(items.ratings.buyer_ratings.communication_rating !==""){
      setratingState((draft) => {
        draft.operation= "Update";
        draft.communication_rating =items.ratings.buyer_ratings.communication_rating;
        draft.description_rating =items.ratings.buyer_ratings.description_rating;
        draft.shipping_rating =items.ratings.buyer_ratings.shipping_rating;
        draft.review=items.review.buyer_review;
      });
      showModal("BuyerRatings");
    }else{
      setratingState((draft) => {
        draft.operation= "Add";
        draft.communication_rating ="";
        draft.description_rating ="";
        draft.shipping_rating ="";
        draft.review="";
      });
      showModal("BuyerRatings");
    }
  }

  //BACK TO MAIN FROM DETAILS PAGE

  const backToMainFromDetail = () => {
    setShowOrderList(true);
    setShowOrderDetail(false);
  };

  //******************************THIS SECTION FOR BUYER FILTER COMPONENT************************************* */
  const filternames = {
    DATEFROM: "date_from",
    DATETO: "date_to"
  };
  //GET THE FILTER VALUE
  const handleFilterChange = (name, e) => {
    setFilter((draft) => {
      const index = draft.findIndex((d) => d.name === name);
      if (index !== -1) {
        draft[index].value =
          draft[index].type === "select" ? e : e.target.value;
      }
    });
  };
  //FILTER OPTIONS
  const statusOptions = [
    { id: 1, value: "OPEN", label: "OPEN" },
    { id: 2, value: "SHIPPED", label: "SHIPPED" },
    { id: 3, value: "DELIVERED", label: "DELIVERED" },
  ];
  const initial_filters = [
    {
      placeholder: "Date from",
      type: "date",
      value: "",
      name: filternames.DATEFROM,
      onChange: handleFilterChange,
    },
    {
      placeholder: "Date to",
      type: "date",
      value: "",
      name: filternames.DATETO,
      onChange: handleFilterChange,
    }
  ];

  const [FilterState, setFilter] = useImmer(initial_filters);
  //GET ALL FILTER VALUE FOR EACH FILTERS
  const getFilterValues = () => {
    var filter_values = {};
    FilterState.forEach((f) => {
      if (f.value) {
        filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
      }
    });
    return filter_values;
  };
  // FILTER SUBMIT PROCESS

  const filterSubmit = () => {
    let filters = {
      ...getFilterValues(),
    };
    getMyBuyOrders(filters);
  };

  // CLEAR FILTER VALUE
  const reset = () => {
    setFilter((draft) => {
      draft.forEach((f) =>
        f.type === "select" ? (f.value = null) : (f.value = "")
      );
    });
    getMyBuyOrders();
  };
  // /////////////////////////////BUYER NEXT PAGE ////////////////////////////

  const nextPage = () => {
    let filters = {
      page: page,
      ...getFilterValues(),
    };
    getNextMyBuyOrders(filters);
  };
   //******************************THIS SECTION FOR RATING************************************* */
  const onRatingSubmit =(content)=>{
      if(content.review.length>0){
    const reviewData={
            order_no:OrderDetailstate.items.order_no,
            sub_order_id:OrderDetailstate.items.suborder_no,
            description:content.review
          }
        addBuyerReview(reviewData)
      }
    const data ={
              communication_rating : content.communication_rating,
              description_rating :content.description_rating,
              shipping_rating :content.shipping_rating,
              order_no:OrderDetailstate.items.order_no,
              suborder_no:OrderDetailstate.items.suborder_no
      }
     addBuyerRating(data)
   }
    useEffect(()=>{
    if(isbuyerRatingSendingStatus===false){
      setBuyerRatingStatus(OPERATION_STATUS.YET_TO_START)

    }
  },[isbuyerRatingSendingStatus])
// Add And Edit Modal
var initial_rating = {
  operation: "Add",
  communication_rating :"",
  description_rating :"",
  shipping_rating :"",
  review : ""
};
const [ratingState, setratingState] = useImmer(initial_rating);

const onAddRating=(e)=>{
  e.preventDefault();
  setratingState((draft) => {
    draft.operation= "Add";
    draft.communication_rating ="";
    draft.description_rating ="";
    draft.shipping_rating ="";
    draft.review="";
  });
  showModal("BuyerRatings");
}

  const onEditRating=(e)=>{
    e.preventDefault();
    setratingState((draft) => {
      draft.operation= "Update";
      draft.communication_rating =OrderDetailstate.items.ratings.buyer_ratings.communication_rating;
      draft.description_rating =OrderDetailstate.items.ratings.buyer_ratings.description_rating;
      draft.shipping_rating =OrderDetailstate.items.ratings.buyer_ratings.shipping_rating;
      draft.review=OrderDetailstate.items.review.buyer_review;
    });
    showModal("BuyerRatings");
  }
  const onRatingClear = (e) => {
    setratingState((draft) => {
      draft.operation= "Add";
      draft.communication_rating ="";
      draft.description_rating ="";
      draft.shipping_rating ="";
      draft.review=""
    });
    hideModal("BuyerRatings");
  };

   useEffect(()=>{
    if(reset_rating ===true){
      onRatingClear()
   resetRating(false)
    }
    
  },[reset_rating])



  useEffect(() => {
    if (approveOrderReceivedStatus === OPERATION_STATUS.SUCCESS) {
      approveOrderReceivedSetStatus(OPERATION_STATUS.YET_TO_START);
    } else if (approveOrderReceivedStatus === OPERATION_STATUS.FAILURE) {
      approveOrderReceivedSetStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [approveOrderReceivedStatus]);

  return (
    <React.Fragment>
      {isLoading || approveOrderReceivedPending ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <Container>
          {showMyOrderList && (
            <div className="my-4">
              <BuyOrdersView
                nextPage={nextPage}
                hasMore={hasMore}
                buyOrderList={buy_orders}
                getBuyerOrderDetailView={getBuyerOrderDetailView}
                viewRateByList={viewRateByList}
                buyerFilterState={FilterState}
                onApply={filterSubmit}
                onClear={reset}
                approveOrderReceived={approveOrderReceived}
              />
            </div>
          )}
          {showOrderDetail && OrderDetailstate !== null && (
            <BuyOrderDetailsView
              backToMainFromDetail={backToMainFromDetail}
              //Showing Data
              order_no={OrderDetailstate.items.order_no}
              order_status={OrderDetailstate.items.order_status}
              order_date={OrderDetailstate.items.order_date}
              buyer_name={OrderDetailstate.items.buyer_name}
              seller_name={OrderDetailstate.items.seller_name}
              is_admin={OrderDetailstate.items.is_admin}
              seller_id={OrderDetailstate.items.seller_id}
              delivery_expected_date={OrderDetailstate.items.delivery_date}
              delivery_status={OrderDetailstate.items.delivery_status}
              carrier={OrderDetailstate.items.carrier}
              service_name={OrderDetailstate.items.service_name}
              tracking_id={OrderDetailstate.items.tracking_id}
              base_price={OrderDetailstate.items.pricing_summary.base_price}
              tax_price={OrderDetailstate.items.pricing_summary.tax_price}
              delivery_charge={
                OrderDetailstate.items.pricing_summary.delivery_charge
              }
              total_price={OrderDetailstate.items.pricing_summary.total_price}
              payment_type={OrderDetailstate.items.payment_summary.payment_type}
              line_items={OrderDetailstate.items.line_items}
              shipping_add={OrderDetailstate.items.shipping_add}
              billing_add={OrderDetailstate.items.billing_add}
              seller_ratings={OrderDetailstate.items.ratings.seller_ratings}
              buyer_ratings={OrderDetailstate.items.ratings.buyer_ratings}
              buyer_review={OrderDetailstate.items.review.buyer_review}
              seller_review={OrderDetailstate.items.review.seller_review}
              payment_type={
                OrderDetailstate.items.payment_summary.payment_method
              }
              payment_date={OrderDetailstate.items.payment_summary.payment_date}
              payment_status={
                OrderDetailstate.items.payment_summary.payment_status
              }
              payment_paid_status={
                OrderDetailstate.items.payment_summary.payment_paid_status
              }
              approveOrderReceived={approveOrderReceived}
              onEditRating={onEditRating}
               onAddRating={onAddRating}
            />
            )}
            </Container>
        )}
      <RateAndReviews
          onRatingClear={onRatingClear}
          onRatingSubmit={onRatingSubmit}
          isbuyerRatingSendingStatus={isbuyerRatingSendingStatus}
          operation= {ratingState.operation}
          communicationRating ={ratingState.communication_rating}
          descriptionRating ={ratingState.description_rating}
          shippingRating ={ratingState.shipping_rating}
          review={ratingState.review}
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    isbuyerRatingSendingStatus:
    state.rating.isbuyerRatingSendingStatus === OPERATION_STATUS.PENDING,
    isLoading:
      "mybuyorder_init" in state.ui.loaders
        ? state.ui.loaders["mybuyorder_init"]
        : true,
    buy_orders: state.myOrder.buy.orders,
    hasMore: state.myOrder.buy.hasMore,
    page: state.myOrder.buy.nextPage,
    approveOrderReceivedStatus: state.myOrder.buy.approve_order_received.status,
    approveOrderReceivedPending:
      state.myOrder.buy.approve_order_received.status === OPERATION_STATUS.PENDING,
      reset_rating:state.rating.reset_status
  };
};

export default connect(mapStateToProps, {
  addBuyerRating,
  setBuyerRatingStatus,
  addBuyerReview,
  showModal,
  hideModal,
  getMyBuyOrders,
  getNextMyBuyOrders,
  approveOrderReceivedSetStatus,
  resetRating
})(BuyOrderPage);
