import {
        GET_REPORT_ADMIN,
        FETCH_REPORT_ADMIN_SUCCESS,
        FETCH_REPORT_ADMIN_ERROR,
        getReportListSuccess,
        getReportListError,
        setgetReportList,

        GET_REPLIES_ADMIN,
        FETCH_REPLIES_ADMIN_SUCCESS,
        FETCH_REPLIES_ADMIN_ERROR,
        getRepliesListSuccess,
        getRepliesListError,
        setRepliesList,
        setnextRepliesList,

        ADD_REPORT_ADMIN, 
        ADD_REPORT_ADMIN_SUCCESS,
        ADD_REPORT_ADMIN_ERROR ,
        addAdminReportSuccess,
        addAdminReportError,
        setReportAdminStatus,
        getReportList,

        SEND_MESSAGE_ADMIN,
        SEND_MESSAGE_ADMIN_SUCCESS,
        SEND_MESSAGE_ADMIN_ERROR,
        sendMessageAdminSuccess,
        sendMessageAdminError,
        setMessageAdminStatus,

        GET_REPORT_TYPES,
        GET_REPORT_TYPES_SUCCESS,
        GET_REPORT_TYPES_ERROR,
        getreportTypesSuccess,
        getreportTypesError,
        setreportType
  } from "../../actions/reportAdmin.js";
  import { showSpinner, hideSpinner } from "../../actions/ui";
  import { apiRequest } from "../../actions/api";
  import Avatar from "../../../images/avatar.png";
  import { formattedMessageDisplayDateTime,formattedDate } from "../../../js/utils";
  import { notification_types, OPERATION_STATUS } from "../../../js/constants";
  
  // this middleware only care about the getBooks action
  export const getReportAdminFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_REPORT_ADMIN) {
      dispatch(
        apiRequest(
          "GET",
          "/user/common_report/report.php",
          action.payload,
          null,
          getReportListSuccess,
          getReportListError,
          true,
          "[reportAdmin]"
        )
      );
      dispatch(showSpinner("report-rooms"));
    }
  
  };
  
  // on successful fetch, process the books data
  export const processReportAdminCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === FETCH_REPORT_ADMIN_SUCCESS) {
      let rooms = action.payload.data.items.map((room) => {
        return {
          room_id:room.room_id,
          type:room.type,
          reported_by:room.reported_by,
          description:room.content,
          status:room.status,
          title:room.title,
          reported_date:formattedDate(room.created_date)
        }
      })
      dispatch(setgetReportList(rooms));
      dispatch(hideSpinner("report-rooms"));
    }
  
    if (action.type === FETCH_REPORT_ADMIN_ERROR) {
      dispatch(hideSpinner("report-rooms"));
    }
  
    
  };
  // this middleware only care about the getBooks action
  export const getRepliesAdminFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_REPLIES_ADMIN) {
      dispatch(
        apiRequest(
          "GET",
          "/user/common_report/replies.php",
          action.payload,
          null,
          getRepliesListSuccess,
          getRepliesListError,
          true,
          "[reportAdmin]"
        )
      );
      dispatch(showSpinner("report-messages_init"));
    }
  
  };
  
  // on successful fetch, process the books data
  export const processRepliesAdminCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === FETCH_REPLIES_ADMIN_SUCCESS) {
      let replies=action.payload.data.items
      let messages =  {
          title:replies[0].title,
          description:replies[0].description,
          reply_list:replies[0].replies.map(message =>{
            return{
              id: message.message_id,
              message: message.content,
              send_date: formattedMessageDisplayDateTime(message.sent_date),
              sender_details: {
                id: message.sender.user_id,
                name: message.sender.user_name,
                avatar: {
                  image_50: message.sender.avatar ? message.sender.avatar : Avatar,
                  image_200: message.sender.avatar ? message.sender.avatar : Avatar,
                  image_400: message.sender.avatar ? message.sender.avatar : Avatar,
                },
              }
            }
          })
      }
      dispatch(setRepliesList(messages));
      dispatch(hideSpinner("report-messages_init"));
    }
  
    if (action.type === FETCH_REPLIES_ADMIN_ERROR) {
      dispatch(hideSpinner("report-messages_init"));
    }
  };
   // this middleware only care about the getBooks action
   export const AddReportAdminFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === ADD_REPORT_ADMIN) {
      dispatch(setReportAdminStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "POST",
          "/user/common_report/report.php",
          null,
          action.payload,
          addAdminReportSuccess,
          addAdminReportError,
          true,
          "[reportAdmin]"
        )
      );
      dispatch(showSpinner("reportAdmin"));
    }
  
  };
  // on successful fetch, process the books data
  export const processAddReportAdminCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === ADD_REPORT_ADMIN_SUCCESS) {
      let replies = action.payload.data
      dispatch(getReportList());
      dispatch(setReportAdminStatus(OPERATION_STATUS.SUCCESS));
      dispatch(hideSpinner("reportAdmin"));
    }
  
    if (action.type === ADD_REPORT_ADMIN_ERROR) {
      dispatch(setReportAdminStatus(OPERATION_STATUS.FAILURE));
      dispatch(hideSpinner("reportAdmin"));
    }
  };



  export const SendMessageAdminFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === SEND_MESSAGE_ADMIN) {
      dispatch(setMessageAdminStatus(OPERATION_STATUS.PENDING));
      dispatch(
        apiRequest(
          "POST",
          "/user/common_report/replies.php",
          null,
          action.payload, 
          sendMessageAdminSuccess,
          sendMessageAdminError,
          true,
          "[reportAdmin]"
        )
      );
      dispatch(showSpinner("reportAdmin"));
    }
  
  };
  // on successful fetch, process the books data
  export const processSendMessageAdminCollection = ({ dispatch,getState }) => (next) => (
    action
  ) => {
    next(action);
  
    if (action.type === SEND_MESSAGE_ADMIN_SUCCESS) {
    let message = action.payload.data
    let sent_message = {
      id: message.message_id,
      message: message.message,
      send_date: formattedMessageDisplayDateTime(message.sent_date),
      sender_details: {
        id: message.sender.user_id,
        name: message.sender.user_name,
        avatar: {
          image_50: message.sender.avatar ? message.sender.avatar : Avatar,
          image_200: message.sender.avatar ? message.sender.avatar : Avatar,
          image_400: message.sender.avatar ? message.sender.avatar : Avatar,
        },
      }
    }
      const prevMessages = getState().reportAdmin.replies.reply_list;
      const currtMessages = [sent_message,...prevMessages];
      var data ={
        title:message.title,
        description:message.description,
        reply_list:currtMessages
      }
      dispatch(setnextRepliesList(data));
      dispatch(setMessageAdminStatus(OPERATION_STATUS.SUCCESS));
      dispatch(hideSpinner("reportAdmin"));
    }
  
    if (action.type === SEND_MESSAGE_ADMIN_ERROR) {
      dispatch(setMessageAdminStatus(OPERATION_STATUS.FAILURE));
      dispatch(hideSpinner("reportAdmin"));
    }
  };

  export const GetTypesFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_REPORT_TYPES) {
      dispatch(
        apiRequest(
          "GET",
          "/user/common_report/report.php?action=type",
          action.payload,
          null, 
          getreportTypesSuccess,
          getreportTypesError,
          true,
          "[reportAdmin]"
        )
      );
      dispatch(showSpinner("reportAdmin"));
    }
  
  };
  // on successful fetch, process the books data
  export const processGetTypesCollection = ({ dispatch }) => (next) => (
    action
  ) => {
    next(action);
    if (action.type === GET_REPORT_TYPES_SUCCESS) {
      let types = action.payload.data.map((room) => {
        return {
        id: room.id,
        value: room.type,
        label: room.type
        }
      })
      dispatch(setreportType(types));
      dispatch(hideSpinner("reportAdmin"));
    }
    if (action.type === GET_REPORT_TYPES_ERROR) {
      dispatch(hideSpinner("reportAdmin"));
    }
  }; 


  export const reportAdminMdl = [
    getReportAdminFlow,
     processReportAdminCollection,
     getRepliesAdminFlow,
     processRepliesAdminCollection,
     AddReportAdminFlow,
     processAddReportAdminCollection,
     SendMessageAdminFlow,
     processSendMessageAdminCollection,
     GetTypesFlow,
     processGetTypesCollection
    ];