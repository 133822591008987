import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Button, Dropdown } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import AddressViewComponent from "../../AddressViewComponent/AddressViewComponent";
import { ORDER_DELEVERED_STATES } from "../../../../js/constants";

const SellOrderCardView = ({
  sellOrderDetailsView,
  viewRateByList,
  order_no,
  tracking_id,
  carrier,
  service_name,
  image,
  buyer_name,
  order_at,
  due_amount,
  total_amount,
  shipping_add,
  fulfillment_status,
  items,
  first_ticket_name,
  first_ticket_image,
  remaining_items_count,
  onAddfullfillmentModel,
  onEditfullfillmentModel,
  approveOrderDelivered,
}) => {
  return (
    <React.Fragment>
      <Card className="searchResultCard my-4">
        <Card.Body>
          <Row>
            <Col
              xs={12}
              md={2}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <div>
                  <img src={first_ticket_image} width="50" height="80" />
                  {remaining_items_count > 0 && (
                    <span className="counter sellOrder-count ">
                      {remaining_items_count > 10
                        ? "10+"
                        : "+" + remaining_items_count}
                    </span>
                  )}
                </div>
                <p className="my-2" title="Click to contact seller">
                  Buyer: <span className="icon_padding">{buyer_name}</span>
                </p>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <div>
                <div className="flex-grow">
                  <h5>
                    {first_ticket_name}
                    {remaining_items_count > 0 ? " & more..." : ""}
                  </h5>
                </div>
                <div className=" my-2">
                  Total cost: <i className="fa fa-usd" aria-hidden="true"></i>
                  <span>{total_amount}</span>
                </div>
                <div className=" my-2">
                  Due Amount: <i className="fa fa-usd" aria-hidden="true"></i>
                  <span>{due_amount}</span>
                </div>
                <div className="">
                  <i className="fa fa-calendar" aria-hidden="true"></i> Ordered
                  on: <span>{order_at}</span>
                </div>
              </div>
              <Dropdown.Divider />
              <div className="d-flex flex-wrap">
                <div>
                  {shipping_add !== null && (
                    <>
                      <strong>Shipping Address</strong>
                      <AddressViewComponent
                        first_name={shipping_add.first_name}
                        last_name={shipping_add.last_name}
                        address_line_1={shipping_add.address_line_1}
                        address_line_2={shipping_add.address_line_2}
                        administrative_area={shipping_add.administrative_area}
                        city={shipping_add.city}
                        country={shipping_add.country}
                        postal_code={shipping_add.postal_code}
                        mobile_no={shipping_add.mobile_no}
                      />
                    </>
                  )}
                </div>

                <div className="ml-2">
                  <strong className="searchResultEventName textBlackColor mr-2">
                    Tracking information
                  </strong>
                  {(fulfillment_status === ORDER_DELEVERED_STATES.OPEN ||
                    fulfillment_status === ORDER_DELEVERED_STATES.SHIPPED) && (
                    <>
                      {tracking_id ? (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            onEditfullfillmentModel(items);
                          }}
                          to="/editTrackingInformation"
                        >
                          Edit
                        </Link>
                      ) : (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            onAddfullfillmentModel(order_no);
                          }}
                          to="/addTrackingInformation"
                        >
                          Add
                        </Link>
                      )}
                    </>
                  )}

                  <div>
                    {carrier && <div>Carrier Name: {carrier}</div>}
                    {service_name && <div>Service Type: {service_name}</div>}
                    {tracking_id && <div>Tracking Id: {tracking_id}</div>}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={2}
              xs={12}
              className="text-center d-flex align-items-center justify-content-center"
            >
              <div>
                <div>
                  {(fulfillment_status ===
                    ORDER_DELEVERED_STATES.DELIVERED_SELLER ||
                    fulfillment_status ===
                    ORDER_DELEVERED_STATES.DELIVERED_ADMIN ||
                    fulfillment_status ===
                    ORDER_DELEVERED_STATES.DELIVERED_BUYER) && (
                    <Badge className="badge-success">Completed </Badge>
                  )}
                </div>

                {fulfillment_status === ORDER_DELEVERED_STATES.SHIPPED && (
                  <div>
                    <Button
                      className="btn btn-secondary"
                      size="sm"
                      onClick={(e) =>{
                        e.preventDefault();
                        approveOrderDelivered(order_no)
                      } }
                    >
                      Order Delivered
                    </Button>
                  </div>
                )}
                <div className="">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      sellOrderDetailsView(items);
                    }}
                    to="/order"
                  >
                    More details <i className="fa fa-arrow-right mr-2"></i>
                  </Link>
                </div>
                {fulfillment_status === ORDER_DELEVERED_STATES.DELIVERED_ADMIN ||
                fulfillment_status === ORDER_DELEVERED_STATES.DELIVERED_BUYER && (
                <div className="">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      viewRateByList(items);
                    }}
                    to="/order"
                  >
                    Give ratings
                  </Link>
                </div>
            )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SellOrderCardView;
