import axios from "axios";
import * as configs from "../js/constants";
import qs from 'qs'


const userAPI = axios.create({
  baseURL: configs.API_BASE_URL,
  withCredentials: true,
});


//functions to make api calls
const api = {
    Home: {
      popularSearches: () => {
        return userAPI.get(`${configs.API_BASE_URL}/popular.php?action=searches`);
      },
      popularEvents: () => {
        return userAPI.get(`${configs.API_BASE_URL}/popular.php?action=events`);
      },
      popularTeams: () => {
        return userAPI.get(`${configs.API_BASE_URL}/popular.php?action=teams`);
      }
    },
    genre:{
      genreList: (patams) => {
        const queryparams = {
          status: "APPROVED",
                ...patams
        }
        return userAPI.get(`${configs.API_BASE_URL}/genre.php`,{ 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      },
      Getgenre: (content) => {
        return userAPI.get(`${configs.API_BASE_URL}/genre.php`,{ 
          params: content,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      }
    },
    team:{
      TeamList: (query) => {
        
        let queryparams = {
          status: "APPROVED",...query
        }
        return userAPI.get('/teams.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      }
    },
    artist:{
      ArtistList: (data) => {
        const queryparams = {
          status: "APPROVED",
          ...data,
        }
        return userAPI.get('/artist.php', { 
          params: queryparams,
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        });
      }
    },
};

export {
    api
}
