import {
  UPDATE_ORDER,
  APPROVE_PAYMENT_ORDER_SET_STATUS,
  CANCEL_ORDER_SET_STATUS,
  EXPIRED_ORDER_SET_STATUS,
  EXPIRED_ORDER_SET_EXPIRY_STATUS
} from "../actions/orderPayment";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  order: {
    info: null,
    error: null,
  },
  approve_payment_status: OPERATION_STATUS.YET_TO_START,
  order_cancelled_status: OPERATION_STATUS.YET_TO_START,
  order_Expiry_status: OPERATION_STATUS.YET_TO_START,
  ExpiryStatus: true,
};

export const orderPaymentReducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_ORDER:
      draft.order.info = action.payload;
      break;
    case APPROVE_PAYMENT_ORDER_SET_STATUS:
      draft.approve_payment_status = action.payload;
      break;
    case CANCEL_ORDER_SET_STATUS:
    draft.order_cancelled_status = action.payload;
    break;
    case EXPIRED_ORDER_SET_STATUS:
      draft.order_Expiry_status = action.payload;
    break;
    case EXPIRED_ORDER_SET_EXPIRY_STATUS:
      draft.ExpiryStatus=action.payload
    break;
    default:
      break;
  }
}, INITIAL_STATE);
