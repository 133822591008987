import React from "react";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { notification_types } from "../../js/constants";
import { removeNotification } from "../../store/actions/notification";

const NotifyComponent = ({ notifications, onCloseNotification }) => {
  notifications.forEach((notification) => {
    switch (notification.type) {
      case notification_types.success:
        toast.success(notification.message, {
          toastId: notification.id,
          position: toast.POSITION.TOP_RIGHT,
          onClose: () =>
            onCloseNotification(notification.id, notification.feature),
        });
        break;
      case notification_types.error:
        toast.error(notification.message, {
          toastId: notification.id,
          position: toast.POSITION.TOP_RIGHT,
          onClose: () =>
            onCloseNotification(notification.id, notification.feature),
        });
        break;
      case notification_types.warning:
        toast.warn(notification.message, {
          toastId: notification.id,
          position: toast.POSITION.TOP_RIGHT,
          onClose: () =>
            onCloseNotification(notification.id, notification.feature),
        });
        break;
      case notification_types.info:
        toast.info(notification.message, {
          toastId: notification.id,
          position: toast.POSITION.TOP_RIGHT,
          onClose: () =>
            onCloseNotification(notification.id, notification.feature),
        });
        break;
      default:
        return null;
    }
  });

  return (
    <React.Fragment>
      <ToastContainer closeOnClick={false} />
    </React.Fragment>
  );
};

NotifyComponent.propTypes = {
  notifications: PropTypes.array.isRequired,
  onCloseNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  onCloseNotification: (notification_id, feature) =>
    dispatch(removeNotification(notification_id, feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyComponent);
