// Import Section
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "../LoginComponent/LoginComponent.css";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from "../../images/memorylanelogo.png";
import { showModal, hideModal } from "../../store/actions/ui";
import { login } from "../../store/actions/auth";
import { OPERATION_STATUS, USER_STATES, MAIL_REGEX } from "../../js/constants";


const LoginComponent = ({
  showModal,
  hideModal,
  login,
  isLogged,
  loading,
}) => {
  const history = useHistory();
  var initial_state = {
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  useEffect(() => {
    if (isLogged) {
      // getUser();
      hideModal("login");
      if(history.location.state){
        history.push(history.location.state.from);
      }
    }
  }, [isLogged]);

  // To get Redux value
  // const dispatch = useDispatch();
  // const getUser = async () => {
  //   try {
  //     dispatch(Operations.userInfo());
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // Set State value using immer
  const [loginForm, setLoginForm] = useImmer(initial_state);
  // To handle the Onchange Function
  const handleLoginInputChange = (e) => {
    setLoginForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };

  // Login validation
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loginForm.email.length === 0) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!loginForm.email.match(MAIL_REGEX)) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.email.length > 256) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.password.length === 0) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    if (loginForm.password.length > 256) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    var useremail = loginForm.email;
    var password = loginForm.password;
    const user = {
      email: useremail,
      password: password,
    };
    login(user);
  };
  return (
    <>
      <ReactBootstrap.Form className="loginFormSection" onSubmit={handleSubmit}>
        <div align="center" className="loginLogo mb-1">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="loginTitleDiv text-center">
          <h5>Login</h5>
        </div>
        <ReactBootstrap.Form.Group
          className="mb-4 mt-4"
          controlId="formBasicName"
        >
          <ReactBootstrap.Form.Label className="requiredField">
            Email
          </ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Control
            type="text"
            name="email"
            value={loginForm.email}
            className={loginForm.error.email.length > 0 ? "is-invalid" : ""}
            placeholder="Enter your mail..."
            onChange={handleLoginInputChange}
            autoComplete="off"
          />
          {loginForm.error.email.length > 0 && (
            <span className="invalid-feedback">{loginForm.error.email}</span>
          )}
        </ReactBootstrap.Form.Group>

        <ReactBootstrap.Form.Group
          className="mb-4 mt-4"
          controlId="formBasicPassword"
        >
          <ReactBootstrap.Form.Label className="requiredField">
            Password
          </ReactBootstrap.Form.Label>
          <ReactBootstrap.Form.Control
            type="password"
            name="password"
            value={loginForm.password}
            className={loginForm.error.password.length > 0 ? "is-invalid" : ""}
            placeholder="Enter your password..."
            onChange={handleLoginInputChange}
          />
          {loginForm.error.password.length > 0 && (
            <span className="invalid-feedback">{loginForm.error.password}</span>
          )}
        </ReactBootstrap.Form.Group>

        <ReactBootstrap.Button type="submit" className="btnSignIn mt-3">
          Login
        </ReactBootstrap.Button>

        <ReactBootstrap.Form.Text className="text-center forgotPassd p-2">
          <ReactBootstrap.Nav.Link
            href="/forgot-password"
            onClick={(e) => {
              e.preventDefault();
              showModal("forgot");
            }}
          >
            Forget Password?{" "}
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Form.Text>

        <ReactBootstrap.Form.Text className="createAccount text-center">
          <span>
            Don't have an account ?{" "}
            <Link
              to="/register"
              onClick={(e) => {
                e.preventDefault();
                showModal("register");
              }}
            >
              Create one{" "}
            </Link>
          </span>
        </ReactBootstrap.Form.Text>
      </ReactBootstrap.Form>
      {/* *************   Loading  ********************/}
      {loading ? <Loader /> : ""}
      {/* **************************************/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.userState.user_status === USER_STATES.LOGGED_IN,
    loading: state.login.login_status === OPERATION_STATUS.PENDING,
  };
};
// const mapDispatchToProps = (dispatch) => ({
//   onLogin: (credentials) => dispatch(authOperations.loginUser(credentials)),
//   showModal: (label) => dispatch(showModal(label)),
//   hideModal: (label) => dispatch(hideModal(label)),
// });

export default connect(mapStateToProps, {
  login,
  showModal,
  hideModal,
})(LoginComponent);
