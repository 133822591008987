import React from "react";
import { connect } from "react-redux";
import { Tabs, Tab, Row, Col, Dropdown } from "react-bootstrap";
import MyBuyOffers from "./MyBuyOffers/MyBuyOffersPage";
import MySellOffers from "./MySellOffers/MySellOffersPage";
import "./MyOffersPage.css";
import { USER_PERMISSIONS, USER_PERMISSIONS_STATES } from "../../js/constants";

const MyOffersPage = ({ isActiveSeller }) => {
  return (
    <React.Fragment>
      <div className="my-4">
        <Row>
          <Col xs={12} md={12}>
            <h5>My Offers</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
      </div>
      {isActiveSeller ? (
        <Tabs fill className="tabselect">
          <Tab
            eventKey="buy"
            title={
              <div className="headerbar">
                <i className="fa fa-shopping-cart"></i> BUY
              </div>
            }
          >
            <MyBuyOffers />
          </Tab>
          <Tab
            eventKey="sell"
            title={
              <div className="headerbar">
                <i className="fa fa-credit-card"></i> SELL
              </div>
            }
          >
            <MySellOffers />
          </Tab>
        </Tabs>
      ) : (
        <MyBuyOffers />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isActiveSeller:
      state.permissions.length > 0
        ? state.permissions.some(
            (permission) =>
              permission.permission === USER_PERMISSIONS.SELL &&
              permission.status === USER_PERMISSIONS_STATES.ACTIVE
          )
        : false,
  };
};

export default connect(mapStateToProps, null)(MyOffersPage);
