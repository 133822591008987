import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
function SellerAgreementModel ({show,onHide,Contents}) {
    return (
        <React.Fragment>
            <ReactBootstrap.Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ReactBootstrap.Modal.Header closeButton>
                <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
                    <h6 className="font-weight-bold">Seller Agreement Policy</h6>
                </ReactBootstrap.Modal.Title>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body>
                {
                    Contents && (
                        Contents.content.map((page) => {
                            if (page.content_description) {
                            return (
                                <div dangerouslySetInnerHTML={{__html: page.content_description}}></div>
                            );
                            }
                        })
                    )
                }
                     
                </ReactBootstrap.Modal.Body>
                <ReactBootstrap.Modal.Footer>
                    {/* <ReactBootstrap.Button className="btnModelSubmit">Submit</ReactBootstrap.Button> */}
                    <ReactBootstrap.Button className="btnModelClose" onClick={onHide}>Close</ReactBootstrap.Button>
                </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>
        </React.Fragment>
    )
}

export default SellerAgreementModel;