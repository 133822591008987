import { REGISTER_SELLER_STATUS, SET_SELLER_INFO,SET_SELLER_PAYMENT_DETAILS,SET_SELLER_PAYMENT_STATUS, SET_REPORT_SELLER_INFO_STATUS } from "../actions/seller";
import produce from "immer";
import { OPERATION_STATUS } from "../../js/constants";

const INITIAL_STATE = {
  register_seller_status: OPERATION_STATUS.YET_TO_START,
  seller_payment_status: OPERATION_STATUS.YET_TO_START,
  seller_report_status: OPERATION_STATUS.YET_TO_START,
  
  info: null
};

export const sellerReducer = produce((draft, action) => {
  switch (action.type) {
    case REGISTER_SELLER_STATUS:
      draft.register_seller_status = action.payload;
      break;
    case SET_SELLER_INFO:
      draft.info = action.payload;
      break;
    case SET_SELLER_PAYMENT_DETAILS:
    draft.info.paypal_details.paypal_id = action.payload.paypal_id;
    draft.info.paypal_details.paypal_mail = action.payload.paypal_email;
    break;
    case SET_SELLER_PAYMENT_STATUS:
      draft.seller_payment_status = action.payload;
    break;
	case SET_REPORT_SELLER_INFO_STATUS:
      draft.seller_report_status = action.payload;
    break;
    default:
      break;
  }
}, INITIAL_STATE);
