import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";
import * as ReactBootstrap from "react-bootstrap";
import * as Operations from "../../../store/actions/profileAction";
import { useDispatch } from "react-redux";
import { addAddress, editAddress } from "../../../store/actions/addressAction";
import { connect } from "react-redux";
import { hideModal } from "../../../store/actions/ui";
import Loader from "../../../common/LoaderComponent/LoaderComponent";
import { OPERATION_STATUS} from "../../../js/constants";

const AddressAddEditModel = ({
  loading,
  onShow,
  hideModal,
  address,
  address_type,
  operation,
  AddressID,
  title,
  addAddress,
  editAddress,
  is_reset,
}) => {
  //Page Onload
  useEffect(() => {
    EditAddress();
  }, [address]);

  const EditAddress = async () => {
    var el = address;
    if (address !== null) {
      setAddDetails((draft) => {
        draft.firstName = el.first_name;
        draft.lastName = el.last_name;
        draft.phoneNo = el.mobile_no;
        draft.addressOne = el.address_line_1;
        draft.addressTwo = el.address_line_2;
        draft.area = el.administrative_area;
        draft.city = el.city;
        draft.code = el.postal_code;
        draft.country = el.country;
        draft.address_id = el.id;
      });
    }
  };

  useEffect(() => {
    if (is_reset) {
      setAddDetails({...initial_state});
    }
  }, [is_reset]);
  // To get Redux value
  const dispatch = useDispatch();
  var initial_state = {
    address_id:"",
    type: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    addressOne: "",
    addressTwo: "",
    area: "",
    city: "",
    code: "",
    country: "",
    error: {
      firstName: "",
      lastName: "",
      phoneNo: "",
      addressOne: "",
      addressTwo: "",
      area: "",
      city: "",
      code: "",
      country: "",
    },
  };
  // Set State value using immer
  const [state, setAddDetails] = useImmer(initial_state);
  const InputHandler = (e) => {
    setAddDetails((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  const Submit = () => {
    if (state.firstName.length === 0) {
      setAddDetails((draft) => {
        draft.error.firstName = "Please enter the First Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.firstName = "";
      });
    }
    if (state.firstName.length > 256) {
      setAddDetails((draft) => {
        draft.error.firstName =
          "Please enter upto 256 characters for First Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.firstName =
          "";
      });
    }
    if (state.lastName.length === 0) {
      setAddDetails((draft) => {
        draft.error.lastName = "Please enter the Last Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.lastName = "";
      });
    }
    if (state.lastName.length > 256) {
      setAddDetails((draft) => {
        draft.error.lastName =
          "Please enter upto 256 characters for Last Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.lastName =
          "";
      });
    }
    if (state.phoneNo.length === 0) {
      setAddDetails((draft) => {
        draft.error.phoneNo = "Please enter the Mobile number.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.phoneNo = "";
      })
    }
    if (state.phoneNo.length > 20) {
      setAddDetails((draft) => {
        draft.error.phoneNo =
          "Please enter upto 20 characters for Mobile number.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.phoneNo =
          "";
      });
    }
    if (state.addressOne.length === 0) {
      setAddDetails((draft) => {
        draft.error.addressOne = "Please enter the Address Line 1.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.addressOne = "";
      });
    }
    if (state.addressOne.length > 1000) {
      setAddDetails((draft) => {
        draft.error.addressOne =
          "Please enter upto 1000 characters for Address Line 1.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.addressOne =
          "";
      });
    }
    if (state.addressTwo.length > 1000) {
      setAddDetails((draft) => {
        draft.error.addressTwo =
          "Please enter upto 1000 characters for Address Line 2.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.addressTwo =
          "";
      });
    }
    if (state.city.length === 0) {
      setAddDetails((draft) => {
        draft.error.city = "Please enter the City Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.city = "";
      });
    }
    if (state.city.length > 300) {
      setAddDetails((draft) => {
        draft.error.city = "Please enter upto 300 characters for City Name.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.city = "";
      });
    }
    if (state.area.length === 0) {
      setAddDetails((draft) => {
        draft.error.area = "Please enter the state.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.area = "";
      });
    }
    if (state.area.length > 300) {
      setAddDetails((draft) => {
        draft.error.area =
          "Please enter upto 300 characters for state.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.area = "";
      });
    }
   
    if (state.code.length === 0) {
      setAddDetails((draft) => {
        draft.error.code = "Please enter the Postal Code.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.code = "";
      });
    }
    if (state.code.length > 50) {
      setAddDetails((draft) => {
        draft.error.code = "Please enter upto 50 characters for Postal Code.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.code = "";
      });
    }
    if (state.country.length === 0) {
      setAddDetails((draft) => {
        draft.error.country = "Please enter the Country.";
      });
      return false;
    }
    else{
      setAddDetails((draft) => {
        draft.error.country = "";
      });
    }
    if (state.country.length > 100) {
      setAddDetails((draft) => {
        draft.error.country = "Please enter upto 100 characters for Country.";
      });
      return false;
    }else{
      setAddDetails((draft) => {
        draft.error.country = "";
      });
    }
    if (operation == "ADD") {
      const ADDcontent = {
        first_name: state.firstName,
        last_name: state.lastName,
        mobile_no: state.phoneNo,
        address_line_1: state.addressOne,
        address_line_2: state.addressTwo,
        administrative_area: state.area,
        city: state.city,
        postal_code: state.code,
        country: state.country,
        type: address_type,
      };
      addAddress(ADDcontent);
    } else if (operation == "EDIT") {
      const EDITcontent = {
        first_name: state.firstName,
        last_name: state.lastName,
        mobile_no: state.phoneNo,
        address_line_1: state.addressOne,
        address_line_2: state.addressTwo,
        administrative_area: state.area,
        city: state.city,
        postal_code: state.code,
        country: state.country,
        id: AddressID,
      };
      editAddress(EDITcontent);
    }
  };
  return (
    <>
    <React.Fragment>
      <ReactBootstrap.Modal
        show={onShow}
        onHide={() => hideModal("AddressAddEdit")}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
            <h6 className="font-weight-bold">{title}</h6>
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Form>
          <ReactBootstrap.Modal.Body>
            <ReactBootstrap.Container>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicFirstName"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      First Name
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter Frist name..."
                      value={state.firstName}
                      name="firstName"
                      className={
                        state.error.firstName.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.firstName.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.firstName}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicLastName"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      Last Name
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter last name..."
                      value={state.lastName}
                      name="lastName"
                      className={
                        state.error.lastName.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.lastName.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.lastName}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicNumber"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      Phone
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="number"
                      placeholder="Enter your number..."
                      value={state.phoneNo}
                      name="phoneNo"
                      className={
                        state.error.phoneNo.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                    />
                    {state.error.phoneNo.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.phoneNo}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicAddress1"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      Address Line 1
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      value={state.addressOne}
                      placeholder="Enter address one..."
                      name="addressOne"
                      className={
                        state.error.addressOne.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.addressOne.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.addressOne}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={12}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicAddress2"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold">
                      Address Line 2
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter address two..."
                      value={state.addressTwo}
                      name="addressTwo"
                      className={
                        state.error.addressTwo.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.addressTwo.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.addressTwo}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicCity"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      City
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter your city..."
                      value={state.city}
                      name="city"
                      className={
                        state.error.city.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.city.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.city}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicState"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      State
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter your state..."
                      value={state.area}
                      name="area"
                      className={
                        state.error.area.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.area.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.area}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicPostalCode"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      Postal Code
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter your Postal Code..."
                      value={state.code}
                      name="code"
                      className={
                        state.error.code.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.code.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.code}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} md={6}>
                  <ReactBootstrap.Form.Group
                    className="mb-3"
                    controlId="formBasicCountry"
                  >
                    <ReactBootstrap.Form.Label className="font-weight-bold requiredField">
                      Country
                    </ReactBootstrap.Form.Label>
                    <ReactBootstrap.Form.Control
                      type="text"
                      placeholder="Enter your country..."
                      value={state.country}
                      name="country"
                      className={
                        state.error.country.length > 0 ? "is-invalid" : ""
                      }
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                    {state.error.country.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.country}
                      </span>
                    )}
                  </ReactBootstrap.Form.Group>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </ReactBootstrap.Container>
          </ReactBootstrap.Modal.Body>
          <ReactBootstrap.Modal.Footer>
            <ReactBootstrap.Button className="btnModelSubmit" onClick={Submit}>
              Save
            </ReactBootstrap.Button>
            <ReactBootstrap.Button
              className="btnModelClose"
              onClick={() => hideModal("AddressAddEdit")}
            >
              Close
            </ReactBootstrap.Button>
          </ReactBootstrap.Modal.Footer>
        </ReactBootstrap.Form>
        {loading ? <Loader /> : ""}
      </ReactBootstrap.Modal>
    </React.Fragment>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading:
      "address" in state.ui.loaders ? state.ui.loaders["address"] : true,
    onShow: state.ui.activeModal === "AddressAddEdit",
    address: state.Address.Address_data.address,
    address_type: state.Address.Address_data.AddressType,
    operation: state.Address.Address_data.operations,
    title: state.Address.Address_data.title,
    AddressID: state.Address.Address_data.AddressID,
    is_reset: state.ui.resetModelValue,
    loading: state.Address.AddressStatus === OPERATION_STATUS.PENDING
  };
};
export default connect(mapStateToProps, { addAddress, editAddress, hideModal })(
  AddressAddEditModel
);
