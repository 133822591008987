
import {momentsHistoryMdl} from "./momentshistory"
import {ticketsMdl} from "./tickets"
import {myticketsMdl} from "./mytickets"
import {myOffersMdl} from "./myOffer"
import {genreMdl} from "./genres"
import {landingpageMdl} from "./landingpage"
import {mymessagesMdl} from "./mymessages"
import {appMdl} from "./app"
import {authMdl} from "./auth"
import {sellerMdl} from "./seller"
import {addressMdl} from "./address"
import {profileMdl} from "./profile"
import {myCartMdl} from "./myCart"
import {myOrderMdl} from "./myOrder"
import {checkoutMdl} from "./checkout"
import {orderPaymentMdl} from "./orderPayment"
import {ratingMdl} from "./rating"
import {accountMdl} from "./account"
import {reportAdminMdl} from "./reportAdmin"
import {venueMdl} from "./venue"
import {myCollectionMdl} from "./myCollection"
import {collectionMdl} from "./collections"

export default function getFeatureMiddleware() {
  return [
    ...landingpageMdl,
    ...momentsHistoryMdl,
    ...ticketsMdl,
    ...genreMdl,
    ...mymessagesMdl,
    ...myticketsMdl,
    ...myOffersMdl,
    ...appMdl,
    ...authMdl,
    ...sellerMdl,
    ...addressMdl,
    ...profileMdl,
    ...myCartMdl,
    ...myOrderMdl,
    ...checkoutMdl,
    ...orderPaymentMdl,
    ...ratingMdl,
    ...accountMdl,
    ...reportAdminMdl,
    ...venueMdl,
    ...myCollectionMdl,
    ...collectionMdl
  ]
}