import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { PAGES } from "../../js/constants"
const PrivacyPolicyPage = ({Contents}) => {
    return (
        <React.Fragment>
            <div>
            <Container className="my-4">
                <h5 className="font-weight-bold">Privacy Policy</h5>
                { Contents ? (
                        Contents.content.map((page) => {
                            if ( page.content_description) {
                            return (
                                <div dangerouslySetInnerHTML={{__html: page.content_description}}></div>
                            );
                            }
                        })  
                    )
                    : (
                        <div>No content found...</div>
                    )}
                    
                    
            </Container>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return { 
        Contents: state.app.staticContents.find( ({ page_name }) => page_name === PAGES.PRIVACY )
    };
  };
export default connect(mapStateToProps)(PrivacyPolicyPage);