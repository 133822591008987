import React from "react";
import { connect } from "react-redux";
import { Card, Row, Col, Nav, Dropdown, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./TicketsCardComponentV2.css";
import { Link } from "react-router-dom";

const TicketsCardComponent = ({
  product_id,
  listing_name,
  seller,
  event_name,
  significance,
  venue,
  date,
  teams,
  artists,
  cost,
  image,
  addToOffer,
  inOffer,
  isSeller,
  isEvent,
  addToCart,
  canBuy,
  isSale
}) => {
  return (
    <React.Fragment>
      <Card className="searchResultCard my-4">
        <Card.Body>
          <Row>
            <Col className="text-center d-flex flex-column">
              <div className="flex-grow-1">
                <Nav.Link
                  as={NavLink}
                  exact
                  to={"/tickets/" + product_id}
                  target="_blank"
                >
                  <div className="mb-2 divTicketImage">
                    <img
                      src={image.image_400}
                      alt="ticket_image"
                      className="img-fluid"
                    />
                  </div>
                </Nav.Link>
                <p className="my-2" title="Click to contact seller">
                  <i className="fa fa-handshake-o" aria-hidden="true"></i>
                  <Link
                    key={seller.id}
                    to={"/tickets?seller=" + seller.id}
                    // onClick={(event) => event.preventDefault()}
                  >
                    <span className="ticketType">{seller.name}</span>
                  </Link>
                </p>
              </div>
              <div className="btnBuySpace">
                {/* <Button
                  className="btnCartSearchResult"
                  onClick={() => {
                    addCart();
                  }}
                >
                  <i className="fa fa-cart-plus" aria-hidden="true"></i>
                  ADD TO CART
                </Button> */}
                <Button
                  className="btnBuySearchResult"
                  onClick={() => addToCart(product_id, listing_name)}
                  disabled={!canBuy}
                >
                  <i className="fa fa-ticket" aria-hidden="true"></i>
                  Add to Cart
                </Button>
              </div>
              {isSeller && (
                <div>
                  <Button
                    className=""
                    onClick={() => addToOffer(product_id)}
                    title="Add to offer"
                    disabled={inOffer}
                  >
                    {inOffer ? "SELECTED" : "SELECT"}
                    {/* <i className="fa fa-plus-circle" aria-hidden="true"></i> */}
                  </Button>
                </div>
              )}
            </Col>
            <Col>
              <div className="searchResultEventName textBlackColor">
                <Link to={"/tickets/" + product_id} target="_blank">
                  <h5>{listing_name} </h5>
                </Link>
                <div className="font-weight-bold my-2">
                { isSale !== "0" ?(
                  <>
                  <i className="fa fa-usd" aria-hidden="true"></i> {cost}
                  </>  
                ):(
                  <p>Not for sale</p>
                )
                  }
                 
                </div>
                {!isEvent && (
                  <>
                    <span title="Event">{event_name}</span>
                    {significance && (
                      <>
                        <span>|</span>
                        <span title="Event significance">{significance}</span>
                      </>
                    )}
                  </>
                )}
              </div>
              {!isEvent && (
                <>
                  <Dropdown.Divider />
                  <div className="searchResultLeftColumn textBlackColor">
                    <div title="Event venue">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span className="searchResultLocation">{venue}</span>
                    </div>
                    <div title="Event date">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <span className="searchResultDate">{date}</span>
                    </div>
                  </div>
                  <div
                    className="searchResultCenterColumn textBlackColor"
                    title="Teams"
                  >
                    {teams.length > 0 && (
                      <div>
                        <i className="fa fa-users" aria-hidden="true"></i>
                        <span className="searchResultTeamArt">
                          {teams.join(", ")}
                        </span>
                      </div>
                    )}
                    {artists.length > 0 && (
                      <div title="Artists">
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                        ></i>
                        <span className="searchResultTeamArt">
                          {artists.join(", ")}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

TicketsCardComponent.propTypes = {
  listing_name: PropTypes.string.isRequired,
  seller: PropTypes.object,
  event_name: PropTypes.string.isRequired,
  significance: PropTypes.string,
  cost: PropTypes.string,
  image: PropTypes.object,
  venue: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  teams: PropTypes.array,
  artists: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(TicketsCardComponent);
