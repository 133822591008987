import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-toastify/dist/ReactToastify.css';

// import store from './store/store'
// import { ConnectedRouter } from 'react-router-redux';
// import { Route, Switch } from 'react-router-dom';
// import history from './store/store';
import App from './App';
import { store } from './store/store';

import { userAPIInterceptors } from './apis/userProtectedApis.instance';
userAPIInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    {/* <ConnectedRouter history={history}> */}
      {/* <Switch>
        <Route path="/" component={App} />
      </Switch> */}
    {/* </ConnectedRouter> */}
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
