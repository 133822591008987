import {
  GET_MY_BUY_OFFERS,
  FETCH_MY_BUY_OFFERS_SUCCESS,
  FETCH_MY_BUY_OFFERS_ERROR,
  fetchMyBuyOffersSuccess,
  fetchMyBuyOffersError,
  GET_NEXT_MY_BUY_OFFERS,
  FETCH_NEXT_MY_BUY_OFFERS_SUCCESS,
  FETCH_NEXT_MY_BUY_OFFERS_ERROR,
  fetchNextMyBuyOffersSuccess,
  fetchNextMyBuyOffersError,
  setMyBuyOffers,
  GET_MY_SELL_OFFERS,
  FETCH_MY_SELL_OFFERS_SUCCESS,
  FETCH_MY_SELL_OFFERS_ERROR,
  fetchMySellOffersSuccess,
  fetchMySellOffersError,
  GET_NEXT_MY_SELL_OFFERS,
  FETCH_NEXT_MY_SELL_OFFERS_SUCCESS,
  FETCH_NEXT_MY_SELL_OFFERS_ERROR,
  fetchNextMySellOffersSuccess,
  fetchNextMySellOffersError,
  setMySellOffers,
  SEND_OFFER,
  SEND_OFFER_SUCCESS,
  SEND_OFFER_ERROR,
  sendOfferSuccess,
  sendOfferError,
  setSendOfferData,
  setSendOfferStatus,
  SELECT_MY_OFFER,
  FETCH_SINGLE_MY_OFFER_SUCCESS,
  FETCH_SINGLE_MY_OFFER_ERROR,
  fetchSingleMyOfferSuccess,
  fetchSingleMyOfferError,
  setSingleMyOffer
} from "../../actions/myOffer";
import { showSpinner, hideSpinner } from "../../actions/ui";
import { setNotification } from "../../actions/notification";
import {
  notification_types,
  OPERATION_STATUS,
  OFFER_STATES,
  FLAGGED_TERM
} from "../../../js/constants";
import { apiRequest } from "../../actions/api";
import Avatar from "../../../images/avatar.png";
import { formattedMessageDisplayDateTime } from "../../../js/utils";

const getOfferActions = (is_user_created, is_seller, status) => {
  let actions = {
    isAccept: false,
    isReject: false,
    isCancel: false,
    isEdit: false,
    isCounter: false,
    isCart: false,
  };

  if (is_seller) {
    // Current user of the offer is seller
    // Latest offer status is open
    if (status === OFFER_STATES.OPEN) {
      if (is_user_created) {
        // created by user so can able to edit
        actions.isEdit = true;
      } else {
        // Not created by user so seller can accept, reject or counter offer
        actions.isAccept = true;
        actions.isReject = true;
        actions.isCounter = true;
      }
    } else if (status === OFFER_STATES.ACCEPTED_BUYER) {
      // Buyer accepted the offer so seller should be able to accept
      actions.isAccept = true;
    } else if (status === OFFER_STATES.ACCEPTED_SELLER) {
      // Seller accepted the offer so seller can able to cancel the offer
      actions.isCancel = true;
    }
  } else {
    // Current user of the offer is buyer
    // Latest offer status is open
    if (status === OFFER_STATES.OPEN) {
      if (is_user_created) {
        // created by user so can able to edit
        actions.isEdit = true;
      } else {
        // Not created by user so buyer can accept or counter offer
        actions.isAccept = true;
        actions.isCounter = true;
      }
    } else if (status === OFFER_STATES.ACCEPTED_SELLER) {
      // Seller accepted the offer so buyer can able to add the offer to cart
      actions.isCart = true;
    }
  }

  return actions;
};

const processOffers = (data, loggedUserId) => {
  let result = {};
  let latest_offer = null;
  if (data.counter_offers.length > 0) {
    // Counter offers exist
    let latest_counter_offer =
      data.counter_offers[data.counter_offers.length - 1];

    latest_offer = {
      id: latest_counter_offer.offer_id,
      type: latest_counter_offer.offer_type,
      status: latest_counter_offer.offer_status,
      price: latest_counter_offer.offer_price,
      message: latest_counter_offer.offer_message,
      is_logged_user_created:
        latest_counter_offer.created_by.id === loggedUserId,
      created_by: {
        id: latest_counter_offer.created_by.id,
        name: latest_counter_offer.created_by.name,
        avatar: {
          image_50: latest_counter_offer.created_by.avatar
            ? latest_counter_offer.created_by.avatar
            : Avatar,
          image_200: latest_counter_offer.created_by.avatar
            ? latest_counter_offer.created_by.avatar
            : Avatar,
          image_400: latest_counter_offer.created_by.avatar
            ? latest_counter_offer.created_by.avatar
            : Avatar,
        },
        is_admin: latest_counter_offer.created_by.is_admin,
      },
      created_at: formattedMessageDisplayDateTime(
        latest_counter_offer.created_at
      ),
      items: latest_counter_offer.offer_items.map((offer_item) => {
        return {
          id: offer_item.id,
          product_id: offer_item.product_id,
          name: offer_item.product_listing_name,
          price: offer_item.price,
          image: offer_item.images[0],
        };
      }),
    };
  } else {
    latest_offer = {
      id: data.offer_id,
      type: data.offer_type,
      status: data.offer_status,
      price: data.offer_price,
      message: data.offer_message,
      is_logged_user_created: data.created_by.id === loggedUserId,
      created_by: {
        id: data.created_by.id,
        name: data.created_by.name,
        avatar: {
          image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
          image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
          image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
        },
        is_admin: data.created_by.is_admin,
      },
      created_at: formattedMessageDisplayDateTime(data.created_at),
      items: data.offer_items.map((offer_item) => {
        return {
          id: offer_item.id,
          product_id: offer_item.product_id,
          name: offer_item.product_listing_name,
          price: offer_item.price,
          image: offer_item.images[0],
        };
      }),
    };
  }

  let actions = {
    isAccept: false,
    isReject: false,
    isCancel: false,
    isEdit: false,
    isCounter: false,
    isCart: false,
  };

  // if (data.seller_details.is_admin) {
  let processed_Actions = getOfferActions(
    latest_offer.is_logged_user_created,
    data.seller_details.seller_id === loggedUserId,
    latest_offer.status
  );

  actions.isEdit = processed_Actions.isEdit;
  actions.isAccept = processed_Actions.isAccept;
  actions.isReject = processed_Actions.isReject;
  actions.isCounter = processed_Actions.isCounter;
  actions.isCancel = processed_Actions.isCancel;
  actions.isCart = processed_Actions.isCart;
  // }

  let offer_details = [
    {
      id: data.offer_id,
      type: data.offer_type,
      status: data.offer_status,
      price: data.offer_price,
      message: data.offer_message,
      is_logged_user_created: data.created_by.id === loggedUserId,
      created_by: {
        id: data.created_by.id,
        name: data.created_by.name,
        avatar: {
          image_50: data.created_by.avatar ? data.created_by.avatar : Avatar,
          image_200: data.created_by.avatar ? data.created_by.avatar : Avatar,
          image_400: data.created_by.avatar ? data.created_by.avatar : Avatar,
        },
        is_admin: data.created_by.is_admin,
      },
      created_at: formattedMessageDisplayDateTime(data.created_at),
      items: data.offer_items.map((offer_item) => {
        return {
          id: offer_item.id,
          product_id: offer_item.product_id,
          name: offer_item.product_listing_name,
          price: offer_item.price,
          image: offer_item.images[0],
        };
      }),
      isEdit: actions.isEdit && data.offer_id === latest_offer.id,
      showEdit: false,
    },
  ];

  data.counter_offers.forEach((offer) => {
    offer_details.push({
      id: offer.offer_id,
      type: offer.offer_type,
      status: offer.offer_status,
      price: offer.offer_price,
      message: offer.offer_message,
      is_logged_user_created: offer.created_by.id === loggedUserId,
      created_by: {
        id: offer.created_by.id,
        name: offer.created_by.name,
        avatar: {
          image_50: offer.created_by.avatar ? offer.created_by.avatar : Avatar,
          image_200: offer.created_by.avatar ? offer.created_by.avatar : Avatar,
          image_400: offer.created_by.avatar ? offer.created_by.avatar : Avatar,
        },
        is_admin: offer.created_by.is_admin,
      },
      created_at: formattedMessageDisplayDateTime(offer.created_at),
      items: offer.offer_items.map((offer_item) => {
        return {
          id: offer_item.id,
          product_id: offer_item.product_id,
          name: offer_item.product_listing_name,
          price: offer_item.price,
          image: offer_item.images[0],
        };
      }),
      isEdit: actions.isEdit && offer.offer_id === latest_offer.id,
      showEdit: false,
    });
  });

  result.offer_no = data.offer_no;
  result.status = latest_offer.status;
  result.price = latest_offer.price;
  result.offer_details = offer_details;
  result.latest_offer = latest_offer;
  result.actions = actions;
  result.seller = data.seller_details;
  result.buyer = data.buyer_details;
  result.is_seller_offer = data.seller_details.seller_id === loggedUserId;
  return result;
};
export const getMyBuyOffersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GET_MY_BUY_OFFERS) {
      dispatch(
        apiRequest(
          "GET",
          "/user/offers/offers.php",
          action.payload,
          null,
          fetchMyBuyOffersSuccess,
          fetchMyBuyOffersError,
          true,
          "[myoffers]"
        )
      );
      dispatch(showSpinner("mybuyoffers_init"));
    }

    if (action.type === GET_NEXT_MY_BUY_OFFERS) {
      dispatch(
        apiRequest(
          "GET",
          "/user/offers/offers.php",
          action.payload,
          null,
          fetchNextMyBuyOffersSuccess,
          fetchNextMyBuyOffersError,
          true,
          "[myoffers]"
        )
      );
      dispatch(showSpinner("mybuyoffers_next"));
    }
  };

// on successful fetch, process the books data
export const processMyBuyOffersCollection = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
    if (action.type === FETCH_MY_BUY_OFFERS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
        if (page) {
          hasMore = true;
        }
      }
      let offers = action.payload.data.items.map((offer) => {
        let processed_offer = processOffers(offer, getState().user.id);
        return {
          offer_no: processed_offer.offer_no,
          status: processed_offer.status,
          price: processed_offer.price,
          offer_details: offer,
          processed_offer: processed_offer,
          created_by: {
            id: processed_offer.seller.seller_id,
            name:  processed_offer.seller.seller_name,
            avatar: {
              image_50:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
              image_200:  processed_offer.seller.avatar?  processed_offer.seller.avatar : Avatar,
              image_400:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
            },
            is_admin:  processed_offer.seller.is_admin,
          }
        };
      });
      const newData = {
        offers: offers,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setMyBuyOffers(newData));
      dispatch(hideSpinner("mybuyoffers_init"));
    }

    if (action.type === FETCH_MY_BUY_OFFERS_ERROR) {
      dispatch(hideSpinner("mybuyoffers_init"));
    }

    if (action.type === FETCH_NEXT_MY_BUY_OFFERS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
        if (page) {
          hasMore = true;
        }
      }
      let newoffers = action.payload.data.items.map((offer) => {
        let processed_offer = processOffers(offer, getState().user.id);
        return {
          offer_no: processed_offer.offer_no,
          status: processed_offer.status,
          price: processed_offer.price,
          offer_details: offer,
          processed_offer: processed_offer,
          created_by: {
            id: processed_offer.seller.seller_id,
            name:  processed_offer.seller.seller_name,
            avatar: {
              image_50:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
              image_200:  processed_offer.seller.avatar?  processed_offer.seller.avatar : Avatar,
              image_400:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
            },
            is_admin:  processed_offer.seller.is_admin,
          }
        };
      });

      const prevoffers = getState().myOffers.buy.offers;
      const curroffers = [...prevoffers, ...newoffers];
      const newData = {
        offers: curroffers,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setMyBuyOffers(newData));
      dispatch(hideSpinner("mybuyoffers_next"));
    }

    if (action.type === FETCH_NEXT_MY_BUY_OFFERS_ERROR) {
      dispatch(hideSpinner("mybuyoffers_next"));
    }
  };

export const getMySellOffersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GET_MY_SELL_OFFERS) {
      dispatch(
        apiRequest(
          "GET",
          "/user/offers/offers.php",
          action.payload,
          null,
          fetchMySellOffersSuccess,
          fetchMySellOffersError,
          true,
          "[myoffers]"
        )
      );
      dispatch(showSpinner("myselloffers_init"));
    }

    if (action.type === GET_NEXT_MY_SELL_OFFERS) {
      dispatch(
        apiRequest(
          "GET",
          "/user/offers/offers.php",
          action.payload,
          null,
          fetchNextMySellOffersSuccess,
          fetchNextMySellOffersError,
          true,
          "[myoffers]"
        )
      );
      dispatch(showSpinner("myselloffers_next"));
    }
  };

// on successful fetch, process the books data
export const processMySellOffersCollection = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
    if (action.type === FETCH_MY_SELL_OFFERS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
        if (page) {
          hasMore = true;
        }
      }
      let offers = action.payload.data.items.map((offer) => {
        let processed_offer = processOffers(offer, getState().user.id);
        return {
          offer_no: processed_offer.offer_no,
          status: processed_offer.status,
          price: processed_offer.price,
          offer_details: offer,
          processed_offer: processed_offer,
          created_by: {
            id: processed_offer.buyer.buyer_id,
            name:  processed_offer.buyer.buyer_name,
            avatar: {
              image_50:  processed_offer.buyer.avatar ?  processed_offer.buyer.avatar : Avatar,
              image_200:  processed_offer.buyer.avatar?  processed_offer.buyer.avatar : Avatar,
              image_400:  processed_offer.buyer.avatar ?  processed_offer.buyer.avatar : Avatar,
            },
            is_admin:  processed_offer.buyer.is_admin,
          }
        };
      });
      const newData = {
        offers: offers,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setMySellOffers(newData));
      dispatch(hideSpinner("myselloffers_init"));
    }

    if (action.type === FETCH_MY_SELL_OFFERS_ERROR) {
      dispatch(hideSpinner("myselloffers_init"));
    }

    if (action.type === FETCH_NEXT_MY_SELL_OFFERS_SUCCESS) {
      let hasMore = false;
      let page = null;
      if (action.payload.data.next) {
        let next_url = new URL(action.payload.data.next);
        page = next_url.searchParams.get("page");
        if (page) {
          hasMore = true;
        }
      }
      let newoffers = action.payload.data.items.map((offer) => {
        let processed_offer = processOffers(offer, getState().user.id);
        return {
          offer_no: processed_offer.offer_no,
          status: processed_offer.status,
          price: processed_offer.price,
          offer_details: offer,
          processed_offer: processed_offer,
          created_by: {
            id: processed_offer.seller.seller_id,
            name:  processed_offer.seller.seller_name,
            avatar: {
              image_50:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
              image_200:  processed_offer.seller.avatar?  processed_offer.seller.avatar : Avatar,
              image_400:  processed_offer.seller.avatar ?  processed_offer.seller.avatar : Avatar,
            },
            is_admin:  processed_offer.seller.is_admin,
          }
        };
      });

      const prevoffers = getState().myOffers.sell.offers;
      const curroffers = [...prevoffers, ...newoffers];
      const newData = {
        offers: curroffers,
        hasMore: hasMore,
        page: page,
      };
      dispatch(setMySellOffers(newData));
      dispatch(hideSpinner("myselloffers_next"));
    }

    if (action.type === FETCH_NEXT_MY_SELL_OFFERS_ERROR) {
      dispatch(hideSpinner("myselloffers_next"));
    }
  };

//***************************SEND OFFER************************** */
export const sendOfferFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === SEND_OFFER) {
      dispatch(setSendOfferStatus(OPERATION_STATUS.PENDING));
      dispatch(showSpinner("myoffers-create_offer"));
      dispatch(
        apiRequest(
          "POST",
          "/user/offers/offers.php",
          null,
          action.payload,
          sendOfferSuccess,
          sendOfferError,
          true,
          "[myOffer]"
        )
      );
    }
  };

// on successful fetch, process the data
export const processSendOffer = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
    if (action.type === SEND_OFFER_SUCCESS) {
      let offer = action.payload.data;

      let offer_details = {
        offer_id: offer.offer_id,
        offer_no: offer.offer_no,
        offer_type: offer.offer_type,
        offer_price: offer.offer_price,
        offer_status: offer.offer_status,
        offer_message: offer.offer_message,
        seller_details: offer.seller_details,
        buyer_details: offer.buyer_details,
        created_by: offer.created_by,
        created_at: offer.created_at,
        offer_items: offer.offer_items,
        counter_offers: [],
      };
     
 
      dispatch(setSendOfferData(offer_details));
      dispatch(setSendOfferStatus(OPERATION_STATUS.SUCCESS));
      dispatch(
        setNotification(
          "Offer sent successfully",
          "myoffers-create_offer",
          notification_types.success
        )
      );
      dispatch(hideSpinner("myoffers-create_offer"));
    }
    if (action.type === SEND_OFFER_ERROR) {
      var error_code = action.payload.response.data.error.Code;
      var error_message = action.payload.response.data.error.message;
      dispatch(
        setNotification(
          error_message,
          "myoffers-create_offer",
          notification_types.error
        )
      );
      if(error_code === FLAGGED_TERM.ERROR_CODE){
        dispatch(
          setNotification(
            FLAGGED_TERM.ERROR_MSG,
            "myoffers-create_offer",
            notification_types.error
          )
        );
      }
      // var error_message = action.payload.response.data.error.message;
      // dispatch(
      //   setNotification(
      //     error_message,
      //     "myoffers-create_offer",
      //     notification_types.error
      //   )
      // );

      dispatch(setSendOfferStatus(OPERATION_STATUS.FAILURE));
      dispatch(hideSpinner("myoffers-create_offer"));
    }
  };



  export const getSingleMyOfferFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === SELECT_MY_OFFER) {
      dispatch(showSpinner("myoffers-single_offer"));
      dispatch(
        apiRequest(
          "GET",
          "/user/offers/offers.php",
          action.payload,
          null,
          fetchSingleMyOfferSuccess,
          fetchSingleMyOfferError,
          true,
          "[myOffer]"
        )
      );
    }
  };
  
  // on successful fetch, process the ticket data
  export const processMyOfferSingle = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === FETCH_SINGLE_MY_OFFER_SUCCESS) {
      var offer = action.payload.data;
      
      dispatch(setSingleMyOffer(offer));
      dispatch(hideSpinner("myoffers-single_offer"));
    }
  
    if (action.type === FETCH_SINGLE_MY_OFFER_ERROR) {
      dispatch(hideSpinner("myoffers-single_offer"));
    }
  };

export const myOffersMdl = [
  getMyBuyOffersFlow,
  processMyBuyOffersCollection,
  getMySellOffersFlow,
  processMySellOffersCollection,
  sendOfferFlow,
  processSendOffer,
  getSingleMyOfferFlow,
  processMyOfferSingle
];
