export const UPDATE_PROFILE_PIC                 = '[profile] upload';
export const UPDATE_PROFILE_PIC_SUCCESS         = '[profile] upload success';
export const UPDATE_PROFILE_PIC_ERROR           = '[profile] upload Error';


export const UPDATE_PERSONAL_INFO                 = '[profile] updateInfo';
export const UPDATE_PERSONAL_INFO_SUCCESS         = '[profile] updateInfo success';
export const UPDATE_PERSONAL_INFO_ERROR           = '[profile] updateInfo Error';

export const UPDATE_PASSWORD                    = '[profile] updatePassword';
export const UPDATE_PASSWORD_SUCCESS            = '[profile] updatePassword success';
export const UPDATE_PASSWORD_ERROR              = '[profile] updatePassword Error';
export const SET_PASSWORD_STATUS               = '[profile] setpassword status Error';
export const USER_DETAILS_MODIFY_STATUS         = '[profile] status';


// THIS FOR UPDATE PROFILE PICTURE
export const ProfilePicUpload = (data) => ({
    type: UPDATE_PROFILE_PIC,
    payload: data,
  });

export const profilePicUploadSuccess = (data) => ({
  type: UPDATE_PROFILE_PIC_SUCCESS,
  payload: data,
});

export const profilePicUploadError = (data) => ({
  type: UPDATE_PROFILE_PIC_ERROR,
  payload: data
});


// THIS FOR UPDATE USERNAME AND PHONE NUMBER
export const updatePersonalInfo = (data) => ({
    type: UPDATE_PERSONAL_INFO,
    payload: data,
  });

export const updatePersonalInfoSuccess = (data) => ({
  type: UPDATE_PERSONAL_INFO_SUCCESS,
  payload: data,
});

export const updatePersonalInfoError = (data) => ({
  type: UPDATE_PERSONAL_INFO_ERROR,
  payload: data
});


// THIS FOR UPDATE PASSWORD

export const updatePassword = (data) => ({
    type: UPDATE_PASSWORD,
    payload: data,
  });

export const updatePasswordSuccess = (data) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updatePasswordError = (data) => ({
  type: UPDATE_PASSWORD_ERROR,
  payload: data
});
export const setPasswordStatus = (data) => ({
  type: SET_PASSWORD_STATUS,
  payload: data
});


export const UserDetailsModifyStatus = (data) => ({
  type: USER_DETAILS_MODIFY_STATUS,
  payload: data
});