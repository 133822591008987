import React from "react";
import { connect } from "react-redux";
import ProductItem from "./ProductItem/ProductItem";

function ShipmentDetails({ line_items }) {
  return (
    <React.Fragment>
      <strong>Shipment details</strong>

      {line_items.map((items,idx) => {
        return (
          <ProductItem
            key={idx}
            type={items.type}
            offer_no={items.offer_no}
            offer_id={items.offer_id}
            base_price={items.base_price}
            tax_price={items.tax_price}
            item_details={items.item_details}
            seller_id={items.seller_id}
            seller_name={items.seller_name}
          />
        );
      })}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(ShipmentDetails);
