export const SITE_LOGIN              = '[landingpage] Site login';
export const SITE_LOGIN_SUCCESS      = '[landingpage] Site login success';
export const SITE_LOGIN_ERROR        = '[landingpage] Site login Error';
export const SITE_LOGIN_STATUS       = '[landingpage] Site login set status';
export const SITE_REFRESH_TOKEN                = "[landingpage] Site Refresh";
export const SITE_REFRESH_TOKEN_SUCCESS        = "[landingpage] Site Refresh success";
export const SITE_REFRESH_TOKEN_ERROR          = "[landingpage] Site Refresh error";
export const SET_SITE_REFRESH_STATUS           = '[landingpage] Site Refresh set status';
export const SET_SITE_TOKENS                   = '[landingpage] Site Set token';
export const SET_SITE_STATUS                   = '[landingpage] Site Set status';

// For login landing
export const siteLogin = (data) => ({
    type: SITE_LOGIN,
    payload: data
  });

// For fetching moments
export const siteLoginSuccess = (data) => ({
  type: SITE_LOGIN_SUCCESS,
  payload: data,
});

export const siteLoginError = (data) => ({
  type: SITE_LOGIN_ERROR,
  payload: data
});


export const siteLoginSetStatus = (data) => ({
  type: SITE_LOGIN_STATUS,
  payload: data
});

export const siteRefresh = () => ({
  type: SITE_REFRESH_TOKEN
});

export const siteRefreshSuccess = (data) => ({
  type: SITE_REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const siteRefreshError = (data) => ({
  type: SITE_REFRESH_TOKEN_ERROR,
  payload: data,
});

export const setSiteRefreshStatus = (data) => ({
  type: SET_SITE_REFRESH_STATUS,
  payload: data,
});

export const setSiteTokens = (data) => ({
  type: SET_SITE_TOKENS,
  payload: data,
});

export const setSiteStatus = (data) => ({
  type: SET_SITE_STATUS,
  payload: data,
});