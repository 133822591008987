export const DELETE_ACCOUNT                 = '[account] delete account';
export const DELETE_ACCOUNT_SUCCESS         = '[account] Delete Account success';
export const DELETE_ACCOUNT_ERROR           = '[account] Delete Account Error';
export const SET_DELETE_ACCOUNT             = '[account] Set delete status';

export const deleteAccount = (data) => ({
    type: DELETE_ACCOUNT,
    payload: data,
  });

export const deleteAccountSuccess = (data) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: data,
});

export const deleteAccountError = (data) => ({
  type: DELETE_ACCOUNT_ERROR,
  payload: data
});

export const setdeleteAccount = (data) => ({
    type   :SET_DELETE_ACCOUNT,
    payload: data
  });