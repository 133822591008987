import React from "react";
import { Card, Button, Badge } from "react-bootstrap";
// import EditIcon from "../../../images/edit.svg";

import { getMyOfferRenderStatus } from "../../../js/utils";
import { OFFER_STATES } from "../../../js/constants";

const MyOfferCard = ({ offer_no, status, price, name, avatar }) => {
  const getBadgeClassName = (status) => {
    let className = "";
    switch (status) {
      case OFFER_STATES.ACCEPTED_BUYER:
      case OFFER_STATES.ACCEPTED_SELLER:
      case OFFER_STATES.CART:
      case OFFER_STATES.IN_CHECK_OUT:
      case OFFER_STATES.IN_ORDER:
        className = "badge-success";
        break;
      case OFFER_STATES.CANCELLED:
      case OFFER_STATES.DROPPED:
      case OFFER_STATES.REJECTED:
        className = "badge-danger";
        break;
      case OFFER_STATES.OPEN:
        className = "badge-warning";
        break;
      default:
        break;
    }
    return className;
  };
  return (
    <React.Fragment>
      <Card className="searchResultCard my-4" style={{ cursor: "pointer" }}>
      <Card.Header>
          <div className="d-flex flex-grow-1 align-items-center">
            {avatar && (
              <div>
                <img
                  src={avatar}
                  className="rounded"
                  alt={name}
                  width={40}
                  height={40}
                  style={{objectFit:"contain"}}
                />
              </div>
            )}
            <div className="text-ellipsis-wrap font-weight-bold ml-2" style={{color:"#000"}}>
              {name}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-wrap align-items-center">
            <div className="flex-grow-1 font-weight-bold offer_no">
              {offer_no}
            </div>
            <div className="ml-auto">
              <Badge className={getBadgeClassName(status)}>
                {getMyOfferRenderStatus(status)}
              </Badge>
              <div style={{ color: "#000" }}>
                <i className="fa fa-usd" aria-hidden="true"></i>
                {price}
              </div>
            </div>
          </div>
        </Card.Body>
        
      </Card>
    </React.Fragment>
  );
};
export default MyOfferCard;
