import React from "react";
import { Link } from "react-router-dom";
import {Navbar,Nav,Badge,Col,Row,Container} from "react-bootstrap";
import { connect } from "react-redux";
import "../FooterComponent/FooterComponentNew.css";
import { NavLink } from "react-router-dom";
import SiteLogo from "../../images/site-logo.png";
import Facebook from "../../images/facebook.svg";
import Twitter from "../../images/twitter.svg";
import GooglePlus from "../../images/googleplus.svg";
import Envelope from "../../images/envelope.svg";
import Copyright from "../../images/copyright.svg";
import { showModal } from "../../store/actions/ui";

function FooterComponent({ showModal }) {
  return (
    <React.Fragment>
      <section className="footer-section">
        <Container>
          <Row>
            <Col xs={12} sm={8}>
              <Navbar.Brand to="#">
                <img src={SiteLogo} alt="site-logo" />
              </Navbar.Brand>
              <div className="mr-5 mt-3 footer-content">
                A marketplace for tickets and <br /> memories from moments in <br /> history
              </div>
              <div className="d-flex social-media justify-content-start align-item-center mt-5 my-3">
              <Nav.Link href=""><Badge className="badge-color "><img src={Facebook} alt="facebook" /></Badge></Nav.Link>
              <Nav.Link href=""><Badge className="badge-color"><img src={Twitter} alt="twitter" /></Badge></Nav.Link>
              <Nav.Link href=""><Badge className="badge-color"><img src={GooglePlus} alt="googleplus" /></Badge></Nav.Link>
              <Nav.Link href=""><Badge className="badge-color"><img src={Envelope} alt="envelope" /></Badge></Nav.Link>
              </div>
            </Col>
            <Col xs={12} sm={2}>
              <div className="product-links my-2">
                <h5>Product</h5>
                <Nav.Link 
                as={NavLink}
                exact
                to="/" className="pl-0"
                activeClassName="headerNavBarActive">
                  Home
                </Nav.Link>
                <Nav.Link 
                as={NavLink}
                exact
                to="/teamArtist" className="pl-0"
                activeClassName="headerNavBarActive">
                  Team/Artist
                </Nav.Link>
                <Nav.Link 
                as={NavLink}
                exact
                to="/genre" className="pl-0"
                activeClassName="headerNavBarActive">
                  Genre
                </Nav.Link>
                <Nav.Link 
                as={NavLink}
                exact
                to="/venue" className="pl-0"
                activeClassName="headerNavBarActive">
                  Venue
                </Nav.Link>
                <Nav.Link 
                as={NavLink}
                exact
                to="/momentHistory" className="pl-0"
                activeClassName="headerNavBarActive">
                  Moments In History
                </Nav.Link>
                <Nav.Link 
                as={NavLink}
                exact
                to="/about" className="pl-0"
                activeClassName="headerNavBarActive">
                  About
                </Nav.Link>
              </div>
            </Col>
            <Col xs={12} sm={2}>
              <div className="legal-links my-2">
                <h5>Legal</h5>
                <Nav.Link
                as={NavLink}
                exact
                  to="/termscondition"
                  className="pl-0"
                  activeClassName="headerNavBarActive"
                >
                  Terms & Conditions
                </Nav.Link>
                <Nav.Link
                as={NavLink}
                exact
                 to="/policy" className="pl-0"
                 activeClassName="headerNavBarActive">
                  Privacy Policy
                </Nav.Link>
              </div>
            </Col>
          </Row>
        </Container>
        <hr />
        <Container>
          <Row>
            <Col xs={12} sm={6}>
              <span className="copyright-text">
                Copyright &copy; 2022 Memorylane. All Rights Reserved.
              </span>
            </Col>
            {/* <Col xs={12} sm={6} className="link-sub-footer">
                        <span>
                            <Link to="#" className="pt-0">Terms of Use</Link>
                        </span>
                        <span>
                            <Link to="#" className="pt-0">Privacy Policy</Link>
                        </span>
                    </Col> */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default connect(null, { showModal })(FooterComponent);
