import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./VenueComponent.css";

const Genre = ({ venues, isLoading }) => {
  return (
    <ReactBootstrap.Container fluid className="my-4 m-0">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <ReactBootstrap.Spinner animation="border" role="status" />
        </div>
      ) : venues.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <p>No Venue found</p>
        </div>
      ) : (
        <ReactBootstrap.Row className="m-0">
          {venues.map((venue) => {
            return (
              <ReactBootstrap.Col
                key={venue.venue_id}
                xs={12}
                md={3}
                lg={3}
                xl={3}
              >
                <div className="genreListSection">
                  <ul>
                    <ReactBootstrap.Nav.Link
                      key={venue.venue_id}
                      as={Link}
                      to={"/momentHistory?venue=" + venue.venue_id}
                    >
                      <li> {venue.display_name}</li>
                    </ReactBootstrap.Nav.Link>
                  </ul>
                </div>
              </ReactBootstrap.Col>
            );
          })}
        </ReactBootstrap.Row>
      )}
    </ReactBootstrap.Container>
  );
};

Genre.propTypes = {
  venue: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Genre;
