import { SET_SEARCH_BAR_VALUE, REMOVE_SEARCH_BAR_VALUE } from "../actions/searchbar";
import produce from 'immer'

const INITIAL_STATE = {
  search: ""
};

export const searchBarReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SEARCH_BAR_VALUE:
      draft.search = action.payload;
      break;
    case REMOVE_SEARCH_BAR_VALUE:
      draft.search = "";
      break;
    default:
      break;
  }
},INITIAL_STATE);
