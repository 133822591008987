import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";
import "./TicketsCardSelectedComponent.css";

const TicketsCardSelectedComponent = ({
  product_id,
  listing_name,
  cost,
  image,
  remove,
}) => {
  return (
    <React.Fragment>
      <ReactBootstrap.Card className="searchResultCard m-2">
        <ReactBootstrap.Card.Body>
          <div className="d-flex flex-nowrap align-items-center">
            <div className="mx-1 divTicketImage">
              <img
                src={image.image_200}
                alt="ticket_image"
                height={100}
                width={100}
              />
            </div>
            <div className="searchResultEventName textBlackColor flex-grow-1 mx-1">
              <h6>{listing_name}</h6>
              <div className="font-weight-bold my-2">
                <i className="fa fa-usd" aria-hidden="true"></i> {cost}
              </div>
            </div>
            <div className="mx-1">
              <ReactBootstrap.Button
                className="btn btn-danger"
                onClick={() => {
                  remove(product_id);
                }}
                title="Remove from offer"
              >
                <i className="fa fa-minus" aria-hidden="true"></i>
              </ReactBootstrap.Button>
            </div>
          </div>
        </ReactBootstrap.Card.Body>
      </ReactBootstrap.Card>
    </React.Fragment>
  );
};

TicketsCardSelectedComponent.propTypes = {
  product_id: PropTypes.string.isRequired,
  listing_name: PropTypes.string.isRequired,
  cost: PropTypes.string,
  image: PropTypes.object,
  remove: PropTypes.func.isRequired,
};

export default TicketsCardSelectedComponent;
