import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { Modal, Form, Button,Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { hideModal } from "../../../../store/actions/ui";
import Select from "react-select";
import { resetRating} from "../../../../store/actions/rating";

function BuyerRateAndReview({
  BuyerRatingsOnShow,
  onRatingSubmit,
  isbuyerRatingSendingStatus,
  operation,
  communicationRating,
  descriptionRating,
  shippingRating,
  review,
  onRatingClear
}) {
  useEffect(()=>{
    if(operation){
      setRatingState((draft) => {
        draft.communication_rating.value=communicationRating;
        draft.communication_rating.label=communicationRating;
        draft.description_rating.value=descriptionRating;
        draft.description_rating.label=descriptionRating;
        draft.shipping_rating.value=shippingRating;
        draft.shipping_rating.label=shippingRating;
        draft.review = review; 
      });
    }
  },[operation])
  // const profile = useSelector((state) => state.profile);
  // const { UserInfo } = profile;
  var INITIAL_STATE = {
            statusOptions : [
                              { value: "1", label: "1" },
                              { value: "2",  label: "2" },
                              { value: "3",  label: "3" },
                              { value: "4",  label: "4" },
                              { value: "5",  label: "5" },
                                  ],
              communication_rating:  { value: "", label: "" },
              description_rating: { value: "", label: "" },
              shipping_rating: { value: "", label: "" },
              review:"",
              error: {
                    communication_rating: "",
                    description_rating:"",
                    shipping_rating:"",
                    review: "",
              },
          };
  // Set State value using immer
  const [state, setRatingState] = useImmer(INITIAL_STATE);
  const handleInputChange = (name,e) => {
    
    if(name==="review"){
      setRatingState((draft) => {
        draft.review = e.target.value;
      });
    }else{
      setRatingState((draft) => {
        draft[name] = e;
      });
    }
  };
  const Submit = () => {
    if (state.communication_rating.value.length === 0) {
        setRatingState((draft) => {
          draft.error.communication_rating = "Please enter the Communication ratings.";
        });
        return false;
      } else
      {
        setRatingState((draft) => {
          draft.error.communication_rating = "";
        });
      }
      if (state.description_rating.value.length === 0) {
        setRatingState((draft) => {
          draft.error.description_rating = "Please enter the Description ratings.";
        });
        return false;
      } else
      {
        setRatingState((draft) => {
          draft.error.description_rating = "";
        });
      }
      if (state.shipping_rating.value.length === 0) {
        setRatingState((draft) => {
          draft.error.shipping_rating = "Please enter the Shipping ratings.";
        });
        return false;
      } else
      {
        setRatingState((draft) => {
          draft.error.shipping_rating = "";
        });
      }
      if (state.review.length === 0) {
        setRatingState((draft) => {
          draft.error.review = "Please enter the review.";
        });
        return false;
      } else
      {
        setRatingState((draft) => {
          draft.error.review = "";
        });
      }
    const content = {
                      communication_rating : state.communication_rating.value,
                      description_rating :state.description_rating.value,
                      shipping_rating :state.shipping_rating.value,
                      review : state.review
                    }
    onRatingSubmit(content);
  };
 
  return (
    <>
      <React.Fragment>
        <Modal
          show={BuyerRatingsOnShow}
          onHide={onRatingClear}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h6 className="font-weight-bold">Rate And Reviews</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formBasicName">
                <Form.Label className="font-weight-bold requiredField">
                  Communication Rating
                </Form.Label>
                <Select
                 className={state.error.communication_rating.length > 0 ? "is-invalid" : ""}
                   value={state.communication_rating}
                   isClearable={false}
                   placeholder={"Communication Rating"}
                   onChange={(e) => {
                    handleInputChange("communication_rating", e);
                   }}
                   name="communication_rating"
                   options={state.statusOptions}
                />
                {state.error.communication_rating.length > 0 && (
                  <span className="invalid-feedback">{state.error.communication_rating}</span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicName">
                <Form.Label className="font-weight-bold requiredField">
                  Description Rating
                </Form.Label>
                <Select
                  className={state.error.description_rating.length > 0 ? "is-invalid" : ""}
                  value={state.description_rating}
                  placeholder={"Description Rating"}
                  isClearable={false}
                  name="description_rating"
                  options={state.statusOptions}
                  onChange={(e)=>
                    handleInputChange("description_rating",e)
                  }
                />
                {state.error.description_rating.length > 0 && (
                  <span className="invalid-feedback">{state.error.description_rating}</span>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicName">
                <Form.Label className="font-weight-bold requiredField ">
                  Shipping Rating
                </Form.Label>
                <Select
                  className={state.error.shipping_rating.length > 0 ? "is-invalid" : ""}
                  value={state.shipping_rating}
                  placeholder={"Shipping Rating"}
                  isClearable={false}
                  name="shipping_rating"
                  options={state.statusOptions}
                  onChange={(e)=>
                    handleInputChange("shipping_rating",e)
                  }
                />
                {state.error.shipping_rating.length > 0 && (
                  <span className="invalid-feedback">{state.error.shipping_rating}</span>
                )}
              </Form.Group>
              <Form.Group
                    className="mb-3"
                  >
                    <Form.Label className="font-weight-bold requiredField ">
                      Review
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={state.review}
                      placeholder="Give review..."
                      name="review"
                      className={
                        state.error.review.length > 0 ? "is-invalid" : ""
                      }
                      onChange={(e)=>
                        handleInputChange("review",e)}
                      autoComplete="off"
                    />
                    {state.error.review.length > 0 && (
                      <span className="invalid-feedback">
                        {state.error.review}
                      </span>
                    )}
                  </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button 
              className="btnModelSubmit"
               onClick={Submit}
               >
                  {isbuyerRatingSendingStatus && (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      size="sm"
                      className="mr-1"
                    />
                  )}
                  {operation==="Add"?("Save"):("Update")}
                
              </Button>
              {!isbuyerRatingSendingStatus && (
                 <Button
                 className="btnModelClose"
                 onClick={onRatingClear}
               >
                 Close
               </Button>
              )}
             
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    BuyerRatingsOnShow: state.ui.activeModal === "BuyerRatings",
  };
};

export default connect(mapStateToProps, { hideModal,resetRating })(BuyerRateAndReview);
