import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Card, Row, Col, Button, Badge, Dropdown } from "react-bootstrap";
import "./BuyOrderCardView.css";
import { getDeliveredStatus } from "../../../../js/utils";
import { ORDER_DELEVERED_STATES, ORDER_STATES } from "../../../../js/constants";

const BuyOrderCardView = ({
  getBuyerOrderDetailView,
  viewRateByList,
  order_number,
  suborder_no,
  order_status,
  expiry_date,
  tracking_number,
  image,
  first_ticket_name,
  delivery_date,
  delivery_status,
  seller_name,
  seller_id,
  order_date,
  order_total,
  remaining_items_count,
  items,
  payment_paid_status,
  approveOrderReceived,
}) => {
  return (
    <React.Fragment>
      <Card className="searchResultCard my-4">
        <Card.Body>
          <Row>
            <Col xs={12} md={2} className="text-center">
              <div>
                <img src={image} width="50" height="80" />
                {remaining_items_count !== 0 && (
                  <span className="counter Order-count ">
                    {remaining_items_count > 10
                      ? "10+"
                      : "+" + remaining_items_count}
                  </span>
                )}
              </div>
              <p className="my-2" title="Click to contact seller">
                <i className="fa fa-handshake-o" aria-hidden="true"></i>
                <Link key={seller_id} to={"/tickets?seller=" + seller_id}>
                  <span className="icon_padding">{seller_name}</span>
                </Link>
              </p>
            </Col>
            <Col xs={12} md={8}>
              <div>
                <div className="flex-grow">
                  <h5>
                    {first_ticket_name}
                    {remaining_items_count !== 0 ? " & more..." : ""}
                  </h5>
                </div>
                <div className="font-weight-bold my-2">
                  Total cost: <i className="fa fa-usd" aria-hidden="true"></i>
                  <span>{order_total}</span>
                </div>
                <div className="">
                  <i className="fa fa-calendar" aria-hidden="true"></i> Ordered
                  on: <span>{order_date}</span>
                </div>
                {order_status === ORDER_STATES.OPEN &&
                  <div className="">
                  <i className="fa fa-calendar" aria-hidden="true"></i> Expires
                  on: <span>{expiry_date}</span>
                </div>
                }
              </div>
              {payment_paid_status && (
                <>
                  <Dropdown.Divider />
                  <Row>
                    <Col xs={12} md={6}>
                      {delivery_date &&
                        delivery_status === ORDER_DELEVERED_STATES.SHIPPED && (
                          <div title="Delivery date">
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>
                            <span className="icon_padding">
                              Delivery Expected by {delivery_date}
                            </span>
                          </div>
                        )}

                      <i className="fa fa-info" aria-hidden="true"></i>
                      <span className="icon_padding">
                        {getDeliveredStatus(delivery_status)}
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      {tracking_number && (
                        <div>
                          <span>Tracking ID: {tracking_number}</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col xs={12} md={2} className="text-center">
              <div>
                {payment_paid_status === true ? (
                  <Badge className="badge-info">Paid </Badge>
                ) : (
                  <>
                    {order_status === ORDER_STATES.CANCELLED && (
                      <Badge className="badge-danger">Order Cancelled </Badge>
                    )}
                    {order_status === ORDER_STATES.OPEN && (
                      <>
                        <Badge className="badge-danger">Not Paid </Badge>
                      <div>
                        <Button
                          className="btn btn-primary"
                          size="sm"
                          as={NavLink}
                          to={"/previeworder/" + order_number}
                        >
                          Pay Now
                        </Button>
                      </div>
                      </>
                    )}
                  </>
                )}
              </div>

              {delivery_status === ORDER_DELEVERED_STATES.DELIVERED_SELLER && (
                <div className="m-2">
                  <Button
                    type="button"
                    className="btn btn-secondary "
                    size="sm"
                    onClick={() => approveOrderReceived(order_number, suborder_no)
                    } 
                    title="Give Acknowledgement"
                  >
                    Order Received
                  </Button>
                </div>
              )}
              <div className="m-2">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    getBuyerOrderDetailView(items);
                  }}
                  to="/order"
                >
                  More details <i className="fa fa-arrow-right mr-2"></i>
                </Link>
              </div>
              {delivery_status === ORDER_DELEVERED_STATES.DELIVERED_ADMIN ||
            delivery_status === ORDER_DELEVERED_STATES.DELIVERED_BUYER && (
              
              <div className="m-2">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    viewRateByList(items);
                  }}
                  to="/order"
                >
                  Give Ratings
                </Link>
              </div>
            )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default BuyOrderCardView;
