import { UPDATE_GENRES } from "../actions/genre";

const INITIAL_STATE = []

export const genreReducer = (genres = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_GENRES:
      return action.payload;

    default:
      return genres;
  }
};
