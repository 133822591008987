export const LOAD_APP = "[app] Load";
export const GET_USER_DATA = "[app] Fetch user data";
export const FETCH_USER_DATA_SUCCESS = "[app] Fetch user data sucess";
export const FETCH_USER_DATA_ERROR = "[app] Fetch Error user data error";
export const UPDATE_USER_DATA = "[app] Update user data";
export const UPDATE_APP = "[app] Update";

export const GET_PAYMENT_MASTER_DATA = "[app] Fetch payment master data";
export const FETCH_PAYMENT_MASTER_DATA_SUCCESS = "[app] Fetch payment master  data sucess";
export const FETCH_PAYMENT_MASTERR_DATA_ERROR = "[app] Fetch payment master  data error";
export const UPDATE_PAYMENT_MASTER_DATA = "[app] Update payment master data";

export const GET_CONSTANTS = "[app] GET page contents";
export const FETCH_CONSTANTS_SUCCESS = "[app] Fetch page contents success";
export const FETCH_CONSTANTS_ERROR = "[app] Fetch page contents Error";
export const SET_CONSTANTS = "[app] Set page contents";
// For fetching moments
export const loadAppState = () => ({
  type: LOAD_APP,
});

export const getUserData = () => ({
  type: GET_USER_DATA,
});

// For fetching moments
export const fetchUserDataSuccess = (data) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: data,
});

export const fetchUserDataError = (data) => ({
  type: FETCH_USER_DATA_ERROR,
  payload: data,
});

export const setUserData = (data) => ({
  type: UPDATE_USER_DATA,
  payload: data,
});

export const setAppState = (data) => ({
  type: UPDATE_APP,
  payload: data,
});


export const getPaymentMasterData = () => ({
  type: GET_PAYMENT_MASTER_DATA,
});

// For fetching moments
export const fetchPaymentMasterDataSuccess = (data) => ({
  type: FETCH_PAYMENT_MASTER_DATA_SUCCESS,
  payload: data,
});

export const fetchPaymentMasterDataError = (data) => ({
  type: FETCH_PAYMENT_MASTERR_DATA_ERROR,
  payload: data,
});

export const setPaymentMasterData = (data) => ({
  type: UPDATE_PAYMENT_MASTER_DATA,
  payload: data,
});

// For fetching moments
export const getContents = () => ({
  type: GET_CONSTANTS
});
// For fetching moments
export const getContentsSuccess = (data) => ({
  type: FETCH_CONSTANTS_SUCCESS,
  payload: data
});

export const getContentsError = (data) => ({
  type: FETCH_CONSTANTS_ERROR,
  payload: data
});

export const setContents = (data) => ({
  type: SET_CONSTANTS,
  payload: data,
});
