import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch,useLocation } from "react-router-dom";
import "./App.css";
import NotifyComponent from "./common/NotifyComponent/NotifyComponent";
import MainLayout from "./screens/Layouts/MainLayout";
import PageNotFoundComponent from "./common/PageNotFoundComponent/PageNotFoundComponent";
import VerifyComponent from "./screens/EmailVerify/VerifyComponent";
import ResetPasswordComponent from "./screens/ResetPassword/ResetPasswordComponent";
import PrivateRoute from "./screens/RouteGuard/PrivateRoute"
import LandingPage from "./screens/LandingPage/LandingPage"
import { PAGE_URLS } from "./js/constants"
import Loader from "./common/LoaderComponent/LoaderComponent";
import { connect } from "react-redux";
import { loadAppState } from "./store/actions/app";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = ({ loadAppState, apploading }) => {
  useEffect(() => {
    loadAppState();
  }, []);
  return (
    <>
      {apploading ? (
        <Loader />
      ) : (
        <>
          <NotifyComponent />
          <Router>
            <ScrollToTop />
            <Switch>
            <Route path="/" exact component={LandingPage} />
              <PrivateRoute
                path={[
                  PAGE_URLS.HOME,
                  "/genre",
                  "/venue",
                  "/teamArtist",
                  "/momentHistory",
                  "/collections",
                  "/collections/:id",
                  "/tickets",
                  "/tickets/:id",
                  "/mytickets/:id",
                  "/account",
                  "/account/seller",
                  "/account/mytickets",
                  "/account/mycollection",
                  "/account/mycollection/:id",
                  "/account/myorders",
                  "/account/myoffers",
                  "/account/myoffers/:id",
                  "/account/mymessages",
                  "/account/mymessages/:id",
                  "/mycart",
                  "/checkout",
                  "/previeworder/:id",
                  "/account/myorders",
                  "/account/myreviews",
                  "/account/delete",
                  "/account/report",
                  "/account/report/:id",
                  "/termscondition",
                  "/policy",
                  "/about"
                ]}
                exact
                component={MainLayout}
              />
              <Route exact path="/verify" component={VerifyComponent} />
              <Route exact path="/reset" component={ResetPasswordComponent} />
              <Route component={PageNotFoundComponent} />
            </Switch>
          </Router>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    apploading:
      "global" in state.ui.loaders ? state.ui.loaders["global"] : true,
  };
};

export default connect(mapStateToProps, {
  loadAppState,
})(App);
