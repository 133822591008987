import React, { useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { logout } from "../../../store/actions/auth";
import Sidebar from "../../SidebarComponent/SidebarComponent";
import { connect } from "react-redux";
import "./OffCanvas.css";
import { showModal, hideModal } from "../../../store/actions/ui";
import { useLocation } from "react-router-dom";

const OffCanvas = ({ offCanShow, logout, name, hideModal, activeModal }) => {
  const location = useLocation();
  useEffect(() => {
    if (activeModal && activeModal === "offCan") {
      hideModal("offCan");
    }
  }, [location]);

  return (
    <React.Fragment>
      <ReactBootstrap.Modal
        show={offCanShow}
        onHide={() => hideModal("offCan")}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        dialogClassName="sideBar-modal-dialog position-fixed m-auto"
        contentClassName="sideBar-modal-content h-100"
        className="left menu"
      >
        <ReactBootstrap.Modal.Header closeButton>
          <div className="d-flex flex-column">
            <ReactBootstrap.Modal.Title id="contained-modal-title-vcenter">
              <h6 className="font-weight-bold">{name}</h6>
            </ReactBootstrap.Modal.Title>
            <div className="Offcan-logout btn btn-danger" onClick={logout}>
              <i className="fa fa-sign-out mr-1"></i>LogOut
            </div>
          </div>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className="px-0">
          <Sidebar />
        </ReactBootstrap.Modal.Body>
      </ReactBootstrap.Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    offCanShow: state.ui.activeModal === "offCan",
    activeModal: state.ui.activeModal,
  };
};
export default connect(mapStateToProps, { logout, showModal, hideModal })(
  OffCanvas
);
