import React from "react";
import "./ProductCard.css"

const ProductCard = ({ listing_name, image, cost, expiry_date }) => {
  return (
    <div className="m-1 p-3 border rounded d-flex flex-nowrap align-items-center">
      <div className="mx-1 divTicketImage">
        <img src={image.image_200} alt="ticket_image" height={50} width={50} />
      </div>
      <div className="searchResultEventName textBlackColor flex-grow-1 mx-1">
        <h6>{listing_name}</h6>
        <div className="font-weight-bold my-2">
          <i className="fa fa-usd" aria-hidden="true"></i> {cost}
        </div>
      </div>
      <div>{expiry_date}</div>
    </div>
  );
};

export default ProductCard;
