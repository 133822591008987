import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ReactBootstrap from "react-bootstrap";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as authOperations from "../../store/actions/authAction";
import Loader from "../../common/LoaderComponent/LoaderComponent";
import Logo from "../../images/memorylanelogo.png";
import "./VerifyComponent.css";
import {PAGE_URLS} from "../../js/constants"

function Verify() {
  // Redux Dispatcher
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { loading, isMailCheck } = auth;

  const params = new URLSearchParams(useLocation().search);
  //  GET QUERY STRING ON PAGE LOAD
  useEffect(() => {
    var useremail = params.get("email");
    var key= params.get("verification_key");

    const MailCheck = {
      email: useremail,
      email_verification_key: key
    };
    dispatch(authOperations.mailValidate(MailCheck))
  },[]);
  return (
    <>
      <div className="cardVerifySent">
        <ReactBootstrap.Card className="emailVerify">
          <ReactBootstrap.Card.Header>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} md={4} className="pl-0">
                <div className="emailVerifyLogo">
                  <img src={Logo} alt="Logo" />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} md={8}>
                <div className="mt-4">
                  <h5>Email Verification</h5>
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Card.Header>
          <ReactBootstrap.Card.Body>
            {isMailCheck ? (
              <p>Your email has been verified successfully</p>
            ) : (
              <p>Email verification failed</p>
            )}
          </ReactBootstrap.Card.Body>
          <ReactBootstrap.Card.Footer>
            <ReactBootstrap.Nav.Link as={Link} to={PAGE_URLS.HOME}>
              Back to home
            </ReactBootstrap.Nav.Link>
          </ReactBootstrap.Card.Footer>
        </ReactBootstrap.Card>
      </div>
      {/* *************   Loading  ********************/}
      {loading ? <Loader /> : ""}
      {/* **************************************/}
    </>
  );
}

export default Verify;
