import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

function PaymentDetails({ Payment_type }) {
  return (
    <React.Fragment>
      <strong>Payment information</strong>
      <Row>
        <Col xs={6} sm={6} md={8}>
          <div>Payment Method</div>
        </Col>
        <Col xs={6} sm={6} md={4} className=" text-center text-md-right">
          <div>{Payment_type}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(PaymentDetails);
