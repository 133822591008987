import  * as authTypes  from '../constants/authConstants'
import {UPDATE_USER_DATA} from "../actions/app"
import produce from 'immer'

const INITIAL_STATE = {
    isLogged: false,
    isChecking: false,
    isRegistred: false,
    isMailCheck: false,
    isForgetpass: false,
    isResetpass: false,
    islogOut:false,
    userInfo: null
    // errorMessage:''
}

const user_auth_reducer = produce((draft, action) => {
    // FOR LOGIN 
    switch (action.type) {
        case authTypes.USER_LOGIN_REQUEST:
            draft.loading = true
            draft.isLogged = false
            break
        case authTypes.USER_LOGIN_SUCCESS:
        case UPDATE_USER_DATA:
            draft.loading = false
            draft.isLogged = true
            draft.userInfo = action.payload.data
            window.localStorage.setItem("refresh_token",action.payload.data.refresh_token)
            break
        case authTypes.USER_LOGIN_ERROR:
            draft.loading = false
            draft.isLogged = false
            draft.error = action.payload.message
            // draft.errorMessage =action.payload.response.data.error.message
            break
        default:
            break
    }

    // FOR REGISTER
    switch (action.type) {
        case authTypes.USER_REGISTER_REQUEST:
            draft.loading = true
            draft.isRegistred = false
            break
        case authTypes.USER_REGISTER_SUCCESS:
            draft.loading = false
            draft.isRegistred = true
            draft.userInfo = action.payload.data
            break
        case authTypes.USER_REGISTER_ERROR:
            draft.loading = false
            draft.isRegistred = false
            draft.error = action.payload
            break
            case authTypes.USER_REGISTER_RESET_STATUS:
            draft.isRegistred = false
            break
        default:
            break
    }

    // FOR MAIL VALIDATE
    switch (action.type) {
        case authTypes.USER_MAILVALIDATE_REQUEST:
            draft.loading = true
            draft.isLogged = false
            break
        case authTypes.USER_MAILVALIDATE_SUCCESS:
            draft.loading = false
            draft.isMailCheck = true
            draft.userInfo = action.payload.data
            break
        case authTypes.USER_MAILVALIDATE_ERROR:
            draft.loading = false
            draft.isLogged = false
            draft.error = action.payload
            break
        default:
            break
    }

    // FOR FORGOT PASSWORD
    switch (action.type) {
        case authTypes.USER_FORGOTPASS_REQUEST:
            draft.loading = true
            draft.isLogged = false
            break
        case authTypes.USER_FORGOTPASS_SUCCESS:
            draft.loading = false
            draft.isForgetpass = true
            draft.userInfo = action.payload.data
            break
        case authTypes.USER_FORGOTPASS_ERROR:
            draft.loading = false
            draft.isLogged = false
            draft.error = action.payload
            break
        default:
            break
    }

    // FOR RESET PASSWORD
    switch (action.type) {
        case authTypes.USER_RESETPASS_REQUEST:
            draft.loading = true
            draft.isLogged = false
            break
        case authTypes.USER_RESETPASS_SUCCESS:
            draft.loading = false
            draft.isResetpass = true
            draft.userInfo = action.payload.data
            break
        case authTypes.USER_RESETPASS_ERROR:
            draft.loading = false
            draft.isLogged = false
            draft.error = action.payload
            break
        default:
            break
    }

    // FOR LOGOUT 
    switch (action.type) {
        case authTypes.USER_LOGOUT_REQUEST:
            draft.loading = true
            draft.isLogged = false
            draft.islogOut = false
            break
        case authTypes.USER_LOGOUT_SUCCESS:
            draft.loading = false
            draft.isLogged = false
            draft.islogOut = true
            draft.userInfo = null
            break
        case authTypes.USER_LOGOUT_ERROR:
            draft.loading = false
            draft.isLogged = false
            draft.error = action.payload
            break
        default:
            break
    }
}, INITIAL_STATE)

export default user_auth_reducer;
