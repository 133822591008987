import React, { useEffect } from "react";
import { Row, Col, Dropdown, Spinner, Button } from "react-bootstrap";
import { connect } from "react-redux";
import MyCartView from "./MyCartView";
import {
  getCartDetails,
  removeItemFromCart,
  removeItemFromCartSetStatus,
  setCartDetailsFetchStatus,
} from "../../store/actions/myCart";
import {
  initiateCheckout,
  setCheckoutStatus,
} from "../../store/actions/checkout";
import { OPERATION_STATUS } from "../../js/constants";
import { NavLink } from "react-router-dom";

const MyCartPage = ({
  cart_id,
  cart_items,
  getCartDetails,
  history,
  isItemRemoveLoading,
  itemRemoveStatus,
  removeItemFromCart,
  removeItemFromCartSetStatus,
  pricing_summary,
  initiateCheckout,
  checkout_status,
  setCheckoutStatus,
  initiateCheckoutLoading,
  setCartDetailsFetchStatus,
  cartFetchStatus,
  isCartFetchLoading,
}) => {
  const removeItem = (id) => {
    removeItemFromCart({
      product_id: id,
    });
  };

  const checkout = () => {
    var data = {
      cart_id: cart_id,
    };
    initiateCheckout(data);
  };

  useEffect(() => {
    if (checkout_status === OPERATION_STATUS.SUCCESS) {
      setCheckoutStatus(OPERATION_STATUS.YET_TO_START);
      history.push({
        pathname: "/checkout",
      });
    } else if (checkout_status === OPERATION_STATUS.FAILURE) {
      setCheckoutStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [checkout_status]);

  useEffect(() => {
    if (itemRemoveStatus === OPERATION_STATUS.SUCCESS) {
      removeItemFromCartSetStatus(OPERATION_STATUS.YET_TO_START);
    } else if (itemRemoveStatus === OPERATION_STATUS.FAILURE) {
      removeItemFromCartSetStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [itemRemoveStatus]);

  useEffect(() => {
    if (cartFetchStatus === OPERATION_STATUS.SUCCESS) {
      setCartDetailsFetchStatus(OPERATION_STATUS.YET_TO_START);
    } else if (cartFetchStatus === OPERATION_STATUS.FAILURE) {
      setCartDetailsFetchStatus(OPERATION_STATUS.YET_TO_START);
    }
  }, [cartFetchStatus]);

  useEffect(() => {
    getCartDetails();
  }, []);
  return (
    <React.Fragment>
      <div className="m-0 my-4 mr-3 ml-3">
        <Row>
          <Col>
            <h5>My Cart</h5>
          </Col>
        </Row>
        <Dropdown.Divider />
        {initiateCheckoutLoading || isCartFetchLoading ? (
          <div className="d-flex justify-content-center align-items-center my-5">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            {cart_items.length > 0 ? (
              <MyCartView
                checkout={checkout}
                cart_items={cart_items}
                pricing_details={pricing_summary}
                remove={removeItem}
                cart_id={cart_id}
                isLoading={isItemRemoveLoading}
              />
            ) : (
              <div>
                <div className="d-flex justify-content-center">
                  Your cart is empty!
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className="btnBuySearchResult ml-2 my-1"
                    as={NavLink} exact to="/tickets"
                  >
                    <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                    Shop now
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    cart_id: state.cart.cart_id,
    cart_items: state.cart.items,
    pricing_summary: {
      sub_total: state.cart.price_sub_total,
      total_tax: state.cart.total_tax,
      grand_total: state.cart.grand_total,
    },
    isItemRemoveLoading: state.cart.remove_status === OPERATION_STATUS.PENDING,
    itemRemoveStatus: state.cart.remove_status,
    checkout_status: state.checkout.initiate_checkout_status,
    initiateCheckoutLoading:
      state.checkout.initiate_checkout_status === OPERATION_STATUS.PENDING,
    isCartFetchLoading: state.cart.fetch_status === OPERATION_STATUS.PENDING,
    cartFetchStatus: state.cart.fetch_status,
  };
};

export default connect(mapStateToProps, {
  getCartDetails,
  removeItemFromCart,
  removeItemFromCartSetStatus,
  initiateCheckout,
  setCheckoutStatus,
  setCartDetailsFetchStatus,
})(MyCartPage);
