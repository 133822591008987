import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
import ShippingAddressPage from "./ShippingAddressComponent/ShippingAddressPage";
import BillingAddressPage from "./BillingAddressComponent/BillingAddressPage";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import CheckoutOrderSummary from "./CheckoutOrderSummaryComponent/OrderSummaryComponent";
import PriceSummary from "./PricingSummaryComponent/PriceSummary";
import "./CheckoutView.css";

const CheckoutView = ({
  accepted_payments,
  line_items,
  pricing_summary,
  placeorder,
}) => {
  return (
    <React.Fragment>
      <Container>
        <div className="collapsibleMobile">
          <Collapsible trigger="Address" open={true}>
            <Container>
              <Row>
                <Col xs={12} md={6} className="my-2 p-1">
                  <strong> Billing Address</strong>
                  <BillingAddressPage />
                </Col>
                <Col xs={12} md={6} className="my-2 p-1">
                  <strong> Shipping Address</strong>
                  <ShippingAddressPage />
                </Col>
              </Row>
            </Container>
          </Collapsible>
          <Collapsible trigger="Payment" open={true}>
            <Container>
              <PaymentMethod accepted_payments={accepted_payments} />
            </Container>
          </Collapsible>
          <Collapsible trigger="Order Summary" open={true}>
            <Container>
              <Row>
                <Col className="p-1"> 
                  <CheckoutOrderSummary line_items={line_items} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="p-1">
                  <PriceSummary
                    price_sub_total={pricing_summary.price_sub_total}
                    tax={pricing_summary.tax}
                    total={pricing_summary.total}
                  />
                </Col>
              </Row>
            </Container>
          </Collapsible>
        </div>
        <Col xs={12} sm={12} className="text-center my-3">
          {/* <Button className="btnClickSeller " as={NavLink} exact to="/tickets">
            <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
            Continue Shopping
          </Button> */}
          <Button
            className="btnClickSeller ml-2 my-1"
            type="submit"
            onClick={placeorder}
          >
            Go to checkout
          </Button>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default CheckoutView;
