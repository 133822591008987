export const LOGIN                        = "[auth] Login";
export const LOGIN_SUCCESS                = "[auth] Login success";
export const LOGIN_ERROR                  = "[auth] Login error";
export const SET_LOGIN_STATUS             = '[auth] Login set status';
export const LOGOUT                       = "[auth] Logout";
export const LOGOUT_SUCCESS               = "[auth] Logout success";
export const LOGOUT_ERROR                 = "[auth] Logout error";
export const SET_LOGOUT_STATUS            = '[auth] Logout set status';
export const REFRESH_TOKEN                = "[auth] Refresh";
export const REFRESH_TOKEN_SUCCESS        = "[auth] Refresh success";
export const REFRESH_TOKEN_ERROR          = "[auth] Refresh error";
export const SET_REFRESH_STATUS           = '[auth] Refresh set status';
export const PROCESS_LOGIN_REFRESH_RESPONSE           = '[auth] Login - Refresh process response';
export const SET_TOKENS                   = '[auth] Set token';
export const GET_USER_DATA                = "[auth] Fetch user data";
export const FETCH_USER_DATA_SUCCESS      = "[auth] Fetch user data sucess";
export const FETCH_USER_DATA_ERROR        = "[auth] Fetch user data error";
export const UPDATE_USER_DATA             = "[auth] Update user data";
export const UPDATE_USER_PERMISSIONS      = "[auth] Update user permissions";
export const SET_USER_STATUS              = '[auth] Set user status';


// For user login
export const login = (data) => ({
  type: LOGIN,
  payload: data
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginError = (data) => ({
  type: LOGIN_ERROR,
  payload: data,
});

export const setLoginStatus = (data) => ({
  type: SET_LOGIN_STATUS,
  payload: data,
});

export const refresh = () => ({
  type: REFRESH_TOKEN
});

export const refreshSuccess = (data) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const refreshError = (data) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: data,
});

export const setRefreshStatus = (data) => ({
  type: SET_REFRESH_STATUS,
  payload: data,
});

export const processLoginRefreshResponse = (data) => ({
  type: PROCESS_LOGIN_REFRESH_RESPONSE,
  payload: data,
});

export const getUserData = () => ({
  type: GET_USER_DATA,
});

// For fetching moments
export const fetchUserDataSuccess = (data) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: data,
});

export const fetchUserDataError = (data) => ({
  type: FETCH_USER_DATA_ERROR,
  payload: data,
});

export const setUserData = (data) => ({
  type: UPDATE_USER_DATA,
  payload: data,
});

export const setUserPermission = (data) => ({
  type: UPDATE_USER_PERMISSIONS,
  payload: data,
});

export const setTokens = (data) => ({
  type: SET_TOKENS,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT
});

export const logoutSuccess = (data) => ({
  type: LOGOUT_SUCCESS,
  payload: data,
});

export const logoutError = (data) => ({
  type: LOGOUT_SUCCESS,
  payload: data,
});

export const setLogoutStatus = (data) => ({
  type: SET_LOGOUT_STATUS,
  payload: data,
});

export const setUserStatus = (data) => ({
  type: SET_USER_STATUS,
  payload: data,
});
